import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IFilterService } from 'angular';

export class InArrayFilter {

    public static Factory<T, TFilter>($filter: IFilterService): any {
        return (list: T[], arrayFilter: TFilter[], element: string) => $filter('filter')(list, (listItem) => arrayFilter.indexOf(listItem[element]) !== -1);
    }
}

InArrayFilter.Factory.$inject = [Injectables.$filter];

app.filter('inArray', InArrayFilter.Factory);