import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { BondLineReportItem } from "../../../states/agentSpecific/bondLines/bondLineReportItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import TableColumn from "../../../components/dataViews/tableColumn";
import DataView from "../../../components/dataViews/dataView";
import DataViewType from "../../../components/dataViews/dataViewType";
import deepCopy from "../../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import QueryBuilderLogicType from "../../../components/queryBuilder/queryBuilderLogicType";
import QueryConditionPropertyType from "../../../components/queryBuilder/queryConditionPropertyType";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { BondAccountService } from "../../../api/bondAccountService";
import { ModalOpener } from "../../../modals/modalOpener";
import { UserService } from "../../../api/userService";
import { SearchType } from "../../../api/types/searchType";
import QueryConditionPropertyGroup from "../../../components/queryBuilder/queryConditionPropertyGroup";

export class BondLinesReportController {
    
    public static $inject = [
        Injectables.BondAccountService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator,
        Injectables.UserService
    ];

    constructor(
        private readonly bondAccountService: BondAccountService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        userService: UserService
    ) {
        const columnOptions: TableColumn<any>[] = [{
            name: 'Id',
            heading: 'Id',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BondAccounts.Id',
            index: 0
        },{
            name: 'Customer',
            heading: 'Customer',
            headingClass: '',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'Customers.Name',
            locked: true,
            index: 1
        },
        {
            name: 'Status',
            heading: 'Status',
            headingClass: '',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.Status',
            index: 2
        },
        {
            name: 'EffectiveDate',
            heading: 'Effective Date',
            headingClass: '',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.EffectiveDate',
            index: 4 
        },
        {
            name: 'ExpirationDate',
            heading: 'Expiration Date',
            headingClass: '',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.ExpirationDate',
            index: 5 
        },
        {
            name: 'ClientServiceManager',
            heading: 'CSM',
            tooltip: 'Client Service Manager',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'ClientServiceManager.FullName',
            index: 6
        },
        {
            name: 'ClientServiceExecutive',
            heading: 'CSE',
            tooltip: 'Client Service Manager',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'ClientServiceExecutive.FullName',
            index: 6
        },
        {
            name: 'ClientServiceAssociate',
            heading: 'CSA',
            tooltip: 'Client Service Associate',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'ClientServiceAssociate.FullName',
            index: 6
        },
        {
            name: 'Producer',
            heading: 'Producer',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'Producer.FullName',
            index: 7
        },
        {
            name: 'Carrier',
            heading: 'Carrier',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'SystemAccounts.CompanyName',
            index: 8
        },
        {
            name: 'CarrierAccountNumber',
            heading: 'Carrier Account Number',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.CarrierAccountNumber',
            index: 9
        },
        {
            name: 'AggregateBondLimit',
            heading: 'Aggregate Bond Limit',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.AggregateBondLimit',
            index: 10
        },
        {
            name: 'SingleBondLimit',
            heading: 'Single Bond Limit',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: true,
            sortExpression: 'BondAccounts.SingleBondLimit',
            index: 11
        },
        { 
            name: 'DetailsButton',
            heading: '',
            label: 'Details',
            headingClass: 'fit-content',
            cellClass: 'text-right',
            isVisible: true,
            locked: true,
            index: 100
        },];

        const propertyGroups: QueryConditionPropertyGroup[] = [ 
        {
            label: 'General',
                options: [
                    { label: 'Id', value: 'BondAccounts.Id', type: QueryConditionPropertyType.Integer },
                    { label: 'Customer Name', value: 'Customers.Name', type: QueryConditionPropertyType.String },
                    { label: 'Status', value: 'BondAccounts.Status', type: QueryConditionPropertyType.BondAccountStatus },
                    { label: 'Effective Date', value: 'BondAccounts.EffectiveDate', type: QueryConditionPropertyType.Date },
                    { label: 'Expiration Date', value: 'BondAccounts.ExpirationDate', type: QueryConditionPropertyType.Date },
                    { label: 'Carrier', value: 'SystemAccounts.CompanyName', type: QueryConditionPropertyType.String },
                    { label: 'CarrierAccountNumber', value: 'BondAccounts.CarrierAccountNumber', type: QueryConditionPropertyType.String },
                    { label: 'AggregateBondLimit', value: 'BondAccounts.AggregateBondLimit', type: QueryConditionPropertyType.Decimal },
                    { label: 'SingleBondLimit', value: 'BondAccounts.SingleBondLimit', type: QueryConditionPropertyType.Decimal },
                ]
            }, {
                label: 'Servicing Roles',
                options: [
                    { label: 'Producer', value: 'Customers.ProducerUserId', type: QueryConditionPropertyType.Producer },
                    { label: 'CSE', value: 'Customers.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive },
                    { label: 'CSM', value: 'Customers.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager },
                    { label: 'CSA', value: 'Customers.ClientServiceAgentUserId', type: QueryConditionPropertyType.ClientServiceAssociate },    
                ]
            }
        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.BondLines,
            recordsPerPage: 10,
            sortExpression: 'Customers.Name',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
                isActive: true,
            
            },
            searchType: SearchType.Basic
        }];

        this.table = new QueryBuilderTable(
            this.loadData,
            debouceDelayer,
            DataViewType.BondLines,
            propertyGroups,
            columnOptions,
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener
        );
    }

    public readonly table: QueryBuilderTable<BondLineReportItem>;
    public busyIndicator: BusyIndicator = {};

    public showBondAccountModal(bond: BondLineReportItem) {
        this.modalOpener
            .showBondAccountModal(bond.customerId, bond.id)
            .result
            .then(() => {
                this.refresh();
            })
            .catch(() => {});
    }
    
    public exportBondLinesExcelReport = () => {
        this.busyIndicator = {
            message: "Loading...",
            promise: this.bondAccountService.getBondLineExcelReport(this.table.getQueryOptions(), this.table.getSelectedColumnNames())
        };
    };

    public refresh = () => {
        this.loadData();
    };

    public loadData = () => {
        return this.bondAccountService
            .getBondLineReportData(this.table.getQueryOptions())
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load bondline information');
            });
    };

    public $onInit(): void {
        this.table.load();
    }
}

const bondLinesReportComponent = {
    bindings: {
        filters: "=?",
        onTableChanged: "&?",
        onTableReady: "&?"
    },
    controller: BondLinesReportController,
    templateUrl: "app/states/agentspecific/bondLines/bondLinesReport.html",
    controllerAs: "vm"
};

app.component("bondLinesReport", bondLinesReportComponent);
