import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    const unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    const allowFixedAmount = element.attr('allow-fixed-amount')?.toLowerCase() == 'true';

    var stateOptions = `
        <option value="">${unselectedText}</option>
        <option value="PercentOfCommission">% of Commission</option>
        <option value="PercentOfPremium">% of Premium</option>
        ${allowFixedAmount ? '<option value="FixedAmount">Fixed Amount<option>' : ''}
    `;

    element.append(stateOptions);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};