export interface EProducerBrandingSettings {
    id: number;
    enabled: boolean;
    companyName: string;
    phone: string;
    email: string;
    mailAddress: string;
    mailCity: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    physicalAddress: string;
    physicalCity: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    largeLogoFileId: number;
    smallLogoFileId: number;
    largeLogo: File;
    smallLogo: File;
}