import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { SystemAccountService } from "../../api/systemAccountService";
import BillingEntriesListItem from "../billingEntriesList/billingEntriesListItem";
import { QueryBuilderTableQueryOptions } from "../../api/types/queryBuilderTableOptions";
import { QueryBuilderTable } from "../../utilities/tables/queryBuilderTable";
import { SearchType } from "../../api/types/searchType";

class BillingEntriesListFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService
    ];

    constructor(private readonly systemAccountService: SystemAccountService) {
        this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public codeOptions: SelectOption<string>[];
    public producerUserOptions: SelectOption<number>[];  
    public modifiedFilters: QueryBuilderTableQueryOptions;

    public table: QueryBuilderTable<BillingEntriesListItem>;

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }
    
    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    public $onInit() {
        this.codeOptions = [
            { label: 'Any', value: null },
            { label: 'New Business', value: 'NEWB'},
            { label: 'Renewal', value: 'RENB'},
            { label: 'Rider', value: 'ENDT'},
            { label: 'Cancellation', value: 'CANC'},
            { label: 'Reinstatement', value: 'REIN'},
            { label: 'Agency Fee', value: 'AFEE' },
            { label: 'Carrier Fee', value: 'CFEE' },
            { label: 'Tax/Surcharge (Carrier paid)', value: 'SRGX' },
        ];

        this.systemAccountService.getProducerUserOptions()
            .then((producerUserOptions) => {
                this.producerUserOptions = producerUserOptions;
            });
            
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
    }
}

const billingEntriesListFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: BillingEntriesListFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/components/billingEntriesListFilterDropdown/billingEntriesListFilterDropdown.html"
};

app.component("billingEntriesListFilterDropdown", billingEntriesListFilterDropdownComponent);
