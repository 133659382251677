import { TableQueryOptions } from "../../../api/types/tableQuery";
import { UnderwritingConfigurationService } from "../../../api/underwritingConfigurationService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";

export class UnderwritingConfigurationGridController {

    public static $inject = [        
        Injectables.ToastMessageCreator,
        Injectables.UnderwritingConfigurationService,
        Injectables.$state,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer
    ];
        
    constructor( 
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly underwritingConfigurationService: UnderwritingConfigurationService,
        private readonly $state: State,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer,
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public table: Table<UnderwritingConfigurationTableListItem, UnderwritingConfigurationTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    public copy = (config: UnderwritingConfigurationTableListItem) => {
        this.modalOpener.confirmModal(
            'Copy Underwriting',
            'Are you sure you want to copy this underwriting configuration?',
            'Copy', 
            'Cancel')
        .result
        .then(() => {
            this.busyIndicator.message = 'Coping...';
            this.busyIndicator.promise = this.underwritingConfigurationService.copyConfiguration(config.id)
                .then((newId: number) => {
                    this.toastMessageCreator.createSuccessMessage('The configuration has been copied successfully');
                    this.$state.go('main.underwritingConfigurationDetail', { id: newId });
                });
        })
        .catch(() => {});
    }    

    public promptToDelete(underwritingConfiguration: UnderwritingConfigurationTableListItem) {
        this.modalOpener.confirmModal(
                'Delete Underwriting Configuration',
                'Are you sure you want to delete this underwriting configuration?',
                'Delete',
                'Cancel')
            .result
            .then(() => {
                this.busyIndicator.message = "Deleting...";
                this.busyIndicator.promise = this.underwritingConfigurationService.deleteById(underwritingConfiguration.id)
                    .then(() => {
                        this.loadData();
                        this.toastMessageCreator.createSuccessMessage('Underwriting configuration was deleted successfully');
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An Error occurred deleting the underwiriting configuration');
                    })
            })
            .catch(() => {});
    }

    public loadData = () => {
        this.busyIndicator.promise = this.underwritingConfigurationService.getUnderwritingConfigurationTableListItems(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load underwriting configurations');
            })
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.loadData();
    }
}

export type UnderwritingConfigurationTableListItem = {
    id: number;
    bondType: string;
    isActive: boolean;
    minimumVantage?: number;
    writingCompany: string;
    rate: string;
    term: number;
    minimumBondAmount?: number;
    maximumBondAmount?: number;
}

export type UnderwritingConfigurationTableQueryOptions = TableQueryOptions & {}