import { SystemAccountService } from "../../api/systemAccountService";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { Table } from "../../utilities/tables/table";
import BillingEntriesListItem from "./billingEntriesListItem";
import { BillingReportDropdownFilterOptions }  from   "../../states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";
import { ModalOpener } from "../../modals/modalOpener";

class BillingEntriesListController {
    
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly modalOpener: ModalOpener
    ) {}

    public onCreditAssigned: () => void;
    public onDownloadExcel: () => void;
    public onCreateNewInvoice: () => void;
    public table: Table<BillingEntriesListItem, BillingReportDropdownFilterOptions>;
    
    public producerUserOptions: SelectOption<number>[];
    
    public showCreditBillingEntryAssignmentModal = (creditBillingEntryId: number) => {
        this.modalOpener.showCreditBillingEntryAssignmentModal(creditBillingEntryId)
            .result
            .then(() => {
                this.table.refresh();
                if (typeof this.onCreditAssigned === 'function') {
                    this.onCreditAssigned();
                }
            });
    }

    public newInvoiceClicked = () => {
        if (typeof this.onCreateNewInvoice === 'function') {
            this.onCreateNewInvoice();
        }
    }

    public donloadExcelClicked = () => {
        if (typeof this.onDownloadExcel === 'function') {
            this.onDownloadExcel();
        }
    }

    $onInit = () => {
        if (!this.currentUserResolver.getCurrentUser().systemAccount.isCarrier) {
            this.systemAccountService.getProducerUserOptions()
                .then((producerUserOptions) => {
                    this.producerUserOptions = producerUserOptions;
                });
        }}
}

const billingEntriesListComponent = {
    templateUrl: 'app/components/billingEntriesList/billingEntriesList.html',
    bindings: {
        table: '<',
        onCreditAssigned: '&?',
        onCreateNewInvoice: '&?',
        onDownloadExcel: '&?'
    },
    controllerAs: 'vm',
    controller: BillingEntriesListController
};

app.component('billingEntriesList', billingEntriesListComponent);