import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";

export interface BondNumberGroup extends IBaseEntity { 
    systemAccountId: number;
    agencySystemAccountId?: number;
    name: string;
    nextNumber: number;
    lastNumber: number;
    prefix: string;
    suffix: string;
    systemAccount: SystemAccount;
    id: number;
    isActive: boolean;
    createdDateTime: Date;
}