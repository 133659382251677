import { PaymentMethod } from "../../../api/types/model/paymentTransaction";
import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    const unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    
    var options = `
    
        <option value="">${unselectedText}</option>
        <option value="${PaymentMethod.ACH}">ACH</option>
        <option value="${PaymentMethod.Check}">Check</option>
        <option value="${PaymentMethod.CreditCard}">Credit Card</option>
        <option value="${PaymentMethod.Other}">Other</option>
        <option value="${PaymentMethod.Return}">Return</option>
        <option value="${PaymentMethod.WireTransfer}">Wire Transfer</option>
    `;

    element.append(options);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};