import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import app from "../../main";
import { ApplicationFollowUpStrategyService } from "../../api/applicationFollowUpStrategyService";
import { TableQueryOptions } from "../../api/types/tableQuery";
import { ApplicationFollowUpType } from "../../api/types/model/applicationFollowUp";

export class FollowUpStrategyListController {

    public static $inject = [
        Injectables.ApplicationFollowUpStrategyService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly followUpStrategyService: ApplicationFollowUpStrategyService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<ApplicationFollowUpListItem, TableQueryOptions>;
    public busyIndicator: BusyIndicator;

    public newFollowUpStrategyClicked = () => {
        this.modalOpener.showFollowUpStrategyModal()
            .result
            .then(() => {
                this.loadData();
            });
    }

    public editFollowUpStrategyClicked = (id: number) => {
        this.modalOpener.showFollowUpStrategyModal(id)
            .result
            .then(() => {
                this.loadData();
            });
    }

    public promptToDelete = (followUpStrategy: ApplicationFollowUpListItem) => {
        this.busyIndicator = {
            message: 'Deleting...',
            promise: this.modalOpener.confirmModal('Delete Follow-Up Strategy', 'Are you sure you want to delete this follow-up strategy?', 'Delete', 'Cancel')
            .result
            .then(() => {
                return this.followUpStrategyService.deleteById(followUpStrategy.id);
            })
            .then(this.loadData)
        };
    }

    public loadData = () => {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.followUpStrategyService.getFollowUpStrategies(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            })
        };
    }

    public $onInit(): void {
        this.loadData();
    }
}

export type ApplicationFollowUpListItem = {
    id: number;
    name: string;
    strategyType: ApplicationFollowUpType;
    comments: string;
}

const followUpStrategyListComponent = {
    bindings: {},
    templateUrl: 'app/components/followUpStrategyList/followUpStrategyList.html',
    controller: FollowUpStrategyListController,
    controllerAs: 'vm'
};

app.component('followUpStrategyList', followUpStrategyListComponent);