import { ODataFactory, ODataService, ODataEndpoint } from "../../../api/odata";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { BondType, BondAmountTypes, FieldStatusType, StatutoryExpirationDateType, CancellationNotificationRecipientTypes, CancellationNotificationMethodTypes } from "../../../api/types/model/bondType";
import { DocumentCategory } from "../../../api/types/model/documentCategory";
import { MasterApplicationQuestion } from "../../../api/types/model/masterApplicationQuestion";
import { Obligee } from "../../../api/types/model/obligee";
import { SelectOption } from "../../../api/types/selectOption";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { DocumentPickerDocument } from "../../../components/documentPicker/documentPicker";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { UtilityService } from "../../../utilities/utilityService";
import { State } from "../../state";
import { Document } from "../../../api/types/model/document";
import A3ApiResponse from "../../../api/types/a3ApiResponse";
import { IQService } from "angular";
import { DocumentService } from "../../../api/documentService";
import { SfaaCodeService } from "../../../api/sfaaCodeService";
import { BondTypeService } from "../../../api/bondTypeService";
import { UploaderItem } from "../../../components/uploader/uploader";


export type BondTypeDetailStateParams = {
    id?: number;
};

export class BondTypeDetailController {

    public static $inject = [
        Injectables.$stateParams, 
        Injectables.$state, 
        Injectables.ODataFactory, 
        Injectables.$q, 
        Injectables.ModalOpener, 
        Injectables.UtilityService, 
        Injectables.CurrentUserResolver,
        Injectables.ToastMessageCreator,
        Injectables.DocumentService,
        Injectables.SfaaCodeService,
        Injectables.BondTypeService
    ];

    constructor(
        private readonly $stateParams: BondTypeDetailStateParams,
        private readonly $state: State,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly modalOpener: ModalOpener,
        private readonly utilityService: UtilityService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly documentService: DocumentService,
        private readonly sfaaCodeService: SfaaCodeService,
        private readonly bondTypeService: BondTypeService
    ) {}

    public currentUser: CurrentUser;
    public busyIndicator: BusyIndicator;
    public isNewBondType: boolean;
    public bondTypeId: number;
    public bondType: BondTypeDetail;
    
    public bondFormDocuments: DocumentPickerDocument[] = [];
    public coverLetterDocuments: DocumentPickerDocument[] = [];
    public applicationFormDocuments: DocumentPickerDocument[] = [];
    public renewalCoverLetterDocuments: DocumentPickerDocument[] = [];
    public continuationCertificateDocuments: DocumentPickerDocument[] = [];
    public verificationCertificateDocuments: DocumentPickerDocument[] = [];

    public bondFormsToUpload: UploaderItem[];
    public coverLettersToUpload: UploaderItem[];
    public applicationsToUpload: UploaderItem[];
    public renewalCoverLettersToUpload: UploaderItem[];
    public continuationCertificatesToUpload: UploaderItem[];
    public verificationCertificatesToUpload: UploaderItem[];

    public bondFormDocumentPicker = undefined;
    public coverLetterDocumentPicker = undefined;
    public applicationFormDocumentPicker = undefined;
    public renewalCoverLetterDocumentPicker = undefined;
    public continuationCertificateDocumentPicker = undefined;
    public verificationCertificateDocumentPicker = undefined;

    public bondFormDocumentCategory = undefined;
    public coverLetterDocumentCategory = undefined;
    public applicationFormDocumentCategory = undefined;
    public renewalCoverLetterCategory = undefined;
    public continuationCertificateCategory = undefined;
    public verificationCertificateCategory = undefined;

    public bondFormCategoryId: number;
    public applicationFormCategoryId: number;
    public coverLetterCategoryId: number;
    public renewalCoverLetterCategoryId: number;
    public continuationCertificateCategoryId: number;
    public verificationCertificateCategoryId: number;

    public bondAmountTypeOptions: SelectOption<BondAmountTypes>[];
    public fieldStatusOptions: SelectOption<FieldStatusType>[];
    public documentCategories: DocumentCategory[] = [];
    public sfaaCodeOptions: SelectOption<number>[] = [];

    public documentSvc: ODataService<Document>;
    public questionSvc: ODataService<MasterApplicationQuestion>;
    public bondTypeSvc: ODataService<BondType>;
    public obligeeSvc: ODataService<Obligee>;

    public months: SelectOption<number>[];
    public days: SelectOption<number>[];
    public years: SelectOption<string>[];

    public obligeeSearch: Obligee[] = [];
    public questionSearch: MasterApplicationQuestion[] = [];

    public uploadProgress: any;
    public newVariableBondAmount: number;
    
    public documentSelected = (selectedDocument: Document) => {
        this.bondType.bondTypeDocuments.push({
            documentId: selectedDocument.id,
            bondTypeId: this.bondType.id,
            documentCategoryId: selectedDocument.documentCategoryId,
            name: selectedDocument.name
        } as BondTypeDetailDocument);
        
        this.mapBondTypeDocuments();
    }

    private buildDocumentPickerObject = (document: BondTypeDetailDocument) => {
        return {
            id: document.documentId,
            name: document.name,
            isRemoved: document.isRemoved
        };
    }

    public mapBondTypeDocuments = () => {
        this.bondFormDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.bondFormCategoryId)
            .map(this.buildDocumentPickerObject);

        this.coverLetterDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.coverLetterCategoryId)
            .map(this.buildDocumentPickerObject);

        this.applicationFormDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.applicationFormCategoryId)
            .map(this.buildDocumentPickerObject);

        this.renewalCoverLetterDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.renewalCoverLetterCategoryId)
            .map(this.buildDocumentPickerObject);
           
        this.continuationCertificateDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.continuationCertificateCategoryId)
            .map(this.buildDocumentPickerObject);
        
        this.verificationCertificateDocuments = this.bondType.bondTypeDocuments
            .filter((bondTypeDocument: BondTypeDetailDocument) => bondTypeDocument.documentCategoryId === this.verificationCertificateCategoryId)
            .map(this.buildDocumentPickerObject);
    }

    public documentRemoved = (documentId: number) => {
        const index = this.getDocumentIndex(documentId);
        const bondTypeDocument = this.bondType.bondTypeDocuments[index];

        if (bondTypeDocument.id) {
            bondTypeDocument.isRemoved = bondTypeDocument.isRemoved !== true;
        }

        this.mapBondTypeDocuments();
    }

    public getDocumentIndex = (documentId: number) => {
        return this.bondType.bondTypeDocuments
            .findIndex(bondTypeDocument => bondTypeDocument.documentId === documentId);
    }

    public showHistoryModal = () => {
        this.modalOpener.showBondTypeHistoryModal(this.bondType.id)
            .result
            .then(() => {})
            .catch(() => {});
    }

    public clearObligee = () => {
        this.bondType.obligeeId = null;
        this.bondType.obligee = null;
    }

    public saveClick = () => {

        const filesToUpload = [
            ...this.bondFormsToUpload,
            ...this.coverLettersToUpload,
            ...this.applicationsToUpload,
            ...this.renewalCoverLettersToUpload,
            ...this.continuationCertificatesToUpload,
            ...this.verificationCertificatesToUpload,
        ];

        if (filesToUpload.length > 0) {
            this.modalOpener.uploadModal(filesToUpload)
                .result
                .then(() => {
                    this.save();
                })
                .catch(() => {});

            Promise.all([
                this.bondFormDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    }),
                this.coverLetterDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    }),
                this.applicationFormDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    }),
                this.renewalCoverLetterDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    }),
                this.continuationCertificateDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    }),
                this.verificationCertificateDocumentPicker.save()
                    .then(uploadedItems => {
                        this.addUploadedFiles(uploadedItems, this.bondFormCategoryId);
                    })
            ])
            .then(() => { })
            .catch(() => { });
        } else {
            this.save();
        }
    }

    private addUploadedFiles = (uploaderItems: UploaderItem[], documentCategoryId: number) => {
        uploaderItems.forEach(uploaderItem => {
            this.bondType.bondTypeDocuments.push({
                bondTypeId: this.bondTypeId,
                name: uploaderItem.file.name,
                documentId: uploaderItem.response,
                documentCategoryId: documentCategoryId,
                documentCategory: this.documentCategories.find(category => category.id === documentCategoryId)?.name
            } as BondTypeDetailDocument); 
        });
    }

    public save = () => {

        if (this.bondType.obligee) {
            this.bondType.obligeeId = this.bondType.obligee.id;
        } else {
            this.bondType.obligeeId = null;
        }

        this.busyIndicator = {
            message: 'Saving...',
            promise: this.bondTypeService.saveBondType(this.bondType)
                .then(() => {
                    this.$state.go('main.bondTypes');
                    this.toastMessageCreator.createSuccessMessage('Bond type saved successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to save the bond type');
                })
        };
    }

    public searchObligees = (search) => {
        if (!search) {
            return;
        }

        const query = this.odata.getQuery();
        query.filter("isActive eq true and (contains(name, '" + search + "')) and masterObligeeId eq null");
        query.orderby('name');
        query.top(25);

        return this.obligeeSvc.get<A3ApiResponse<Obligee[]>>(query)
            .then((response) => {
                this.obligeeSearch = response.data.value;
            });
    }

    public addBondAmount = () => {

        if (!this.newVariableBondAmount) {
            return;
        } else if (this.newVariableBondAmount <= 0) {
            return;
        } else if (this.bondType.bondTypeVariableBondAmounts.some((bondAmount) => bondAmount.amount == this.newVariableBondAmount)) {
            return;
        }

        this.bondType.bondTypeVariableBondAmounts.push({
            amount: this.newVariableBondAmount
        } as BondTypeDetailVariableBondAmount);

        // sort bond amounts by amount property
        this.bondType.bondTypeVariableBondAmounts.sort((a, b) => {
            if (a.amount < b.amount) return -1;
            if (a.amount > b.amount) return 1;

            return 0;
        });

        this.newVariableBondAmount = null;
    }

    public removeBondAmount = (bondAmount: BondTypeDetailVariableBondAmount, index: number) => {
        if (!bondAmount.id) {
            this.bondType.bondTypeVariableBondAmounts.splice(index, 1);
        } else {
            bondAmount.isRemoved = bondAmount.isRemoved !== true;
        }
    }

    public showObligeeModal = (obligee: Obligee) => {
        this.modalOpener.showObligeeModal(obligee)
            .result
            .then((result) => {
                this.bondType.obligee = result.obligee;
            })
            .catch(() => {});
    }

    public searchQuestions = (search) => {
        const query = this.odata.getQuery()
            .top(20)
            .filter("contains(name,'" + search + "') and isSubQuestion eq false");

        this.questionSvc.get<A3ApiResponse<MasterApplicationQuestion[]>>(query)
            .then((response) => {
                this.questionSearch = response.data.value;
            });
    }

    public addQuestion = () => {
        if (!this.questionSearch || !this.questionSearch['selected']) { 
            return;
        }

        for (let i = 0; i < this.bondType.bondTypeMasterApplicationQuestions.length; i++) {
            if (this.bondType.bondTypeMasterApplicationQuestions[i].masterApplicationQuestionId == this.questionSearch['selected'].id) {
                this.questionSearch['selected'] = null;
                return;
            }
        }

        this.bondType.bondTypeMasterApplicationQuestions.push({
            bondTypeId: this.bondType.id,
            masterApplicationQuestionId: this.questionSearch['selected'].id,
            questionText: this.questionSearch['selected'].questionText,
            name: this.questionSearch['selected'].name
        } as BondTypeDetailMasterApplicationQuestion);

        this.questionSearch['selected'] = null;
    }

    public removeQuestion = (question: BondTypeDetailMasterApplicationQuestion, index: number) => {
        if (!question.id) {
            this.bondType.bondTypeMasterApplicationQuestions.splice(index, 1);
        } else {
            question.isRemoved = question.isRemoved !== true;
        }
    }

    public loadBondType = () => {
        return this.bondTypeService.getBondTypeDetail(this.bondTypeId)
            .then((bondType) => {
                this.bondType = bondType;
                this.mapBondTypeDocuments();
            });
        
    };

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getNewAllSfaaCodeOptions()
            .then((sfaaCodeOptions) => {
                this.sfaaCodeOptions = sfaaCodeOptions;
            });
    };

    public loadDocumentCategories = () => {
        return this.documentService.getDocumentCategories()
            .then((categories) => {
                this.documentCategories = categories;

                for (let i = 0; i < this.documentCategories.length; i++) {
                    if (this.documentCategories[i].name === 'Bond Form') {
                        this.bondFormDocumentCategory = this.documentCategories[i];
                        this.bondFormCategoryId = this.documentCategories[i].id;
                    } else if (this.documentCategories[i].name === 'Application Form') {
                        this.applicationFormDocumentCategory = this.documentCategories[i];
                        this.applicationFormCategoryId = this.documentCategories[i].id;
                    } else if (this.documentCategories[i].name === 'Cover Letter') {
                        this.coverLetterDocumentCategory = this.documentCategories[i];
                        this.coverLetterCategoryId = this.documentCategories[i].id;
                    } else if (this.documentCategories[i].name === 'Continuation Certificate') {
                        this.continuationCertificateCategory = this.documentCategories[i];
                        this.continuationCertificateCategoryId = this.documentCategories[i].id;
                    } else if (this.documentCategories[i].name === 'Verification Certificate') {
                        this.verificationCertificateCategory = this.documentCategories[i];
                        this.verificationCertificateCategoryId = this.documentCategories[i].id;
                    } else if (this.documentCategories[i].name === 'Renewal Cover Letter') {
                        this.renewalCoverLetterCategory = this.documentCategories[i];
                        this.renewalCoverLetterCategoryId = this.documentCategories[i].id;
                    }
                }
            });
    };

    public $onInit = () => {
        this.busyIndicator = {};
        this.bondTypeId = this.$stateParams.id;
        this.isNewBondType = this.$stateParams.id ? false : true;

        this.bondType = {
            isActive: true,
            isElectronic: false,
            bondTypeVariableBondAmounts: [],
            bondTypeDocuments: [],
            bondTypeMasterApplicationQuestions: [],
            bondAmount: null,
            minimumBondAmount: null,
            maximumBondAmount: null,
            allowsElectronicSignature: false,
            allowsPrincipalElectronicSignature: false,
            companyInformationIsApplicable: true,
            multiplePeopleAllowed: true,
            nameOnBondAllowFirstMiddleLast: true,
            nameOnBondAllowCompanyDBA: true,
            nameOnBondAllowOtherA3: true,
            nameOnBondAllowCombinePrincipals: true,
            nameOnBondAllowFirstLast: true,
            nameOnBondAllowCompanyName: true,
            nameOnBondAllowOtherEProducer: true,
            nameOnBondInstructions: "It's important that you confirm the name printed on your bond. Some bonds have specific requirements for how your name is printed.",
            signatureDateOnBondForm: true,
            hideInEProducer: false,
            requiresVerificationCertificate: false,
            requiresContinuationCertificate: false,
            allowsBackdating: false
        } as BondTypeDetail;

        this.currentUser = this.currentUserResolver.getCurrentUser();
        
        this.bondAmountTypeOptions = [{
            label: 'Fixed',
            value: BondAmountTypes.Fixed
        }, {
            label: 'Variable',
            value: BondAmountTypes.Variable
        }, {
            label: 'User Defined',
            value: BondAmountTypes.UserDefined
        }];

        this.fieldStatusOptions = [{
            label: 'Hidden',
            value: FieldStatusType.Hidden
        }, {
            label: 'Optional',
            value: FieldStatusType.Visible
        }, {
            label: 'Required',
            value: FieldStatusType.Required
        }];

        this.uploadProgress = {};

        this.documentSvc = this.odata.getService(ODataEndpoint.Document);
        this.questionSvc = this.odata.getService(ODataEndpoint.MasterApplicationQuestion);
        this.bondTypeSvc = this.odata.getService(ODataEndpoint.BondType);
        this.obligeeSvc = this.odata.getService(ODataEndpoint.Obligee);

        this.busyIndicator.promise = this.$q.all([
            this.loadSfaaCodes(),
            this.loadDocumentCategories()
        ])
        .then(() => {
            if (!this.isNewBondType) {
                return this.loadBondType();
            }
        });

        this.months = this.utilityService.getDropdownMonths();
        this.days = this.utilityService.getDropdownDaysProper();
        this.years = this.utilityService.getDropdownYearDurations();
    }
}

export interface BondTypeDetail {
    id: number;
    name: string;
    isActive: boolean;
    description: string;
    keywords: string;
    internalComments: string;
    sfaaCodeId: number;
    signatureDateOnBondForm: boolean;
    bondAmountType: BondAmountTypes;
    bondAmount: number;
    bondTypeVariableBondAmounts: BondTypeDetailVariableBondAmount[];
    minimumBondAmount: number;
    maximumBondAmount: number;
    statutoryExpirationDateType: StatutoryExpirationDateType;
    statutoryExpirationDateMonth: number;
    statutoryExpirationDateDay: number;
    expirationYearType: string;
    isRenewable: boolean;
    requiresContinuationCertificate: boolean;
    requiresVerificationCertificate: boolean;
    requiresNewBondUponExpiration: boolean;
    isCancellable: boolean;
    cancellationProvisionDays: number;
    cancellationNotificationReceipientType: CancellationNotificationRecipientTypes;
    cancellationNotificationMethodTypes: CancellationNotificationMethodTypes;
    hideInEProducer: boolean;
    isElectronic: boolean;
    allowsElectronicSignature: boolean;
    allowsPrincipalElectronicSignature: boolean;
    principalWitnessSignatureRequired: boolean;
    principalWitnessPrintedNameRequired: boolean;
    principalNotaryAcknowledgementRequired: boolean;
    agencyNotaryAcknowledgementRequired: boolean;
    principalCorporateSealRequired: boolean;
    principalRetainsOriginal: boolean;
    principalSignatureLocationRequired: boolean;
    allowsBackdating: boolean;
    companyInformationIsApplicable: boolean;
    companyNameFieldStatus: FieldStatusType;
    companyDbaFieldStatus: FieldStatusType;
    companyTypeFieldStatus: FieldStatusType;
    companyPhoneFieldStatus: FieldStatusType;
    companyDateFormedFieldStatus: FieldStatusType;
    companyNumberOfOwnersFieldStatus: FieldStatusType;
    companyNumberOfEmployeesFieldStatus: FieldStatusType;
    companyFaxFieldStatus: FieldStatusType;
    companyEmailFieldStatus: FieldStatusType;
    companyAddressFieldStatus: FieldStatusType;
    companyCountyFieldStatus: FieldStatusType;
    companyStateOfIncorporationFieldStatus: FieldStatusType;
    companyFEINFieldStatus: FieldStatusType;
    personMiddleNameFieldStatus: FieldStatusType;
    personPrefixFieldStatus: FieldStatusType;
    personSuffixFieldStatus: FieldStatusType;
    personSSNFieldStatus: FieldStatusType;
    personDateOfBirthFieldStatus: FieldStatusType;
    personDriversLicenseNumberFieldStatus: FieldStatusType;
    personDriversLicenseStateFieldStatus: FieldStatusType;
    personHomePhoneFieldStatus: FieldStatusType;
    personCellPhoneFieldStatus: FieldStatusType;
    personFaxFieldStatus: FieldStatusType;
    personGenderFieldStatus: FieldStatusType;
    personAddressFieldStatus: FieldStatusType;
    personCountyFieldStatus: FieldStatusType;
    personJobTitleFieldStatus: FieldStatusType;
    personMaritalStatusFieldStatus: FieldStatusType;
    personSpouseNameFieldStatus: FieldStatusType;
    personSpouseSsnFieldStatus: FieldStatusType;
    personSpouseEmailFieldStatus: FieldStatusType;
    personSpouseCellPhoneFieldStatus: FieldStatusType;
    personSpouseDateOfBirthFieldStatus: FieldStatusType;
    personSpouseGenderFieldStatus: FieldStatusType;
    personResidenceTypeFieldStatus: FieldStatusType;
    personDateMovedToResidenceFieldStatus: FieldStatusType;
    personEmployerNameFieldStatus: FieldStatusType;
    personEmployerAddressFieldStatus: FieldStatusType;
    personEmployerCountyFieldStatus: FieldStatusType;
    personEmployerPhoneFieldStatus: FieldStatusType;
    personYearsExperienceFieldStatus: FieldStatusType;
    personOwnershipPercentFieldStatus: FieldStatusType;
    personIsUSCitizenFieldStatus: FieldStatusType;
    multiplePeopleAllowed: boolean;
    nameOnBondAllowFirstMiddleLast: boolean;
    nameOnBondAllowCompanyDBA: boolean;
    nameOnBondAllowOtherA3: boolean;
    nameOnBondAllowCombinePrincipals: boolean;
    nameOnBondAllowFirstLast: boolean;
    nameOnBondAllowCompanyName: boolean;
    nameOnBondAllowOtherEProducer: boolean;
    nameOnBondInstructions: string;
    obligee: Obligee;
    obligeeId: number;
    bondTypeMasterApplicationQuestions: BondTypeDetailMasterApplicationQuestion[],
    bondTypeDocuments: BondTypeDetailDocument[];
}

export interface BondTypeDetailDocument {
    id: number;
    bondTypeId: number;
    documentId: number;
    documentCategory: string;
    documentCategoryId: number;
    name: string;
    isRemoved?: boolean;
}

export interface BondTypeDetailMasterApplicationQuestion {
    id: number;
    masterApplicationQuestionId: number;
    isRequired: boolean;
    bondTypeId: number;
    name: string;
    questionText: string;
    isRemoved?: boolean;
}

export interface BondTypeDetailVariableBondAmount {
    id: number;
    bondTypeId: number;
    amount: number;
    isRemoved: boolean;
}