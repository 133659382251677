import { DocumentService } from "../../../api/documentService";
import { DocumentCategory } from "../../../api/types/model/documentCategory";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { Document } from "../../../api/types/model/document";
import { LocalStorageService } from "../../../utilities/localStorage/localStorageService";
import { DocumentLibraryStateParams } from "./DocumentLibraryStateParams";
import { DocumentLibrarySettings } from "./DocumentLibrarySettings";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import DocumentTableQueryOptions from "./documentFilterDropdown/documentTableQueryOptions";
import DocumentListItem from "./documentListItem";
import DocumentDetails from "../../../api/types/documents/documentDetails";
import { UploadControls } from "../../../components/uploader/uploader";

export class DocumentLibraryController {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.CurrentUserResolver,
        Injectables.LocalStorageService,
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator,
        Injectables.DocumentService,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly localStorageService: LocalStorageService,
        private readonly $state: State,
        private readonly $stateParams: DocumentLibraryStateParams,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly documentService: DocumentService,
        debouceDelayer: IDebounceDelayer
    ) {
        this.table = new Table(debouceDelayer);
    }

    public downloadUrls: string[];
    public sampleDownloadUrls: string[];
    public table: Table<DocumentListItem, DocumentTableQueryOptions>;
    public documentCategories: DocumentCategory[];
    public isDetailsPaneVisible: boolean;
    public busyIndicator: BusyIndicator;
    public documentDetails: DocumentDetails;
    public uploaderControls: UploadControls;
    public versionUpdateUploaderControls: UploadControls;

    public filesAddedForUpload() {
        this.modalOpener.showDocumentLibrarySelectCategoryModal(this.documentCategories)
            .result
            .then((category) => {
                this.uploaderControls.setUrl(`${this.systemSettings.apiBaseUrl}documentLibrary/Upload?categoryId=${category?.id}`);
                this.uploaderControls.uploadAll()
                    .then(() => {
                        this.toastMessageCreator.createSuccessMessage("The form was uploaded succesfully");
                        this.table.refresh();
                    });
            })
            .catch(() => {});
    }

    public showDetails = (documentId: number) => {
        this.isDetailsPaneVisible = true;

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.documentService.getDocumentDetails(documentId)
                .then((documentDetails) => {
                    this.documentDetails = documentDetails;
                })
        };
    };

    public hideDetails = () => {
        this.isDetailsPaneVisible = false;
        this.documentDetails = null;
    };

    public promptToDelete = (document) => {
        this.busyIndicator.message = "Loading...";
        this.busyIndicator.promise = this.modalOpener
            .deleteConfirmationModal(
                "Delete from Library",
                "You sure you want to delete the document <strong>" + document.name + "</strong>?",
                document.id,
                null,
                this.documentService.deleteDocument,
                "Document deleted successfully",
                "Unable to delete the document. Make sure that it is not assigned to any bond types."
            )
            .result
            .then(() => this.loadDocuments())
            .catch((response) => {
            
                if (response.data.Message === "Transactions") {
                    this.toastMessageCreator.createErrorMessage(
                        "This document is already used in bond packets and cannot be deleted."
                    );
                } else if (response.data.Message === "BondTypes") {
                    this.toastMessageCreator.createErrorMessage(
                        "This document is assigned to bond types. Please remove the document from those bond types and try again."
                    );
                } else {
                    this.toastMessageCreator.createErrorMessage(
                        "An unknown error occurred while trying to delete this document."
                    );
                }
            })
            .catch(() => {});
    };

    public loadDocuments = () => {
        return this.documentService.getDocuments(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);                
                this.downloadUrls = response.items.map((document) => this.documentService.getDocumentUrl(document.id));
                this.sampleDownloadUrls = response.items.map((document) => this.documentService.getSampleBondDocumentUrl(document.id));
            });
    };

    public showDownloadModal = () => {
        this.modalOpener.showDownloadModal()
            .result
            .then(() => {})
            .catch(() => {});
    };

    public uploadNewVersion = (document: Document) => {
        this.versionUpdateUploaderControls.setUrl(`${this.systemSettings.apiBaseUrl}documentLibrary/Update`);
        this.versionUpdateUploaderControls.clearFormData();
        this.versionUpdateUploaderControls.addpendFormData("document", {documentId: document.id});
        this.versionUpdateUploaderControls.openFileDialog();
    }

    public documentVersionUpdated = () => {
        this.toastMessageCreator.createSuccessMessage("Form was updated succesfully");        
    }

    public reloadDocumentLibrarySettings = () => {
        const settings: DocumentLibrarySettings =
            this.localStorageService.getDocumentLibrarySettings();

        if (!settings) {
            return;
        }

        if (settings.search) {
            this.table.queryOptions.searchPhrase = settings.search;
        }

        if (settings.orderby) {
            this.table.sorter.sort(settings.orderby);
        }

        if (settings.recordsPerPage) {
            this.table.pager.setRecordPerPage(settings.recordsPerPage);
        }

        if (settings.currentPage) {
            this.table.pager.currentPage = settings.currentPage;
        }

        this.localStorageService.removeDocumentLibrarySettings();
    };

    public editDocument = (document: Document) => {
        const settings = {
            filters: this.table.queryOptions,
            search: this.table.queryOptions.searchPhrase,
            orderby: this.table.sorter.sortBy,
            recordsPerPage: this.table.pager.recordsPerPage,
            currentPage: this.table.pager.currentPage,
            theme: this.currentUserResolver.getCurrentUser().user.theme
        };

        this.localStorageService.setDocumentLibrarySettings(settings);

        this.$state.go("documentEditor", {
            id: document.id,
            settings: settings
        });
    };

    public $onInit = () => {
        this.busyIndicator = {
            message: "Loading..."
        };

        this.busyIndicator.promise = this.documentService.getDocumentCategories()
            .then((categories) => {
                this.documentCategories = categories;

                const categoryOptions = [];
                for (let i = 0; i < this.documentCategories.length; i++) {
                    categoryOptions.push({
                        label: this.documentCategories[i].name,
                        value: this.documentCategories[i].name
                    });
                }

                // check for existing settings (i.e. filters, orderby, etc.) and if param was passed in to restore those settings then do so otherwise clear settings.
                if (this.$stateParams.reloadDocumentLibrarySettings === true) {
                    this.reloadDocumentLibrarySettings();
                } else {
                    this.localStorageService.removeDocumentLibrarySettings();
                }

                this.table.onChange = this.loadDocuments;
            })
            .then(() => this.loadDocuments());
    };
}
