import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    const unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    
    var options = `
    
        <option value="">${unselectedText}</option>
        <option value="None">None</option>
        <option value="Requested">Requested</option>

    `;

    element.append(options);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};