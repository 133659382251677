import { SystemAccount } from "../../../api/types/model/systemAccount";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { AgencyAppointmentsDetailParams } from "./agencyAppointmentsDetailParams";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import SystemAccountOption from "../../../api/types/systemAccounts/systemAccountOption";

export class AgencyAppointmentsDetailController {
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private $stateParams: AgencyAppointmentsDetailParams,
        private $state: State,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public busyIndicator: BusyIndicator;
    public isNewAppointment: boolean;
    public agencyOptions: SystemAccountOption[];
    public appointment: CarrierAppointmentDetail;
    
    private loadAgencyOptions() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.systemAccountService.getAllUnappointedAgencies()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
            });
    }

    public loadExisting() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.systemAccountService.getAppointmentDetailById(this.$stateParams.id)
            .then((appointment) => {
                this.appointment = appointment;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load the appointment');
            });
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.systemAccountService.saveAppointmentDetail(this.appointment)
            .then(() => {
                this.$state.go('main.agencyAppointments');
                this.toastMessageCreator.createSuccessMessage('Appointment saved successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the appointment');    
            });
    }

    public $onInit(): void {
        this.isNewAppointment = this.$stateParams.id ? false : true;

        this.busyIndicator = {
            message: 'Loading...'
        };

        if (!this.isNewAppointment) {
            this.loadExisting();
        } else {
            this.appointment = {
                isActive: true
            } as CarrierAppointmentDetail;

            this.loadAgencyOptions();
        }
    }
}

export type CarrierAppointmentDetail = {
    id?: number;
    agencySystemAccountId: number;
    defaultCommissionPercent: number;
    renewalCommissionPercent: number;
    isActive: boolean;
    agencyCode: string;
    territory: string;
    agencyCanSetIrpm: boolean;
    agencyCanAddQuote: boolean;
    agencyCanSetPoa: boolean;
    agencyCanEditQuote: boolean;
}
