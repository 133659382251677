import { AgencySettings } from "../../../api/types/activities/activitySettings";
import { ActivityTypeSetting } from "../../../api/types/activities/activityTypeSetting";
import { AttachmentTypeSetting } from "../../../api/types/activities/attachmentTypeSetting";
import { Injectables } from "../../../configuration/injectables";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { SelectOption } from "../../../api/types/selectOption";
import { constants } from "../../../configuration/constants";
import { IPromise, IQService } from "angular";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { UploadControls, UploaderItem } from "../../../components/uploader/uploader";
import { ModalOpener } from "../../../modals/modalOpener";

type AgencySettingsPanes = 
    'OurAgency' |
    'ActivityTypes' | 
    'AttachmentTypes' | 
    'EpicIntegration' | 
    'Payments' | 
    'EProducer' | 
    'AttorneyInFact' | 
    'InvoiceSettings';

export class SettingsController {

    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.ToastMessageCreator,
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly modalOpener: ModalOpener
    ) {}

    public settings: AgencySettings;
    public busyIndicator: BusyIndicator;
    public newActivityType: string;
    public newAttachmentType: string;
    public activePane: AgencySettingsPanes;
    public creditCardProcessors: SelectOption<string>[];
    public achProcessors: SelectOption<string>[];
    public largeLogoUploader: UploadControls;
    public smallLogoUploader: UploadControls;
    public largeLogoFiles: UploaderItem[];
    public smallLogoFiles: UploaderItem[];

    public get lgLogo(): File {
        if (!this.largeLogoFiles) {
            return null;
        } 

        return this.largeLogoFiles[0]?.file;
    }
    
    public get smLogo(): File {
        if (!this.smallLogoFiles) {
            return null;
        }

        return this.smallLogoFiles[0]?.file;
    }
    
    public lgLogoPicturePath: string;
    public smLogoPicturePath: string;

    private loadSettings() {
        return this.systemAccountService.getAgencySettings()
            .then((settings) => {
                this.settings = settings;
                return this.setLogoPicture();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred loading account settings')
            });
    }

    public setLogoPicture(): IPromise<void[]> {
        const promises = [] as IPromise<void>[];

        if (this.settings.largeLogoFileId) {
            promises.push(this.setLargeLogoPicture());
        }

        if (this.settings.thumbnailLogoFileId) {
            promises.push(this.setSmallLogoPicture());
        }

        return this.$q.all(promises);
    }

    public setLargeLogoPicture(clearCache: boolean = false): IPromise<void> {
        return this.systemAccountService.getLargeLogo(this.settings.systemAccountId, clearCache)
            .then((imgData) => {
                this.lgLogoPicturePath = imgData;
            });
    }

    public setSmallLogoPicture(clearCache: boolean = false): IPromise<void> {
        return this.systemAccountService.getSmallLogo(this.settings.systemAccountId, clearCache)
            .then((imgData) => {
                this.smLogoPicturePath = imgData;
            });
    }

    public removeLargeLogoUpload() {
        this.largeLogoUploader.clearAll();
    }

    public removeSmallLogoUpload() {
        this.smallLogoUploader.clearAll();
    }

    public addActivityType() {
        if (!this.newActivityType) {
            return;
        }

        this.settings.activityTypes.push({
            name: this.newActivityType
        } as ActivityTypeSetting);

        this.newActivityType = '';
    }

    public deleteActivityType(activityType: ActivityTypeSetting, index: number) {
        if (!activityType.id) {
            this.settings.activityTypes.splice(index, 1);
            return;
        }

        if (activityType.isRemoved) {
            activityType.isRemoved = false;
        } else {
            activityType.isRemoved = true;
        }
    }

    public addAttachmentType() {
        if (!this.newAttachmentType) {
            return;
        }

        this.settings.attachmentTypes.push({
            name: this.newAttachmentType
        });

        this.newAttachmentType = '';
    }

    public deleteAttachmentType(attachmentType: AttachmentTypeSetting, index: number) {
        if (!attachmentType.id) {
            this.settings.attachmentTypes.splice(index, 1);
            return;
        }

        attachmentType.isRemoved = attachmentType.isRemoved !== true;
    }

    public saveClick() {
        var filesToUpload = [
            ...this.largeLogoUploader.getFiles(),
            ...this.smallLogoUploader.getFiles()
        ];

        if (filesToUpload.length) {
            this.modalOpener.uploadModal(filesToUpload)
                .result
                .then(() => {})
                .catch(() => {});
        }

        this.save();
    }

    private save = () => {
        if (this.largeLogoUploader.getIncompleteFiles().length) {
            this.largeLogoUploader.setUrl(`${this.systemSettings.apiBaseUrl}upload/UploadSystemAccountLogo`);
            this.largeLogoUploader.uploadAll()
                .then(uploadedItem => {
                    this.settings.largeLogoFileId = uploadedItem[0].response;
                    this.save();
                });
        } else if (this.smallLogoUploader.getIncompleteFiles().length) {
            this.smallLogoUploader.setUrl(`${this.systemSettings.apiBaseUrl}upload/UploadSystemAccountLogo?height=75&width=75`);
            this.smallLogoUploader.uploadAll()
                .then(uploadedItem => {
                    this.settings.thumbnailLogoFileId = uploadedItem[0].response;
                    this.save();
                });
        } else {
            this.busyIndicator.message = 'Saving...';
            this.busyIndicator.promise = this.systemAccountService.saveAgencySettings(this.settings)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage('Settings saved successfully');
                    this.busyIndicator.message = 'Loading...';
                    return this.$q.all([
                        this.loadSettings(),
                        this.setLargeLogoPicture(true),
                        this.setSmallLogoPicture(true)
                    ])
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to save settings');
                });  
        }
    }

    public $onInit(): void {
        this.activePane = 'OurAgency';
        this.busyIndicator = { message: 'Loading...' };

        this.creditCardProcessors = [];
        this.achProcessors = [];

        for (const property in constants.creditCardProcessors) {
            if (constants.creditCardProcessors.hasOwnProperty(property)) {
                this.creditCardProcessors.push({
                    value: constants.creditCardProcessors[property],
                    label: constants.creditCardProcessors[property]
                });
            }
        }

        for (const property in constants.achProcessors) {
            if (constants.achProcessors.hasOwnProperty(property)) {
                this.achProcessors.push({
                    value: constants.achProcessors[property],
                    label: constants.achProcessors[property]
                })
            }
        }

        this.busyIndicator.promise = this.loadSettings();
    }
}