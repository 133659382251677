import { ActivityListItem } from "../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../components/activityList/activityTableQueryOptions";
import { AttachmentType } from "../components/attachmentList/attachmentType";
import { Injectables } from "../configuration/injectables";
import { ActivityHistoryTableQueryOptions } from "../modals/activityModal/activityHistoryTableQueryOptions";
import A3ApiResponse from "./types/a3ApiResponse";
import { PageResponse } from "./types/pageResponse";
import { ActivityDetail } from "./types/activities/activityDetail";
import { ActivityHistoryListItem } from "./types/activities/activityHistoryListItem";
import { AgencySettings } from "./types/activities/activitySettings";
import { ActivityType } from "./types/activities/activityType";
import app from "../main";
import { IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { CacheStore } from "./cacheStore";

export class ActivityService {

    public static $inject = [
        Injectables.$http,
        Injectables.CacheStore,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly cacheStore: CacheStore,
        private readonly systemSettings: SystemSettings
    ) {
    }

    public getActivityTypes(): IPromise<ActivityType[]> {
        const url = `${this.systemSettings.apiBaseUrl}ActivityActions/GetActivityTypes`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<ActivityType[]>>(url)
                .then((response) => response.data.value);
        });
    }

    public getActivityHistoryListItems(tableQueryOptions: ActivityHistoryTableQueryOptions): IPromise<PageResponse<ActivityHistoryListItem>> {
        let queryString = `activityId=${tableQueryOptions.activityId}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        
        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }
        
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        
        return this.$http.get<A3ApiResponse<PageResponse<ActivityHistoryListItem>>>(`${this.systemSettings.apiBaseUrl}ActivityActions/GetActivityHistoryListItems?${queryString}`)
            .then((response) => response.data.value);
    }

    public getActivityDetailById(activityId: number): IPromise<ActivityDetail> {
        return this.$http.get<A3ApiResponse<ActivityDetail>>(this.systemSettings.apiBaseUrl + 'ActivityActions/GetActivityDetailById?activityId=' + activityId)
            .then((response) => response.data.value);
    }

    public createActivity(activity: ActivityDetail): IPromise<void> {
        return this.$http.post<A3ApiResponse<void>>(this.systemSettings.apiBaseUrl + 'ActivityActions/CreateActivity', activity)
            .then(() => {});
    }

    public updateActivity(activity: ActivityDetail): IPromise<void> {
        return this.$http.put<A3ApiResponse<void>>(this.systemSettings.apiBaseUrl + 'ActivityActions/UpdateActivity', activity)
            .then(() => {});
    }

    public getCustomerActivities(customerId: number, tableQueryOptions: ActivityTableQueryOptions): IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(customerId, null, null, null, null, tableQueryOptions);
    }

    public getBondActivities(bondId: number, tableQueryOptions: ActivityTableQueryOptions): IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(null, bondId, null, null, null, tableQueryOptions);
    }

    public getBondAccountActivities(bondAccountId: number, tableQueryOptions: ActivityTableQueryOptions):ng.IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(null, null, bondAccountId, null, null, tableQueryOptions);
    }

    public getBrokerActivities(eproducerAccountId: number, tableQueryOptions: ActivityTableQueryOptions): IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(null, null, null, eproducerAccountId, null, tableQueryOptions);
    }

    public getApplicationActivities(applicationId: number, tableQueryOptions: ActivityTableQueryOptions): IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(null, null, null, null, applicationId, tableQueryOptions);
    }

    public getAllActivities(tableQueryOptions: ActivityTableQueryOptions): IPromise<PageResponse<ActivityListItem>> {
        return this.getActivities(null, null, null, null, null, tableQueryOptions);
    }

    private getActivities(
        customerId: number, 
        bondId: number, 
        bondAccountId: number, 
        eproducerAccountId: number, 
        applicationId: number,
        tableQueryOptions: ActivityTableQueryOptions
    ){
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }
        
        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'createdDateTime desc';
        }

        let queryString = `?`;

        if (customerId) {
            queryString += `customerId=${customerId}`;
        }

        if (bondId) {
            queryString +=  `bondId=${bondId}`;
        }

        if (bondAccountId) {
            queryString += `bondAccountId=${bondAccountId}`;
        }

        if (eproducerAccountId) {
            queryString += `eProducerAccountId=${eproducerAccountId}`;
        }

        if (applicationId) {
            queryString += `applicationId=${applicationId}`;
        }

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.lookupCode) {
            queryString += `&lookupCode=${tableQueryOptions.lookupCode}`;
        }

        if (tableQueryOptions.createdByUserId) {
            queryString += `&createdByUserId=${tableQueryOptions.createdByUserId}`;
        }

        if (tableQueryOptions.assignedToUserId) {
            queryString += `&assignedToUserId=${tableQueryOptions.assignedToUserId}`;
        }
        
        if (tableQueryOptions.isFollowed || tableQueryOptions.isFollowed === false) {
            queryString += `&isFollowed=${tableQueryOptions.isFollowed}`;
        }

        if (tableQueryOptions.activityTypeId) {
            queryString += `&activityTypeId=${tableQueryOptions.activityTypeId}`;
        }

        if (tableQueryOptions.daysTillDue) {
            queryString += `&daysTillDue=${tableQueryOptions.daysTillDue}`;
        }

        if (tableQueryOptions.lowPriorityLevel) {
            queryString += `&lowPriorityLevel=${tableQueryOptions.lowPriorityLevel}`;
        }

        if (tableQueryOptions.highPriorityLevel) {
            queryString += `&highPriorityLevel=${tableQueryOptions.highPriorityLevel}`;
        }

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<ActivityListItem>>>(this.systemSettings.apiBaseUrl + 'ActivityActions/GetActivities' + queryString)
            .then((response) => response.data.value);
    }        

    public getAttachmentTypes(): IPromise<AttachmentType[]> {
        const url = `${this.systemSettings.apiBaseUrl}ActivityActions/GetAttachmentTypes`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<AttachmentType[]>>(url)
                .then((response) => response.data.value);
        });
    }
}

app.service(Injectables.ActivityService, ActivityService);
