import { BillingEntryService } from "../../../api/billingEntryService";
import { Injectables } from "../../../configuration/injectables";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { InvoiceService } from "../../../api/invoiceService";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { UserService } from "../../../api/userService";
import { ModalOpener } from "../../../modals/modalOpener";
import BillingEntriesListTable from "../../../components/billingEntriesList/billingEntriesListTable";

export class ReportBillingEntryController {
    public static $inject = [
        Injectables.BillingEntries,
        Injectables.InvoiceService,
        Injectables.IDebouceDelayer,
        Injectables.CurrentUserResolver,
        Injectables.ToastMessageCreator,
        Injectables.UserService,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly billingEntriesService: BillingEntryService,
        private readonly invoiceService: InvoiceService,
        debouceDelayer: IDebounceDelayer,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly toastMessageCreator: ToastMessageCreator,
        userService: UserService,
        modalOpener: ModalOpener
    ) {
        this.table = new BillingEntriesListTable(
            this.loadData,
            userService,
            toastMessageCreator,
            debouceDelayer,
            modalOpener
        );
    }

    public readonly table: BillingEntriesListTable;
    public busyIndicator: BusyIndicator;

    public save = () => {
        const request = {
            items: this.table.data.map((billingEntry) => {
                return {
                    id: billingEntry.id,
                    producerUserId: billingEntry.producerUserId,
                    productionCredit: billingEntry.productionCredit,
                    carrierPayablePaid: billingEntry.carrierPayablePaid,
                    brokerPayablePaid: billingEntry.brokerPayablePaid,
                    isProductionCreditPaid: billingEntry.isProductionCreditPaid
                }
            })
        };

        this.busyIndicator = {
            message: 'Saving...',
            promise: this.billingEntriesService.saveBillingEntryReport(request)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage('Billing entries saved successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred saving billing entries');
                })
        }
    }

    public refresh = () => {
        this.loadData();
    };

    public get canEditBillingEntries(): boolean {
        return this.currentUserResolver.getCurrentUser().permissions.canEditBillingEntriesReport;
    }

    public exportBillingEntries(){
        this.busyIndicator = {
            message: "Loading...",
            promise: this.billingEntriesService.downloadBillingEntriesExcelReport(this.table.getQueryOptions(), this.table.getSelectedColumnNames())
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred while trying to download');
                })
        };
    }
    
    public getInvoiceDownloadUrl(invoiceId: number): string {
        return this.invoiceService.getInvoiceDocumentUrl(invoiceId);
    }

    public loadData = () => {
        return this.billingEntriesService.getBillingEntriesReportData(this.table.getQueryOptions())
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);

                for(let i = 0; i < response.items.length; i++) {
                    response.items[i].carrierPayablePaid = response.items[i].carrierPayableAmountDue == 0;
                    response.items[i].brokerPayablePaid = response.items[i].brokerPayableAmountDue == 0;
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to load billing entries');
            });
    };

    public $onInit(): void {
        this.busyIndicator = {};
        this.table.load();
    }
}

