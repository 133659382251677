import { SelectOption } from "../../../../api/types/selectOption";
import app from "../../../../main";
import { BillingEntryReportItem } from "../billingEntryReportItem";
import { Injectables } from "../../../../configuration/injectables";
import { SystemAccountService } from "../../../../api/systemAccountService";
import { QueryBuilderTable } from "../../../../utilities/tables/queryBuilderTable";
import { SearchType } from "../../../../api/types/searchType";
import { QueryBuilderTableQueryOptions } from "../../../../api/types/queryBuilderTableOptions";

class BillingReportFilterDropdownController {
    public static $inject = [
        Injectables.SystemAccountService
    ];

    constructor( private readonly systemAccountService: SystemAccountService ) {
        this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public codeOptions: SelectOption<string>[];
    public producerUserOptions: SelectOption<number>[];  
    public clientServiceAssociateUserOptions: SelectOption<number>[];
    public clientServiceManagerUserOptions: SelectOption<number>[];
    public clientServiceExecutiveUserOptions: SelectOption<number>[];
    public modifiedFilters: QueryBuilderTableQueryOptions;

    public table: QueryBuilderTable<BillingEntryReportItem>;

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }
    
    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    }

    public $onInit() {
        this.codeOptions = [
            { label: 'Any', value: null },
            { label: 'New Business', value: 'NEWB'},
            { label: 'Renewal', value: 'RENB'},
            { label: 'Rider', value: 'ENDT'},
            { label: 'Cancellation', value: 'CANC'},
            { label: 'Reinstatement', value: 'REIN'},
            { label: 'Agency Fee', value: 'AFEE' },
            { label: 'Carrier Fee', value: 'CFEE' },
            { label: 'Tax/Surcharge (Carrier paid)', value: 'SRGX' },
        ];

        this.systemAccountService.getProducerUserOptions()
            .then((producerUserOptions) => {
                this.producerUserOptions = producerUserOptions;
            });

        this.systemAccountService.getClientServiceAgentUserOptions()
            .then((clientServiceAssociateUserOptions) => {
                this.clientServiceAssociateUserOptions = clientServiceAssociateUserOptions;
            });

        this.systemAccountService.getClientServiceManagerUserOptions()
            .then((clientServiceManagerUserOptions) => {
                this.clientServiceManagerUserOptions = clientServiceManagerUserOptions;
            });

        this.systemAccountService.getClientServiceExecutiveUserOptions()
            .then((ClientServiceExecutiveUserOptions) => {
                this.clientServiceExecutiveUserOptions = ClientServiceExecutiveUserOptions;
            });
        
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
    }
}

const billingEntryReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: BillingReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingEntryReportFilterDropdown.html"
};

app.component("billingEntryReportFilterDropdown",billingEntryReportFilterDropdownComponent);
