import { IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import app from "../../main";

export interface ICompareFloatsScope extends IScope {
    compareFloat: string;
    comparisonType: FloatComparisonTypes;
 }

 enum FloatComparisonTypes {
    lessThanOrEqual = 'lessThanOrEqual',
    greaterThanOrEqual = 'greaterThanOrEqual',
    greaterThan = 'greaterThan',
    lessThan = 'lessThan'
 }

const compareFloatsDirective = () => {
    const link = (
        scope: ICompareFloatsScope, 
        element: JQuery, 
        attrs: IAttributes, 
        ngModel: INgModelController
    ) => {

        if (!Object.values(FloatComparisonTypes).includes(scope.comparisonType)) {
            throw new Error('comparison-type attribute is not found or is invalid for compare-float directive');
        }
        
        ngModel.$validators.compareFloat = (modelValue) => {
            const otherValue = parseFloat(scope.compareFloat);
            const thisValue = parseFloat(modelValue);

            if (isNaN(thisValue) || isNaN(otherValue)) {
                return true;
            }
            
            switch (scope.comparisonType) {
                case FloatComparisonTypes.lessThanOrEqual:
                    return thisValue <= otherValue;
                case FloatComparisonTypes.greaterThanOrEqual:
                    return thisValue >= otherValue;
                case FloatComparisonTypes.greaterThan:
                    return thisValue > otherValue;
                case FloatComparisonTypes.lessThan:
                    return thisValue < otherValue;
                default:
                    throw new Error('comparison-type attribute is not found or is invalid for compare-float directive');
            }
        };

        scope.$watch('compareFloat', () => {
            ngModel.$validate();
        });
    }

    return {
        require: 'ngModel',
        scope: {
            compareFloat: '<',
            comparisonType: '@'
        },
        link: link
    };
}

app.directive('compareFloat', compareFloatsDirective as IDirectiveFactory);