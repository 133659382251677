import { SystemAccountService } from "../../../../api/systemAccountService";
import { SearchType } from "../../../../api/types/searchType";
import { SelectOption } from "../../../../api/types/selectOption";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import { QueryBuilderTable } from "../../../../utilities/tables/queryBuilderTable";
import { QueryBuilderTableQueryOptions } from "../../../../api/types/queryBuilderTableOptions";
import { PurchasedBondItem } from "../purchasedBondItem";
import { CurrentUserResolver } from "../../../../utilities/currentUserResolver/currentUserResolver";


class PurchasedBondsReportDropdownFilterController {
    
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        private readonly currentUserResolver: CurrentUserResolver
    ) {
        this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
        this.filterCount = 0;
    }

    public isOpen: boolean;
    public filterCount = 0;
   
    public modifiedFilters: QueryBuilderTableQueryOptions;
    public carrierOptions: SelectOption<number>[];
    public agencyOptions: SelectOption<number>[];
    public isCarrier: boolean;
    public table: QueryBuilderTable<PurchasedBondItem>;

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
        this.setModifiedFilters();
    }

    private loadCarrierOptions() {
        return this.systemAccountService.getAppointedCarrierOptions()
            .then((carrierOptions) => {
                this.carrierOptions = carrierOptions;
                this.carrierOptions.unshift({ label: 'Any', value: null });
            });
    }

    private loadAgencyOptions() {
        return this.systemAccountService.getAppointedAgencyOptions()
            .then((agencyOptions) => {
                this.agencyOptions = agencyOptions;
                this.agencyOptions.unshift({ label: 'Any', value: null});
            });
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    public $onInit() {
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
        
        if (this.isCarrier) {
            this.loadAgencyOptions();
        } else {
            this.loadCarrierOptions();
        }

    }
}

const purchasedBondsReportDropdownFilterComponent = {
    bindings: {
        table: "<"
    },
    controller: PurchasedBondsReportDropdownFilterController,
    templateUrl:"app/states/common/purchasedBonds/purchasedBondsReportDropdown/purchasedBondsReportDropdownFilter.html",
	controllerAs: "vm",
};

app.component(
    "purchasedBondsReportDropdownFilter",
    purchasedBondsReportDropdownFilterComponent
);
