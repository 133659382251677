import { IPromise, IIntervalService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalUploadOptions } from "./modalUploadOptions";
import { Modal } from "../../components/modals/modal";
import { UploaderItem } from "../../components/uploader/uploader";

export class UploadModalController {
    
    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options, 
        Injectables.$interval
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: ModalUploadOptions,
        private readonly $interval: IIntervalService) {
    }

    public stopInterval: IPromise<void>;
    public files: UploaderItem[];
    public closeDelayCounter = 0;
    public hasError: boolean;

    public get total(): number {
        let _total = 0;
        
        for(let i = 0; i < this.files.length; i++) {
            _total += this.files[i].totalBytes;
        }

        return _total;
    }

    public get sent(): number {
        let _sent = 0;
        
        for(let i = 0; i < this.files.length; i++) {
            _sent += this.files[i].bytesSent;
        }

        return _sent;
    }

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
        this.$interval.cancel(this.stopInterval);
    }

    public updateProgress = () => {
        for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].isError) {
                this.hasError = true;
                this.$interval.cancel(this.stopInterval);
                return;
            }

            if (this.files[i].uploadResponseReceived !== true) {
                return;
            }
        }

        // wait 1 additional second (10 x interval length of 100ms)
        this.closeDelayCounter++;
        
        if (this.closeDelayCounter >= 10) {
            this.$uibModalInstance.close();
            this.$interval.cancel(this.stopInterval);
        }
    };

    public $onInit = () => {
        this.files = this.options.files;
        this.stopInterval = this.$interval(this.updateProgress, 100);
    }
}

app.controller('UploadModalController', UploadModalController);