import { ApplicationService } from "../../api/applicationService";
import { PaymentService } from "../../api/paymentService";
import { SystemAccountService } from "../../api/systemAccountService";
import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { Payment } from "../../api/types/payment";
import { CreditCardFeeRequest } from "../../api/types/payments/creditCardFeeRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { UtilityService } from "../../utilities/utilityService";
import { PurchaseQuoteOptions } from "./purchaseQuoteModalOptions";
import { PurchaseQuoteResult } from "./PurchaseQuoteResult";
import app from "../../main";
import { IQService, IPromise } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { QuoteForPurchaseModal } from "../../api/types/quoteForPurchase";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { QuotePurchaseRequest } from "../../api/types/quotePurchaseRequest";
import { PaymentContact } from "../../api/types/paymentContact";
import { BillToType } from "../../api/types/billToType";
import { CustomersService } from "../../api/customerService";
import BillingEntry from "./billingEntry";

export class PurchaseQuoteModalController {

    public static $inject = [
        Injectables.PaymentService,
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.SystemAccountService,
        Injectables.ApplicationService,
        Injectables.CurrentUserResolver,
        Injectables.$q,
        Injectables.UtilityService,
        Injectables.SystemSettings,
        Injectables.ToastMessageCreator,
        Injectables.CustomersService
    ];

    constructor(
        private readonly paymentService: PaymentService,
        private readonly $uibModalInstance: Modal<PurchaseQuoteResult>,
        private readonly options: PurchaseQuoteOptions,
        private readonly systemAccountService: SystemAccountService,
        private readonly applicationService: ApplicationService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly $q: IQService,
        private readonly utilityService: UtilityService,
        private readonly systemSettings: SystemSettings,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly customerService: CustomersService
    ){}

    public quote: QuoteForPurchaseModal;
    public totalPaymentAmount: number;
    public expeditedFee: number;
    public payment: Payment;
    public busyIndicator: BusyIndicator;
    public errorMessage: string;
    public creditCardExpirationMonth: SelectOption<string>[];
    public creditCardExpirationYear: SelectOption<string>[];
    public paymentMethods: SelectOption<PaymentMethod>[];
    public processPayment: boolean;
    public customerPaymentContacts: PaymentContact[];
    public brokerPaymentContacts: PaymentContact[];
    public customerPaymentContactOptions: SelectOption<number>[];
    public brokerPaymentContactOptions: SelectOption<number>[];
    public selectedCustomerPaymentContactIndex: number;
    public selectedBrokerPaymentContactIndex: number;
    public bondNumberGroupOptions: SelectOption<number>[];
    public billingEntries: BillingEntry[];
    public carrierPayableBillingEntries: BillingEntry[];
    public brokerPayableBillingEntries: BillingEntry[];
    public achAccountTypeOptions: SelectOption<string>[];

    public getPaymentMethods(): IPromise<void> {
        return this.systemAccountService.getPaymentMethods()
            .then(paymentMethods => {
                
                this.paymentMethods = paymentMethods;

                this.payment = {
                    paymentMethod: this.paymentMethods.length > 0 ? this.paymentMethods[0].value : null,
                    processingFee: null,
                    systemAccountId: this.currentUserResolver.getCurrentUser().user.systemAccountId,
                    amount: this.quote.totalAmountDue
                } as Payment;

                if (this.systemSettings.environment === 'Dev') {
                    this.payment.paymentMethod =  PaymentMethod.CreditCard,
                    this.payment.cardNumber = '4111111111111111',
                    this.payment.expirationMonth = '12',
                    this.payment.expirationYear = '2031',
                    this.payment.securityCode = '123'
                }

                return this.checkForPaymentTransactionFees();
            });
    }

    public getExpeditedProcessingFee(): IPromise<void>  {
        return this.systemAccountService.getExpeditedProcessingFee(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then(result => { 
                this.expeditedFee = result;
            });
    }

    public getQuote(quoteId: number): IPromise<void> {
        return this.applicationService.getQuoteForPurchase(quoteId)
            .then((quote) => {
                this.quote = quote;
                this.setBillingEntries();

                return this.systemAccountService.getBondNumberGroups(this.quote.carrierSystemAccountId);
            })
            .then(bondNumberGroups => {
                this.bondNumberGroupOptions = bondNumberGroups.map(bondNumberGroup => {
                    return {
                        label: bondNumberGroup.name,
                        value: bondNumberGroup.id
                    }
                });
            })
            .then(() => {
                return this.loadPaymentContacts();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to get information about this quote');
            });
    }

    private setBillingEntries() {
        if (!this.quote.billToType) {
            this.quote.billToType = BillToType.BillClient;
        }

        switch (this.quote.billToType) {
            case BillToType.BillClient:
                this.billingEntries = this.quote.clientBillingEntries;
                this.brokerPayableBillingEntries = this.quote.clientBillingEntries.filter(billingEntry => billingEntry.brokerPayableAmountDue);
                this.carrierPayableBillingEntries = this.quote.clientBillingEntries.filter(billingEntry => billingEntry.carrierPayableAmountDue);
                break;
            case BillToType.BillBrokerNet:
                this.billingEntries = this.quote.brokerBillNetBillingEntries;
                this.brokerPayableBillingEntries = this.quote.brokerBillNetBillingEntries.filter(billingEntry => billingEntry.brokerPayableAmountDue);
                this.carrierPayableBillingEntries = this.quote.brokerBillNetBillingEntries.filter(billingEntry => billingEntry.carrierPayableAmountDue);
                break;
            case BillToType.BillBrokerGross:
                this.billingEntries = this.quote.brokerBillGrossBillingEntries; 
                this.brokerPayableBillingEntries = this.quote.brokerBillGrossBillingEntries.filter(billingEntry => billingEntry.brokerPayableAmountDue);
                this.carrierPayableBillingEntries = this.quote.brokerBillGrossBillingEntries.filter(billingEntry => billingEntry.carrierPayableAmountDue);
                break;
        }

        this.setTotalAmountDue();
    }

    public billToTypeChanged() {
        const promises = [
            this.loadPaymentContacts(),
            this.checkForPaymentTransactionFees()
        ];

        this.busyIndicator.promise = this.$q.all(promises);

        this.setBillingEntries();
    }

    public loadPaymentContacts() {
        if (this.quote.eProducerAccountId && this.quote.billToType == BillToType.BillBrokerGross || this.quote.billToType == BillToType.BillBrokerNet) {
            if (!this.brokerPaymentContacts) {
                return this.customerService.getPaymentContacts(null, this.quote.eProducerAccountId)
                    .then(paymentContacts => {
                        this.brokerPaymentContacts = paymentContacts;

                        this.brokerPaymentContacts.push({
                            displayName: 'Other'
                        } as PaymentContact);
        
                        this.brokerPaymentContactOptions = this.brokerPaymentContacts.map((name, index) => {
                            return {
                                label: name.displayName,
                                value: index
                            }
                        });
                    });
            }
        } else {
            if (!this.customerPaymentContacts) {
                return this.customerService.getPaymentContacts(this.quote.customerId, null)
                    .then(paymentContacts => {
                        this.customerPaymentContacts = paymentContacts;
                        
                        this.customerPaymentContacts.push({
                            displayName: 'Other'
                        } as PaymentContact);
        
                        this.customerPaymentContactOptions = this.customerPaymentContacts.map((name, index) => {
                            return {
                                label: name.displayName,
                                value: index
                            }
                        });
                    });
            }
        }
    }

    public paymentContactSelected() {
        let selectedContact;
        
        if (this.quote.billToType == BillToType.BillBrokerGross || this.quote.billToType == BillToType.BillBrokerNet) {
            selectedContact = this.brokerPaymentContacts[this.selectedBrokerPaymentContactIndex];
        } else {
            selectedContact = this.customerPaymentContacts[this.selectedCustomerPaymentContactIndex];
        }

        if (selectedContact.isCompany) {
            this.payment.companyName = selectedContact.displayName;
        } else {
            this.payment.companyName = null;
        }

        this.payment.firstName = selectedContact.firstName;
        this.payment.phone = selectedContact.cellPhone ? selectedContact.cellPhone : selectedContact.homePhone;
        this.payment.lastName = selectedContact.lastName;
        this.payment.address = selectedContact.physicalAddress;
        this.payment.suiteAptNumber = selectedContact.physicalSuiteAptNumber;
        this.payment.zip = selectedContact.physicalZip;
        this.payment.city = selectedContact.physicalCity;
        this.payment.state = selectedContact.physicalState;
        this.payment.email = selectedContact.email;
    }

    public expeditedChanged(): void {
        this.setTotalAmountDue();
    }

    private setTotalAmountDue() {

        let totalAmountDue = 0;
        
        if (this.quote.expedited) {
            totalAmountDue += this.expeditedFee;
        }

        if (this.billingEntries?.length) {
            for (let i = 0; this.billingEntries.length > i; i++) {
                totalAmountDue += this.billingEntries[i].amountDue;
            }
        }

        this.quote.totalAmountDue = totalAmountDue;
        this.payment.amount = this.quote.totalAmountDue;
        this.checkForPaymentTransactionFees();
    }

    public purchase(): void {

        this.payment.amount = this.quote.totalAmountDue;
        
        const quotePurchaseRequest = {
            quoteId: this.quote.id,
            quoteType: this.quote.quoteType,
            payment: this.processPayment ? this.payment : null,
            billToType: this.quote.billToType,
            effectiveDate: this.quote.effectiveDate,
            expirationDate: this.quote.expirationDate,
            bondNumber: this.quote.bondNumber,
            bondNumberGroupId: this.quote.bondNumberGroupId,
            expedited: this.quote.expedited
        } as QuotePurchaseRequest;

        this.busyIndicator.message = 'Processing...';
        this.busyIndicator.promise = this.applicationService.purchaseQuote(quotePurchaseRequest)
            .then((response) => {
                this.toastMessageCreator.createSuccessMessage('Bond was processed successfully');
                this.$uibModalInstance.close(new PurchaseQuoteResult(response.bondId));
            })
            .catch((response) => {
                if (!response.message) {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to purchase this quote');
                } else {
                    this.errorMessage = response.message;
                }
            });
    }

    private calculateTotal(): void {
        this.totalPaymentAmount = this.payment.amount + this.payment.processingFee;
    }

    public checkForPaymentTransactionFees() {
        /*
        Payment transaction fees are assessed by XPress Pay and must be retrieved before submitting the payment.
        We only use XPress Pay for testing purposes and not in production
        */
        if (this.systemSettings.environment === 'Prod' ||
            !this.payment || 
            !this.payment.amount || 
            !this.processPayment || 
            this.payment.paymentMethod !== PaymentMethod.CreditCard) {

            return this.$q.when();
        }

        const request: CreditCardFeeRequest = {
            payment: this.payment
        };

        return this.paymentService.getPaymentTransactionFees(request)
            .then((response) => {
                if (response.success) {
                    this.payment.processingFee = response.fee;
                    this.calculateTotal();
                } else {
                    this.errorMessage = response.message;
                }
            });
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public $onInit(): void {
        this.billingEntries = [];
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.processPayment = true;
        this.expeditedFee = 0;

        this.achAccountTypeOptions = this.paymentService.getAchAccountTypeOptions();

        this.creditCardExpirationMonth = this.utilityService.creditCardMonthOptions;
        this.creditCardExpirationYear = this.utilityService.creditCardYearOptions;

        this.busyIndicator.promise = this.getQuote(this.options.quoteId)
            .then(() => this.getPaymentMethods())
            .then(() => this.getExpeditedProcessingFee());
    }
}

app.controller('PurchaseQuoteModalController', PurchaseQuoteModalController);
