import { DocumentService } from "../../../api/documentService";
import { ODataEndpoint } from "../../../api/odata";
import { SystemAccountService } from "../../../api/systemAccountService";
import { CancellationReason } from "../../../api/types/model/cancellationReason";
import { DocumentCategory } from "../../../api/types/model/documentCategory";
import { ReinstatementReason } from "../../../api/types/model/reinstatementReason";
import { RiderReason } from "../../../api/types/model/riderReason";
import { SystemAccount } from "../../../api/types/model/systemAccount";
import { SystemAccountContact } from "../../../api/types/model/systemAccountContact";
import { SystemAccountDocument } from "../../../api/types/model/systemAccountDocument";
import { WritingCompany } from "../../../api/types/model/writingCompany";
import { DocumentPicker } from "../../../components/documentPicker/documentPicker";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { Document } from "../../../api/types/model/document";
import { IPromise, IQService } from "angular";
import * as angular from "angular";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { UploadControls, UploaderItem } from "../../../components/uploader/uploader";


export class CarrierProfileController {

    public static $inject = [
        Injectables.$q,
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
        Injectables.ModalOpener,
        Injectables.DocumentService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $q: IQService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener,
        private readonly documentService: DocumentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings) { 

    }

    public get lgLogo(): File {
        if (!this.largeLogoFiles) {
            return null;
        } 

        return this.largeLogoFiles[0]?.file;
    }
    
    public get smLogo(): File {
        if (!this.smallLogoFiles) {
            return null;
        }

        return this.smallLogoFiles[0]?.file;
    }

    public carrier:  SystemAccount;
    public largeLogoUploader: UploadControls;
    public smallLogoUploader: UploadControls;
    public largeLogoFiles: UploaderItem[];
    public smallLogoFiles: UploaderItem[];
    public lgLogoPicturePath: string;
    public smLogoPicturePath: string;
    public loadingPromise: IPromise<any>;
    public riderReasonsInValid: boolean;
    public cancellationReasonsInvalid: boolean;
    public reinstatementReason:  ReinstatementReason;
    public riderReason:  RiderReason;
    public cancellationReason:  CancellationReason;
    public adverseActionNotificationDocumentCategory:  DocumentCategory;
    public continuationCertificateDocumentCategory:  DocumentCategory;
    public verificationCertificateDocumentCategory:  DocumentCategory;
    public adverseActionNotificationPicker: DocumentPicker;
    public continuationCertificatePicker: DocumentPicker;
    public verificationCertificatePicker: DocumentPicker;
    public carrierAdverseActionDocuments:  Document[];
    public carrierContinuationCertificateDocuments:  Document[];
    public carrierVerificationCertificateDocuments:  Document[];
    public adverseActionFilesToUpload: UploaderItem[];
    public continuationCertificatesToUpload: UploaderItem[];
    public verificationCertificatesToUpload: UploaderItem[];

    public removeLargeLogoUpload = () => {
        this.largeLogoUploader.removeFile(0);
    }

    public removeSmallLogoUpload = () => {
        this.smallLogoUploader.removeFile(0);
    }

    public addCancellationReason(): void {
        if (!this.cancellationReason || !this.cancellationReason.code || !this.cancellationReason.description) {
            return;
        }

        this.carrier.cancellationReasons.push({ code: this.cancellationReason.code, description: this.cancellationReason.description } as  CancellationReason);

        delete this.cancellationReason.code;
        delete this.cancellationReason.description;
    }

    public addReinstatementReason(): void {
        if (!this.reinstatementReason || !this.reinstatementReason.code || !this.reinstatementReason.description) {
            return;
        }

        this.carrier.reinstatementReasons.push({ code: this.reinstatementReason.code, description: this.reinstatementReason.description } as  ReinstatementReason);

        delete this.reinstatementReason.code;
        delete this.reinstatementReason.description;
    }

    public addRiderReason(): void {
        if (!this.riderReason || !this.riderReason.code || !this.riderReason.description) {
            return;
        }

        this.carrier.riderReasons.push({ code: this.riderReason.code, description: this.riderReason.description } as  RiderReason);

        delete this.riderReason.code;
        delete this.riderReason.description;
    }

    public deleteContact(contact:  SystemAccountContact, $index: number): void {
        if (!contact) {
            throw new Error('contact is not valid');
        }
        if ($index === undefined || $index < 0) {
            throw new Error('$index is not valid');
        }

        this.modalOpener.deleteConfirmationModal('Delete Contact',
            'You sure you want to delete <strong>' + contact.name + '</strong> from contacts?',
            contact.id,
            ODataEndpoint.SystemAccountContact,
            null,
            'Contact deleted successfully',
            'Error deleting contact')
            .result
            .then(() => {
                this.carrier.systemAccountContacts.splice($index, 1);
            })
            .catch(() => {});
    }

    public deleteWritingCompany(company:  WritingCompany, $index: number): void {
        if (!company) {
            throw new Error('company is not valid');
        }
        if ($index === undefined || $index < 0) {
            throw new Error('$index is not valid');
        }

        this.modalOpener.deleteConfirmationModal(
            'Delete Company',
            'You sure you want to delete <strong>' + company.name + '</strong>?',
            company.id,
            ODataEndpoint.WritingCompany,
            null,
            'Writing company deleted successfully',
            'Error deleting Writing company')
                .result
                .then(() => {
                    this.carrier.writingCompanies.splice($index, 1);
                })
                .catch(() => {});
    }

    public removeCancellationReason($index: number): void {
        this.carrier.cancellationReasons.splice($index, 1);
    }

    public removeReinstatementReason($index: number): void {
        this.carrier.reinstatementReasons.splice($index, 1);
    }

    public removeRiderReason($index: number): void {
        this.carrier.riderReasons.splice($index, 1);
    }

    public save_click(): void {
        var filesToUpload = [
            ...this.largeLogoUploader.getFiles(),
            ...this.smallLogoUploader.getFiles()
        ];

        if (filesToUpload.length) {
            this.modalOpener.uploadModal(filesToUpload)
                .result
                .then(() => {})
                .catch(() => {});

            const promises = [];

            if (this.largeLogoUploader.getFiles().length) {
                promises.push(this.largeLogoUploader.uploadAll()
                    .then(uploadedItems => {
                        this.carrier.largeLogoFileId = uploadedItems[0].response[0];
                    }));
            }

            if (this.smallLogoUploader.getFiles().length) {
                promises.push(this.smallLogoUploader.uploadAll()
                    .then(uploadedItems => {
                        this.carrier.thumbnailLogoFileId = uploadedItems[0].response[0];
                    }));
            }

            this.$q.all(promises)
                .then(() => {
                    this.submit();
                })
        } else {
            this.submit();
        }
    }

    public saveIndemnity(): void {
        this.loadingPromise = this.systemAccountService.patchCarrier(this.carrier.id, { indemnityLanguage: this.carrier.indemnityLanguage } as  SystemAccount);

        this.loadingPromise
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Indemnity language saved successfully');
            });
    }

    public saveSystemSettings(): void {
        if (!this.carrier.cancellationReasons.length) {
            this.cancellationReasonsInvalid = true;
            return;
        }

        if (!this.carrier.riderReasons.length) {
            this.riderReasonsInValid = true;
            return;
        }

        this.cancellationReasonsInvalid = false;
        this.riderReasonsInValid = false;

        this.loadingPromise = this.systemAccountService.saveCarrierSystemAccountSystemSettings(this.carrier);

        this.loadingPromise
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('System settings saved successfully');
            });
    }

    public setLogoPicture(clearCache: boolean = false): IPromise<void[]> {
        const promises = [] as IPromise<void>[];

        if (this.carrier.largeLogoFileId) {
            promises.push(this.systemAccountService.getLargeLogo(this.carrier.id, clearCache)
                .then((imgData) => {
                    this.lgLogoPicturePath = imgData;
                }));
        }

        if (this.carrier.thumbnailLogoFileId) {
            promises.push(this.systemAccountService.getSmallLogo(this.carrier.id, clearCache)
                .then((imgData) => {
                    this.smLogoPicturePath = imgData;
                }));
        }

        return this.$q.all(promises);
    }

    public showContactModal(contact: SystemAccountContact, $index: number): void {
        contact = contact || { systemAccountId: this.carrier.id } as SystemAccountContact;

        this.modalOpener.contactModal(angular.copy(contact))
            .result
            .then((savedContact: SystemAccountContact) => {
                if ($index >= 0) {
                    savedContact.$index = $index;
                    this.carrier.systemAccountContacts[$index] = savedContact;
                } else {
                    this.carrier.systemAccountContacts.push(savedContact);
                }
            })
            .catch(() => {});
    }

    public showWritingCompanyModal(company: WritingCompany, $index: number): void {
        company = company || { systemAccountId: this.carrier.id } as WritingCompany;
        company.$index = $index;

        this.modalOpener.showWritingCompanyModal(angular.copy(company))
            .result
            .then((savedCompany: WritingCompany) => {

                if (company.$index >= 0) {
                    this.carrier.writingCompanies[company.$index] = savedCompany;
                } else {
                    this.carrier.writingCompanies.push(savedCompany);
                }
            })
            .catch(() => {});
    }
    
    public submit(): void {

        const carrier = {
            id: this.carrier.id,
            companyName: this.carrier.companyName,
            description: this.carrier.description,
            email: this.carrier.email,
            fein: this.carrier.fein,
            largeLogoFileId: this.carrier.largeLogoFileId,
            legalName: this.carrier.legalName,
            mailAddress: this.carrier.mailAddress,
            mailCity: this.carrier.mailCity,
            mailCounty: this.carrier.mailCounty,
            mailState: this.carrier.mailState,
            mailSuiteAptNumber: this.carrier.mailSuiteAptNumber,
            mailZip: this.carrier.mailZip,
            phone: this.carrier.phone,
            physicalAddress: this.carrier.physicalAddress,
            physicalCity: this.carrier.physicalCity,
            physicalCounty: this.carrier.physicalCounty,
            physicalState: this.carrier.physicalState,
            physicalSuiteAptNumber: this.carrier.physicalSuiteAptNumber,
            claimsPhone: this.carrier.claimsPhone,
            claimsFax: this.carrier.claimsFax,
            claimsEmail: this.carrier.claimsEmail,
            claimsMailAddress: this.carrier.claimsMailAddress,
            claimsMailSuiteAptNumber: this.carrier.claimsMailSuiteAptNumber,
            claimsMailZip: this.carrier.claimsMailZip,
            claimsMailCity: this.carrier.claimsMailCity,
            claimsMailState: this.carrier.claimsMailState,
            paymentMailAddress: this.carrier.paymentMailAddress,
            paymentMailSuiteAptNumber: this.carrier.paymentMailSuiteAptNumber,
            paymentMailZip: this.carrier.paymentMailZip,
            paymentMailCity: this.carrier.paymentMailCity,
            paymentMailState: this.carrier.paymentMailState,
            paymentMailCounty: this.carrier.paymentMailCounty,
            paymentMadeOutToName: this.carrier.paymentMadeOutToName,
            paymentEmail: this.carrier.paymentEmail,
            paymentPhone: this.carrier.paymentPhone,
            physicalZip: this.carrier.physicalZip,
            stateOfIncorporation: this.carrier.stateOfIncorporation,
            thumbnailLogoFileId: this.carrier.thumbnailLogoFileId,
            websiteURL: this.carrier.websiteURL
        } as SystemAccount;
        
        this.loadingPromise = this.systemAccountService.patchCarrier(this.carrier.id, carrier)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your company information was saved successfully');
                this.setLogoPicture(true);
            }).catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to save your company information');
            });
    }

    private mapCarrierDocuments(): void {
        this.carrierAdverseActionDocuments = this.carrier.systemAccountDocuments
            .filter((d) => d.document.documentCategoryId === this.adverseActionNotificationDocumentCategory.id)
            .map((d) => d.document);

        this.carrierContinuationCertificateDocuments = this.carrier.systemAccountDocuments
            .filter((d) => d.document.documentCategoryId === this.continuationCertificateDocumentCategory.id)
            .map((d) => d.document);

        this.carrierVerificationCertificateDocuments = this.carrier.systemAccountDocuments
            .filter((d) => d.document.documentCategoryId === this.verificationCertificateDocumentCategory.id)
            .map((d) => d.document);
    }

    public documentSelected(selectedDocument:  Document): void {
        this.carrier.systemAccountDocuments.push({
            documentId: selectedDocument.id,
            systemAccountId: this.carrier.id,
            document: {
                ...selectedDocument
            }
        } as  SystemAccountDocument);

        this.mapCarrierDocuments();
    }

    public documentRemoved(documentId: number): void {
        this.removeSystemAccountDocument(documentId);

        this.mapCarrierDocuments();
    }

    private removeSystemAccountDocument(documentId: number): void {
        const index = this.carrier.systemAccountDocuments.findIndex((d) => d.documentId === documentId);

        this.carrier.systemAccountDocuments.splice(index, 1);
    }

    public saveDocuments(): void {

        const filesToUpload = [
            ...this.adverseActionFilesToUpload,
            ...this.continuationCertificatesToUpload,
            ...this.verificationCertificatesToUpload
        ];

        if (filesToUpload.length > 0) {
            this.modalOpener.uploadModal(filesToUpload)
                .result
                .then(() => { })
                .catch(() => { });
        }

        Promise.all([
            this.adverseActionNotificationPicker.save()
                .then(uploadedItems => {
                    this.addUploadedFiles(uploadedItems, this.adverseActionNotificationDocumentCategory.id);
                }),
            this.continuationCertificatePicker.save()
                .then(uploadedItems => {
                    this.addUploadedFiles(uploadedItems, this.continuationCertificateDocumentCategory.id);
                }),
            this.verificationCertificatePicker.save()
                .then(uploadedItems => {
                    this.addUploadedFiles(uploadedItems, this.verificationCertificateDocumentCategory.id);
                })
        ])
        .then(() => {
            this.uploadsCompleted();
        })
        .catch(() => {});        
    }

    public uploadsCompleted(): void {       
        this.mapCarrierDocuments();
        this.loadingPromise = this.systemAccountService.saveCarrierDocuments(this.carrier)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Your documents were saved successfully');
            });
    }

    private addUploadedFiles = (uploaderItem: UploaderItem[], documentCategoryId: number) => {
        uploaderItem.forEach(uploaderItem => {
            this.carrier.systemAccountDocuments.push({
                documentId: uploaderItem.response,
                systemAccountId: this.carrier.id,
                document: {
                    id: uploaderItem.response,
                    name: uploaderItem.file.name,
                    documentCategoryId: documentCategoryId
                }
            } as  SystemAccountDocument); 
        });
    }

    private loadCarrier(): IPromise<void> {
        return this.systemAccountService
            .loadCarrierData(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then((account) => {
                this.carrier = account;
                this.mapCarrierDocuments();
            });
    }

    private loadDocumentCategories(): IPromise<void> {
        return this.documentService
            .getDocumentCategories()
            .then((categories) => {
                const adverseActionCategory = categories.find((c) => c.name === 'Adverse Action Notification');
                const continuationCertificateCategory = categories.find((c) => c.name === 'Continuation Certificate');
                const verificationCertificateCategory = categories.find((c) => c.name === 'Verification Certificate');

                if (adverseActionCategory) {
                    this.adverseActionNotificationDocumentCategory = adverseActionCategory;
                }

                if (continuationCertificateCategory) {
                    this.continuationCertificateDocumentCategory = continuationCertificateCategory;
                }

                if (verificationCertificateCategory) {
                    this.verificationCertificateDocumentCategory = verificationCertificateCategory;
                }
            });
    }

    public $onInit(): void {
        this.cancellationReason = {} as  CancellationReason;
        this.riderReason = {} as  RiderReason;
        this.reinstatementReason = {} as  ReinstatementReason;
        this.carrier = { systemAccountContacts: [], writingCompanies: [], cancellationReasons: [], riderReasons: [], reinstatementReasons: [] } as  SystemAccount;
        
        this.loadingPromise = this.loadDocumentCategories()
            .then(() => this.loadCarrier())
            .then(() => this.setLogoPicture());
    }
}
