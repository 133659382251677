import { IPromise, ISCEService, ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import { Modal } from "../../components/modals/modal";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { EmailTemplateService } from "../../api/emailTemplateService";
import { EmailTemplate } from "../../api/types/model/emailTemplate";

export class EmailTemplateDetailModal {
    
    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options,
        Injectables.ToastMessageCreator,
        Injectables.EmailTemplateService,
        Injectables.$sce,
        Injectables.$timeout,
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: {id?: number},
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly emailTemplateService: EmailTemplateService,
        private readonly $sce: ISCEService,
        private readonly $timeout: ITimeoutService,)
    {
    }

    public templateId?: number;
    public template: EmailTemplate;
    public busyIndicator: BusyIndicator;
    public previewBuilderTimeout: IPromise<any>;

    public setPreview() {
        if (this.previewBuilderTimeout) {
            this.$timeout.cancel(this.previewBuilderTimeout);
        }

        if (!this.template.$callToActionText) {
            this.template.$callToActionText = 'Click Here';
        }

        this.previewBuilderTimeout = this.$timeout(() => this.buildPreview(), 750);
    }

    public buildPreview() {
        this.template.$preview = this.$sce.trustAsHtml(
                this.template.$templateHeader +
                this.emailTemplateService.populateEmailDefaultPlaceholders(this.template.$templateEditableBody) +
                (this.template.$includeCallToAction ? this.emailTemplateService.getDefaultCallToActionForPreview(this.template.$callToActionText) : '') +
                this.template.$templateFooter);
    }

    public save()
    {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.emailTemplateService.save(this.template)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('The email template has been saved');    
                this.$uibModalInstance.close();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the email template');
            })
    }

    public loadData() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.emailTemplateService.getById(this.templateId)
            .then(template => {
                this.template = template;

                this.emailTemplateService.parseHtmlBodyToVmParts(this.template);
                this.buildPreview();
            });
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public $onInit = () => {
        this.templateId = this.options.id;
        this.busyIndicator = {};

        if (this.templateId) {
            this.loadData();
        } else {
            this.template = {
                $templateHeader: this.emailTemplateService.getDefaultHeaderForPreview(),
                $templateEditableBody: '',
                $templateFooter: this.emailTemplateService.getDefaultFooterForPreview()
            } as EmailTemplate;

            this.setPreview();
        }
    }
}
