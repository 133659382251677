import { ActivityService } from "../../../api/activityService";
import { ApplicationService } from "../../../api/applicationService";
import { AttachmentService } from "../../../api/attachmentService";
import { BondService } from "../../../api/bondService";
import { BondTypeService } from "../../../api/bondTypeService";
import { EProducerService } from "../../../api/eProducerService";
import { InvoiceService } from "../../../api/invoiceService";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BillToType } from "../../../api/types/billToType";
import { EProducerBrandingSettings } from "../../../api/types/eProducerBrandingSettings";
import { BondStatus } from "../../../api/types/model/bond";
import { BondType } from "../../../api/types/model/bondType";
import { CommissionCalculationType } from "../../../api/types/model/eProducerAccount";
import { EProducerUser } from "../../../api/types/model/eProducerUser";
import { EProducerAccountFavoriteBondTypeConfiguration } from "../../../api/types/model/favoriteBondTypeConfiguration";
import { SelectOption } from "../../../api/types/selectOption";
import { ActivityListItem } from "../../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../../components/activityList/activityTableQueryOptions";
import { AttachmentTableListItem } from "../../../components/attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../../../components/attachmentList/attachmentTableQueryOptions";
import { BondListTableQueryOptions } from "../../../components/bondList/bondListTableQueryOptions";
import { BondListItem } from "../../../components/bondListFilterDropdown/bondListItem";
import { InvoiceListItem } from "../../../components/invoiceList/invoiceListItem";
import { InvoiceTableQueryOptions } from "../../../components/invoiceList/invoiceTableQueryOptions";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { SearchControl } from "../../../utilities/searchControl";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { IQService, IPromise, IDeferred } from "angular";
import { SystemSettings } from "../../../configuration/settings/systemSettings";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { InvoiceStatus } from "../../../api/types/model/invoiceStatus";
import ApplicationListTableQueryOptions from "../../../components/applicationList/applicationListQueryOptions";
import ApplicationListItem from "../../../components/applicationList/applicationListItem";
import { PaymentService } from "../../../api/paymentService";
import { PaymentListItem } from "../../../components/paymentList/paymentListItem";
import PaymentTableQueryOptions from "../../../api/types/payments/paymentTableQueryOptions";
import { PaymentStatus } from "../../../api/types/payments/paymentStatus";
import { BillingEntryService } from "../../../api/billingEntryService";
import { NewInvoiceRequest } from "../../../api/types/invoices/newInvoiceRequest";
import { Person } from "../../../api/types/model/person";
import { CompanyType } from "../../../api/types/model/company";
import BillingEntriesListTable from "../../../components/billingEntriesList/billingEntriesListTable";
import { UserService } from "../../../api/userService";
import { UploadControls, UploaderItem } from "../../../components/uploader/uploader";

export class EProducerAccountsDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.EProducerService,
        Injectables.ModalOpener,
        Injectables.ApplicationService,
        Injectables.$q,
        Injectables.BondTypeService,
        Injectables.SystemAccountService,
        Injectables.BondService,
        Injectables.IDebouceDelayer,
        Injectables.InvoiceService,
        Injectables.ActivityService,
        Injectables.AttachmentService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings,
        Injectables.PaymentService,
        Injectables.BillingEntries,
        Injectables.UserService
    ];

    constructor(
        private readonly $stateParams: EProducerAccountDetailStateParams,
        private readonly eProducerService: EProducerService,
        private readonly modalOpener: ModalOpener,
        private readonly applicationService: ApplicationService,
        private readonly $q: IQService,
        private readonly bondTypeService: BondTypeService,
        private readonly systemAccountService: SystemAccountService,
        private readonly bondService: BondService,
        debouceDelayer: IDebounceDelayer,
        private readonly invoiceService: InvoiceService,
        private readonly activityService: ActivityService,
        private readonly attachmentService: AttachmentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings,
        private readonly paymentService: PaymentService,
        private readonly billingEntriesService: BillingEntryService,
        userService: UserService
    ) {
        this.applicationsTable = new Table(debouceDelayer);
        this.applicationsTable.queryOptions.status = "Open";
        this.applicationsTable.onChange = this.loadApplications;

        this.bondsTable = new Table(debouceDelayer);
        this.bondsTable.queryOptions.status = BondStatus.Active;
        this.bondsTable.onChange = this.loadBonds;
        
        this.billingEntriesTable = new BillingEntriesListTable(
            this.loadBillingEntries,
            userService,
            toastMessageCreator,
            debouceDelayer,
            modalOpener
        );

        this.invoiceTable = new Table(debouceDelayer);
        this.invoiceTable.queryOptions.status = InvoiceStatus.Open;
        this.invoiceTable.onChange = this.loadInvoices;
        
        this.paymentsTable = new Table(debouceDelayer);
        this.paymentsTable.queryOptions.paymentStatus = PaymentStatus.Approved;
        this.paymentsTable.onChange = this.loadPayments;

        this.activitiesTable = new Table(debouceDelayer);
        this.activitiesTable.onChange = this.loadActivities;

        this.attachmentsTable = new Table(debouceDelayer);
        this.attachmentsTable.onChange = this.loadAttachments;
    }

    public readonly applicationsTable: Table<ApplicationListItem, ApplicationListTableQueryOptions>;
    public readonly bondsTable: Table<BondListItem, BondListTableQueryOptions>;
    public readonly invoiceTable: Table<InvoiceListItem, InvoiceTableQueryOptions>;
    public readonly billingEntriesTable: BillingEntriesListTable; 
    public readonly paymentsTable: Table<PaymentListItem, PaymentTableQueryOptions>;
    public readonly activitiesTable: Table<ActivityListItem, ActivityTableQueryOptions>;
    public readonly attachmentsTable: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;

    public account: BrokerDetail;
    public accountId: number;
    public busyIndicator: BusyIndicator;
    public settingsLoadingIndicator: BusyIndicator;
    public balanceCardBusyIndicator: BusyIndicator;
    
    private settingsLoaded: boolean;

    public contacts: Person[];
    public contactsBusyIndicator: BusyIndicator;

    public attachmentsBusyIndicator: BusyIndicator;
    public attachmentsLoaded: boolean;

    public invoicesLoaded: boolean;
    public invoicesBusyIndicator: BusyIndicator;

    public applicationsLoaded: boolean;
    public applicationBusyIndicator: BusyIndicator;

    public paymentsBusyIndicator: BusyIndicator;
    public paymentsLoaded: boolean;

    public bondsBusyIndicator: BusyIndicator;
    public bondsLoaded: boolean;
    
    public users: EProducerUser[];

    public branding: EProducerBrandingSettings;

    public get lgLogo(): File {
        if (!this.largeLogoFiles || !this.largeLogoFiles[0]?.file) {
            return null;
        }

        return this.largeLogoFiles[0]?.file;
    }
    public get smLogo(): File {
        if (!this.smallLogoFiles || !this.smallLogoFiles[0]?.file) {
            return null;
        }

        return this.smallLogoFiles[0]?.file;
    }
    public lgLogoPicturePath: string;
    public smLogoPicturePath: string;
    public largeLogoUploader: UploadControls;
    public smallLogoUploader: UploadControls;
    public largeLogoFiles: UploaderItem[];
    public smallLogoFiles: UploaderItem[];
    
    public epicIntegrationEnabled: boolean;

    public commissionCalculationTypeOptions: SelectOption<string>[];
    
    public favoriteBondTypeConfiguration: EProducerAccountFavoriteBondTypeConfiguration;
    public favoriteBondTypeSearch: SearchControl<BondType>;

    public invoiceSearchPhrase: string;
    public onlyOpenInvoices: boolean;
    public onlyApprovedPayments: boolean;

    public activitiesLoaded: boolean;
    public activitiesBusyIndicator: BusyIndicator;
    public brokerCategoryOptions: SelectOption<number>[];

    private billingEntriesLoaded: boolean;
    public billingEntriesBusyIndicator: BusyIndicator;

    public eProducerSettingsActivePane: 'Commission' | 'Logins' | 'Branding' | 'Payments' | 'FavoriteBondTypes' | 'CarrierVisibility';

    public removeLargeLogoUpload = () => {
        this.largeLogoUploader.removeFile(0);
    }

    public removeSmallLogoUpload = () => {
        this.smallLogoUploader.removeFile(0);
    }

    public applicationTabSelected() {
        if (this.applicationsLoaded) {
            return;
        }

        this.loadApplications();
    }

    public loadApplications = () => {
        
        this.applicationsTable.queryOptions.eProducerAccountId = this.account.id;

        this.applicationBusyIndicator = {
            message: 'Loading Applications...',
            promise: this.applicationService.getApplicationList(this.applicationsTable.queryOptions)
                .then((response) => {
                    this.applicationsTable.setData(
                        response.items,
                        response.totalRecordCount
                    );

                    this.applicationsLoaded = true;
                })
        };
    }

    public attachmentTabSelected(): void {
        if (this.attachmentsLoaded) {
            return;
        }

        this.loadAttachments();
    }

    public loadAttachments = () => {
        this.attachmentsBusyIndicator = {
            message: 'Loading Attachments...',
            promise: this.attachmentService.getEProducerAttachments(
                    this.account.id,
                    this.attachmentsTable.queryOptions
                )
                .then((response) => {
                    this.attachmentsTable.setData(response.items, response.totalRecordCount);
                    this.attachmentsLoaded = true;
                })
        }
    }

    public setLogoPicture(clearCache: boolean = false): IPromise<void> {
        const promises = [] as IPromise<void>[];
        
        if (this.branding?.largeLogoFileId) {
            promises.push(this.eProducerService.getLargeLogo(this.branding.id, clearCache)
                .then((imgData) => {
                    this.lgLogoPicturePath = imgData;
                }));
        }

        if (this.branding?.smallLogoFileId) {
            promises.push(this.eProducerService.getSmallLogo(this.branding.id, clearCache)
                .then((imgData) => {
                    this.smLogoPicturePath = imgData;
                }));
        }

        return this.$q.all(promises)
            .then(() => {});
    }

    public newPayment() {
        
        this.modalOpener.newPaymentModal(null, this.accountId)
            .result
            .then(() => {
                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }

                if (this.billingEntriesLoaded) {
                    this.billingEntriesTable.load();
                }

                this.loadPayments();
                this.loadBalances();
            })
            .catch(() => {});
    }

    public contactsTabSelected() : void {
        if (this.contacts) {
            return;
        }

        this.loadContacts();
    }

    public loadContacts = () => {
        this.contactsBusyIndicator = {
            message: 'Loading Contacts...',
            promise: this.eProducerService.getPeopleContactsForEProducerAccountId(this.accountId)
                .then((people) => {
                    this.contacts = people;
                })
        };
    }

    public invoiceTabSelected(): void {
        if (this.invoicesLoaded) {
            return;
        }

        this.loadInvoices();
    }

    public mergeInvoices() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords();
        if (!selectedInvoices || !selectedInvoices.length) {
            return;
        }

        if (selectedInvoices.length === 1) {
            this.toastMessageCreator.createErrorMessage("You must select more than one invoice to merge");
            return;
        }

        if (selectedInvoices.filter((invoice) => invoice.status == InvoiceStatus.Voided).length) {
            this.toastMessageCreator.createErrorMessage("You cannot merge voided invoices");
            return;
        }

        const message =
            `Merging invoices is permanent. The original invoices will be voided. Are you sure you want to merge ${selectedInvoices.length} invoice${(selectedInvoices.length > 1 ? "s" : "")} ?`;
  
        this.modalOpener
            .confirmModal('Merge Invoice', message, 'Merge', 'Cancel')
            .result
            .then(() => {
                this.invoicesBusyIndicator = {
                    message: "Merging Invoices...",
                    promise: this.invoiceService.mergeInvoices(selectedInvoices.map((invoice) => invoice.id))
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage('The selected invoices have been merged');
                            
                            if (this.paymentsLoaded) {
                                this.loadPayments();
                            }

                            this.loadInvoices();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage('An error occurred while trying to merge these invoices');
                        })
                };
            })
            .catch(() => {});
    }
    
    public createNewInvoice() {
        const selectedBillingEntries = this.billingEntriesTable.getSelectedRecords();
        if (!selectedBillingEntries || !selectedBillingEntries.length) {
            return;
        }

        const newInvoice = {
            billingEntryIds: selectedBillingEntries.map((billingEntry) => billingEntry.id)
        } as NewInvoiceRequest;

        this.billingEntriesBusyIndicator.message = 'Creating Invoice...'
        this.billingEntriesBusyIndicator.promise = this.invoiceService.createInvoice(newInvoice)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Invoice was created successfully');
                this.billingEntriesTable.load();

                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create this invoice');
            });
    }

    public loadInvoices = () => {
        this.invoiceTable.queryOptions.eProducerAccountId = this.accountId;

        this.invoicesBusyIndicator = {
            message: "Loading Invoices...",
            promise: this.invoiceService.getInvoiceListItems(this.invoiceTable.queryOptions)
                .then((response) => {
                    this.invoiceTable.setData(response.items, response.totalRecordCount);
                    this.invoicesLoaded = true;
                })
            };
    }

    public showVoidInvoicePrompt() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords()
        if (!selectedInvoices || !selectedInvoices.length){
            return;
        }

        const message = 'Voiding invoices is permanent. Any payments assoicated to the invoices you are voiding will be unassigned. Are you sure you want to void ' + selectedInvoices.length + ' invoice' + (selectedInvoices.length > 1 ? 's' : '') + '?'
        
        this.modalOpener.confirmModal('Void Invoice(s)', message, 'Void', 'Cancel')
            .result
            .then(() => {
                this.invoicesBusyIndicator = {
                    message: "Voiding Invoices...",
                    promise: this.invoiceService.voidInvoice(selectedInvoices.map((invoice) => invoice.id))
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage('The selected invoices have been voided');
                            this.loadInvoices();
                            this.loadPayments();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage('An error occurred while trying to void the invoice');
                        })
                }
            })
            .catch(() => {});
    }
    
    public paymentsTabSelected() {
        if (this.paymentsLoaded) {
            return;
        }

        this.loadPayments();
    }

    public loadPayments = () => {
        this.paymentsTable.queryOptions.eProducerAccountId = this.account.id;

        this.paymentsBusyIndicator = {
            message: 'Loading Payments',
            promise: this.paymentService
            .getPaymentListItems(this.paymentsTable.queryOptions)
            .then((response) => {
                this.paymentsTable.setData(response.items,response.totalRecordCount);
                this.paymentsLoaded = true;
            })
        }
    }
    
    public creditAssigned() {
        if (this.invoicesLoaded) {
            this.loadInvoices();
        }

        if (this.paymentsLoaded) {
            this.loadPayments();
        }

        this.loadBalances();
    }

    public paymentReassigned() {
        this.loadPayments();

        if (this.invoicesLoaded) {
            this.loadInvoices();
        }

        if (this.billingEntriesLoaded) {
            this.billingEntriesTable.load();
        }

        this.loadBalances();
    }

    public loadBranding() {
        if (this.branding?.id) {
            return this.$q.when();
        }

        return this.eProducerService.getBranding(this.account.id)
                .then((branding) => {
                    this.branding = branding;
                    return this.setLogoPicture();
                });
    }

    public loadFavoriteBondTypeConfiguration() {
        return this.eProducerService.getFavoriteBondTypeConfiguration(this.account.id)
                .then((favoriteBondTypeConfiguration) => {
                    this.favoriteBondTypeConfiguration = favoriteBondTypeConfiguration;
                });
    }

    public addFavoriteBondType(): void {
        for (const favorite of this.favoriteBondTypeConfiguration.favoriteBondTypes) {
            if (favorite.bondTypeId === this.favoriteBondTypeSearch.selected.id) {
                return;
            }
        }

        this.favoriteBondTypeConfiguration.favoriteBondTypes.push({
            bondTypeId: this.favoriteBondTypeSearch.selected.id,
            eProducerAccountId: this.account.id,
            bondTypeName: this.favoriteBondTypeSearch.selected.name
        });

        this.favoriteBondTypeSearch.clear();
    }

    public saveFavoriteBondTypes() {
        if (!this.favoriteBondTypeConfiguration?.id) {
            return this.$q.when();
        }

        return this.eProducerService.saveFavoriteBondTypeConfiguration({...this.favoriteBondTypeConfiguration});
    }

    public removeFavoriteBondType(index: number): void {
        this.favoriteBondTypeConfiguration.favoriteBondTypes.splice(index, 1);
    }

    public searchBondTypes(search: string): IPromise<void> {
        return this.bondTypeService.dropdownSearch(search).then((response) => {
            this.favoriteBondTypeSearch.matches = response;
        });
    }

    public save_click() {
        var filesToUpload = [
            ...this.largeLogoUploader.getFiles(),
            ...this.smallLogoUploader.getFiles()
        ];

        if (filesToUpload.length) {
            this.largeLogoUploader.setUrl(`${this.systemSettings.apiBaseUrl}upload/UploadEProducerAccountLogo`);
            this.smallLogoUploader.setUrl(`${this.systemSettings.apiBaseUrl}upload/UploadEProducerAccountLogo?height=75&width=75`);
            
            this.modalOpener.uploadModal(filesToUpload)
                .result
                .then(() => {})
                .catch(() => {});

            const promises = [];

            if (this.lgLogo) {
                promises.push(this.largeLogoUploader.uploadAll()
                    .then(uploadItems => {
                        this.branding.largeLogoFileId = uploadItems[0].response;
                    }));
            }

            if (this.smLogo) {
                promises.push(this.smallLogoUploader.uploadAll()
                    .then(uploadItems => {
                        this.branding.smallLogoFileId = uploadItems[0].response;
                    }));
            }

            this.$q.all(promises)
                .then(() => {
                    this.save();
                });
                
        } else {
            this.save();
        }
    }

    public save(): void {
        const promises = [
            this.eProducerService.saveBranding({...this.branding}),
            this.eProducerService.updateEProducerAccount(this.account),
            this.saveFavoriteBondTypes()
        ];

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.$q.all(promises)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Broker saved successfully');
                this.setLogoPicture(true);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save this record');
            });
    }

    /* BONDS */
    public bondTabSelected(): void {
        if (this.bondsLoaded) {
            return;
        }  

        this.loadBonds();
    }

    private loadBonds = () => {
        this.bondsBusyIndicator = {
            message: 'Loading Bonds...',
            promise: this.bondService.getBondsByEProducerAccountId(this.accountId, this.bondsTable.queryOptions)
                .then((response) => {
                    this.bondsTable.setData(response.items, response.totalRecordCount);
                    this.bondsLoaded = true;
                })
        };
    }

    public exportBonds() {
        this.bondsBusyIndicator = {
            message: 'Loading Bonds...',
            promise: this.bondService.getBondListReportByEProducerAccountId(this.accountId, this.bondsTable.queryOptions)
        };
    }
    /* BONDS END */

    /* ACTIVITIES */
    public activitiesSelected(): void {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    private loadActivities = () => {
        this.activitiesBusyIndicator = {
            message: 'Loading Activities',
            promise: this.activityService.getBrokerActivities(
                this.accountId, 
                this.activitiesTable.queryOptions
            )
            .then((response) => {
                this.activitiesTable.setData(response.items, response.totalRecordCount);
                this.activitiesLoaded = true;
            })
        }
    }
    /* ACTIVITIES END */

    public addUser(): void {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.modalOpener.showEproducerUserModal(null, this.account.id)
            .result
            .then(() => this.loadUsers())
            .catch(() => {});
    }

    public loadUsers = () => {
        return this.eProducerService.getUsers(this.account.id)
                .then((users) => {
                    this.users = users;
                });
    }

    public loadAccount(id: number): IPromise<void> {
        if (!id) {
            throw new Error('id is not valid');
        }

        return this.eProducerService.getEProducerAccount(id)
            .then((account) => {
                this.account = account;
            });
    }
    
    public loadBalances = () => {
        this.balanceCardBusyIndicator = {
            message: "Loading...",
            promise: this.eProducerService
                .getBrokerBalances(this.accountId)
                .then((accountBalances) => {
                    this.account.balance = accountBalances.balance;
                    this.account.unappliedPaymentTotal = accountBalances.unappliedPaymentTotal;
                    this.account.invoicedBalance = accountBalances.invoicedBalance;
                    this.account.notInvoicedTotal = accountBalances.notInvoicedTotal;
                    this.account.debitTotal = accountBalances.debitTotal;
                    this.account.creditTotal = accountBalances.creditTotal;
                })
        };
    }

    public loadEpicSettings(): IPromise<void> {
        return this.systemAccountService.isEpicIntegrationEnabled()
            .then((epicIntegrationEnabled) => {
                this.epicIntegrationEnabled = epicIntegrationEnabled;
            });
    }

    private async getBrokerCategoryTypeOptions(): Promise<void> {
        this.brokerCategoryOptions = await this.eProducerService.getBrokerCateogryTypeOptions();
    }

    public loadEProducerSettings() {
        if (this.settingsLoaded) {
            return;
        }

        if (!this.eProducerSettingsActivePane) {
            this.eProducerSettingsActivePane = 'Commission'
        }

        const promises = [
            this.loadUsers(),
            this.loadBranding(),
            this.loadFavoriteBondTypeConfiguration()
        ];

        this.settingsLoadingIndicator.promise = this.$q.all(promises)
            .then(() => {
                this.settingsLoaded = true;
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred while trying to load broker settings');
            });
    }

    public billingEntriesTabSelected = () => {
        if (this.billingEntriesLoaded) {
            return;
        }

        this.billingEntriesTable.load();
    }

    public loadBillingEntries = () => {
        return this.billingEntriesService.getBillingEntryListItems(this.billingEntriesTable.getQueryOptions())
                .then((response) => {
                    this.billingEntriesTable.setData(
                        response.items,
                        response.totalRecordCount
                    );

                    this.billingEntriesLoaded = true;
                });
    }

    public exportBillingEntries = () => {
        this.busyIndicator = {
            message: "Preparing Excel...",
            promise: this.billingEntriesService.getBillingEntriesListDownload(this.billingEntriesTable.getQueryOptions(), this.billingEntriesTable.getSelectedColumnNames())
        };
    }

    public $onInit(): void {
        this.accountId = this.$stateParams.id;
        this.favoriteBondTypeSearch = new SearchControl();
        this.onlyOpenInvoices = true;
        this.onlyApprovedPayments = true;

        this.billingEntriesTable.eProducerAccountId = this.accountId;
        
        this.commissionCalculationTypeOptions = [
            { label: 'Percent of Commission', value: 'PercentOfCommission' },
            { label: 'Percent of Premium', value: 'PercentOfPremium' }
        ];

        const promises: IPromise<void>[] = [
            this.loadAccount(this.accountId),
            this.loadEpicSettings(),
            this.getBrokerCategoryTypeOptions()
        ];

        this.billingEntriesBusyIndicator = {};
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.$q.all(promises)
        };

        this.settingsLoadingIndicator = {
            message: 'Loading...'
        };
    }
}

export class EProducerAccountDetailStateParams {
    public id: number;
}

export interface BrokerDetail {
    id?: number;
    name: string;
    isApproved: boolean;
    isLocked: boolean;
    lookupCode: string;
    commissionCalculationType: CommissionCalculationType;
    commission: number;
    billToType: BillToType;
    eProducerAccountBrokerCategoryId: number;
    allowDeferredPayment: boolean;
    isBrokerVisibleToCarrier: boolean;
    balance: number;
    unappliedPaymentTotal: number;
    invoicedBalance: number;
    notInvoicedTotal: number;
    debitTotal: number;
    creditTotal: number;
    companyType: CompanyType;
    phone: string;
    fax: string;
    eoPolicyNumber: string;
    eoPolicyExpirationDate: Date;
    isLLC: boolean;
    requires1099: boolean;
    agencyCode: string;
    agencySubCode: string;
    referredBy: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    mailAddress: string;
    mailSuiteAptNumber: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
}