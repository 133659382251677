import { IScope } from "angular";
import DropdownOption from "../../../api/types/dropdownOption";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { DashboardService } from "./dashboardService";
import { Dashboard } from "./types/dashboard";
import { DashboardConfiguration } from "./types/dashboardConfiguration";
import { GridsterOptions } from "./types/gridsterOptions";
import { WidgetColor } from "./widgetColorSelector/widgetColors";

export class DashboardController {
    
    public static $inject = [
        Injectables.$scope,
        Injectables.DashboardService,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly dashboardService: DashboardService,
        private readonly modalOpener: ModalOpener
    ) {
    }

    public currentDashboard: Dashboard;
    public lastRefreshTimeOption: DropdownOption;
    public gridsterOpts: GridsterOptions;
    public busyIndicator: BusyIndicator;
    public dashboardConfiguration: DashboardConfiguration;
    public dashboardNameForm: any;
    public refreshDropdownOptions: DropdownOption<number>[];

    public endSortDashboards = (args) => {
        this.dashboardService.moveDashboard(args.oldIndex, args.newIndex);
    }

    public refreshOptionSelected = (option: DropdownOption) => {
        this.dashboardService.setAutoRefresh(option.value);
    }

    public refresh = () => {
        this.dashboardService.refresh();
    }

    public refreshed =  () => {
        this.lastRefreshTimeOption.label = 'Refreshed at ' + this.dashboardService.lastRefreshTime;
    }

    public removeWidget = (index: number) => {
        this.dashboardService.removeWidgetFromCurrentDashboard(index);
    }

    public cloneWidget = (index: number) => {
        this.dashboardService.cloneWidgetToCurrentDashboard(index);
    }
    
    public showWidgetModal = () => {
        this.modalOpener.showWidgetModal();
    }

    public removeDashboard = () => {
        this.modalOpener.removeDashboardModal(this.currentDashboard.name)
            .result
            .then(() => {
                this.dashboardService.removeDashboard();
                this.currentDashboard = this.dashboardService.currentDashboard;
            })
            .catch(() => {});
    }

    public setDashboardName = (name: string) => {
        this.dashboardService.renameDashboard(name);
    }

    public selectDashboard = (index: number) => {
        this.dashboardService.selectDashboard(index);
        this.currentDashboard = this.dashboardService.currentDashboard;
    }

    public addDashboard = () => {
        this.dashboardService.addDashboard();
        this.currentDashboard = this.dashboardService.currentDashboard;
        this.dashboardNameForm.$show();
    }

    public cloneDashboard = () => {
        this.dashboardService.cloneDashboard();
        this.currentDashboard = this.dashboardService.currentDashboard;
        this.dashboardNameForm.$show();
    }

    public getWidgetBorderColor = (color: WidgetColor) => {
        if (color && color.cssClass) {
            return color.cssClass;
        }
    }
    
    public $onInit = () => {
        this.gridsterOpts = this.dashboardService.gridsterOptions;

        this.lastRefreshTimeOption = {
            isText: true,
            textClass: 'text-italic text-small text-center text-faint'
        };

        this.refreshDropdownOptions = [
            {
                label: '5 mins',
                value: 300000
            },{
                label: '15 mins',
                value: 900000
            },{
                label: '30 mins',
                value: 1800000
            },{
                label: '1 hour',
                value: 3600000
            },{
                label: 'Never',
                value: -1
            },{
                isDivider: true
            }, 
            this.lastRefreshTimeOption
        ];

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.dashboardService.load()
                .then(() => {
                    this.dashboardConfiguration = this.dashboardService.dashboardConfiguration;
                    this.lastRefreshTimeOption.label = 'Refreshed at ' + this.dashboardService.lastRefreshTime;
                    this.currentDashboard = this.dashboardService.currentDashboard;
                    this.dashboardService.onRefreshed.push(this.refreshed);
                })
        }

        this.$scope.$on("$destroy", () => {
            this.dashboardService.stopAutoRefresh();
        });
    }
}