import { Injectables } from "../../../configuration/injectables";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { ApplicationService } from "../../../api/applicationService";
import { ApplicationReportItem } from "./applicationReportItem";
import TableColumn from "../../../components/dataViews/tableColumn";
import DataView from "../../../components/dataViews/dataView";
import DataViewType from "../../../components/dataViews/dataViewType";
import deepCopy from "../../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import QueryBuilderLogicType from "../../../components/queryBuilder/queryBuilderLogicType";
import QueryConditionPropertyType from "../../../components/queryBuilder/queryConditionPropertyType";
import { ModalOpener } from "../../../modals/modalOpener";
import { UserService } from "../../../api/userService";
import { SearchType } from "../../../api/types/searchType";
import QueryConditionPropertyGroup from "../../../components/queryBuilder/queryConditionPropertyGroup";

export class ApplicationReportController {

    public static $inject = [
        Injectables.ApplicationService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator,
        Injectables.UserService
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        debouceDelayer: IDebounceDelayer,
        modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        userService: UserService
    ) {
        const columnOptions: TableColumn<any>[] = [{
            name: 'ApplicationNumber',
            heading: '#',
            headingClass: 'fit-content',
            label: 'Application Number',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Applications.Id',
            locked: true,
            index: 0
        }, {
            name: 'CustomerName',
            heading: 'Customer',
            headingClass: 'text-left',
            cellClass: '',
            isVisible: true,
            sortExpression: 'Customers.Name',
            index: 1
        }, {
            name: 'Status',
            heading: 'Status',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            index: 1
        },{
            name: 'ApplicationType',
            heading: 'Type',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Applications.ApplicationType',
            index: 2
        },{
            name: 'NameOnBond',
            heading: 'Name on Bond',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: true,
            sortExpression: 'Applications.NameOnBond',
            index: 3
        },{
            name: 'DesiredEffectiveDate',
            heading: 'Effective Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Applications.DesiredEffectiveDate',
            index: 4 
        },{
            name: 'BondAmount',
            heading: 'Bond Amount',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Applications.BondAmount',
            index: 5 
        },{
            name: 'BondType',
            heading: 'Bond Type',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: true,
            sortExpression: 'BondTypes.Name',
            index: 6 
        }, {
            name: 'CustomerLookupCode',
            heading: 'Customer Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Customers.LookupCode',
            index: 7
        },{
            name: 'Producer',
            heading: 'Producer',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Producer.FullName',
            index: 7
        },{
            name: 'ClientServiceExecutive',
            heading: 'CSE',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceExecutive.FullName',
            index: 7
        },{
            name: 'ClientServiceManager',
            heading: 'CSM',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceManager.FullName',
            index: 7
        },{
            name: 'ClientServiceAssociate',
            heading: 'CSA',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceAssociate.FullName',
            index: 7
        },{
            name: 'OriginSystem',
            heading: 'Origin System',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            index: 7
        },{
            name: 'SfaaCode',
            heading: 'Sfaa Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'SfaaCodes.Code',
            index: 7
        },{
            name: 'Broker',
            heading: 'Broker Name',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'EProducerAccounts.Name',
            index: 7
        },{
            name: 'BrokerLookupCode',
            heading: 'Broker Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'EProducerAccounts.LookupCode',
            index: 7
        },{
            name: 'ObligeeName',
            heading: 'Obligee',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: false,
            sortExpression: 'Obligees.Name',
            index: 7
        },{
            name: 'ObligeeMailAddress',
            heading: 'Obligee Mail Address',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Obligees.MailAddress',
            index: 7
        },{
            name: 'ObligeeMailCity',
            heading: 'Obligee Mail City',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Obligees.MailCity',
            index: 7
        },{
            name: 'ObligeeMailState',
            heading: 'Obligee Mail State',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Obligees.MailState',
            index: 7
        },{
            name: 'ObligeeMailZip',
            heading: 'Obligee Mail Zip',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Obligees.MailZip',
            index: 7
        },{
            name: 'RequisitioningState',
            heading: 'Requisitioning State',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Obligees.RequisitioningState',
            index: 7
        },{
            name: 'Tags',
            heading: 'Tags',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'ApprovedQuoteCount',
            heading: "Approved Quotes",
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 12
        },{
            name: 'PendingQuoteCount',
            heading: "Pending Quotes",
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 12
        }
        ];

        const conditionPropertyGroups: QueryConditionPropertyGroup[] = [
            {
                label: 'General',
                options: [
                    { label: 'Status', value: 'Applications.Status', type: QueryConditionPropertyType.ApplicationStatus },
                    { label: 'Name On Bond', value: 'Applications.NameOnBond', type: QueryConditionPropertyType.String },
                    { label: 'Bond Amount', value: 'Applications.BondAmount', type: QueryConditionPropertyType.Money },
                    { label: 'Bond Type', value: 'BondTypes.Name', type: QueryConditionPropertyType.String },
                    { label: 'Effective Date', value: 'Applications.DesiredEffectiveDate', type: QueryConditionPropertyType.Date },
                    { label: 'Submission Date', value: 'Applications.SubmissionDateTime', type: QueryConditionPropertyType.Date},
                    { label: 'Origin System', value: 'Applications.OriginSystem', type: QueryConditionPropertyType.OriginSystem },
                    { label: 'Tag', value: 'Tags.Name', type: QueryConditionPropertyType.StringExists },
                    { label: 'Created Date', value: 'Applications.CreatedDateTime', type: QueryConditionPropertyType.Date},
                ]
            },
            {
                label: 'Servicing Roles',
                options: [
                    { label: 'Producer', value: 'Applications.ProducerUserId', type: QueryConditionPropertyType.Producer },
                    { label: 'CSE', value: 'Applications.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive },
                    { label: 'CSM', value: 'Applications.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager },
                    { label: 'CSA', value: 'Applications.ClientServiceAgentUserId', type: QueryConditionPropertyType.ClientServiceAssociate },
                ]
            },
            {
                label: 'Obligee',
                options: [
                    { label: 'Obligee', value: 'Obligees.Name', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail Address', value: 'Obligees.MailAddress', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail City', value: 'Obligees.MailCity', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail State', value: 'Obligees.MailState', type: QueryConditionPropertyType.State },
                    { label: 'Obligee Mail Zip', value: 'Obligees.MailZip', type: QueryConditionPropertyType.String },
                ]
            },
            {
                label: 'Customer / Broker',
                options: [
                    { label: 'Customer', value: 'Customers.Name', type: QueryConditionPropertyType.String },
                    { label: 'Customer Lookup Code', value: 'Customers.LookupCode', type: QueryConditionPropertyType.String },
                    { label: 'Broker', value: 'EProducerAccounts.Name', type: QueryConditionPropertyType.String },
                    { label: 'Broker Lookup Code', value: 'EProducerAccounts.LookupCode', type: QueryConditionPropertyType.String },
                ]
            },

        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.Applications,
            recordsPerPage: 10,
            sortExpression: 'Applications.NameOnBond',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
                isActive: true,
            
            },
            searchType: SearchType.Basic
        }];

        this.table = new QueryBuilderTable(
            this.loadData,
            debouceDelayer,
            DataViewType.Applications,
            conditionPropertyGroups,
            columnOptions,
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener
        );
    }

    public readonly table: QueryBuilderTable<ApplicationReportItem>;
    public busyIndicator: BusyIndicator = {};
   
    public exportApplicationReport = () =>{
        this.busyIndicator = {
            message: "Loading...",
            promise: this.applicationService.getApplicationExcelReport(this.table.getQueryOptions(), this.table.getSelectedColumnNames())
        };
    }

    private loadData = () => {
       return this.applicationService
            .getApplicationReportData(this.table.getQueryOptions())
            .then((response) => {
                    this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load application information');
            });
        }; 

    public $onInit(): void {
        this.table.load();
    }
}