import * as angular from "angular";
import { IAttributes, ICompileService, IDirectiveFactory, IHttpService, IScope, ITemplateCacheService } from "angular";
import app from "../../main";
import { BusyIndicator } from "./busyIndicator";
import { BusyIndicatorTrackerFactory } from "./busyIndicatorTrackerFactory";
import { Injectables } from "../../configuration/injectables";


const busyIndicatorDirective: IDirectiveFactory = (
    $compile: ICompileService, 
    $templateCache: ITemplateCacheService,
    $http: IHttpService,
    busyIndicatorTrackerFactory: BusyIndicatorTrackerFactory) => {

        const link = (
            scope: IScope,
            element: JQuery,
            attrs: IAttributes
        ) => {

            let templateElement;
            let backdropElement;
            let currentTemplate;
            let templateScope;
            let backdrop;
            let tracker = busyIndicatorTrackerFactory.create();

            const defaults: BusyIndicator = {
                templateUrl: 'app/components/busyIndicator/busyIndicator.html',
                backdrop: true,
                message: 'Please Wait...',
                hideMessage: false
            };

            scope.$watchCollection(attrs.busyIndicator, (options: BusyIndicator) => {

                if (!options) {
                    options = { promise: null };
                }

                options = {...defaults, ...options};

                if (!options.templateUrl) {
                    options.templateUrl = defaults.templateUrl;
                }

                if (!templateScope) {
                    templateScope = scope.$new();
                }

                templateScope.message = options.message;
                templateScope.hideMessage = options.hideMessage;

                if (tracker.promise != options.promise) {
                    tracker.reset({promise: options.promise});
                }

                templateScope.isActive = () => {
                    return tracker.active();
                };

                if (!templateElement || 
                    currentTemplate !== options.templateUrl || 
                    backdrop !== options.backdrop) {

                    if (templateElement) {
                        templateElement.remove();
                    }
                    if (backdropElement) {
                        backdropElement.remove();
                    }

                    currentTemplate = options.templateUrl;
                    backdrop = options.backdrop;

                    $http.get(currentTemplate, { cache: $templateCache })
                        .then((indicatorTemplate) => {
                            options.backdrop = typeof options.backdrop === 'undefined' ? true : options.backdrop;

                            if (options.backdrop) {
                                var backdrop = '<div class="busy-indicator busy-indicator-backdrop ng-hide" ng-show="isActive()"></div>';
                                backdropElement = $compile(backdrop)(templateScope);
                                element.append(backdropElement);
                            }

                            var template = '<div class="busy-indicator ng-hide" ng-show="isActive()">' + indicatorTemplate.data + '</div>';
                            templateElement = $compile(template)(templateScope);

                            angular.element(templateElement.children()[0])
                                .css('position', 'absolute')
                                .css('top', 0)
                                .css('left', 0)
                                .css('right', 0)
                                .css('bottom', 0);
                            element.append(templateElement);

                        })
                        .catch(() => {});
                }
            });
        }

        return {
            restrict: 'A',
            link: link
        }
}

busyIndicatorDirective.$inject = [
    Injectables.$compile,
    Injectables.$templateCache,
    Injectables.$http,
    Injectables.busyIndicatorTrackerFactory
];
app.directive('busyIndicator', busyIndicatorDirective);