import { UserGroupService } from "../../api/userGroupService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { UserGroupTableItem } from "../../states/common/userGroups/userGroupTableItem";
import { UserGroupTableQueryOptions } from "../../states/common/userGroups/userGroupTableQuery";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class UserGroupListController {

    static $inject = [
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener,
        Injectables.UserGroupService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener,
        private readonly userGroupService: UserGroupService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }
    
    public table: Table<UserGroupTableItem, UserGroupTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    public promptToDelete(userGroup: UserGroupTableItem) {
        this.modalOpener.confirmModal(
                'Delete User Group',
                'Are you sure you want to delete the user group named ' + userGroup.name + '?',
                'Delete',
                'Cancel')
            .result
            .then(() => {
                this.busyIndicator.message = "Deleting...";
                this.busyIndicator.promise = this.userGroupService.deleteUserGroup(userGroup.id)
                    .then(() => {
                        this.loadData();
                        this.toastMessageCreator.createSuccessMessage('User Group was deleted successfully');
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An Error occurred deleting the user group');
                    })
            })
            .catch(() => {});
    }

    public newGroupClicked = () => {
        this.modalOpener.showUserGroupDetailModal()
            .result
            .then(() => {
                this.loadData();
            });
    }

    public editGroupClicked = (userGroupId: number) => {
        this.modalOpener.showUserGroupDetailModal(userGroupId)
            .result
            .then(() => {
                this.loadData();
            });
    }

    public loadData = () => {
        this.busyIndicator.promise = this.userGroupService.getUserGroups(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    }

    public $onInit() {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.loadData();
    }
}

const userGroupListComponent = {
    bindings: {},
    templateUrl: 'app/components/userGroupList/userGroupList.html',
    controller: UserGroupListController,
    controllerAs: 'vm'
};

app.component('userGroupList', userGroupListComponent);