import { ActivityService } from "../../api/activityService";
import { ApplicationService } from "../../api/applicationService";
import { BondService } from "../../api/bondService";
import { ActivityDetail } from "../../api/types/activities/activityDetail";
import { ActivityHistoryListItem } from "../../api/types/activities/activityHistoryListItem";
import { ActivityStatus } from "../../api/types/activities/activityStatus";
import { ActivityTypeAssignment } from "../../api/types/activities/activityTypeAssignment";
import { BondAccountService } from "../../api/bondAccountService";
import { SelectOption } from "../../api/types/selectOption";
import { ActivityAttachment } from "../../components/activityAttachmentList/activityAttachment";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ActivityHistoryTableQueryOptions } from "./activityHistoryTableQueryOptions";
import { ActivityModalOptions } from "./activityModalOptions";
import { ActivityModalResult } from "./activityModalResult";
import app from "../../main";
import { IQService, IPromise } from "angular";
import { PriorityLevelOptions } from "../../api/types/activities/activityPriorityLevelOptions";
import { UploadControls, UploaderItem } from "../../components/uploader/uploader";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class ActivityModalController {
    
    public static $inject = [
        Injectables.Options,
        Injectables.$uibModalInstance,
        Injectables.ActivityService,
        Injectables.$q,
        Injectables.BondService,
        Injectables.BondAccountService,
        Injectables.IDebouceDelayer,
        Injectables.ApplicationService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly options: ActivityModalOptions,
        private readonly $uibModalInstance: Modal<ActivityModalResult>,
        private readonly activityService: ActivityService,
        private readonly $q: IQService,
        private readonly bondService: BondService,
        private readonly bondAccountService: BondAccountService,
        debouceDelayer: IDebounceDelayer,
        private readonly applicationService: ApplicationService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.activityHistoryTable = new Table(debouceDelayer);
        this.activityHistoryTable.onChange = this.loadHistory;
    }

    public readonly activityHistoryTable: Table<ActivityHistoryListItem, ActivityHistoryTableQueryOptions>;

    public busyIndicator: BusyIndicator;
    public activity: ActivityDetail;
    public statusOptions: SelectOption<string>[];
    public historyShown: boolean;
    public historyLoaded: boolean;
    public currentSearchPhrase: string;
    public activityTypeOptions: SelectOption<number>[];
    public showMissingActivityTypeAlert: boolean;
    public bondOptions: SelectOption[];
    private tempFileId: number;
    public assignmentTypeOptions: SelectOption<string>[];
    public bondAccountOptions: SelectOption[];
    public applicationOptions: SelectOption[];
    public assignmentType: string;
    public selectedActivityTypeId: number;
    public priorityLevelOptions: SelectOption<number>[];
    public uploaderControls: UploadControls;

    public readonly noAssignmentType = 'NoAssignmentType';
    public readonly bondAccountAssignmentType = 'BondAccount';
    public readonly bondAssignmentType = 'Bond';
    public readonly applicationAssignmentType = 'Application';

    public toggleHistory(): void {
        this.historyShown = !this.historyShown;

        if (!this.historyLoaded) {
            this.loadHistory();
        }
    }

    private loadHistory = () => {
        this.busyIndicator.promise = this.activityService.getActivityHistoryListItems(this.activityHistoryTable.queryOptions)
            .then((response) => {
                this.activityHistoryTable.setData(response.items, response.totalRecordCount);
                this.historyLoaded = true;
            })
    }

    public addActivityType(activityTypeId?: number): void {
        if (activityTypeId) {
            this.selectedActivityTypeId = activityTypeId;
        }

        for(let i = 0; i < this.activity.activityTypeAssignments.length; i++) {
            if (this.activity.activityTypeAssignments[i].activityTypeId == this.selectedActivityTypeId) {
                this.selectedActivityTypeId = null;
                return;
            }
        }

        const activityType = this.activityTypeOptions.filter((activityType) => activityType.value == this.selectedActivityTypeId)[0];

        this.activity.activityTypeAssignments.push({
            activityTypeName: activityType.label,
            activityTypeId: activityType.value
        } as ActivityTypeAssignment);
        
        this.selectedActivityTypeId = null;
    }

    public removeActivityType(activityTypeAssignment: ActivityTypeAssignment, index: number): void {
        if (!activityTypeAssignment.id) {
            this.activity.activityTypeAssignments.splice(index, 1);
            return;
        }

        if (activityTypeAssignment.isRemoved) {
            activityTypeAssignment.isRemoved = false;
        } else {
            activityTypeAssignment.isRemoved = true;
        }
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public filesAdded = (files: UploaderItem[]) => {
        files.forEach(item => {
            item.data = this.tempFileId;

            this.activity.attachments.push({
                fileId: this.tempFileId,
                filename: item.file.name,
                fileSize: item.file.size
            } as ActivityAttachment);
    
            this.tempFileId--; 
        });
    }

    public fileUploaded = (file: UploaderItem) => {
        const attachment = this.activity.attachments.find(a => a.fileId === file.data);
        attachment.fileId = file.response[0];        
    }

    public submit_click(): void {
        if (!this.activity.activityTypeAssignments.filter((activityType) => activityType.isRemoved != true).length) {
            this.showMissingActivityTypeAlert = true;
            return;
        }            

        if (this.uploaderControls.getIncompleteFiles().length) {            
            this.busyIndicator.message = `Uploading Attachments...`;
            this.busyIndicator.promise = this.uploaderControls.uploadAll()
                .then(() => { this.submit(); });
        } else {
            this.submit();
        }
    }

    public submit(): void {
        if (!this.activity.bondId) {
            this.activity.bondId = null;
        }

        if (!this.activity.bondAccountId) {
            this.activity.bondAccountId = null;
        }

        if (!this.activity.eProducerAccountId) {
            this.activity.eProducerAccountId = null;
        }

        if (this.assignmentType != this.bondAccountAssignmentType) {
            this.activity.bondAccountId = null;
        }

        if (this.assignmentType != this.bondAssignmentType) {
            this.activity.bondId = null;
        }

        if (this.assignmentType != this.applicationAssignmentType) {
            this.activity.applicationId = null;
        }
        
        if (!this.activity.assignedToUserId) {
            this.activity.priorityLevel = null;
            this.activity.dueDate = null;
        }

        this.showMissingActivityTypeAlert = false;

        this.busyIndicator.message = 'Saving Activity...';

        if (this.options.activityId) {
            this.busyIndicator.promise = this.activityService.updateActivity(this.activity)
                .then(() => {
                    this.$uibModalInstance.close({} as ActivityModalResult);
                    this.toastMessageCreator.createSuccessMessage('Activity Updated Successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to update this activity');
                });
        } else {
            this.busyIndicator.promise = this.activityService.createActivity(this.activity)
                .then(() => {
                    this.$uibModalInstance.close({} as ActivityModalResult);
                    this.toastMessageCreator.createSuccessMessage('Activity Created Successfully');
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to create this activity');
                });
        }
    }
    
    public loadActivity(activityId: number): IPromise<any> {
        return this.activityService.getActivityDetailById(activityId)
            .then((activity) => {
                this.activity = activity;

                if (this.activity.bondAccountId) {
                    this.assignmentType = this.bondAccountAssignmentType;
                } else if (this.activity.applicationId) {
                    this.assignmentType = this.applicationAssignmentType;
                } else if (this.activity.bondId) {
                    this.assignmentType = this.bondAssignmentType;
                } else {
                    this.assignmentType = this.noAssignmentType;
                }

                if (this.activity.customerId) {
                    return this.$q.all([
                        this.loadBondOptions(),
                        this.loadBondAccountOptions(),
                        this.loadApplicationOptions()
                    ]);
                }
            });
    }

    public toggleIsFollowed() {
        this.activity.isFollowed = this.activity.isFollowed !== true;
    }

    public loadBondAccountOptions(): IPromise<any> {
        return this.bondAccountService.getBondAccountOptionsByCustomerId(this.activity.customerId)
            .then((bondAccountOptions) => {
                this.bondAccountOptions = [
                    { label: '', value: null },
                    ...bondAccountOptions
                ]
            });
    }

    public loadApplicationOptions(): IPromise<any> {
        return this.applicationService.getApplicationOptionsByCustomerId(this.activity.customerId)
            .then((applicationOptions) => {
                this.applicationOptions = [
                    { label: '', value: null },
                    ...applicationOptions
                ];
            })
    }

    public loadActivityTypes(): IPromise<any> {
        return this.activityService.getActivityTypes()
            .then((activityTypes) => {
                this.activityTypeOptions = activityTypes.map((activityType) => {
                    return { label: activityType.name, value: activityType.id };
                });
            });
    }

    public loadBondOptions(): IPromise<any> {
        return this.bondService.getBondOptionsByCustomerId(this.activity.customerId)
            .then((bondOptions) => {
                this.bondOptions = [
                    { label: '', value: null },
                    ...bondOptions
                ];
            })
    }

    private buildDefaultActivity() {
        this.activity = {
            customerId: this.options.customerId,
            bondId: this.options.bondId,
            bondAccountId: this.options.bondAccountId,
            eProducerAccountId: this.options.eProducerAccountId,
            applicationId: this.options.applicationId,
            attachments: [],
            activityTypeAssignments: [],
            conversationMessages: [],
            isFollowed: true
        } as ActivityDetail;
    }

    private buildDefaultActivityOptions() {
        if (this.options.defaultOptions === 'attachment') {
            const attachmentActivityTypeId: number = this.activityTypeOptions.filter((activityTypeOption) => activityTypeOption.label === 'Attachment')[0].value;

            this.activity.status = ActivityStatus.Closed;
            this.activity.description = 'Attachment uploaded';

            if (attachmentActivityTypeId) {
                this.addActivityType(attachmentActivityTypeId);
            }                
        }
    }
    
    public $onInit() { 
        this.busyIndicator = { message: 'Loading...' };
        this.historyShown = false;
        this.tempFileId = -1;
        this.priorityLevelOptions = PriorityLevelOptions;

        this.activityHistoryTable.queryOptions.activityId = this.options.activityId; 

        this.statusOptions = [
            { label: 'Open', value: 'Open'},
            { label: 'Closed', value: 'Closed' },
            { label: 'Hidden', value: 'Hidden'}
        ];

        this.assignmentTypeOptions = [
            { label: 'None', value: this.noAssignmentType},
            { label: 'Bond', value: this.bondAssignmentType },
            { label: 'Bond Line', value: this.bondAccountAssignmentType },
            { label: 'Application', value: this.applicationAssignmentType }
        ];

        if (!this.options.eProducerAccountId) {
            if (this.options.bondId) {
                this.assignmentType = this.bondAssignmentType;
            } else if (this.options.bondAccountId) {
                this.assignmentType = this.bondAccountAssignmentType;
            } else if (this.options.applicationId) {
                this.assignmentType = this.applicationAssignmentType;
            } else {
                this.assignmentType = this.noAssignmentType;    
            }
        } else {
            this.assignmentType = this.noAssignmentType;
        }

        const promises: IPromise<any>[] = [
            this.loadActivityTypes()
        ];

        this.buildDefaultActivity();

        if (this.options.activityId) {
            promises.push(this.loadActivity(this.options.activityId));
        } else {
            if (this.options.customerId) {
                promises.push(this.loadBondOptions());
                promises.push(this.loadBondAccountOptions());
                promises.push(this.loadApplicationOptions());
            }
        }

        this.busyIndicator.promise = this.$q.all(promises)
            .then(() => {

                // load default after data loads
                // so that options are available
                if (!this.options.activityId) {
                    this.buildDefaultActivityOptions();
                }
            });
    }
}

app.controller('ActivityModalController', ActivityModalController);
