import { IFilterService, IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const isIntegerDirective = ($filter: IFilterService) => {
    const INTEGER_REGEXP_1 = /^\$?\d+$/; // Matches integers like: 1234
    const INTEGER_REGEXP_2 = /^\$?\d{1,3}(,\d{3})*$/; // Matches integers with commas like: 1,234

    const link = function (
        scope: IScope, 
        elm: JQuery, 
        attrs: IAttributes, 
        ctrl: INgModelController) {

        if ('isString' in attrs) {
            return;
        }

        ctrl.$parsers.unshift(function (viewValue) {
            viewValue = viewValue.replace(/\,/g, '');
            if (INTEGER_REGEXP_1.test(viewValue) || INTEGER_REGEXP_2.test(viewValue)) {
                ctrl.$setValidity('integer', true);
                return parseInt(viewValue, 10);
            } else if (!viewValue) {
                ctrl.$setValidity('integer', true);
                return null;
            } else {
                ctrl.$setValidity('integer', false);
                return undefined;
            }
        });

        ctrl.$formatters.unshift(
            function (modelValue) {
                return $filter('number')(parseInt(modelValue), 0);
            }
        );

        ctrl.$validators.min = (modelValue) => {
            if (!modelValue) {

                if (modelValue === null || modelValue === undefined) {
                    return true;
                }
            }

            if (attrs.min) {
                return parseInt(modelValue) >= parseInt(attrs.min);
            }

            return true;
        };

        ctrl.$validators.max = (modelValue) => {
            if (!modelValue) {

                if (modelValue === null || modelValue === undefined) {
                    return true;
                }
            }

            if (attrs.max) {
                return parseInt(modelValue) <= parseInt(attrs.max);
            }

            return true;
        };
    };

    isIntegerDirective.$inject = [Injectables.$filter];

    return {
        require: 'ngModel',
        link: link
    };
}

isIntegerDirective.$inject = [Injectables.$filter];

app.directive('isInteger', isIntegerDirective as IDirectiveFactory);