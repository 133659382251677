angular.module('a3TemplateCache').run(['$templateCache', function($templateCache) {
  'use strict';

  $templateCache.put('app/components/activityAttachmentList/activityAttachmentList.html',
    "<div>\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"group-by-tbody\"\r" +
    "\n" +
    "        ng-show=\"vm.attachments.length > 0\">\r" +
    "\n" +
    "       \r" +
    "\n" +
    "        <tbody\r" +
    "\n" +
    "            ng-init=\"vm.attachmentInit(attachment)\" \r" +
    "\n" +
    "            ng-repeat=\"attachment in vm.attachments\"\r" +
    "\n" +
    "            ng-class=\"{'line-through disabled': attachment.isRemoved}\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td class=\"fit-content no-right-padding\">\r" +
    "\n" +
    "                    <file-icon filename=\"attachment.filename\"></file-icon>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td class=\"text-left small fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"attachment.displayName\"\r" +
    "\n" +
    "                        ng-focus=\"vm.removeExtension(attachment)\"\r" +
    "\n" +
    "                        ng-blur=\"vm.addExtension(attachment)\"\r" +
    "\n" +
    "                        aa-label=\"\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin fixed-width-lg\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        minlength=\"1\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"!attachment.fileId\" \r" +
    "\n" +
    "                        class=\"red\"> \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        - NEW\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"small fit-content\">\r" +
    "\n" +
    "                    {{ attachment.fileSize | filesize:2 }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"attachment.attachmentTypeId\"\r" +
    "\n" +
    "                        aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                        aa-label=\"Type:\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin fixed-width-150 small\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.attachmentTypeOptions\"\r" +
    "\n" +
    "                        ng-change=\"vm.attachmentModified(attachment)\" >\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column text-right nowrap\">                    \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        ng-if=\"attachment.id\"\r" +
    "\n" +
    "                        show-attachment-viewer-modal=\"attachment\"\r" +
    "\n" +
    "                        title=\"View Attachment\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        View\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <pdf-download \r" +
    "\n" +
    "                        url=\"attachment.$url\"\r" +
    "\n" +
    "                        title=\"Download Attachment\"\r" +
    "\n" +
    "                        ng-if=\"attachment.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "                     \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.deleteAttachment(attachment, $index)\" \r" +
    "\n" +
    "                        title=\"Delete Attachment\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    colspan=\"5\"\r" +
    "\n" +
    "                    class=\"text-left small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin right-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"attachment.visibleInEProducer\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                            aa-label=\"Share through eProducer\"\r" +
    "\n" +
    "                            ng-change=\"vm.attachmentModified(attachment)\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"attachment.shareWithCarrier\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                            aa-label=\"Share with Carrier\"\r" +
    "\n" +
    "                            ng-change=\"vm.attachmentModified(attachment)\"\r" +
    "\n" +
    "                            options=\"vm.carrierSharingOptions\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    colspan=\"5\"\r" +
    "\n" +
    "                    class=\"text-left small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"attachment.comments\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        aa-label=\"\"\r" +
    "\n" +
    "                        ng-change=\"vm.attachmentModified(attachment)\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.attachments.length === 0\" \r" +
    "\n" +
    "        message=\"No attachments were found.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/activityList/activityList.html',
    "<perfect-scrollbar \r" +
    "\n" +
    "    class=\"scroller list-wrapper bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table>\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Activity Type\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Created By\"\r" +
    "\n" +
    "                        sort-expression=\"createdByUserFullName\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Assigned To\"\r" +
    "\n" +
    "                        sort-expression=\"dueDate\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Due Date\"\r" +
    "\n" +
    "                        sort-expression=\"dueDate\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Priority\"\r" +
    "\n" +
    "                        sort-expression=\"dueDate\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Date\"\r" +
    "\n" +
    "                        sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Status\"\r" +
    "\n" +
    "                        sort-expression=\"status\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    Reference\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>Description</th>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>                    \r" +
    "\n" +
    "            <tr ng-repeat=\"activity in vm.table.data\">\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-class=\"{\r" +
    "\n" +
    "                        'table-row-status-critical' : activity.priorityLevel >= 4 && activity.status == 'Open',\r" +
    "\n" +
    "                        'table-row-status-warning' : activity.priorityLevel == 3 && activity.status == 'Open',\r" +
    "\n" +
    "                        'table-row-status-attention' : activity.priorityLevel == 2 && activity.status == 'Open'\r" +
    "\n" +
    "                    }\"\r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        uib-tooltip=\"Has Attachment\"\r" +
    "\n" +
    "                        tooltip-placement=\"top-left\"\r" +
    "\n" +
    "                        ng-show=\"activity.hasAttachment\"\r" +
    "\n" +
    "                        class=\"fa fa-paperclip no-margin\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        uib-tooltip=\"Following\"\r" +
    "\n" +
    "                        tooltip-placement=\"top-left\"\r" +
    "\n" +
    "                        ng-show=\"activity.isFollowed\"\r" +
    "\n" +
    "                        class=\"fa fa-bell green\"\r" +
    "\n" +
    "                        ng-class=\"{'left-margin-thin' : activity.isFollowed && activity.hasAttachment}\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                    <span class=\"tag-container\">\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"tag\" \r" +
    "\n" +
    "                            ng-repeat=\"activityTypeAssignment in activity.activityTypeAssignments\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            {{activityTypeAssignment.activityTypeName}}                \r" +
    "\n" +
    "                        </div>            \r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"activity.createdByUserId\"\r" +
    "\n" +
    "                        class=\"user mini flex\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <user-avatar \r" +
    "\n" +
    "                            user-id=\"activity.createdByUserId\"\r" +
    "\n" +
    "                            class=\"mini v-center\">\r" +
    "\n" +
    "                        </user-avatar>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"left-margin-thin inline-block v-center\">\r" +
    "\n" +
    "                            {{activity.createdByUserFullName}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"activity.createdByCarrierName\"\r" +
    "\n" +
    "                                class=\"x-small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{activity.createdByCarrierName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"!activity.createdByUserId\">\r" +
    "\n" +
    "                        Auto Generated\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"activity.assignedToUserId\">\r" +
    "\n" +
    "                        {{activity.assignedToFirstName}} {{activity.assignedToLastName}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"activity.assignedToUserId\">\r" +
    "\n" +
    "                        Due: {{activity.dueDate | dateOnly}} \r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"activity.assignedToUserId\">\r" +
    "\n" +
    "                        Priority: {{vm.getPriorityLevelString(activity)}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{activity.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-center fit-content\">{{activity.status}}</td>\r" +
    "\n" +
    "                <td class=\"text-center fit-content\">\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ng-show=\"activity.bondId\"\r" +
    "\n" +
    "                        show-bond-detail-modal=\"activity.bondId\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{activity.bondNumber}}\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ng-show=\"activity.applicationId\"\r" +
    "\n" +
    "                        show-application-detail-modal=\"activity.applicationId\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        App# {{activity.applicationNumber}}\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{activity.description}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"activity.hasConversation\"\r" +
    "\n" +
    "                        uib-tooltip=\"More comments in this activty's conversation\"\r" +
    "\n" +
    "                        class=\"fa fa-comments\" ></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <show-activity-modal-button\r" +
    "\n" +
    "                        button-class=\"mini\"\r" +
    "\n" +
    "                        activity-id=\"activity.id\"\r" +
    "\n" +
    "                        on-submit=\"vm.onActivitiesModified()\">\r" +
    "\n" +
    "                    </show-activity-modal-button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No activities were found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/components/activityListFilterDropdown/activityListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"activityListFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Status </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Open\"> Open </option>\r" +
    "\n" +
    "                    <option value=\"Closed\"> Closed </option>\r" +
    "\n" +
    "                    <option value=\"Hidden\"> Hidden </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\"> Type </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.activityTypeId\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.activityTypeOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Created By </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <user-dropdown\r" +
    "\n" +
    "                    ng-model=\"vm.modifiedFilters.createdByUserId\"\r" +
    "\n" +
    "                    class=\"fill\"\r" +
    "\n" +
    "                    allow-unselected=\"true\"\r" +
    "\n" +
    "                    unselected-text=\"Any\"\r" +
    "\n" +
    "                    all-users=\"true\">\r" +
    "\n" +
    "                </user-dropdown>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Assigned To </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <user-dropdown\r" +
    "\n" +
    "                    ng-model=\"vm.modifiedFilters.assignedToUserId\"\r" +
    "\n" +
    "                    class=\"fill\"\r" +
    "\n" +
    "                    allow-unselected=\"true\"\r" +
    "\n" +
    "                    unselected-text=\"Any\"\r" +
    "\n" +
    "                    all-users=\"true\">\r" +
    "\n" +
    "                </user-dropdown>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Following </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isFollowed\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"true\"> Followed </option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"false\"> Unfollowed </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Lookup Code </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.lookupCode\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    placeholder=\"Customer or Broker Lookup Code\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Days Till Due</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.daysTillDue\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    placeholder=\"Days till Due\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Priority Level Between</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                aa-field-group=\"vm.modifiedFilters.lowPriorityLevel\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                options=\"vm.priorityLevelOptions\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                aa-field-group=\"vm.modifiedFilters.highPriorityLevel\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                options=\"vm.priorityLevelOptions\"></select>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/additionalQuestionList/additionalQuestionList.html',
    "<div\r" +
    "\n" +
    "    class=\"top-margin\"\r" +
    "\n" +
    "    ng-repeat=\"question in vm.questions\"\r" +
    "\n" +
    "    ng-init=\"vm.initQuestion(question)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <!-- application-question-label used in e2e tests -->\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"fill v-center text-right application-question-label\"\r" +
    "\n" +
    "            ng-class=\"{'label-left' : question.type == 'dropDown' || question.type == 'textBox'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ question.question }}\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"red\"\r" +
    "\n" +
    "                 ng-show=\"question.required\">\r" +
    "\n" +
    "                 \r" +
    "\n" +
    "                 *\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"left-margin flex fill v-center\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"{{'additional-question-' + $index + '-radio'}}\"\r" +
    "\n" +
    "                aa-field-group=\"question.selectedValue\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                name=\"{{ question.name }}\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                ng-show=\"question.type == 'Radio'\"\r" +
    "\n" +
    "                ng-required=\"question.required\"\r" +
    "\n" +
    "                options=\"question.options\"\r" +
    "\n" +
    "                required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                ng-change=\"vm.setQuestionResponses()\"\r" +
    "\n" +
    "                aa-wrapper-class=\"no-margin flex\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                id=\"{{'additional-question-' + $index + '-select'}}\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                aa-field-group=\"question.selectedValue\"\r" +
    "\n" +
    "                name=\"{{ question.name }}\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                ng-show=\"question.type == 'DropDown'\"\r" +
    "\n" +
    "                ng-required=\"question.required\"\r" +
    "\n" +
    "                options=\"question.options\"\r" +
    "\n" +
    "                required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                is-string\r" +
    "\n" +
    "                ng-change=\"vm.setQuestionResponses()\" >\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <textarea\r" +
    "\n" +
    "                id=\"{{'additional-question-' + $index + '-textarea'}}\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                aa-field-group=\"question.selectedValue\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                name=\"{{ question.name }}\"\r" +
    "\n" +
    "                ng-show=\"question.type == 'TextArea'\"\r" +
    "\n" +
    "                ng-required=\"question.required\"\r" +
    "\n" +
    "                required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                ng-blur=\"vm.setQuestionResponses()\" >\r" +
    "\n" +
    "            </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"{{'additional-question-' + $index + '-input'}}\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                aa-field-group=\"question.selectedValue\"\r" +
    "\n" +
    "                name=\"{{ question.name }}\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                ng-show=\"question.type == 'TextBox'\"\r" +
    "\n" +
    "                ng-required=\"question.required\"\r" +
    "\n" +
    "                required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                ng-blur=\"vm.setQuestionResponses()\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"top-margin\"\r" +
    "\n" +
    "        ng-repeat=\"subQuestion in question.subQuestions\"\r" +
    "\n" +
    "        ng-init=\"vm.initQuestion(subQuestion)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"flex\"\r" +
    "\n" +
    "            ng-show=\"question.selectedValue == subQuestion.showWhenParentValueEquals\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"v-center fill text-right\"\r" +
    "\n" +
    "                ng-class=\"{'label-left' : question.type == 'DropDown' || question.type == 'TextBox'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{ subQuestion.question }}\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        class=\"red\"\r" +
    "\n" +
    "                        ng-show=\"question.required\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        *\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"left-margin flex fill v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"{{'additional-question-' + $parent.$index + '-' + $index + '-radio'}}\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                    aa-field-group=\"subQuestion.selectedValue\"\r" +
    "\n" +
    "                    name=\"{{ subQuestion.name }}\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                    ng-show=\"subQuestion.type == 'Radio'\"\r" +
    "\n" +
    "                    ng-required=\"question.required && question.selectedValue == subQuestion.showWhenParentValueEquals\"\r" +
    "\n" +
    "                    options=\"subQuestion.options\"\r" +
    "\n" +
    "                    required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                    ng-change=\"vm.setQuestionResponses()\"\r" +
    "\n" +
    "                    aa-wrapper-class=\"no-margin\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    id=\"{{'additional-question-' + $parent.$index + '-' + $index + '-select'}}\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                    aa-field-group=\"subQuestion.selectedValue\"\r" +
    "\n" +
    "                    name=\"{{ subQuestion.name }}\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    ng-show=\"subQuestion.type == 'DropDown'\"\r" +
    "\n" +
    "                    ng-required=\"question.required && question.selectedValue == subQuestion.showWhenParentValueEquals\"\r" +
    "\n" +
    "                    options=\"subQuestion.options\"\r" +
    "\n" +
    "                    required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                    is-string\r" +
    "\n" +
    "                    ng-change=\"vm.setQuestionResponses()\" >\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea\r" +
    "\n" +
    "                    id=\"{{'additional-question-' + $parent.$index + '-' + $index + '-textarea'}}\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                    aa-field-group=\"subQuestion.selectedValue\"\r" +
    "\n" +
    "                    name=\"{{ subQuestion.name }}\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    ng-show=\"subQuestion.type == 'TextArea'\"\r" +
    "\n" +
    "                    ng-required=\"question.required && question.selectedValue == subQuestion.showWhenParentValueEquals\"\r" +
    "\n" +
    "                    required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                    ng-blur=\"vm.setQuestionResponses()\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"{{'additional-question-' + $parent.$index + '-' + $index + '-input'}}\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                    aa-field-group=\"subQuestion.selectedValue\"\r" +
    "\n" +
    "                    name=\"{{ subQuestion.name }}\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    ng-show=\"subQuestion.type == 'TextBox'\"\r" +
    "\n" +
    "                    ng-required=\"question.required && question.selectedValue == subQuestion.showWhenParentValueEquals\"\r" +
    "\n" +
    "                    required-msg=\"Underwriting question is required\"\r" +
    "\n" +
    "                    ng-blur=\"vm.setQuestionResponses()\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/addressForm/addressForm.html',
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"row top-margin\"\r" +
    "\n" +
    "    ng-if=\"!vm.isEmployer && !vm.isPayment\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"address-field-wrapper col-sm-6\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <label class=\"flex\">\r" +
    "\n" +
    "            Physical Address\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                ng-show=\"vm.isRequired\" \r" +
    "\n" +
    "                class=\"validation-indicator\">\r" +
    "\n" +
    "                *\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"address-field-border-wrapper\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.physicalAddress\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Address\"\r" +
    "\n" +
    "                ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.physicalSuiteAptNumber\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Suite/Apt #\"\r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.physicalCity\" \r" +
    "\n" +
    "                    aa-fg-class=\"city-field no-margin fill\"\r" +
    "\n" +
    "                    aa-label=\"City\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"state-field\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.physicalState\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        aa-label=\"State\"\r" +
    "\n" +
    "                        ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"state\" >\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"zip-field no-margin\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.physicalZip\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    aa-label=\"Zip\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.physicalCounty\" \r" +
    "\n" +
    "                    aa-fg-class=\"county-field no-margin\" \r" +
    "\n" +
    "                    ng-required=\"vm.countyRequired\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-label=\"County\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideCounty\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"address-field-wrapper col-sm-6\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <label class=\"flex\">\r" +
    "\n" +
    "            <span class=\"v-center\">\r" +
    "\n" +
    "                Mailing Address\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.isRequired\" \r" +
    "\n" +
    "                    class=\"validation-indicator\">\r" +
    "\n" +
    "                    *\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.$addressesSame\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                aa-label-class=\"small\" \r" +
    "\n" +
    "                aa-label=\"Same as Physical\" \r" +
    "\n" +
    "                aa-fg-class=\"v-center left-margin-auto address-same-as-checkbox\" \r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\" \r" +
    "\n" +
    "                ng-change=\"vm.addressSameChanged()\" />\r" +
    "\n" +
    "        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"address-field-border-wrapper\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.mailAddress\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Address\" \r" +
    "\n" +
    "                ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.mailSuiteAptNumber\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Suite/Apt #\"\r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.mailCity\" \r" +
    "\n" +
    "                    aa-fg-class=\"city-field no-margin fill\"\r" +
    "\n" +
    "                    aa-label=\"City\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"state-field\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.mailState\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                        aa-label=\"State\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"zip-field no-margin\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                    aa-field-group=\"vm.mailZip\"\r" +
    "\n" +
    "                    aa-label=\"Zip\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.mailCounty\" \r" +
    "\n" +
    "                    aa-fg-class=\"county-field no-margin\" \r" +
    "\n" +
    "                    ng-required=\"vm.countyRequired\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-label=\"County\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideCounty\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"flex top-margin\" \r" +
    "\n" +
    "    ng-if=\"vm.isEmployer\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"address-field-wrapper\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <label class=\"flex\">\r" +
    "\n" +
    "            <span class=\"v-center\">\r" +
    "\n" +
    "                Employer Address\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.isRequired\" \r" +
    "\n" +
    "                    class=\"validation-indicator\">\r" +
    "\n" +
    "                    *\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"address-field-border-wrapper\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.employerAddress\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Address\"\r" +
    "\n" +
    "                ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.employerSuiteAptNumber\" \r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Suite/Apt #\"\r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.employerCity\" \r" +
    "\n" +
    "                    aa-fg-class=\"city-field no-margin fill\"\r" +
    "\n" +
    "                    aa-label=\"City\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"state-field\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.employerState\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        ng-required=\"vm.isRequired\"                     \r" +
    "\n" +
    "                        aa-label=\"State\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.employerZip\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                    city=\"vm.employerCity\" \r" +
    "\n" +
    "                    state=\"vm.employerState\" \r" +
    "\n" +
    "                    aa-label=\"Zip\"\r" +
    "\n" +
    "                    aa-fg-class=\"zip-field no-margin\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.employerCounty\" \r" +
    "\n" +
    "                    aa-fg-class=\"county-field no-margin\" \r" +
    "\n" +
    "                    ng-required=\"vm.countyRequired\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-label=\"County\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideCounty\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"flex top-margin\" \r" +
    "\n" +
    "    ng-if=\"vm.isPayment\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"address-field-wrapper fill\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <label class=\"flex\">\r" +
    "\n" +
    "            <span class=\"v-center\">\r" +
    "\n" +
    "                Address\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.isRequired\" \r" +
    "\n" +
    "                    class=\"validation-indicator\">\r" +
    "\n" +
    "                    *\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"address-field-border-wrapper\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.address\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                aa-label=\"Address\"\r" +
    "\n" +
    "                ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.city\" \r" +
    "\n" +
    "                    aa-fg-class=\"city-field no-margin fill\"\r" +
    "\n" +
    "                    aa-label=\"City\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"state-field\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.state\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        ng-required=\"vm.isRequired\"                     \r" +
    "\n" +
    "                        aa-label=\"State\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.zip\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                    city=\"vm.zip\" \r" +
    "\n" +
    "                    state=\"vm.state\" \r" +
    "\n" +
    "                    aa-label=\"Zip\"\r" +
    "\n" +
    "                    aa-fg-class=\"zip-field no-margin\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    ng-required=\"vm.isRequired\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/amSelectDropdown/amSelect.html',
    "<span \r" +
    "\n" +
    "    class=\"dropdown records-per-page\" \r" +
    "\n" +
    "    ng-class=\"{'dropup': vm.isDropUp}\" \r" +
    "\n" +
    "    uib-dropdown \r" +
    "\n" +
    "    ng-click=\"vm.toggleDropUp($event)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"mini\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        {{ vm.selectedOption.selectedText }} \r" +
    "\n" +
    "        {{ vm.selectedOption.label }} \r" +
    "\n" +
    "        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <ul class=\"dropdown-menu dropdown-menu-left\">\r" +
    "\n" +
    "        <li ng-repeat=\"option in vm.options\">\r" +
    "\n" +
    "            <a ng-click=\"vm.selectOption(option)\">\r" +
    "\n" +
    "                <i ng-class=\"{'fa fa-check' : option.value === vm.selectedValue}\"></i>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{option.text}}\r" +
    "\n" +
    "                {{option.label}}\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/applicationList/applicationList.html',
    "<table \r" +
    "\n" +
    "    class=\"bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"App #\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.Id\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Status\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.Status\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Submission Date/Time\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.SubmissionDateTime\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Type\"\r" +
    "\n" +
    "                    sort-expression=\"BondTypes.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">Quotes</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"application in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <a show-application-detail-modal=\"application.id\">{{application.applicationNumber}}</a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <span ng-bind-html=\"application | applicationStatus\"></span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{application.submissionDateTime | localDateTime}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <span ng-show=\"application.bondId\">\r" +
    "\n" +
    "                    <b>\r" +
    "\n" +
    "                        <span ng-show=\"application.applicationType == 'SingleBondRenewal'\">Renewal - </span>\r" +
    "\n" +
    "                        <span ng-show=\"application.applicationType == 'SingleBond' && application.bondId\">New Business - </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Bond#: \r" +
    "\n" +
    "                        <a show-bond-detail-modal=\"application.bondId\">\r" +
    "\n" +
    "                            {{application.bondNumber}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{application.bondAmount | currency:'$'}} \r" +
    "\n" +
    "                - \r" +
    "\n" +
    "                {{application.bondType | limitTo:75}} \r" +
    "\n" +
    "                - \r" +
    "\n" +
    "                Effective: {{application.desiredEffectiveDate | dateOnly}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{application.approvedQuoteCount}} Approved / {{application.pendingQuoteCount}} Pending\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No applications found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/components/applicationListFilterDropdown/applicationListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"applicationLiftFilterForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Status\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Open\">\r" +
    "\n" +
    "                        Open\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Submitted\">\r" +
    "\n" +
    "                        Submitted\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"NotSubmitted\">\r" +
    "\n" +
    "                        Not Submitted\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Closed\">\r" +
    "\n" +
    "                        Closed\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Completed\">\r" +
    "\n" +
    "                        Purchased\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/attachmentList/attachmentList.html',
    "<perfect-scrollbar \r" +
    "\n" +
    "    class=\"scroller list-wrapper bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"bottom-margin group-b-tbody\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Filename\"\r" +
    "\n" +
    "                        sort-expression=\"attachments.filename\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Uploaded By\"\r" +
    "\n" +
    "                        sort-expression=\"uploadedByUserFullName\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Upload Date/Time\"\r" +
    "\n" +
    "                        sort-expression=\"attachments.createdDateTime\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Type\"\r" +
    "\n" +
    "                        sort-expression=\"attachmentTypes.name\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>Sharing</th>\r" +
    "\n" +
    "                <th>Comments</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"attachment in vm.table.data\">\r" +
    "\n" +
    "                <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <div class=\"v-center right-margin-thin\">\r" +
    "\n" +
    "                            <file-icon filename=\"attachment.filename\"></file-icon>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                                                \r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{ attachment.filename }}\r" +
    "\n" +
    "                            <div class=\"small\">                                \r" +
    "\n" +
    "                                {{ attachment.fileSize | filesize:2 }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"attachment.uploadedByUserId || attachment.uploadedByEProducerUserId\"\r" +
    "\n" +
    "                        class=\"user mini flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <user-avatar\r" +
    "\n" +
    "                            user-id=\"attachment.uploadedByUserId\"\r" +
    "\n" +
    "                            class=\"mini v-center\">\r" +
    "\n" +
    "                        </user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"left-margin-thin inline-block v-center\">\r" +
    "\n" +
    "                            {{attachment.uploadedByUserFullName}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"vm.createdByAnotherSystemAccount(attachment) || attachment.uploadedByEProducerUserId\"\r" +
    "\n" +
    "                                class=\"x-small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{attachment.uploadedByCompanyName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content small\">\r" +
    "\n" +
    "                    {{ attachment.createdDateTime | localDateTime }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    {{ attachment.attachmentType }}\r" +
    "\n" +
    "                </td>                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"fit-content text-left small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"attachment.visibleToAllCarriers\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        All Carriers\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"attachment.sharedWithCarrierCompanyName\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{attachment.sharedWithCarrierCompanyName}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"attachment.visibleInEProducer\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Through eProducer\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-if=\"vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"fit-content text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"attachment.visibleToAgent || vm.createdByAnotherSystemAccount(attachment)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Agent\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"attachment.visibleInEProducer\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Through eProducer\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"attachment.comments.length <= 40\">\r" +
    "\n" +
    "                        {{attachment.comments}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"attachment.comments.length > 40\" \r" +
    "\n" +
    "                        uib-tooltip=\"{{attachment.comments}}\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{attachment.comments | limitTo:75 }}...\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column text-right nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        show-attachment-viewer-modal=\"attachment\"\r" +
    "\n" +
    "                        uib-tooltip=\"View Attachment\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        View\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <pdf-download \r" +
    "\n" +
    "                        url=\"vm.getAttachmentUrl(attachment.id)\" \r" +
    "\n" +
    "                        filename=\"{{ attachment.filename }}\" \r" +
    "\n" +
    "                        uib-tooltip=\"Download Attachment\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.editAttachment(attachment, $index)\" \r" +
    "\n" +
    "                        uib-tooltip=\"Edit Attachment\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.createdByAnotherSystemAccount(attachment)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.deleteAttachment(attachment, $index)\" \r" +
    "\n" +
    "                        uib-tooltip=\"Delete Attachment\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.createdByAnotherSystemAccount(attachment)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No attachments were found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/components/attachmentListFilterDropdown/attachmentListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"attachmentListFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>     \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Type\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.attachmentTypeId\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.attachmentTypeOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Shared with carrier\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isSharedWithCarrier\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"true\">\r" +
    "\n" +
    "                        Yes\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"false\">\r" +
    "\n" +
    "                        No\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Shared with agent\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isSharedWithAgent\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"true\">\r" +
    "\n" +
    "                        Yes\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"false\">\r" +
    "\n" +
    "                        No\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Shared through eProducer\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isSharedThroughEProducer\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"true\">\r" +
    "\n" +
    "                        Yes\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"false\">\r" +
    "\n" +
    "                        No\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/autoFeeList/autoFeeList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"bottom-margin flex\"\r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search Auto Fees\"\r" +
    "\n" +
    "        uib-tooltip=\"Search Auto Fees\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"auto-fees-refresh-button\"\r" +
    "\n" +
    "        class=\"v-center left-margin\"\r" +
    "\n" +
    "        ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <auto-fees-filter-dropdown \r" +
    "\n" +
    "        class=\"flex left-margin\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        table=\"vm.table\">\r" +
    "\n" +
    "    </auto-fees-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a  class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"samll\"\r" +
    "\n" +
    "                    ng-click=\"vm.newFeeClicked()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Fee\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Description\"\r" +
    "\n" +
    "                    sort-expression=\"Description\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"autoFee in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ng-click=\"vm.editFeeClicked(autoFee.id)\">\r" +
    "\n" +
    "                    {{autoFee.description}} \r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"button-column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(autoFee)\" \r" +
    "\n" +
    "                    uib-tooltip=\"Delete Fee\"\r" +
    "\n" +
    "                    ng-disabled=\"autoFee.hasRelatedRecords\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/components/autoFeeList/autoFeesFilterDropdown/autoFeesFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"autoFeesFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Auto Apply</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.autoApply\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\"> All </option>\r" +
    "\n" +
    "                    <option value=\"true\"> Yes </option>\r" +
    "\n" +
    "                    <option value=\"false\"> No </option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Credit Producer</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.creditProducer\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\"> All </option>\r" +
    "\n" +
    "                    <option value=\"true\"> Yes </option>\r" +
    "\n" +
    "                    <option value=\"false\"> No </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Include in broker commission calculation</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.includeInBrokerCommissionCalculation\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <option value=\"\">All</option>\r" +
    "\n" +
    "                    <option value=\"true\">Yes</option>\r" +
    "\n" +
    "                    <option value=\"false\">No</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/billingEntriesList/billingEntriesList.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "            aa-label=\"Search Billing Entries\"\r" +
    "\n" +
    "            uib-tooltip=\"Search by bond number or billing #\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"v-center left-margin small\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <billing-entries-list-filter-dropdown\r" +
    "\n" +
    "            class=\"left-margin flex\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </billing-entries-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.newInvoiceClicked()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                        Invoice Selected\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.donloadExcelClicked()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                        Download Excel\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-y=\"true\"\r" +
    "\n" +
    "        class=\"scroller no-right-padding\"\r" +
    "\n" +
    "        wheel-propagation=\"true\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table class=\"small bottom-margin nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr\r" +
    "\n" +
    "                    class=\"sortable\"\r" +
    "\n" +
    "                    ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                        ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                        ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                        ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-if=\"column.name == 'row-select-column'\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.table.allSelected\" />\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            ng-if=\"column.name !== 'row-select-column'\"\r" +
    "\n" +
    "                            text=\"{{column.heading}}\"\r" +
    "\n" +
    "                            sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                            uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr \r" +
    "\n" +
    "                    ng-repeat=\"billingEntry in vm.table.data\"\r" +
    "\n" +
    "                    ng-init=\"vm.table.rows[$index].isSelectionDisabled = !!billingEntry.invoices.length || billingEntry.originalAmount <= 0\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                        ng-switch=\"column.name\"\r" +
    "\n" +
    "                        ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                        ng-class=\"column.cellClass\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span ng-switch-when=\"row-select-column\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.table.rows[$parent.$parent.$parent.$index].isSelected\"\r" +
    "\n" +
    "                                ng-disabled=\"vm.table.rows[$parent.$parent.$parent.$index].isSelectionDisabled\" />\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-switch-when=\"Id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <span ng-show=\"billingEntry.originalAmount >= 0\">{{billingEntry.id}}</span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                ng-show=\"billingEntry.originalAmount < 0\"\r" +
    "\n" +
    "                                ng-click=\"vm.showCreditBillingEntryAssignmentModal(billingEntry.id)\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                {{billingEntry.id}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BilledTo\">\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"nowrap\"\r" +
    "\n" +
    "                                ng-show=\"!vm.eProducerAccountId\"\r" +
    "\n" +
    "                                ui-sref=\"main.customerDetail({ customerId: billingEntry.customerId, sectionView: 'billingEntries' })\"\r" +
    "\n" +
    "                                target=\"{{'customerDetail/' + billingEntry.customerId}}\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{billingEntry.customerName}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"nowrap\"\r" +
    "\n" +
    "                                ng-show=\"vm.eProducerAccountId\"\r" +
    "\n" +
    "                                ui-sref=\"main.eProducerAccountDetail({ id: billingEntry.eProducerAccountId })\"\r" +
    "\n" +
    "                                target=\"{{'eProducerAccountDetail/' + billingEntry.eProducerAccountId}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{billingEntry.eProducerAccountName}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"CustomerLookupCode\">\r" +
    "\n" +
    "                            {{billingEntry.customerLookupCode}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Customer\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"nowrap\"\r" +
    "\n" +
    "                                ui-sref=\"main.customerDetail({ customerId: billingEntry.customerId, sectionView: 'billingEntries' })\"\r" +
    "\n" +
    "                                target=\"{{'customerDetail/' + billingEntry.customerId}}\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{billingEntry.customerName}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Broker\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"nowrap\"\r" +
    "\n" +
    "                                ng-show=\"billingEntry.eProducerAccountName\"\r" +
    "\n" +
    "                                ui-sref=\"main.eProducerAccountDetail({ id: billingEntry.eProducerAccountId })\"\r" +
    "\n" +
    "                                target=\"{{'eProducerAccountDetail/' + billingEntry.eProducerAccountId}}\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                {{billingEntry.eProducerAccountName}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"CarrierLogo\">\r" +
    "\n" +
    "                            <system-account-logo\r" +
    "\n" +
    "                                system-account-id=\"billingEntry.carrierSystemAccountId\"\r" +
    "\n" +
    "                                show-labels=\"false\">\r" +
    "\n" +
    "                            </system-account-logo>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"EffectiveDate\">\r" +
    "\n" +
    "                            {{billingEntry.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"ExpirationDate\">\r" +
    "\n" +
    "                            {{billingEntry.expirationDate | dateOnly}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"TransactionEffectiveDate\">\r" +
    "\n" +
    "                            {{billingEntry.transactionEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Carrier\">\r" +
    "\n" +
    "                            {{billingEntry.carrier}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"WritingCompany\">\r" +
    "\n" +
    "                            {{billingEntry.writingCompany}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BrokerLookupCode\">\r" +
    "\n" +
    "                            {{billingEntry.brokerLookupCode}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Producer\">\r" +
    "\n" +
    "                            <span ng-show=\"!vm.canEditBillingEntries\">\r" +
    "\n" +
    "                                {{billingEntry.producer}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <user-dropdown \r" +
    "\n" +
    "                                ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                ng-model=\"billingEntry.producerUserId\"\r" +
    "\n" +
    "                                is-producer=\"true\"\r" +
    "\n" +
    "                                label=\"\"\r" +
    "\n" +
    "                                class=\"no-margin width-100\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"ClientServiceExecutive\">\r" +
    "\n" +
    "                            {{billingEntry.clientServiceExecutive}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"ClientServiceManager\">\r" +
    "\n" +
    "                            {{billingEntry.clientServiceManager}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"ClientServiceAssociate\">\r" +
    "\n" +
    "                            {{billingEntry.clientServiceAssociate}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"flex\"\r" +
    "\n" +
    "                            ng-switch-when=\"ProductionCredit\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{billingEntry.productionCredit ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BondNumber\">\r" +
    "\n" +
    "                            <a show-bond-detail-modal=\"{{billingEntry.bondId}}\">\r" +
    "\n" +
    "                                {{billingEntry.bondNumber}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BillingEntryType\">\r" +
    "\n" +
    "                            {{billingEntry.billingEntryType}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Description\">\r" +
    "\n" +
    "                            {{billingEntry.description}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"TransactionType\">\r" +
    "\n" +
    "                            {{billingEntry | transactionType}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"CreatedDateTime\">\r" +
    "\n" +
    "                            {{billingEntry.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Code\">\r" +
    "\n" +
    "                            {{billingEntry.code}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"IsOriginalEntry\">\r" +
    "\n" +
    "                            {{billingEntry.isOriginalEntry ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Invoices\">\r" +
    "\n" +
    "                            <span ng-repeat=\"invoice in billingEntry.invoices\">\r" +
    "\n" +
    "                                <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                    {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BillToType\">\r" +
    "\n" +
    "                            <span ng-show=\"billingEntry.billToType == 'BillClient'\">Bill Client</span>\r" +
    "\n" +
    "                            <span ng-show=\"billingEntry.billToType == 'BillBrokerNet'\">Bill Broker (Net)</span>\r" +
    "\n" +
    "                            <span ng-show=\"billingEntry.billToType == 'BillBrokerGross'\">Bill Broker (Gross)</span>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"OriginalAmount\">\r" +
    "\n" +
    "                            {{billingEntry.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"AmountReceived\">\r" +
    "\n" +
    "                            {{billingEntry.amountReceived | currency: '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"AmountDue\">\r" +
    "\n" +
    "                            {{billingEntry.amountDue | currency: '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"BrokerCommission\">\r" +
    "\n" +
    "                            {{billingEntry.brokerCommission | currency: '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"Commission\">\r" +
    "\n" +
    "                            {{billingEntry.commission | currency: '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            class=\"flex\" \r" +
    "\n" +
    "                            ng-switch-when=\"IsProductionCreditPaid\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{billingEntry.isProductionCreditPaid ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-switch-when=\"CarrierPayableOriginalAmount\">\r" +
    "\n" +
    "                            {{billingEntry.carrierPayableOriginalAmount | currency : '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"flex\"\r" +
    "\n" +
    "                            ng-switch-when=\"CarrierPayableAmountDue\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{billingEntry.carrierPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"flex\"\r" +
    "\n" +
    "                            ng-switch-when=\"BrokerPayableAmountDue\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{billingEntry.brokerPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No billing entries found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/billingEntriesListFilterDropdown/billingEntriesListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"billingEntriesListFilterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> \r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul\r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <li\r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Invoiced</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.invoiced\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\">Any</option>\r" +
    "\n" +
    "                        <option value=\"true\">Invoiced</option>\r" +
    "\n" +
    "                        <option value=\"false\">Not Invoiced</option>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Billing Entry Type</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.billingEntryType\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\">Any</option>\r" +
    "\n" +
    "                        <option value=\"Premium\">Premium</option>\r" +
    "\n" +
    "                        <option value=\"Fee\">Fee</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Original vs Corrections</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.isOriginalEntry\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                        <option value=\"\">All</option>\r" +
    "\n" +
    "                        <option value=\"true\">Originals</option>\r" +
    "\n" +
    "                        <option value=\"false\">Corrections</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Code</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.code\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.codeOptions\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.producerUserId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                        options=\"vm.producerUserOptions\"\r" +
    "\n" +
    "                        aa-label=\"Producer\"\r" +
    "\n" +
    "                        aa-unselected-text=\"Any\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Date Between</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.startDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.endDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Amount Received Between</label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmountReceived\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmountReceived\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Amount Due Between</label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmountDue\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmountDue\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/bondChangeManager/bondChangeManager.html',
    "<div class=\"top-margin\">\r" +
    "\n" +
    "    <h5 class=\"bottom-margin-thin\"> Bond Changes </h5>\r" +
    "\n" +
    "    <table ng-show=\"vm.hasChanges\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>What is being changed?</th>\r" +
    "\n" +
    "                <th>Original Value</th>\r" +
    "\n" +
    "                <th>New Value</th>\r" +
    "\n" +
    "                <th class=\"text-right\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        id=\"bond-change-manager-add-change-button\"\r" +
    "\n" +
    "                        ng-click=\"vm.addChange()\"\r" +
    "\n" +
    "                        class=\"mini\"\r" +
    "\n" +
    "                        uib-tooltip=\"Add another change\"\r" +
    "\n" +
    "                        tooltip-placement=\"left\">\r" +
    "\n" +
    "                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"change in vm.changes\">\r" +
    "\n" +
    "                <td class=\"rider-change-selection\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        id=\"{{'bond-change-manager-change-type-select-' + $index}}\"\r" +
    "\n" +
    "                        aa-field-group=\"change.changeType\"\r" +
    "\n" +
    "                        aa-label=\"Change Type\"\r" +
    "\n" +
    "                        aa-option-text=\"label\"\r" +
    "\n" +
    "                        aa-option-value=\"enumValue\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        ng-change=\"vm.changeTypeSelected(change)\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.changeTypes\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.$isPerson\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin-big\"\r" +
    "\n" +
    "                            aa-field-group=\"change.personId\"\r" +
    "\n" +
    "                            aa-label=\"Person\"\r" +
    "\n" +
    "                            ng-change=\"vm.personSelected(change)\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.peopleOptions\"\r" +
    "\n" +
    "                            ng-required=\"change.$isPerson\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.$isCompany\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin-big\"\r" +
    "\n" +
    "                            aa-field-group=\"change.companyId\"\r" +
    "\n" +
    "                            aa-label=\"Company\"\r" +
    "\n" +
    "                            ng-change=\"vm.companySelected(change)\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.companyOptions\"\r" +
    "\n" +
    "                            ng-required=\"change.$isCompany\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.$isQuestion\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin-big\"\r" +
    "\n" +
    "                            aa-field-group=\"change.bondAdditionalQuestionResponseId\"\r" +
    "\n" +
    "                            aa-label=\"Question\"\r" +
    "\n" +
    "                            ng-change=\"vm.questionSelected(change)\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.bondAdditionalQuestionResponsesOptions\"\r" +
    "\n" +
    "                            ng-required=\"change.$isQuestion\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"rider-change-originals\">\r" +
    "\n" +
    "                    <span ng-if=\"vm.renderChangeTypeAsDate(change.changeType)\">\r" +
    "\n" +
    "                        {{ change.originalValue | date : 'MM/dd/yyyy' }}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-if=\"vm.renderChangeTypeAsCurrency(change.changeType)\">\r" +
    "\n" +
    "                        {{ change.originalValue | currency : '$'}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-if=\"vm.renderChangeTypeAsExpression(change.changeType)\">\r" +
    "\n" +
    "                        {{ change.originalValue }}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-if=\"vm.renderChangeTypeAsHtml(change.changeType)\"\r" +
    "\n" +
    "                        ng-bind-html=\"change.originalValue\">\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"rider-change-values\">\r" +
    "\n" +
    "                    <!-- Bond -->\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'NameOnBond'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_nameOnBond\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Name on bond (principal)\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'NameOnBond'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'WritingCompanyId'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            id=\"issue-bond-writing-company\"\r" +
    "\n" +
    "                            aa-label=\"Writing Company\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_writingCompanyId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"groupSelect\"\r" +
    "\n" +
    "                            class=\"small\"\r" +
    "\n" +
    "                            groups=\"vm.writingCompanyOptionGroups\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'WritingCompanyId'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'EffectiveDate'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_effectiveDate\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Bond Effective Date\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'EffectiveDate'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ExpirationDate'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_expirationDate\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Bond Expiration Date\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ExpirationDate'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'BondAmount'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            id=\"{{'bond-change-manager-bond-amount-new-value-' + $index}}\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_bondAmount\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Bond Amount\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'BondAmount'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'BondNumber'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_bondNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Bond Number\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'BondNumber'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'BondTypeId'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select\r" +
    "\n" +
    "                            ng-model=\"change.$newValue_bondType\"\r" +
    "\n" +
    "                            reset-search-input=\"false\"\r" +
    "\n" +
    "                            theme=\"app/components/uiSelect/a3\"\r" +
    "\n" +
    "                            class=\"no-margin\"\r" +
    "\n" +
    "                            name=\"bondTypeId\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'BondTypeId'\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <ui-select-match\r" +
    "\n" +
    "                                id=\"{{'bond-change-manager-bond-type-new-value-' + $index}}\"\r" +
    "\n" +
    "                                placeholder=\"Search Bond Types\"\r" +
    "\n" +
    "                                label=\"Bond Type\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <span\r" +
    "\n" +
    "                                    id=\"{{'bond-change-manager-bond-type-new-value-' + $index + '-selected'}}\">\r" +
    "\n" +
    "                                    {{$select.selected.name}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ui-select-choices\r" +
    "\n" +
    "                                repeat=\"bondType in vm.bondTypeSearch.matches track by $index\"\r" +
    "\n" +
    "                                refresh=\"vm.searchBondTypes($select.search)\"\r" +
    "\n" +
    "                                refresh-delay=\"100\">\r" +
    "\n" +
    "                                <div\r" +
    "\n" +
    "                                    id=\"{{'bond-change-manager-bond-type-new-value-selection-' + $index}}\"\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-bind-html=\"bondType.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                            </ui-select-choices>\r" +
    "\n" +
    "                        </ui-select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <!-- Individual -->\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualName'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Prefix (Mr., Ms., etc.)\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualPrefix\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"First Name\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualFirstName\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualName'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Middle Name\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMiddleName\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Last Names\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualLastName\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualName'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Suffix (Jr., Sr., III, etc.)\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSuffix\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualSsn'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSsn\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"SSN\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"ssn\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSsn'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualDateOfBirth'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualDateOfBirth\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Date of Birth\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualDateOfBirth'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualDriversLicenseNumber'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualDriversLicenseNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Drivers License Number\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualDriversLicenseNumber'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualDriversLicenseState'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualDriversLicenseState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Drivers License State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualDriversLicenseState'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualHomePhone'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualHomePhone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Home Phone\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualHomePhone'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualCellPhone'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualCellPhone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Cell Phone\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualCellPhone'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualFax'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualFax\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Fax\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualFax'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualEmail'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmail\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Email\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmail'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualEmployerName'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Employer Name\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmployerName'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualPhysicalAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Street Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Appt Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualPhysicalAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualMailingAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Mailing Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Appt Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualMailingAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMailCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualEmployerAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Employer Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmployerAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Suite Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmployerAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmployerAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualEmployerAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualEmployerCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualJobTitle'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualJobTitle\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Job Title\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualJobTitle'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualYearsExperience'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualYearsExperience\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Years Experience\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualYearsExperience'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualGender'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualGender\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Gender\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"gender\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualGender'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualResidenceType'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualResidenceType\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Residence Type\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"residenceType\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualResidenceType'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualDateMovedToResidence'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualDateMovedToResidence\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Last Move Date\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualDateMovedToResidence'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualMaritalStatus'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualMaritalStatus\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Marital Status\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"maritalStatus\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualMaritalStatus'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualSpouseName'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseFirstName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"First Name\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseMiddleName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Middle Name\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseLastName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Last Name\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualSpouseSsn'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseSsn\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Spouse SSN\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"ssn\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSpouseSsn'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualSpouseEmail'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseEmail\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Spouse Email\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSpouseEmail'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualSpouseCellPhone'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseCellPhone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Spouse Phone\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSpouseCellPhone'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualSpouseDateOfBirth'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseDateOfBirth\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Spouse DOB\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSpouseDateOfBirth'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualOwnershipPercent'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualOwnershipPercent\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Business Onership Percentage\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualOwnershipPercent'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'IndividualIsUSCitizen'\">\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-bind-html=\"change.$newValue_individualIsUSCitizen\">\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'IndividualSpouseGender'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_individualSpouseGender\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Spouse Gender\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"gender\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'IndividualSpouseGender'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <!-- Company -->\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyName'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Company Name\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyName'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyDba'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyDba\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Company DBA\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyDba'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyType'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyType\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Company Type\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyType'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyPhone'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyPhone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Phone\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyPhone'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyFax'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyFax\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Fax\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyFax'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyEmail'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyEmail\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Company Email\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyEmail'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyPhysicalAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Street Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Appt Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyPhysicalAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyPhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyMailingAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Mailing Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Appt Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyMailingAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyMailCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyDateFormed'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyDateFormed\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Date Formed\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyDateFormed'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyNumberOfOwners'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyNumberOfOwners\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Number of Owners\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyNumberOfOwners'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'CompanyStateOfIncorporation'\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyStateOfIncorporation\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State of Incorporation\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyStateOfIncorporation'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'CompanyFein'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyFein\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Company FEIN\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyFein'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"change.changeType === 'CompanyNumberOfEmployees'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_companyNumberOfEmployees\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Number of Employees\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'CompanyNumberOfEmployees'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <!-- Obligee -->\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeName'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeName\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Name\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeName'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeContact1'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeContact1\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Contact 1\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeContact1'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeContact2'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeContact2\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Contact 2\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeContact2'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeContact3'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeContact3\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Contact 3\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeContact3'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeePhone'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhone\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Phone\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeePhone'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeFax'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeFax\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Fax\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeFax'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeEmail'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeEmail\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee E-mail\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeEmail'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeWebsiteURL'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeWebsiteURL\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"url\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Obligee Website URL\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeWebsiteURL'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeePhysicalAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Street Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeePhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Apt/Suite\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeePhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeePhysicalAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeePhysicalAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeePhysicalCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'ObligeeMailAddress'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailAddress\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Mailing Address\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailApptNumber\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Apt/Suite\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailCity\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"City\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailState\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"State\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeMailingAddress'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailZip\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Zip Code\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'ObligeeMailingAddress'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_obligeeMailCounty\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"County\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <!-- Form Question -->\r" +
    "\n" +
    "                    <div ng-if=\"change.changeType === 'BondFormInformation'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"change.$newValue_bondFormInformation\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-label=\"Response\"\r" +
    "\n" +
    "                            ng-required=\"change.changeType === 'BondFormInformation'\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"rider-change-remove\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"charcoal mini\"\r" +
    "\n" +
    "                        ng-click=\"vm.removeChange($index)\"\r" +
    "\n" +
    "                        uib-tooltip=\"Remove change from rider\"\r" +
    "\n" +
    "                        tooltip-placement=\"left\">\r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"initial-state white\"\r" +
    "\n" +
    "        ng-show=\"!vm.hasChanges\">\r" +
    "\n" +
    "        <div class=\"empty-backdrop\">\r" +
    "\n" +
    "            <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <table>\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>&nbsp;</td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>&nbsp;</td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>&nbsp;</td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>&nbsp;</td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>&nbsp;</td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"initial-state-body\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                id=\"bond-change-manager-empty-state-add-change-button\"\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                ng-click=\"vm.addChange()\">\r" +
    "\n" +
    "                Add Bond Change\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/bondList/bondList.html',
    "<div>\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"bottom-margin group-by-tbody\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th class=\"row-select-column\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.table.allSelected\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Status\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.status\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Bond Number\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.bondNumber\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th ng-show=\"vm.showNameOnBondColumn\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Name on Bond\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.nameOnBond\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th ng-show=\"vm.showCustomerNameColumn\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Customer\"\r" +
    "\n" +
    "                        sort-expression=\"customers.name\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Bond Type\"\r" +
    "\n" +
    "                        sort-expression=\"bondTypes.name\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Bond Amount\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.bondAmount\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Writing Company\"\r" +
    "\n" +
    "                        sort-expression=\"writingCompanies.name\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Eff Date\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.effectiveDate\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Exp Date\"\r" +
    "\n" +
    "                        sort-expression=\"bonds.expirationDate\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody ng-repeat=\"bond in vm.table.data\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td class=\"row-select-column\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.table.rows[$index].isSelected\" \r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td\r" +
    "\n" +
    "                    class=\"nowrap\" \r" +
    "\n" +
    "                    ng-bind-html=\"bond | bondStatus\">\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.bondNumber}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        uib-tooltip=\"View Bond Line\"\r" +
    "\n" +
    "                        ng-show=\"bond.bondAccountId\"\r" +
    "\n" +
    "                        ng-click=\"vm.showBondAccountModal(bond)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-handshake\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"nowrap\"\r" +
    "\n" +
    "                    ng-show=\"vm.showCustomerNameColumn\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ui-sref=\"main.customerDetail({ customerId: bond.customerId })\"\r" +
    "\n" +
    "                        target=\"_blank\" >\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{bond.customerName}}\r" +
    "\n" +
    "                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td ng-show=\"vm.showNameOnBondColumn\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{bond.nameOnBond}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.bondTypeName}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{bond.writingCompanyName}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{bond.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-center\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column\">\r" +
    "\n" +
    "                    <button show-bond-detail-modal=\"bond.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Bond Details\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "        message=\"No bonds were found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/bondListFilterDropdown/bondListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"bondListFilterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>     \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Status\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.bondStatusFilter\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Active\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Active\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Active|None\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Active - Renewal Not Started\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Active|ApplicationSubmitted\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Active - Renewal Started\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"PendingCancellation\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Active - Pending Cancellation\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Cancelled\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Cancelled\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"PendingReinstatement\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Cancelled - Pending Reinstatement\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Expired\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Expired\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Effective Date Between\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.effectiveStartDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"between-label\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    and\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.effectiveEndDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Expiration Date Between\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.expirationStartDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"between-label\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    and\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.expirationEndDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/bondTransactionBillingEntryList/bondTransactionBillingEntryList.html',
    "<div ng-form=\"bondTransactionBillingEntryListMode\">\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex bottom-margin-thin\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"left-margin-auto mini\"\r" +
    "\n" +
    "            ng-click=\"vm.addBillingEntry()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        ng-show=\"vm.billingEntries.length\"\r" +
    "\n" +
    "        class=\"bottom-margin table small v-align-middle\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th class=\"fit-content\">#</th>\r" +
    "\n" +
    "                <th class=\"text-center\">Type</th>\r" +
    "\n" +
    "                <th>Description</th>\r" +
    "\n" +
    "                <th class=\"text-center\">Invoice #</th>\r" +
    "\n" +
    "                <th class=\"text-center\">Billed</th>\r" +
    "\n" +
    "                <th class=\"text-center\">Commission</th>\r" +
    "\n" +
    "                <th class=\"text-center\">Balance</th>\r" +
    "\n" +
    "                <th \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"fit-content text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Producer\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th \r" +
    "\n" +
    "                    ng-show=\"vm.showBrokerCommission\" \r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Bill To\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th \r" +
    "\n" +
    "                    ng-show=\"vm.showBrokerCommission\"\r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Broker Commission\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"fit-content\"></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"billingEntry in vm.billingEntries\">\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    {{billingEntry.id}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"billingEntry.id\">\r" +
    "\n" +
    "                        {{billingEntry.billingEntryType}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        ng-show=\"!billingEntry.id\"\r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.billingEntryType\"\r" +
    "\n" +
    "                        options=\"vm.billingEntryTypeOptions\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small fixed-width-xs\"\r" +
    "\n" +
    "                        ng-change=\"vm.billintEntryTypeChanged(billingEntry)\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.description\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small width-200\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex top-margin-thin width-200\">\r" +
    "\n" +
    "                        <div class=\"v-center right-margin-thin\">\r" +
    "\n" +
    "                            Code: \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <div class=\"fill\">\r" +
    "\n" +
    "                            <transaction-type-code-dropdown\r" +
    "\n" +
    "                                ng-show=\"billingEntry.billingEntryType === 'Premium'\"\r" +
    "\n" +
    "                                code=\"billingEntry.code\"\r" +
    "\n" +
    "                                aa-fg-class=\"no-margin small\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                ng-required=\"billingEntry.billingEntryType === 'Premium'\" >\r" +
    "\n" +
    "                            </transaction-type-code-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <fee-code-dropdown\r" +
    "\n" +
    "                                ng-show=\"billingEntry.billingEntryType === 'Fee'\"\r" +
    "\n" +
    "                                fee-code=\"billingEntry.code\"\r" +
    "\n" +
    "                                aa-fg-class=\"no-margin small\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                ng-required=\"billingEntry.billingEntryType === 'Fee'\" >\r" +
    "\n" +
    "                            </fee-code-dropdown>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-repeat=\"invoice in billingEntry.invoices\">\r" +
    "\n" +
    "                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <span ng-show=\"billingEntry.id\">\r" +
    "\n" +
    "                        {{billingEntry.originalAmount | currency: \"$\"}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        ng-show=\"!billingEntry.id\"\r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.originalAmount\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small fixed-width-xs\"\r" +
    "\n" +
    "                        ng-blur=\"vm.originalAmountInputBlurred(billingEntry)\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.commission\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small fixed-width-xs \"\r" +
    "\n" +
    "                        ng-show=\"billingEntry.billingEntryType === 'Premium'\"\r" +
    "\n" +
    "                        ng-required=\"billingEntry.billingEntryType === 'Premium'\"\r" +
    "\n" +
    "                        ng-blur=\"vm.calculateBrokerCommission(billingEntry)\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    {{billingEntry.amountDue | currency:\"$\"}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.producerUserId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"fixed-width-sm no-margin\"\r" +
    "\n" +
    "                        options=\"vm.producerUserOptions\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.productionCredit\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Credit Producer\"\r" +
    "\n" +
    "                        aa-label-strategy=\"inline\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-show=\"vm.showBrokerCommission\"\r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.billToType\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin width-125\"\r" +
    "\n" +
    "                        ng-disabled=\"billingEntry.invoices.length\"\r" +
    "\n" +
    "                        ng-change=\"vm.calculateBrokerCommission(billingEntry)\">\r" +
    "\n" +
    "                    </select>                    \r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td\r" +
    "\n" +
    "                    ng-show=\"vm.showBrokerCommission\"\r" +
    "\n" +
    "                    class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"billingEntry.brokerCommission\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin small fixed-width-xs\"\r" +
    "\n" +
    "                        ng-disabled=\"billingEntry.invoices.length\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"mini\"\r" +
    "\n" +
    "                        ng-show=\"!billingEntry.id\"\r" +
    "\n" +
    "                        ng-click=\"vm.removeBillingEntry($index)\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"mini nowrap\"\r" +
    "\n" +
    "                        ng-if=\"billingEntry.id && billingEntry.originalAmount < 0\"\r" +
    "\n" +
    "                        ng-click=\"vm.showCreditBillingEntryAssignmentModal(billingEntry.id)\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        Assign Credit\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"!vm.billingEntries.length\"\r" +
    "\n" +
    "        message=\"No billing entries for this transaction\" >\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/bondTransactionList/bondTransactionList.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <table\r" +
    "\n" +
    "        class=\"group-by-tbody\"\r" +
    "\n" +
    "        ng-show=\"vm.transactions.length > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "                <th>Transaction Type</th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    Transaction Date\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    Eff / Exp Dates\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    Premium\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th \r" +
    "\n" +
    "                    class=\"text-center\"\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    Fees\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    Commission\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tbody ng-repeat=\"transaction in vm.transactions\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"cursor-pointer\"\r" +
    "\n" +
    "                    id=\"{{'bond-transaction-list-expand-cell-' + $index}}\"\r" +
    "\n" +
    "                    ng-click=\"vm.transactionExpanderClicked(transaction)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"transaction.transactionType !== 'Import'\"\r" +
    "\n" +
    "                        ng-class=\"{'fa fa-caret-right': !transaction.showDetail, 'fa fa-caret-down': transaction.showDetail}\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"fa fa-flag red\"\r" +
    "\n" +
    "                        ng-show=\"transaction.expedited\"\r" +
    "\n" +
    "                        tooltip-placement=\"right\"\r" +
    "\n" +
    "                        uib-tooltip=\"Expedited Shipping and Processing Requested\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{ transaction | transactionType }}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <img\r" +
    "\n" +
    "                        class=\"integration-icon\"\r" +
    "\n" +
    "                        ng-show=\"transaction.isExportedToAms360\"\r" +
    "\n" +
    "                        src=\"/img/icons/Vertafore_logo.png\"\r" +
    "\n" +
    "                        alt=\"From Ams 360\"\r" +
    "\n" +
    "                        uib-tooltip=\"Exported to AMS360\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"text-center\">{{ transaction.createdDateTime | date:'MM/dd/yyyy' }}</td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    id=\"{{'bond-transaction-list-term-dates-cell-' + $index}}\"\r" +
    "\n" +
    "                    class=\"text-center\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{ transaction.transactionEffectiveDate | dateOnly }} \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"transaction.transactionType === 'NewBusiness' || transaction.transactionType === 'Renewal'\">\r" +
    "\n" +
    "                        - \r" +
    "\n" +
    "                        {{ transaction.expirationDate | dateOnly }}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    id=\"{{'bond-transaction-list-premium-cell-' + $index}}\"\r" +
    "\n" +
    "                    class=\"text-center\">{{ transaction.premium - transaction.refundedPremium | currency:'$' }}</td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    id=\"{{'bond-transaction-list-fees-cell-' + $index}}\"\r" +
    "\n" +
    "                    class=\"text-center\"\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{ transaction.fees | currency:'$' }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    id=\"{{'bond-transaction-list-commission-cell-' + $index}}\"\r" +
    "\n" +
    "                    class=\"text-center\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{ transaction.commission | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"text-right button-column\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        ng-show=\"transaction.transactionType !== 'Import'\"\r" +
    "\n" +
    "                        show-download-documents-modal=\"transaction.id\"\r" +
    "\n" +
    "                        uib-tooltip=\"Download Documents\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    </button><button\r" +
    "\n" +
    "                        ng-if=\"vm.epicIntegrationEnabled\"\r" +
    "\n" +
    "                        ng-disabled=\"transaction.exportToEpicStatus == 'Success' || transaction.exportToEpicStatus == 'Error'\"\r" +
    "\n" +
    "                        export-to-epic=\"{transaction: transaction, busyIndicator: vm.busyIndicator}\"\r" +
    "\n" +
    "                        uib-tooltip=\"Export to Epic\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-cloud-upload-alt\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tr ng-show=\"transaction.showDetail\">\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    ng-form=\"transactionForm\"\r" +
    "\n" +
    "                    colspan=\"8\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-if=\"!vm.isCarrier\" \r" +
    "\n" +
    "                            class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"v-center\"\r" +
    "\n" +
    "                                ng-click=\"vm.showHistory(transaction)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-history\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"small primary left-margin-auto v-center\"\r" +
    "\n" +
    "                                aa-submit-form=\"vm.save(transaction)\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                Save\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Billing Entries\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <bond-transaction-billing-entry-list\r" +
    "\n" +
    "                                bond-transaction-id=\"transaction.id\"\r" +
    "\n" +
    "                                billing-entries=\"transaction.details.billingEntries\" \r" +
    "\n" +
    "                                eproducer-account-id=\"transaction.details.eproducerAccountId\"\r" +
    "\n" +
    "                                eproducer-account-type=\"transaction.details.eproducerAccountType\"\r" +
    "\n" +
    "                                default-producer-user-id=\"transaction.details.producerUserId\"\r" +
    "\n" +
    "                                default-broker-bill-to-type=\"transaction.details.billToType\"\r" +
    "\n" +
    "                                transaction-type=\"transaction.transactionType\">\r" +
    "\n" +
    "                            </bond-transaction-billing-entry-list>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-if=\"!vm.isCarrier\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Shipping Info\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"row\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.expedited\" \r" +
    "\n" +
    "                                    aa-label=\"Expedited Shipping & Processing\" \r" +
    "\n" +
    "                                    ng-change=\"vm.expeditedChanged()\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-12 v-center\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                    aa-label-class=\"small\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailTo\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-12\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailAddress\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-6\" >\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailSuiteAptNumber\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-6\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailZip\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                    aa-field-group-strategy=\"zip\" />\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailCity\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <select \r" +
    "\n" +
    "                                    aa-field-group=\"transaction.details.mailState\" \r" +
    "\n" +
    "                                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                    aa-field-group-strategy=\"state\">\r" +
    "\n" +
    "                                </select>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-show=\"transaction.transactionType == 'Cancellation'\">\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Cancellation Date\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"transaction.details.transactionEffectiveDate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                                ng-required=\"transaction.transactionType == 'Cancellation'\" />\r" +
    "\n" +
    "                        </div>                    \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-show=\"transaction.transactionType == 'Reinstatement'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Reinstatement Date\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"transaction.details.transactionEffectiveDate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                                ng-required=\"transaction.transactionType == 'Reinstatement'\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Attorney-In-Fact\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                aa-field-group=\"transaction.details.attorneyInFactUserId\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.aifOptions\"\r" +
    "\n" +
    "                                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-show=\"vm.showChangeDescription(transaction)\">\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Changes\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                class=\"text-left\"\r" +
    "\n" +
    "                                ng-repeat=\"change in transaction.details.bondChanges\"\r" +
    "\n" +
    "                                id=\"{{'bond-transaction-list-change-list-' + $parent.$index + '-' + $index}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{ change.description }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                                Comments\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                                class=\"top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <b>Reason Code:</b> \r" +
    "\n" +
    "                                {{ transaction.details.reasonCode }} - {{ transaction.details.reasonText }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <textarea\r" +
    "\n" +
    "                                ng-disabled=\"vm.isCarrier\"\r" +
    "\n" +
    "                                aa-field-group=\"transaction.details.agencyComments\"\r" +
    "\n" +
    "                                id=\"{{'bond-transaction-list-agency-comments-textarea-' + $index}}\">\r" +
    "\n" +
    "                            </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <textarea\r" +
    "\n" +
    "                                ng-disabled=\"!vm.isCarrier\"\r" +
    "\n" +
    "                                aa-field-group=\"transaction.details.carrierComments\"\r" +
    "\n" +
    "                                id=\"{{'bond-transaction-list-carrier-comments-textarea-' + $index}}\">\r" +
    "\n" +
    "                            </textarea>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/bondTypeRequestForm/bondTypeRequest.html',
    "<div \r" +
    "\n" +
    "    ng-form \r" +
    "\n" +
    "    novalidate \r" +
    "\n" +
    "    name=\"bondtypeform\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Bond Type Info\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input  \r" +
    "\n" +
    "                aa-field-group=\"vm.request.name\"  \r" +
    "\n" +
    "                aa-label=\"Name of the Bond Type\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-12\" \r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <input  \r" +
    "\n" +
    "                aa-field-group=\"vm.request.bondAmount\"  \r" +
    "\n" +
    "                aa-field-group-strategy=\"money\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-6\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <sfaa-code-selection \r" +
    "\n" +
    "                change=\"vm.sfaaCodeChanged()\" \r" +
    "\n" +
    "                sfaa-code=\"vm.selectedSfaaCode\" \r" +
    "\n" +
    "                class=\"col-md-6\">\r" +
    "\n" +
    "            </sfaa-code-selection>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Obligee\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input  \r" +
    "\n" +
    "                aa-field-group=\"vm.request.obligeeName\"  \r" +
    "\n" +
    "                aa-label=\"Obligee Name\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-12\"/>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <select  \r" +
    "\n" +
    "                aa-field-group=\"vm.request.obligeeRequisitioningState\"  \r" +
    "\n" +
    "                aa-field-group-strategy=\"state\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-6\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input  \r" +
    "\n" +
    "                aa-field-group=\"vm.request.obligeeRequisitioningDepartment\"  \r" +
    "\n" +
    "                aa-label=\"Department\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-6\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <address \r" +
    "\n" +
    "            object=\"vm.address\" \r" +
    "\n" +
    "            changed=\"vm.addressChanged(object, changedProperty)\">\r" +
    "\n" +
    "        </address>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5>Contact Info</h5>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-4\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <input  \r" +
    "\n" +
    "                    aa-field-group=\"vm.request.obligeePhone\"  \r" +
    "\n" +
    "                    aa-field-group-strategy=\"phone\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <input aa-field-group=\"vm.request.obligeeContact1\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-4\">\r" +
    "\n" +
    "                <input  \r" +
    "\n" +
    "                    aa-field-group=\"vm.request.obligeeFax\"  \r" +
    "\n" +
    "                    aa-field-group-strategy=\"phone\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input aa-field-group=\"vm.request.obligeeContact2\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-4\">\r" +
    "\n" +
    "                <input  \r" +
    "\n" +
    "                    aa-field-group=\"vm.request.obligeeEmail\"  \r" +
    "\n" +
    "                    aa-field-group-strategy=\"email\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input aa-field-group=\"vm.request.obligeeContact3\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.request.obligeeWebsiteURL\"  \r" +
    "\n" +
    "                aa-field-group-strategy=\"url\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-md-12\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/bondTypeSelectionForm/bondTypeSelection.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"bondtypeform\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header class=\"flex\">\r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                Choose Bond Type\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.table.queryOptions.favorites\" \r" +
    "\n" +
    "                aa-label=\"Only show my favorites\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                aa-fg-class=\"left-margin-auto v-center\" \r" +
    "\n" +
    "                ng-change=\"vm.showOnlyFavoritesClicked()\"/>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                all-option=\"showAllStateOption\" \r" +
    "\n" +
    "                aa-field-group=\"vm.table.queryOptions.requisitioningState\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                aa-unselected-text=\"All States\" \r" +
    "\n" +
    "                ng-change=\"vm.loadBondTypeMatches()\" \r" +
    "\n" +
    "                aa-label=\"Filter by state\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <sfaa-code-selection \r" +
    "\n" +
    "                sfaa-code-id=\"vm.table.queryOptions.sfaaCodeId\" \r" +
    "\n" +
    "                sfaa-code=\"vm.sfaaCode\" \r" +
    "\n" +
    "                change=\"vm.loadBondTypeMatches()\" \r" +
    "\n" +
    "                class=\"col-sm-6\">\r" +
    "\n" +
    "            </sfaa-code-selection>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                id=\"bond-type-selection-table-search-input\"\r" +
    "\n" +
    "                aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-12\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.table.queryOptions.isActiveOnly\"\r" +
    "\n" +
    "                    aa-label=\"Only show active bond types\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    ng-change=\"vm.loadBondTypeMatches()\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"col-sm-6 top-margin text-right\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    class=\"left-margin\"\r" +
    "\n" +
    "                    ng-click=\"vm.requestNewBondType()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Request a new bond type\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"top-margin\">    \r" +
    "\n" +
    "    <table\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Bond Type\"\r" +
    "\n" +
    "                        sort-expression=\"BondTypes.Name\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Bond Amount\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody ng-repeat=\"bondType in vm.table.data\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td class=\"text-left\">\r" +
    "\n" +
    "                    <span>\r" +
    "\n" +
    "                        <i \r" +
    "\n" +
    "                            ng-click=\"vm.toggleFavorite(bondType)\" \r" +
    "\n" +
    "                            ng-class=\"{'fa fa-star' : bondType.favoriteId != null, 'far fa-star' : !(bondType.favoriteId != null)}\" class=\"favorite\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{bondType.name}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td class=\"text-left\">\r" +
    "\n" +
    "                    <span ng-show=\"bondType.bondAmountType == 'Fixed'\">\r" +
    "\n" +
    "                        {{bondType.bondAmount | currency:\"$\"}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"bondType.bondAmountType == 'UserDefined'\">\r" +
    "\n" +
    "                        Between: {{bondType.minimumBondAmount | currency:\"$\"}} - {{bondType.maximumBondAmount | currency:\"$\"}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"bondType.bondAmountType == 'Variable'\">\r" +
    "\n" +
    "                        {{bondType.bondAmountOptions}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            ng-show=\"vm.hideEmailButton\" \r" +
    "\n" +
    "                            ng-click=\"vm.emailApplicationClick(bondType)\" \r" +
    "\n" +
    "                            tooltip=\"Send application email\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <i class=\"fa fa-envelope\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            id=\"{{'bond-type-selection-table-select-button-' + $index}}\"\r" +
    "\n" +
    "                            ng-click=\"vm.selectBondType(bondType)\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span class=\"right-margin\">\r" +
    "\n" +
    "                                Select\r" +
    "\n" +
    "                            </span> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-arrow-circle-right\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        message=\"No bond types found matching your search\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/boundGrid/boundGrid.html',
    "<div>\r" +
    "\n" +
    "    <div class=\"{{wrapperClass}} bottom-margin\">\r" +
    "\n" +
    "        <div busy-indicator=\"busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table\r" +
    "\n" +
    "            class=\"{{'manual-stripe ' + tableClass}}\"\r" +
    "\n" +
    "            ng-show=\"table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-if=\"detailTemplate\"></th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-repeat=\"col in columns\"\r" +
    "\n" +
    "                        class=\"{{ col.headerCssClass }}\"\r" +
    "\n" +
    "                        ng-show=\"col.show\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            id=\"{{id}}-sort-{{col.sortOn}}-wrapper\"\r" +
    "\n" +
    "                            ng-if=\"col.enableSorting\"\r" +
    "\n" +
    "                            text=\"{{ col.headerText }}\"\r" +
    "\n" +
    "                            sort-expression=\"{{ col.sortOn }}\"\r" +
    "\n" +
    "                            table-sorter=\"table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"!col.enableSorting\">\r" +
    "\n" +
    "                            {{ col.headerText }}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody ng-repeat=\"item in table.data track by item.id\">\r" +
    "\n" +
    "                <tr\r" +
    "\n" +
    "                    ng-class=\"{ 'clickable-table-row': detailTemplate, 'alt': $index % 2 === 0 }\">\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-if=\"detailTemplate\"\r" +
    "\n" +
    "                        ng-click=\"item.$showDetail = !item.$showDetail\"\r" +
    "\n" +
    "                        class=\"clickable-table-cell\">\r" +
    "\n" +
    "                        <i\r" +
    "\n" +
    "                            ng-class=\"{ 'fa fa-caret-down': item.$showDetail, 'fa fa-caret-right': !item.$showDetail }\"></i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-repeat=\"col in columns\"\r" +
    "\n" +
    "                        ng-show=\"col.show\"\r" +
    "\n" +
    "                        ng-class=\"{ 'text-right': col.columnType === 'buttonColumn', '{{ col.cssClass }}': col.cssClass }\"\r" +
    "\n" +
    "                        ng-click=\"item.$showDetail = !item.$showDetail\">\r" +
    "\n" +
    "                        <bound-grid-table-column\r" +
    "\n" +
    "                            column=\"col\"\r" +
    "\n" +
    "                            item=\"item\"\r" +
    "\n" +
    "                            update-item=\"updateItem\"\r" +
    "\n" +
    "                            delete-item=\"delete\"\r" +
    "\n" +
    "                            key-field=\"{{ keyField }}\"\r" +
    "\n" +
    "                            label-field=\"{{ labelField }}\">\r" +
    "\n" +
    "                        </bound-grid-table-column>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tr\r" +
    "\n" +
    "                    ng-if=\"detailTemplate\"\r" +
    "\n" +
    "                    ng-show=\"item.$showDetail\"\r" +
    "\n" +
    "                    class=\"table-details-row\"\r" +
    "\n" +
    "                    ng-class=\"{ 'alt': $index % 2 === 0 }\">\r" +
    "\n" +
    "                    <td colspan=\"{{ columns.length + 1 }}\">\r" +
    "\n" +
    "                        <span bound-grid-details-template-compiler></span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No entries were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-transclude></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        id=\"{{id}}-pager-wrapper\"\r" +
    "\n" +
    "        ng-show=\"table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/boundGrid/boundGrid.tablecolumn.html',
    "<span ng-switch=\"col.columnType\"\r" +
    "\n" +
    "      ng-mouseover=\"cellHovered=true\"\r" +
    "\n" +
    "      ng-mouseout=\"cellHovered=false\"\r" +
    "\n" +
    "      ng-dblclick=\"enableInlineEditMode()\"\r" +
    "\n" +
    "      class=\"boundGridTableColumn\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- VIEW MODE -->\r" +
    "\n" +
    "    <span ng-show=\"editMode == false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- BOUND COLUMN -->\r" +
    "\n" +
    "        <span ng-switch-when=\"boundColumn\">\r" +
    "\n" +
    "            {{item | navigateObject:col.dataitem}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- TEMPLATE COLUMN -->\r" +
    "\n" +
    "        <span ng-switch-when=\"templateColumn\" bound-grid-template-column-compiler>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- BUTTON COLUMN -->\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-switch-when=\"buttonColumn\" \r" +
    "\n" +
    "            class=\"nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button ng-if=\"col.showAuditButton === 'true' || $eval(col.showAuditButton)\"\r" +
    "\n" +
    "                 ng-click=\"auditItem()($eval(col.auditButtonOptions))\"\r" +
    "\n" +
    "                 uib-tooltip=\"Record History\">\r" +
    "\n" +
    "                <i class=\"fa fa-history\"></i>\r" +
    "\n" +
    "            </button><!--\r" +
    "\n" +
    "            --><a ng-if=\"col.showEditButton ==='true' || $eval(col.showEditButton)\" \r" +
    "\n" +
    "                class=\"button\" \r" +
    "\n" +
    "                uib-tooltip=\"Edit\" \r" +
    "\n" +
    "                ui-sref=\"{{col.editButtonSref}}\">\r" +
    "\n" +
    "                <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "            </a><!--\r" +
    "\n" +
    "            --><button ng-if=\"col.showDeleteButton === 'true' || $eval(col.showDeleteButton)\" \r" +
    "\n" +
    "                    uib-tooltip=\"Delete\" \r" +
    "\n" +
    "                    ng-click=\"deleteItem()(item[keyField], item[labelField])\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- INLINE EDIT BUTTON - not visible in button column -->\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            ng-click=\"editMode=true;\" \r" +
    "\n" +
    "            href=\"javascript:void(0);\" \r" +
    "\n" +
    "            ng-show=\"col.enableInlineEdit && col.columnType != 'buttonColumn'\"\r" +
    "\n" +
    "            class=\"enableInlineEditButton\">\r" +
    "\n" +
    "            <i class=\"fa fa-pencil-alt\" ng-show=\"cellHovered\" ng-click=\"enableInlineEditMode()\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- EDIT MODE -->\r" +
    "\n" +
    "    <form \r" +
    "\n" +
    "        ng-show=\"col.enableInlineEdit && editMode\" \r" +
    "\n" +
    "        class=\"boundGridInlineEdit\" \r" +
    "\n" +
    "        ng-submit=\"submitInlineEdit()\" \r" +
    "\n" +
    "        name=\"inlineEditForm\" \r" +
    "\n" +
    "        ui-keydown=\"{'esc': 'cancelInlineEdit()'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input type=\"text\" \r" +
    "\n" +
    "            ng-model=\"item[col.dataitem]\" \r" +
    "\n" +
    "            focus-me=\"inputSelect\" />\r" +
    "\n" +
    "        <span class=\"btn-group\">\r" +
    "\n" +
    "            <button type=\"button\" \r" +
    "\n" +
    "                uib-tooltip=\"Cancel [Esc]\" \r" +
    "\n" +
    "                ng-click=\"cancelInlineEdit()\" \r" +
    "\n" +
    "                class=\"gray\">\r" +
    "\n" +
    "                <i class=\"fa fa-ban\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button type=\"submit\" \r" +
    "\n" +
    "                uib-tooltip=\"Save [Enter]\" \r" +
    "\n" +
    "                ng-click=\"submitInlineEdit()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <input type=\"submit\" \r" +
    "\n" +
    "                ng-show=\"false\" />\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/brokerCategoryDropdown/brokerCategoryDropdown.html',
    "<select\r" +
    "\n" +
    "    aa-label=\"{{vm.label}}\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{!vm.label ? 'none' : 'defaultLabelStrategy'}}\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.class}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.ngModel\"\r" +
    "\n" +
    "    options=\"vm.options\"\r" +
    "\n" +
    "    ng-change=\"vm.changed()\"\r" +
    "\n" +
    "    ng-required=\"vm.ngRequired\">\r" +
    "\n" +
    "</select>"
  );


  $templateCache.put('app/components/brokerInfoCard/brokerCard.html',
    "<div>\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <b class=\"small red fill\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.broker.eProducerCategoryName}}\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            ng-show=\"vm.showRemoveButton\"\r" +
    "\n" +
    "            class=\"left-margin small\" \r" +
    "\n" +
    "            ng-click=\"vm.onRemoveClick()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Remove\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            target=\"{{'eProducerAccountDetail/' + vm.broker.id}}\" \r" +
    "\n" +
    "            ui-sref=\"main.eProducerAccountDetail({ id: vm.broker.id })\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.broker.name}}\r" +
    "\n" +
    "            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"small\">\r" +
    "\n" +
    "            <div ng-show=\"vm.broker.lookupCode\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.broker.lookupCode}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.broker.phone}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div ng-show=\"vm.broker.contact1\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.broker.contact1}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div ng-show=\"vm.broker.contact2\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.broker.contact2}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div ng-show=\"vm.broker.contact3\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.broker.contact3}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{vm.broker.mailCity}}, {{vm.broker.mailState}}, {{vm.broker.mailZip}}\r" +
    "\n" +
    "            </div>   \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <span ng-show=\"vm.broker.billToType === 'BillClient'\">\r" +
    "\n" +
    "                    Client Bill\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                <span ng-show=\"vm.broker.billToType === 'BillBrokerGross'\">\r" +
    "\n" +
    "                    Bill Broker (Gross)\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                <span ng-show=\"vm.broker.billToType === 'BillBrokerNet'\">\r" +
    "\n" +
    "                    Bill Broker (Net)\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"vm.broker.billToType === 'BillBrokerGross' || vm.broker.billToType === 'BillBrokerNet'\">\r" +
    "\n" +
    "                    <span ng-show=\"vm.broker.commissionCalculationType === 'PercentOfCommission'\">\r" +
    "\n" +
    "                        - {{vm.broker.commission}}% of Commission\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <span ng-show=\"vm.broker.commissionCalculationType === 'PercentOfPremium'\">\r" +
    "\n" +
    "                        - {{vm.broker.commission}}% of Premium\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/browserNotificationDropdown/browserNotificationDropdown.html',
    "<li\r" +
    "\n" +
    "    class=\"notifications\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.notificationDropdownOpen\"\r" +
    "\n" +
    "    on-toggle=\"vm.dropdownToggled(open)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        id=\"system-notification-dropdown-button\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"vm.newNotificationCount > 0\"\r" +
    "\n" +
    "            class=\"badge red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ vm.newNotificationCount }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i\r" +
    "\n" +
    "            class=\"fa-bell\"\r" +
    "\n" +
    "            ng-class=\"{ 'far': vm.newNotificationCount == 0, 'fas': vm.newNotificationCount > 0 }\">\r" +
    "\n" +
    "        </i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\"\r" +
    "\n" +
    "        uib-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"notification-loading-mask-wrapper\"\r" +
    "\n" +
    "            busy-indicator=\"vm.busyIndicator\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <b>Notifications</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"pull-right text-right\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    id=\"system-notification-mark-all-read-button\"\r" +
    "\n" +
    "                    ng-click=\"vm.markAllNotificationsAsRead()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Mark all as read\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <main>\r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                class=\"scroller\"\r" +
    "\n" +
    "                wheel-propagation=\"true\"\r" +
    "\n" +
    "                on-scroll=\"vm.onNotificationScroll(scrollTop, scrollHeight)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    id=\"{{'system-notification-' + $index}}\"\r" +
    "\n" +
    "                    class=\"notification\"\r" +
    "\n" +
    "                    ng-repeat=\"notification in vm.notifications\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleNotificationClick(notification)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"notification-content\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <header id=\"{{'system-notification-header-' + $index}}\">\r" +
    "\n" +
    "                                <span id=\"{{'system-notification-header-title-span-' + $index}}\">\r" +
    "\n" +
    "                                    {{ notification.title }} \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    id=\"{{'system-notification-header-title-new-badge-span-' + $index}}\"\r" +
    "\n" +
    "                                    class=\"badge red\" \r" +
    "\n" +
    "                                    ng-show=\"!notification.read\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    New\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </header>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <div class=\"flex\">   \r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <main\r" +
    "\n" +
    "                                    class=\"fill\" \r" +
    "\n" +
    "                                    ng-bind-html=\"notification.message\"></main>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <div class=\"notification-actions v-center left-margin\">\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        id=\"{{'system-notification-mark-as-read-button-' + $index}}\"\r" +
    "\n" +
    "                                        ng-show=\"!notification.read\"\r" +
    "\n" +
    "                                        uib-tooltip=\"Mark as read\"\r" +
    "\n" +
    "                                        tooltip-placement=\"left\"\r" +
    "\n" +
    "                                        ng-click=\"vm.toggleReadStatus(notification); $event.stopPropagation();\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        id=\"{{'system-notification-mark-as-unread-button-' + $index}}\"                                            \r" +
    "\n" +
    "                                        ng-show=\"notification.read\"\r" +
    "\n" +
    "                                        uib-tooltip=\"Mark as unread\"\r" +
    "\n" +
    "                                        tooltip-placement=\"left\"\r" +
    "\n" +
    "                                        ng-click=\"vm.toggleReadStatus(notification); $event.stopPropagation();\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <footer>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{ notification.createdDateTime | timeAgo }}\r" +
    "\n" +
    "                        </footer>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"clearfix\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <empty-state\r" +
    "\n" +
    "                    ng-show=\"!vm.notifications.length\"\r" +
    "\n" +
    "                    message=\"It looks like you don't have any notifications. Don't worry, we'll let you know when you get one.\">\r" +
    "\n" +
    "                </empty-state>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "        </main>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</li>"
  );


  $templateCache.put('app/components/busyIndicator/busyIndicator.html',
    "<div class=\"busy-indicator-default-wrapper\">\r" +
    "\n" +
    "     \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-show=\"!hideMessage\" \r" +
    "\n" +
    "        class=\"busy-indicator-default-sign\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"busy-indicator-default-spinner\">\r" +
    "\n" +
    "            <div class=\"bar1\"></div>\r" +
    "\n" +
    "            <div class=\"bar2\"></div>\r" +
    "\n" +
    "            <div class=\"bar3\"></div>\r" +
    "\n" +
    "            <div class=\"bar4\"></div>\r" +
    "\n" +
    "            <div class=\"bar5\"></div>\r" +
    "\n" +
    "            <div class=\"bar6\"></div>\r" +
    "\n" +
    "            <div class=\"bar7\"></div>\r" +
    "\n" +
    "            <div class=\"bar8\"></div>\r" +
    "\n" +
    "            <div class=\"bar9\"></div>\r" +
    "\n" +
    "            <div class=\"bar10\"></div>\r" +
    "\n" +
    "            <div class=\"bar11\"></div>\r" +
    "\n" +
    "            <div class=\"bar12\"></div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "       <div class=\"busy-indicator-default-text\">{{message}}</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/columnSelectionDropdownMenu/columnSelectionDropdownMenu.html',
    "<ng-form name=\"columnDropdownForm\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"dropdown column-selection-dropdown scrollable\"\r" +
    "\n" +
    "        uib-dropdown\r" +
    "\n" +
    "        auto-close=\"outsideClick\"\r" +
    "\n" +
    "        on-toggle=\"vm.toggled(open)\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Change visible columns\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-columns\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul \r" +
    "\n" +
    "            class=\"dropdown-menu-right\"\r" +
    "\n" +
    "            uib-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"dropdown-search-field\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.search\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    placeholder=\"Search columns...\"\r" +
    "\n" +
    "                    ng-change=\"vm.searchChanged()\"\r" +
    "\n" +
    "                    ng-model-options=\"{ debounce: 250 }\"\r" +
    "\n" +
    "                    focus-me=\"vm.isOpen\" />\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                suppress-scroll-x=\"true\"\r" +
    "\n" +
    "                class=\"scroller content-scroller\"\r" +
    "\n" +
    "                wheel-propagation=\"false\"\r" +
    "\n" +
    "                on-scroll=\"vm.onScroll(scrollTop, scrollHeight)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <li ng-repeat=\"column in vm.filteredColumns\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ng-disabled=\"column.locked\"\r" +
    "\n" +
    "                        ng-click=\"vm.columnOptionClicked($event, column)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i \r" +
    "\n" +
    "                            ng-show=\"column.isVisible\"\r" +
    "\n" +
    "                            class=\"fa fa-check\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i ng-show=\"!column.isVisible\"></i>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span ng-show=\"!column.label\">{{column.heading}}</span>\r" +
    "\n" +
    "                        <span ng-show=\"column.label\">{{column.label}}</span>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"!vm.filteredColumns.length\"\r" +
    "\n" +
    "                class=\"disabled text-center top-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                No Matches Found\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                class=\"more-record-indicator\"\r" +
    "\n" +
    "                ng-class=\"{active: vm.scrollDownIndicatorVisible}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                More \r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/components/commissionDropdown/commissionDropdown.html',
    "<select\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.dropdownClass}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.selectedCommissionOptionIndex\"\r" +
    "\n" +
    "    ng-change=\"vm.commissionPercentOptionChanged()\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "    aa-label=\"Commission\"\r" +
    "\n" +
    "    options=\"vm.commissionDropdownOptions\"\r" +
    "\n" +
    "    uib-tooltip=\"Commission options are based on the carrier/agency appointment and underwriting configuration commission overrides\"\r" +
    "\n" +
    "    required\r" +
    "\n" +
    "    ng-disabled=\"vm.ngDisabled\">\r" +
    "\n" +
    "</select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<input\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.otherClass}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.selectedCommissionOption.commissionPercent\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "    ng-change=\"vm.setValues()\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "    ng-show=\"vm.commissionType === 'Other' && !vm.selectedCommissionOption.isLastTermCommission\"\r" +
    "\n" +
    "    ng-required=\"vm.commissionType === 'Other'\" \r" +
    "\n" +
    "    ng-disabled=\"vm.ngDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "<input\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.otherClass}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.selectedCommissionOption.commission\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "    aa-label=\"Commission Amount\"\r" +
    "\n" +
    "    ng-change=\"vm.setValues()\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "    ng-show=\"vm.commissionType === 'Fixed' && !vm.selectedCommissionOption.isLastTermCommission\"\r" +
    "\n" +
    "    ng-required=\"vm.commissionType === 'Fixed'\"\r" +
    "\n" +
    "    ng-disabled=\"vm.ngDisabled\"/>\r" +
    "\n"
  );


  $templateCache.put('app/components/companyForm/company.html',
    "<div ng-form=\"companyForm\">\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header ng-transclude=\"heading\">\r" +
    "\n" +
    "            <span>{{vm.company.name}}</span>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.name\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('name')\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.dba\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('dba')\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyDba\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyDba\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.company.companyType\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('companyType')\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                aa-label=\"Company Type\" \r" +
    "\n" +
    "                options=\"vm.companyTypes\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyType\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyType\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.fein\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('fein')\" \r" +
    "\n" +
    "                aa-label=\"FEIN\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyFein\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyFein\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.company.stateOfIncorporation\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('stateOfIncorporation')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                aa-label=\"State Of Incorporation\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.stateOfIncorporation\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.stateOfIncorporation\" >\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "                     \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.dateFormed\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('dateFormed')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyDateFormed\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyDateFormed\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.fiscalYearEnd\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('fiscalYearEnd')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.fiscalYearEnd\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.fiscalYearEnd\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.nmlsNumber\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('nmlsNumber')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"text\" \r" +
    "\n" +
    "                aa-label=\"NMLS Number\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6 col-xs-6\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"false\" \r" +
    "\n" +
    "                maxlength=\"500\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.websiteUrl\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('websiteUrl')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"url\" \r" +
    "\n" +
    "                aa-label=\"WebSite URL\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6 col-xs-6\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"false\" \r" +
    "\n" +
    "                maxlength=\"500\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyPhone || vm.requiredFields.companyFax || vm.requiredFields.companyEmail || vm.requiredFields.companyFein || vm.requiredFields.stateOfIncorporation || vm.requiredFields.companyDateFormed || vm.requiredFields.fiscalYearEnd\">\r" +
    "\n" +
    "            Contact\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.phone\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('phone')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyPhone\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyPhone\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.fax\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('fax')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyFax\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyFax\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.company.email\" \r" +
    "\n" +
    "                ng-change=\"vm.localChange('email')\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6 col-xs-3\" \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.companyEmail\" \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyEmail\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyPhysicalAddress\" >\r" +
    "\n" +
    "            Address\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <address \r" +
    "\n" +
    "            object=\"vm.company\" \r" +
    "\n" +
    "            changed=\"vm.addressChanged(object, changedProperty)\" \r" +
    "\n" +
    "            required \r" +
    "\n" +
    "            county-required=\"vm.requiredFields.companyPhysicalCounty\" \r" +
    "\n" +
    "            ng-disabled=\"vm.vm.isDisabled\" \r" +
    "\n" +
    "            ng-show=\"!vm.hideOptionalFields || vm.requiredFields.companyPhysicalAddress\">\r" +
    "\n" +
    "        </address>        \r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/creditReportList/creditReportList.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "         class=\"alert alert-danger\"\r" +
    "\n" +
    "         ng-show=\"vm.errorMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{vm.errorMessage}}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table ng-show=\"vm.people.length > 0\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "                <th>Name</th>\r" +
    "\n" +
    "                <th>Pull Date</th>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"person in vm.people\">\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"vm.getCreditReportStatus(person) === vm.notPulledStatus\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Credit Not Pulled\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.getCreditReportStatus(person) === vm.foundStatus\"\r" +
    "\n" +
    "                          class=\"green\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Report Found\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.getCreditReportStatus(person) === vm.notFoundStatus\"\r" +
    "\n" +
    "                          class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        No Report Found\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>{{person.firstName}} {{person.lastName}}</td>\r" +
    "\n" +
    "                <td>{{person.creditReports[0].creditPullDate | date:'MM/dd/yyyy hh:mm a'}}</td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        ng-show=\"vm.getCreditReportStatus(person) === vm.notPulledStatus\"\r" +
    "\n" +
    "                        ng-click=\"vm.pullCreditReport(person)\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Pull Report\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button ng-show=\"vm.getCreditReportStatus(person) !== vm.notPulledStatus\"\r" +
    "\n" +
    "                            ng-click=\"vm.viewReport(person)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        View Report\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.people.length === 0\"\r" +
    "\n" +
    "        message=\"No credit reports were found.\" >\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/customerBalanceCard/balanceCard.html',
    "<section class=\"text-right\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div busy-indicator=\"vm.balanceCardBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"right-margin\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Balance: \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Unapplied Payments <help message=\"Unapplied credit balance on account\"></help>: \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Invoiced Balance:\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Not Invoiced Total:\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Outstanding Debits:\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Outstanding Credits:\r" +
    "\n" +
    "            </div>            \r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div class=\"fill\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <b ng-class=\"{'red' : vm.balance > 0}\">\r" +
    "\n" +
    "                    {{ vm.balance | currency:\"$\" }}\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{ vm.unappliedPaymentTotal | currency:\"$\" }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{ vm.invoicedBalance | currency:\"$\" }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{ vm.notInvoicedTotal | currency:\"$\" }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{ vm.debitTotal | currency:\"$\" }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                {{ vm.creditTotal | currency:\"$\" }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/components/customerContactList/contactList.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"text-right bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"mini\"\r" +
    "\n" +
    "            ng-click=\"vm.addContact()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table\r" +
    "\n" +
    "        ng-show=\"vm.contacts.length\"\r" +
    "\n" +
    "        class=\"small group-by-tbody\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Name\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Email\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Phone\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    Description\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th ng-hide=\"vm.hideIncludeOnBonds\">\r" +
    "\n" +
    "                    Include on Bonds\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <tbody\r" +
    "\n" +
    "            ng-form=\"contactForm\" \r" +
    "\n" +
    "            ng-repeat=\"contact in vm.contacts\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td ng-click=\"vm.toggleContactExpander($index)\">\r" +
    "\n" +
    "                    <i ng-class=\"{'fa fa-caret-right': !vm.isContactExpanded($index), 'fa fa-caret-down': vm.isContactExpanded($index)}\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{contact.prefix}} \r" +
    "\n" +
    "                    {{contact.firstName}} \r" +
    "\n" +
    "                    {{contact.middleName}} \r" +
    "\n" +
    "                    {{contact.lastName}} \r" +
    "\n" +
    "                    {{contact.suffix}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"contact.preferredName\">\r" +
    "\n" +
    "                        (Perferred: {{contact.preferredName}})\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"contact.isPrimaryContact\"\r" +
    "\n" +
    "                        class=\"fa fa-star favorite\"\r" +
    "\n" +
    "                        uib-tooltip=\"Primary Contact\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"contact.isBillingContact\"\r" +
    "\n" +
    "                        class=\"fas fa-dollar-sign green\"\r" +
    "\n" +
    "                        uib-tooltip=\"Billing Contact\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{contact.email}}\r" +
    "\n" +
    "                    <span ng-show=\"contact.email && contact.secondaryEmail\"> | </span>\r" +
    "\n" +
    "                    {{contact.secondaryEmail}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{contact.cellPhone}} \r" +
    "\n" +
    "                    <span ng-show=\"contact.cellPhone && contact.homePhone\"> | </span>\r" +
    "\n" +
    "                    {{contact.homePhone}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"!vm.isContactExpanded($index)\">{{contact.description}}</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"contact.description\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                        ng-show=\"vm.isContactExpanded($index)\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td ng-hide=\"vm.hideIncludeOnBonds\">\r" +
    "\n" +
    "                    <span ng-show=\"!contact.includeOnBonds && !vm.isContactExpanded($index)\">\r" +
    "\n" +
    "                        No\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <b \r" +
    "\n" +
    "                        ng-show=\"contact.includeOnBonds && !vm.isContactExpanded($index)\"\r" +
    "\n" +
    "                        class=\"green\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Yes\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"contact.includeOnBonds\" \r" +
    "\n" +
    "                        aa-fg-class=\"inline-check inline v-center\"\r" +
    "\n" +
    "                        ng-show=\"vm.isContactExpanded($index)\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"charcoal\"\r" +
    "\n" +
    "                        ng-click=\"vm.removeContact($index)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr ng-show=\"vm.isContactExpanded($index)\">\r" +
    "\n" +
    "                <td colspan=\"7\">\r" +
    "\n" +
    "                    <div class=\"text-right bottom-margin\">\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            aa-submit-form=\"vm.saveContact($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Save\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <person\r" +
    "\n" +
    "                        changed=\"vm.contactChanged($index)\" \r" +
    "\n" +
    "                        person=\"contact\" >\r" +
    "\n" +
    "                    </person>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"!vm.contacts.length\" \r" +
    "\n" +
    "        message=\"No contacts were found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/customerListFilterDropdown/customerListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"filterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div    \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>     \r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "                    Status\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.isActive\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Any\r" +
    "\n" +
    "                        </option>\r" +
    "\n" +
    "                        <option value=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </option>\r" +
    "\n" +
    "                        <option value=\"false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Archived\r" +
    "\n" +
    "                        </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Contact Name\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.contactFirstName\"\r" +
    "\n" +
    "                        placeholder=\"First Name\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">&nbsp;</div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.contactLastName\"\r" +
    "\n" +
    "                        placeholder=\"Last name\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Contact Email\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.contactEmail\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Contact Phone\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.contactPhone\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Tagged With\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.tag\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\"></option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option \r" +
    "\n" +
    "                            ng-repeat=\"tag in vm.tagOptions\" \r" +
    "\n" +
    "                            value=\"{{tag.value}}\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{tag.label}}\r" +
    "\n" +
    "                        </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <label class=\"small\">\r" +
    "\n" +
    "                        Broker\r" +
    "\n" +
    "                    </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        class=\"left-margin-auto small v-center btn-link\"\r" +
    "\n" +
    "                        ng-show=\"vm.selectedBroker\"\r" +
    "\n" +
    "                        ng-click=\"vm.clearBrokerselection()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Clear\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <ui-select \r" +
    "\n" +
    "                        ng-model=\"vm.selectedBroker\" \r" +
    "\n" +
    "                        ng-init=\"$select.label = ''\" \r" +
    "\n" +
    "                        reset-search-input=\"false\"\r" +
    "\n" +
    "                        name=\"brokerSearch\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.selectedBroker\"\r" +
    "\n" +
    "                        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-match \r" +
    "\n" +
    "                            placeholder=\"Search by broker...\"\r" +
    "\n" +
    "                            label=\"\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{$select.selected.name}}\r" +
    "\n" +
    "                        </ui-select-match>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <ui-select-choices \r" +
    "\n" +
    "                            repeat=\"account in vm.accountSearchResults track by $index\" \r" +
    "\n" +
    "                            refresh=\"vm.searchBrokers($select.search)\" \r" +
    "\n" +
    "                            refresh-delay=\"400\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"small\">\r" +
    "\n" +
    "                                <div ng-bind-html=\"account.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                <div ng-bind-html=\"account.lookupCode | highlight: $select.search\"></div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </ui-select-choices>\r" +
    "\n" +
    "                    </ui-select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row top-padding-small\">\r" +
    "\n" +
    "                <div class=\"top-margin\">\r" +
    "\n" +
    "                    <customer-search-dropdown\r" +
    "\n" +
    "                        label=\"Parent Customer\"\r" +
    "\n" +
    "                        search-control=\"vm.parentCustomerSearchControl\">\r" +
    "\n" +
    "                    </customer-search-dropdown>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/customerLookupForm/customerLookup.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "    <div ng-form novalidate name=\"customerlookupform\">\r" +
    "\n" +
    "        <div class=\"row\" ng-show=\"vm.showLookupForm\">\r" +
    "\n" +
    "            <div class=\"col-sm-12 field-wrapper empty-input\" ng-class=\"{'empty-input' : !vm.nameSearch }\">\r" +
    "\n" +
    "                <input ng-change=\"vm.loadCustomerMatches()\" type=\"text\" ng-model=\"vm.nameSearch\" placeholder=\"Name or Customer Number\"/>\r" +
    "\n" +
    "                <div class=\"label\">\r" +
    "\n" +
    "                    Name or Customer Number\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <input aa-field-group=\"vm.phoneSearch\" aa-field-group-strategy=\"phone\" aa-fg-class=\"col-sm-12\" aa-label=\"Phone\" ng-change=\"vm.loadCustomerMatches()\"/>\r" +
    "\n" +
    "            <input aa-field-group=\"vm.emailSearch\" aa-fg-class=\"col-sm-12\" aa-label=\"Email\" ng-change=\"vm.loadCustomerMatches()\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.showLookupForm\" class=\"top-margin\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th colspan=\"2\">Matching Customers</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"customer in vm.matchingCustomers\">\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span class=\"col-md-12 text-left\">\r" +
    "\n" +
    "                            <b>\r" +
    "\n" +
    "                                <i class=\"fa\" ng-class=\"{'fa-male': customer.customerType==='Individual', 'fa-building' : customer.customerType==='Company'}\"></i><span ng-bind-html=\"customer.$highlighted\"></span>\r" +
    "\n" +
    "                                <img class=\"integration-icon\" ng-show=\"customer.$integrationType==='ams360'\" src=\"/img/icons/Vertafore_logo.png\" alt=\"From Ams 360\" uib-tooltip=\"Import from Ams 360\" />\r" +
    "\n" +
    "                            </b>\r" +
    "\n" +
    "                            <br />\r" +
    "\n" +
    "                            <span ng-show=\"customer.customerType=='Individual'\">\r" +
    "\n" +
    "                                <span ng-show=\"customer.people[0].physicalAddress\" ng-bind-html=\"customer.people[0] | physicalAddress\"></span> <br />\r" +
    "\n" +
    "                                <span  ng-bind-html=\"customer.people[0] | phone\"></span> / <span ng-bind-html=\"customer.people[0].email\"></span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-show=\"customer.customerType=='Company'\">\r" +
    "\n" +
    "                                <span ng-bind-html=\"customer.companies[0] | physicalAddress\"></span> <br />\r" +
    "\n" +
    "                                <span ng-bind-html=\"customer.companies[0] | phone\"></span> / <span ng-bind-html=\"customer.people[0].email\"></span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                       </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    <button ng-show=\"customer.$integrationType==='ams360'\" \r" +
    "\n" +
    "                        type=\"button\" ng-click=\"vm.selectCustomer(customer)\">\r" +
    "\n" +
    "                        <i class=\"fa fa-cloud-download\"></i>\r" +
    "\n" +
    "                            Import\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    <button ng-show=\"customer.$integrationType==='a3'\"\r" +
    "\n" +
    "                        type=\"button\" \r" +
    "\n" +
    "                        ng-click=\"vm.selectCustomer(customer)\">\r" +
    "\n" +
    "                        <i class=\"fa fa-arrow-right\"></i> \r" +
    "\n" +
    "                            Select\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "        <empty-state ng-show=\"vm.showLookupForm && !vm.matchingCustomers.length\" \r" +
    "\n" +
    "            message=\"Either no records exist or your search resulted in no records.\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.showAms360ContactSelection\" class=\"text-left\">\r" +
    "\n" +
    "            <h4><b>\r" +
    "\n" +
    "                <i class=\"fa\" \r" +
    "\n" +
    "                    ng-class=\"{'fa-male': vm.customer.customerType==='Individual', 'fa-building' : vm.customer.customerType==='Company'}\">\r" +
    "\n" +
    "                </i>\r" +
    "\n" +
    "                <span ng-bind-html=\"vm.customer.name\"></span>\r" +
    "\n" +
    "                <img class=\"integration-icon\" \r" +
    "\n" +
    "                ng-show=\"vm.customer.$integrationType==='ams360'\" \r" +
    "\n" +
    "                src=\"img/icons/Vertafore_logo.png\"\r" +
    "\n" +
    "                alt=\"From Ams 360\" \r" +
    "\n" +
    "                uib-tooltip=\"Import from Ams 360\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </b></h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <br />\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.customerType=='Individual'\">\r" +
    "\n" +
    "                <span ng-show=\"vm.customer.people[0].physicalAddress\" ng-bind-html=\"customer.people[0] | physicalAddress\"></span> <br />\r" +
    "\n" +
    "                <span ng-bind-html=\"customer.people[0] | phone\"></span> / <span ng-bind-html=\"(customer.people[0].email)\"></span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.customerType=='Company'\">\r" +
    "\n" +
    "                <span ng-bind-html=\"vm.customer.companies[0] | physicalAddress\"></span> <br />\r" +
    "\n" +
    "                <span ng-bind-html=\"(customer.companies[0] | phone)\"></span> / <span ng-bind-html=\"(customer.people[0].email)\"></span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <table ng-show=\"vm.showAms360ContactSelection\" class=\"top-margin\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th colspan=\"2\">Customer Contacts</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"person in vm.matchingPeople\">\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span  class=\"col-md-12 text-left\">\r" +
    "\n" +
    "                            <b>\r" +
    "\n" +
    "                                <i class=\"fa fa-male\"></i><span ng-bind-html=\"person.firstName + ' ' + person.middleName + ' ' + person.lastName\"></span>\r" +
    "\n" +
    "                            </b>\r" +
    "\n" +
    "                            <br />\r" +
    "\n" +
    "                            <span ng-show=\"person.physicalAddress\" \r" +
    "\n" +
    "                                ng-bind-html=\"person | physicalAddress\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <br />\r" +
    "\n" +
    "                            <span ng-bind-html=\"(person | phone)\"></span>\r" +
    "\n" +
    "                                 / \r" +
    "\n" +
    "                            <span ng-bind-html=\"person.email\"></span>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"person.$include\" \r" +
    "\n" +
    "                        aa-label=\"Include\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        ng-change=\"vm.setSelectedCustomerContacts()\"/>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.showAms360ContactSelection && !vm.matchingPeople.length\" \r" +
    "\n" +
    "            message=\"We couldn't find any customer contacts to import for this customer.'\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/customerOrEproducerBrokerSelection/customerOrEproducerBrokerSelection.html',
    "<div \r" +
    "\n" +
    "    class=\"special-input-box eproducer-search\" \r" +
    "\n" +
    "    ng-class=\"{'empty-input': !vm.selectedAccount }\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"placeholder field-wrapper empty-input\" \r" +
    "\n" +
    "        ng-class=\"{'empty-input' : !vm.searchPhrase }\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            name=\"searchPhrase\" \r" +
    "\n" +
    "            ng-change=\"vm.search()\" \r" +
    "\n" +
    "            ng-model-options=\"{debounce: 400}\" \r" +
    "\n" +
    "            type=\"text\" \r" +
    "\n" +
    "            ng-model=\"vm.searchPhrase\" \r" +
    "\n" +
    "            placeholder=\"Search by name or lookup code\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"label\">Search Customers and Brokers</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"top-margin\">\r" +
    "\n" +
    "        <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            message=\"Search for customer or broker\"\r" +
    "\n" +
    "            icon=\"fa-search\" \r" +
    "\n" +
    "            ng-show=\"!vm.searchPhrase\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            message=\"We couldn't find any matches based on your search\" \r" +
    "\n" +
    "            icon=\"fa-question\" \r" +
    "\n" +
    "            ng-show=\"vm.searchPhrase && !vm.matches && !vm.matches.length\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-show=\"vm.searchPhrase\"\r" +
    "\n" +
    "        ng-repeat=\"match in vm.matches\" \r" +
    "\n" +
    "        class=\"matching-eProducer-brokers\" \r" +
    "\n" +
    "        ng-class=\"{'top-margin-big': $first}\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <b>{{match.name}} </b> \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    class=\"red\"\r" +
    "\n" +
    "                    ng-show=\"match.eProducerAccountId\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    - Broker\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"small\">\r" +
    "\n" +
    "                    <div ng-show=\"match.lookupCode\">\r" +
    "\n" +
    "                        Lookup Code: {{match.lookupCode}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small v-center left-margin-auto\" \r" +
    "\n" +
    "                ng-click=\"vm.setSelection(match)\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Select\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <hr \r" +
    "\n" +
    "            class=\"top-margin-thin bottom-margin-thin\"\r" +
    "\n" +
    "            ng-show=\"!$last\"/>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/customerSearchDropdown/customerSearchDropdown.html',
    "<search-dropdown \r" +
    "\n" +
    "    search-control=\"vm.searchControl\">\r" +
    "\n" +
    "</search-dropdown>"
  );


  $templateCache.put('app/components/documentLibraryDetailsPane/documentLibraryDetailsPane.html',
    "<page-slide \r" +
    "\n" +
    "    ps-open=\"vm.isOpen\" \r" +
    "\n" +
    "    ps-click-outside=\"false\" \r" +
    "\n" +
    "    ps-container=\"content\" \r" +
    "\n" +
    "    ps-body-class=\"true\"\r" +
    "\n" +
    "    ng-form=\"documentDetailForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <strong class=\"v-center\">{{vm.documentDetails.name}}</strong>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "                    ng-click=\"vm.hide()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"flex red text-center top-margin\" \r" +
    "\n" +
    "            ng-show=\"vm.documentDetails.status === 'ProblemReported'\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <strong class=\"fill\">\r" +
    "\n" +
    "                <i class=\"fa fa-flag\"></i> Problem Reported\r" +
    "\n" +
    "            </strong>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset class=\"fill\">\r" +
    "\n" +
    "            <uib-tab heading=\"Info\">\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <div class=\"detail-info\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"detail-label\">\r" +
    "\n" +
    "                                Status\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.documentDetails.status === 'Untouched'\">\r" +
    "\n" +
    "                                Untouched\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.documentDetails.status === 'PrimaryReview'\">\r" +
    "\n" +
    "                                Primary Review\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.documentDetails.status === 'SecondaryReview'\">\r" +
    "\n" +
    "                                Secondary Review\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.documentDetails.status === 'Approved'\" \r" +
    "\n" +
    "                                class=\"green\">\r" +
    "\n" +
    "                                <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Approved\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.documentDetails.status === 'ProblemReported'\" \r" +
    "\n" +
    "                                class=\"red\">\r" +
    "\n" +
    "                                <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Problem Reported\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"detail-label\">\r" +
    "\n" +
    "                                File Size\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{vm.documentDetails.fileSize | filesize}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"detail-label\">\r" +
    "\n" +
    "                                Category\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{vm.documentDetails.documentCategoryName}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"detail-label\">    \r" +
    "\n" +
    "                                Created\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{vm.documentDetails.createdDateTime | localDateTime}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"detail-label\">    \r" +
    "\n" +
    "                                Last Modified\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{vm.documentDetails.lastModifiedDateTime | localDateTime}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Usage\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <perfect-scrollbar\r" +
    "\n" +
    "                        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "                        class=\"scroller\"\r" +
    "\n" +
    "                        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <b>{{vm.systemAccountCompanyName}}</b>\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isQuoteOption\"\r" +
    "\n" +
    "                                aa-label=\"Quote\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isPublic\"\r" +
    "\n" +
    "                                aa-label=\"Public\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <b>System Defaults</b>\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isRiderDocument\"\r" +
    "\n" +
    "                                aa-label=\"Rider\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultInvoice\"\r" +
    "\n" +
    "                                aa-label=\"Invoices\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultQuote\"\r" +
    "\n" +
    "                                aa-label=\"Quote\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultIndemnity\"\r" +
    "\n" +
    "                                aa-label=\"Indemnity\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultNoticeOfCancellation\"\r" +
    "\n" +
    "                                aa-label=\"Notice of cancellation\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultNotaryAcknowledgement\"\r" +
    "\n" +
    "                                aa-label=\"Notary Acknowledgment\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isReinstatementNotification\"\r" +
    "\n" +
    "                                aa-label=\"Reinstatement notice\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultAdverseActionNotice\"\r" +
    "\n" +
    "                                aa-label=\"Adverse action notice\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultRenewalCoverLetter\"\r" +
    "\n" +
    "                                aa-label=\"Renewal cover letter\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultContinuationCertificate\"\r" +
    "\n" +
    "                                aa-label=\"Continuation certificate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultVerificationCertificate\"\r" +
    "\n" +
    "                                aa-label=\"Verification certificate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.documentDetails.isDefaultDocumentSetTransactionSeparator\"\r" +
    "\n" +
    "                                aa-label=\"Document set transaction separator\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"/>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"top-margin\">\r" +
    "\n" +
    "                            <b>Bond Types</b>\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-repeat=\"bondType in vm.documentDetails.bondTypes\" \r" +
    "\n" +
    "                                class=\"text-left\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <a ui-sref=\"main.bondTypesDetail({ id: bondType.id })\">{{bondType.name}}</a>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <empty-state\r" +
    "\n" +
    "                                ng-show=\"!vm.documentDetails.bondTypes.length\"\r" +
    "\n" +
    "                                message=\"Not assigned to any bond types\">\r" +
    "\n" +
    "                            </empty-state>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex top-margin\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button\r" +
    "\n" +
    "                                type=\"submit\"\r" +
    "\n" +
    "                                class=\"left-margin-auto\"\r" +
    "\n" +
    "                                aa-submit-form=\"vm.saveUsage()\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                Save\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </perfect-scrollbar>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Versions\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <document-versions-list versions=\"vm.documentDetails.versions\"></document-versions-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</page-slide>"
  );


  $templateCache.put('app/components/documentPicker/documentPicker.html',
    "<div\r" +
    "\n" +
    "    class=\"field-flex-box bottom-margin\"\r" +
    "\n" +
    "    ng-show=\"vm.showSearch\"\r" +
    "\n" +
    "    ng-form=\"documentPickerForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select\r" +
    "\n" +
    "        ng-model=\"vm.searchResults['selected']\"\r" +
    "\n" +
    "        ng-init=\"$select.label = 'Search Documents'\"\r" +
    "\n" +
    "        ng-click=\"vm.onClick($select)\"\r" +
    "\n" +
    "        reset-search-input=\"false\"\r" +
    "\n" +
    "        name=\"documentSelection\"\r" +
    "\n" +
    "        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ui-select-match\r" +
    "\n" +
    "            placeholder=\"Search for existing document...\"\r" +
    "\n" +
    "            label=\"Search Documents\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ $select.selected.name }}\r" +
    "\n" +
    "        </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ui-select-choices\r" +
    "\n" +
    "            repeat=\"document in vm.searchResults track by $index\"\r" +
    "\n" +
    "            refresh=\"vm.searchDocuments($select.search)\"\r" +
    "\n" +
    "            refresh-delay=\"0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-bind-html=\"document.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "        </ui-select-choices>\r" +
    "\n" +
    "    </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button ng-click=\"vm.addSelectedDocument()\">\r" +
    "\n" +
    "        Add\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-show=\"vm.canAddDocument\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uploader\r" +
    "\n" +
    "        show-upload-modal=\"false\"\r" +
    "\n" +
    "        on-files-added=\"vm.filesAddedForUpload(files)\"\r" +
    "\n" +
    "        files=\"vm.filesToUpload\"\r" +
    "\n" +
    "        controls=\"vm.uploaderControls\">\r" +
    "\n" +
    "    </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uploader\r" +
    "\n" +
    "        hidden=\"true\"\r" +
    "\n" +
    "        max-file-size=\"10000000\"\r" +
    "\n" +
    "        controls=\"vm.versionUpdateUploaderControls\"\r" +
    "\n" +
    "        on-success=\"vm.documentVersionUpdated()\"\r" +
    "\n" +
    "        auto-upload=\"true\">\r" +
    "\n" +
    "    </uploader>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table ng-show=\"vm.documents.length || vm.filesToUpload.length\">\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th colspan=\"3\">\r" +
    "\n" +
    "                Document Name\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <tbody>\r" +
    "\n" +
    "        <tr \r" +
    "\n" +
    "            ng-class=\"{'line-through': document.isRemoved}\"\r" +
    "\n" +
    "            ng-repeat=\"document in vm.documents\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <td>{{ document.name }}</td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <span\r" +
    "\n" +
    "                    class=\"pull-right\"\r" +
    "\n" +
    "                    ng-if=\"vm.showStatus\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.isDocumentUntouched(document)\">\r" +
    "\n" +
    "                        Untouched\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"vm.isDocumentInPrimaryReview(document)\">\r" +
    "\n" +
    "                        Primary Review\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"vm.isDocumentInSecondaryReview(document)\">\r" +
    "\n" +
    "                        Secondary Review\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-show=\"vm.isDocumentApproved(document)\"\r" +
    "\n" +
    "                        class=\"green\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                         Approved\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-show=\"vm.isDocumentProblemReported(document)\"\r" +
    "\n" +
    "                        class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                         Problem Reported\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <td class=\"text-right\">\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"dropdown\"\r" +
    "\n" +
    "                    uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button uib-dropdown-toggle>\r" +
    "\n" +
    "                        Actions \r" +
    "\n" +
    "                        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                        <li>\r" +
    "\n" +
    "                            <button\r" +
    "\n" +
    "                                ng-click=\"vm.removeDocument(document)\"\r" +
    "\n" +
    "                                uib-tooltip=\"Remove Document\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                                Remove\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <li ng-show=\"vm.canDownloadDocument(document)\">\r" +
    "\n" +
    "                            <pdf-download\r" +
    "\n" +
    "                                url=\"vm.getDocumentUrl(document)\"\r" +
    "\n" +
    "                                filename=\"A3_Document.pdf\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                                 Download\r" +
    "\n" +
    "                            </pdf-download>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <li ng-show=\"vm.canPreviewDocument(document)\">\r" +
    "\n" +
    "                            <pdf-download\r" +
    "\n" +
    "                                url=\"vm.getPreviewDocumentUrl(document)\"\r" +
    "\n" +
    "                                filename=\"A3_Document_Sample.pdf\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-eye\"></i>\r" +
    "\n" +
    "                                 Preview\r" +
    "\n" +
    "                            </pdf-download>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <li ng-show=\"vm.canEditDocument(document)\">\r" +
    "\n" +
    "                            <a\r" +
    "\n" +
    "                                ui-sref=\"documentEditor({ id: {{ document.id }} })\"\r" +
    "\n" +
    "                                target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                                <span>\r" +
    "\n" +
    "                                    Edit\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <li ng-show=\"vm.canUploadNewVersion(document)\">\r" +
    "\n" +
    "                            <button ng-click=\"vm.uploadNewVersion(document)\">\r" +
    "\n" +
    "                                <i class=\"fa fa-upload\"></i>\r" +
    "\n" +
    "                                <span>\r" +
    "\n" +
    "                                    Upload new version\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "                    </ul>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tr ng-repeat=\"item in vm.filesToUpload\">\r" +
    "\n" +
    "            <td colspan=\"2\">\r" +
    "\n" +
    "                {{ item.file.name }}<span class=\"red\"> - will upload on save</span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    ng-click=\"vm.uploaderControls.removeFile($index)\"\r" +
    "\n" +
    "                    uib-tooltip=\"Remove Document\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                    Remove\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.showDefault && !vm.hideEmptyState\"\r" +
    "\n" +
    "    message=\"{{vm.defaultMessage}}\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/components/documentVersionList/documentVersionsList.html',
    "<div \r" +
    "\n" +
    "    ng-repeat=\"version in vm.versions\" \r" +
    "\n" +
    "    class=\"text-left detail-info\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "        class=\"detail-label\" \r" +
    "\n" +
    "        ng-show=\"version.initialVersion\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Created By\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "        class=\"detail-label\" \r" +
    "\n" +
    "        ng-show=\"!version.initialVersion\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Modified By\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span class=\"user mini\">\r" +
    "\n" +
    "        <user-avatar \r" +
    "\n" +
    "            user-id=\"version.createdByUserId\" \r" +
    "\n" +
    "            class=\"mini\" \r" +
    "\n" +
    "            external-resource=\"{{externalResource}}\">\r" +
    "\n" +
    "        </user-avatar>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <span>\r" +
    "\n" +
    "            {{version.createdByFirstName}} {{version.createdByLastName}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span> \r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        {{version.createdDateTime | localDateTime}}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <hr ng-show=\"!$last\"/>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/documentViewer/documentViewer.html',
    "<iframe\r" +
    "\n" +
    "    class=\"{{vm.class}}\" \r" +
    "\n" +
    "    ng-src=\"{{vm.trustUrl(vm.objectUrl)}}\">\r" +
    "\n" +
    "</iframe>"
  );


  $templateCache.put('app/components/dropdown/dropdown.html',
    "<div \r" +
    "\n" +
    "    class=\"dropdown scrollable\" \r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    keyboard-nav>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        id=\"vm.buttonId\"\r" +
    "\n" +
    "        class=\"{{vm.buttonClass}}\"\r" +
    "\n" +
    "        ng-if=\"vm.buttonText\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        {{vm.buttonText}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i\r" +
    "\n" +
    "            ng-show=\"vm.rightButtonIcon\"\r" +
    "\n" +
    "            class=\"{{vm.rightButtonIcon}}\">\r" +
    "\n" +
    "        </i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        id=\"vm.buttonId\"\r" +
    "\n" +
    "        class=\"vm.buttonClass\"\r" +
    "\n" +
    "        ng-if=\"!vm.buttonText\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span>\r" +
    "\n" +
    "            {{vm.selectedOption.label}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "      \r" +
    "\n" +
    "    <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar \r" +
    "\n" +
    "            suppress-scroll-x=\"true\"\r" +
    "\n" +
    "            class=\"scroller content-scroller {{vm.menuPositionClass}}\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-class=\"{divider: option.isDivider}\"\r" +
    "\n" +
    "                ng-repeat=\"option in vm.options\"\r" +
    "\n" +
    "                id=\"{{option.id}}\"\r" +
    "\n" +
    "                ng-show=\"!option.isHidden\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    ng-if=\"!option.isDivider && !option.isText && !option.isDisabled\"\r" +
    "\n" +
    "                    ng-click=\"vm.optionSelected(option)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"{{option.iconClass}}\"\r" +
    "\n" +
    "                        ng-show=\"option.iconClass\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{option.label | limitTo: 50}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-if=\"!options.isDivider && !option.isText && option.isDisabled\"\r" +
    "\n" +
    "                    class=\"block disabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"{{option.iconClass}}\"\r" +
    "\n" +
    "                        ng-show=\"option.iconClass\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{option.label | limitTo: 50 }}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <help-icon\r" +
    "\n" +
    "                        ng-if=\"option.helpText\"\r" +
    "\n" +
    "                        message=\"{{option.helpText}}\"\r" +
    "\n" +
    "                        icon-class=\"{{option.helpIconClass}}\">\r" +
    "\n" +
    "                    </help-icon>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-if=\"!options.isDivider && option.isText\"\r" +
    "\n" +
    "                    class=\"block {{option.textClass}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"{{option.iconClass}}\"\r" +
    "\n" +
    "                        ng-show=\"option.iconClass\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{option.label | limitTo: 50 }}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/emailTemplateList/emailTemplateList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"bottom-margin flex\"\r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search Email Templates\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center left-margin\"\r" +
    "\n" +
    "        ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a  class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"samll\"\r" +
    "\n" +
    "                    ng-click=\"vm.newEmailTemplateClicked()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Email Template\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                Comments\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"emailTemplate in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                <a ng-click=\"vm.editEmailTemplateClicked(emailTemplate.id)\">\r" +
    "\n" +
    "                    {{emailTemplate.name}} \r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"comments\">\r" +
    "\n" +
    "                {{emailTemplate.comments}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"button-column\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(emailTemplate)\" \r" +
    "\n" +
    "                    uib-tooltip=\"Delete Email Template\"\r" +
    "\n" +
    "                    ng-disabled=\"emailTemplate.isDefault\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/components/emptyState/emptyState.html',
    "<div class=\"initial-state\">\r" +
    "\n" +
    "    <div class=\"empty-backdrop\">\r" +
    "\n" +
    "        <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "        <table class=\"empty-state-table\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>&nbsp;</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"initial-state-body\">\r" +
    "\n" +
    "        <div class=\"icon\">\r" +
    "\n" +
    "            <i class=\"fa\"></i>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <p class=\"state-message\"></p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/eProducerBrokerSummary/eProducerBrokerSummary.html',
    "<div class=\"row\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.account.name\" \r" +
    "\n" +
    "        aa-label=\"Company Name\" \r" +
    "\n" +
    "        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.account.legalName\" \r" +
    "\n" +
    "        aa-label=\"Legal Name\" \r" +
    "\n" +
    "        aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.account.phone\" \r" +
    "\n" +
    "        aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "        aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"col-xs-12\">\r" +
    "\n" +
    "        <address \r" +
    "\n" +
    "            hide-county=\"true\" \r" +
    "\n" +
    "            object=\"vm.account\" \r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "        </address>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.account.fax\" \r" +
    "\n" +
    "        aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "        aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <select \r" +
    "\n" +
    "        aa-field-group=\"vm.account.referredBy\" \r" +
    "\n" +
    "        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "        options=\"vm.referredByTypeOptions\" \r" +
    "\n" +
    "        aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <select \r" +
    "\n" +
    "        aa-field-group=\"vm.account.companyType\" \r" +
    "\n" +
    "        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "        aa-label=\"Company Type\" \r" +
    "\n" +
    "        options=\"vm.companyTypeOptions\" \r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        aa-fg-class=\"col-sm-2\">\r" +
    "\n" +
    "    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"col-sm-4 flex\">\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.account.isLLC\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-fg-class=\"form-inline left-margin-auto right-margin-auto\"\r" +
    "\n" +
    "            aa-label=\"Is LLC\" \r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.account.requires1099\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-fg-class=\"form-inline left-margin-auto right-margin-auto\"\r" +
    "\n" +
    "            aa-label=\"Requires 1099\" \r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/eProducerUserAccessCard/eProducerUserAccessCard.html',
    "<div class=\"small\">\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <span class=\"fill v-center\">EProducer Access</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            ng-show=\"vm.showRemoveButton\"\r" +
    "\n" +
    "            class=\"small v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.onRemoveClick()\">\r" +
    "\n" +
    "            Remove Access\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section busy-indicator=\"vm.busyIndicator\">\r" +
    "\n" +
    "        <div>{{vm.eproducerUser.firstName}} {{vm.eproducerUser.lastName}}</div>\r" +
    "\n" +
    "        <div>{{vm.eproducerUser.email}}</div>\r" +
    "\n" +
    "        <div>\r" +
    "\n" +
    "            Last Login: {{vm.eproducerUser.lastLoginDate | localDateTime}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            ng-click=\"vm.resetApplicantPassword()\"\r" +
    "\n" +
    "            class=\"small\">\r" +
    "\n" +
    "            Reset Password\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/eProducerUserList/eProducerUserList.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"group-by-tbody\" \r" +
    "\n" +
    "        ng-show=\"vm.users.length > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>Name</th>\r" +
    "\n" +
    "                <th>Email</th>\r" +
    "\n" +
    "                <th>Job Title</th>\r" +
    "\n" +
    "                <th>Last Login</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <tbody ng-repeat=\"user in vm.users\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td>{{user.firstName}} {{user.lastName}}</td>\r" +
    "\n" +
    "                <td>{{user.email}}</td>\r" +
    "\n" +
    "                <td>{{user.jobTitle}}</td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"user.lastLoginDate === '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "                        Never Logged In\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <span ng-show=\"user.lastLoginDate !== '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "                        {{user.lastLoginDate | localDateTime}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.resetPassword(user.email)\" \r" +
    "\n" +
    "                        uib-tooltip=\"Send reset password request\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-key\"></i>\r" +
    "\n" +
    "                    </button><!--\r" +
    "\n" +
    "                    --><button \r" +
    "\n" +
    "                        show-eproducer-user-modal=\"{{user.id}}\" \r" +
    "\n" +
    "                        uib-tooltip=\"Edit user\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Edit\r" +
    "\n" +
    "                    </button><!--\r" +
    "\n" +
    "                    --><button \r" +
    "\n" +
    "                        ng-click=\"vm.promptToDelete(user, $index)\" \r" +
    "\n" +
    "                        uib-tooltip=\"Delete user\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.users.length === 0\" \r" +
    "\n" +
    "        message=\"No users found.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/feeCodeDropdown/feeCodeDropdown.html',
    "<select\r" +
    "\n" +
    "    id=\"{{vm.id}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.feeCode\"\r" +
    "\n" +
    "    ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "    aa-label=\"Fee Code\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.aaFgClass}}\"\r" +
    "\n" +
    "    options=\"vm.options\"\r" +
    "\n" +
    "    ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "</select>"
  );


  $templateCache.put('app/components/feeList/feeList.html',
    "<div>\r" +
    "\n" +
    "    <h5 \r" +
    "\n" +
    "        clas=\"bottom-margin\"\r" +
    "\n" +
    "        ng-show=\"vm.heading\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{vm.heading}}\r" +
    "\n" +
    "    </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"v-center\" \r" +
    "\n" +
    "            ng-show=\"!vm.fees.length\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            No Fees Applied\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            id=\"fee-list-add-fee-button\"\r" +
    "\n" +
    "            class=\"mini v-center left-margin-auto\"\r" +
    "\n" +
    "            ng-click=\"vm.addFee()\"\r" +
    "\n" +
    "            ng-if=\"!vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span>Add Fee</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table ng-form=\"feesForm\"\r" +
    "\n" +
    "           ng-show=\"vm.fees.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead class=\"small\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>Fee Type</th>\r" +
    "\n" +
    "                <th>Amount</th>\r" +
    "\n" +
    "                <th class=\"fit-content\">Credit Producer</th>\r" +
    "\n" +
    "                <th class=\"fit-content\">Calculate Broker Commission</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tr ng-repeat=\"fee in vm.fees\">\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    id=\"{{'fee-list-' + $index + '-auto-fee'}}\"\r" +
    "\n" +
    "                    aa-field-group=\"fee.autoFeeId\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-fg-class=\"no-margin small\"\r" +
    "\n" +
    "                    required\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.feeOptions\"\r" +
    "\n" +
    "                    ng-change=\"vm.autoFeeChanged(fee)\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"{{'fee-list-' + $index + '-amount'}}\"\r" +
    "\n" +
    "                    aa-field-group=\"fee.amount\"\r" +
    "\n" +
    "                    aa-label=\"Amount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-fg-class=\"no-margin small\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                    ng-change=\"vm.calculateTotal()\"\r" +
    "\n" +
    "                    required\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    type=\"checkbox\"\r" +
    "\n" +
    "                    ng-model=\"fee.creditProducer\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    type=\"checkbox\"\r" +
    "\n" +
    "                    ng-model=\"fee.includeInBrokerCommissionCalculation\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    id=\"{{'fee-list-' + $index + '-remove-button'}}\"\r" +
    "\n" +
    "                    ng-click=\"vm.removeFee($index)\"\r" +
    "\n" +
    "                    ng-if=\"!vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fileIcon/fileIcon.html',
    "<span>\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        uib-tooltip=\"PDF\"\r" +
    "\n" +
    "        class=\"fa fa-file-pdf\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === 'pdf'\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        uib-tooltip=\"Excel\"\r" +
    "\n" +
    "        class=\"fa fa-file-excel\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === 'xlsx'\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        uib-tooltip=\"Word\"\r" +
    "\n" +
    "        class=\"fa fa-file-word\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === 'docx'\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        uib-tooltip=\"Image\"\r" +
    "\n" +
    "        class=\"fa fa-image\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === 'image'\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        uib-tooltip=\"Email Message\"\r" +
    "\n" +
    "        class=\"fa fa-envelope\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === 'msg'\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        class=\"fa fa-file\"\r" +
    "\n" +
    "        ng-if=\"vm.fileExtension === ''\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/filterTags/filterTags.html',
    "<section\r" +
    "\n" +
    "    class=\"above\"\r" +
    "\n" +
    "    ng-show=\"vm.filters.getIsEnabled()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <strong>Searching: </strong>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        class=\"tag-button\"\r" +
    "\n" +
    "        ng-repeat=\"filter in vm.filters.getEnabledFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{ filter | filterString }}\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <i \r" +
    "\n" +
    "            ng-click=\"vm.onFilterRemoved(filter)\"\r" +
    "\n" +
    "            class=\"fa fa-times\">\r" +
    "\n" +
    "        </i>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a ng-click=\"vm.allFiltersRemoved()\">\r" +
    "\n" +
    "        Remove All\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/components/followUp/followUp.html',
    "<div>\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <follow-up-status\r" +
    "\n" +
    "                    follow-up=\"vm.followUp\"\r" +
    "\n" +
    "                    class=\"top-margin\">\r" +
    "\n" +
    "                </follow-up-status>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.followUp.unsubscribed\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.followUp.replyToEmailAddress\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                aa-label=\"Sender Email\"\r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.followUp.senderName\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                aa-label=\"Sender Name\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row top-margin\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.followUp.emailAddress\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                aa-label=\"Recipient Email\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.followUp.sendDateTime\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                aa-label=\"Send Date\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-blur=\"vm.updateTimeOfDay()\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-field-group=\"vm.timeOfDay\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"hours\"\r" +
    "\n" +
    "                aa-label=\"Send Time\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.updateTimeOfDay()\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.followUp.subject\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-12\"\r" +
    "\n" +
    "                aa-label=\"Email Subject\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"field-wrapper\">\r" +
    "\n" +
    "            <div class=\"label\">Email Message</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-angular\r" +
    "\n" +
    "                name=\"htmlcontent\"\r" +
    "\n" +
    "                ng-model=\"vm.followUp.htmlBody\"\r" +
    "\n" +
    "                ta-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "            </text-angular>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/followUpList/followUpList.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"text-right bottom-margin\">\r" +
    "\n" +
    "            <button class=\"small\" \r" +
    "\n" +
    "                ng-click=\"vm.addFollowUp()\">  \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Add Follow Up\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.followUps.length\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th></th> \r" +
    "\n" +
    "                    <th>Status</th>\r" +
    "\n" +
    "                    <th>Email Address</th>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"followUp in vm.followUps track by followUp.id\">\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <button ng-show=\"!followUp.sent && !followUp.unsubscribed\" \r" +
    "\n" +
    "                            ng-click=\"vm.send(followUp,$index)\" \r" +
    "\n" +
    "                            uib-tooltip=\"Send Now\">\r" +
    "\n" +
    "                            <i class=\"fa fa-paper-plane\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <follow-up-status   \r" +
    "\n" +
    "                            follow-up=\"followUp\" \r" +
    "\n" +
    "                            status-only>\r" +
    "\n" +
    "                        </follow-up-status>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{followUp.emailAddress}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            ng-click=\"vm.copyFollowUp(followUp,$index)\" \r" +
    "\n" +
    "                            uib-tooltip=\"Copy Email\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "                        </button><!--\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        --><button \r" +
    "\n" +
    "                            ng-click=\"vm.editFollowUp(followUp, $index)\"\r" +
    "\n" +
    "                            uib-tooltip=\"Edit\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                        </button><!--\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        --><button \r" +
    "\n" +
    "                            ng-show=\"!followUp.unsubscribed\"    \r" +
    "\n" +
    "                            ng-disabled=\"followUp.sent\" \r" +
    "\n" +
    "                            ng-click=\"vm.unsubscribeFollowUp(followUp,$index)\"\r" +
    "\n" +
    "                            uib-tooltip=\"Unsubscribe\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button><!--\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        --><button \r" +
    "\n" +
    "                            ng-show=\"followUp.unsubscribed\" \r" +
    "\n" +
    "                            ng-disabled=\"followUp.sent\" \r" +
    "\n" +
    "                            ng-click=\"vm.subscribeFollowUp(followUp,$index)\" \r" +
    "\n" +
    "                            uib-tooltip=\"Resubscribe\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-undo\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"!vm.followUps.length\" \r" +
    "\n" +
    "            message=\"No email follow-ups found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/followUpStatus/followUpStatus.html',
    "<span>\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        ng-show=\"!vm.followUp.sent && !vm.followUp.bounced && !vm.followUp.unsubscribed\"\r" +
    "\n" +
    "        uib-tooltip=\"{{ vm.sendDate }}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-calendar\"></i>\r" +
    "\n" +
    "        Scheduled \r" +
    "\n" +
    "        <span ng-show=\"!vm.statusOnly\">\r" +
    "\n" +
    "            - {{ vm.sendDate }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        ng-show=\"!vm.followUp.sent && vm.followUp.bounced\"\r" +
    "\n" +
    "        class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "         Bounced\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        ng-show=\"!vm.followUp.sent && vm.followUp.unsubscribed\"\r" +
    "\n" +
    "        class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "         Unsubscribed\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span ng-show=\"vm.followUp.sent\">\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"green\"\r" +
    "\n" +
    "            ng-show=\"!vm.followUp.opened && !vm.followUp.clickCount && !vm.followUp.bounced\"\r" +
    "\n" +
    "            uib-tooltip=\"{{ vm.sendDate }}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-envelope\"></i>\r" +
    "\n" +
    "             Sent \r" +
    "\n" +
    "             <span ng-show=\"!vm.statusOnly\">\r" +
    "\n" +
    "                - {{ vm.sendDate }}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"green\"\r" +
    "\n" +
    "            ng-show=\"vm.followUp.opened && !vm.followUp.clickCount && !vm.followUp.bounced\"\r" +
    "\n" +
    "            uib-tooltip=\"{{ vm.sendDate }}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-envelope-open\"></i>\r" +
    "\n" +
    "             Opened\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"green\"\r" +
    "\n" +
    "            ng-show=\"vm.followUp.clickCount > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-mouse-pointer\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ng-pluralize\r" +
    "\n" +
    "                count=\"vm.followUp.clickCount\"\r" +
    "\n" +
    "                ng-class=\"{'green': vm.followUp.clickCount > 0}\"\r" +
    "\n" +
    "                when=\"{'0': 'and never clicked', '1': 'Clicked Once', 'other': 'Clicked {} times'}\">\r" +
    "\n" +
    "            </ng-pluralize>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/followUpStrategyList/followUpStrategyList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"bottom-margin flex\"\r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search Strategies\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center left-margin\"\r" +
    "\n" +
    "        ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a  class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"samll\"\r" +
    "\n" +
    "                    ng-click=\"vm.newFollowUpStrategyClicked()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Strategy\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                Type\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                Comments\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"strategy in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                <a ng-click=\"vm.editFollowUpStrategyClicked(strategy.id)\">\r" +
    "\n" +
    "                    {{strategy.name}} \r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{strategy.strategyType}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"comments\">\r" +
    "\n" +
    "                {{strategy.comments}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"button-column\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(strategy)\" \r" +
    "\n" +
    "                    uib-tooltip=\"Delete Strategy\"\r" +
    "\n" +
    "                    ng-disabled=\"strategy.isDefault\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/components/globalSearch/globalSearch.html',
    "<div>\r" +
    "\n" +
    "     <!-- Search Bar Agent -->\r" +
    "\n" +
    "     <div\r" +
    "\n" +
    "        ng-form=\"globalSearch\"\r" +
    "\n" +
    "        class=\"global-search\"\r" +
    "\n" +
    "        ng-if=\"!vm.currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.searchPhrase\"\r" +
    "\n" +
    "                aa-label=\"Customer Search (bond #, name, email, etc.)\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"fill global-search-input\"\r" +
    "\n" +
    "                autocomplete=\"off\"\r" +
    "\n" +
    "                ui-keyup=\"{ esc: 'vm.closeSearch()' }\"\r" +
    "\n" +
    "                id=\"global-search-input\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-click=\"vm.search()\"\r" +
    "\n" +
    "                class=\"search-button\"\r" +
    "\n" +
    "                id=\"global-search-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-search\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div id=\"search-results\"\r" +
    "\n" +
    "             class=\"search-results fx\"\r" +
    "\n" +
    "             ng-show=\"vm.isSearchDropdownOpen\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.isError\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"no-padding left-padding bottom-padding\">\r" +
    "\n" +
    "                    <b class=\"red\">\r" +
    "\n" +
    "                        Error\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <small>An error occurred while searching</small>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasCustomerSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Customers\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    id=\"{{'global-search-customer-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterCustomerSearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"fill v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Customer #: <span ng-bind-html=\"result.customerId | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <span ng-bind-html=\"result.customerName | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            <span ng-show=\"result.personName\">\r" +
    "\n" +
    "                                <span ng-bind-html=\"result.personName | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"result.email\">\r" +
    "\n" +
    "                                <span>-</span>\r" +
    "\n" +
    "                                <span ng-bind-html=\"result.email | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"result.phone\">\r" +
    "\n" +
    "                                <span>-</span>\r" +
    "\n" +
    "                                <span ng-bind-html=\"result.phone | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasActivitySearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Activities\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    id=\"{{'global-search-activity-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterActivitySearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span class=\"fill v-center\">\r" +
    "\n" +
    "                        Activity #: <span ng-bind-html=\"result.activityId | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        <span>-</span>\r" +
    "\n" +
    "                        {{result.activityStatus}}\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            <span ng-bind-html=\"result.activityDescription | limitTo:50 \"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasBondSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Bonds\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    id=\"{{'global-search-bond-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterBondSearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"fill v-center\">\r" +
    "\n" +
    "                        Bond #: <span ng-bind-html=\"result.bondNumber | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            Principal: <span ng-bind-html=\"result.nameOnBond | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small ng-show=\"result.bondLegacyId\">\r" +
    "\n" +
    "                            Legacy Id: <span ng-bind-html=\"result.bondLegacyId | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button id=\"{{'global-search-bond-result-detail-button-' + $index}}\"\r" +
    "\n" +
    "                            show-bond-detail-modal=\"{{result.bondId}}\"\r" +
    "\n" +
    "                            class=\"mini v-center left-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Details\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasInvoiceSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Invoices\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    id=\"{{'global-search-invoice-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterInvoiceSearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"v-center fill\">\r" +
    "\n" +
    "                        Invoice #: <span ng-bind-html=\"result.invoiceNumber | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            Customer: <span ng-bind-html=\"result.customerName | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small ng-show=\"result.billingEntryId\">\r" +
    "\n" +
    "                            Billing Entry Id: <span ng-bind-html=\"result.billingEntryId| highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        show-invoice-detail-modal=\"{{result.invoiceId}}\"\r" +
    "\n" +
    "                        class=\"mini left-margin v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        View Invoice\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasApplicationSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Applications\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li id=\"{{'global-search-application-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterApplicationSearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"v-center fill\">\r" +
    "\n" +
    "                        Application #: <span ng-bind-html=\"result.applicationNumber | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            Principal: <span ng-bind-html=\"result.nameOnBond | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small ng-show=\"result.applicationLegacyId\">\r" +
    "\n" +
    "                            Legacy Id: <span ng-bind-html=\"result.applicationLegacyId | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button show-application-detail-modal=\"{{result.applicationId}}\"\r" +
    "\n" +
    "                            class=\"mini left-margin v-center\"\r" +
    "\n" +
    "                            ng-click=\"vm.clearSearch()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Detail\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasQuoteSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">\r" +
    "\n" +
    "                    Quotes\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li id=\"{{'global-search-quotes-result-' + $index}}\"\r" +
    "\n" +
    "                    ng-repeat=\"result in vm.searchResults | filter: vm.filterQuoteSearchResultType\"\r" +
    "\n" +
    "                    ng-click=\"vm.handleResultClick(result)\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"v-center fill\">\r" +
    "\n" +
    "                        Quote #: <span ng-bind-html=\"result.quoteNumber | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            Principal: <span ng-bind-html=\"result.nameOnBond | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button show-application-detail-modal=\"result.applicationId\"\r" +
    "\n" +
    "                            class=\"mini left-margin v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Detail\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category no-results\"\r" +
    "\n" +
    "                ng-show=\"!vm.isError && !vm.isSearching && !vm.searchResults.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-click=\"vm.clearSearch()\">\r" +
    "\n" +
    "                    <span>\r" +
    "\n" +
    "                        Nothing found\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                    <small>\r" +
    "\n" +
    "                        We couldn't find any matches for \"<span ng-bind-html=\"vm.searchPhrase | highlight: vm.searchPhrase\"></span>\"\r" +
    "\n" +
    "                    </small>\r" +
    "\n" +
    "                    <small>\r" +
    "\n" +
    "                        Click to clear\r" +
    "\n" +
    "                    </small>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"results-category searching\"\r" +
    "\n" +
    "                ng-show=\"vm.isSearching\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    Searching... \r" +
    "\n" +
    "                    <small>One second while we look this up</small>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"search-overlay\"\r" +
    "\n" +
    "            id=\"search-overlay\"\r" +
    "\n" +
    "            ng-show=\"vm.isSearchDropdownOpen\"\r" +
    "\n" +
    "            ng-click=\"vm.closeSearch()\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "     </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- Search Bar Carrier-->\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"globalSearch\"\r" +
    "\n" +
    "        class=\"global-search\"\r" +
    "\n" +
    "        ng-if=\"vm.currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.searchPhrase\"\r" +
    "\n" +
    "                aa-label=\"Bond Search (bond #, principal name)\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-fg-class=\"fill global-search-input\"\r" +
    "\n" +
    "                autocomplete=\"off\"\r" +
    "\n" +
    "                ui-keyup=\"{ esc: 'vm.closeSearch()' }\"\r" +
    "\n" +
    "                id=\"global-search-input-carrier\"/>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                id=\"global-search-button-carrier\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-click=\"vm.search()\"\r" +
    "\n" +
    "                class=\"search-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-search\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            id=\"search-results\"\r" +
    "\n" +
    "            class=\"search-results fx\"\r" +
    "\n" +
    "            ng-show=\"vm.isSearchDropdownOpen\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <ul\r" +
    "\n" +
    "                class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.isError\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"no-padding left-padding bottom-padding\">\r" +
    "\n" +
    "                    <b class=\"red\">Error</b>\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <small>An error occurred while searching</small>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul\r" +
    "\n" +
    "                class=\"results-category\"\r" +
    "\n" +
    "                ng-show=\"vm.hasBondSearchResults\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"title\">Purchased Bonds</li>\r" +
    "\n" +
    "                <li ng-repeat=\"result in vm.searchResults | filter: vm.filterBondSearchResultType\">\r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        id=\"{{'global-search-bonds-result-' + $index}}\"\r" +
    "\n" +
    "                        show-bond-detail-modal=\"{{result.bondId}}\"\r" +
    "\n" +
    "                        ng-click=\"vm.clearSearch()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"search-result-info\">\r" +
    "\n" +
    "                            {{ result.bondType | limitTo:50 }} #\r" +
    "\n" +
    "                            <span ng-bind-html=\"result.bondNumber | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                            <small>\r" +
    "\n" +
    "                                Principal: <span ng-bind-html=\"result.nameOnBond | limitTo:50 | highlight: vm.searchPhrase\"></span>\r" +
    "\n" +
    "                            </small>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <div class=\"clearfix\"></div>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul\r" +
    "\n" +
    "                class=\"results-category no-results\"\r" +
    "\n" +
    "                ng-show=\"!vm.isSearching && !vm.isError && !vm.searchResults.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <a ng-click=\"vm.clearSearch()\">\r" +
    "\n" +
    "                        <span>Nothing found </span>\r" +
    "\n" +
    "                        <small>\r" +
    "\n" +
    "                            We couldn't find any customer information for \"<span ng-bind-html=\"vm.searchPhrase | highlight: vm.searchPhrase\"></span>\" - Click to clear\r" +
    "\n" +
    "                        </small>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul\r" +
    "\n" +
    "                class=\"results-category searching\"\r" +
    "\n" +
    "                ng-show=\"vm.isSearching\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <a>Searching... <small>One second while we look this up</small></a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"search-overlay\"\r" +
    "\n" +
    "            id=\"search-overlay\"\r" +
    "\n" +
    "            ng-show=\"vm.isSearchDropdownOpen\"\r" +
    "\n" +
    "            ng-click=\"vm.closeSearch()\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/help/help.html',
    "<span class=\"help-popover\">\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        class=\"fa fa-question-circle\" \r" +
    "\n" +
    "        popover-trigger=\"'mouseenter'\" \r" +
    "\n" +
    "        uib-popover=\"{{vm.message}}\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/helpIcon/helpIcon.html',
    "<span class=\"{{vm.iconClass}}\">\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        class=\"fa fa-question-circle\"\r" +
    "\n" +
    "        title=\"{{vm.message}}\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/invoiceList/invoiceList.html',
    "<div>\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"bottom-margin group-by-tbody\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th class=\"expand-indicator-column\">&nbsp;</th>\r" +
    "\n" +
    "                <th class=\"row-select-column\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.table.allSelected\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Invoice #\"\r" +
    "\n" +
    "                        sort-expression=\"invoices.invoiceNumber\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Date\"\r" +
    "\n" +
    "                        sort-expression=\"invoices.createdDateTime\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Status\"\r" +
    "\n" +
    "                        sort-expression=\"invoices.status\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>Comments</th>\r" +
    "\n" +
    "                <th class=\"text-center\">\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Amount Due\"\r" +
    "\n" +
    "                        sort-expression=\"invoices.totalAmountDue\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody\r" +
    "\n" +
    "            ng-form=\"{{'invoiceForm' + $index }}\" \r" +
    "\n" +
    "            ng-repeat=\"invoice in vm.table.data\">\r" +
    "\n" +
    "            <tr \r" +
    "\n" +
    "                id=\"{{'invoice-list-item-row-' + $index}}\"\r" +
    "\n" +
    "                class=\"cursor-pointer\"> \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"fit-content\"\r" +
    "\n" +
    "                    ng-click=\"vm.table.toggleIsExpanded($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i ng-class=\"{\r" +
    "\n" +
    "                        'fa fa-caret-right': !vm.table.rows[$index].isExpanded, \r" +
    "\n" +
    "                        'fa fa-caret-down': vm.table.rows[$index].isExpanded\r" +
    "\n" +
    "                    }\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"row-select-column\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.table.rows[$index].isSelected\" \r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ui-sref=\"main.eProducerAccountDetail({ id: invoice.eProducerAccountId })\"\r" +
    "\n" +
    "                        ng-show=\"invoice.eProducerAccountId\" \r" +
    "\n" +
    "                        target=\"_blank\" >\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Broker -\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        show-invoice-detail-modal=\"invoice.id\"\r" +
    "\n" +
    "                        ng-show=\"invoice.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    {{invoice.createdDateTime | date:'MM/dd/yyyy'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <span ng-class=\"{'red': invoice.status === 'Voided'}\">\r" +
    "\n" +
    "                        {{invoice.status}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{invoice.comments}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        id=\"{{'invoice-list-total-amount-due-span-' + $index}}\"\r" +
    "\n" +
    "                        ng-show=\"!vm.table.rows[$index].isExpanded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{invoice.totalAmountDue | currency:'$'}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column fit-content\">\r" +
    "\n" +
    "                    <pdf-download \r" +
    "\n" +
    "                        uib-tooltip=\"Download Invoice\" \r" +
    "\n" +
    "                        url=\"vm.getInvoiceDownloadUrl(invoice.id)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                        Download\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr \r" +
    "\n" +
    "                class=\"table-details-row\"\r" +
    "\n" +
    "                ng-show=\"vm.table.rows[$index].isExpanded\">\r" +
    "\n" +
    "                <td colspan=\"8\">\r" +
    "\n" +
    "                    <table class=\"small no-stripe\">\r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <th class=\"fit-content\">Billing #</th>\r" +
    "\n" +
    "                            <th>Description</th>\r" +
    "\n" +
    "                            <th class=\"fit-content\">Credit</th>\r" +
    "\n" +
    "                            <th class=\"fit-content\">Amount Due</th>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <tr ng-repeat-start=\"lineItem in invoice.lineItems\"\r" +
    "\n" +
    "                            ng-if=\"$index != 0 && invoice.lineItems[$index - 1].bondTransactionId != invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                            <td colspan=\"4\">\r" +
    "\n" +
    "                                <!-- Spacer row between bond numbers -->\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <tr ng-if=\"$index == 0 || invoice.lineItems[$index - 1].bondTransactionId != invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                            <td></td>\r" +
    "\n" +
    "                            <td>\r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    {{lineItem.bondNumber}} - {{lineItem.bondAmount | currency:'$'}} {{lineItem.bondType}}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    Term: {{ lineItem.effectiveDate | dateOnly }} - {{ lineItem.expirationDate | dateOnly }} Eff Date: {{ lineItem.transactionEffectiveDate | dateOnly }}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    Principal: {{ lineItem.nameOnBond }}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    Writing Company: {{ lineItem.writingCompany }}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td></td>\r" +
    "\n" +
    "                            <td></td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <tr ng-repeat-end>\r" +
    "\n" +
    "                            <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                {{lineItem.billingEntryId}}\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td class=\"no-top-padding no-bottom-padding\">\r" +
    "\n" +
    "                                {{ lineItem.code }} - {{ lineItem.description }}\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                <span ng-show=\"lineItem.amountReceived != 0\">\r" +
    "\n" +
    "                                    {{lineItem.amountReceived | currency:'$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                <span ng-show=\"lineItem.amountDue != 0\">\r" +
    "\n" +
    "                                    {{lineItem.amountDue | currency:'$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <td \r" +
    "\n" +
    "                                class=\"no-right-padding\"\r" +
    "\n" +
    "                                colspan=\"4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"flex\">\r" +
    "\n" +
    "                                    <table class=\"no-stripe width-auto left-margin-auto\">\r" +
    "\n" +
    "                                        <tr>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                Invoice Amount:\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                {{invoice.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                        </tr>\r" +
    "\n" +
    "                                        <tr>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                Payments Received:\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                {{invoice.totalAmountReceived | currency: '$'}}\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                        </tr>\r" +
    "\n" +
    "                                        <tr>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                Outstanding Balance:\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                <b>{{invoice.totalAmountDue | currency:'$'}}</b>\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                        </tr>\r" +
    "\n" +
    "                                    </table>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No invoices found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/invoiceListFilterDropdown/invoiceListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"invoiceListFilterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>     \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Status\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\">\r" +
    "\n" +
    "                        Any\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Open\">\r" +
    "\n" +
    "                        Open\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Paid\">\r" +
    "\n" +
    "                        Paid\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Voided\">\r" +
    "\n" +
    "                        Voided\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Amount Due Between\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.amountDueMinimum\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"between-label\">\r" +
    "\n" +
    "                    and\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.amountDueMaximum\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/loggedInUserDropdown/loggedInUserDropdown.html',
    "<li\r" +
    "\n" +
    "    class=\"user\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.userDropdownOpen\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        id=\"header-user-profile-dropdown-button\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <user-avatar \r" +
    "\n" +
    "            user-id=\"vm.currentUser.user.userId\" \r" +
    "\n" +
    "            class=\"mini\">\r" +
    "\n" +
    "        </user-avatar>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\"\r" +
    "\n" +
    "        uib-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-form=\"userProfileDropdown\"\r" +
    "\n" +
    "            class=\"profile-dropdown row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-4 text-center\">\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    <user-avatar\r" +
    "\n" +
    "                        id=\"profileImage\"\r" +
    "\n" +
    "                        class=\"profile-image\"\r" +
    "\n" +
    "                        user-id=\"vm.currentUser.user.userId\">\r" +
    "\n" +
    "                    </user-avatar>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    id=\"header-user-profile-button\"\r" +
    "\n" +
    "                    class=\"user-profile-button\"\r" +
    "\n" +
    "                    ui-sref=\"main.userProfile\"\r" +
    "\n" +
    "                    ng-click=\"vm.userDropdownOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    User Profile\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-8 flex column button-container\">\r" +
    "\n" +
    "                <div class=\"fill\">\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <h4 class=\"top-margin-thin bottom-margin-thin\">\r" +
    "\n" +
    "                            {{vm.currentUser.user.fullName}}\r" +
    "\n" +
    "                        </h4>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        {{vm.currentUser.user.email}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            id=\"header-user-selection-button\"\r" +
    "\n" +
    "                            ng-show=\"!vm.currentUser.isSingleSignOn && vm.currentUser.hasMultipleUsers\"\r" +
    "\n" +
    "                            class=\"v-center\"\r" +
    "\n" +
    "                            ui-sref=\"userSelection\"\r" +
    "\n" +
    "                            ng-click=\"vm.userDropdownOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Switch Account\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"top-margin-auto\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        id=\"header-logout-button\"\r" +
    "\n" +
    "                        class=\"logout-button\"\r" +
    "\n" +
    "                        ui-sref=\"userSelection\"\r" +
    "\n" +
    "                        ng-click=\"vm.userDropdownOpen = false\"\r" +
    "\n" +
    "                        ng-show=\"vm.currentUser.isSingleSignOn && vm.currentUser.hasMultipleUsers\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Switch Account\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"top-margin-auto\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        id=\"header-logout-button\"\r" +
    "\n" +
    "                        class=\"logout-button\"\r" +
    "\n" +
    "                        ng-click=\"vm.logout()\"\r" +
    "\n" +
    "                        ng-hide=\"vm.currentUser.isSingleSignOn\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Log Out\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</li>"
  );


  $templateCache.put('app/components/memoList/memoList.html',
    "<div  busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"field-flex-box\"\r" +
    "\n" +
    "        ng-form name=\"newMemoForm\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.newMemo\"\r" +
    "\n" +
    "            aa-label-strategy=\"none\" \r" +
    "\n" +
    "            placeholder=\"New Memo\" \r" +
    "\n" +
    "            aa-fg-class=\"no-margin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.addNewMemo()\" \r" +
    "\n" +
    "            class=\"no-margin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Add Memo\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"top-margin small\">\r" +
    "\n" +
    "        <table \r" +
    "\n" +
    "            class=\"bottom-margin\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"User\"\r" +
    "\n" +
    "                            sort-expression=\"Users.FirstName,Users.LastName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Date/Time\"\r" +
    "\n" +
    "                            sort-expression=\"Memos.CreatedDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>Memo</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"memo in vm.table.data\">\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <span class=\"user mini flex\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <user-avatar \r" +
    "\n" +
    "                                user-id=\"memo.userId\" \r" +
    "\n" +
    "                                class=\"mini v-center\">\r" +
    "\n" +
    "                            </user-avatar>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"left-margin-thin inline-block v-center\">\r" +
    "\n" +
    "                                {{memo.createdByUser}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        {{memo.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{memo.memoText}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer \r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "            table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "        </table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"!vm.table.isVisible\"\r" +
    "\n" +
    "            message=\"No memos found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/notificationSelectionForm/notificationSelection.html',
    "<section>\r" +
    "\n" +
    "    <div  busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        <i class=\"fa fa-envelope\"></i>Notifications\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <p>Here you can choose when and how you want to be notified by A3 for events that happen. \r" +
    "\n" +
    "        We'll send emails to the same email address that you log in with. Browser notifications show in\r" +
    "\n" +
    "         the notification dropdown located in the upper right corner.</p>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"row\">\r" +
    "\n" +
    "        <div class=\" col-md-4 col-sm-6\">\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Release Notifications \r" +
    "\n" +
    "                <help message=\"Release notifications are sent out when ever we release a new version of A3. We try to keep you informed of recent changes.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReleaseEmails\" \r" +
    "\n" +
    "                    aa-label=\"Email\" \r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReleaseBrowserNotifications\" \r" +
    "\n" +
    "                    aa-label=\"Browser Notification\" \r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\" col-md-4 col-sm-6\">\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Support Ticket Notifications \r" +
    "\n" +
    "                <help message=\"We'll send you this notification when a support ticket that you created has been updated.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesSupportTicketEmails\" \r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesSupportTicketBrowserNotifications\" \r" +
    "\n" +
    "                    aa-label=\"Browser Notification\" \r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\" col-md-4 col-sm-6\">\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Document Correction Notifications \r" +
    "\n" +
    "                <help message=\"These notifications are sent when you request a bond form to be corrected or replaced and the Agency Multiplied Support Team has updated the document with the corrections.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesDocumentCorrectionCompletionEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.receivesDocumentCorrectionCompletionBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" \r" +
    "\n" +
    "                    disabled />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\" col-md-4 col-sm-6\">\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Bond Type Request Notifications \r" +
    "\n" +
    "                <help message=\"These notifications are sent when you request a new bond type be added to the system. Once the Agency Multiplied Support Team adds your new bond type you will receive this notification\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesBondTypeRequestCompletionEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesBondTypeRequestCompletionBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\" \r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" \r" +
    "\n" +
    "                    disabled />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Applications Through EProducer \r" +
    "\n" +
    "                <help message=\"This notification let's you know when an application is submitted through eProducer.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerApplicationSubmissionEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerApplicationSubmissionBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notifications\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Bond Purchased in EProducer \r" +
    "\n" +
    "                <help message=\"Alerts you when a bond is purchased through eProducer.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerBondPurchaseEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerBondPurchaseBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Broker Account Request \r" +
    "\n" +
    "                <help message=\"This notification alerts you to let you know when someone requests to be a broker agent through eProducer.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerBrokerAccountRequestEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"  />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerBrokerAccountRequestBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Payment Received Through eProducer \r" +
    "\n" +
    "                <help message=\"This notification is sent out when a payment is received through the eProducer payment.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerPaymentReceivedEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerPaymentReceivedBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Indemnity Signature Received \r" +
    "\n" +
    "                <help message=\"When an Indemnity Signature is received a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesIndemnitySignatureReceivedEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesIndemnitySignatureReceivedBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Cancellation Request \r" +
    "\n" +
    "                <help message=\"When a Cancellation is requested by an agent a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCancellationRequestEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCancellationRequestBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Agent Requested Bond Type \r" +
    "\n" +
    "                <help message=\"When a new bond type is created after an agent submits a request for that bond type. This will prompt your team to configure underwriting for the new bond type.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesBondTypeRequestCompletionCarrierEmail\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesBondTypeRequestCompletionCarrierBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Reinstatement Request \r" +
    "\n" +
    "                <help message=\"When a Reinstatement is requested by an agent a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReinstatementRequestEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReinstatementRequestBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Reinstatement Approved/Declined \r" +
    "\n" +
    "                <help message=\"When a Reinstatement is approved or denied by a carrier, a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReinstatementApprovedDeclinedEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesReinstatementApprovedDeclinedBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Cancellation Approved\r" +
    "\n" +
    "                <help message=\"When a Cancellation is approved by a carrier,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCancellationApprovedEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCancellationApprovedBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Attachment Uploaded In EProducer\r" +
    "\n" +
    "                <help message=\"When an attachment is uploaded in EProducer,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerAttachmentEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesEProducerAttachmentBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Rider Request\r" +
    "\n" +
    "                <help message=\"When a Rider is requested by an agent,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesRiderRequestEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesRiderRequestBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Rider Approved/Declined\r" +
    "\n" +
    "                <help message=\"When a Rider is approved/declined by a carrier,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesRiderApprovedDeclinedEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesRiderApprovedDeclinedBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Rider Created by Carrier\r" +
    "\n" +
    "                <help message=\"When a Rider is created by a carrier,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedRiderEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedRiderBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Cancellations Created by Carrier\r" +
    "\n" +
    "                <help message=\"When a Cancellation is created by a carrier,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedCancellationEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedCancellationBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\" col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-if=\"vm.isAgent\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h5 class=\"top-margin\">\r" +
    "\n" +
    "                Reinstatements Created by Carrier\r" +
    "\n" +
    "                <help message=\"When a Reinstatement is created by a carrier,  a notification is sent to alert you of the event.\"></help>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedReinstatementEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesCarrierCreatedReinstatementBrowserNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"row top-margin\">\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"col-md-4 col-sm-6\" \r" +
    "\n" +
    "            ng-show=\"vm.isDevelopmentEnvironment\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h5 class=\"top-margin flex\">\r" +
    "\n" +
    "                Test Notifications\r" +
    "\n" +
    "                <help message=\"This is to test that the functionality of the email and SignalR notification systems are operational\"></help>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"left-margin mini left-margin-auto\"\r" +
    "\n" +
    "                    ng-click=\"vm.sendTestNotification()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Test\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesTestEmails\"\r" +
    "\n" +
    "                    aa-label=\"Email\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.user.receivesTestNotifications\"\r" +
    "\n" +
    "                    aa-label=\"Browser Notification\"\r" +
    "\n" +
    "                    label-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/components/obligeeForm/obligeeForm.html',
    "<div ng-form=\"obligeeForm\">\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section\r" +
    "\n" +
    "        class=\"above\"\r" +
    "\n" +
    "        ng-show=\"vm.isMasterEntryMode\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div class=\"v-center left-margin-auto\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.obligee.isGeneric\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-change=\"vm.obligeeChange('isGeneric')\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-input-wrapper-class=\"pull-right\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center left-margin\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.obligee.isActive\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-change=\"vm.obligeeChange('isActive')\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-input-wrapper-class=\"pull-right\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section\r" +
    "\n" +
    "        class=\"text-left\"\r" +
    "\n" +
    "        ng-show=\"vm.isGenericObligee && vm.isMasterEntryMode\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        Generic Obligee information collected via eProducer will be defaulted to the values provided below but may be changed by the user.\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            General Info\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.obligee.name\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "            ng-change=\"vm.obligeeChange('name')\"\r" +
    "\n" +
    "            aa-label=\"Obligee Name\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <address\r" +
    "\n" +
    "            object=\"vm.obligee\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "            changed=\"vm.addressChange(object, changedProperty)\"\r" +
    "\n" +
    "            ng-required=\"!vm.isGenericObligee\">\r" +
    "\n" +
    "        </address>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Contact Info\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.phone\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('phone')\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.fax\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('fax')\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.email\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('email')\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Contacts\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.contact1\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('contact1')\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.contact2\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('contact2')\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.obligee.contact3\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeChange('contact3')\"  \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Additional Info\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.obligee.websiteURL\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "            ng-change=\"vm.obligeeChange('websiteURL')\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"url\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            aa-field-group=\"vm.obligee.requisitioningState\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "            ng-change=\"vm.obligeeChange('requisitioningState')\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"stateWithFederal\"\r" +
    "\n" +
    "            ng-required=\"vm.isMasterEntryMode\"\r" +
    "\n" +
    "            ng-show=\"vm.isMasterEntryMode\">\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            aa-field-group=\"vm.obligee.comments\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "            ng-change=\"vm.obligeeChange('comments')\"\r" +
    "\n" +
    "            ng-show=\"vm.isMasterEntryMode\">\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-left\"\r" +
    "\n" +
    "        ng-show=\"!vm.newItem && vm.isMasterEntryMode\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Bond Types Assigned to Obligee\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"col-md-4\"\r" +
    "\n" +
    "                ng-repeat=\"bondType in vm.obligee.bondTypes\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a ui-sref=\"main.bondTypesDetail({ id: bondType.id })\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{bondType.name}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-if=\"!vm.obligee.bondTypes\"\r" +
    "\n" +
    "                class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                No bond types assigned\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-click=\"vm.cancel()\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        class=\"white\"\r" +
    "\n" +
    "        ng-show=\"vm.isMasterEntryMode\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        value=\"Save\"\r" +
    "\n" +
    "        ng-show=\"vm.isMasterEntryMode\" />\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/paymentList/paymentList.html',
    "<div>\r" +
    "\n" +
    "    <table \r" +
    "\n" +
    "        class=\"bottom-margin small\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Status\"\r" +
    "\n" +
    "                        sort-expression=\"paymentStatus\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Date/Time\"\r" +
    "\n" +
    "                        sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Payment Method\"\r" +
    "\n" +
    "                        sort-expression=\"paymentMethod\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>Payment Processor ID</th>\r" +
    "\n" +
    "                <th>Account #</th>\r" +
    "\n" +
    "                <th>Comments</th>\r" +
    "\n" +
    "                <th>\r" +
    "\n" +
    "                    <table-sort-button\r" +
    "\n" +
    "                        text=\"Amount\"\r" +
    "\n" +
    "                        sort-expression=\"amount\"\r" +
    "\n" +
    "                        table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                    </table-sort-button>\r" +
    "\n" +
    "                </th>\r" +
    "\n" +
    "                <th>Unapplied</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"fit-content text-left\"\r" +
    "\n" +
    "                    ng-class=\"{'red': item.paymentStatus != 'Approved'}\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{item.paymentStatus}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"item.paymentStatus != 'Approved'\"\r" +
    "\n" +
    "                        class=\"fa fa-info-circle small\"\r" +
    "\n" +
    "                        uib-tooltip={{item.message}}></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                    <span ng-show=\"item.paymentMethod !== 'Other'\">\r" +
    "\n" +
    "                        {{item.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"item.paymentMethod === 'Other'\">\r" +
    "\n" +
    "                        {{item.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{item.paymentMethod}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{item.transactionId}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{item.accountNumber}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>{{item.comments}}</td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    {{item.amount | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        ng-show=\"item.isNotFullyApplied\"\r" +
    "\n" +
    "                        class=\"red fa fa-exclamation-triangle right-margin-thin\"\r" +
    "\n" +
    "                        uib-tooltip=\"Payment amount is not fully applied to invoices\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "                    {{item.unappliedAmount | currency: '$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"button-column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-disabled=\"vm.getIsAssignButtonDisabled(item)\"\r" +
    "\n" +
    "                        ng-click=\"vm.showPaymentAssignmentModal(item)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Details\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "        message=\"No payments were found.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/paymentListFilterDropdown/paymentListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"paymentListFilterDropdown\">\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.paymentStatus\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Approved\"> Approved </option>\r" +
    "\n" +
    "                    <option value=\"Declined\"> Declined </option>\r" +
    "\n" +
    "                    <option value=\"Error\"> Error </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Payment Amount Between </label>\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Applied Status </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isNotFullyApplied\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"false\"> Fully Applied </option>\r" +
    "\n" +
    "                    <option value=\"true\"> Not Fully Applied </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Payment Method </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.paymentMethod\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"allPaymentMethods\"\r" +
    "\n" +
    "                    aa-unselected-text=\"All\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/paymentReport/paymentReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"flex bottom-margin\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search Payments\"\r" +
    "\n" +
    "           uib-tooltip=\"Search Payments\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"purchased-bonds-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <payment-report-filter-dropdown class=\"flex left-margin\"\r" +
    "\n" +
    "                                    table=\"vm.table\">\r" +
    "\n" +
    "    </payment-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportReport()\">\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download CSV\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <table class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"expand-indicator-column\">&nbsp;</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Date/Time</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Status</th>\r" +
    "\n" +
    "                    <th>Customer/Broker</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Amount</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Payment Method</th>\r" +
    "\n" +
    "                    <th class=\"text-left\">Reference #</th>\r" +
    "\n" +
    "                    <th class=\"text-left\">Bond #</th>\r" +
    "\n" +
    "                    <th class=\"text-left\">Invoice #</th>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"payment in vm.table.data\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        class=\"expand-indicator-column cursor-pointer\"\r" +
    "\n" +
    "                        ng-click=\"vm.table.toggleIsExpanded($index)\">\r" +
    "\n" +
    "                        <i\r" +
    "\n" +
    "                            ng-class=\"{\r" +
    "\n" +
    "                            'fa fa-caret-right': !vm.table.rows[$index].isExpanded, \r" +
    "\n" +
    "                            'fa fa-caret-down': vm.table.rows[$index].isExpanded}\"></i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <span ng-show=\"payment.paymentMethod !== 'Other'\">\r" +
    "\n" +
    "                            {{payment.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"payment.paymentMethod === 'Other'\">\r" +
    "\n" +
    "                            {{payment.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-class=\"{'red': payment.paymentStatus !== 'Approved'}\">\r" +
    "\n" +
    "                            {{payment.paymentStatus}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: payment.customerId, sectionView: 'payments' })\"\r" +
    "\n" +
    "                            ng-show=\"payment.customerId && !payment.eProducerAccountId\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            <span ng-show=\"!payment.customerLookupCode\"\r" +
    "\n" +
    "                                >No Lookup Code</span\r" +
    "\n" +
    "                            >\r" +
    "\n" +
    "                            <span ng-show=\"payment.customerLookupCode\"\r" +
    "\n" +
    "                                >{{payment.customerLookupCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.eProducerAccountDetail({ id: payment.eProducerAccountId })\"\r" +
    "\n" +
    "                            ng-show=\"payment.eProducerAccountId\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"!payment.brokerLookupCode\">\r" +
    "\n" +
    "                                No Lookup Code\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"payment.brokerLookupCode\">\r" +
    "\n" +
    "                                {{payment.brokerLookupCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <span ng-show=\"!payment.eProducerAccountId\">\r" +
    "\n" +
    "                                {{payment.customerName}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"payment.eProducerAccountId\">\r" +
    "\n" +
    "                                Broker - {{payment.eProducerAccountName}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{payment.amount | currency:'$'}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i\r" +
    "\n" +
    "                            ng-show=\"payment.isNotFullyApplied\"\r" +
    "\n" +
    "                            class=\"red fa fa-exclamation-triangle right-margin\"\r" +
    "\n" +
    "                            uib-tooltip=\"Payment amount is not fully applied to invoices\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{payment.paymentMethod}} {{payment.accountNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <div ng-repeat=\"referenceNumber in payment.referenceNumbers\">\r" +
    "\n" +
    "                            {{referenceNumber}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <div ng-repeat=\"bond in payment.bonds\">\r" +
    "\n" +
    "                            <a show-bond-detail-modal=\"bond.id\">\r" +
    "\n" +
    "                                {{bond.bondNumber}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <div ng-repeat=\"invoice in payment.invoices\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                show-invoice-detail-modal=\"invoice.id\"\r" +
    "\n" +
    "                                ng-show=\"invoice.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"button-column\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-show=\"payment.paymentStatus === 'Approved'\"\r" +
    "\n" +
    "                            class=\"mini\"\r" +
    "\n" +
    "                            show-payment-assignment-modal=\"payment.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr\r" +
    "\n" +
    "                    class=\"table-details-row\"\r" +
    "\n" +
    "                    ng-show=\"vm.table.rows[$index].isExpanded\">\r" +
    "\n" +
    "                    <td>&nbsp;</td>\r" +
    "\n" +
    "                    <td colspan=\"10\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <div class=\"fill\">\r" +
    "\n" +
    "                                <div>                                    \r" +
    "\n" +
    "                                    <div ng-show=\"payment.companyName\">\r" +
    "\n" +
    "                                        {{payment.companyName}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div ng-show=\"payment.firstName\">    \r" +
    "\n" +
    "                                        {{payment.firstName}} {{payment.lastName}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{payment.email}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i ng-show=\"!payment.email\">\r" +
    "\n" +
    "                                            Email Unknown\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <span> | </span> \r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        {{payment.phone}}\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i ng-show=\"!payment.phone\">\r" +
    "\n" +
    "                                            Phone Unknown\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div ng-show=\"payment.streetAddress\">\r" +
    "\n" +
    "                                        {{payment.streetAddress}}\r" +
    "\n" +
    "                                        {{payment.suiteAptNumber}} <br />\r" +
    "\n" +
    "                                        {{payment.city}}, {{payment.state}},\r" +
    "\n" +
    "                                        {{payment.zipCode}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div ng-show=\"!payment.streetAddress\">\r" +
    "\n" +
    "                                        Address: <i>Unknown</i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex\">\r" +
    "\n" +
    "                                <div class=\"text-right right-margin\">\r" +
    "\n" +
    "                                    <div>Transaction Id:</div>\r" +
    "\n" +
    "                                    <div>Submitted Through: </div>\r" +
    "\n" +
    "                                    <div>Submitted By: </div>\r" +
    "\n" +
    "                                    <div>Account: </div>\r" +
    "\n" +
    "                                    <div>Check #: </div>\r" +
    "\n" +
    "                                    <div>Response: </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"text-left\">\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{payment.transactionId}}\r" +
    "\n" +
    "                                        <i ng-show=\"!payment.transactionId\">Unknown</i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        <span ng-show=\"payment.paymentOrigin && payment.paymentOrigin != 'Unknown'\">\r" +
    "\n" +
    "                                            {{payment.paymentOrigin}}\r" +
    "\n" +
    "                                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i ng-show=\"!payment.paymentOrigin || payment.paymentOrigin == 'Unknown'\">\r" +
    "\n" +
    "                                            Unknown\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{payment.paymentMadeBy}}\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i ng-show=\"!payment.paymentMadeBy\">\r" +
    "\n" +
    "                                            Unknown\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        <span ng-show=\"payment.accountNumber\">\r" +
    "\n" +
    "                                            {{payment.paymentInstitutionName}} Ending in {{payment.accountNumber}}\r" +
    "\n" +
    "                                        </span>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i ng-show=\"!payment.accountNumber\">\r" +
    "\n" +
    "                                            Unknown\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        <span ng-show=\"payment.checkNumber\">\r" +
    "\n" +
    "                                            {{payment.checkNumber}}\r" +
    "\n" +
    "                                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i ng-show=\"!payment.checkNumber\">\r" +
    "\n" +
    "                                            N/A\r" +
    "\n" +
    "                                        </i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{payment.message}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"top-margin\">\r" +
    "\n" +
    "                            Comments: {{payment.comments}}\r" +
    "\n" +
    "                            <i ng-show=\"!payment.comments\">None</i>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer\r" +
    "\n" +
    "            table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No payments found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/paymentsReportFilterDropdown/paymentReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"paymentReportFilterDropdown\">\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Approved\"> Approved </option>\r" +
    "\n" +
    "                    <option value=\"Declined\"> Declined </option>\r" +
    "\n" +
    "                    <option value=\"Error\"> Error </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Payment Amount Between </label>\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Applied Status </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isNotFullyApplied\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"false\"> Fully Applied </option>\r" +
    "\n" +
    "                    <option value=\"true\"> Not Fully Applied </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Payment Method </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.paymentMethod\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"allPaymentMethods\"\r" +
    "\n" +
    "                    aa-unselected-text=\"All\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/personForm/person.html',
    "<ng-form name=\"objectForm\">\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header ng-transclude=\"heading\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    class=\"v-center\"\r" +
    "\n" +
    "                    ng-show=\"!vm.heading\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{ vm.person.firstName }} \r" +
    "\n" +
    "                    {{ vm.person.lastName }}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.person.preferredName\">(Perferred: {{vm.person.preferredName}})</span>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    class=\"v-center\"\r" +
    "\n" +
    "                    ng-show=\"vm.heading\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{ vm.heading }}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span class=\"left-margin-auto v-center flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-track-with-epic\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.trackWithEpic\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Track With Epic\" \r" +
    "\n" +
    "                        aa-fg-class=\"left-margin v-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('trackWithEpic')\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-is-primary-contact\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.isPrimaryContact\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Primary Contact\" \r" +
    "\n" +
    "                        aa-fg-class=\"left-margin v-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('isPrimaryContact')\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-is-billing-contact\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.isBillingContact\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Billing Contact\" \r" +
    "\n" +
    "                        aa-fg-class=\"left-margin v-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('isBillingContact')\" />\r" +
    "\n" +
    "                      \r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-is-indemnitor\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.isIndemnitor\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Is Indemnitor\"\r" +
    "\n" +
    "                        aa-fg-class=\"left-margin v-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('isIndemnitor')\" />\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            id=\"person-notes\"\r" +
    "\n" +
    "            aa-label=\"Notes\"\r" +
    "\n" +
    "            aa-field-group=\"vm.person.notes\"\r" +
    "\n" +
    "            ng-change=\"vm.localChange('notes')\"\r" +
    "\n" +
    "            ng-required=\"false\"\r" +
    "\n" +
    "            maxlength=\"1000\"\r" +
    "\n" +
    "            ng-show=\"!vm.hideOptionalFields\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5>Name</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-first-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.firstName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('firstName')\"\r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div     \r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personMiddleName\"\r" +
    "\n" +
    "                class=\"col-xs-4\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-middle-name\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.middleName\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('middleName')\"\r" +
    "\n" +
    "                        ng-required=\"vm.requiredFields.personMiddleName && !vm.person.noMiddleName\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        aa-fg-class=\"fill right-margin-big\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"person-no-middle-name\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.person.noMiddleName\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                        aa-fg-class=\"form-inline\"\r" +
    "\n" +
    "                        ng-change=\"vm.localChange('noMiddleName')\"\r" +
    "\n" +
    "                        aa-label=\"N/A\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-last-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.lastName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('lastName')\"\r" +
    "\n" +
    "                required ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-preferred-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.preferredName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('preferredName')\"\r" +
    "\n" +
    "                ng-required=\"false\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"text\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-suffix\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.suffix\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('suffix')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSuffix\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personSuffix\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-prefix\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.prefix\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('prefix')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personPrefix\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personPrefix\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personSsn || vm.requiredFields.personDob || vm.requiredFields.personGender || vm.requiredFields.personDriversLicenseNumber || vm.requiredFields.personDriversLicenseState\">\r" +
    "\n" +
    "            Personal Information\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-ssn\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.ssn\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('ssn')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSsn\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personSsn\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"ssn\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-date-of-birth\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.dateOfBirth\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('dateOfBirth')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personDob\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personDob\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                id=\"person-gender\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.gender\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('gender')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personGender\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personGender\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"gender\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-drivers-license-number\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.driversLicenseNumber\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('driversLicenseNumber')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personDriversLicenseNumber\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personDriversLicenseNumber\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                id=\"person-drivers-license-state\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.driversLicenseState\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('driversLicenseState')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personDriversLicenseState\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personDriversLicenseState\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-nmls-number\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.nmlsNumber\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('nmlsNumber')\"\r" +
    "\n" +
    "                ng-required=\"false\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"text\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-ownership-percent\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.ownershipPercent\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('ownershipPercent')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personOwnershipPercent\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personOwnershipPercent\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"col-xs-3\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personIsUSCitizen\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"person-is-us-citizen\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.person.isUSCitizen\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    aa-fg-class=\"form-inline\"\r" +
    "\n" +
    "                    ng-change=\"vm.localChange('isUSCitizen')\"\r" +
    "\n" +
    "                    aa-label=\"US Citizen\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>    \r" +
    "\n" +
    "        \r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personEmail || vm.requiredFields.personHomePhone || vm.requiredFields.personCellPhone || vm.requiredFields.personFax || vm.requiredFields.personEmail\">\r" +
    "\n" +
    "            Contact\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-home-phone\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.homePhone\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('homePhone')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personHomePhone\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personHomePhone\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-cell-phone\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.cellPhone\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('cellPhone')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personCellPhone\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personCellPhone\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-fax\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.fax\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('fax')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personFax\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personFax\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-email\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.email\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('email')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personEmail\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personEmail\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-secondary-email\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.secondaryEmail\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('secondaryEmail')\"\r" +
    "\n" +
    "                ng-required=\"false\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personPhysicalAddress || vm.requiredFields.personResidenceType || vm.requiredFields.personDateMovedToResidence\">\r" +
    "\n" +
    "            Address\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-12\">\r" +
    "\n" +
    "                <address\r" +
    "\n" +
    "                    object=\"vm.person\"\r" +
    "\n" +
    "                    changed=\"vm.addressChanged(object, changedProperty)\"\r" +
    "\n" +
    "                    ng-required=\"vm.requiredFields.personPhysicalAddress\"\r" +
    "\n" +
    "                    county-required=\"vm.requiredFields.personPhysicalCounty\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personPhysicalAddress\">\r" +
    "\n" +
    "                </address>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                id=\"person-residence-type\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.residenceType\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('residenceType')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personResidenceType\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personResidenceType\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"residenceType\"\r" +
    "\n" +
    "                aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-date-moved-to-residence\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.dateMovedToResidence\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('dateMovedToResidence')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personDateMovedToResidence\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personDateMovedToResidence\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personEmployerName || vm.requiredFields.personEmployerAddress || vm.requiredFields.personJobTitle || vm.requiredFields.personYearsExperience\">\r" +
    "\n" +
    "            Employer\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-employer-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.employerName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('employerName')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personEmployerName\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personEmployerName\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-12\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <address\r" +
    "\n" +
    "                    object=\"vm.person\"\r" +
    "\n" +
    "                    changed=\"vm.addressChanged(object, changedProperty)\"\r" +
    "\n" +
    "                    ng-required=\"vm.requiredFields.personEmployerAddress\"\r" +
    "\n" +
    "                    county-required=\"vm.requiredFields.personEmployerCounty\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personEmployerAddress\"\r" +
    "\n" +
    "                    is-employer=\"true\">\r" +
    "\n" +
    "                </address>\r" +
    "\n" +
    "            </div>            \r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"person-job-title\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.person.jobTitle\"\r" +
    "\n" +
    "                    ng-change=\"vm.localChange('jobTitle')\"\r" +
    "\n" +
    "                    ng-required=\"vm.requiredFields.personJobTitle\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personJobTitle\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"person-years-experience\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.person.yearsExperience\"\r" +
    "\n" +
    "                    aa-label=\"Years of Industry Experience\"\r" +
    "\n" +
    "                    ng-change=\"vm.localChange('yearsExperience')\"\r" +
    "\n" +
    "                    ng-required=\"vm.requiredFields.personYearsExperience\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personYearsExperience\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"integer\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personMaritalStatus\">\r" +
    "\n" +
    "            Marital Status\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            id=\"person-marital-status\"\r" +
    "\n" +
    "            aa-field-group=\"vm.person.maritalStatus\"\r" +
    "\n" +
    "            ng-change=\"vm.localChange('maritalStatus')\"\r" +
    "\n" +
    "            ng-required=\"vm.requiredFields.personMaritalStatus\"\r" +
    "\n" +
    "            ng-show=\"!vm.hideOptionalFields || vm.requiredFields.personMaritalStatus\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"maritalStatus\"\r" +
    "\n" +
    "            aa-fg-class=\"width-200\"\r" +
    "\n" +
    "            ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"row\"\r" +
    "\n" +
    "            ng-if=\"vm.isMarried\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-first-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseFirstName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseFirstName')\"\r" +
    "\n" +
    "                ng-required=\"vm.isSpouseFieldRequired('personSpouseFirstName')\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.isSpouseFieldRequired('personSpouseFirstName')\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-middle-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseMiddleName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseMiddleName')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSpouseMiddleName\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.requiredFields.personSpouseMiddleName\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-last-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseLastName\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseLastName')\"\r" +
    "\n" +
    "                ng-required=\"vm.isSpouseFieldRequired('personSpouseLastName')\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.isSpouseFieldRequired('personSpouseLastName')\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-date-of-birth\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseDateOfBirth\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseDateOfBirth')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSpouseDob\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.requiredFields.personSpouseDob\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-ssn\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseSsn\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseSsn')\"\r" +
    "\n" +
    "                ng-required=\"vm.isSpouseFieldRequired('personSpouseSsn')\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.isSpouseFieldRequired('personSpouseSsn')\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"ssn\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                id=\"person-spouse-gender\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseGender\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseGender')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSpouseGender\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.requiredFields.personSpouseGender\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"gender\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-email\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseEmail\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseEmail')\"\r" +
    "\n" +
    "                ng-required=\"vm.isSpouseFieldRequired('personSpouseEmail')\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.isSpouseFieldRequired('personSpouseEmail')\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"person-spouse-cell-phone\"\r" +
    "\n" +
    "                aa-field-group=\"vm.person.spouseCellPhone\"\r" +
    "\n" +
    "                ng-change=\"vm.localChange('spouseCellPhone')\"\r" +
    "\n" +
    "                ng-required=\"vm.requiredFields.personSpousePhone\"\r" +
    "\n" +
    "                ng-if=\"!vm.hideOptionalFields || vm.requiredFields.personSpousePhone\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isDisabled\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/components/premiumDropdown/premiumDropdown.html',
    "<div>\r" +
    "\n" +
    "    <select\r" +
    "\n" +
    "        aa-fg-class={{vm.dropdownClass}}\r" +
    "\n" +
    "        aa-field-group=\"vm.selectedPremiumOption\"\r" +
    "\n" +
    "        ng-change=\"vm.premiumOptionChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "        aa-field-group-strategy=\"floatSelect\"\r" +
    "\n" +
    "        options=\"vm.premiumOptions\"\r" +
    "\n" +
    "        required>\r" +
    "\n" +
    "    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"{{vm.otherClass}}\"\r" +
    "\n" +
    "        aa-field-group=\"vm.otherPremium\"\r" +
    "\n" +
    "        aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "        ng-change=\"vm.otherPremiumChanged()\"\r" +
    "\n" +
    "        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "        ng-show=\"vm.selectedPremiumOption === 'other'\"\r" +
    "\n" +
    "        ng-required=\"vm.selectedPremiumOption === 'other'\" />\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/queryBuilder/queryBuilderCondition.html',
    "<div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"!vm.propertyOptionsAreGroups\">\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            aa-fg-class=\"small v-center property-select-field\"\r" +
    "\n" +
    "            aa-field-group=\"vm.queryCondition.property\"\r" +
    "\n" +
    "            ng-change=\"vm.propertyChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"none\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "            options=\"vm.propertyOptions\"\r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.propertyOptionsAreGroups\">\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            aa-fg-class=\"small v-center property-select-field\"\r" +
    "\n" +
    "            aa-field-group=\"vm.queryCondition.property\"\r" +
    "\n" +
    "            ng-change=\"vm.propertyChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"none\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"groupSelect\"\r" +
    "\n" +
    "            groups=\"vm.propertyOptionGroups\"\r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <select\r" +
    "\n" +
    "        aa-fg-class=\"small v-center left-margin operator-select-field\"\r" +
    "\n" +
    "        aa-field-group=\"vm.queryCondition.operator\"\r" +
    "\n" +
    "        ng-change=\"vm.operatorChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"none\"\r" +
    "\n" +
    "        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "        options=\"vm.operatorOptions\"\r" +
    "\n" +
    "        required>\r" +
    "\n" +
    "    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"[undefined, 'NotBlank', 'Blank', 'IsTrue', 'NotTrue', 'IsMe', 'IsNotMe'].indexOf(vm.queryCondition.operator) == -1\" \r" +
    "\n" +
    "        class=\"v-center fill flex left-margin\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Money'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Decimal'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"float\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Percent'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Integer'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Date'\" >\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'String' || vm.queryCondition.propertyType == 'StringExists'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\" \r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Phone' || vm.queryCondition.propertyType == 'PhoneExists'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'Producer'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-producer=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'ClientServiceExecutive'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-executive=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'ClientServiceManager'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-manager=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'ClientServiceAssociate'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-agent=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'BillToType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'CommissionCalculationType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"commissionCalculationType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'BrokerCategory'\">\r" +
    "\n" +
    "            <broker-category-dropdown\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                ng-required=\"true\">\r" +
    "\n" +
    "            </broker-category-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'BondAccountStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"bondAccountStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'ApplicationStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"applicationStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'BondStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"bondStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'RenewalStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"renewalStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'CancellationStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"cancellationStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'ReinstatementStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"reinstatementStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'RiderStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"riderStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'OriginSystem'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"originSystem\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'TransactionType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"transactionType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'BillingEntryType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"billingEntryType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.queryCondition.propertyType == 'State'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[0]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"vm.hasMultipleFields\" \r" +
    "\n" +
    "            class=\"left-margin right-margin v-center small text-faint\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            and\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Money'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Decimal'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"float\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Percent'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Integer'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Date'\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && (vm.queryCondition.propertyType == 'String' || vm.queryCondition.propertyType == 'StringExists')\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && (vm.queryCondition.propertyType == 'Phone' || vm.queryCondition.propertyType == 'PhoneExists')\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'Producer'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-producer=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'ClientServiceExecutive'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-executive=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'ClientServiceManager'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-manager=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'ClientServiceAssociate'\">\r" +
    "\n" +
    "            <user-dropdown\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                is-client-service-agent=\"true\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                allow-unselected=\"false\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\">\r" +
    "\n" +
    "            </user-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'BillToType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'CommissionCalculationType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"commissionCalculationType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'BrokerCategory'\">\r" +
    "\n" +
    "            <broker-category-dropdown\r" +
    "\n" +
    "                class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                ng-model=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                ng-required=\"true\">\r" +
    "\n" +
    "            </broker-category-dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'BondAccountStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"bondAccountStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'ApplicationStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"applicationStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'BondStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"bondStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'RenewalStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"renewalStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'CancellationStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"cancellationStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'ReinstatementStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"reinstatementStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'RiderStatus'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"riderStatus\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'OriginSystem'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"originSystem\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'State'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'TransactionType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"transactionType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-if=\"vm.hasMultipleFields && vm.queryCondition.propertyType == 'BillingEntryType'\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center small fill value-input-field\"\r" +
    "\n" +
    "                aa-field-group=\"vm.queryCondition.values[1]\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"billingEntryType\"\r" +
    "\n" +
    "                ng-change=\"vm.valueChanged()\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center small left-margin\"\r" +
    "\n" +
    "        ng-click=\"vm.remove($event)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/queryBuilder/queryBuilderConditionSet.html',
    "<div class=\"query-builder-condition-set\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.isChild\" \r" +
    "\n" +
    "        class=\"query-builder-condition-set-pointer\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"btn-group\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setLogicType('And', $event)\" \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.conditionSet.logicType == 'And', 'inactive' : vm.conditionSet.logicType != 'And'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                And\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setLogicType('Or', $event)\"\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.conditionSet.logicType == 'Or', 'inactive' : vm.conditionSet.logicType != 'Or'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Or\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            ng-show=\"vm.isChild\"\r" +
    "\n" +
    "            ng-click=\"vm.removeConditionSetClicked($event)\"\r" +
    "\n" +
    "            class=\"small left-margin v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "            Remove\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"query-builder-condition-container\">\r" +
    "\n" +
    "        <div class=\"query-builder-left-line\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"fill\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"query-builder-condition\"\r" +
    "\n" +
    "                ng-repeat=\"condition in vm.conditionSet.conditions\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"query-builder-condition-pointer\"></div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <query-builder-condition\r" +
    "\n" +
    "                    property-option-groups=\"vm.propertyOptionGroups\"\r" +
    "\n" +
    "                    query-condition=\"condition\"\r" +
    "\n" +
    "                    on-removed=\"vm.conditionRemoved($index)\"\r" +
    "\n" +
    "                    on-changed=\"vm.queryConditionChanged()\">\r" +
    "\n" +
    "                </query-builder-condition>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <query-builder-condition-set\r" +
    "\n" +
    "                ng-repeat=\"conditionSet in vm.conditionSet.conditionSets\"\r" +
    "\n" +
    "                is-child=\"true\"\r" +
    "\n" +
    "                property-option-groups=\"vm.propertyOptionGroups\"\r" +
    "\n" +
    "                condition-set=\"conditionSet\"\r" +
    "\n" +
    "                on-removed=\"vm.childConditionSetRemoved($index)\"\r" +
    "\n" +
    "                on-changed=\"vm.childConditionSetChanged()\">\r" +
    "\n" +
    "            </query-builder-condition-set>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"query-builder-add-buttons small\">\r" +
    "\n" +
    "                <div class=\"query-builder-condition-pointer\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    class=\"v-center left-margin\"\r" +
    "\n" +
    "                    ng-click=\"vm.addCondition($event)\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "                    Add Filter\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    class=\"v-center left-margin-big\"\r" +
    "\n" +
    "                    ng-click=\"vm.addConditionSet($event)\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-stream\"></i>\r" +
    "\n" +
    "                    Add Group\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/quoteList/quoteList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"flex bottom-margin\">\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"v-center flex fill\" \r" +
    "\n" +
    "        ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.showBestQuotes\" \r" +
    "\n" +
    "            aa-label=\"Show only best\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-fg-class=\"v-center\" \r" +
    "\n" +
    "            aa-label-class=\"small\"\r" +
    "\n" +
    "            ng-change=\"vm.performFilter()\" /> \r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <help \r" +
    "\n" +
    "            class=\"left-margin v-center\" \r" +
    "\n" +
    "            message=\"Shows only the lowest premium quotes for each carrier and term length.\">\r" +
    "\n" +
    "        </help>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.showEmailButton\" \r" +
    "\n" +
    "        class=\"flex v-center\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"mini v-center\"\r" +
    "\n" +
    "            ng-click=\"vm.showEmailModal()\"\r" +
    "\n" +
    "            ng-class=\"{'disabled': vm.disableEmailQuotesButton}\"\r" +
    "\n" +
    "            uib-tooltip=\"{{vm.emailQuotesToolTip}}\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Email Quotes\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i \r" +
    "\n" +
    "                    ng-show=\"vm.disableEmailQuotesButton\"\r" +
    "\n" +
    "                    class=\"fa fa-info-circle small\" >\r" +
    "\n" +
    "                </i>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        ng-show=\"vm.showAddButton && !vm.isApplicationDeclined\" \r" +
    "\n" +
    "        class=\"mini left-margin v-center\" \r" +
    "\n" +
    "        ng-click=\"vm.showQuoteModal()\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        + Add Quote\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-show=\"vm.showAutoQuoteButton\"\r" +
    "\n" +
    "        ng-click=\"vm.autoQuote()\"\r" +
    "\n" +
    "        class=\"mini v-center left-margin\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Auto-Quote\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table ng-show=\"vm.quotes.length > 0\">\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "    <tr>\r" +
    "\n" +
    "        <th ng-show=\"!vm.isCarrier\"></th>\r" +
    "\n" +
    "        <th>Status</th>\r" +
    "\n" +
    "        <th>Term</th>\r" +
    "\n" +
    "        <th>Indemnity</th>\r" +
    "\n" +
    "        <th>Premium</th>\r" +
    "\n" +
    "        <th ng-show=\"!vm.isCarrier\">Fees</th>\r" +
    "\n" +
    "        <th ng-show=\"!vm.isCarrier\">Total Due</th>\r" +
    "\n" +
    "        <th>Commission</th>\r" +
    "\n" +
    "        <th ng-show=\"vm.isCarrier\">Rate Type</th>\r" +
    "\n" +
    "        <th ng-show=\"vm.isCarrier\">Writing Company</th>\r" +
    "\n" +
    "        <th></th>\r" +
    "\n" +
    "    </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody>\r" +
    "\n" +
    "    <tr ng-repeat=\"quote in vm.filteredQuotes track by $index\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <td ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            <system-account-logo\r" +
    "\n" +
    "                system-account-id=\"quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                company-name=\"quote.carrierName\"\r" +
    "\n" +
    "                writing-company-name=\"quote.writingCompanyName\"\r" +
    "\n" +
    "                show-labels=\"true\">\r" +
    "\n" +
    "            </system-account-logo>\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td class=\"nowrap\">\r" +
    "\n" +
    "            <span ng-class=\"{\r" +
    "\n" +
    "                  'green' : quote.status == 'Approved', \r" +
    "\n" +
    "                  'red' : quote.status == 'Declined'\r" +
    "\n" +
    "                }\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{quote.status}}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td>{{quote.term}} yrs</td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td>{{quote.indemnityRequirements}}</td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td>{{quote.totalPremium | currency: '$'}}</td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            {{quote.totalFees | currency: '$'}}\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            {{quote.totalAmountDue | currency: '$'}}\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td>\r" +
    "\n" +
    "            {{quote.commission | currency: '$'}}\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <td ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "            {{quote.rateType}}\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <td ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "            <system-account-logo\r" +
    "\n" +
    "                system-account-id=\"quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                company-name=\"quote.carrierName\"\r" +
    "\n" +
    "                writing-company-name=\"quote.writingCompanyName\"\r" +
    "\n" +
    "                show-labels=\"true\">\r" +
    "\n" +
    "            </system-account-logo>\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "        <td class=\"text-right nowrap\">\r" +
    "\n" +
    "            <span ng-show=\"!vm.isApplicationDeclined\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.downloadQuoteDocument(quote.id)\" \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                </button><!--\r" +
    "\n" +
    "                --><button\r" +
    "\n" +
    "                    ng-click=\"vm.showIndemnityModal(quote)\" \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Indemnity\r" +
    "\n" +
    "                </button><!--\r" +
    "\n" +
    "                --><button ng-click=\"vm.showQuoteModal(quote, $index, vm.isDisabled)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{vm.isDisabled ? 'View' : 'Edit'}}\r" +
    "\n" +
    "                </button><!--\r" +
    "\n" +
    "                --><button\r" +
    "\n" +
    "                    ng-click=\"vm.showDeleteConfirmation(quote, $index)\"    \r" +
    "\n" +
    "                    ng-disabled=\"(!vm.isCarrier && !quote.canEdit) || vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Delete\r" +
    "\n" +
    "                </button><!--\r" +
    "\n" +
    "                --><button\r" +
    "\n" +
    "                    ng-click=\"vm.showPurchaseModal(quote.id)\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.isPurchaseDisabled(quote)\"\r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Purchase\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </td>\r" +
    "\n" +
    "    </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state ng-show=\"vm.quotes.length === 0\"\r" +
    "\n" +
    "        message=\"There are no quotes on this application.\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/components/rateForm/rateForm.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-form=\"mainForm\">\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                <i class=\"fa fa-dollar-sign\"></i>\r" +
    "\n" +
    "                Rate Configuration\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"rate-detail-name-input\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.rate.name\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.rate.rateType\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.rateTypeOptions\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea\r" +
    "\n" +
    "                    aa-field-group=\"vm.rate.comments\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.rate.ratePerThousand\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                    ng-required=\"vm.rate.rateType=='RatePerThousand'\"\r" +
    "\n" +
    "                    ng-show=\"vm.rate.rateType === 'RatePerThousand'\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.rate.fixedRate\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                    ng-required=\"vm.rate.rateType=='Fixed'\"\r" +
    "\n" +
    "                    ng-show=\"vm.rate.rateType == 'Fixed'\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-sm-12\">\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"row\"\r" +
    "\n" +
    "                        ng-show=\"vm.rate.rateType === 'Tiered'\">\r" +
    "\n" +
    "                        <div class=\"col-sm-6\">\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.newThreshold\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                aa-label=\"Threshold\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                class=\"red\"\r" +
    "\n" +
    "                                ng-show=\"!vm.newThreshold && vm.addingRateTier\">\r" +
    "\n" +
    "                                Tier threshold is required\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-6\">\r" +
    "\n" +
    "                            <div class=\"field-flex-box\">\r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.newTierRate\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                    aa-label=\"Rate per Thousand\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <button ng-click=\"vm.addNewTierRate(rate)\"\r" +
    "\n" +
    "                                    >Add</button\r" +
    "\n" +
    "                                >\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                class=\"red\"\r" +
    "\n" +
    "                                ng-show=\"!vm.newTierRate && vm.addingRateTier\">\r" +
    "\n" +
    "                                Tier threshold is required\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table ng-show=\"vm.rate.rateType === 'Tiered'\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th>Threshold</th>\r" +
    "\n" +
    "                                <th>Rate per Thousand</th>\r" +
    "\n" +
    "                                <th>&nbsp;</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-repeat=\"tier in vm.rate.rateTiers\">\r" +
    "\n" +
    "                                <td>\r" +
    "\n" +
    "                                    <span ng-show=\"$index == 0\"></span>\r" +
    "\n" +
    "                                    <span ng-show=\"$index > 0\">\r" +
    "\n" +
    "                                        {{vm.rate.rateTiers[$index -\r" +
    "\n" +
    "                                        1].thresholdAmount | currency:\"$\"}} -\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                    {{tier.thresholdAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td>\r" +
    "\n" +
    "                                    {{tier.ratePerThousand | currency:\"$\"}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"text-right\">\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        ng-click=\"vm.removeTierRate($index)\">\r" +
    "\n" +
    "                                        Remove\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr\r" +
    "\n" +
    "                                class=\"noRecords\"\r" +
    "\n" +
    "                                ng-show=\"!vm.rate.rateTiers || vm.rate.rateTiers.length == 0\">\r" +
    "\n" +
    "                                <td colspan=\"3\">\r" +
    "\n" +
    "                                    <span>No Tiers Assigned</span>\r" +
    "\n" +
    "                                    <p class=\"red\">\r" +
    "\n" +
    "                                        You must have at least one tier for\r" +
    "\n" +
    "                                        tiered rates\r" +
    "\n" +
    "                                    </p>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/recordCounter/recordCounter.html',
    "<span \r" +
    "\n" +
    "    ng-show=\"vm.recordCount > 0\"\r" +
    "\n" +
    "    class=\"{{vm.class}}\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        {{vm.recordCount | number }} record<!--\r" +
    "\n" +
    "        --><span ng-show=\"vm.recordCount > 1\">s</span>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/relatedCustomerCard/relatedCustomerCard.html',
    "<div class=\"small\">\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <b class=\"small red fill\">\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.isParentCompany\">\r" +
    "\n" +
    "                Has Subsidiaries\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.parentCompanyId\">\r" +
    "\n" +
    "                Has Parent Company\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.isEmployer\">\r" +
    "\n" +
    "                Has Employee Customers\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span ng-show=\"vm.customer.employerCustomerId\">\r" +
    "\n" +
    "                Has Employer Customer\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"left-margin-big small\" \r" +
    "\n" +
    "            ng-click=\"vm.showCustomerRelationshipDetails()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Show Details\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <!-- Parent Company -->\r" +
    "\n" +
    "        <div ng-show=\"vm.customer.isParentCompany\">\r" +
    "\n" +
    "            <b>Is Parent Company</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.subsidiaryCompanyCount}} Subsidiaries\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.customer.parentCompanyId\">\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                target=\"{{'customerDetail/' + vm.customer.parentCompanyId}}\" \r" +
    "\n" +
    "                ui-sref=\"main.customerDetail({ customerId: vm.customer.parentCompanyId, sectionView: '' })\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.parentCompanyName}}\r" +
    "\n" +
    "                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"vm.customer.parentCompanyLookupCode\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.parentCompanyLookupCode}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div ng-show=\"vm.customer.parentCompanyComments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{vm.customer.parentCompanyComments}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{vm.customer.sisterCompanyCount}} Sister Companies\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <!-- Employer -->\r" +
    "\n" +
    "        <div ng-show=\"vm.customer.isEmployer\">\r" +
    "\n" +
    "            <b>Is Employer</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"small\">\r" +
    "\n" +
    "                {{vm.customer.employeeCustomerCount}} Employee Customers\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.customer.employerCustomerId\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                target=\"{{'customerDetail/' + vm.customer.employerCustomerId}}\"\r" +
    "\n" +
    "                ui-sref=\"main.customerDetail({ customerId: vm.customer.employerCustomerId, sectionView: '' })\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                {{vm.customer.employerCustomerName}}\r" +
    "\n" +
    "                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.customer.employerCustomerLookupCode\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.employerCustomerLookupCode}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div ng-show=\"vm.customer.employerCustomerComments\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.employerCustomerComments}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.customer.relatedEmployeeCustomerCount}} Sibling Employees\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/searchDropdown/searchDropdown.html',
    "\r" +
    "\n" +
    "<ui-select\r" +
    "\n" +
    "    ng-model=\"vm.searchControl.selected\"\r" +
    "\n" +
    "    on-select=\"vm.onSelect($item, $model)\"\r" +
    "\n" +
    "    ng-init=\"$select.label = vm.searchControl.label\"\r" +
    "\n" +
    "    ng-click=\"vm.clicked($select.search)\"\r" +
    "\n" +
    "    reset-search-input=\"true\"\r" +
    "\n" +
    "    name=\"{{vm.searchControl.name}}\"\r" +
    "\n" +
    "    ng-required=\"vm.searchControl.isRequired\"\r" +
    "\n" +
    "    ng-disabled=\"vm.searchControl.isDisabled\"\r" +
    "\n" +
    "    theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-match\r" +
    "\n" +
    "        allow-clear=\"{{vm.searchControl.allowSelectionClearing}}\"\r" +
    "\n" +
    "        placeholder=\"{{vm.searchControl.placeholder}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{vm.searchControl.buildSelectionLabel()}}\r" +
    "\n" +
    "    </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-choices\r" +
    "\n" +
    "        repeat=\"match in vm.searchControl.matches track by $index\"\r" +
    "\n" +
    "        refresh=\"vm.search($select.search)\"\r" +
    "\n" +
    "        refresh-delay=\"{{vm.searchControl.debounce}}\"\r" +
    "\n" +
    "        ui-disable-choice=\"match.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <user-avatar\r" +
    "\n" +
    "                ng-if=\"vm.showUserAvatar\"\r" +
    "\n" +
    "                user-id=\"match.id\"\r" +
    "\n" +
    "                class=\"mini v-center right-margin-thin\">\r" +
    "\n" +
    "            </user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center fill\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{vm.searchControl.buildOptionLabel(match)}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </ui-select-choices>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-no-choice>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        {{vm.searchControl.emptyStateMessage}}\r" +
    "\n" +
    "    </ui-select-no-choice>\r" +
    "\n" +
    "</ui-select>"
  );


  $templateCache.put('app/components/sfaaCodeList/sfaaCodeList.html',
    "<div>\r" +
    "\n" +
    "    <div class=\"field-flex-box\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ui-select \r" +
    "\n" +
    "            ng-model=\"vm.sfaaCodeSearch.selected\"\r" +
    "\n" +
    "            ng-init=\"vm.controlInit($select)\" \r" +
    "\n" +
    "            reset-search-input=\"false\" \r" +
    "\n" +
    "            name=\"sfaaCodeSearch\" \r" +
    "\n" +
    "            on-select=\"vm.addSfaaCode()\"\r" +
    "\n" +
    "            theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ui-select-match\r" +
    "\n" +
    "                placeholder=\"Search for SFAA class codes...\"\r" +
    "\n" +
    "                label=\"Search Sfaa Codes\">\r" +
    "\n" +
    "                    {{$select.selected.code}} - {{$select.selected.description | limitTo:50}}\r" +
    "\n" +
    "                    <span ng-show=\"$select.selected.description.length > 50\">...</span>\r" +
    "\n" +
    "            </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ui-select-choices\r" +
    "\n" +
    "                repeat=\"sfaaCode in vm.sfaaCodeSearch track by $index\"\r" +
    "\n" +
    "                refresh=\"vm.searchSfaaCodes($select.search)\"\r" +
    "\n" +
    "                refresh-delay=\"0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-bind-html=\"sfaaCode.code | highlight: $select.search\"></span>\r" +
    "\n" +
    "                <span> - </span>\r" +
    "\n" +
    "                <span ng-bind-html=\"sfaaCode.description | highlight: $select.search | limitTo:50\"></span>\r" +
    "\n" +
    "                <span ng-show=\"sfaaCode.description.length > 50\">...</span>\r" +
    "\n" +
    "            </ui-select-choices>\r" +
    "\n" +
    "        </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"col-sm-1\"\r" +
    "\n" +
    "            ng-click=\"vm.addSfaaCode()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Add\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table ng-show=\"vm.sfaaCodes.length\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>Assigned SFAA Classes</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody>\r" +
    "\n" +
    "            <tr \r" +
    "\n" +
    "                ng-repeat=\"sfaaCode in vm.sfaaCodes\" \r" +
    "\n" +
    "                ng-class=\"{'red': sfaaCode.$assigned}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{sfaaCode.code}} - {{sfaaCode.description | limitTo:50}}\r" +
    "\n" +
    "                    <span ng-show=\"sfaaCode.description.length > 50\">...</span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.removeSfaaCode($index)\">\r" +
    "\n" +
    "                        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                        <span>Remove</span>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"!vm.sfaaCodes.length\"\r" +
    "\n" +
    "        message=\"{{vm.noEntriesText}}\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/sfaaCodeSelector/sfaaCodeSelection.html',
    "<div class=\"field-flex-box\">\r" +
    "\n" +
    "    <ui-select \r" +
    "\n" +
    "        ng-model=\"vm.sfaaCodeSearch.selected\" \r" +
    "\n" +
    "        ng-init=\"vm.controlInit($select)\" \r" +
    "\n" +
    "        reset-search-input=\"false\" \r" +
    "\n" +
    "        name=\"sfaaCodeSearch\" \r" +
    "\n" +
    "        on-select=\"vm.selectSfaaCode($item)\"\r" +
    "\n" +
    "        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ui-select-match \r" +
    "\n" +
    "            placeholder=\"Search for SFAA class codes...\" \r" +
    "\n" +
    "            label=\"Search Sfaa Codes\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{$select.selected.code}} - {{$select.selected.description | limitTo:50}}\r" +
    "\n" +
    "            <span ng-show=\"$select.selected.description.length > 50\">\r" +
    "\n" +
    "                ...\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </ui-select-match>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ui-select-choices \r" +
    "\n" +
    "            class=\"pull-left text-left\"\r" +
    "\n" +
    "            repeat=\"sfaaCode in vm.sfaaCodeSearch track by $index\" \r" +
    "\n" +
    "            refresh=\"vm.searchSfaaCodes($select.search)\" \r" +
    "\n" +
    "            refresh-delay=\"0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-bind-html=\"sfaaCode.code\"></span>\r" +
    "\n" +
    "             - \r" +
    "\n" +
    "            <span ng-bind-html=\"sfaaCode.description\"></span>\r" +
    "\n" +
    "            <span ng-show=\"vm.sfaaCode.description.length > 50\">\r" +
    "\n" +
    "                ...\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </ui-select-choices>\r" +
    "\n" +
    "    </ui-select>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/showActivityModalButton/showActivityModalButton.html',
    "<button\r" +
    "\n" +
    "    class=\"{{vm.buttonClass}}\"\r" +
    "\n" +
    "    ng-click=\"vm.showActivityModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        ng-if=\"vm.activityId\"\r" +
    "\n" +
    "        class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        ng-if=\"!vm.activityId\"\r" +
    "\n" +
    "        class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "</button>"
  );


  $templateCache.put('app/components/sortBy/sortBy.html',
    "<button ng-click=\"vm.onClick()\">\r" +
    "\n" +
    "    <span ng-transclude></span>\r" +
    "\n" +
    "    <i ng-class=\"currentSort == sortBy ? 'fa fa-sort-down' : (currentSort == sortBy + ' desc' ? 'fa fa-sort-up' : 'fa fa-sort')\" \r" +
    "\n" +
    "        class=\"fa fa-sort\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "</button>\r" +
    "\n"
  );


  $templateCache.put('app/components/systemAccountLogo/systemAccountLogo.html',
    "<div class=\"flex\">\r" +
    "\n" +
    "    <img\r" +
    "\n" +
    "        class=\"v-center system-account-logo\"\r" +
    "\n" +
    "        ng-src=\"{{vm.url}}\" \r" +
    "\n" +
    "        tooltip=\"{{vm.companyName}}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.showLabels == 'true'\" \r" +
    "\n" +
    "        class=\"nowrap small v-center left-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.companyName\">\r" +
    "\n" +
    "            {{vm.companyName}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div ng-show=\"vm.writingCompanyName\">\r" +
    "\n" +
    "            {{vm.writingCompanyName}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/tablePager/tablePaginationFooter.html',
    "<div\r" +
    "\n" +
    "    id=\"{{vm.cleanedId}}\"\r" +
    "\n" +
    "    class=\"small list-pagination\"\r" +
    "\n" +
    "    ng-show=\"vm.tablePager.isVisible\">\r" +
    "\n" +
    "    <div class=\"col-xs-6\">\r" +
    "\n" +
    "        <div class=\"hidden-xs\">\r" +
    "\n" +
    "            <am-select\r" +
    "\n" +
    "                id=\"{{vm.id}}-records-per-page-dropdown\"\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                options=\"vm.tablePager.recordsPerPageOptions\"\r" +
    "\n" +
    "                selected-value=\"vm.tablePager.recordsPerPage\"\r" +
    "\n" +
    "                option-changed=\"vm.tablePager.setRecordPerPage(selectedOption)\">\r" +
    "\n" +
    "            </am-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            records per page\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden-sm hidden-md hidden-lg\">\r" +
    "\n" +
    "            Page {{ vm.tablePager.currentPage }} of {{\r" +
    "\n" +
    "            vm.tablePager.totalPages}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"col-xs-6\"\r" +
    "\n" +
    "        ng-show=\"vm.tablePager.isVisible\">\r" +
    "\n" +
    "        <div class=\"hidden-xs\">\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                id=\"{{vm.cleanedId}}-buttons\"\r" +
    "\n" +
    "                uib-pagination\r" +
    "\n" +
    "                total-items=\"vm.tablePager.totalRecordCount\"\r" +
    "\n" +
    "                max-size=\"7\"\r" +
    "\n" +
    "                items-per-page=\"vm.tablePager.recordsPerPage\"\r" +
    "\n" +
    "                ng-model=\"vm.tablePager.currentPage\"\r" +
    "\n" +
    "                ng-change=\"vm.tablePager.currentPageChanged()\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden-sm hidden-md hidden-lg\">\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                uib-pagination\r" +
    "\n" +
    "                total-items=\"vm.tablePager.totalRecordCount\"\r" +
    "\n" +
    "                max-size=\"0\"\r" +
    "\n" +
    "                items-per-page=\"vm.tablePager.recordsPerPage\"\r" +
    "\n" +
    "                ng-model=\"vm.tablePager.currentPage\"\r" +
    "\n" +
    "                ng-change=\"vm.tablePager.currentPageChanged()\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/tableSorter/tableSortButton.html',
    "<button\r" +
    "\n" +
    "    id=\"{{vm.cleanId}}\"\r" +
    "\n" +
    "    ng-show=\"vm.sortExpression\"\r" +
    "\n" +
    "    ng-click=\"vm.tableSorter.sort(vm.sortExpression)\">\r" +
    "\n" +
    "    {{vm.text}}\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "        'fa fa-caret-up' : vm.tableSorter.isSortedBy(vm.sortExpression),\r" +
    "\n" +
    "        'fa fa-caret-down': vm.tableSorter.isSortedByDesc(vm.sortExpression)\r" +
    "\n" +
    "    }\"></i>\r" +
    "\n" +
    "</button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<span ng-show=\"!vm.sortExpression\">\r" +
    "\n" +
    "    {{vm.text}}\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/tagContainer/tagContainer.html',
    "<div class=\"tag-container flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        class=\"tag v-center\"\r" +
    "\n" +
    "        ng-repeat=\"tag in vm.tags\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        {{tag.name}}\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            uib-tooltip=\"Remove tag\"\r" +
    "\n" +
    "            ng-click=\"vm.deleteTag(tag, $index)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"tagForm\"\r" +
    "\n" +
    "        ng-submit=\"vm.create()\"\r" +
    "\n" +
    "        class=\"tag-form v-center\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"mini\"\r" +
    "\n" +
    "            ng-click=\"vm.clickNew()\"\r" +
    "\n" +
    "            uib-tooltip=\"Add New Tag\"\r" +
    "\n" +
    "            ng-show=\"!vm.creatingNewTag\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "            <span ng-show=\"!vm.tags.length\">{{vm.tagType}}</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.newTagName\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "            focus-me=\"vm.creatingNewTag\"\r" +
    "\n" +
    "            ng-show=\"vm.creatingNewTag\"\r" +
    "\n" +
    "            list=\"datalistTagSelector\"\r" +
    "\n" +
    "            name=\"dlTagSelector\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <datalist \r" +
    "\n" +
    "            id=\"datalistTagSelector\" \r" +
    "\n" +
    "            focus-me=\"vm.creatingNewTag\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <option \r" +
    "\n" +
    "                ng-repeat=\"tagOption in vm.tagOptions\" \r" +
    "\n" +
    "                value={{tagOption.value}}>\r" +
    "\n" +
    "            </option>\r" +
    "\n" +
    "        </datalist>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            ng-click=\"vm.create()\"\r" +
    "\n" +
    "            class=\"mini\"\r" +
    "\n" +
    "            ui-tooltip=\"Add tag\"\r" +
    "\n" +
    "            ng-show=\"vm.creatingNewTag\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            class=\"mini\"\r" +
    "\n" +
    "            ng-click=\"vm.cancelCreate()\"\r" +
    "\n" +
    "            ui-tooltip=\"Cancel Tag\"\r" +
    "\n" +
    "            ng-show=\"vm.creatingNewTag\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/transactionTypeCodeDropdown/transactionTypeCodeDropdown.html',
    "<select\r" +
    "\n" +
    "    id=\"{{vm.id}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.code\"\r" +
    "\n" +
    "    ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{vm.aaLabelStrategy}}\"\r" +
    "\n" +
    "    aa-label=\"Code\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.aaFgClass}}\"\r" +
    "\n" +
    "    options=\"vm.options\"\r" +
    "\n" +
    "    ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "</select>"
  );


  $templateCache.put('app/components/uiSelect/a3/choices.tpl.html',
    "<ul \r" +
    "\n" +
    "    class=\"ui-select-choices ui-select-choices-content dropdown-menu\" \r" +
    "\n" +
    "    role=\"menu\" \r" +
    "\n" +
    "    aria-labelledby=\"dLabel\" \r" +
    "\n" +
    "    ng-show=\"$select.items.length > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <li class=\"ui-select-choices-group\">\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"divider\" \r" +
    "\n" +
    "            ng-show=\"$select.isGrouped && $index > 0\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"$select.isGrouped\" \r" +
    "\n" +
    "            class=\"ui-select-choices-group-label dropdown-header\" \r" +
    "\n" +
    "            ng-bind-html=\"$group.name\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"ui-select-choices-row\" \r" +
    "\n" +
    "            ng-class=\"{active: $select.isActive(this), disabled: $select.isDisabled(this)}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                href=\"javascript:void(0)\" \r" +
    "\n" +
    "                class=\"ui-select-choices-row-inner\">\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </li>\r" +
    "\n" +
    "</ul>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/a3/choices.tpl.min.html',
    "<ul class=\\\"ui-select-choices ui-select-choices-content uib-dropdown-menu\\\" role=\\\"menu\\\" aria-labelledby=\\\"dLabel\\\" ng-show=\\\"$select.items.length > 0\\\"><li class=\\\"ui-select-choices-group\\\"><div class=\\\"divider\\\" ng-show=\\\"$select.isGrouped &amp;&amp; $index > 0\\\"></div><div ng-show=\\\"$select.isGrouped\\\" class=\\\"ui-select-choices-group-label uib-dropdown-header\\\" ng-bind-html=\\\"$group.name\\\"></div><div class=\\\"ui-select-choices-row\\\" ng-class=\\\"{active: $select.isActive(this), disabled: $select.isDisabled(this)}\\\"><a href=\\\"javascript:void(0)\\\" class=\\\"ui-select-choices-row-inner\\\"></a></div></li></ul>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/a3/match-multiple.tpl.html',
    "<span class=\"ui-select-match\">\r" +
    "\n" +
    "  <span ng-repeat=\"$item in $select.selected\">\r" +
    "\n" +
    "    <span style=\"margin-right: 3px;\" \r" +
    "\n" +
    "      class=\"ui-select-match-item\" \r" +
    "\n" +
    "      tabindex=\"-1\" \r" +
    "\n" +
    "      type=\"button\" \r" +
    "\n" +
    "      ng-disabled=\"$select.disabled\" \r" +
    "\n" +
    "      ng-click=\"$select.activeMatchIndex = $index;\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span class=\"close\" \r" +
    "\n" +
    "          ng-hide=\"$select.disabled\" \r" +
    "\n" +
    "          ng-click=\"$select.removeChoice($index)\">\r" +
    "\n" +
    "          \r" +
    "\n" +
    "          <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span uis-transclude-append></span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/a3/match-multiple.tpl.min.html',
    "<span class=\"ui-select-match\">\r" +
    "\n" +
    "     <span ng-repeat=\"$item in $select.selected\">\r" +
    "\n" +
    "         <span style=\"margin-right:3px\" \r" +
    "\n" +
    "            class=\"ui-select-match-item\" \r" +
    "\n" +
    "            tabindex=\"-1\" \r" +
    "\n" +
    "            type=\"button\" \r" +
    "\n" +
    "            ng-disabled=\"$select.disabled\" \r" +
    "\n" +
    "            ng-click=\"$select.activeMatchIndex = $index;\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span class=\"close\" \r" +
    "\n" +
    "                ng-hide=\"$select.disabled\" \r" +
    "\n" +
    "                ng-click=\"$select.removeChoice($index)\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span uis-transclude-append>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "         </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/uiSelect/a3/match.tpl.html',
    "<div \r" +
    "\n" +
    "    class=\"ui-select-match\" \r" +
    "\n" +
    "    tabindex=\"-1\" \r" +
    "\n" +
    "    ng-hide=\"$select.open\" \r" +
    "\n" +
    "    ng-disabled=\"$select.disabled\" \r" +
    "\n" +
    "    ng-class=\"{'':$select.focus}\" \r" +
    "\n" +
    "    ng-click=\"$select.activate()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "        ng-show=\"$select.isEmpty()\" \r" +
    "\n" +
    "        class=\"text-muted\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        {{$select.placeholder}}\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "        ng-hide=\"$select.isEmpty()\"\r" +
    "\n" +
    "        ng-transclude>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "        class=\"ui-select-toggle\" \r" +
    "\n" +
    "        ng-click=\"$select.toggle($event)\">\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/a3/match.tpl.min.html',
    "<div class=\"ui-select-match\" \r" +
    "\n" +
    "    tabindex=\"-1\" \r" +
    "\n" +
    "    ng-hide=\"$select.open\" \r" +
    "\n" +
    "    ng-disabled=\"$select.disabled\" \r" +
    "\n" +
    "    ng-class=\"{'':$select.focus}\" \r" +
    "\n" +
    "    ng-click=\"$select.activate()\">\r" +
    "\n" +
    "    <span ng-show=\"$select.isEmpty()\" \r" +
    "\n" +
    "        class=\"text-muted\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        {{$select.placeholder}}\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "    <span ng-hide=\"$select.isEmpty()\" \r" +
    "\n" +
    "        ng-transclude>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "    <span class=\"ui-select-toggle\" \r" +
    "\n" +
    "        ng-click=\"$select.toggle($event)\">\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/uiSelect/a3/no-choice.tpl.html',
    "<div class=\"ui-select-no-choice selectize-dropdown\"\r" +
    "\n" +
    "    ng-show=\"$select.items.length == 0\">\r" +
    "\n" +
    "    <div class=\"selectize-dropdown-content\">\r" +
    "\n" +
    "        <div data-selectable=\"\" \r" +
    "\n" +
    "            ng-transclude>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/uiSelect/a3/select-multiple.tpl.html',
    "<div class=\"ui-select-multiple dropdown\" \r" +
    "\n" +
    "    ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "    <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "    <div class=\"ui-select-multiple-input-wrapper\">\r" +
    "\n" +
    "        <input type=\"text\"\r" +
    "\n" +
    "               autocomplete=\"off\"\r" +
    "\n" +
    "               autocorrect=\"off\"\r" +
    "\n" +
    "               autocapitalize=\"off\"\r" +
    "\n" +
    "               spellcheck=\"false\"\r" +
    "\n" +
    "               class=\"ui-select-search\"\r" +
    "\n" +
    "               placeholder=\"{{$select.placeholder}}\"\r" +
    "\n" +
    "               ng-disabled=\"$select.disabled\"\r" +
    "\n" +
    "               ng-hide=\"$select.disabled\"\r" +
    "\n" +
    "               ng-click=\"$select.activate()\"\r" +
    "\n" +
    "               ng-model=\"$select.search\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/uiSelect/a3/select-multiple.tpl.min.html',
    "<div class=\"ui-select-multiple uib-dropdown\" \r" +
    "\n" +
    "    ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "    <div class=\"ui-select-match\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"ui-select-multiple-input-wrapper\">\r" +
    "\n" +
    "            <input type=\"text\" \r" +
    "\n" +
    "                autocomplete=\"off\" \r" +
    "\n" +
    "                autocorrect=\"off\" \r" +
    "\n" +
    "                autocapitalize=\"off\" \r" +
    "\n" +
    "                spellcheck=\"false\" \r" +
    "\n" +
    "                class=\"ui-select-search\" \r" +
    "\n" +
    "                placeholder=\"{{$select.placeholder}}\" \r" +
    "\n" +
    "                ng-disabled=\"$select.disabled\" \r" +
    "\n" +
    "                ng-hide=\"$select.disabled\" \r" +
    "\n" +
    "                ng-click=\"$select.activate()\" \r" +
    "\n" +
    "                ng-model=\"$select.search\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/a3/select.tpl.html',
    "<div \r" +
    "\n" +
    "    class=\"field-wrapper height-25\"\r" +
    "\n" +
    "    ng-class=\"{'inline-label-strategy': $select.labelStrategy == 'inline'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <label \r" +
    "\n" +
    "        ng-show=\"$select.labelStrategy == 'inline'\"\r" +
    "\n" +
    "        class=\"nowrap right-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{$select.label}} \r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"$select.required\" \r" +
    "\n" +
    "            class=\"validation-indicator\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            *\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"ui-select-bootstrap dropdown ui-select-a3\" \r" +
    "\n" +
    "        ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"text\" \r" +
    "\n" +
    "            autocomplete=\"off\" \r" +
    "\n" +
    "            tabindex=\"-1\"\r" +
    "\n" +
    "            class=\" ui-select-search\"\r" +
    "\n" +
    "            placeholder=\"{{$select.placeholder}}\"\r" +
    "\n" +
    "            ng-model=\"$select.search\"\r" +
    "\n" +
    "            ng-show=\"$select.searchEnabled && $select.open\"\r" +
    "\n" +
    "            ng-disabled=\"$select.disabled\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            ng-show=\"!$select.isEmpty() && !$select.disabled && $select.allowClear && $select.labelStrategy == 'inline'\"\r" +
    "\n" +
    "            ng-click=\"$select.clear($event)\"\r" +
    "\n" +
    "            class=\"text-small link\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Clear\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i \r" +
    "\n" +
    "            ng-show=\"$select.labelStrategy == 'inline'\"\r" +
    "\n" +
    "            class=\"fa fa-caret-down\">\r" +
    "\n" +
    "        </i>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!$select.labelStrategy || $select.labelStrategy == 'default'\" \r" +
    "\n" +
    "        class=\"label text-left flex width-100p\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <span class=\"fill\">\r" +
    "\n" +
    "            {{$select.label}} \r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i \r" +
    "\n" +
    "                ng-show=\"$select.label\"\r" +
    "\n" +
    "                class=\"fa fa-caret-down\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                ng-show=\"$select.required\"\r" +
    "\n" +
    "                class=\"validation-indicator\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                *\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            ng-show=\"!$select.isEmpty() && !$select.disabled && $select.allowClear\"\r" +
    "\n" +
    "            ng-click=\"$select.clear($event)\"\r" +
    "\n" +
    "            class=\"text-small link\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Clear\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"validation-errors\" \r" +
    "\n" +
    "        aa-val-msg-for=\"{{$select.aaValMsgFor}}\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"validation-error\" \r" +
    "\n" +
    "            ng-show=\"showMessages\" \r" +
    "\n" +
    "            ng-repeat=\"msg in errorMessages\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{msg}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"notch notch-border\"></div>\r" +
    "\n" +
    "        <div class=\"notch\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/uiSelect/a3/select.tpl.min.html',
    "<div class=\"field-wrapper\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"ui-select-bootstrap uib-dropdown\" \r" +
    "\n" +
    "        ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"ui-select-match\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input type=\"text\" \r" +
    "\n" +
    "            autocomplete=\"off\" \r" +
    "\n" +
    "            tabindex=\"-1\" \r" +
    "\n" +
    "            class=\"ui-select-search\" \r" +
    "\n" +
    "            placeholder=\"{{$select.placeholder}}\" \r" +
    "\n" +
    "            ng-model=\"$select.search\" \r" +
    "\n" +
    "            ng-show=\"$select.searchEnabled &amp;&amp; $select.open\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"ui-select-choices\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"label\">\r" +
    "\n" +
    "        {{$select.label}}\r" +
    "\n" +
    "        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "        <span class=\"validation-indicator\">*</span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/bootstrap/choices.tpl.html',
    "<ul class=\"ui-select-choices ui-select-choices-content uib-dropdown-menu\"\r" +
    "\n" +
    "    role=\"menu\" aria-labelledby=\"dLabel\"\r" +
    "\n" +
    "    ng-show=\"$select.items.length > 0\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "  <li class=\"ui-select-choices-group\">\r" +
    "\n" +
    "    <div class=\"divider\" \r" +
    "\n" +
    "      ng-show=\"$select.isGrouped && $index > 0\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div ng-show=\"$select.isGrouped\" \r" +
    "\n" +
    "      class=\"ui-select-choices-group-label uib-dropdown-header\" \r" +
    "\n" +
    "      ng-bind-html=\"$group.name\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"ui-select-choices-row\" \r" +
    "\n" +
    "      ng-class=\"{active: $select.isActive(this), disabled: $select.isDisabled(this)}\">\r" +
    "\n" +
    "      <a href=\"javascript:void(0)\" \r" +
    "\n" +
    "        class=\"ui-select-choices-row-inner\">\r" +
    "\n" +
    "      </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "  </li>\r" +
    "\n" +
    "</ul>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/bootstrap/match-multiple.tpl.html',
    "<span class=\"ui-select-match\">\r" +
    "\n" +
    "  <span ng-repeat=\"$item in $select.selected\">\r" +
    "\n" +
    "    <span \r" +
    "\n" +
    "      style=\"margin-right: 3px;\"\r" +
    "\n" +
    "      class=\"ui-select-match-item btn btn-default btn-xs\"\r" +
    "\n" +
    "      tabindex=\"-1\"\r" +
    "\n" +
    "      type=\"button\"\r" +
    "\n" +
    "      ng-disabled=\"$select.disabled\"\r" +
    "\n" +
    "      ng-click=\"$select.activeMatchIndex = $index;\"\r" +
    "\n" +
    "      ng-class=\"{'btn-primary':$select.activeMatchIndex === $index}\">\r" +
    "\n" +
    "        <span class=\"close ui-select-match-close\" \r" +
    "\n" +
    "          ng-hide=\"$select.disabled\" \r" +
    "\n" +
    "          ng-click=\"$select.removeChoice($index)\">\r" +
    "\n" +
    "          &nbsp;&times;\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span uis-transclude-append></span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "</span>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/bootstrap/match.tpl.html',
    "<button type=\"button\" \r" +
    "\n" +
    "  class=\"btn btn-default ui-select-match\" \r" +
    "\n" +
    "  tabindex=\"-1\"\r" +
    "\n" +
    "  ng-hide=\"$select.open\"\r" +
    "\n" +
    "  ng-disabled=\"$select.disabled\"\r" +
    "\n" +
    "  ng-class=\"{'btn-default-focus':$select.focus}\";\r" +
    "\n" +
    "  ng-click=\"$select.activate()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <span ng-show=\"$select.isEmpty()\"\r" +
    "\n" +
    "    class=\"text-muted\">\r" +
    "\n" +
    "      {{$select.placeholder}}\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "  <span ng-hide=\"$select.isEmpty()\" \r" +
    "\n" +
    "    ng-transclude>\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "  <span class=\"caret ui-select-toggle\" \r" +
    "\n" +
    "    ng-click=\"$select.toggle($event)\">\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "</button>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/bootstrap/select-multiple.tpl.html',
    "<div class=\"ui-select-multiple ui-select-bootstrap uib-dropdown\" ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "  <div>\r" +
    "\n" +
    "    <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "    <input type=\"text\"\r" +
    "\n" +
    "           autocomplete=\"off\" \r" +
    "\n" +
    "           autocorrect=\"off\" \r" +
    "\n" +
    "           autocapitalize=\"off\" \r" +
    "\n" +
    "           spellcheck=\"false\" \r" +
    "\n" +
    "           class=\"ui-select-search input-xs\"\r" +
    "\n" +
    "           placeholder=\"{{$select.getPlaceholder()}}\"\r" +
    "\n" +
    "           ng-disabled=\"$select.disabled\"\r" +
    "\n" +
    "           ng-hide=\"$select.disabled\"\r" +
    "\n" +
    "           ng-click=\"$select.activate()\"\r" +
    "\n" +
    "           ng-model=\"$select.search\">\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "  <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/bootstrap/select.tpl.html',
    "<div class=\"ui-select-bootstrap uib-dropdown\" ng-class=\"{open: $select.open}\">\r" +
    "\n" +
    "  <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "  <input type=\"text\" \r" +
    "\n" +
    "    autocomplete=\"off\"  \r" +
    "\n" +
    "    tabindex=\"-1\"\r" +
    "\n" +
    "    class=\" ui-select-search\"\r" +
    "\n" +
    "    placeholder=\"{{$select.placeholder}}\"\r" +
    "\n" +
    "    ng-model=\"$select.search\"\r" +
    "\n" +
    "    ng-show=\"$select.searchEnabled && $select.open\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/select2/choices.tpl.html',
    "<ul class=\"ui-select-choices ui-select-choices-content select2-results\">\r" +
    "\n" +
    "  <li class=\"ui-select-choices-group\" \r" +
    "\n" +
    "    ng-class=\"{'select2-result-with-children': $select.isGrouped}\">\r" +
    "\n" +
    "    <div ng-show=\"$select.isGrouped\" \r" +
    "\n" +
    "      class=\"ui-select-choices-group-label select2-result-label\" \r" +
    "\n" +
    "      ng-bind-html=\"$group.name\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <ul ng-class=\"{'select2-result-sub': $select.isGrouped, 'select2-result-single': !$select.isGrouped}\">\r" +
    "\n" +
    "      <li class=\"ui-select-choices-row\" \r" +
    "\n" +
    "        ng-class=\"{'select2-highlighted': $select.isActive(this), 'select2-disabled': $select.isDisabled(this)}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"select2-result-label ui-select-choices-row-inner\"></div>\r" +
    "\n" +
    "      </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "  </li>\r" +
    "\n" +
    "</ul>"
  );


  $templateCache.put('app/components/uiSelect/select2/match-multiple.tpl.html',
    "<!--\r" +
    "\n" +
    "  select2-choice needs to be before ui-select-multiple\r" +
    "\n" +
    "  otherwise CSS rules from https://github.com/fk/select2-bootstrap-css\r" +
    "\n" +
    "  do not work: [class^=\"select2-choice\"]\r" +
    "\n" +
    "-->\r" +
    "\n" +
    "<span class=\"ui-select-match\">\r" +
    "\n" +
    "  <li class=\"ui-select-match-item select2-search-choice\" \r" +
    "\n" +
    "    ng-repeat=\"$item in $select.selected\" \r" +
    "\n" +
    "      ng-class=\"{'select2-search-choice-focus':$select.activeMatchIndex === $index, 'select2-locked':$select.isLocked(this, $index)}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <span uis-transclude-append></span>\r" +
    "\n" +
    "      <a href=\"javascript:;\"  \r" +
    "\n" +
    "        class=\"ui-select-match-close select2-search-choice-close\" \r" +
    "\n" +
    "        ng-click=\"$select.removeChoice($index)\" \r" +
    "\n" +
    "        tabindex=\"-1\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "      </a>\r" +
    "\n" +
    "  </li>\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/uiSelect/select2/match.tpl.html',
    "<!--\r" +
    "\n" +
    "  select2-choice needs to be before ui-select-match\r" +
    "\n" +
    "  otherwise CSS rules from https://github.com/fk/select2-bootstrap-css\r" +
    "\n" +
    "  do not work: [class^=\"select2-choice\"]\r" +
    "\n" +
    "-->\r" +
    "\n" +
    "<a class=\"select2-choice ui-select-match\"\r" +
    "\n" +
    "   ng-class=\"{'select2-default': $select.isEmpty()}\"\r" +
    "\n" +
    "   ng-click=\"$select.activate()\">\r" +
    "\n" +
    "    <span ng-show=\"$select.isEmpty()\" \r" +
    "\n" +
    "      class=\"select2-chosen\">\r" +
    "\n" +
    "      \r" +
    "\n" +
    "        {{$select.placeholder}}\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "  <span ng-hide=\"$select.isEmpty()\"   \r" +
    "\n" +
    "    class=\"select2-chosen\" \r" +
    "\n" +
    "    ng-transclude>\r" +
    "\n" +
    "\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "  <abbr ng-if=\"$select.allowClear && !$select.isEmpty()\" \r" +
    "\n" +
    "    class=\"select2-search-choice-close\" \r" +
    "\n" +
    "    ng-click=\"$select.select(undefined)\">\r" +
    "\n" +
    "  </abbr>\r" +
    "\n" +
    "  <span class=\"select2-arrow ui-select-toggle\" \r" +
    "\n" +
    "    ng-click=\"$select.toggle($event)\">\r" +
    "\n" +
    "    <b></b>\r" +
    "\n" +
    "  </span>\r" +
    "\n" +
    "</a>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/select2/select-multiple.tpl.html',
    "<div class=\"ui-select-multiple select2 select2-container select2-container-multi\"\r" +
    "\n" +
    "     ng-class=\"{'select2-container-active select2-dropdown-open': $select.open,\r" +
    "\n" +
    "                'select2-container-disabled': $select.disabled}\">\r" +
    "\n" +
    "  <ul class=\"select2-choices\">\r" +
    "\n" +
    "    <span class=\"ui-select-match\"></span>\r" +
    "\n" +
    "    <li class=\"select2-search-field\">\r" +
    "\n" +
    "      <input \r" +
    "\n" +
    "        type=\"text\" \r" +
    "\n" +
    "        autocomplete=\"off\" \r" +
    "\n" +
    "        autocorrect=\"off\" \r" +
    "\n" +
    "        autocapitalize=\"off\" \r" +
    "\n" +
    "        spellcheck=\"false\" \r" +
    "\n" +
    "        class=\"select2-input ui-select-search\" \r" +
    "\n" +
    "        placeholder=\"{{$select.getPlaceholder()}}\"\r" +
    "\n" +
    "        ng-disabled=\"$select.disabled\"\r" +
    "\n" +
    "        ng-hide=\"$select.disabled\"\r" +
    "\n" +
    "        ng-model=\"$select.search\"\r" +
    "\n" +
    "        ng-click=\"$select.activate()\"\r" +
    "\n" +
    "        style=\"width: 34px;\">\r" +
    "\n" +
    "    </li>\r" +
    "\n" +
    "  </ul>\r" +
    "\n" +
    "  <div class=\"select2-drop select2-with-searchbox select2-drop-active\"\r" +
    "\n" +
    "       ng-class=\"{'select2-display-none': !$select.open}\">\r" +
    "\n" +
    "    <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/uiSelect/select2/select.tpl.html',
    "<div class=\"select2 select2-container\"\r" +
    "\n" +
    "     ng-class=\"{'select2-container-active select2-dropdown-open': $select.open,\r" +
    "\n" +
    "                'select2-container-disabled': $select.disabled,\r" +
    "\n" +
    "                'select2-container-active': $select.focus, \r" +
    "\n" +
    "                'select2-allowclear': $select.allowClear && !$select.isEmpty()}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "  <div class=\"select2-drop select2-with-searchbox select2-drop-active\"\r" +
    "\n" +
    "       ng-class=\"{'select2-display-none': !$select.open}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"select2-search\" \r" +
    "\n" +
    "      ng-show=\"$select.searchEnabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <input type=\"text\" \r" +
    "\n" +
    "        autocomplete=\"off\" \r" +
    "\n" +
    "        autocorrect=\"off\" \r" +
    "\n" +
    "        autocapitalize=\"off\" \r" +
    "\n" +
    "        spellcheck=\"false\"\r" +
    "\n" +
    "             class=\"ui-select-search select2-input\"\r" +
    "\n" +
    "             ng-model=\"$select.search\">\r" +
    "\n" +
    "             \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/selectize/choices.tpl.html',
    "<div ng-show=\"$select.open\" \r" +
    "\n" +
    "  class=\"ui-select-choices selectize-dropdown single\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <div class=\"ui-select-choices-content selectize-dropdown-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"ui-select-choices-group optgroup\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-show=\"$select.isGrouped\" \r" +
    "\n" +
    "        class=\"ui-select-choices-group-label optgroup-header\" \r" +
    "\n" +
    "        ng-bind-html=\"$group.name\">\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div class=\"ui-select-choices-row\" \r" +
    "\n" +
    "        ng-class=\"{active: $select.isActive(this), disabled: $select.isDisabled(this)}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"option ui-select-choices-row-inner\" \r" +
    "\n" +
    "          data-selectable>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/selectize/match.tpl.html',
    "<div ng-hide=\"($select.open || $select.isEmpty())\" \r" +
    "\n" +
    "    class=\"ui-select-match\" \r" +
    "\n" +
    "    ng-transclude>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uiSelect/selectize/select.tpl.html',
    "<div class=\"selectize-control single\">\r" +
    "\n" +
    "  <div class=\"selectize-input\"\r" +
    "\n" +
    "       ng-class=\"{'focus': $select.open, 'disabled': $select.disabled, 'selectize-focus' : $select.focus}\"\r" +
    "\n" +
    "       ng-click=\"$select.activate()\">\r" +
    "\n" +
    "    <div class=\"ui-select-match\"></div>\r" +
    "\n" +
    "      <input type=\"text\" \r" +
    "\n" +
    "        autocomplete=\"off\"  \r" +
    "\n" +
    "        tabindex=\"-1\"\r" +
    "\n" +
    "        class=\"ui-select-search ui-select-toggle\"\r" +
    "\n" +
    "        ng-click=\"$select.toggle($event)\"\r" +
    "\n" +
    "        placeholder=\"{{$select.placeholder}}\"\r" +
    "\n" +
    "        ng-model=\"$select.search\"\r" +
    "\n" +
    "        ng-hide=\"!$select.searchEnabled || ($select.selected && !$select.open)\"\r" +
    "\n" +
    "        ng-disabled=\"$select.disabled\">\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "  <div class=\"ui-select-choices\"></div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/uploader/uploader.html',
    "<div \r" +
    "\n" +
    "    ng-if=\"vm.showBusyIndicator\"\r" +
    "\n" +
    "    busy-indicator=\"vm.busyIndicator\">\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"file-uploader\"\r" +
    "\n" +
    "    ng-class=\"{'invalid': vm.invalid}\"\r" +
    "\n" +
    "    ng-show=\"!vm.hidden\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div ng-show=\"!vm.invalid\">\r" +
    "\n" +
    "        <p>Drop files here to upload</p>\r" +
    "\n" +
    "        <p class=\"text-small\">or click to select file(s)</p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.invalid\">\r" +
    "\n" +
    "        <p>{{vm.validationMessage}}</p>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"text-small\"\r" +
    "\n" +
    "            ng-show=\"vm.invalidFile\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Invalid File: <b>{{vm.invalidFile.name}}</b> - {{vm.invalidFile.size | filesize}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"text-small\"\r" +
    "\n" +
    "            ng-show=\"vm.invalidFileCountMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{vm.invalidFileCountMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"action-area\"\r" +
    "\n" +
    "        ng-click=\"vm.openFileDialog()\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        type=\"file\" \r" +
    "\n" +
    "        ng-if=\"vm.multiple\" \r" +
    "\n" +
    "        multiple \r" +
    "\n" +
    "        ng-attr-accept=\"{{vm._acceptedTypes}}\"\r" +
    "\n" +
    "        file-change=\"vm.onFileInputChange($event)\"\r" +
    "\n" +
    "        ng-model=\"dummyModel\"\r" +
    "\n" +
    "        class=\"ng-hide\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        type=\"file\" \r" +
    "\n" +
    "        ng-if=\"!vm.multiple\" \r" +
    "\n" +
    "        ng-attr-accept=\"{{vm._acceptedTypes}}\"\r" +
    "\n" +
    "        file-change=\"vm.onFileInputChange($event)\" \r" +
    "\n" +
    "        ng-model=\"dummyModel\"\r" +
    "\n" +
    "        class=\"ng-hide\" />\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/userAvatar/userAvatar.html',
    "<div class=\"user-avatar\">\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"avatar-img\" \r" +
    "\n" +
    "        ng-style=\"{\r" +
    "\n" +
    "            'background': 'url(' + vm.userProfilePicturePath + ') 50% 50% no-repeat', \r" +
    "\n" +
    "            'background-size': 'cover'\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/userDropdown/userDropdown.html',
    "<select\r" +
    "\n" +
    "    aa-label=\"{{vm.label}}\"\r" +
    "\n" +
    "    aa-label-strategy=\"{{!vm.label ? 'none' : 'defaultLabelStrategy'}}\"\r" +
    "\n" +
    "    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "    aa-fg-class=\"{{vm.class}}\"\r" +
    "\n" +
    "    aa-field-group=\"vm.ngModel\"\r" +
    "\n" +
    "    options=\"vm.userOptions\"\r" +
    "\n" +
    "    ng-change=\"vm.changed()\"\r" +
    "\n" +
    "    ng-required=\"vm.ngRequired\">\r" +
    "\n" +
    "</select>"
  );


  $templateCache.put('app/components/userGroupList/userGroupList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"listSearchForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search Groups\"\r" +
    "\n" +
    "        uib-tooltip=\"Search by name\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewUserButton\" \r" +
    "\n" +
    "                    ng-click=\"vm.newGroupClicked()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Group\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"UserGroups.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ng-click=\"vm.editGroupClicked(item.id)\">\r" +
    "\n" +
    "                    {{item.name}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-disabled=\"!item.canBeDeleted\"\r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(item)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No users groups were found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>"
  );


  $templateCache.put('app/components/userList/userList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"userListSearchForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search Users\"\r" +
    "\n" +
    "        uib-tooltip=\"Search by name or email\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <user-list-filter-dropdown\r" +
    "\n" +
    "        class=\"v-center left-margin\"\r" +
    "\n" +
    "        table=\"vm.table\">\r" +
    "\n" +
    "    </user-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewUserButton\" \r" +
    "\n" +
    "                    ng-click=\"vm.newUserClicked()\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New User\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.exportUsersExcelReport()\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>Id</th>\r" +
    "\n" +
    "            <th>Status</th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"Users.FirstName,Users.LastName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Email\"\r" +
    "\n" +
    "                    sort-expression=\"users.Email\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{item.id}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"item.isLockedOut\"\r" +
    "\n" +
    "                    class=\"red\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Locked Out\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"!item.isLockedOut\">\r" +
    "\n" +
    "                    Active\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ng-click=\"vm.editUserClicked(item.id)\">\r" +
    "\n" +
    "                    {{item.firstName}} {{item.lastName}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{item.email}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No users were found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/components/userSearchDropdown/userSearchDropdown.html',
    "<ui-select\r" +
    "\n" +
    "    ng-model=\"vm.searchControl.selected\"\r" +
    "\n" +
    "    on-select=\"vm.handleOnSelect($item, $model)\"\r" +
    "\n" +
    "    ng-init=\"$select.label = vm.searchControl.label\"\r" +
    "\n" +
    "    ng-click=\"vm.searchControl.onSearch($select.search)\"\r" +
    "\n" +
    "    reset-search-input=\"false\"\r" +
    "\n" +
    "    name=\"{{vm.searchControl.name}}\"\r" +
    "\n" +
    "    theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-match placeholder=\"{{vm.searchControl.placeholder}}\">\r" +
    "\n" +
    "        {{$select.selected.firstName}} {{$select.selected.lastName}}\r" +
    "\n" +
    "    </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-choices\r" +
    "\n" +
    "        repeat=\"match in vm.searchControl.matches track by $index\"\r" +
    "\n" +
    "        refresh=\"vm.searchControl.onSearch($select.search)\"\r" +
    "\n" +
    "        refresh-delay=\"{{vm.searchControl.debounce}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <user-avatar \r" +
    "\n" +
    "                user-id=\"match.id\"\r" +
    "\n" +
    "                class=\"mini v-center right-margin-thin\">\r" +
    "\n" +
    "            </user-avatar>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                {{match.firstName}} {{match.lastName}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </ui-select-choices>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ui-select-no-choice>\r" +
    "\n" +
    "        {{vm.searchControl.emptyStateMessage}}\r" +
    "\n" +
    "    </ui-select-no-choice>\r" +
    "\n" +
    "</ui-select>\r" +
    "\n"
  );


  $templateCache.put('app/components/viewDropdownMenu/viewDropdownMenu.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown v-center\"\r" +
    "\n" +
    "    uib-dropdown>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"text-large\"\r" +
    "\n" +
    "        ng-class=\"{'red' : vm.table.currentView.isModified}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{vm.table.currentView.name}}\r" +
    "\n" +
    "        <span ng-show=\"vm.table.currentView.isModified\"> - Modified</span>\r" +
    "\n" +
    "        <i class=\"fa fa-chevron-down left-margin-thin\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul uib-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"list-group\"\r" +
    "\n" +
    "            ng-sortable=\"vm.table.viewSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li\r" +
    "\n" +
    "                class=\"nowrap\"\r" +
    "\n" +
    "                ng-repeat=\"view in vm.table.viewOptions track by $index\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    ng-click=\"vm.table.viewSelected(view)\"\r" +
    "\n" +
    "                    class=\"view-selection-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"grab-handle fa fa-ellipsis-v v-center clickable\"></i>\r" +
    "\n" +
    "                    <span class=\"v-center right-margin-big\">{{view.name}}</span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"view.isDefault\"\r" +
    "\n" +
    "                        class=\"small v-center fa fa-lock left-margin-auto no-right-margin\"\r" +
    "\n" +
    "                        uib-tooltip=\"Default views cannot be modified\"\r" +
    "\n" +
    "                        tooltip-placement=\"top-right\"></i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-show=\"!view.isDefault\"\r" +
    "\n" +
    "                        class=\"view-shared-icon small v-center fa fa-share-alt left-margin-auto no-right-margin\"\r" +
    "\n" +
    "                        ng-class=\"{'active': view.isShared}\"\r" +
    "\n" +
    "                        ng-click=\"vm.shareButtonClicked($event, view)\"\r" +
    "\n" +
    "                        uib-tooltip=\"Shared with others\"\r" +
    "\n" +
    "                        tooltip-placement=\"top-right\"></i>\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a ng-click=\"vm.table.sharedViewsClicked()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <i class=\"fa fa-share\"></i>\r" +
    "\n" +
    "                Shared Views\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a ng-click=\"vm.table.resetView()\">\r" +
    "\n" +
    "                <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                Reset View\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a ng-click=\"vm.table.saveViewAsClicked()\">\r" +
    "\n" +
    "                <i class=\"fa fa-save\"></i>\r" +
    "\n" +
    "                Save As\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                ng-click=\"vm.table.saveViewClicked()\"\r" +
    "\n" +
    "                ng-disabled=\"vm.table.currentView.isDefault\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-save\"></i>\r" +
    "\n" +
    "                Save\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                ng-click=\"vm.table.renameViewClicked()\"\r" +
    "\n" +
    "                ng-disabled=\"vm.table.currentView.isDefault\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-edit\"></i>\r" +
    "\n" +
    "                Rename\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                ng-click=\"vm.table.promptToDeleteView()\"\r" +
    "\n" +
    "                ng-disabled=\"vm.table.currentView.isDefault\">\r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                Delete\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/index.html',
    "<!DOCTYPE html>\r" +
    "\n" +
    "<html lang=\"en\">\r" +
    "\n" +
    "    <head>\r" +
    "\n" +
    "        <meta charset=\"UTF-8\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <meta\r" +
    "\n" +
    "            content=\"IE=edge, chrome=1\"\r" +
    "\n" +
    "            http-equiv=\"X-UA-Compatible\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <meta\r" +
    "\n" +
    "            name=\"viewport\"\r" +
    "\n" +
    "            content=\"width=device-width, initial-scale=1\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <script type=\"text/javascript\">\r" +
    "\n" +
    "            WebFontConfig = {\r" +
    "\n" +
    "                google: {\r" +
    "\n" +
    "                    families: [\"Open+Sans:400,300,600,700,400italic:latin\"]\r" +
    "\n" +
    "                }\r" +
    "\n" +
    "            };\r" +
    "\n" +
    "\r" +
    "\n" +
    "            (function () {\r" +
    "\n" +
    "                var wf = document.createElement(\"script\");\r" +
    "\n" +
    "                wf.src =\r" +
    "\n" +
    "                    \"https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js\";\r" +
    "\n" +
    "                wf.type = \"text/javascript\";\r" +
    "\n" +
    "                wf.async = \"true\";\r" +
    "\n" +
    "                var s = document.getElementsByTagName(\"script\")[0];\r" +
    "\n" +
    "                s.parentNode.insertBefore(wf, s);\r" +
    "\n" +
    "            })();\r" +
    "\n" +
    "        </script>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <title>A3 by Agency Multiplied</title>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/bootstrap-3.1.1/bootstrap.min.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/font-awesome-5.15.1/css/all.min.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\"\r" +
    "\n" +
    "            type=\"text/css\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/amcharts/style.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/amcharts/ammap.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/amcharts/export.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            rel=\"shortcut icon\"\r" +
    "\n" +
    "            href=\"favicon.ico\"\r" +
    "\n" +
    "            type=\"image/x-icon\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <script src=\"scripts/jquery/jquery.min.js\"></script>\r" +
    "\n" +
    "    </head>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <body \r" +
    "\n" +
    "        ng-app=\"app\" \r" +
    "\n" +
    "        ng-strict-di>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ui-view class=\"main-view\"></ui-view>\r" +
    "\n" +
    "    </body>\r" +
    "\n" +
    "</html>\r" +
    "\n"
  );


  $templateCache.put('app/modals/activityModal/activityModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"flex no-bottom-margin no-bottom-padding modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4\r" +
    "\n" +
    "        class=\"no-bottom-margin flex-column\"\r" +
    "\n" +
    "        ng-show=\"!vm.historyShown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span>\r" +
    "\n" +
    "            Activity\r" +
    "\n" +
    "            <span ng-show=\"vm.activity.id\">- #{{vm.activity.id}}</span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"small\"\r" +
    "\n" +
    "            ng-click=\"vm.toggleIsFollowed()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span\r" +
    "\n" +
    "                ng-show=\"!vm.activity.isFollowed\"\r" +
    "\n" +
    "                class=\"light-gray\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-bell-slash right-margin-thin\"></i>\r" +
    "\n" +
    "                Not Following\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span\r" +
    "\n" +
    "                ng-show=\"vm.activity.isFollowed\"\r" +
    "\n" +
    "                class=\"green\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-bell right-margin-thin\"></i>\r" +
    "\n" +
    "                Following\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"vm.activity.id\"\r" +
    "\n" +
    "            class=\"x-small top-margin-auto\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-disabled=\"!vm.activity.customerId\"\r" +
    "\n" +
    "                ui-sref=\"main.customerDetail({ customerId: vm.activity.customerId })\"\r" +
    "\n" +
    "                target=\"_blank\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Customer Detail\r" +
    "\n" +
    "                <i class=\"left-margin-thin no-right-margin fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"right-margin-thin left-margin-thin\">|</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-disabled=\"!vm.activity.bondId\"\r" +
    "\n" +
    "                ui-sref=\"main.bondDetail({ id: vm.activity.bondId })\"\r" +
    "\n" +
    "                target=\"_blank\">\r" +
    "\n" +
    "                Bond Detail\r" +
    "\n" +
    "                <i\r" +
    "\n" +
    "                    class=\"left-margin-thin no-right-margin fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"right-margin-thin left-margin-thin\">|</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-disabled=\"!vm.activity.eProducerAccountId\"\r" +
    "\n" +
    "                ui-sref=\"main.eProducerAccountDetail({ id: vm.activity.eProducerAccountId })\"\r" +
    "\n" +
    "                target=\"_blank\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Broker Detail\r" +
    "\n" +
    "                <i class=\"left-margin-thin no-right-margin fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"right-margin-thin left-margin-thin\">|</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-disabled=\"!vm.activity.bondAccountId\"\r" +
    "\n" +
    "                ui-sref=\"main.bondAccountDetail({ id: vm.activity.bondAccountId })\"\r" +
    "\n" +
    "                target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Line Detail\r" +
    "\n" +
    "                <i class=\"left-margin-thin no-right-margin fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 ng-show=\"vm.historyShown\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"right-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.toggleHistory()\">\r" +
    "\n" +
    "            <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Activity History\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.activity.id\"\r" +
    "\n" +
    "        class=\"left-margin-auto inline-block\"\r" +
    "\n" +
    "        ng-if=\"vm.activity\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <i class=\"v-center\">Created By</i>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"v-center left-margin-auto small\"\r" +
    "\n" +
    "                ng-show=\"!vm.historyShown\"\r" +
    "\n" +
    "                ng-click=\"vm.toggleHistory()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                History\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"user mini flex activity-created-info\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <user-avatar\r" +
    "\n" +
    "                user-id=\"vm.activity.createdByUserId\"\r" +
    "\n" +
    "                class=\"mini v-center right-margin\">\r" +
    "\n" +
    "            </user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                <b ng-show=\"vm.activity.createdByUserId\">\r" +
    "\n" +
    "                    {{vm.activity.createdByUserFullName}}\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <b ng-show=\"!vm.activity.createdByUserId\">Auto Generated</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <br />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"small\">\r" +
    "\n" +
    "                    {{vm.activity.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"top-margin small alert alert-danger\"\r" +
    "\n" +
    "    ng-show=\"vm.showMissingActivityTypeAlert\">\r" +
    "\n" +
    "    <b>Missing Activity Type</b>\r" +
    "\n" +
    "    <div>You must assign at least one activity type</div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"!vm.historyShown\"\r" +
    "\n" +
    "    ng-form=\"activityForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"flex tag-container text-left\">\r" +
    "\n" +
    "        <div class=\"flex fill v-center\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                name=\"activitySelect\"\r" +
    "\n" +
    "                id=\"activitySelect\"\r" +
    "\n" +
    "                ng-change=\"vm.addActivityType()\"\r" +
    "\n" +
    "                aa-field-group=\"vm.selectedActivityTypeId\"\r" +
    "\n" +
    "                ng-required=\"vm.activity.activityTypeAssignments.length == 0\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                options=\"vm.activityTypeOptions\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center right-margin-thin left-margin-thin width-auto\"\r" +
    "\n" +
    "                aa-label=\"Activity Type: \">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"tag v-center\"\r" +
    "\n" +
    "                ng-class=\"{'line-through' : activityTypeAssignment.isRemoved}\"\r" +
    "\n" +
    "                ng-repeat=\"activityTypeAssignment in vm.activity.activityTypeAssignments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{activityTypeAssignment.activityTypeName}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button ng-click=\"vm.removeActivityType(activityTypeAssignment, $index)\">\r" +
    "\n" +
    "                    <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            aa-field-group=\"vm.activity.status\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "            aa-fg-class=\"fixed-width-xs v-center no-margin\"\r" +
    "\n" +
    "            options=\"vm.statusOptions\"\r" +
    "\n" +
    "            aa-label=\"Status: \"\r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"modal-activity-assignment-type\"\r" +
    "\n" +
    "                    aa-label=\"Related To: \"\r" +
    "\n" +
    "                    aa-field-group=\"vm.assignmentType\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center left-margin\"\r" +
    "\n" +
    "                    aa-wrapper-class=\"no-margin flex\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                    options=\"vm.assignmentTypeOptions\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    ng-show=\"vm.assignmentType === vm.applicationAssignmentType\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activity.applicationId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    aa-label=\"Application\"\r" +
    "\n" +
    "                    aa-fg-class=\"fixed-width-md\"\r" +
    "\n" +
    "                    options=\"vm.applicationOptions\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    ng-show=\"vm.assignmentType === vm.bondAssignmentType\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activity.bondId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    aa-label=\"Bond\"\r" +
    "\n" +
    "                    aa-fg-class=\"fixed-width-md\"\r" +
    "\n" +
    "                    options=\"vm.bondOptions\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    ng-show=\"vm.assignmentType === vm.bondAccountAssignmentType\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activity.bondAccountId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    aa-fg-class=\"fixed-width-md\"\r" +
    "\n" +
    "                    aa-label=\"Bond Line\"\r" +
    "\n" +
    "                    options=\"vm.bondAccountOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"left-margin-auto width-200\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <user-dropdown\r" +
    "\n" +
    "                    label=\"Assigned To\"\r" +
    "\n" +
    "                    ng-model=\"vm.activity.assignedToUserId\"\r" +
    "\n" +
    "                    class=\"fill\"\r" +
    "\n" +
    "                    allow-unselected=\"true\"\r" +
    "\n" +
    "                    all-users=\"true\">\r" +
    "\n" +
    "                </user-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            id=\"modal-activity-due-date\"\r" +
    "\n" +
    "                            ng-show=\"vm.activity.assignedToUserId\"\r" +
    "\n" +
    "                            ng-required=\"vm.activity.assignedToUserId\"\r" +
    "\n" +
    "                            aa-label=\"Due By\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.activity.dueDate\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"left-margin-thin right-margin-thin\">&nbsp;</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.activity.priorityLevel\"\r" +
    "\n" +
    "                            ng-show=\"vm.activity.assignedToUserId\"\r" +
    "\n" +
    "                            ng-required=\"vm.activity.assignedToUserId\"\r" +
    "\n" +
    "                            aa-label=\"Priority\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.priorityLevelOptions\"></select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            class=\"top-margin\"\r" +
    "\n" +
    "            aa-field-group=\"vm.activity.description\"\r" +
    "\n" +
    "            aa-label=\"Description\"\r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tabset>\r" +
    "\n" +
    "        <uib-tab>\r" +
    "\n" +
    "            <uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Attachments\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span\r" +
    "\n" +
    "                    class=\"badge\"\r" +
    "\n" +
    "                    ng-show=\"vm.activity.attachments.length\">\r" +
    "\n" +
    "                    {{vm.activity.attachments.length}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"top-margin\">\r" +
    "\n" +
    "                <uploader\r" +
    "\n" +
    "                    multiple=\"true\"\r" +
    "\n" +
    "                    max-number-of-files=\"10\"\r" +
    "\n" +
    "                    max-file-size=\"10000000\"\r" +
    "\n" +
    "                    on-files-added=\"vm.filesAdded(files)\"\r" +
    "\n" +
    "                    on-file-uploaded=\"vm.fileUploaded(file)\"\r" +
    "\n" +
    "                    accepted-types=\"attachments\"\r" +
    "\n" +
    "                    controls=\"vm.uploaderControls\">\r" +
    "\n" +
    "                </uploader>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section\r" +
    "\n" +
    "                ng-show=\"vm.activity.attachments.length\"\r" +
    "\n" +
    "                class=\"text-left top-margin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <activity-attachment-list \r" +
    "\n" +
    "                    attachments=\"vm.activity.attachments\"\r" +
    "\n" +
    "                    uploader-controls=\"vm.uploaderControls\">\r" +
    "\n" +
    "                </activity-attachment-list>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab>\r" +
    "\n" +
    "            <uib-tab-heading>\r" +
    "\n" +
    "                Conversation\r" +
    "\n" +
    "                <span\r" +
    "\n" +
    "                    class=\"badge\"\r" +
    "\n" +
    "                    ng-show=\"vm.activity.conversationMessages.length\">\r" +
    "\n" +
    "                    {{vm.activity.conversationMessages.length}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </uib-tab-heading>\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"conversation-message\"\r" +
    "\n" +
    "                    ng-repeat=\"message in vm.activity.conversationMessages\">\r" +
    "\n" +
    "                    <div class=\"message-metadata\">\r" +
    "\n" +
    "                        <user-avatar\r" +
    "\n" +
    "                            user-id=\"message.createdByUserId\"\r" +
    "\n" +
    "                            class=\"mini v-center right-margin\"></user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"message-user-info\">\r" +
    "\n" +
    "                            <span>{{message.createdByUserFullName}}</span>\r" +
    "\n" +
    "                            <span>{{message.createdDateTime | localDateTime}}</span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-bind-html=\"message.message\"\r" +
    "\n" +
    "                        class=\"message-content\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"new-message-container\">\r" +
    "\n" +
    "                    <label>New Message</label>\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"new-message message-content\"\r" +
    "\n" +
    "                        contenteditable=\"true\"\r" +
    "\n" +
    "                        ng-model=\"vm.activity.newConversationMessage\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "    </uib-tabset>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.submit_click()\">Save</button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"activityHistoryForm\"\r" +
    "\n" +
    "    ng-show=\"vm.historyShown\">\r" +
    "\n" +
    "    <form\r" +
    "\n" +
    "        name=\"historySearchForm\"\r" +
    "\n" +
    "        class=\"bottom-margin\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.activityHistoryTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                ng-change=\"vm.activityHistoryTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                aa-fg-class=\"fixed-width-md small inline-block\"\r" +
    "\n" +
    "                placeholder=\"Search by name or by keyword\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.activityHistoryTable.isVisible\">\r" +
    "\n" +
    "        <table class=\"bottom-margin\">\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"historyEntry in vm.activityHistoryTable.data\">\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <user-avatar\r" +
    "\n" +
    "                                user-id=\"vm.activity.createdByUserId\"\r" +
    "\n" +
    "                                class=\"mini v-center right-margin\">\r" +
    "\n" +
    "                            </user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"v-center nowrap\">\r" +
    "\n" +
    "                                <b ng-bind-html=\"historyEntry.createdByUserFullName | highlight:vm.currentSearchPhrase\"></b>\r" +
    "\n" +
    "                                <br />\r" +
    "\n" +
    "                                <span class=\"small\">\r" +
    "\n" +
    "                                    {{historyEntry.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"small text-left\">\r" +
    "\n" +
    "                        <div ng-repeat=\"noteItem in historyEntry.notes\">\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                ng-bind-html=\"noteItem.note | highlight:vm.currentSearchPhrase\"></span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.activityHistoryTable.pager\">\r" +
    "\n" +
    "        </table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"vm.activityHistoryTable.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No history entries found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/modals/addWidgetModal/addWidgetModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">    \r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    <h4>Add A Widget</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "<section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-form=\"addWidgetForm\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.search\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"search\" \r" +
    "\n" +
    "            aa-fg-class=\"search white\" \r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <main class=\"dashboard-widget-picker\">\r" +
    "\n" +
    "        <div class=\"col-sm-6 dashboard-widgets-list text-left\">\r" +
    "\n" +
    "            <perfect-scrollbar class=\"scroller\">\r" +
    "\n" +
    "                <uib-accordion>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        uib-accordion-group \r" +
    "\n" +
    "                        ng-repeat=\"category in vm.widgetCategories\" \r" +
    "\n" +
    "                        is-open=\"category.open\" \r" +
    "\n" +
    "                        ng-show=\"category.filteredWidgetTypes.length > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-accordion-heading>\r" +
    "\n" +
    "                            {{category.name}} \r" +
    "\n" +
    "                            <em>({{category.filteredWidgetTypes.length}})</em> \r" +
    "\n" +
    "                            <i \r" +
    "\n" +
    "                                class=\"fa\" \r" +
    "\n" +
    "                                ng-class=\"{ 'fa-minus': category.open, 'fa-plus': !category.open }\">\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                        </uib-accordion-heading>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            ng-dblclick=\"vm.addWidget(widgetType)\" \r" +
    "\n" +
    "                            ng-click=\"vm.showWidgetType(widgetType)\" \r" +
    "\n" +
    "                            ng-class=\"{ 'active': widgetType == vm.displayWidgetType }\" \r" +
    "\n" +
    "                            ng-repeat=\"widgetType in (category.filteredWidgetTypes = (category.widgetTypes | filter:vm.searchWidgetTypes ))\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"{{widgetType.thumbnailClass}}\"></i>\r" +
    "\n" +
    "                            <span>\r" +
    "\n" +
    "                                {{widgetType.name}}\r" +
    "\n" +
    "                                <br />\r" +
    "\n" +
    "                                <small>{{widgetType.description | limitTo:50}}\r" +
    "\n" +
    "                                    <span ng-show=\"widgetType.description.length > 50\">...</span>\r" +
    "\n" +
    "                                </small>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </uib-accordion>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"vm.displayWidgetType\" \r" +
    "\n" +
    "            class=\"col-sm-6 dashboard-widget-pane\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <perfect-scrollbar class=\"scroller\">\r" +
    "\n" +
    "                <h5>{{vm.displayWidgetType.name}}</h5>\r" +
    "\n" +
    "                <img ng-src=\"{{vm.displayWidgetType.image}}\" />\r" +
    "\n" +
    "                <div class=\"text-left\">\r" +
    "\n" +
    "                    <p>{{vm.displayWidgetType.description}}</p>\r" +
    "\n" +
    "                    <div class=\"tag-container\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"tag\" \r" +
    "\n" +
    "                            ng-repeat=\"tag in vm.displayWidgetType.tags\" \r" +
    "\n" +
    "                            ng-click=\"vm.searchTags(tag)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{tag}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <button ng-click=\"vm.addWidget(vm.displayWidgetType)\">\r" +
    "\n" +
    "                    Add To Dashboard\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </main>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/modals/applicationAccessModal/applicationAccessModal.html',
    "<div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"emailAppForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\"\r" +
    "\n" +
    "            type=\"button\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Email Application</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <select\r" +
    "\n" +
    "            aa-field-group=\"vm.emailTemplateId\"\r" +
    "\n" +
    "            aa-label=\"Email Template\"\r" +
    "\n" +
    "            aa-unselected-text=\"Default Template\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "            options=\"vm.templateOptions\"\r" +
    "\n" +
    "            ng-change=\"vm.templateSelectionChanged()\">\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.emailAddress\"\r" +
    "\n" +
    "            aa-label=\"Recipient Email Address\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.subject\"\r" +
    "\n" +
    "            required/>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.returnUrl\"\r" +
    "\n" +
    "                aa-label=\"Call to Action URL\"\r" +
    "\n" +
    "                disabled\r" +
    "\n" +
    "                aa-fg-class=\"fill\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.copyReturnUrl()\"\r" +
    "\n" +
    "                class=\"top-margin-auto left-margin small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>        \r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-angular\r" +
    "\n" +
    "        name=\"htmlcontent\"\r" +
    "\n" +
    "        ng-model=\"vm.htmlBody\">\r" +
    "\n" +
    "    </text-angular>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\"\r" +
    "\n" +
    "            type=\"button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            aa-submit-form=\"vm.send()\"\r" +
    "\n" +
    "            type=\"button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Send Email\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/applicationDetailModal/applicationDetailModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"modal-refresh-button\"\r" +
    "\n" +
    "        ng-click=\"vm.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        id=\"modal-application-detail-customer-profile-link\"\r" +
    "\n" +
    "        ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "        ng-click=\"vm.goToCustomerDetail()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "        Customer Detail\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span ng-show=\"vm.isCarrier\">Application Detail</span>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"bond-detail text-left\"\r" +
    "\n" +
    "    ng-form=\"applicationDetailForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"bond-summary\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-xs-8\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"bond-type\">\r" +
    "\n" +
    "                    {{vm.applicationDetail.bondTypeName | limitTo:75}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                    SFAA: {{vm.applicationDetail.sfaaCode}} - {{vm.applicationDetail.sfaaCodeDescription | limitTo:75}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-xs-4 text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h4 class=\"no-margin\">App# {{vm.applicationDetail.applicationNumber}}</h4>\r" +
    "\n" +
    "                <b>{{vm.applicationDetail | applicationType}}</b>\r" +
    "\n" +
    "                <span> - </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <b ng-bind-html=\"vm.applicationDetail | applicationStatus\"></b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-xs-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div>Bond Amount: {{vm.applicationDetail.bondAmount | currency:'$'}}</div>\r" +
    "\n" +
    "                <div>Desired Effective Date: {{vm.applicationDetail.desiredEffectiveDate | dateOnly}}</div>\r" +
    "\n" +
    "                <div>Principal: <b>{{vm.applicationDetail.nameOnBond}}</b></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"carrier\" \r" +
    "\n" +
    "                    ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <img \r" +
    "\n" +
    "                        ng-src=\"{{ vm.logoImg }}\" \r" +
    "\n" +
    "                        alt=\"{{ vm.application.customer.systemAccount.agencyCompanyName }}\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div>{{vm.applicationDetail.agencyCompanyName}}</div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"writing-company\">\r" +
    "\n" +
    "                            {{vm.applicationDetail.agencyPhysicalAddress}} {{vm.applicationDetail.agencyPhysicalSuiteAptNumber}} {{vm.applicationDetail.agencyPhysicalCity}} {{vm.applicationDetail.agencyPhysicalState}} {{vm.applicationDetail.agencyPhysicalZip}} {{vm.applicationDetail.agencyPhysicalCounty}}  county\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"clearfix\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tag-container application-id=\"vm.applicationId\"></tag-container>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-xs-6 right-content\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <textarea \r" +
    "\n" +
    "                    aa-field-group=\"vm.applicationDetail.DeclinedApplicationComments\" \r" +
    "\n" +
    "                    aa-label=\"Comments\" \r" +
    "\n" +
    "                    disabled \r" +
    "\n" +
    "                    ng-show=\"vm.isDeclined\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <dropdown\r" +
    "\n" +
    "                    options=\"vm.actionDropdownOptions\"\r" +
    "\n" +
    "                    button-text=\"Actions\"\r" +
    "\n" +
    "                    button-class=\"top-margin small white\"\r" +
    "\n" +
    "                    button-id=\"application-detail-actions-button\"\r" +
    "\n" +
    "                    right-button-icon=\"fa fa-caret-down\"\r" +
    "\n" +
    "                    menu-position=\"right\">\r" +
    "\n" +
    "                </dropdown>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tabset active=\"vm.activeTab\">\r" +
    "\n" +
    "        <uib-tab heading=\"Bond Info\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header>General</header>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.applicationDetail.nameOnBond\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        disabled \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.applicationDetail.bondAmount\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        disabled \r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.applicationDetail.desiredEffectiveDate\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        disabled \r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header>Bond Form Questions</header>\r" +
    "\n" +
    "                <div ng-repeat=\"response in bondQuestionResponses = (vm.applicationDetail.applicationQuestionResponses | filter: {originType: 'BondType'})\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"response.responseText\" \r" +
    "\n" +
    "                        aa-label=\"{{response.questionText}}\"  \r" +
    "\n" +
    "                        ng-change=\"vm.questionResponseChanged(response)\" \r" +
    "\n" +
    "                        disabled />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"initial-state white\" \r" +
    "\n" +
    "                    ng-show=\"!bondQuestionResponses.length\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <p>This bond doesn't have any bond form specific information associated to it</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header>Underwriting Questions</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-repeat=\"response in underwritingQuestions = (vm.applicationDetail.applicationQuestionResponses | filter: {originType: 'Underwriting'})\">\r" +
    "\n" +
    "                    <span>{{response.questionText}}</span>\r" +
    "\n" +
    "                    <b>{{response.responseText}}</b>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"initial-state white\" ng-show=\"!underwritingQuestions.length\">\r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <p>No underwriting questions were asked</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Principal\" \r" +
    "\n" +
    "            select=\"vm.loadPrincipal()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.principalPromise}\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-repeat=\"c in vm.companies\" \r" +
    "\n" +
    "                class=\"bottom-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <company \r" +
    "\n" +
    "                    company=\"c\" \r" +
    "\n" +
    "                    changed=\"vm.companyChanged(company, changedProperty)\" \r" +
    "\n" +
    "                    disabled>\r" +
    "\n" +
    "                </company>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-repeat=\"p in vm.people\" \r" +
    "\n" +
    "                class=\"bottom-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <person \r" +
    "\n" +
    "                    person=\"p\" \r" +
    "\n" +
    "                    changed=\"vm.personChanged(person, changedProperty)\" \r" +
    "\n" +
    "                    disabled>\r" +
    "\n" +
    "                </person>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Obligee\"\r" +
    "\n" +
    "            select=\"vm.loadObligee()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <obligee-form\r" +
    "\n" +
    "                is-disabled=\"true\"\r" +
    "\n" +
    "                entryMode=\"application\" \r" +
    "\n" +
    "                obligee=\"vm.obligee\">\r" +
    "\n" +
    "            </obligee-form>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Credit Reports\"\r" +
    "\n" +
    "            select=\"vm.loadCreditReports()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.creditReportsPromise}\"></div>\r" +
    "\n" +
    "            <credit-report-list\r" +
    "\n" +
    "                people=\"vm.applicationDetail.creditReportPersonReport\"\r" +
    "\n" +
    "                is-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Attachments\"\r" +
    "\n" +
    "            select=\"vm.attachmentsTabSelected()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{\r" +
    "\n" +
    "                promise:vm.attachmentsPromise,\r" +
    "\n" +
    "                message: 'Loading Attachments...'}\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-form=\"attachmentsForm\"\r" +
    "\n" +
    "                class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.attachmentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                    ng-change=\"vm.attachmentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"Search Attachments\"\r" +
    "\n" +
    "                    uib-tooltip=\"Search by filename\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.attachmentsTable.pager.totalRecordCount\"\r" +
    "\n" +
    "                    class=\"v-center small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.attachmentsTable.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <attachment-list-filter-dropdown \r" +
    "\n" +
    "                    class=\"left-margin flex\"\r" +
    "\n" +
    "                    table=\"vm.attachmentsTable\">\r" +
    "\n" +
    "                </attachment-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <show-activity-modal-button \r" +
    "\n" +
    "                    application-id=\"vm.applicationId\"\r" +
    "\n" +
    "                    customer-id=\"vm.applicationDetail.customerId\"\r" +
    "\n" +
    "                    class=\"left-margin v-center\"\r" +
    "\n" +
    "                    button-class=\"mini\"\r" +
    "\n" +
    "                    on-submit=\"vm.loadAttachments()\"\r" +
    "\n" +
    "                    default-options=\"attachment\">\r" +
    "\n" +
    "                </show-activity-modal-button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <attachment-list\r" +
    "\n" +
    "                table=\"vm.attachmentsTable\"\r" +
    "\n" +
    "                on-attachment-modified=\"vm.loadAttachments()\" />\r" +
    "\n" +
    "            </attachment-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Quotes\" \r" +
    "\n" +
    "            select=\"vm.loadQuotes()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.quotesPromise}\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <quote-list \r" +
    "\n" +
    "                quotes=\"vm.quotes\" \r" +
    "\n" +
    "                application-id=\"vm.applicationId\"\r" +
    "\n" +
    "                quotes-updated=\"vm.quotesUpdated()\" \r" +
    "\n" +
    "                on-purchase=\"vm.quotePurchased(bondId)\" \r" +
    "\n" +
    "                is-application-declined=\"vm.isDeclined\"\r" +
    "\n" +
    "                is-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "                is-submitted=\"vm.isSubmitted\">\r" +
    "\n" +
    "            </quote-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Follow Ups\" \r" +
    "\n" +
    "            select=\"vm.loadFollowUps()\" \r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{ promise:vm.followUpPromise }\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <follow-up-list \r" +
    "\n" +
    "                follow-ups=\"vm.applicationFollowUps\" \r" +
    "\n" +
    "                application-id=\"vm.application.id\">\r" +
    "\n" +
    "            </follow-up-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            heading=\"Memos\"\r" +
    "\n" +
    "            select=\"vm.memosTabSelected()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.memoPromise}\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <memo-list \r" +
    "\n" +
    "                table=\"vm.memosTable\" \r" +
    "\n" +
    "                application-id=\"vm.applicationId\">\r" +
    "\n" +
    "            </memo-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab\r" +
    "\n" +
    "            heading=\"Activities\"\r" +
    "\n" +
    "            select=\"vm.activityTabSelected()\"\r" +
    "\n" +
    "            ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{\r" +
    "\n" +
    "                promise: vm.activityPromise,\r" +
    "\n" +
    "                message: 'Loading Activities...' }\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-form=\"activityListForm\"\r" +
    "\n" +
    "                class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                    ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"Search Activities\"\r" +
    "\n" +
    "                    uib-tooltip=\"Search by description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.activitiesTable.pager.totalRecordCount\"\r" +
    "\n" +
    "                    class=\"v-center small\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        {{vm.activitiesTable.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <activity-list-filter-dropdown\r" +
    "\n" +
    "                    class=\"left-margin flex\"\r" +
    "\n" +
    "                    table=\"vm.activitiesTable\">\r" +
    "\n" +
    "                </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <show-activity-modal-button \r" +
    "\n" +
    "                    application-id=\"vm.applicationId\"\r" +
    "\n" +
    "                    customer-id=\"vm.applicationDetail.customerId\"\r" +
    "\n" +
    "                    class=\"left-margin v-center\"\r" +
    "\n" +
    "                    button-class=\"mini\"\r" +
    "\n" +
    "                    on-submit=\"vm.loadActivities()\">\r" +
    "\n" +
    "                </show-activity-modal-button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <activity-list \r" +
    "\n" +
    "                table=\"vm.activitiesTable\"\r" +
    "\n" +
    "                customer-id=\"vm.applicationDetail.customerId\"\r" +
    "\n" +
    "                application-id=\"vm.applicationId\"\r" +
    "\n" +
    "                on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "            </activity-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "    </uib-tabset>    \r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<footer class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!vm.isCarrier && vm.applicationDetail.legacyId\" \r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Legacy Id:\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"!vm.applicationDetail.legacyUrl\">\r" +
    "\n" +
    "            {{vm.application.legacyId}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            href=\"{{vm.applicationDetail.legacyUrl}}\"\r" +
    "\n" +
    "            ng-show=\"vm.applicationDetail.legacyUrl\"\r" +
    "\n" +
    "            target=\"_blank\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.applicationDetail.legacyId}}\r" +
    "\n" +
    "            <i class=\"fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        id=\"modal-application-detail-close-button\"\r" +
    "\n" +
    "        class=\"left-margin-auto white v-center\"\r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Close\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/modals/assignPrincipalModal/assignPrincipalModal.html',
    "<div busy-indicator=\"vm.processingPromise\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <h4>Assign Principal</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"assignPrincipalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h5 class=\"sub-heading\">Bonds</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table>\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>Bond Type</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Bond Number</th>\r" +
    "\n" +
    "                    <th class=\"text-center\">Term</th>\r" +
    "\n" +
    "                    <th>Name on Bond</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tr ng-repeat=\"bond in vm.bonds\">\r" +
    "\n" +
    "                <td class=\"small\">\r" +
    "\n" +
    "                    {{ bond.bondAmount | currency:'$' }}\r" +
    "\n" +
    "                    {{ bond.bondType }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"small text-center\">    \r" +
    "\n" +
    "                    {{ bond.bondNumber }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-center\">\r" +
    "\n" +
    "                    {{ bond.effectiveDate | dateOnly}} - {{bond.expirationDate | dateOnly }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"input-sm no-top-margin no-padding\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-field-group=\"bond.nameOnBond\"\r" +
    "\n" +
    "                        ng-disabled=\"!bond.allowPrincipalAssignment\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 class=\"flex sub-heading\">\r" +
    "\n" +
    "            Principal\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                aa-label=\"Principal is a company\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"no-section\">\r" +
    "\n" +
    "            <company\r" +
    "\n" +
    "                ng-if=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                class=\"bottom-margin-big no-section\"\r" +
    "\n" +
    "                company=\"vm.principal.company\">\r" +
    "\n" +
    "            </company>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <contact-list \r" +
    "\n" +
    "            ng-if=\"vm.principal.people\"\r" +
    "\n" +
    "            contacts=\"vm.principal.people\" \r" +
    "\n" +
    "            hide-include-on-bonds=\"true\">\r" +
    "\n" +
    "        </contact-list>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/attachmentModal/attachmentModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Attachment</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\" \r" +
    "\n" +
    "            class=\"alert alert-danger\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-show=\"!vm.attachment.id\">\r" +
    "\n" +
    "            <uploader\r" +
    "\n" +
    "                on-files-added=\"vm.filesAdded(files)\"\r" +
    "\n" +
    "                controls=\"vm.uploaderControls\" \r" +
    "\n" +
    "                accepted-types=\"attachments\">\r" +
    "\n" +
    "            </uploader-zone>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.attachment.filename\" \r" +
    "\n" +
    "            disabled />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea aa-field-group=\"vm.attachment.comments\"></textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 class=\"bottom-margin\">\r" +
    "\n" +
    "            Sharing <help message=\"This lets you set who is able to download this attachment\"></help>\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.attachment.visibleToAgent\" \r" +
    "\n" +
    "            aa-label=\"Share with Agent\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.attachment.visibleInEProducer\" \r" +
    "\n" +
    "            aa-label=\"Share through EProducer\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save_click()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/attachmentViewerModal/attachmentViewerModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"attachmentViewerForm\" \r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>{{vm.fileName}}</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <document-viewer\r" +
    "\n" +
    "        class=\"fill flex column width-100p\"\r" +
    "\n" +
    "        ng-if=\"vm.url\"\r" +
    "\n" +
    "        url=\"vm.url\">\r" +
    "\n" +
    "    </document-viewer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"top-margin flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <pdf-download \r" +
    "\n" +
    "            class=\"v-center right-margin-auto\"\r" +
    "\n" +
    "            url=\"vm.url\" \r" +
    "\n" +
    "            filename=\"{{ vm.fileName }}\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "            Download\r" +
    "\n" +
    "        </pdf-download>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white v-center\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/autoFeeDetailModal/autoFeeDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4 ng-show=\"!vm.newFee\">\r" +
    "\n" +
    "            Edit Fee\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.newFee\">\r" +
    "\n" +
    "            New Fee\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Description</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.autoFee.description\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                    <fee-code-dropdown\r" +
    "\n" +
    "                        fee-code=\"vm.autoFee.code\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Settings</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div class=\"fill right-margin\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.autoFee.creditProducer\" \r" +
    "\n" +
    "                        aa-label=\"Credit Producer\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        label-class=\"fill\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"fill left-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.autoFee.includeInBrokerCommissionCalculation\" \r" +
    "\n" +
    "                        aa-label=\"Include in Broker Commission Calculation\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        label-class=\"fill\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.autoFee.defaultAmount\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"money\"/>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header class=\"flex\">\r" +
    "\n" +
    "                <span>\r" +
    "\n" +
    "                    Auto Creation\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.autoFee.autoApply\" \r" +
    "\n" +
    "                    aa-label=\"Automatically Apply Fee\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"left-margin-auto\" />\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"vm.autoFee.autoApply\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <h5>Where does this apply?</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.autoFee.appliesToAllBondTypes\" \r" +
    "\n" +
    "                    aa-label=\"Fee applies to all bond types\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\" \r" +
    "\n" +
    "                    aa-fg-class=\"width-350\" \r" +
    "\n" +
    "                    label-class=\"fill\"/>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"row top-margin\" \r" +
    "\n" +
    "                    ng-show=\"!vm.autoFee.appliesToAllBondTypes\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <div class=\"field-flex-box\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ui-select \r" +
    "\n" +
    "                                ng-model=\"vm.bondTypeSearch.selected\" \r" +
    "\n" +
    "                                ng-init=\"$select.label = 'Search Bond Types'\" \r" +
    "\n" +
    "                                reset-search-input=\"false\" \r" +
    "\n" +
    "                                name=\"bondTypeSearch\"\r" +
    "\n" +
    "                                theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <ui-select-match \r" +
    "\n" +
    "                                placeholder=\"Search for bond type name...\" \r" +
    "\n" +
    "                                label=\"Search Bond Types\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{$select.selected.name}}\r" +
    "\n" +
    "                            </ui-select-match>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <ui-select-choices \r" +
    "\n" +
    "                                repeat=\"bondType in vm.bondTypeSearch.matches track by $index\" \r" +
    "\n" +
    "                                refresh=\"vm.searchBondTypes($select.search)\" \r" +
    "\n" +
    "                                refresh-delay=\"0\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    <div ng-bind-html=\"bondType.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                </ui-select-choices>\r" +
    "\n" +
    "                            </ui-select>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"col-sm-1\" \r" +
    "\n" +
    "                                ng-click=\"vm.addBondType()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <table ng-show=\"vm.autoFee.autoFeeBondTypes.length\">\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th>Assigned Bond Types</th>\r" +
    "\n" +
    "                                    <th>&nbsp;</th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tr \r" +
    "\n" +
    "                                ng-class=\"{'line-through': bondType.isRemoved}\"\r" +
    "\n" +
    "                                ng-repeat=\"bondType in vm.autoFee.autoFeeBondTypes\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <td>\r" +
    "\n" +
    "                                    {{bondType.bondTypeName}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"text-right\">\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        class=\"mini\"\r" +
    "\n" +
    "                                        ng-click=\"vm.removeBondType(bondType, $index)\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <empty-state\r" +
    "\n" +
    "                            ng-show=\"!vm.autoFee.autoFeeBondTypes.length\"\r" +
    "\n" +
    "                            message=\"This fee isn't assigned to any bond types.\">\r" +
    "\n" +
    "                        </empty-state>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <div class=\"field-flex-box\">\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                aa-field-group=\"vm.selectedSfaaCodeId\"\r" +
    "\n" +
    "                                options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"col-sm-1\" \r" +
    "\n" +
    "                                ng-click=\"vm.addSfaaCode()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <table ng-show=\"vm.autoFee.autoFeeSfaaCodes.length\">\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th>Assigned Sfaa Codes</th>\r" +
    "\n" +
    "                                    <th>&nbsp;</th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tr \r" +
    "\n" +
    "                                ng-class=\"{'line-through': sfaaCode.isRemoved}\"\r" +
    "\n" +
    "                                ng-repeat=\"sfaaCode in vm.autoFee.autoFeeSfaaCodes\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <td>\r" +
    "\n" +
    "                                    {{sfaaCode.sfaaCodeDescription}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"text-right\">\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        class=\"mini\"\r" +
    "\n" +
    "                                        ng-click=\"vm.removeSfaaCode(sfaaCode, $index)\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <empty-state\r" +
    "\n" +
    "                            ng-show=\"!vm.autoFee.autoFeeSfaaCodes.length\"\r" +
    "\n" +
    "                            message=\"This fee isn't assigned to any sfaa codes.\">\r" +
    "\n" +
    "                        </empty-state>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <h5>\r" +
    "\n" +
    "                    Amount\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.feeType\" \r" +
    "\n" +
    "                            aa-label=\"Fee Amount Type\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.feeTypes\" >\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-sm-6\"></div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.feeAmount\" \r" +
    "\n" +
    "                            aa-label=\"Flat Fee Amount\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                            ng-show=\"vm.autoFee.feeType == 'Flat'\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"row\" \r" +
    "\n" +
    "                            ng-show=\"vm.autoFee.feeType == 'PercentOfPremium'\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.autoFee.feePercent\" \r" +
    "\n" +
    "                                aa-label=\"Fee Percentage\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"percent\" \r" +
    "\n" +
    "                                ng-required=\"vm.autoFee.autoApply && vm.autoFee.feeType == 'PercentOfPremium'\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-md-4\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.autoFee.minimumAmount\" \r" +
    "\n" +
    "                                aa-label=\"Minimum Fee\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                                ng-required= \"vm.autoFee.autoApply && vm.autoFee.feeType == 'PercentOfPremium'\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-md-4\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.autoFee.maximumAmount\" \r" +
    "\n" +
    "                                aa-label=\"Maximum Fee\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                                ng-required= \"vm.autoFee.autoApply && vm.autoFee.feeType == 'PercentOfPremium'\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-md-4\" />\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                            <p class=\"col-md-12\">\r" +
    "\n" +
    "                                For Riders, Cancellations, and Reinstatements, fees will be calculated based on the premium of the most recent Renewal or New Business transactions.\r" +
    "\n" +
    "                            </p>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <h5 class=\"top-margin\">\r" +
    "\n" +
    "                    What transactions does this apply to?\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToNewBusiness\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to New Business\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToRiders\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to Riders\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToCancellations\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to Cancellations\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToRenewals\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to Renewals\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToReinstatements\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to Reinstatements\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 class=\"top-margin\">\r" +
    "\n" +
    "                    Where should the <strong>New Business</strong> fee apply?\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToEProducerPrincipals\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to eProducer principals\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesToEproducerBrokers\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to eProducer brokers\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.autoFee.appliesInA3\" \r" +
    "\n" +
    "                            aa-label=\"Fee applies to A3 Dashboard\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            label-class=\"fill\"\r" +
    "\n" +
    "                            aa-fg-class=\"small-switch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white left-margin-auto right-margin\" \r" +
    "\n" +
    "            ng-click=\"vm.close()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\" \r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/billingEntryCreditAssignmentModal/billingEntryCreditAssignmentModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Credit Assignment</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div class=\"fill\">\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <div class=\"text-right\">\r" +
    "\n" +
    "                            Bond #: \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            Transaction Type: \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            Effective Date: \r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                                Customer:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-show=\"vm.creditBillingEntry.eProducerAccountId\">\r" +
    "\n" +
    "                                Broker: \r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"left-margin\">\r" +
    "\n" +
    "                            {{vm.creditBillingEntry.bondNumber}}\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            {{vm.creditBillingEntry | transactionType}}\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            {{vm.creditBillingEntry.transactionEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                            <div class=\"top-margin-thin\">\r" +
    "\n" +
    "                                <span ng-show=\"vm.creditBillingEntry.customerLookupCode\">\r" +
    "\n" +
    "                                    {{vm.creditBillingEntry.customerLookupCode}} - \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <b> {{vm.creditBillingEntry.customerName}}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div ng-show=\"vm.creditBillingEntry.eProducerAccountId\">\r" +
    "\n" +
    "                                <span ng-show=\"vm.creditBillingEntry.brokerLookupCode\">\r" +
    "\n" +
    "                                    {{vm.creditBillingEntry.brokerLookupCode}} - \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <b> {{vm.creditBillingEntry.brokerName}}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"fill flex\">\r" +
    "\n" +
    "                    <div class=\"fill text-right\">\r" +
    "\n" +
    "                        Billing Entry #:\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        Description:\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        Original Credit: \r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        Bill To:\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.creditBillingEntry.eProducerAccountId\">\r" +
    "\n" +
    "                            Broker Commission:\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"top-margin-big\">\r" +
    "\n" +
    "                            Remaining Credit:\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"left-margin\">\r" +
    "\n" +
    "                        {{vm.creditBillingEntry.id}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{vm.creditBillingEntry.code}} - {{vm.creditBillingEntry.description}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{-vm.creditBillingEntry.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{vm.creditBillingEntry.billToTypeDescription}}    \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-show=\"vm.creditBillingEntry.eProducerAccountId\">\r" +
    "\n" +
    "                            {{vm.creditBillingEntry.brokerCommission | currency : '$'}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex top-margin-big\">\r" +
    "\n" +
    "                            <i \r" +
    "\n" +
    "                                ng-show=\"vm.remainingCredit === 0\"\r" +
    "\n" +
    "                                class=\"v-center left-margin-auto text-large fa fa-check green\">\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i \r" +
    "\n" +
    "                                ng-show=\"vm.remainingCredit !== 0\"\r" +
    "\n" +
    "                                class=\"v-center left-margin-auto text-large fa fa-exclamation-triangle red\">\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"v-center left-margin\"> {{vm.remainingCredit | currency:'$'}}</span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>            \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset class=\"tab-set\">\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Billing Entries\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.creditBillingEntry.billingEntryCreditAssignments.length\"\r" +
    "\n" +
    "                        class=\"badge left-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.creditBillingEntry.billingEntryCreditAssignments.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section>                        \r" +
    "\n" +
    "                    <div class=\"flex bottom-margin-thin\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.searchPhrase\"\r" +
    "\n" +
    "                            aa-label=\"Search by Bond or Invoice Number\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin fixed-width-lg\" \r" +
    "\n" +
    "                            ng-change=\"vm.searchPhraseChanged()\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                    <table \r" +
    "\n" +
    "                        ng-show=\"vm.creditBillingEntry.openBillingEntries.length || vm.creditBillingEntry.billingEntryCreditAssignments.length\"\r" +
    "\n" +
    "                        class=\"small top-margin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr class=\"text-bold\">\r" +
    "\n" +
    "                                <th class=\"fit-content\">#</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Bond Number</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Type</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Transaction</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Effective Date</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Transaction Date</th>\r" +
    "\n" +
    "                                <th>Description</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Invoice #</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Billed Amount</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Broker Commission</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Due</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Applied</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.creditBillingEntry.billingEntryCreditAssignments.length\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <b>Assigned Billing Entries</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr ng-repeat=\"billingEntryAssignment in vm.creditBillingEntry.billingEntryCreditAssignments\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{billingEntryAssignment.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in billingEntryAssignment.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{billingEntryAssignment.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{billingEntryAssignment.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountClicked(billingEntryAssignment)\">\r" +
    "\n" +
    "                                        {{billingEntryAssignment.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"billingEntryAssignment.amount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"billingEntryAssignment.originalAmount > 0\"/>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"billingEntryAssignment.amount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"billingEntryAssignment.originalAmount < 0\"/>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>Outstanding Billing Entries</b>\r" +
    "\n" +
    "                                    <span ng-show=\"vm.searchPhrase && vm.filteredOpenBillingEntries.length\">\r" +
    "\n" +
    "                                        <span> - </span>\r" +
    "\n" +
    "                                        <a ng-click=\"vm.assignAllSearchResultsClicked()\">Assign All</a>\r" +
    "\n" +
    "                                    </span> \r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr ng-repeat=\"openBillingEntry in vm.appliedOpenBillingEntries\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{openBillingEntry.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in openBillingEntry.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountDueClicked(openBillingEntry)\">    \r" +
    "\n" +
    "                                        {{openBillingEntry.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue > 0\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue < 0\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr ng-repeat=\"openBillingEntry in vm.filteredOpenBillingEntries\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{openBillingEntry.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in openBillingEntry.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountDueClicked(openBillingEntry)\">\r" +
    "\n" +
    "                                        {{openBillingEntry.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue > 0\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue < 0\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    ng-show=\"!vm.filteredOpenBillingEntries.length\"\r" +
    "\n" +
    "                                    colspan=\"100\" \r" +
    "\n" +
    "                                    class=\"text-large text-center opaque top-padding bottom-padding\">                        \r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>No Billing Entries Found</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"!vm.creditBillingEntry.openBillingEntries.length && !vm.creditBillingEntry.billingEntryCreditAssignments.length\"\r" +
    "\n" +
    "                        message=\"No billing entries with open balances where found\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Returned Payments\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.creditBillingEntry.returnPaymentAssignments.length\"\r" +
    "\n" +
    "                        class=\"badge left-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.creditBillingEntry.returnPaymentAssignments.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <table \r" +
    "\n" +
    "                        ng-show=\"vm.creditBillingEntry.unappliedReturnPayments.length || vm.creditBillingEntry.returnPaymentAssignments\"\r" +
    "\n" +
    "                        class=\"small top-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th>Date/Time</th>\r" +
    "\n" +
    "                                <th>Comments</th>\r" +
    "\n" +
    "                                <th>Amount</th>\r" +
    "\n" +
    "                                <th>Unapplied</th>\r" +
    "\n" +
    "                                <th>Amount Applied</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.creditBillingEntry.returnPaymentAssignments.length\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <b>Assigned Returned Payments</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr ng-repeat=\"returnPayment in vm.creditBillingEntry.returnPaymentAssignments\">\r" +
    "\n" +
    "                                <td>{{returnPayment.createdDateTime | localDateTime}}</td>\r" +
    "\n" +
    "                                <td>{{returnPayment.comments}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{returnPayment.amount | currency:\"$\"}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.paymentAmountDueClicked(returnPayment)\">\r" +
    "\n" +
    "                                        {{returnPayment.unappliedAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"returnPayment.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"/>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <b>Returned Payments</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <tr ng-repeat=\"returnPayment in vm.creditBillingEntry.unappliedReturnPayments\">\r" +
    "\n" +
    "                                <td>{{returnPayment.createdDateTime | localDateTime}}</td>\r" +
    "\n" +
    "                                <td>{{returnPayment.comments}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{returnPayment.amount | currency:\"$\"}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.paymentAmountDueClicked(returnPayment)\">\r" +
    "\n" +
    "                                        {{returnPayment.unappliedAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"returnPayment.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"/>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-show=\"!vm.creditBillingEntry.unappliedReturnPayments.length\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    colspan=\"100\"\r" +
    "\n" +
    "                                    class=\"text-large text-center opaque top-padding bottom-padding\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>No Unapplied Returned Payments</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"!vm.creditBillingEntry.unappliedReturnPayments.length && !vm.creditBillingEntry.returnPaymentAssignments\"\r" +
    "\n" +
    "                        message=\"No Returned Payments Found\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <div class=\"text-left v-center red\">\r" +
    "\n" +
    "            <div ng-show=\"vm.overAppliedAmount\">\r" +
    "\n" +
    "                The applied amount exceeds the total credit by {{vm.overAppliedAmount | currency: \"$\"}}.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                <b>You cannot proceed until this is correct.</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"vm.remainingCredit > 0\">\r" +
    "\n" +
    "                The amount applied is less than the total amount of the credit.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                <b> This will leave a credit on account in the amount of {{vm.remainingCredit | currency: \"$\"}}.</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"fill v-center\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isSubmitButtonDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Submit\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondAccountAssignmentModal/bondAccountAssignmentModal.html',
    "<div busy-indicator=\"{\r" +
    "\n" +
    "    promise: vm.processingPromise,\r" +
    "\n" +
    "    message: vm.processingMessage\r" +
    "\n" +
    "}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header  class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <h4>Bond Line Assignment</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"bondLineAssignmentForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        ng-show=\"vm.bonds\"\r" +
    "\n" +
    "        class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\"\r" +
    "\n" +
    "            class=\"alert alert-danger\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select \r" +
    "\n" +
    "            ng-show=\"!vm.errorMessage\"           \r" +
    "\n" +
    "            aa-field-group=\"vm.bondAccountId\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "            options=\"vm.bondAccountOptions\"\r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 class=\"sub-heading\">Bonds</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-class=\"{'bottom-margin' : !$last}\" \r" +
    "\n" +
    "            ng-repeat=\"bond in vm.bonds\">\r" +
    "\n" +
    "            <b>{{bond.bondNumber}}</b>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <br/>\r" +
    "\n" +
    "            {{bond.bondAmount | currency : '$'}} {{bond.bondType}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <br/>\r" +
    "\n" +
    "            {{bond.effectiveDate | dateOnly}} - {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\" >\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-disabled=\"vm.errorMessage\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondAccountModal/bondAccountModal.html',
    "<div busy-indicator=\"{\r" +
    "\n" +
    "    promise: vm.processingPromise,\r" +
    "\n" +
    "    message: vm.processingMessage\r" +
    "\n" +
    "}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header  class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <h4>Bond Line</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"bondAccountForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tabset class=\"no-margin\">\r" +
    "\n" +
    "        <uib-tab heading=\"Summary\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-9\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.status\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"bondAccountStatus\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>    \r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.carrierSystemAccountId\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        aa-label=\"Carrier\" \r" +
    "\n" +
    "                        options=\"vm.carrierOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-9\"\r" +
    "\n" +
    "                        required >\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"bond-account-modal-carrier-account-number\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.carrierAccountNumber\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input  \r" +
    "\n" +
    "                        id=\"bond-account-modal-single-limit\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.singleBondLimit\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input  \r" +
    "\n" +
    "                        id=\"bond-account-modal-aggregate-limit\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.aggregateBondLimit\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input  \r" +
    "\n" +
    "                        id=\"bond-account-modal-effective-date\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.effectiveDate\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input  \r" +
    "\n" +
    "                        id=\"bond-account-modal-expiration-date\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.expirationDate\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"bond-account-modal-rate-type\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.rateType\"\r" +
    "\n" +
    "                        aa-wrapper-class=\"top-margin-big flex col-sm-6\"\r" +
    "\n" +
    "                        aa-fg-class=\"right-margin v-center\"\r" +
    "\n" +
    "                        options=\"vm.rateTypeOptions\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"radio\" \r" +
    "\n" +
    "                        ng-change=\"vm.rateTypeChanged()\" />  \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"bond-account-modal-rate-per-thousand\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondAccount.rateType === 'RatePerThousand'\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.ratePerThousand\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-show=\"vm.bondAccount.rateType === 'Tiered'\"\r" +
    "\n" +
    "                        class=\"col-sm-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">        \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-fg-class=\"fill\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.newTierThreshold\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                                aa-label=\"Threshold\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-fg-class=\"fill left-margin\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.newTierRate\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                                aa-label=\"Rate per Thousand\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"small left-margin top-margin-auto\"\r" +
    "\n" +
    "                                ng-click=\"vm.addNewTierRate()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <table class=\"top-margin small\">\r" +
    "\n" +
    "                            <thead ng-show=\"!vm.bondAccountRateTiersInvalid\">\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th>Threshold</th>\r" +
    "\n" +
    "                                    <th>Rate per Thousand</th>\r" +
    "\n" +
    "                                    <th>&nbsp;</th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tbody>\r" +
    "\n" +
    "                                <tr \r" +
    "\n" +
    "                                    ng-class=\"{'line-through': tier.isRemoved}\"\r" +
    "\n" +
    "                                    ng-repeat=\"tier in vm.bondAccount.rateTiers\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <td>\r" +
    "\n" +
    "                                        <span ng-show=\"$index == 0\"></span>\r" +
    "\n" +
    "                                        <span ng-show=\"$index > 0\">\r" +
    "\n" +
    "                                            {{vm.bondAccount.rateTiers[$index - 1].thresholdAmount | currency:\"$\"}} -\r" +
    "\n" +
    "                                        </span>\r" +
    "\n" +
    "                                        {{tier.thresholdAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                    <td>\r" +
    "\n" +
    "                                        {{tier.ratePerThousand | currency:\"$\"}}\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                    <td class=\"text-right\">\r" +
    "\n" +
    "                                        <button ng-click=\"vm.removeTierRate(tier, $index)\">\r" +
    "\n" +
    "                                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <tr \r" +
    "\n" +
    "                                    class=\"noRecords\" \r" +
    "\n" +
    "                                    ng-show=\"vm.bondAccountRateTiersInvalid\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <td colspan=\"3\">\r" +
    "\n" +
    "                                        <span>No Tiers Assigned</span>\r" +
    "\n" +
    "                                        <p class=\"red\">You must have at least one tier for tiered rates</p>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </tbody>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <textarea\r" +
    "\n" +
    "                        style=\"height:400px; font-family: monospace !important;\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondAccount.notes\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-xs-12\">\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab\r" +
    "\n" +
    "            ng-if=\"vm.bondAccount.id\"\r" +
    "\n" +
    "            heading=\"Activities\"\r" +
    "\n" +
    "            select=\"vm.activitiesSelected()\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{\r" +
    "\n" +
    "                promise: vm.activityPromise,\r" +
    "\n" +
    "                message: 'Loading Activities...' }\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-form=\"activityListForm\"\r" +
    "\n" +
    "                class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                    ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"Search Activities\"\r" +
    "\n" +
    "                    uib-tooltip=\"Search by bond number, description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.activitiesTable.pager.totalRecordCount\"\r" +
    "\n" +
    "                    class=\"v-center small\">\r" +
    "\n" +
    "                        {{vm.activitiesTable.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <activity-list-filter-dropdown\r" +
    "\n" +
    "                    class=\"left-margin flex\"\r" +
    "\n" +
    "                    table=\"vm.activitiesTable\">\r" +
    "\n" +
    "                </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <show-activity-modal-button \r" +
    "\n" +
    "                    bond-account-id=\"vm.bondAccount.id\"\r" +
    "\n" +
    "                    customer-id=\"vm.bondAccount.customerId\"\r" +
    "\n" +
    "                    class=\"left-margin v-center\"\r" +
    "\n" +
    "                    button-class=\"mini\"\r" +
    "\n" +
    "                    on-submit=\"vm.loadActivities()\"\r" +
    "\n" +
    "                    default-options=\"New Bond Line\">\r" +
    "\n" +
    "                </show-activity-modal-button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <activity-list \r" +
    "\n" +
    "                table=\"vm.activitiesTable\"\r" +
    "\n" +
    "                bond-account-id=\"vm.bondAccount.id\"\r" +
    "\n" +
    "                customer-id=\"vm.bondAccount.customerId\"\r" +
    "\n" +
    "                on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "            </activity-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "    </uib-tabset>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\" >\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondBuilkDownloadModal/bondBulkDownloadModal.html',
    "<div busy-indicator=\"vm.processingPromise\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"exportForm\">\r" +
    "\n" +
    "    <header  class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        <h4>Download Document Packets</h4>\r" +
    "\n" +
    "        <p>Coming Soon... in an upcoming release there will be additional options below that will give you more flexability in how document sets are downloaded.</p>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        <p class=\"small\">\r" +
    "\n" +
    "            <strong>Please Note: </strong> Depending on the number of documents, it may take several minutes to gather and download these documents. \r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "                Download\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondCorrectionModal/bondCorrectionModal.html',
    "<div busy-indicator=\"{ promise: vm.processingPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"correctionForm\"\r" +
    "\n" +
    "    class=\"rider\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header  class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Bond Correction</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"text-left small\">\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-show=\"vm.bond.renewalStatus === 'ApplicationSubmitted'\" \r" +
    "\n" +
    "                    class=\"col-sm-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                        <b>Open Renewal Application</b>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        This bond has an open renewal application. Any changes to this bond will also update the renewal application. \r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <b>Note: </b> You should review all renewal quotes to ensure premium and eligibility are correct considering the changes being made.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"col-sm-12\"\r" +
    "\n" +
    "                    ng-show=\"!vm.hasChanges\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                        Bond correction must include at least one change. Please indicate what is being changed before proceeding.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                    <span>\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.selectedAttorneyInFactId\"\r" +
    "\n" +
    "                            aa-label=\"Attorney-in-Fact\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.attorneyInFactOptions\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <bond-change-manager\r" +
    "\n" +
    "                bond=\"vm.bond\"\r" +
    "\n" +
    "                changes=\"vm.changes\">\r" +
    "\n" +
    "            </bond-change-manager>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <textarea\r" +
    "\n" +
    "                rows=\"3\"\r" +
    "\n" +
    "                aa-fg-class=\"top-margin-big\"\r" +
    "\n" +
    "                aa-field-group=\"vm.comments\">\r" +
    "\n" +
    "            </textarea>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            aa-submit-form=\"vm.apply()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Apply\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondDetailModal/bondDetailModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"flex\">\r" +
    "\n" +
    "        <span class=\"v-center\">\r" +
    "\n" +
    "            {{ vm.bond.bondTypeName | limitTo:80 }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            ng-class=\"{'green': vm.bond.isElectronic, 'text-faint': !vm.bond.isElectronic}\"\r" +
    "\n" +
    "            class=\"left-margin v-center small\"\r" +
    "\n" +
    "            uib-tooltip=\"{{vm.espTooltip}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-bolt\"></i> \r" +
    "\n" +
    "            ESB\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <span ng-show=\"vm.isCarrier\">Bond Detail</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"modal-refresh-button\"\r" +
    "\n" +
    "        ng-click=\"vm.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"bondDetailForm\"\r" +
    "\n" +
    "    class=\"bond-detail text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"vm.bond.reviewRequired && !vm.isCarrier\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div class=\"fill\">\r" +
    "\n" +
    "                <b>Requires Review</b>\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    {{vm.bond.reviewComments}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"v-center charcoal\"\r" +
    "\n" +
    "                ng-click=\"vm.approveReview()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Approve\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"vm.isPendingRider\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isAgency\">\r" +
    "\n" +
    "            This bond is locked due to a pending rider request. To make changes, \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"bond-detail-edit-rider-request-button\" \r" +
    "\n" +
    "                ng-click=\"vm.showRiderRequestModal()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                edit the request\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            This bond is locked due to a pending rider request. To unlock the bond, \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                id=\"bond-detail-approve-or-deny-rider-button\" \r" +
    "\n" +
    "                ng-click=\"vm.showRiderRequestModal()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                approve or deny the request\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"vm.hasChanges\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isAgency\">\r" +
    "\n" +
    "                To save changes to this bond, \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ng-click=\"vm.showRiderRequestModal()\">\r" +
    "\n" +
    "                    request a rider\r" +
    "\n" +
    "                </a> \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                or \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ng-click=\"vm.showBondCorrectionModal()\">\r" +
    "\n" +
    "                    submit a bond correction\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "                To save changes to this bond, \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ng-click=\"vm.showRiderRequestModal()\">\r" +
    "\n" +
    "                    create a rider\r" +
    "\n" +
    "                </a> \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                or \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ng-click=\"vm.showBondCorrectionModal()\">\r" +
    "\n" +
    "                    submit a bond correction\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"vm.isPendingCancellation\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isAgency\">\r" +
    "\n" +
    "            This bond is locked due to a pending cancellation request. To make changes, \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a ng-click=\"vm.showCancellationRequestModal()\">\r" +
    "\n" +
    "                edit the request\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "                This bond is locked due to a pending cancellation request. To unlock the bond, \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ng-click=\"vm.showCancellationRequestModal()\">\r" +
    "\n" +
    "                    approve or deny the request\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"vm.isPendingReinstatement\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isAgency\">\r" +
    "\n" +
    "            This bond is locked due to a pending reinstatement request. To make changes, \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a ng-click=\"vm.showReinstatementRequestModal()\">\r" +
    "\n" +
    "                edit the request\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "            This bond is locked due to a pending reinstatement request. To unlock the bond, \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a ng-click=\"vm.showReinstatementRequestModal()\">\r" +
    "\n" +
    "                approve or deny the request\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"bond-summary\">\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div class=\"fill\">       \r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"v-center width-110\">\r" +
    "\n" +
    "                        Bond#: \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondNumber\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin v-center\"\r" +
    "\n" +
    "                        aa-input-wrapper-class=\"width-150\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondChanged('BondNumber')\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex top-margin-thin height-25\">\r" +
    "\n" +
    "                    <div class=\"width-110 v-center\">\r" +
    "\n" +
    "                        Name on Bond:\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.nameOnBond\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin v-center\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondChanged('NameOnBond')\"\r" +
    "\n" +
    "                        aa-input-wrapper-class=\"width-250\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "                </div>         \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex top-margin-thin height-25\">\r" +
    "\n" +
    "                    <div class=\"width-110 v-center\">\r" +
    "\n" +
    "                        Bond Amount: \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondAmount\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin v-center\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondChanged('BondAmount')\"\r" +
    "\n" +
    "                        aa-input-wrapper-class=\"width-100\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex top-margin-thin height-25\">\r" +
    "\n" +
    "                    <div class=\"width-110 v-center\">\r" +
    "\n" +
    "                        Current Term: \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.effectiveDate\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin v-center width-90\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondChanged('EffectiveDate')\"\r" +
    "\n" +
    "                        class=\"text-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span class=\"v-center left-margin-thin right-margin-thin\">-</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.expirationDate\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin v-center width-90\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondChanged('ExpirationDate')\"\r" +
    "\n" +
    "                        class=\"text-center\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"flex carrier text-left\"\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <img\r" +
    "\n" +
    "                        class=\"v-center\"\r" +
    "\n" +
    "                        ng-src=\"{{ vm.logoImg }}\"\r" +
    "\n" +
    "                        alt=\"{{ vm.bond.carrierName }}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"v-center\">\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{ vm.bond.carrierName }}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"small\">\r" +
    "\n" +
    "                            {{ vm.bond.writingCompanyName }}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"flex carrier\"\r" +
    "\n" +
    "                    ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <img\r" +
    "\n" +
    "                        class=\"v-center\"\r" +
    "\n" +
    "                        ng-src=\"{{ vm.logoImg }}\"\r" +
    "\n" +
    "                        alt=\"{{ vm.bond.agencyName }}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"v-center\">\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{ vm.bond.agencyName }}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-show=\"vm.bond.carrierBondAccountNumber\"\r" +
    "\n" +
    "                            class=\"small\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            Bond Line#: {{vm.bond.carrierBondAccountNumber}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"top-margin-negative\">\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"text-right text-large text-bold\" \r" +
    "\n" +
    "                    ng-bind-html=\"vm.bond | bondStatus\">\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                    Writing Company: {{ vm.bond.writingCompanyName }}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex top-margin\">\r" +
    "\n" +
    "            <tag-container \r" +
    "\n" +
    "                bond-id=\"vm.bondId\">\r" +
    "\n" +
    "            </tag-container>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <dropdown\r" +
    "\n" +
    "                ng-if=\"vm.showActions\"\r" +
    "\n" +
    "                class=\"left-margin-auto small\"\r" +
    "\n" +
    "                options=\"vm.actionDropdownOptions\"\r" +
    "\n" +
    "                button-text=\"Actions\"\r" +
    "\n" +
    "                button-class=\"small top-margin\"\r" +
    "\n" +
    "                button-id=\"bond-detail-actions-button\"\r" +
    "\n" +
    "                right-button-icon=\"fa fa-caret-down\"\r" +
    "\n" +
    "                menu-position=\"right\">\r" +
    "\n" +
    "            </dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tabset active=\"vm.activeTab\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-bond-info-tab-header\"\r" +
    "\n" +
    "            heading=\"Bond Info\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header class=\"flex\">\r" +
    "\n" +
    "                    <span class=\"v-center\">\r" +
    "\n" +
    "                        General\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                        class=\"v-center left-margin-auto mini\"\r" +
    "\n" +
    "                        ng-click=\"vm.saveServicingRoles()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Save\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </header> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex height-50 fill right-margin\"\r" +
    "\n" +
    "                        ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"width-100 bottom-margin-auto\">\r" +
    "\n" +
    "                            Customer: \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"v-center fill\">\r" +
    "\n" +
    "                            <a ng-click=\"vm.goToCustomerDetail()\">\r" +
    "\n" +
    "                                {{vm.bond.customerName}} \r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                            <div>{{vm.bond.customerLookupCode}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-show=\"vm.showBroker\"\r" +
    "\n" +
    "                        class=\"flex height-50 fill left-margin\"\r" +
    "\n" +
    "                        ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"width-100 bottom-margin-auto\">\r" +
    "\n" +
    "                            Broker: \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"v-center fill\">\r" +
    "\n" +
    "                            <a ng-click=\"vm.goToCustomerDetail()\">\r" +
    "\n" +
    "                                {{ vm.bond.brokerName }}\r" +
    "\n" +
    "                            </a>                        \r" +
    "\n" +
    "                            <div ng-show=\"vm.bond.brokerLookupCode\">{{vm.bond.brokerLookupCode}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\" \r" +
    "\n" +
    "                    class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "  \r" +
    "\n" +
    "                    <div class=\"fill flex right-margin\">\r" +
    "\n" +
    "                        <div class=\"v-center width-100\">\r" +
    "\n" +
    "                            Bond Line#: \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ng-click=\"vm.showBondAccountModal()\"\r" +
    "\n" +
    "                            ng-show=\"vm.bond.bondAccountId\"\r" +
    "\n" +
    "                            class=\"v-center\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.bond.carrierBondAccountNumber\">\r" +
    "\n" +
    "                                {{vm.bond.carrierBondAccountNumber}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i ng-show=\"!vm.bond.carrierBondAccountNumber\">\r" +
    "\n" +
    "                                Number Unknown\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            class=\"v-center\"\r" +
    "\n" +
    "                            uib-tooltip=\"Remove from bond line\"\r" +
    "\n" +
    "                            ng-show=\"vm.bond.bondAccountId\"\r" +
    "\n" +
    "                            ng-click=\"vm.showBondLineRemovalConfirmation()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-trash\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            class=\"v-center\" \r" +
    "\n" +
    "                            ng-show=\"!vm.bond.bondAccountId\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Not Assigned\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.isElectronic\"\r" +
    "\n" +
    "                        ng-change=\"vm.checkboxChangedESB()\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Electronic Surety Bond (ESB)\"\r" +
    "\n" +
    "                        aa-fg-class=\"checkbox-inline small v-center fill left-margin\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill right-margin\">\r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.producerUserId\"\r" +
    "\n" +
    "                            class=\"top-margin-big\"\r" +
    "\n" +
    "                            is-producer=\"true\"\r" +
    "\n" +
    "                            label=\"Producer\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceExecutiveUserId\"\r" +
    "\n" +
    "                            class=\"top-margin-big\"\r" +
    "\n" +
    "                            is-client-service-executive=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Executive\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div class=\"fill left-margin\">\r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceManagerUserId\"\r" +
    "\n" +
    "                            class=\"top-margin-big\"\r" +
    "\n" +
    "                            is-client-service-manager=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Manager\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>         \r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceAgentUserId\"\r" +
    "\n" +
    "                            class=\"top-margin-big\"\r" +
    "\n" +
    "                            is-client-service-agent=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Agent\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"vm.bond.importComments && !vm.isCarrier\" class=\"top-margin-big small\">\r" +
    "\n" +
    "                    <b>Import Comments:</b> \r" +
    "\n" +
    "                    <div>{{vm.bond.importComments}}</div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header>Bond Form Questions</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-repeat=\"response in bondTypeQuestions = (vm.bond.bondAdditionalQuestionResponses | filter: {originType: 'BondType'})\">\r" +
    "\n" +
    "                    <input aa-field-group=\"response.responseText\"\r" +
    "\n" +
    "                        aa-label=\"{{response.questionText}}\"\r" +
    "\n" +
    "                        ng-change=\"vm.questionResponseChanged(response)\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.disabled\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"initial-state white\"\r" +
    "\n" +
    "                    ng-show=\"!bondTypeQuestions.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <p>This bond doesn't have any bond form specific information associated to it</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <header>Underwriting Questions</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-repeat=\"response in underwritingQuestions = (vm.bond.bondAdditionalQuestionResponses | filter:{ originType: 'Underwriting' })\">\r" +
    "\n" +
    "                    <span>{{ response.questionText }}</span> <b>{{ response.responseText }}</b>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"initial-state white\"\r" +
    "\n" +
    "                    ng-show=\"!underwritingQuestions.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <p>No underwriting questions were asked</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-principal-tab-header\"\r" +
    "\n" +
    "            heading=\"Principal\"\r" +
    "\n" +
    "            select=\"vm.loadPrincipal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{ promise: vm.principalPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-repeat=\"c in vm.companies\"\r" +
    "\n" +
    "                class=\"bottom-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <company \r" +
    "\n" +
    "                    company=\"c\"\r" +
    "\n" +
    "                    changed=\"vm.companyChanged(company, changedProperty)\"\r" +
    "\n" +
    "                    is-disabled=\"vm.disabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <company-heading>\r" +
    "\n" +
    "                        <span>Company Information</span>\r" +
    "\n" +
    "                    </company-heading>\r" +
    "\n" +
    "                </company>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-repeat=\"p in vm.people\"\r" +
    "\n" +
    "                class=\"bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <person \r" +
    "\n" +
    "                    person=\"p\"\r" +
    "\n" +
    "                    changed=\"vm.personChanged(person, changedProperty)\"\r" +
    "\n" +
    "                    is-disabled=\"vm.disabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.bond.customerType == 'Individual'\">Personal Information</span>\r" +
    "\n" +
    "                    <span ng-show=\"vm.bond.customerType == 'Company'\">Business Owner</span>\r" +
    "\n" +
    "                </person>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-transactions-tab-header\"\r" +
    "\n" +
    "            heading=\"Transactions\"\r" +
    "\n" +
    "            select=\"vm.loadTransactions()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{ promise: vm.transactionPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <bond-transaction-list \r" +
    "\n" +
    "                ng-if=\"vm.transactions\"\r" +
    "\n" +
    "                transactions=\"vm.transactions\"\r" +
    "\n" +
    "                on-transaction-billing-entries-saved=\"vm.transactionBillingEntriesSaved()\">\r" +
    "\n" +
    "            </bond-transaction-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            select=\"vm.memosTabSelected()\"\r" +
    "\n" +
    "            heading=\"Memos\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{ promise: vm.memoLoadingPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <memo-list \r" +
    "\n" +
    "                table=\"vm.memosTable\"\r" +
    "\n" +
    "                bond-id=\"vm.bond.id\">\r" +
    "\n" +
    "            </memo-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-attachments-tab-header\"\r" +
    "\n" +
    "            heading=\"Attachments\"\r" +
    "\n" +
    "            select=\"vm.attachmentTabSelected()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{ \r" +
    "\n" +
    "                promise: vm.attachmentPromise,\r" +
    "\n" +
    "                message: 'Loading Attachments...'}\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-form=\"attachmentsForm\"\r" +
    "\n" +
    "                class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.attachmentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                    ng-change=\"vm.attachmentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"Search Attachments\"\r" +
    "\n" +
    "                    uib-tooltip=\"Search by filename\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.attachmentsTable.pager.totalRecordCount\"\r" +
    "\n" +
    "                    class=\"v-center small\">\r" +
    "\n" +
    "                        {{vm.attachmentsTable.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    class=\"left-margin flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <attachment-list-filter-dropdown \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        table=\"vm.attachmentsTable\">\r" +
    "\n" +
    "                    </attachment-list-filter-dropdown>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <show-activity-modal-button \r" +
    "\n" +
    "                        bond-id=\"vm.bondId\"\r" +
    "\n" +
    "                        customer-id=\"vm.bond.customerId\"\r" +
    "\n" +
    "                        class=\"left-margin v-center\"\r" +
    "\n" +
    "                        button-class=\"mini\"\r" +
    "\n" +
    "                        on-submit=\"vm.loadAttachments()\"\r" +
    "\n" +
    "                        default-options=\"attachment\">\r" +
    "\n" +
    "                    </show-activity-modal-button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                    ng-click=\"vm.showAddAttachmentModal()\" \r" +
    "\n" +
    "                    uib-tooltip=\"Upload Attachment\"\r" +
    "\n" +
    "                    class=\"left-margin mini\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <attachment-list\r" +
    "\n" +
    "                table=\"vm.attachmentsTable\"\r" +
    "\n" +
    "                on-attachment-modified=\"vm.loadAttachments()\" />\r" +
    "\n" +
    "            </attachment-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-obligee-tab-header\"\r" +
    "\n" +
    "            select=\"vm.loadObligee()\"\r" +
    "\n" +
    "            heading=\"Obligee\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{ promise: vm.obligeePromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <obligee-form \r" +
    "\n" +
    "                is-disabled=\"vm.disabled\"\r" +
    "\n" +
    "                changed=\"vm.obligeeChanged(entity, changedProperty)\"\r" +
    "\n" +
    "                entryMode=\"application\"\r" +
    "\n" +
    "                obligee=\"vm.obligee\">\r" +
    "\n" +
    "            </obligee-form>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab \r" +
    "\n" +
    "            id=\"modal-bond-detail-credit-reports-tab-header\"\r" +
    "\n" +
    "            heading=\"Credit Reports\"\r" +
    "\n" +
    "            select=\"vm.loadCreditReports()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.creditReportsPromise}\"></div>\r" +
    "\n" +
    "            <credit-report-list people=\"vm.creditReportPeople\" />\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab\r" +
    "\n" +
    "            id=\"modal-bond-detail-activities-tab-header\"\r" +
    "\n" +
    "            heading=\"Activities\"\r" +
    "\n" +
    "            select=\"vm.activityTabSelected()\"\r" +
    "\n" +
    "            ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div busy-indicator=\"{\r" +
    "\n" +
    "                promise: vm.activityPromise,\r" +
    "\n" +
    "                message: 'Loading Activities...' }\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-form=\"activityListForm\"\r" +
    "\n" +
    "                class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                    ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"Search Activities\"\r" +
    "\n" +
    "                    uib-tooltip=\"Search by bond number, description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.activitiesTable.pager.totalRecordCount\"\r" +
    "\n" +
    "                    class=\"v-center small\">\r" +
    "\n" +
    "                        {{vm.activitiesTable.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <activity-list-filter-dropdown\r" +
    "\n" +
    "                    class=\"left-margin flex\"\r" +
    "\n" +
    "                    table=\"vm.activitiesTable\">\r" +
    "\n" +
    "                </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <show-activity-modal-button \r" +
    "\n" +
    "                    bond-id=\"vm.bondId\"\r" +
    "\n" +
    "                    customer-id=\"vm.bond.customerId\"\r" +
    "\n" +
    "                    class=\"left-margin v-center\"\r" +
    "\n" +
    "                    button-class=\"mini\"\r" +
    "\n" +
    "                    on-submit=\"vm.loadActivities()\">\r" +
    "\n" +
    "                </show-activity-modal-button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <activity-list \r" +
    "\n" +
    "                table=\"vm.activitiesTable\"\r" +
    "\n" +
    "                customer-id=\"vm.bond.customerId\"\r" +
    "\n" +
    "                bond-id=\"vm.bondId\"\r" +
    "\n" +
    "                on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "            </activity-list>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab\r" +
    "\n" +
    "            id=\"modal-bond-detail-renewal-tab-header\"\r" +
    "\n" +
    "            heading=\"Renewal Quotes\"\r" +
    "\n" +
    "            ng-if=\"!vm.isCarrier && vm.bond.renewalStatus === 'ApplicationSubmitted'\"\r" +
    "\n" +
    "            select=\"vm.loadRenewalQuotes()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div busy-indicator=\"{promise:vm.renewalQuotesPromise}\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"top-margin\">\r" +
    "\n" +
    "                <quote-list \r" +
    "\n" +
    "                    ng-if=\"vm.renewalApplicationId\"\r" +
    "\n" +
    "                    quotes=\"vm.renewalQuotes\" \r" +
    "\n" +
    "                    writing-company-id=\"vm.bond.writingCompanyId\"\r" +
    "\n" +
    "                    application-id=\"vm.renewalApplicationId\" \r" +
    "\n" +
    "                    quotes-updated=\"vm.loadRenewalQuotes()()\" \r" +
    "\n" +
    "                    on-purchase=\"vm.renewalQuotePurchased(bondId)\" \r" +
    "\n" +
    "                    is-application-declined=\"vm.isDeclined\"\r" +
    "\n" +
    "                    show-auto-quote-button=\"{true}\"\r" +
    "\n" +
    "                    on-auto-quote-clicked=\"vm.loadRenewalQuotes()\">\r" +
    "\n" +
    "                </quote-list> \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </uib-tabset>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<footer class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!vm.isCarrier && vm.bond.legacyId\" \r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Legacy Id:\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"!vm.bond.legacyUrl\">\r" +
    "\n" +
    "            {{vm.bond.legacyId}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            href=\"{{vm.bond.legacyUrl}}\"\r" +
    "\n" +
    "            ng-show=\"vm.bond.legacyUrl\"\r" +
    "\n" +
    "            target=\"_blank\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.bond.legacyId}}\r" +
    "\n" +
    "            <i class=\"fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        id=\"modal-bond-detail-close-button\"\r" +
    "\n" +
    "        class=\"white v-center left-margin-auto\"\r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Close\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/modals/bondTransactionHistoryModal/bondTransactionHistoryModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header  class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    <b>Bond Transaction History</b>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-form=\"historySearchForm\"\r" +
    "\n" +
    "        class=\"bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                aa-field-group=\"vm.bondTransactionHistoryTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                ng-change=\"vm.bondTransactionHistoryTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                aa-label=\"Search History\" \r" +
    "\n" +
    "                focus-me />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div ng-show=\"vm.bondTransactionHistoryTable.isVisible\">\r" +
    "\n" +
    "        <table class=\"bottom-margin\">\r" +
    "\n" +
    "            <thead>               \r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>               \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Saved On\"\r" +
    "\n" +
    "                            sort-expression=\"CreatedDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.bondTransactionHistoryTable.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>                  \r" +
    "\n" +
    "                    <th>Changes</th>\r" +
    "\n" +
    "                </tr>  \r" +
    "\n" +
    "            </thread>      \r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"historyEntry in vm.bondTransactionHistoryTable.data\">\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <user-avatar                              \r" +
    "\n" +
    "                                user-id=\"historyEntry.userId\"\r" +
    "\n" +
    "                                class=\"mini v-center right-margin\">\r" +
    "\n" +
    "                            </user-avatar>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"v-center nowrap\">\r" +
    "\n" +
    "                                <b ng-bind-html=\"historyEntry.createdByUserFullName | highlight:vm.currentSearchPhrase\"></b>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                <span class=\"small\">{{historyEntry.createdDateTime | dateOnly}}</span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                 \r" +
    "\n" +
    "                    <td class=\"small text-left\" colspan=\"2\">\r" +
    "\n" +
    "                        <div ng-repeat=\"noteItem in historyEntry.bondTransactionHistoryNotes track by $index\">\r" +
    "\n" +
    "                            <span ng-bind-html=\"noteItem.note | highlight:vm.currentSearchPhrase\"></span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.bondTransactionHistoryTable.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.bondTransactionHistoryTable.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No history entries found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondTypeHistoryModal/bondTypeHistoryModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    <b>Bond Type History</b>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-form=\"historySearchForm\"\r" +
    "\n" +
    "        class=\"bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                aa-field-group=\"vm.bondTypeHistoryTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                ng-change=\"vm.bondTypeHistoryTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                aa-label=\"Search History\" \r" +
    "\n" +
    "                focus-me />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div ng-show=\"vm.bondTypeHistoryTable.isVisible\">\r" +
    "\n" +
    "        <table class=\"bottom-margin\">\r" +
    "\n" +
    "            <thead>               \r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>               \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Saved On\"\r" +
    "\n" +
    "                            sort-expression=\"CreatedDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.bondTypeHistoryTable.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>                  \r" +
    "\n" +
    "                    <th>Changes</th>\r" +
    "\n" +
    "                </tr>  \r" +
    "\n" +
    "            </thread>      \r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"historyEntry in vm.bondTypeHistoryTable.data\">\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <user-avatar                              \r" +
    "\n" +
    "                                user-id=\"historyEntry.userId\"\r" +
    "\n" +
    "                                class=\"mini v-center right-margin\">\r" +
    "\n" +
    "                            </user-avatar>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"v-center nowrap\">\r" +
    "\n" +
    "                                <b ng-bind-html=\"historyEntry.createdByUserFullName | highlight:vm.currentSearchPhrase\"></b>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                <span class=\"small\">{{historyEntry.createdDateTime | dateOnly}}</span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                 \r" +
    "\n" +
    "                    <td class=\"small text-left\" colspan=\"2\">\r" +
    "\n" +
    "                        <div ng-repeat=\"noteItem in historyEntry.bondTypeHistoryNotes track by $index\">\r" +
    "\n" +
    "                            <span ng-bind-html=\"noteItem.note | highlight:vm.currentSearchPhrase\"></span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.bondTypeHistoryTable.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.bondTypeHistoryTable.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No history entries found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondTypeRequestModal/bondTypeRequestModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Request New Bond Type</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <header>Bond Form</header>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "        <uploader \r" +
    "\n" +
    "            controls=\"vm.uploaderControls\"\r" +
    "\n" +
    "            files=\"vm.bondFormFiles\">\r" +
    "\n" +
    "        </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"vm.bondFormFiles[0].file.name\"\r" +
    "\n" +
    "            class=\"top-margin flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span>\r" +
    "\n" +
    "                    <b>Bond Form:</b> \r" +
    "\n" +
    "                    {{vm.bondFormFiles[0].file.name}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    ng-click=\"vm.removeFile()\"\r" +
    "\n" +
    "                    class=\"left-margin v-center\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Remove\r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </strong>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bond-type-request request=\"vm.request\"></bond-type-request>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit_click()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/bondTypeSelectionModal/bondTypeSelectionModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"attachmentViewerForm\" \r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Select Bond Type</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <bond-type-selection \r" +
    "\n" +
    "        on-select=\"vm.bondTypeSelected(bondTypeId)\">\r" +
    "\n" +
    "    </bond-type-selection>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"top-margin flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"left-margin-auto white v-center\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/brokerAssignmentModal/brokerAssignmentModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <h4>Customer Broker Assignment</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <ui-select \r" +
    "\n" +
    "            ng-model=\"vm.selectedBroker\" \r" +
    "\n" +
    "            ng-init=\"$select.label = 'Search Broker Accounts...'\" \r" +
    "\n" +
    "            reset-search-input=\"false\" \r" +
    "\n" +
    "            name=\"brokerSearch\" \r" +
    "\n" +
    "            required \r" +
    "\n" +
    "            theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ui-select-match \r" +
    "\n" +
    "                placeholder=\"Search broker accounts...\" \r" +
    "\n" +
    "                label=\"Broker Account\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{$select.selected.name}}\r" +
    "\n" +
    "            </ui-select-match>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <ui-select-choices \r" +
    "\n" +
    "                repeat=\"account in vm.accountSearchResults track by $index\" \r" +
    "\n" +
    "                refresh=\"vm.searchEProducerAccounts($select.search)\" \r" +
    "\n" +
    "                refresh-delay=\"100\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span ng-bind-html=\"account.name | highlight: $select.search\"></span> - {{account.physicalCity}}, {{account.physicalState}} {{account.physicalZip}}\r" +
    "\n" +
    "            </ui-select-choices>\r" +
    "\n" +
    "        </ui-select>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/cancellationRequestModal/cancellationRequestModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>\r" +
    "\n" +
    "            <span>Cancel Bond</span>\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <uib-tabset ng-show=\"!vm.confirmRescind && !vm.confirmDecline && !vm.confirmRequest\">\r" +
    "\n" +
    "        <uib-tab heading=\"Cancellation Detail\">\r" +
    "\n" +
    "            <section class=\"text-left small\">\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"col-sm-12\"\r" +
    "\n" +
    "                        ng-show=\"!!vm.validationMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"alert alert-danger\">{{ vm.validationMessage }}</div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                        class=\"col-sm-12 bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.agentSubmissionType\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center right-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                            options=\"vm.agentCancelSubmissionTypeOptions\"\r" +
    "\n" +
    "                            aa-wrapper-class=\"no-margin flex\" \r" +
    "\n" +
    "                            required />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"row bottom-margin-big\"\r" +
    "\n" +
    "                    ng-show=\"vm.isCarrier && vm.request.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                Requested Cancellation On:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                <b>{{ vm.request.requestedCancellationDate | dateOnly }}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                Current term:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                {{ vm.mostRecentTermBondTransaction.effectiveDate | dateOnly }} - {{ vm.mostRecentTermBondTransaction.expirationDate | dateOnly }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                Premium on current term:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                {{ vm.mostRecentTermBondTransaction.termTotalPremium | currency:'$' }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-show=\"vm.showRefundAdjustments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                    Adjusted Total Premium:\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                    <b>{{ vm.mostRecentTermBondTransaction.termTotalPremium - vm.request.premiumRefund | currency:'$' }}</b>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                class=\"col-xs-12\"\r" +
    "\n" +
    "                                ng-show=\"vm.request.agencyComments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Agency Comments: \r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    <b>{{ vm.request.agencyComments }}</b>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-md-6 requested-by\">\r" +
    "\n" +
    "                        Requested By: \r" +
    "\n" +
    "                        <br />\r" +
    "\n" +
    "                        <b>{{ vm.request.user.fullName }}</b> \r" +
    "\n" +
    "                        <br />\r" +
    "\n" +
    "                        {{ vm.request.user.email }} \r" +
    "\n" +
    "                        <br />\r" +
    "\n" +
    "                        {{ vm.request.user.systemAccount.phone }}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-6\"\r" +
    "\n" +
    "                            ng-if=\"!vm.isCarrier && vm.agentSubmissionType != 'Execute'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.request.requestedCancellationDate\"\r" +
    "\n" +
    "                                aa-label=\"Requested Cancellation Date\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-if=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.request.effectiveDate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-label=\"Cancellation Date\"\r" +
    "\n" +
    "                                ng-blur=\"vm.calculateRefund()\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-if=\"!vm.isCarrier || (vm.isCarrier && (!vm.request.cancellationStatus || vm.request.cancellationStatus === 'None'))\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select aa-field-group=\"vm.request.attorneyInFactUserId\"\r" +
    "\n" +
    "                                aa-label=\"Attorney-in-Fact\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.aifOptions\"\r" +
    "\n" +
    "                                ng-required=\"true\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"top-margin\">\r" +
    "\n" +
    "                    <div ng-if=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\"\r" +
    "\n" +
    "                            class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select aa-field-group=\"vm.cancellationReasonId\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                aa-unselected-text=\"Cancellation Reason\"\r" +
    "\n" +
    "                                options=\"vm.cancellationReasons\"\r" +
    "\n" +
    "                                aa-option-value=\"id\"\r" +
    "\n" +
    "                                aa-option-text=\"description\"\r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select aa-field-group=\"vm.refundType\"\r" +
    "\n" +
    "                                aa-label=\"Premium Adjustment\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.refundOptions\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                                ng-change=\"vm.calculateRefund()\"\r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"col-md-6\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"col-md-12\"\r" +
    "\n" +
    "                                ng-show=\"vm.showRefundAdjustments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"row\">\r" +
    "\n" +
    "                                <input aa-field-group=\"vm.request.premiumRefund\"\r" +
    "\n" +
    "                                        aa-label=\"Returned Premium\"\r" +
    "\n" +
    "                                        aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <input aa-field-group=\"vm.request.commissionReduction\"\r" +
    "\n" +
    "                                        aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <textarea rows=\"5\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.carrierComments\"\r" +
    "\n" +
    "                                aa-label=\"Comments\"\r" +
    "\n" +
    "                                ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <textarea rows=\"5\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.agencyComments\"\r" +
    "\n" +
    "                                aa-label=\"Comments\"\r" +
    "\n" +
    "                                ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tab  \r" +
    "\n" +
    "            heading=\"Fees ({{vm.totalFees | currency:'$'}})\"\r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section class=\"text-left small\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                <fee-list \r" +
    "\n" +
    "                    fees=\"vm.request.fees\"\r" +
    "\n" +
    "                    total=\"vm.totalFees\">\r" +
    "\n" +
    "                </fee-list>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </uib-tab>\r" +
    "\n" +
    "    </uib-tabset>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"charcoal pull-left\"\r" +
    "\n" +
    "            ng-click=\"vm.rescindRequest()\"\r" +
    "\n" +
    "            ng-show=\"vm.request.id && !vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Rescind Request\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.close()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"modal-request-cancellation-submit-button\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-show=\"vm.isSubmitting\">Submit</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isUpdating\">Update</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isCreating\">Cancel Bond</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isApproving\">Approve Request</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/changePasswordModal/changePasswordModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"updatePasswordForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <h4 class=\"modal-title\">Let's change your password</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.password\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "            ng-pattern=\"/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/\"\r" +
    "\n" +
    "            ng-pattern-msg=\"Password requires 1 upper-case, 1 lower-case, 1 numeric and must be at least 8 characters long\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.confirmPassword\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "            ng-match=\"vm.password\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\"\r" +
    "\n" +
    "            type=\"button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Nevermind\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Update Password\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/closeApplicationModal/closeApplicationModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <h4>Close Out Application</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section class=\"text-left\">\r" +
    "\n" +
    "    If the application is reopened, it will have to be resubmitted.\r" +
    "\n" +
    "    Are you sure you want to close out this application?\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<footer>\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"charcoal\" \r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Cancel\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button ng-click=\"vm.closeApplication()\">\r" +
    "\n" +
    "        Close Application\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/modals/confirmationModal/confirmationModal.html',
    "<div busy-indicator=\"vm.processingPromise\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form name=\"deleteForm\">\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>{{vm.header}}</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <span ng-bind-html=\"vm.message\"></span>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{vm.cancelButtonText}}\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            aa-submit-form=\"vm.confirmClicked()\" \r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            {{vm.confirmButtonText}}\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/modals/creditReportModal/creditReportModal.html',
    "<div \r" +
    "\n" +
    "    ng-form \r" +
    "\n" +
    "    name=\"creditReportForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Credit Report</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"vm.processingPromise\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"credit-report-form\" \r" +
    "\n" +
    "        ng-show=\"vm.creditReport.bureau==='EquifaxV6' && !vm.creditReport.reportNotFound\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>\r" +
    "\n" +
    "            {{vm.creditReport.usHeader.usConsumerSubject.personName.firstName}}\r" +
    "\n" +
    "            {{(vm.creditReport.usHeader.usConsumerSubject.personName.middleName ? vm.creditReport.usHeader.usConsumerSubject.personName.middleName + ' ' : '')}}\r" +
    "\n" +
    "            {{vm.creditReport.usHeader.usConsumerSubject.personName.lastName}}\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"pull-right\" \r" +
    "\n" +
    "                ng-show=\"vm.creditReport.$scoreResult != 4 && vm.creditReport.$scoreResult != 1\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Credit Score: \r" +
    "\n" +
    "                <b>{{vm.creditReport.$scoreResult}}</b> \r" +
    "\n" +
    "                <help message=\"Score calculated using the Vantange v3.0 credit model\"></help>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"pull-right\" \r" +
    "\n" +
    "                ng-show=\"vm.creditReport.$scoreResult == 4\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Credit Score: \r" +
    "\n" +
    "                <b class=\"red\">Insufficient Report Data</b> \r" +
    "\n" +
    "                <help message=\"Not enought activity to generate credit score using the Vantage v3.0 credit model\"></help>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"pull-right\" \r" +
    "\n" +
    "                ng-show=\"vm.creditReport.$scoreResult == 1\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Credit Score: \r" +
    "\n" +
    "                <b class=\"red\">Deceased</b> \r" +
    "\n" +
    "                <help message=\"This individual has been reported as being dceased\"></help>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row top-margin bottom-margin\">\r" +
    "\n" +
    "            <div class=\"col-sm-4\">\r" +
    "\n" +
    "                Credit Pull Date: {{vm.creditReport.usHeader.usCreditFile.dateOfRequest.text}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"col-sm-4 text-center\">\r" +
    "\n" +
    "                File Since Date: {{vm.creditReport.usHeader.usCreditFile.fileSinceDate.text}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                Last date of activity: {{vm.creditReport.usHeader.usCreditFile.dateOfLastActivity.text}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset>\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>Score Factors</uib-tab-heading>\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <div ng-repeat=\"score in vm.creditReport.usModelScores.usModelScore[0].scoreReasons.scoreReason\">\r" +
    "\n" +
    "                        <span>{{score.description}}</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <empty-state \r" +
    "\n" +
    "                        message=\"No negative factors to highlight on this report\" \r" +
    "\n" +
    "                        ng-show=\"!vm.creditReport.usModelScores.usModelScore[0].scoreReasons.scoreReason\" \r" +
    "\n" +
    "                        icon=\"fa-thumbs-up\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>Reported Addresses</uib-tab-heading>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-repeat=\"address in vm.creditReport.usAddresses.usAddress\" \r" +
    "\n" +
    "                    class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div class=\"col-sm-6\">\r" +
    "\n" +
    "                            <b>{{address.description}}</b>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                            {{address.dateAddressFirstReported.text + ' - ' + address.dateAddressLastReported.text}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-12\">\r" +
    "\n" +
    "                            {{address.streetNumber + ' ' + address.streetName + ' ' + address.streetType}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-12\">\r" +
    "\n" +
    "                            {{address.city.text + ', ' + address.state + ' ' + address.postalCode}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"!vm.creditReport.usAddresses.usAddress.length\">\r" +
    "\n" +
    "                    <empty-state message=\"No addresses were found on this report\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Public Records \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.creditReport.$bankruptcies.length || vm.creditReport.$taxLiens.length\" \r" +
    "\n" +
    "                        class=\"badge red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{(vm.creditReport.$bankruptcies.length || 0) +(vm.creditReport.$taxLiens.length || 0)}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 \r" +
    "\n" +
    "                    ng-class=\"{'top-margin-super' : !$first}\" \r" +
    "\n" +
    "                    class=\"bottom-margin\" \r" +
    "\n" +
    "                    ng-show=\"vm.creditReport.$bankruptcies.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Bankruptcies\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-show=\"vm.creditReport.$bankruptcies.length\" \r" +
    "\n" +
    "                    ng-class=\"{'collapsed': !b.$expanded}\" \r" +
    "\n" +
    "                    ng-repeat=\"b in vm.creditReport.$bankruptcies\" \r" +
    "\n" +
    "                    class=\"small text-left cursor-pointer\" \r" +
    "\n" +
    "                    ng-init=\"b.$expanded = false\" \r" +
    "\n" +
    "                    ng-click=\"b.$expanded = !b.$expanded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div class=\"col-sm-8\">\r" +
    "\n" +
    "                                <i \r" +
    "\n" +
    "                                    class=\"fa orange small right-margin pull-left expander\" \r" +
    "\n" +
    "                                    ng-class=\"{'fa-plus': !b.$expanded, 'fa-minus': b.$expanded}\">\r" +
    "\n" +
    "                                </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <b>{{b.disposition}}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                                {{b.dateFiled}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div ng-show=\"b.$expanded\">\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <b>Details</b>\r" +
    "\n" +
    "                                <div class=\"row\">\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date Filed</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.dateFiled}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Customer Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.customerNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Court Name</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.courtName}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Industry</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.industry}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Case Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.caseNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Type</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.type}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Filer</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.filer}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Disposition</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.disposition}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Liability Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.liabilityAmount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Asset Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.assetAmount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Exempt Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.exemptAmount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Verification Date</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.verificationDate}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Prior Intent Code</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.priorIntentCode}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date Reported</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{b.dateReported}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <b>Narratives</b>\r" +
    "\n" +
    "                                <div class=\"row\">\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                        <ul>\r" +
    "\n" +
    "                                            <li ng-repeat=\"narrative in b.narratives\">{{narrative}}</li>\r" +
    "\n" +
    "                                        </ul>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <h5 \r" +
    "\n" +
    "                    ng-class=\"{'top-margin-super' : !$first}\" \r" +
    "\n" +
    "                    class=\"bottom-margin\" \r" +
    "\n" +
    "                    ng-show=\"vm.creditReport.$taxLiens.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Tax Liens\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-show=\"vm.creditReport.$taxLiens.length\" \r" +
    "\n" +
    "                    ng-class=\"{'collapsed': !t.$expanded}\" \r" +
    "\n" +
    "                    ng-repeat=\"t in vm.creditReport.$taxLiens\" \r" +
    "\n" +
    "                    class=\"small text-left cursor-pointer\" \r" +
    "\n" +
    "                    ng-init=\"t.$expanded = false\" \r" +
    "\n" +
    "                    ng-click=\"t.$expanded = !t.$expanded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div class=\"col-sm-8\">\r" +
    "\n" +
    "                                <i \r" +
    "\n" +
    "                                    class=\"fa orange small right-margin pull-left top-margin expander\" \r" +
    "\n" +
    "                                    ng-class=\"{'fa-plus': !t.$expanded, 'fa-minus': t.$expanded}\">\r" +
    "\n" +
    "                                </i>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <b>{{t.lienClass}}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                                {{t.dateFiled}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-sm-12\">\r" +
    "\n" +
    "                                {{t.courtName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div ng-show=\"t.$expanded\">\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <b>Details</b>\r" +
    "\n" +
    "                                <div class=\"row\">\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date Filed</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.dateFiled}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Customer Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.customerNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Court Name</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.courtName}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Industry</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.industry}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Case Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.caseNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.amount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Lien Class</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.lienClass}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Release Date</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.releaseDate}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Lien Status</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.lienStatus}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date Reported</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{t.dateReported}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <div ng-show=\"t.narratives\">\r" +
    "\n" +
    "                                    <b>Narratives</b>\r" +
    "\n" +
    "                                    <div class=\"row\">\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                            <ul>\r" +
    "\n" +
    "                                                <li ng-repeat=\"narrative in t.narratives\">{{narrative}}</li>\r" +
    "\n" +
    "                                            </ul>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"!vm.creditReport.$bankruptcies.length && !vm.creditReport.$taxLiens.length\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <empty-state \r" +
    "\n" +
    "                        message=\"No public records were found on this report\" \r" +
    "\n" +
    "                        icon=\"fa-thumbs-up\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>Accounts</uib-tab-heading>\r" +
    "\n" +
    "                <div ng-repeat=\"(key,c) in vm.creditReport.$accts\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                    <h5 \r" +
    "\n" +
    "                        ng-class=\"{'top-margin-super' : !$first}\" \r" +
    "\n" +
    "                        class=\"bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"key === '--' || !key\">Unknown Account Type</span>\r" +
    "\n" +
    "                        <span ng-show=\"key !== '--'\">{{key}}</span>\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section \r" +
    "\n" +
    "                        ng-class=\"{'collapsed': !a.$expanded}\" \r" +
    "\n" +
    "                        ng-repeat=\"a in c\" \r" +
    "\n" +
    "                        class=\"small text-left cursor-pointer\" \r" +
    "\n" +
    "                        ng-init=\"a.$expanded = false\" \r" +
    "\n" +
    "                        ng-click=\"a.$expanded = !a.$expanded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            <div class=\"row\">\r" +
    "\n" +
    "                                <div class=\"col-sm-8\">\r" +
    "\n" +
    "                                    <i \r" +
    "\n" +
    "                                        class=\"fa orange small right-margin pull-left top-margin expander\" \r" +
    "\n" +
    "                                        ng-class=\"{'fa-plus': !a.$expanded, 'fa-minus': a.$expanded}\">\r" +
    "\n" +
    "                                    </i>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        <b>{{a.creditorName}}</b>\r" +
    "\n" +
    "                                        <div class=\"small\">\r" +
    "\n" +
    "                                            Status: <b>{{a.accountStatus}}</b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                                    Balance: <b>{{a.balance}}</b>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div ng-show=\"a.$expanded\">\r" +
    "\n" +
    "                                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                    <b>Account Details</b>\r" +
    "\n" +
    "                                    <div class=\"row\">\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Last Reported</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.lastReported}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Creditor Name</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.creditorName}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Account Type</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.accountType}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Opened Date</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.openedDate}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Closed Date</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.closedDate}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Limit</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.limit}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Term</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.term}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Monthly Payment</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.montlyPayment}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Responsibility</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.responsibility}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Highest Balance</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.highestBalance}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Payment Status</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.term}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6\">Date of Last Payment</div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                            <b>{{a.dateOfLastPayment}} </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                    <span class=\"bottom-margin-small\">\r" +
    "\n" +
    "                                        <b>Payment History</b>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <div class=\"row\">\r" +
    "\n" +
    "                                        <div class=\"col-sm-12 bottom-margin-big\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                            <table \r" +
    "\n" +
    "                                                class=\"payment-history-table\" \r" +
    "\n" +
    "                                                ng-show=\"!!a.paymentHistory && a.paymentHistory.length > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <tr ng-repeat=\"y in a.paymentHistory\">\r" +
    "\n" +
    "                                                    \r" +
    "\n" +
    "                                                    <td \r" +
    "\n" +
    "                                                        class=\"payment-history-table-year\" \r" +
    "\n" +
    "                                                        ng-bind=\"y.year\">\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "                                                    \r" +
    "\n" +
    "                                                    <td ng-repeat=\"item in y.history\">\r" +
    "\n" +
    "                                                        <span \r" +
    "\n" +
    "                                                            ng-class=\"item.value.css\" \r" +
    "\n" +
    "                                                            ng-bind=\"item.value.text\">\r" +
    "\n" +
    "                                                        </span>\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "                                                </tr>\r" +
    "\n" +
    "                                                <tr>\r" +
    "\n" +
    "                                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                                    <td ng-repeat=\"m in a.paymentHistory[0].history\">{{m.month}}</td>\r" +
    "\n" +
    "                                                </tr>\r" +
    "\n" +
    "                                            </table>\r" +
    "\n" +
    "                                            <div ng-show=\"!a.paymentHistory || a.paymentHistory.length < 1\">\r" +
    "\n" +
    "                                                <empty-state message=\"No payment history found\"></empty-state>\r" +
    "\n" +
    "                                            </div>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-12 top-margin-big bottom-margin-big\">\r" +
    "\n" +
    "                                            <div class=\"row\">\r" +
    "\n" +
    "                                                <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                                    Credit Utilization\r" +
    "\n" +
    "                                                </div>\r" +
    "\n" +
    "                                                <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                                    <b>{{a.creditUtilization}} %</b>\r" +
    "\n" +
    "                                                </div>\r" +
    "\n" +
    "                                            </div>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-12  top-margin-big\">\r" +
    "\n" +
    "                                            <b>Creditor Contact Details</b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                        <div class=\"col-sm-12 bottom-margin-big\">\r" +
    "\n" +
    "                                            {{a.creditorInformation}}\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <section ng-show=\"!vm.creditReport.usTrades.usTrade.length\">\r" +
    "\n" +
    "                    <empty-state message=\"No accounts were found on this report\"></empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Collections \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.creditReport.$collections.length\" \r" +
    "\n" +
    "                        class=\"badge red\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{vm.creditReport.$collections.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-show=\"vm.creditReport.$collections.length\" \r" +
    "\n" +
    "                    ng-class=\"{'collapsed': !c.$expanded}\" \r" +
    "\n" +
    "                    ng-repeat=\"c in vm.creditReport.$collections\" \r" +
    "\n" +
    "                    class=\"small text-left cursor-pointer\" \r" +
    "\n" +
    "                    ng-init=\"c.$expanded = false\" \r" +
    "\n" +
    "                    ng-click=\"c.$expanded = !c.$expanded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div class=\"col-sm-8\">\r" +
    "\n" +
    "                                <i \r" +
    "\n" +
    "                                    class=\"fa orange small right-margin pull-left expander\" \r" +
    "\n" +
    "                                    ng-class=\"{'fa-plus': !c.$expanded, 'fa-minus': c.$expanded}\">\r" +
    "\n" +
    "                                </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <b><span ng-bind-html=\"c | collectionHeader\"></span></b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                                {{c.dateReported}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div ng-show=\"c.$expanded\">\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <b>Details</b>\r" +
    "\n" +
    "                                <div class=\"row\">\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date Reported</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.dateReported}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Assigned Date</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.assignedDate}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Customer Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.customerNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Court Name</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.courtName}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Industry</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.industry}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Client Name / Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.clientNameNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Account Number</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.accountNumber}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Original Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.originalAmount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Balance Amount</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.balanceAmount}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Balance Date</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.balanceDate}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date of Last Payment</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.dateOfLastPayment}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Status</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.status}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Status Date</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.statusDate}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Date of First Delinquency</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.dateOfFirstDelinquency}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Account Designator</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.accountDesignator}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Creditor Classification</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.creditorClassification}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">Update Indicator</div>\r" +
    "\n" +
    "                                    <div class=\"col-sm-6 text-right\">\r" +
    "\n" +
    "                                        <b>{{c.updateIndicator}} </b>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                <b>Narratives</b>\r" +
    "\n" +
    "                                <div class=\"row\">\r" +
    "\n" +
    "                                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                                        <ul>\r" +
    "\n" +
    "                                            <li ng-repeat=\"narrative in c.narratives\">{{narrative}}</li>\r" +
    "\n" +
    "                                        </ul>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"!vm.creditReport.$collections.length\">\r" +
    "\n" +
    "                    <empty-state message=\"No collections were found on this report\" icon=\"fa-thumbs-up\"></empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>Inquiries</uib-tab-heading>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-repeat=\"i in vm.creditReport.usInquiries.usInquiry\" \r" +
    "\n" +
    "                    class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div class=\"col-sm-8\">\r" +
    "\n" +
    "                            <b>{{i.customerId.name}}</b>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-4 text-right\">\r" +
    "\n" +
    "                            {{i.customerId.industry.description}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"col-sm-12\">\r" +
    "\n" +
    "                            Inquiry Date: {{i.dateOfInquiry.text}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"!vm.creditReport.usInquiries.usInquiry.length\">\r" +
    "\n" +
    "                    <empty-state message=\"No hard inquiries were found on this report\"></empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-center\" \r" +
    "\n" +
    "        ng-show=\"vm.creditReport.bureau==='Experian' && !vm.creditReport.reportNotFound\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <pre class=\"small text-left inline-block\">\r" +
    "\n" +
    "            {{creditReport.textOutput}}\r" +
    "\n" +
    "        </pre>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-center\" \r" +
    "\n" +
    "        ng-show=\"vm.creditReport.reportNotFound\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <empty-state message=\"We tried to pull a credit report for this individual. Based on the information provided, no credit report was found.\"></empty-state>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/customerLookupModal/customerLookupModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4>Customer Lookup</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <customer-lookup \r" +
    "\n" +
    "        on-select=\"vm.selectCustomer(customer, source)\" \r" +
    "\n" +
    "        customer=\"vm.customer\" \r" +
    "\n" +
    "        source=\"vm.source\" \r" +
    "\n" +
    "        show-lookup-form=\"vm.showLookupForm\" \r" +
    "\n" +
    "        show-ams360-contact-selection =\"vm.showAms360ContactSelection\">\r" +
    "\n" +
    "    </customer-lookup>\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "<footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        ng-show=\"vm.showAms360ContactSelection\" \r" +
    "\n" +
    "        class=\"pull-left\" \r" +
    "\n" +
    "        ng-click=\"vm.goBack()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"charcoal\" \r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Cancel\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <!-- Customer Lookup will close if it's an A3 customer automatically -->\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        ng-show=\"vm.customer\" \r" +
    "\n" +
    "        type=\"button\" \r" +
    "\n" +
    "        ng-click=\"vm.selectCustomer(vm.customer, vm.source)\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Import Customer\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/modals/declineApplicationModal/declineApplicationModal.html',
    "<div ng-form=\"purchaseBondForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Decline Application</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div>Are you sure you want to decline this application?</div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <textarea \r" +
    "\n" +
    "            aa-field-group=\"vm.internalComments\" \r" +
    "\n" +
    "            aa-label=\"Internal Comments\">\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"top-margin\">\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.adverseActionRequired\" \r" +
    "\n" +
    "                aa-label-class=\"small\" \r" +
    "\n" +
    "                aa-label=\"Send Adverse Action Notice\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"charcoal\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.declineApplication()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Decline Application\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/deleteConfirmationModal/deleteConfirmationModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form name=\"deleteForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>{{vm.header}}</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <span ng-bind-html=\"vm.message\"></span>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            aa-submit-form=\"vm.deleteClicked()\" \r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Delete\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/modals/documentCorrectionModal/documentCorrectionModal.html',
    "<div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Document Correction Request</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-left\" \r" +
    "\n" +
    "        ng-show=\"!vm.documentCorrectionRequest\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>We're so sorry about this</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            We take pride in our document library and we want to correct this issue as \r" +
    "\n" +
    "            soon as possible. Please provide a brief explanation of the problem you noticed\r" +
    "\n" +
    "            with this document.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b>Document Name: </b> {{vm.documentName}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea \r" +
    "\n" +
    "            aa-field-group=\"vm.comments\" \r" +
    "\n" +
    "            aa-label=\"Problem Explanation\" \r" +
    "\n" +
    "            required>\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-left\" \r" +
    "\n" +
    "        ng-show=\"vm.documentCorrectionRequest\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>Help is on the way</header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <span class=\"pull-left right-margin\">\r" +
    "\n" +
    "            <i class=\"fa fa-check green super-size\"></i>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            We have received your request to review and correct this document. \r" +
    "\n" +
    "            We'll get on this right away and get back with you soon. Once we're done, \r" +
    "\n" +
    "            we'll send you notification letting you know.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <p>Thanks again for your patience and you'll be hearing from us soon.</p>\r" +
    "\n" +
    "        <b>- A3 Support Team</b>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\" \r" +
    "\n" +
    "            ng-show=\"!vm.documentCorrectionRequest\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\" \r" +
    "\n" +
    "            ng-show=\"!vm.documentCorrectionRequest\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-click=\"vm.done()\" \r" +
    "\n" +
    "            ng-show=\"vm.documentCorrectionRequest\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Done\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/documentLibrarySelectCategoryModal/documentLibrarySelectCategoryModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <h4 class=\"modal-title\">Choose Form Category</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section>\r" +
    "\n" +
    "    Please select a category for this form. Categories help organize forms by \r" +
    "\n" +
    "    grouping them based on their purpose.\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <main class=\"flex\">\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            class=\"dropdown h-center\" \r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"gray\" \r" +
    "\n" +
    "                uib-dropdown-toggle>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.selectedUploadCategory.name}}\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-folder-alt\"></i>\r" +
    "\n" +
    "                <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-left\">\r" +
    "\n" +
    "                <li ng-repeat=\"cat in vm.options.documentCategories\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.selectCategory(cat)\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <i ng-class=\"{'fa fa-check' : cat == vm.selectedUploadCategory}\"></i>\r" +
    "\n" +
    "                        <span>{{cat.name}}</span>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </main>\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<footer>\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"white\" \r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Cancel\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button ng-click=\"vm.upload()\">\r" +
    "\n" +
    "        Upload\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/modals/downloadTransactionDocumentsModal/downloadTransactionDocumentsModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form \r" +
    "\n" +
    "    novalidate \r" +
    "\n" +
    "    name=\"modalForm\" >\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Transaction Documents</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div ng-show=\"!vm.addDocument\">\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div class=\"text-right\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"mini\"\r" +
    "\n" +
    "                    ng-click=\"vm.addDocumentClicked()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Add Document\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"vm.transaction.documents.length\">\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.transactionType\" \r" +
    "\n" +
    "                    aa-fg-class=\"fill right-margin\" \r" +
    "\n" +
    "                    disabled />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"fill left-margin\">\r" +
    "\n" +
    "                    <user-dropdown\r" +
    "\n" +
    "                        id=\"download-document-packet-attorney-in-fact\"\r" +
    "\n" +
    "                        ng-model=\"vm.transaction.attorneyInFactUserId\"\r" +
    "\n" +
    "                        is-attorney-in-fact=\"true\"\r" +
    "\n" +
    "                        label=\"Attorney In Fact\"\r" +
    "\n" +
    "                        allow-unselected=\"false\"\r" +
    "\n" +
    "                        ng-required=\"true\">\r" +
    "\n" +
    "                    </user-dropdown>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <table class=\"top-margin small\">\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th></th>\r" +
    "\n" +
    "                        <th class=\"fit-content\">Include</th>\r" +
    "\n" +
    "                        <th>Document Name</th>\r" +
    "\n" +
    "                        <th>Document Type</th>\r" +
    "\n" +
    "                        <th></th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tbody \r" +
    "\n" +
    "                    class=\"sortable\" \r" +
    "\n" +
    "                    ng-sortable=\"{ animation: 350, chosenClass: 'sortable-chosen',fallbackClass: 'table-sortable-fallback', handle: '.grab-handle', forceFallback: true, onEnd:vm.endSortDocuments }\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <tr ng-repeat=\"bondTransactionDocumentListDocument in vm.transaction.documents\" >\r" +
    "\n" +
    "                        <td class=\"grab-handle\">\r" +
    "\n" +
    "                            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"row-select-column\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"bondTransactionDocumentListDocument.includeInDownload\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-fg-class=\"inline-block\" />\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            {{bondTransactionDocumentListDocument.documentName}} \r" +
    "\n" +
    "                            <i \r" +
    "\n" +
    "                                class=\"fa fa-exclamation-triangle red\" \r" +
    "\n" +
    "                                uib-tooltip=\"We're working to get this document corrected\" \r" +
    "\n" +
    "                                ng-show=\"bondTransactionDocumentListDocument.hasRequestedCorrection\">\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            {{bondTransactionDocumentListDocument.documentCategoryName}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                class=\"dropdown\" \r" +
    "\n" +
    "                                uib-dropdown>\r" +
    "\n" +
    "                                <button uib-dropdown-toggle>\r" +
    "\n" +
    "                                    <span class=\"visible-xs\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <span class=\"hidden-xs\">\r" +
    "\n" +
    "                                        Actions \r" +
    "\n" +
    "                                        <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                                <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                                    <li>\r" +
    "\n" +
    "                                        <button \r" +
    "\n" +
    "                                            ng-show=\"!bondTransactionDocumentListDocument.hasRequestedCorrection\" \r" +
    "\n" +
    "                                            ng-click=\"vm.requestCorrection(bondTransactionDocumentListDocument)\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <i class=\"fa fa-exclamation-triangle\"></i> \r" +
    "\n" +
    "                                            Request a Correction\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <button \r" +
    "\n" +
    "                                            ng-show=\"bondTransactionDocumentListDocument.hasRequestedCorrection\" \r" +
    "\n" +
    "                                            class=\"red\" \r" +
    "\n" +
    "                                            disabled>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                            <i class=\"fa fa-exclamation-triangle red\"></i> \r" +
    "\n" +
    "                                            Help is on the way\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </li>\r" +
    "\n" +
    "                                    <li class=\"divider\"></li>\r" +
    "\n" +
    "                                    <li>\r" +
    "\n" +
    "                                        <button ng-click=\"vm.removeDocumentFromPacket(bondTransactionDocumentListDocument.id, $index)\">\r" +
    "\n" +
    "                                            <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                                            Remove From Packet\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </li>\r" +
    "\n" +
    "                                </ul>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"row top-margin-big\">\r" +
    "\n" +
    "                <div class=\"col-md-6\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.includeAifSignature\" \r" +
    "\n" +
    "                        aa-label=\"Print Attorney-in-Fact Signature\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label-class=\"small\"/>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"col-md-6\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.includeNotaryAcknowledgement\" \r" +
    "\n" +
    "                        aa-label=\"Include Notary Acknowledgement\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-label-class=\"small\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"col-md-6 red\" \r" +
    "\n" +
    "                    ng-show=\"vm.includeAifSignature && !vm.transaction.allowsElectronicSignature\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    This obligee may not accept printed signatures.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"col-md-6 red\" \r" +
    "\n" +
    "                    ng-show=\"!vm.includeNotaryAcknowledgement && vm.transaction.agencyNotaryAcknowledgementRequired\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    It looks like this obligee may require this.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"top-margin\" \r" +
    "\n" +
    "                ng-show=\"!vm.transaction.documents.length\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <empty-state message=\"This transaction has no documents included in the packet\"></empty-state>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "        <footer>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\" \r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Close\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button ng-click=\"vm.download()\">\r" +
    "\n" +
    "                Download\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.addDocument\">\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <div ng-show=\"!vm.selectedNewDocument && !vm.newDocumentToUpload\">\r" +
    "\n" +
    "                <p>To add a new document to this transaction packet you can upload a new file or add one that already exists in the document library</p>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uploader\r" +
    "\n" +
    "                    controls=\"vm.uploader\"\r" +
    "\n" +
    "                    files=\"vm.uploaderFiles\"\r" +
    "\n" +
    "                    on-files-added=\"vm.newUploadDocumentsAdded(files)\">\r" +
    "\n" +
    "                </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-center\">\r" +
    "\n" +
    "                    <h3>OR</h3>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <ui-select \r" +
    "\n" +
    "                        ng-model=\"vm.selectedNewDocument\" \r" +
    "\n" +
    "                        ng-init=\"$select.label = 'Search Existing Documents'\" \r" +
    "\n" +
    "                        label=\"placeholderOnly\" \r" +
    "\n" +
    "                        reset-search-input=\"false\" \r" +
    "\n" +
    "                        name=\"documentSelection\"\r" +
    "\n" +
    "                        theme=\"app/components/uiSelect/a3\"\r" +
    "\n" +
    "                        class=\"fill\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <ui-select-match \r" +
    "\n" +
    "                            placeholder=\"Search for existing document...\" \r" +
    "\n" +
    "                            label=\"Search Documents\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{$select.selected.name}}\r" +
    "\n" +
    "                        </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-choices \r" +
    "\n" +
    "                            repeat=\"document in vm.matchingDocuments track by $index\" \r" +
    "\n" +
    "                            refresh=\"vm.searchDocuments($select.search)\" \r" +
    "\n" +
    "                            refresh-delay=\"0\">\r" +
    "\n" +
    "                            <div ng-bind-html=\"document.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                        </ui-select-choices>\r" +
    "\n" +
    "                    </ui-select>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"v-center left-margin\"\r" +
    "\n" +
    "                        ng-click=\"vm.selectExistingDocument()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Select\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"top-margin\"\r" +
    "\n" +
    "                ng-if=\"vm.newDocumentToUpload\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5>Upload New Document</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill v-center right-margin\">\r" +
    "\n" +
    "                        {{vm.newDocumentToUpload.file.name}}                    \r" +
    "\n" +
    "                        <div class=\"small\">\r" +
    "\n" +
    "                            Size: {{vm.newDocumentToUpload.file.size | filesize}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.newDocumentToUploadCategoryId\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        aa-label=\"Document Category\" \r" +
    "\n" +
    "                        options=\"vm.documentCategories\" \r" +
    "\n" +
    "                        aa-fg-class=\"fill v-center left-margin\"\r" +
    "\n" +
    "                        required >\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"top-margin flex\" \r" +
    "\n" +
    "                ng-show=\"vm.selectedNewDocument && !vm.newDocumentToUpload\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.selectedNewDocument.name\" \r" +
    "\n" +
    "                    aa-label=\"File\" \r" +
    "\n" +
    "                    aa-fg-class=\"fill v-center\" \r" +
    "\n" +
    "                    disabled />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.selectedNewDocument.documentCategoryId\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    aa-label=\"Document Category\" \r" +
    "\n" +
    "                    options=\"vm.documentCategories\" \r" +
    "\n" +
    "                    aa-fg-class=\"fill v-center left-margin\" \r" +
    "\n" +
    "                    disabled >\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "        <footer>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\" \r" +
    "\n" +
    "                ng-click=\"vm.closeAddDocument()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Back\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                aa-submit-form=\"vm.addDocumentSaveClick()\" \r" +
    "\n" +
    "                ng-disabled=\"!vm.selectedNewDocument && !vm.newDocumentToUpload\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                Add Document\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/modals/emailTemplateDetailModal/emailTemplateDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"groupForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 ng-show=\"vm.templateId\">Edit Email Template</h4>\r" +
    "\n" +
    "        <h4 ng-show=\"!vm.templateId\">New Email Template</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Name &amp; Comments\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.template.name\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                ng-disabled=\"vm.template.isDefault\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <textarea \r" +
    "\n" +
    "                aa-field-group=\"vm.template.comments\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                ng-disabled=\"vm.template.isDefault\">\r" +
    "\n" +
    "            </textarea>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>Email Message</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.template.subject\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.template.$includeCallToAction\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-label=\"Include Call To Action\" \r" +
    "\n" +
    "                        ng-change=\"vm.setPreview()\" \r" +
    "\n" +
    "                        aa-fg-class=\"left-margin-auto v-center\" />    \r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.template.$callToActionText\" \r" +
    "\n" +
    "                    ng-required=\"vm.template.$includeCallToAction\" \r" +
    "\n" +
    "                    aa-label=\"Call to Action Text\" \r" +
    "\n" +
    "                    ng-show=\"vm.template.$includeCallToAction\" \r" +
    "\n" +
    "                    ng-change=\"vm.setPreview()\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-6 top-margin\">\r" +
    "\n" +
    "                <span class=\"small\">Email Content</span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <text-angular \r" +
    "\n" +
    "                    name=\"htmlcontent\" \r" +
    "\n" +
    "                    class=\"no-margin\" \r" +
    "\n" +
    "                    ng-model=\"vm.template.$templateEditableBody\" \r" +
    "\n" +
    "                    ta-disabled=\"vm.isDisabled\" \r" +
    "\n" +
    "                    ng-change=\"vm.setPreview()\">\r" +
    "\n" +
    "                </text-angular>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"col-md-6 top-margin\">\r" +
    "\n" +
    "                <span class=\"small\">Preview</span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    ng-bind-html=\"vm.template.$preview\" \r" +
    "\n" +
    "                    class=\"email-preview\">\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-click=\"vm.close()\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            value=\"Save\"\r" +
    "\n" +
    "            class=\"left-margin\"/>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/epicBondTransactionExportModal/epicBondTransactionExportModal.html',
    "<div busy-indicator=\"{promise:vm.loadingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"exportEpicModal\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <h4>Export to Epic</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.exportRequest.producerUserId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.producerUserOptions\"\r" +
    "\n" +
    "                    aa-label=\"Producer\"\r" +
    "\n" +
    "                    required></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select aa-field-group=\"vm.exportRequest.clientServiceManagerUserId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.clientServiceManagerUserOptions\"\r" +
    "\n" +
    "                    aa-label=\"Client Service Manager\"\r" +
    "\n" +
    "                    required></select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6 text-left\">  \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select aa-field-group=\"vm.account.billToType\"\r" +
    "\n" +
    "                        aa.field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                        aa-fg-class=\"fixed-width-md top-margin\"\r" +
    "\n" +
    "                        ng-change=\"vm.summaryFieldChanged()\"></select>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-label=\"Broker Code\" \r" +
    "\n" +
    "                        ng-show=\"vm.brokerCodeRequired\"\r" +
    "\n" +
    "                        aa-fg-class=\"fill\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.exportRequest.brokerLookupCode\"\r" +
    "\n" +
    "                        ng-required=\"vm.brokerCodeRequired\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                   aa-label=\"Premium Payable Code\"\r" +
    "\n" +
    "                   ng-change=\"vm.premiumPayableChanged()\"\r" +
    "\n" +
    "                   ng-model-options=\"{debounce: 450}\" \r" +
    "\n" +
    "                   aa-field-group=\"vm.exportRequest.epicPremiumPayableCode\"\r" +
    "\n" +
    "                   required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.exportRequest.premiumPayableContractId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.premiumPayableContractOptions\"\r" +
    "\n" +
    "                    aa-label=\"Premium Payable Contract\"\r" +
    "\n" +
    "                    required></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                   aa-label=\"Issuing Company Code\"\r" +
    "\n" +
    "                   aa-field-group=\"vm.exportRequest.epicIssuingCompanyCode\"\r" +
    "\n" +
    "                   required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                   aa-label=\"Profit Center\"\r" +
    "\n" +
    "                   aa-field-group=\"vm.exportRequest.epicProfitCenterCode\"\r" +
    "\n" +
    "                   required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                   aa-label=\"Line of Business Code\"\r" +
    "\n" +
    "                   aa-field-group=\"vm.exportRequest.epicLineOfBusinessCode\"\r" +
    "\n" +
    "                   required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                   aa-label=\"Issuing Location Code\"\r" +
    "\n" +
    "                   aa-field-group=\"vm.exportRequest.issuingLocationCode\"\r" +
    "\n" +
    "                   required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Pr/Br\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                ng-click=\"vm.addCommissionBreakdownItem()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Add Row\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <table class=\"group-by-tbody\">\r" +
    "\n" +
    "            <tbody ng-repeat=\"commissionBreakdown in vm.exportRequest.commissionBreakdowns\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <select aa-field-group=\"commissionBreakdown.commissionBreakdownType\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center small top-margin-big \"\r" +
    "\n" +
    "                                    options=\"vm.commissionBreakdownTypes\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                    aa-label=\"Type\"\r" +
    "\n" +
    "                                    required></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select aa-field-group=\"commissionBreakdown.producerUserId\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                    ng-show=\"commissionBreakdown.commissionBreakdownType === 'ProducerPay'\"\r" +
    "\n" +
    "                                    ng-required=\"commissionBreakdown.commissionBreakdownType === 'ProducerPay'\"\r" +
    "\n" +
    "                                    aa-fg-class=\"left-margin fixed-width-md small v-center\"\r" +
    "\n" +
    "                                    options=\"vm.producerUserOptions\"\r" +
    "\n" +
    "                                    aa-label=\"Producer\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"commissionBreakdown.brokerLookupCode\"\r" +
    "\n" +
    "                                aa-fg-class=\"left-margin v-center small\"\r" +
    "\n" +
    "                                aa-label=\"Broker Code\"\r" +
    "\n" +
    "                                ng-show=\"commissionBreakdown.commissionBreakdownType === 'BrokerPay' || commissionBreakdown.commissionBreakdownType === 'BrokerReceivable'\"\r" +
    "\n" +
    "                                ng-required=\"commissionBreakdown.commissionBreakdownType === 'BrokerPay' || commissionBreakdown.commissionBreakdownType === 'BrokerReceivable'\"\r" +
    "\n" +
    "                                ng-change=\"vm.brokerCommissionContractChanged($index)\"\r" +
    "\n" +
    "                                ng-model-options=\"{debounce: 450}\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <select aa-field-group=\"commissionBreakdown.commissionCalculationType\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center small left-margin\"\r" +
    "\n" +
    "                                options=\"vm.commissionCalculationOptions\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                ng-required=\"commissionBreakdown.commissionBreakdownType !== 'ProducerPay'\"\r" +
    "\n" +
    "                                ng-show=\"commissionBreakdown.commissionBreakdownType !== 'ProducerPay'\"\r" +
    "\n" +
    "                                aa-label=\"Calculation Type\"\r" +
    "\n" +
    "                                required></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"commissionBreakdown.commission\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center small left-margin\"\r" +
    "\n" +
    "                                ng-show=\"commissionBreakdown.commissionBreakdownType === 'BrokerPay' || commissionBreakdown.commissionBreakdownType === 'BrokerReceivable'\"\r" +
    "\n" +
    "                                ng-required=\"commissionBreakdown.commissionBreakdownType === 'ProducerPay' || commissionBreakdown.commissionBreakdownType === 'BrokerReceivable'\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"percent\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"commissionBreakdown.commissionCreditPercent\"\r" +
    "\n" +
    "                                ng-show=\"commissionBreakdown.commissionBreakdownType === 'ProducerPay'\"\r" +
    "\n" +
    "                                ng-required=\"commissionBreakdown.commissionBreakdownType === 'ProducerPay'\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center left-margin small\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"percent\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select aa-field-group=\"commissionBreakdown.epicBrokerPayableContractId\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center left-margin small\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                    ng-show=\"commissionBreakdown.commissionBreakdownType === 'BrokerPay'\"\r" +
    "\n" +
    "                                    ng-required=\"commissionBreakdown.commissionBreakdownType === 'BrokerPay'\"\r" +
    "\n" +
    "                                    options=\"vm.brokerCommissionContractOptions[$index]\"></select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                                ng-show=\"vm.exportRequest.commissionBreakdowns.length > 1\"\r" +
    "\n" +
    "                                ng-click=\"vm.removeCommissionBreakdownItem($index)\">\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.export()\">\r" +
    "\n" +
    "            Export\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/epicCustomerConnectionModal/epicCustomerConnectionModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    Epic Customer Connection\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.isLoaded\"\r" +
    "\n" +
    "    ng-form=\"epicConnectForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!vm.lookupCode\" \r" +
    "\n" +
    "        class=\"text-left\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <h5 class=\"no-top-margin bottom-margin-thin\">Connect to Client</h5>\r" +
    "\n" +
    "            <p>To connect this A3 customer to an existing Epic client record you must first search for the client by lookup code.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-fg-class=\"fill\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.lookupCodeSearch\"\r" +
    "\n" +
    "                    maxlength=\"10\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"left-margin top-margin-auto\"\r" +
    "\n" +
    "                    ng-click=\"vm.processsEpicCustomerConnectionRequest()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-search\"></i>\r" +
    "\n" +
    "                    Search Epic\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <p \r" +
    "\n" +
    "                ng-show=\"vm.noMatchesFound\"\r" +
    "\n" +
    "                class=\"red\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                No matching client found\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <h5 class=\"no-top-margin bottom-margin-thin\">Export</h5>\r" +
    "\n" +
    "            <p>By exporting this A3 customer record to Epic you will create a new Epic client.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"top-margin\"\r" +
    "\n" +
    "                ng-click=\"vm.exportToEpic()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-cloud-upload-alt\"></i>\r" +
    "\n" +
    "                Export to Epic\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section\r" +
    "\n" +
    "        ng-show=\"vm.lookupCode\"\r" +
    "\n" +
    "        class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 class=\"no-top-margin bottom-margin-thin\">Disconnect from Epic</h5>\r" +
    "\n" +
    "        <p>This customer record is currently connected to an Epic client record.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"top-margin-big\" \r" +
    "\n" +
    "            ng-click=\"vm.disconnectFromEpic()\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "            <i class=\"fa fa-minus-circle\"></i>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Disconnect\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/eProducerBrokerModal/eProducerBrokerModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>New EProducer Broker</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <eproducer-broker-summary account=\"vm.account\"></eproducer-broker-summary>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/eProducerUserModal/eProducerUserModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>EProducer User</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"alert alert-danger\"\r" +
    "\n" +
    "            ng-show=\"vm.userExists\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            This email address is already registered as an eProducer user\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.firstName\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.lastName\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.jobTitle\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.phone\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-8\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.phoneExtension\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.secondaryPhone\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-8\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.secondaryPhoneExtension\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-4\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.fax\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.email\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <textarea\r" +
    "\n" +
    "                aa-field-group=\"vm.user.additionalComments\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-12\" >\r" +
    "\n" +
    "            </textarea>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"row\"\r" +
    "\n" +
    "            ng-show=\"!vm.user.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.password\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                ng-required=\"!vm.user.id\"\r" +
    "\n" +
    "                ng-pattern=\"/^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$/\"\r" +
    "\n" +
    "                ng-pattern-msg=\"Password requires 1 upper-case, 1 lower-case, 1 numeric and must be at least 8 characters long\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.confirmPassword\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                ng-required=\"!vm.user.id\"\r" +
    "\n" +
    "                ng-match=\"vm.user.password\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/exportFormsModal/exportFormsModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"exportForm\">\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Download Forms</h4>\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            All forms will be compressed in a zip file which makes it more effecient to download. \r" +
    "\n" +
    "            A3 will also seperate newly added forms from updated forms to help you keep track of changes to the document library.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            What forms are you looking for? New ones, updated ones or both?\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.type\" \r" +
    "\n" +
    "                options=\"vm.typeOptions\" \r" +
    "\n" +
    "                aa-label=\"Which forms would you like to download?\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-12\" \r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <p>Please select a date range to download forms.</p>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.startDate\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.endDate\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <p>Which types of documents do you want to include in the download?</p>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.includeCoverLetters\" \r" +
    "\n" +
    "                aa-label=\"Cover Letters\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.includeApplications\" \r" +
    "\n" +
    "                aa-label=\"Application Forms\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.includeBondForms\" \r" +
    "\n" +
    "                aa-label=\"Bond Forms\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.includeOther\" \r" +
    "\n" +
    "                aa-label=\"Other\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <p>A manifest file can be useful to list all forms that are included in the downloaded zip file.</p>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.downloadOptions.includeManifest\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                aa-label=\"Include manifest CSV file in zip file\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-click=\"vm.export()\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Download\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/followUpModal/followUpModal.html',
    "<div busy-indicator=\"{ promise: vm.processingPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Follow Up</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <follow-up follow-up=\"vm.followUp\"></follow-up>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/followUpStrategyDetailModal/followUpStrategyDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 ng-show=\"vm.followUpStrategyId\">\r" +
    "\n" +
    "            Edit Follow-Up Strategy\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "        <h4 ng-show=\"!vm.followUpStrategyId\">\r" +
    "\n" +
    "            New Follow-Up Strategy\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Name &amp; Comments</header>\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.strategy.name\" \r" +
    "\n" +
    "                        required \r" +
    "\n" +
    "                        ng-disabled=\"vm.strategy.isDefault\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.strategy.strategyType\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"  \r" +
    "\n" +
    "                        aa-label=\"Applies To\" \r" +
    "\n" +
    "                        options=\"vm.strategyTypeOptions\" \r" +
    "\n" +
    "                        required \r" +
    "\n" +
    "                        ng-disabled=\"vm.strategy.isDefault\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea \r" +
    "\n" +
    "                    aa-field-group=\"vm.strategy.comments\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                    ng-disabled=\"vm.strategy.isDefault\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Schedule Assignment</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"vm.hasConflicts\" \r" +
    "\n" +
    "                class=\"alert alert-danger\">\r" +
    "\n" +
    "                    Bond types and SFAA codes can only be assigned to a single strategy. Please review the assigments below to make corrections\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <p ng-show=\"vm.strategy.isDefault\">\r" +
    "\n" +
    "                This is the default follow-up strategy. This strategy is used when there is not a specific SFAA or Bond Type specific strategy.\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"row\" \r" +
    "\n" +
    "                ng-show=\"!vm.strategy.isDefault\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-md-12 bottom-margin\">\r" +
    "\n" +
    "                    Use this strategy by assigning bond types and/or sfaa class codes below. The bond type that is applied for will determine the strategy that is used for follow-ups.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"col-md-6\">\r" +
    "\n" +
    "                    <div class=\"field-flex-box\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <ui-select \r" +
    "\n" +
    "                            ng-model=\"vm.bondTypeSearch.selected\" \r" +
    "\n" +
    "                            ng-init=\"$select.label = 'Search Bond Types'\" \r" +
    "\n" +
    "                            reset-search-input=\"false\" \r" +
    "\n" +
    "                            name=\"bondTypeSearch\" \r" +
    "\n" +
    "                            ng-model-options=\"{debounce:300}\"\r" +
    "\n" +
    "                            theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ui-select-match \r" +
    "\n" +
    "                                placeholder=\"Search for bond type name...\" \r" +
    "\n" +
    "                                label=\"Search Bond Types\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                {{$select.selected.name}}\r" +
    "\n" +
    "                            </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ui-select-choices \r" +
    "\n" +
    "                                repeat=\"bondType in vm.bondTypeSearch track by $index\" \r" +
    "\n" +
    "                                refresh=\"vm.searchBondTypes($select.search)\" \r" +
    "\n" +
    "                                refresh-delay=\"0\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <div ng-bind-html=\"bondType.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                            </ui-select-choices>\r" +
    "\n" +
    "                        </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            class=\"col-sm-1\" \r" +
    "\n" +
    "                            ng-click=\"vm.addBondType()\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Add\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table ng-show=\"vm.strategy.applicationFollowUpStrategiesBondTypes.length\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <th>Assigned Bond Types</th>\r" +
    "\n" +
    "                            <th>&nbsp;</th>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tr \r" +
    "\n" +
    "                            ng-repeat=\"bt in vm.strategy.applicationFollowUpStrategiesBondTypes\" \r" +
    "\n" +
    "                            ng-class=\"{'red': bt.$assignedStrategy}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <td>\r" +
    "\n" +
    "                                {{bt.bondType.name}} \r" +
    "\n" +
    "                                <span ng-show=\"bt.$assignedStrategy\">\r" +
    "\n" +
    "                                    <br/> \r" +
    "\n" +
    "                                    Already assigned in strategy named <strong>\"{{bt.$assignedStrategy}}\"</strong>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td class=\"text-right\">\r" +
    "\n" +
    "                                <button ng-click=\"vm.removeBondType($index)\">\r" +
    "\n" +
    "                                    <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "                                    <span>Remove</span>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"!vm.strategy.applicationFollowUpStrategiesBondTypes.length\"\r" +
    "\n" +
    "                        message=\"This schedule isn't assigned to any bond types.\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <sfaa-code-list \r" +
    "\n" +
    "                    sfaa-codes=\"vm.strategy.$sfaaCodes\" \r" +
    "\n" +
    "                    class=\"col-md-6\" \r" +
    "\n" +
    "                    no-entries-text=\"This schedule isn't assigned to any Sfaa Codes\">\r" +
    "\n" +
    "                </sfaa-code-list>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                Immediate Follow-Ups\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"vm.strategy.strategyType == 'Application'\"\r" +
    "\n" +
    "                class=\"bottom-margin-big row\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.strategy.submissionEmailTemplateId\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    aa-label=\"Email To Send on Submission\" \r" +
    "\n" +
    "                    aa-unselected-text=\"Don't send an email on submission\" \r" +
    "\n" +
    "                    options=\"vm.emailTemplates\" \r" +
    "\n" +
    "                    aa-option-value=\"id\" \r" +
    "\n" +
    "                    aa-option-text=\"name\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-md-6\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.strategy.purchaseEmailTemplateId\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    aa-label=\"Email to Send on Purchase\" \r" +
    "\n" +
    "                    aa-unselected-text=\"Don't send an email on purchase\" \r" +
    "\n" +
    "                    options=\"vm.emailTemplates\" \r" +
    "\n" +
    "                    aa-option-value=\"id\" \r" +
    "\n" +
    "                    aa-option-text=\"name\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-md-6\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"vm.strategy.strategyType == 'Prospect'\"\r" +
    "\n" +
    "                class=\"bottom-margin-big row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-md-12\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.strategy.prospectCreationEmailTemplateId\" \r" +
    "\n" +
    "                        aa-unselected-text=\"Don't send an email when prospects are created\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        aa-label=\"Email To Send when lead is created\" \r" +
    "\n" +
    "                        options=\"vm.emailTemplates\" \r" +
    "\n" +
    "                        aa-option-value=\"id\" \r" +
    "\n" +
    "                        aa-option-text=\"name\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Follow-Up Schedule</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"text-right bottom-margin\">\r" +
    "\n" +
    "               \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\" \r" +
    "\n" +
    "                    ng-click=\"vm.addEmail()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Add Scheduled Email\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <table ng-show=\"vm.strategy.emails && vm.strategy.emails.length\">\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>Email Template</th>\r" +
    "\n" +
    "                        <th>When to send</th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody>\r" +
    "\n" +
    "                    <tr ng-repeat=\"email in vm.strategy.emails | orderBy:'daysAfterSubmission'\">\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-field-group=\"email.emailTemplateId\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                                options=\"vm.emailTemplates\" \r" +
    "\n" +
    "                                aa-option-value=\"id\" \r" +
    "\n" +
    "                                aa-option-text=\"name\" \r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"email.daysAfterSubmission\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"integer\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                class=\"pull-left text-right inline-label\" \r" +
    "\n" +
    "                                style=\"line-height:36px;\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                days after submission at \r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-field-group=\"email.timeOfDay\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"hours\" \r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                ng-click=\"vm.removeEmail($index)\" \r" +
    "\n" +
    "                                uib-tooltip=\"Remove Email\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <empty-state\r" +
    "\n" +
    "                ng-show=\"!vm.strategy.emails.length\"\r" +
    "\n" +
    "                message=\"No scheduled email are defined\">\r" +
    "\n" +
    "            </empty-state>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-click=\"vm.close()\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\" \r" +
    "\n" +
    "            value=\"Save\" \r" +
    "\n" +
    "            class=\"left-margin\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/grantCustomerEproducerAccessModal/grantCustomerEproducerAccessModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <h4>Customer EProducer Access</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"alert alert-danger text-center\"\r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.request.firstName\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.request.lastName\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "               \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "            aa-field-group=\"vm.request.email\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/gridConfirmDeleteModal/gridConfirmDeleteModal.html',
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <h4>Are you sure?</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section>\r" +
    "\n" +
    "    Are you sure you want to delete \r" +
    "\n" +
    "    <span ng-bind-html=\"vm.getLabel()\"></span>\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<footer>\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        id=\"noButtonModal\" \r" +
    "\n" +
    "        class=\"white\" \r" +
    "\n" +
    "        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        No\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        id=\"yesButtonModal\" \r" +
    "\n" +
    "        ng-click=\"vm.delete()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Yes\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</footer>\r" +
    "\n"
  );


  $templateCache.put('app/modals/indemnityModal/indemnityModal.html',
    "<div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"indemnityForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Send Indemnity Emails</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"bottom-margin\">\r" +
    "\n" +
    "        <div class=\"text-right\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\" \r" +
    "\n" +
    "                ng-click=\"vm.addIndemnity()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Add Indemnity\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <table>\r" +
    "\n" +
    "            <thead ng-show=\"vm.indemnitySignatures.length > 0\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"text-center\">Check to Send</th>\r" +
    "\n" +
    "                    <th>Signer</th>\r" +
    "\n" +
    "                    <th>Status</th>\r" +
    "\n" +
    "                    <th>Email Address</th>\r" +
    "\n" +
    "                    <th>Type</th>\r" +
    "\n" +
    "                    <th>Associated To</th>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody class=\"small\">\r" +
    "\n" +
    "                <tr \r" +
    "\n" +
    "                    ng-class=\"{'line-through' : indemnitySignature.isRemoved}\"\r" +
    "\n" +
    "                    ng-repeat=\"indemnitySignature in vm.indemnitySignatures\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"indemnitySignature.status != 'Signed'\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            type=\"checkbox\" \r" +
    "\n" +
    "                            ng-model=\"indemnitySignature.send\" \r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            uib-tooltip=\"Check To Send\"/>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-if=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            ng-disabled=\"indemnitySignature.status == 'Signed'\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-field-group=\"indemnitySignature.printedName\" \r" +
    "\n" +
    "                            required/>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-if=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <span ng-show=\"indemnitySignature.status == 'Sent'\">\r" +
    "\n" +
    "                            <i class=\"fa fa-check\"></i> \r" +
    "\n" +
    "                            Email Sent {{indemnitySignature.emailSentDateTime | date: 'MM/dd/yyyy hh:mm a'}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"indemnitySignature.status == 'NotSent'\">\r" +
    "\n" +
    "                            <b>Email Not Sent</b>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-if=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-field-group=\"indemnitySignature.emailedToAddress\" \r" +
    "\n" +
    "                            required/>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                            aa-field-group=\"indemnitySignature.indemnityType\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            options=\"vm.indemnityTypeOptions\" \r" +
    "\n" +
    "                            aa-option-value=\"value\" \r" +
    "\n" +
    "                            aa-option-text=\"label\" \r" +
    "\n" +
    "                            aa-label=\"Type\" \r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <div ng-if=\"indemnitySignature.indemnityType == 'Principal' || indemnitySignature.indemnityType == 'Spouse'\">\r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                                aa-field-group=\"indemnitySignature.personId\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                                options=\"vm.peopleAndCompanyOptions.people\" \r" +
    "\n" +
    "                                aa-option-value=\"id\" \r" +
    "\n" +
    "                                aa-option-text=\"name\" \r" +
    "\n" +
    "                                aa-label=\"Associated Person\" \r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-if=\"indemnitySignature.indemnityType == 'Corporate'\">\r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                                aa-field-group=\"indemnitySignature.companyId\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                                options=\"vm.peopleAndCompanyOptions.companies\" \r" +
    "\n" +
    "                                aa-option-value=\"id\" \r" +
    "\n" +
    "                                aa-option-text=\"name\" \r" +
    "\n" +
    "                                aa-label=\"Associated Company\" \r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.toggleRemoveFlag(indemnitySignature, $index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"indemnitySignature.status == 'Signed'\">\r" +
    "\n" +
    "                        <span>\r" +
    "\n" +
    "                            <i class=\"green fa fa-check\"></i> \r" +
    "\n" +
    "                            Signed\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"indemnitySignature.status == 'Signed'\"\r" +
    "\n" +
    "                        colspan=\"4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"indemnitySignature.indemnityType != 'Principal'\">\r" +
    "\n" +
    "                            ({{indemnitySignature.indemnityType}})\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Signed: {{indemnitySignature.signature}} on {{indemnitySignature.signatureDateTime | date: 'MM/dd/yyyy'}} at {{indemnitySignature.signatureDateTime | date: 'hh:mm a'}} from IP Address {{indemnitySignature.clientIp}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td ng-show=\"indemnitySignature.status == 'Signed'\">\r" +
    "\n" +
    "                        <span ng-show=\"indemnitySignature.status == 'Signed'\">\r" +
    "\n" +
    "                            {{indemnitySignature.emailedToAddress}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody ng-show=\"vm.indemnitySignatures.length === 0\">\r" +
    "\n" +
    "                <tr class=\"noRecords\">\r" +
    "\n" +
    "                    <td colspan=\"3\">\r" +
    "\n" +
    "                        <span>No Records Found</span>\r" +
    "\n" +
    "                        <p>No indemnity signatures are required for this quote</p>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            aa-submit-form=\"vm.saveAndSend()\" \r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save &amp; Send Email\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save &amp; Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/inviteBrokerModal/inviteBrokerModal.html',
    "<div busy-indicator=\"{promise: vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Send a broker invite</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            Let's send an email to a representative of the agency that will contain a link which \r" +
    "\n" +
    "            they could use to access a registration page. Once completed, you'll be notified to \r" +
    "\n" +
    "            review the broker's info and approve their request of registration.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.email\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.send()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Send\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/invoiceModal/invoiceModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"invoiceModalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header no-right-padding\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"modal-refresh-button\"\r" +
    "\n" +
    "            ng-click=\"vm.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex top-margin-big\">\r" +
    "\n" +
    "            <h4 class=\"v-center fill\">Invoice #{{vm.invoice.invoiceNumber}}</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"dropdown actions left-margin\"\r" +
    "\n" +
    "                    uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        id=\"invoice-detail-action-dropdown-button\"\r" +
    "\n" +
    "                        uib-dropdown-toggle\r" +
    "\n" +
    "                        class=\"small white\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Actions <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                        <li>\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                ng-disabled=\"vm.invoice.status == 'Voided'\"\r" +
    "\n" +
    "                                ng-click=\"vm.voidInvoice()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-slash\"></i>\r" +
    "\n" +
    "                                Void Invoice\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "                    </ul>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"fill\"\r" +
    "\n" +
    "                ng-show=\"!vm.invoice.eProducerAccountId\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"text-large text-bold\">{{vm.invoice.customerName}}</div>\r" +
    "\n" +
    "                <div ng-show=\"vm.invoice.customerLookupCode\">Lookup Code: {{vm.invoice.customerLookupCode}}</div>\r" +
    "\n" +
    "                <div>{{vm.invoice.customerMailAddress}} {{vm.invoice.customerMailSuiteAptNumber}}</div>\r" +
    "\n" +
    "                <div>{{vm.invoice.customerMailCity}}, {{vm.invoice.customerMailCity}}, {{vm.invoice.customerMailZip}}</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"fill\"\r" +
    "\n" +
    "                ng-show=\"vm.invoice.eProducerAccountId\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"text-large text-bold\">{{vm.invoice.brokerName}}</div>\r" +
    "\n" +
    "                <div ng-show=\"vm.invoice.brokerLookupCode\">Lookup Code: {{vm.invoice.brokerLookupCode}}</div>\r" +
    "\n" +
    "                <div>{{vm.invoice.brokerMailAddress}} {{vm.invoice.brokerMailSuiteAptNumber}}</div>\r" +
    "\n" +
    "                <div>{{vm.invoice.brokerMailCity}}, {{vm.invoice.brokerMailCity}}, {{vm.invoice.brokerMailZip}}</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex nowrap\">\r" +
    "\n" +
    "                <div class=\"left-margin-auto text-right\">\r" +
    "\n" +
    "                    <div>Status: </div>\r" +
    "\n" +
    "                    <div>Date: </div>\r" +
    "\n" +
    "                    <div>Original Amount: </div>\r" +
    "\n" +
    "                    <div>Outstanding Amount: </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"left-margin text-right\">\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-class=\"{'green': vm.invoice.status == 'Paid', 'red': vm.invoice.status == 'Voided'}\"\r" +
    "\n" +
    "                        class=\"text-bold\">{{vm.invoice.status}}</div>\r" +
    "\n" +
    "                    <div class=\"text-bold\">{{vm.invoice.createdDateTime | dateOnly}}</div>\r" +
    "\n" +
    "                    <div class=\"text-bold\">{{vm.invoice.originalAmount | currency : '$' }}</div>\r" +
    "\n" +
    "                    <div class=\"text-bold\">{{vm.invoice.totalAmountDue | currency : '$' }}</div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            aa-field-group=\"vm.invoice.comments\"\r" +
    "\n" +
    "            aa-fg-class=\"top-margin-big bottom-margin\"\r" +
    "\n" +
    "            disabled>\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table class=\"top-margin small no-stripe\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th class=\"fit-content\">Billing #</th>\r" +
    "\n" +
    "                <th>Description</th>\r" +
    "\n" +
    "                <th class=\"fit-content\">Credit</th>\r" +
    "\n" +
    "                <th class=\"fit-content\">Amount Due</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "            <tr ng-repeat-start=\"lineItem in vm.invoice.lineItems\"\r" +
    "\n" +
    "                ng-if=\"$index != 0 && vm.invoice.lineItems[$index - 1].bondTransactionId != vm.invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"bottom-border-none\"\r" +
    "\n" +
    "                    colspan=\"4\">\r" +
    "\n" +
    "                    <!-- Spacer row between bond numbers -->\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tr ng-if=\"$index == 0 || vm.invoice.lineItems[$index - 1].bondTransactionId != vm.invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                <td></td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        {{lineItem.bondNumber}} - {{lineItem.bondAmount | currency:'$'}} {{lineItem.bondType}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        Term: {{ lineItem.effectiveDate | dateOnly }} - {{ lineItem.expirationDate | dateOnly }} Eff Date: {{ lineItem.transactionEffectiveDate | dateOnly }}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        Principal: {{ lineItem.nameOnBond }}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        Writing Company: {{ lineItem.writingCompany }}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td></td>\r" +
    "\n" +
    "                <td></td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <tr ng-repeat-end>\r" +
    "\n" +
    "                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                    {{lineItem.billingEntryId}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"no-top-padding no-bottom-padding\">\r" +
    "\n" +
    "                    {{ lineItem.code }} - {{ lineItem.description }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                    <span ng-show=\"lineItem.amountReceived != 0\">\r" +
    "\n" +
    "                        {{lineItem.amountReceived | currency:'$'}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                    <span ng-show=\"lineItem.amountDue != 0\">\r" +
    "\n" +
    "                        {{lineItem.amountDue | currency:'$'}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td \r" +
    "\n" +
    "                    class=\"no-right-padding\"\r" +
    "\n" +
    "                    colspan=\"4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex top-margin\">\r" +
    "\n" +
    "                        <table class=\"no-stripe width-auto left-margin-auto\">\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding text-right\">\r" +
    "\n" +
    "                                    Invoice Amount:\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding text-right\">\r" +
    "\n" +
    "                                    {{vm.invoice.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding no-top-padding  text-right\">\r" +
    "\n" +
    "                                    Payments Received:\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding no-top-padding  text-right\">\r" +
    "\n" +
    "                                    {{vm.invoice.totalAmountReceived | currency: '$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding no-top-padding  text-right\">\r" +
    "\n" +
    "                                    Outstanding Balance:\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-bottom-padding no-top-padding text-right\">\r" +
    "\n" +
    "                                    <b>{{vm.invoice.totalAmountDue | currency:'$'}}</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <pdf-download \r" +
    "\n" +
    "            class=\"right-margin-auto\"\r" +
    "\n" +
    "            uib-tooltip=\"Download Invoice\" \r" +
    "\n" +
    "            url=\"vm.invoiceDownloadUrl\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "            Download\r" +
    "\n" +
    "        </pdf-download>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"left-margin-auto white\" \r" +
    "\n" +
    "            ng-click=\"vm.close()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/issueBondModal/issueBondModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Issue Bond</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"!vm.bondType\">\r" +
    "\n" +
    "            <bond-type-selection\r" +
    "\n" +
    "                on-select=\"vm.bondTypeSelected(bondTypeId)\">\r" +
    "\n" +
    "            </bond-type-selection>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.bondType\">\r" +
    "\n" +
    "            <section class=\"text-left\">\r" +
    "\n" +
    "                <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                    Bond Summary\r" +
    "\n" +
    "                    <span>{{vm.bondType.name}}</span>\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"issue-bond-name-on-bond-input\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.nameOnBond\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-label=\"Printed name on bond\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"issue-bond-effective-date\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.effectiveDate\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                        ng-blur=\"vm.effectiveDateChanged()\"\r" +
    "\n" +
    "                        compare-date=\"vm.bond.expirationDate\"\r" +
    "\n" +
    "                        comparison-type=\"lessThanOrEqual\"\r" +
    "\n" +
    "                        compare-date-msg=\"{0} is later than expiration date\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"issue-bond-expiration-date\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.expirationDate\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                        compare-date=\"vm.bond.effectiveDate\"\r" +
    "\n" +
    "                        comparison-type=\"greaterThanOrEqual\"\r" +
    "\n" +
    "                        compare-date-msg=\"{0} precedes effective date\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondAmount\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ui-tooltip=\"This bond type has a fixed bond amount\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondType.bondAmountType == 'Fixed'\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondType.bondAmountType == 'Fixed'\"\r" +
    "\n" +
    "                        disabled />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"issue-bond-bond-amount\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondAmount\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondType.bondAmountType == 'UserDefined'\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondType.bondAmountType == 'UserDefined'\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondAmount\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-unselected-text=\"Select bond amount\"\r" +
    "\n" +
    "                        options=\"vm.bondTypeVariableBondAmounts\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondType.bondAmountType == 'Variable'\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondType.bondAmountType == 'Variable'\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            id=\"issue-bond-attorney-in-fact\"\r" +
    "\n" +
    "                            ng-model=\"vm.bond.attorneyInFactUserId\"\r" +
    "\n" +
    "                            is-attorney-in-fact=\"true\"\r" +
    "\n" +
    "                            label=\"Attorney In Fact\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                            allow-unselected=\"false\"\r" +
    "\n" +
    "                            unselected-text=\"Select attorney in fact...\"\r" +
    "\n" +
    "                            ng-required=\"true\">\r" +
    "\n" +
    "                        </user-dropdown> \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"vm.bondType.isGenericObligee\">\r" +
    "\n" +
    "                    <h5 class=\"sub-heading\"> Obligee </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <obligee-form\r" +
    "\n" +
    "                        entry-mode=\"application\"\r" +
    "\n" +
    "                        obligee=\"vm.obligee\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                    <div class=\"v-center\"> Principal </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"left-margin-auto v-center\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                            ng-change=\"vm.changePrincipalType()\"\r" +
    "\n" +
    "                            aa-label=\"Principal is a company\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-bottom-margin\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"no-section\">\r" +
    "\n" +
    "                    <company\r" +
    "\n" +
    "                        ng-if=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                        class=\"bottom-margin-big no-section\"\r" +
    "\n" +
    "                        company=\"vm.bond.company\">\r" +
    "\n" +
    "                    </company>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <contact-list\r" +
    "\n" +
    "                    ng-if=\"vm.bond.people\"\r" +
    "\n" +
    "                    contacts=\"vm.bond.people\"\r" +
    "\n" +
    "                    hide-include-on-bonds=\"true\"\r" +
    "\n" +
    "                    customer-id=\"vm.bond.customerId\">\r" +
    "\n" +
    "                </contact-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.bondTypeQuestions.length\">\r" +
    "\n" +
    "                    <h5 class=\"sub-heading\">Additional Information</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <additional-questions\r" +
    "\n" +
    "                        questions=\"vm.bondTypeQuestions\"\r" +
    "\n" +
    "                        question-responses=\"vm.bond.questionResponses\">\r" +
    "\n" +
    "                    </additional-questions>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 class=\"sub-heading\">Servicing Roles</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill right-margin\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <user-dropdown\r" +
    "\n" +
    "                                ng-model=\"vm.bond.producerUserId\"\r" +
    "\n" +
    "                                is-producer=\"true\"\r" +
    "\n" +
    "                                label=\"Producer\"\r" +
    "\n" +
    "                                ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"Default\"\r" +
    "\n" +
    "                                class=\"fill right-margin\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.bond.productionCredit\"\r" +
    "\n" +
    "                                aa-fg-class=\"small form-inline\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                aa-label=\"Credit Producer\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceExecutiveUserId\"\r" +
    "\n" +
    "                            is-client-service-executive=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Executive\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill left-margin\">\r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceManagerUserId\"\r" +
    "\n" +
    "                            is-client-service-manager=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Manager\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>         \r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <user-dropdown\r" +
    "\n" +
    "                            ng-model=\"vm.bond.clientServiceAgentUserId\"\r" +
    "\n" +
    "                            is-client-service-agent=\"true\"\r" +
    "\n" +
    "                            label=\"Client Service Agent\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                            allow-unselected=\"true\"\r" +
    "\n" +
    "                            unselected-text=\"Default\">\r" +
    "\n" +
    "                        </user-dropdown>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"vm.broker\">\r" +
    "\n" +
    "                    <h5 class=\"sub-heading\">Broker</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        target=\"{{'eProducerAccountDetail/' + vm.broker.id}}\" \r" +
    "\n" +
    "                        ui-sref=\"main.eProducerAccountDetail({ id: vm.broker.id })\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{vm.broker.name}}\r" +
    "\n" +
    "                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                    <div class=\"small\">\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            Lookup Code: {{vm.broker.lookupCode}}\r" +
    "\n" +
    "                            <i ng-show=\"!vm.broker.lookupCode\">Not Set</i>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            Type: {{vm.broker.eProducerCategoryName}}\r" +
    "\n" +
    "                            <i ng-show=\"!vm.broker.eProducerCategoryName\">Not Set</i>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bond.billToType\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bond.brokerCommissionCalculationType\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"commissionCalculationType\"\r" +
    "\n" +
    "                            allow-fixed-amount=\"true\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-unselected-text=\"No commission\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bond.brokerCommissionPercent\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                            ng-required=\"vm.bond.brokerCommissionCalculationType && vm.bond.brokerCommissionCalculationType != 'FixedAmount'\"\r" +
    "\n" +
    "                            ng-show=\"vm.bond.brokerCommissionCalculationType && vm.bond.brokerCommissionCalculationType != 'FixedAmount'\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            ng-show=\"vm.bond.brokerCommissionCalculationType == 'FixedAmount'\"\r" +
    "\n" +
    "                            ng-required=\"vm.bond.brokerCommissionCalculationType == 'FixedAmount'\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.bond.brokerCommission\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 class=\"sub-heading\">Issuing Company</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        id=\"issue-bond-writing-company\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.writingCompanyId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"groupSelect\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-change=\"vm.writingCompanyChanged()\"\r" +
    "\n" +
    "                        groups=\"vm.writingCompanyOptionGroups\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        ng-show=\"vm.bondAccountOptions.length\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondAccountId\"\r" +
    "\n" +
    "                        aa-label=\"Bond Line\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        options=\"vm.bondAccountOptions\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            id=\"issue-bond-bond-number-type\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.bondNumberType\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center left-margin\"\r" +
    "\n" +
    "                            aa-wrapper-class=\"flex top-margin-big\"\r" +
    "\n" +
    "                            aa-label=\"Bond Number Type\"\r" +
    "\n" +
    "                            options=\"vm.bondNumberTypeOptions\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                            required />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        id=\"issue-bond-bond-number-group\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondNumberGroupId\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-label=\"Bond Number Group\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-unselected-text=\"Select bond number group...\"\r" +
    "\n" +
    "                        options=\"vm.bondNumberGroups\"\r" +
    "\n" +
    "                        aa-option-value=\"id\"\r" +
    "\n" +
    "                        aa-option-text=\"name\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondNumberType === 'auto'\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondNumberType === 'auto'\"\r" +
    "\n" +
    "                        ng-disabled=\"!vm.bond.writingCompanyId\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        id=\"issue-bond-bond-number\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.bondNumber\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-show=\"vm.bondNumberType === 'manual'\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondNumberType === 'manual'\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5 class=\"sub-heading\">Pricing</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.rateType\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        ng-disabled=\"vm.ngDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"RatePerThousand\">Rate per Thousand</option>\r" +
    "\n" +
    "                        <option value=\"Tiered\">Tiered</option>\r" +
    "\n" +
    "                        <option value=\"Fixed\">Fixed</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.fixedRate\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        ng-change=\"vm.rateChange()\"\r" +
    "\n" +
    "                        ng-required=\"vm.bond.rateType=='Fixed'\"\r" +
    "\n" +
    "                        ng-show=\"vm.bond.rateType == 'Fixed'\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.ngDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.ratePerThousand\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        ng-required=\"vm.bond.rateType =='RatePerThousand'\"\r" +
    "\n" +
    "                        ng-show=\"vm.bond.rateType == 'RatePerThousand'\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.ngDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bond.minimumPremium\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.ngDisabled\"\r" +
    "\n" +
    "                        ng-required=\"vm.bond.rateType !== 'Fixed'\"\r" +
    "\n" +
    "                        ng-show=\"vm.bond.rateType === 'RatePerThousand'\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"clearfix\"></div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div ng-show=\"vm.bond.rateType === 'Tiered'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-sm-4\"\r" +
    "\n" +
    "                            ng-show=\"!vm.ngDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.bond.$newTierRate\"\r" +
    "\n" +
    "                                aa-label=\"Rate per thousand\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                ng-disabled=\"vm.ngDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                class=\"red small\"\r" +
    "\n" +
    "                                ng-show=\"!vm.bond.$newTierRate && vm.bond.$tierAdding\">\r" +
    "\n" +
    "                                Tier rate is required\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-sm-4\"\r" +
    "\n" +
    "                            ng-show=\"!vm.ngDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"field-flex-box\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.bond.$newThreshold\"\r" +
    "\n" +
    "                                    aa-label=\"For bond amount up to\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                    ng-disabled=\"vm.ngDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <button ng-click=\"vm.addNewTierRate()\">Add</button>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                class=\"red small\"\r" +
    "\n" +
    "                                ng-show=\"!vm.bond.$newThreshold && vm.bond.$tierAdding\">\r" +
    "\n" +
    "                                Tier threshold is required\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.bond.minimumPremium\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-4\"\r" +
    "\n" +
    "                            ng-disabled=\"vm.ngDisabled\"\r" +
    "\n" +
    "                            ng-required=\"vm.bond.rateType !== 'Fixed'\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"col-sm-12 field-wrapper\">\r" +
    "\n" +
    "                            <table>\r" +
    "\n" +
    "                                <thead>\r" +
    "\n" +
    "                                    <tr>\r" +
    "\n" +
    "                                        <th colspan=\"3\">Tiers</th>\r" +
    "\n" +
    "                                    </tr>\r" +
    "\n" +
    "                                </thead>\r" +
    "\n" +
    "                                <tbody>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <tr ng-repeat=\"tier in vm.bond.rateTiers\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td>\r" +
    "\n" +
    "                                            <span ng-show=\"$index == 0\">\r" +
    "\n" +
    "                                                &lt;</span>\r" +
    "\n" +
    "                                                    <span ng-show=\"$index > 0\">{{vm.bond.rateTiers[$index - 1].thresholdAmount | currency:\"$\"}} -</span>\r" +
    "\n" +
    "                                                    {{tier.thresholdAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td>\r" +
    "\n" +
    "                                            {{tier.ratePerThousand | currency:\"$\"}}\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td class=\"text-right\">\r" +
    "\n" +
    "                                            <button ng-click=\"vm.removeTierRate($index)\">\r" +
    "\n" +
    "                                                <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                                                <span>Remove</span>\r" +
    "\n" +
    "                                            </button>\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "                                    </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <tr\r" +
    "\n" +
    "                                        class=\"noRecords\"\r" +
    "\n" +
    "                                        ng-show=\"!vm.bond.rateTiers || vm.bond.rateTiers.length == 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td colspan=\"3\">\r" +
    "\n" +
    "                                            <span>No Tiers Assigned</span>\r" +
    "\n" +
    "                                            <p>\r" +
    "\n" +
    "                                                <strong class=\"red\">You must have at least one tier for tiered rates</strong>\r" +
    "\n" +
    "                                            </p>\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "                                    </tr>\r" +
    "\n" +
    "                                </tbody>\r" +
    "\n" +
    "                            </table>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div> \r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <commission-dropdown\r" +
    "\n" +
    "                        class=\"col-sm-6 flex\"\r" +
    "\n" +
    "                        controller=\"vm.commissionDropdownController\"\r" +
    "\n" +
    "                        commission-percent=\"vm.bond.commissionPercent\"\r" +
    "\n" +
    "                        commission-fixed-amount=\"vm.bond.commissionFixedAmount\"\r" +
    "\n" +
    "                        commission-type=\"vm.bond.commissionType\"\r" +
    "\n" +
    "                        writing-company-id=\"vm.bond.writingCompanyId\"\r" +
    "\n" +
    "                        carrier-system-account-id=\"vm.carrierSystemAccountId\"\r" +
    "\n" +
    "                        bond-type-id=\"vm.bond.bondTypeId\"\r" +
    "\n" +
    "                        dropdown-class=\"fill\"\r" +
    "\n" +
    "                        other-class=\"fill left-margin\">\r" +
    "\n" +
    "                    </commission-dropdown>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"top-margin-big\">\r" +
    "\n" +
    "                    <h5 class=\"sub-heading\">Fees</h5>\r" +
    "\n" +
    "                    <fee-list fees=\"vm.bond.fees\"></fee-list>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"issue-bond-cancel-button\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"issue-bond-submit-button\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "            class=\"left-margin\">\r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/modals/leadModal/leadModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <h4>Lead Details</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <h3 class=\"no-margin\">\r" +
    "\n" +
    "            {{vm.lead.leadType}}\r" +
    "\n" +
    "        </h3>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"row top-margin\">\r" +
    "\n" +
    "            <div class=\"col-sm-12\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <strong>\r" +
    "\n" +
    "                    {{vm.lead.bondType.name}} \r" +
    "\n" +
    "                    <span ng-show=\"vm.lead.bondAmount\">\r" +
    "\n" +
    "                        - \r" +
    "\n" +
    "                        {{vm.lead.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Number of Carriers Represented:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.carrierCount\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.carrierCount\">{{vm.lead.carrierCount}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Number of Prequalified Quotes Presented:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.prequalifiedQuoteCount\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.prequalifiedQuoteCount\">{{vm.lead.prequalifiedQuoteCount}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Desired Effective Date:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.desiredEffectiveDate\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.desiredEffectiveDate\">{{vm.lead.desiredEffectiveDate | dateOnly}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Name on Bond:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.nameOnBond\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.\">{{vm.lead.nameOnBond}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Premiums Presented:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.lowestPremium\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.lowestPremium\">{{vm.lead.lowestPremium | currency: '$'}} to {{vm.lead.highestPremium | currency: '$'}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                IP Address:\r" +
    "\n" +
    "                <span ng-show=\"!vm.lead.ipAddress\">Unknown</span>\r" +
    "\n" +
    "                <strong ng-show=\"vm.lead.ipAddress\">{{vm.lead.ipAddress}}</strong>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-12\">\r" +
    "\n" +
    "                <tag-container lead-id=\"vm.leadId\"></tag-container>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset>\r" +
    "\n" +
    "            <uib-tab ng-repeat=\"tab in vm.dynamicTabs\">\r" +
    "\n" +
    "                <uib-tab-heading>{{tab.heading}}</uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <company \r" +
    "\n" +
    "                    company=\"vm.lead.companies[tab.index]\" \r" +
    "\n" +
    "                    disabled \r" +
    "\n" +
    "                    ng-if=\"tab.type=='Company'\">\r" +
    "\n" +
    "                </company>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <person \r" +
    "\n" +
    "                    person=\"vm.lead.people[tab.index]\" \r" +
    "\n" +
    "                    ng-if=\"tab.type=='Person'\" \r" +
    "\n" +
    "                    disabled>\r" +
    "\n" +
    "                </person>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section \r" +
    "\n" +
    "                    ng-show=\"tab.type=='AdditionalQuestions'\" \r" +
    "\n" +
    "                    class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5 ng-show=\"vm.lead.leadQuestionResponses.length\">Application Questions</h5>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div ng-repeat=\"question in vm.lead.leadQuestionResponses\">\r" +
    "\n" +
    "                        {{question.questionText}}: <strong>{{question.responseText}}</strong>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <empty-state \r" +
    "\n" +
    "                        ng-show=\"!vm.lead.leadQuestionResponses.length\" \r" +
    "\n" +
    "                        message=\"No additional application questions were completed\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Email Follow-ups \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"badge\" \r" +
    "\n" +
    "                        ng-show=\"vm.lead.followUps.length\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{vm.lead.followUps.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <follow-up-list \r" +
    "\n" +
    "                    follow-ups=\"vm.lead.followUps\" \r" +
    "\n" +
    "                    lead-id=\"vm.lead.id\">\r" +
    "\n" +
    "                </follow-up-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/markBondForReviewModal/markBondForReviewModal.html',
    "<div busy-indicator=\"{promise: vm.loadingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <h4>Bond Review</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"reviewForm\">\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <p>Please provide an explaination for marking this bond for review.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            aa-field-group=\"vm.reviewComments\"\r" +
    "\n" +
    "            aa-label=\"Comments\"\r" +
    "\n" +
    "            required >\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Submit\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/newCustomerModal/newCustomerModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b>New Customer</b>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "            <span>Customer type:</span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex fill left-margin\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"new-customer-customer-type\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.newCustomer.customerType\"\r" +
    "\n" +
    "                    aa-wrapper-class=\"no-margin flex\"\r" +
    "\n" +
    "                    aa-fg-class=\"left-margin\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                    options=\"vm.customerTypeOptions\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <person \r" +
    "\n" +
    "            ng-if=\"vm.newCustomer.customerType == 'Individual'\" \r" +
    "\n" +
    "            person=\"vm.newCustomer.contact\">\r" +
    "\n" +
    "        </person>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <company\r" +
    "\n" +
    "            ng-if=\"vm.newCustomer.customerType == 'Company'\"\r" +
    "\n" +
    "            company=\"vm.newCustomer.company\">\r" +
    "\n" +
    "        </company>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"new-customer-cancel-button\"\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"new-customer-save-button\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/newInvoiceModal/newInvoiceModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"newInvoiceForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        <h4>New Invoice</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"vm.bondTransactions.length\" \r" +
    "\n" +
    "            class=\"bottom-margin-big\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p>\r" +
    "\n" +
    "                Select the billable items below to add to the new invoice. You must select at least one to proceed.\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-fg-class=\"no-top-margin\"\r" +
    "\n" +
    "                aa-field-group=\"vm.selectAll\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                aa-label=\"Select All Transactions\"\r" +
    "\n" +
    "                ng-change=\"vm.handleSelectAll()\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <hr class=\"no-margin\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"top-margin\" \r" +
    "\n" +
    "            ng-repeat=\"transaction in vm.bondTransactions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-fg-class=\"no-top-margin\"\r" +
    "\n" +
    "                    aa-field-group=\"transaction.isSelected\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                    aa-label-strategy=\"none\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"fill v-center\">\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        {{ transaction | transactionType }}\r" +
    "\n" +
    "                        <span> - </span>\r" +
    "\n" +
    "                        #{{transaction.bondNumber}}\r" +
    "\n" +
    "                        <span> | </span>\r" +
    "\n" +
    "                        {{ transaction.effectiveDate | dateOnly }}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"flex small\" \r" +
    "\n" +
    "                        ng-repeat=\"billingEntry in transaction.billingEntries\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"fill\">\r" +
    "\n" +
    "                            {{billingEntry.description}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        {{billingEntry.amountDue | currency: '$'}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"!vm.bondTransactions.length\"\r" +
    "\n" +
    "            message=\"No billable items were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            ng-click=\"vm.submit()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/obligeeHistoryModal/obligeeHistoryModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    <b>Obligee History</b>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-form=\"historySearchForm\"\r" +
    "\n" +
    "        class=\"bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                aa-field-group=\"vm.obligeeHistoryTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                ng-change=\"vm.obligeeHistoryTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                focus-me\r" +
    "\n" +
    "                aa-label=\"Search History\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div ng-show=\"vm.obligeeHistoryTable.isVisible\">\r" +
    "\n" +
    "        <table class=\"bottom-margin\">\r" +
    "\n" +
    "            <thead>               \r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>               \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Saved On\"\r" +
    "\n" +
    "                            sort-expression=\"CreatedDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.obligeeHistoryTable.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>                  \r" +
    "\n" +
    "                    <th>Changes</th>\r" +
    "\n" +
    "                </tr>  \r" +
    "\n" +
    "            </thread>      \r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"historyEntry in vm.obligeeHistoryTable.data\">\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <user-avatar                              \r" +
    "\n" +
    "                                user-id=\"historyEntry.userId\"\r" +
    "\n" +
    "                                class=\"mini v-center right-margin\">\r" +
    "\n" +
    "                            </user-avatar>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"v-center nowrap\">\r" +
    "\n" +
    "                                <b ng-bind-html=\"historyEntry.createdByUserFullName | highlight:vm.currentSearchPhrase\"></b>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                <span class=\"small\">{{historyEntry.createdDateTime | dateOnly}}</span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                 \r" +
    "\n" +
    "                    <td class=\"small text-left\" colspan=\"2\">\r" +
    "\n" +
    "                        <div ng-repeat=\"noteItem in historyEntry.obligeeHistoryNotes track by $index\">\r" +
    "\n" +
    "                            <span ng-bind-html=\"noteItem.note | highlight:vm.currentSearchPhrase\"></span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.obligeeHistoryTable.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.obligeeHistoryTable.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No history entries found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/obligeeModal/modalObligee.html',
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        <h4>New Obligee</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <obligee-form \r" +
    "\n" +
    "        entry-mode=\"master\" \r" +
    "\n" +
    "        busy-indicator=\"vm.busyIndicator\" \r" +
    "\n" +
    "        on-save=\"vm.save(obligee)\" \r" +
    "\n" +
    "        on-cancel=\"vm.cancel()\" \r" +
    "\n" +
    "        class=\"text-left\"\r" +
    "\n" +
    "        obligee=\"vm.obligee\">\r" +
    "\n" +
    "    </obligee-form>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/odataFiltersModal/odataFiltersModal.html',
    "<form name=\"searchForm\" >\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4 class=\"modal-title\">Advanced Search</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-repeat=\"filter in vm.filterCollection.filters\" \r" +
    "\n" +
    "            ng-show=\"filter.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'daysTill'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} within </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"integer\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-label=\"# of days\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- Money Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'money'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} between </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                    aa-label=\"Min {{filter.label}}\"/>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <label class=\"col-sm-1 inline-label\"> and </label>\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.maxValue\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                    aa-label=\"Max {{filter.label}}\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- Date Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'date'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} between </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                    aa-label=\"Min {{filter.label}}\"/>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <label class=\"col-sm-1 inline-label\"> and </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.maxValue\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                    aa-label=\"Max {{filter.label}}\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- State Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'state'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} is </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-label=\"{{filter.label}}\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- String Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'string'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} {{filter.comparisonText ? filter.comparisonText : 'contains '}}</label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"{{filter.label}}\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- String Not Contains Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'stringNotContains'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} doesn't contain </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"{{filter.label}}\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- Select Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'select'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} {{filter.comparisonText ? filter.comparisonText : 'is '}}</label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"{{filter.label}}\" \r" +
    "\n" +
    "                    aa-unselected-text=\"All {{filter.label}}\" \r" +
    "\n" +
    "                    options=\"filter.selectOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- Sfaa Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'sfaa'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} {{filter.comparisonText ? filter.comparisonText : 'is '}}</label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <sfaa-code-selection \r" +
    "\n" +
    "                    sfaa-code-code=\"filter.value\" \r" +
    "\n" +
    "                    class=\"col-sm-9\">\r" +
    "\n" +
    "                </sfaa-code-selection>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- Bool Filter -->\r" +
    "\n" +
    "            <div ng-if=\"filter.dataType === 'bool'\">\r" +
    "\n" +
    "                <label class=\"col-sm-3 inline-label text-right\">{{filter.label}} is </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    show-label=\"false\" \r" +
    "\n" +
    "                    aa-field-group=\"filter.value\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-9\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                    aa-label=\"{{filter.label}}\" \r" +
    "\n" +
    "                    aa-unselected-text=\"All {{filter.label}}\" \r" +
    "\n" +
    "                    options=\"filter.selectOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "            value=\"Search\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/modals/paymentAssignmentModal/paymentAssignmentModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    " \r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>\r" +
    "\n" +
    "            <span ng-show=\"vm.payment.paymentMethod == 'Return'\">Returned </span>\r" +
    "\n" +
    "            Payment Detail\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left no-bottom-margin\">\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div class=\"fill\">\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <div class=\"text-right width-8-rem\">\r" +
    "\n" +
    "                            Payment #:\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            Date: \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"left-margin\">\r" +
    "\n" +
    "                            {{vm.payment.paymentTransactionId}}\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            {{vm.payment.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex top-margin\">\r" +
    "\n" +
    "                        <div class=\"text-right width-8-rem\">\r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.customerId && !vm.payment.eProducerAccountId\">\r" +
    "\n" +
    "                                Customer:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.eProducerAccountId\">\r" +
    "\n" +
    "                                Broker:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"left-margin\">\r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.customerId && !vm.payment.eProducerAccountId\">\r" +
    "\n" +
    "                                <span ng-show=\"vm.payment.customerLookupCode\">\r" +
    "\n" +
    "                                    {{vm.payment.customerLookupCode}} -&nbsp;\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                {{vm.payment.customerName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.eProducerAccountId\">\r" +
    "\n" +
    "                                <span ng-show=\"vm.payment.brokerLookupCode\">\r" +
    "\n" +
    "                                    {{vm.payment.brokerLookupCode}} -&nbsp;\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                {{vm.payment.brokerName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div>\r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-show=\"!vm.payment.invoices.length\"\r" +
    "\n" +
    "                                    ng-click=\"vm.clearCustomerBrokerAssignment()\"> \r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Change Customer or Broker\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex top-margin\">\r" +
    "\n" +
    "                        <div class=\"text-right width-8-rem\">\r" +
    "\n" +
    "                            Paid By:\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"left-margin\">\r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.companyName\">\r" +
    "\n" +
    "                                {{vm.payment.companyName}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.name\">\r" +
    "\n" +
    "                                {{vm.payment.name}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <div>\r" +
    "\n" +
    "                                {{vm.payment.phone}}\r" +
    "\n" +
    "                                <span ng-show=\"vm.payment.phone && vm.payment.email\"> | </span>\r" +
    "\n" +
    "                                {{vm.payment.email}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <div ng-show=\"vm.payment.streetAddress\">\r" +
    "\n" +
    "                                {{vm.payment.streetAddress}}\r" +
    "\n" +
    "                                {{vm.payment.suiteAptNumber}} <br />\r" +
    "\n" +
    "                                {{vm.payment.city}}, {{vm.payment.state}},\r" +
    "\n" +
    "                                {{vm.payment.zipCode}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"flex top-margin\">\r" +
    "\n" +
    "                        <div class=\"text-right width-8-rem\">\r" +
    "\n" +
    "                            Comments:\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div class=\"left-margin\">\r" +
    "\n" +
    "                            {{vm.payment.comments}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"left-margin\">\r" +
    "\n" +
    "                    <div class=\"text-right bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-class=\"{\r" +
    "\n" +
    "                                'green': vm.payment.paymentStatus === 'Approved', \r" +
    "\n" +
    "                                'red': vm.payment.paymentStatus !== 'Approved'\r" +
    "\n" +
    "                            }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{vm.payment.paymentStatus}}\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.paymentStatus !== 'Approved'\">\r" +
    "\n" +
    "                                &nbsp;\r" +
    "\n" +
    "                                <i\r" +
    "\n" +
    "                                    class=\"fa fa-info-circle small\"\r" +
    "\n" +
    "                                    uib-tooltip={{payment.message}}>\r" +
    "\n" +
    "                                </i>  \r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <div class=\"text-right fill right-margin-super\">\r" +
    "\n" +
    "                            Amount: <br/>\r" +
    "\n" +
    "                            Payment Method: <br/>\r" +
    "\n" +
    "                            Submitted Through: <br/>\r" +
    "\n" +
    "                            Submitted By: <br/>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Account:\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.transactionId\">\r" +
    "\n" +
    "                                Merchant Transaction Id:\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.paymentMethod == 'Check'\">\r" +
    "\n" +
    "                                Check #:\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"top-margin-big\">\r" +
    "\n" +
    "                                Unapplied:\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"text-right\">\r" +
    "\n" +
    "                            {{vm.payment.amount | currency: '$'}} \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{vm.payment.paymentMethod}} \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.paymentOrigin && vm.payment.paymentOrigin != 'Unknown'\">\r" +
    "\n" +
    "                                {{vm.payment.paymentOriginDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <i ng-show=\"!vm.payment.paymentOrigin || vm.payment.paymentOrigin == 'Unknown'\">\r" +
    "\n" +
    "                                Unknown\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span>{{vm.payment.paymentMadeBy}}</span>\r" +
    "\n" +
    "                            <i ng-show=\"!vm.payment.paymentMadeBy\">\r" +
    "\n" +
    "                                Unknown\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.accountNumber\">\r" +
    "\n" +
    "                                {{vm.payment.paymentInstitutionName}} Ending in {{vm.payment.accountNumber}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <i ng-show=\"!vm.payment.accountNumber\">\r" +
    "\n" +
    "                                N/A\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.transactionId\">\r" +
    "\n" +
    "                                {{vm.payment.transactionId}}\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"vm.payment.paymentMethod == 'Check'\">\r" +
    "\n" +
    "                                {{vm.payment.checkNumber}}\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex top-margin-big\">\r" +
    "\n" +
    "                                <i \r" +
    "\n" +
    "                                    ng-show=\"vm.unappliedAmount === 0\"\r" +
    "\n" +
    "                                    class=\"v-center left-margin-auto text-large fa fa-check green\">\r" +
    "\n" +
    "                                </i>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i \r" +
    "\n" +
    "                                    ng-show=\"vm.unappliedAmount !== 0\"\r" +
    "\n" +
    "                                    class=\"v-center left-margin-auto text-large fa fa-exclamation-triangle red\">\r" +
    "\n" +
    "                                </i>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span class=\"v-center left-margin\"> {{vm.unappliedAmount | currency: '$'}}</span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset\r" +
    "\n" +
    "            class=\"tab-set\"\r" +
    "\n" +
    "            ng-show=\"vm.payment.customerId || vm.payment.eProducerAccountId\">\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    Billing Entries\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.payment.billingEntryPaymentAssignments.length\"\r" +
    "\n" +
    "                        class=\"badge left-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.payment.billingEntryPaymentAssignments.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <div class=\"flex bottom-margin-thin\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.searchPhrase\"\r" +
    "\n" +
    "                            aa-label=\"Search by Bond or Invoice Number\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin fixed-width-lg\" \r" +
    "\n" +
    "                            ng-change=\"vm.searchPhraseChanged()\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table \r" +
    "\n" +
    "                        ng-show=\"vm.payment.openBillingEntries.length || vm.payment.billingEntryPaymentAssignments.length\"\r" +
    "\n" +
    "                        class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr class=\"text-bold\">\r" +
    "\n" +
    "                                <th class=\"fit-content\">#</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Bond Number</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Type</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Transaction</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Effective Date</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Transaction Date</th>\r" +
    "\n" +
    "                                <th>Description</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Invoice #</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Billed Amount</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Broker Commission</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Due</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Applied</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <tbody>    \r" +
    "\n" +
    "                            <tr ng-show=\"vm.payment.billingEntryPaymentAssignments.length\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <b>Assigned Billing Entries</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-repeat=\"billingEntryAssignment in vm.payment.billingEntryPaymentAssignments\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{billingEntryAssignment.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{billingEntryAssignment.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in billingEntryAssignment.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{billingEntryAssignment.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{billingEntryAssignment.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountClicked(billingEntryAssignment)\">\r" +
    "\n" +
    "                                        {{billingEntryAssignment.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"billingEntryAssignment.amount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"billingEntryAssignment.originalAmount > 0\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"billingEntryAssignment.amount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"billingEntryAssignment.originalAmount < 0\"/>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>Outstanding Billing Entries</b>\r" +
    "\n" +
    "                                    <span ng-show=\"vm.searchPhrase && vm.filteredOpenBillingEntries.length\">\r" +
    "\n" +
    "                                        <span> - </span>\r" +
    "\n" +
    "                                        <a ng-click=\"vm.assignAllSearchResultsClicked()\">Assign All</a>\r" +
    "\n" +
    "                                    </span> \r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-repeat=\"openBillingEntry in vm.appliedOpenBillingEntries\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{openBillingEntry.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in openBillingEntry.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountDueClicked(openBillingEntry)\">    \r" +
    "\n" +
    "                                        {{openBillingEntry.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue > 0\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue < 0\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-repeat=\"openBillingEntry in vm.filteredOpenBillingEntries\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.bondNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.billingEntryType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry | transactionType}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionEffectiveDate | dateOnly }}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openBillingEntry.transactionDateTime | dateOnly }}</td>\r" +
    "\n" +
    "                                <td>{{openBillingEntry.description}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in openBillingEntry.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.originalAmount | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openBillingEntry.brokerCommission | currency:'$' }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.amountDueClicked(openBillingEntry)\">\r" +
    "\n" +
    "                                        {{openBillingEntry.amountDue | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue > 0\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openBillingEntry.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-show=\"openBillingEntry.amountDue < 0\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    ng-show=\"!vm.filteredOpenBillingEntries.length\"\r" +
    "\n" +
    "                                    colspan=\"100\" \r" +
    "\n" +
    "                                    class=\"text-large text-center opaque top-padding bottom-padding\">                        \r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>No Billing Entries Found</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"!vm.payment.openBillingEntries.length && !vm.payment.billingEntryPaymentAssignments.length\"\r" +
    "\n" +
    "                        message=\"No billing entries were found with an open balance\">\r" +
    "\n" +
    "                    </empty-state>   \r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab>\r" +
    "\n" +
    "                <uib-tab-heading>\r" +
    "\n" +
    "                    <span ng-show=\"vm.payment.paymentMethod == 'Return'\">Payments</span>\r" +
    "\n" +
    "                    <span ng-show=\"vm.payment.paymentMethod != 'Return'\">Returned Payments</span>\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        ng-show=\"vm.payment.paymentTransactionAssignments.length\"\r" +
    "\n" +
    "                        class=\"badge left-margin-thin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.payment.paymentTransactionAssignments.length}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </uib-tab-heading>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.payment.paymentMethod != 'Return' && vm.payment.paymentTransactionAssignments.length\" \r" +
    "\n" +
    "                        class=\"alert alert-info text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <b>IMPORTANT NOTE: </b> This payment is offset by a returned payment. To make changes, you must open the detail for the return payment and make updates there.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table \r" +
    "\n" +
    "                        ng-show=\"vm.payment.paymentTransactionAssignments.length || vm.payment.openPaymentEntries.length\"\r" +
    "\n" +
    "                        class=\"small\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr class=\"text-bold\">\r" +
    "\n" +
    "                                <th class=\"fit-content\">#</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Date / Time</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Payment Method</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Payment Processor Id</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Account #</th>\r" +
    "\n" +
    "                                <th>Comments</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Payment Amount</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Unapplied Amount</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Applied</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.payment.paymentTransactionAssignments.length\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>Assigned Payment Entries</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-repeat=\"paymentAssignment in vm.payment.paymentTransactionAssignments\">\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{paymentAssignment.paymentTransactionId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{paymentAssignment.createdDateTime | dateOnly}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{paymentAssignment.paymentMethod}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{paymentAssignment.transactionId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{paymentAssignment.accountNumber }}</td>\r" +
    "\n" +
    "                                <td>{{paymentAssignment.comments}}</td>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{paymentAssignment.amount | currency:'$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        ng-show=\"vm.payment.paymentMethod == 'Return'\"\r" +
    "\n" +
    "                                        ng-click=\"vm.paymentAppliedAmountClicked(paymentAssignment)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{paymentAssignment.unappliedAmount  | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"vm.payment.paymentMethod != 'Return'\">\r" +
    "\n" +
    "                                        {{paymentAssignment.unappliedAmount  | currency:'$' }}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"paymentAssignment.appliedAmount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-disabled=\"vm.payment.paymentMethod != 'Return'\"\r" +
    "\n" +
    "                                        ng-show=\"paymentAssignment.amount > 0\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"paymentAssignment.appliedAmount\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"moneyNegative\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"\r" +
    "\n" +
    "                                        ng-disabled=\"vm.payment.paymentMethod != 'Return'\"\r" +
    "\n" +
    "                                        ng-show=\"paymentAssignment.amount < 0\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-show=\"vm.payment.paymentMethod == 'Return'\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"top-padding bottom-padding\"\r" +
    "\n" +
    "                                    colspan=\"100\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>Open Payment Entries</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr \r" +
    "\n" +
    "                                ng-show=\"vm.payment.paymentMethod == 'Return'\"\r" +
    "\n" +
    "                                ng-repeat=\"openPaymentTransaction in vm.payment.openPaymentEntries\">    \r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openPaymentTransaction.paymentTransactionId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openPaymentTransaction.createdDateTime | localDateTime}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openPaymentTransaction.paymentMethod}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openPaymentTransaction.transactionId}}</td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">{{openPaymentTransaction.accountNumber }}</td>\r" +
    "\n" +
    "                                <td>{{openPaymentTransaction.comments}}</td>  \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    {{openPaymentTransaction.amount | currency:'$'}}\r" +
    "\n" +
    "                                </td>              \r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                                    <a ng-click=\"vm.paymentAmountToApplyClicked(openPaymentTransaction)\">    \r" +
    "\n" +
    "                                        {{openPaymentTransaction.unappliedAmount | currency:'$' }}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"fit-content\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-field-group=\"openPaymentTransaction.amountToApply\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                        ng-change=\"vm.setTotalAmountApplied()\"\r" +
    "\n" +
    "                                        aa-fg-class=\"no-margin fixed-width-sm height-auto\"/>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-show=\"!vm.payment.openPaymentEntries.length && vm.payment.paymentMethod == 'Return'\">\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    colspan=\"100\"\r" +
    "\n" +
    "                                    class=\"text-large text-center opaque top-padding bottom-padding\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <b>No Unapplied Payments</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"!vm.payment.openPaymentEntries.length && !vm.payment.paymentTransactionAssignments.length\"\r" +
    "\n" +
    "                        message=\"No payments were found\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <section \r" +
    "\n" +
    "            ng-show=\"!vm.payment.customerId && !vm.payment.eProducerAccountId\"\r" +
    "\n" +
    "            class=\"top-margin text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <customer-or-eproducer-broker-selection\r" +
    "\n" +
    "                on-account-selected=\"vm.accountSelected(selectedAccount)\">\r" +
    "\n" +
    "            </customer-or-eproducer-broker-selection>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <div class=\"text-left v-center red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.showOverAppliedMessage\">\r" +
    "\n" +
    "                The applied amount exceeds payment amount by {{vm.overAppliedAmount | currency: \"$\"}}.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                <b>You cannot proceed until the applied amount is reduced.</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.showUnderAppliedMessage\">\r" +
    "\n" +
    "                The amount applied is less than the payment amount.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                This will leave cash on account in the amount of {{vm.underAppliedAmount | currency: \"$\"}}.\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.showOverAppliedReturnMessage\">\r" +
    "\n" +
    "                The applied amount exceeds the total return by {{vm.overAppliedAmount | currency: \"$\"}}.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                <b>You cannot proceed until the payment amount is reduced.</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.showUnderAppliedReturnMessage\">\r" +
    "\n" +
    "                The amount applied is less than the total amount of the return.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                <b> This will leave a credit on account in the amount of {{vm.underAppliedAmount | currency: \"$\"}}.</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"fill v-center\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\"\r" +
    "\n" +
    "                type=\"button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                aa-submit-form=\"vm.submitAssignment()\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isSubmitButtonDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Submit\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/paymentModal/paymentModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"purchaseBondForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        <h4>Process Payment</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"alert alert-danger\"\r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\">\r" +
    "\n" +
    "            <b>Payment Declined</b>\r" +
    "\n" +
    "            <br />\r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5>Payment Amount</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.payment.amount\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "            ng-blur=\"vm.checkForPaymentTransactionFees()\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"row\"\r" +
    "\n" +
    "            ng-show=\"vm.payment.processingFee > 0\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Processing Fee: {{vm.processingFeeFormatted}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                Total Charged: {{vm.totalFormatted}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <h5>Billing Information</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.selectedPaymentContactIndex\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    aa-label=\"Payment Received From\"\r" +
    "\n" +
    "                    options=\"vm.paymentContactOptions\"\r" +
    "\n" +
    "                    ng-change=\"vm.paymentContactSelected()\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.companyName\"\r" +
    "\n" +
    "                    aa-label=\"Company\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.firstName\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod === 'CreditCard' || !vm.payment.companyName\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.lastName\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod === 'CreditCard' || !vm.payment.companyName\"/>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.address\"\r" +
    "\n" +
    "                    ng-required=\"vm.payment.paymentMethod === 'CreditCard' || vm.payment.paymentMethod === 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.zip\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"zip\"\r" +
    "\n" +
    "                    ng-required=\"vm.payment.paymentMethod === 'CreditCard' || vm.payment.paymentMethod === 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.city\"\r" +
    "\n" +
    "                    ng-required=\"vm.payment.paymentMethod === 'CreditCard' || vm.payment.paymentMethod === 'ACH'\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.state\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                    ng-required=\"vm.payment.paymentMethod === 'CreditCard' || vm.payment.paymentMethod === 'ACH'\" >\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <h5>Payment</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.paymentMethod\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.paymentMethods\"\r" +
    "\n" +
    "                    ng-change=\"vm.checkForPaymentTransactionFees()\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-show=\"vm.payment.paymentMethod === 'ACH'\"\r" +
    "\n" +
    "                    class=\"row\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.accountNumber\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.confirmAccountNumber\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'ACH'\"\r" +
    "\n" +
    "                        ng-match=\"vm.payment.accountNumber\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.routingNumber\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.accountType\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'ACH'\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.achAccountTypeOptions\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div ng-show=\"vm.payment.paymentMethod === 'CreditCard'\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.cardNumber\"\r" +
    "\n" +
    "                        maxlength=\"16\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'CreditCard'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.expirationMonth\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.creditCardExpirationMonth\"\r" +
    "\n" +
    "                            ng-required=\"vm.payment.paymentMethod == 'CreditCard'\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.expirationYear\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.creditCardExpirationYear\"\r" +
    "\n" +
    "                            ng-required=\"vm.payment.paymentMethod == 'CreditCard'\"></select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.securityCode\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod == 'CreditCard'\"\r" +
    "\n" +
    "                        maxlength=\"4\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-if=\"vm.payment.paymentMethod === 'Check'\"\r" +
    "\n" +
    "                    class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.checkNumber\"\r" +
    "\n" +
    "                        ng-required=\"vm.payment.paymentMethod === 'Check'\"\r" +
    "\n" +
    "                        maxlength=\"15\">\r" +
    "\n" +
    "                    </input>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-if=\"vm.payment.paymentMethod === 'Other' || vm.payment.paymentMethod === 'Return' ||\r" +
    "\n" +
    "                           vm.payment.paymentMethod === 'Check' || vm.payment.paymentMethod === 'WireTransfer'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.createdDateTime\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                        aa-label=\"Processing Date\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"text-center top-margin\"\r" +
    "\n" +
    "                        ng-show=\"vm.payment.paymentMethod === 'Other' || vm.payment.paymentMethod === 'Return'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <b>\r" +
    "\n" +
    "                            Please provide additional information in the comments\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <textarea\r" +
    "\n" +
    "            aa-field-group=\"vm.payment.comments\"\r" +
    "\n" +
    "            ng-required=\"vm.payment.paymentMethod === 'Other' || vm.payment.paymentMethod === 'Return'\">\r" +
    "\n" +
    "        </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.termsConfirmed\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "            required-msg=\"You must confirm before proceeding\"\r" +
    "\n" +
    "            aa-label=\"I understand that this is the final confirmation before submitting this payment and that I have full permission of the customer to submit payment on their behalf.\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.submitPayment()\">Process Payment</button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/modals/purchaseQuoteModal/purchaseQuoteModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"flex column height-100p\"\r" +
    "\n" +
    "    ng-form=\"purchaseBondForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Purchase Quote</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"alert alert-danger text-left\" \r" +
    "\n" +
    "                ng-show=\"vm.errorMessage\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{vm.errorMessage}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <system-account-logo\r" +
    "\n" +
    "                    ng-if=\"vm.quote\"\r" +
    "\n" +
    "                    system-account-id=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                    company-name=\"vm.quote.carrier\"\r" +
    "\n" +
    "                    writing-company-name=\"vm.quote.writingCompany\"\r" +
    "\n" +
    "                    show-labels=\"true\">\r" +
    "\n" +
    "            </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    <div class=\"top-margin flex\">\r" +
    "\n" +
    "                        <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                            Name on Bond:\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.quote.nameOnBond}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                            Quote Type:\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"vm.quote.quoteType == 'NewBusiness'\">New Business</span>\r" +
    "\n" +
    "                        <span ng-show=\"vm.quote.quoteType== 'Renewal'\">Renewal</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                            Bond Type:\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.quote.bondType | limitTo:50}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                            Bond Amount:\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.quote.bondAmount | currency:\"$\"}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex top-margin\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                                Effective Date:\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.quote.effectiveDate\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center small width-90\"\r" +
    "\n" +
    "                                class=\"text-center\"\r" +
    "\n" +
    "                                ng-disabled=\"vm.quote.quoteType != 'NewBusiness'\"\r" +
    "\n" +
    "                                compare-date=\"vm.quote.expirationDate\"\r" +
    "\n" +
    "                                comparison-type=\"lessThanOrEqual\"\r" +
    "\n" +
    "                                compare-date-msg=\"Invalid\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <div class=\"flex left-margin\">\r" +
    "\n" +
    "                            <span class=\"v-center right-margin inline-block\">\r" +
    "\n" +
    "                                Exp Date:\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.quote.expirationDate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center small width-90\"\r" +
    "\n" +
    "                                class=\"text-center\" \r" +
    "\n" +
    "                                ng-disabled=\"vm.quote.quoteType != 'NewBusiness'\"\r" +
    "\n" +
    "                                compare-date=\"vm.quote.effectiveDate\"\r" +
    "\n" +
    "                                comparison-type=\"greaterThanOrEqual\"\r" +
    "\n" +
    "                                compare-date-msg=\"Invalid\"\r" +
    "\n" +
    "                                required\r" +
    "\n" +
    "                                />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.quote.quoteType == 'NewBusiness'\" \r" +
    "\n" +
    "                        class=\"flex top-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"v-center width-100\">Bond Number:</div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            id=\"issue-bond-bond-number-group\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.quote.bondNumberGroupId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-unselected-text=\"Manually Assign\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                            options=\"vm.bondNumberGroupOptions\"\r" +
    "\n" +
    "                            ng-show=\"vm.quote.quoteType == 'NewBusiness'\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\" >\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-show=\"!vm.quote.bondNumberGroupId || vm.quote.quoteType != 'NewBusiness'\"\r" +
    "\n" +
    "                        class=\"flex top-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"v-center width-100\">\r" +
    "\n" +
    "                            <span ng-show=\"vm.quote.quoteType != 'NewBusiness'\">Bond Number:</span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            id=\"issue-bond-bond-number\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.quote.bondNumber\"\r" +
    "\n" +
    "                            ng-required=\"!vm.quote.bondNumberGroupId\"\r" +
    "\n" +
    "                            ng-disabled=\"vm.quote.quoteType != 'NewBusiness'\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center fill\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"fill left-margin-super\">\r" +
    "\n" +
    "                    <textarea\r" +
    "\n" +
    "                        aa-field-group=\"vm.quote.comments\"\r" +
    "\n" +
    "                        aa-label=\"Internal Comments\"\r" +
    "\n" +
    "                        aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                        disabled>\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>Billing</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"top-margin\">            \r" +
    "\n" +
    "                <div class=\"flex top-margin-big\">\r" +
    "\n" +
    "                    <div class=\"fill flex right-margin\">\r" +
    "\n" +
    "                        <div class=\"v-center width-100\">Billing Type:</div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.quote.billToType\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-fg-class=\"width-300 left-margin v-center\"\r" +
    "\n" +
    "                            ng-change=\"vm.billToTypeChanged()\"\r" +
    "\n" +
    "                            ng-disabled=\"!vm.quote.eProducerAccountId\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"fill left-margin\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.quote.expedited\" \r" +
    "\n" +
    "                            aa-label=\"Expedited Shipping & Processing\" \r" +
    "\n" +
    "                            ng-change=\"vm.expeditedChanged()\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"width-100\">Bill To:</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.quote.billToType == 'BillClient'\" \r" +
    "\n" +
    "                        class=\"left-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            Customer\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{vm.quote.customerName}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{vm.quote.customerLookupCode}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.quote.billToType == 'BillBrokerNet' || vm.quote.billToType == 'BillBrokerGross'\" \r" +
    "\n" +
    "                        class=\"left-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            Broker\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{vm.quote.brokerName}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            {{vm.quote.brokerLookupCode}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill right-margin\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <h5 class=\"bottom-margin\">Receivable</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-repeat=\"billingEntry in vm.billingEntries\" \r" +
    "\n" +
    "                            class=\"row\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                {{billingEntry.description}}    \r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                {{billingEntry.originalAmount | currency : '$'}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div ng-show=\"vm.quote.expedited && vm.expeditedFee > 0\">\r" +
    "\n" +
    "                                <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                    Expedited Processing Fee:\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                    {{ vm.expeditedFee | currency: '$'}}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-show=\"vm.quote.billToType == 'BillBrokerNet' && vm.quote.brokerCommission\">\r" +
    "\n" +
    "                                <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                    Less Broker Commission:\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                    {{ vm.quote.brokerCommission | currency: '$'}}\r" +
    "\n" +
    "                                    <span class=\"width-75 inline-block\"></span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-xs-12\">\r" +
    "\n" +
    "                                <hr class=\"no-margin\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-xs-6\">\r" +
    "\n" +
    "                                <b>Amount Due:</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                                <b>{{vm.quote.totalAmountDue | currency: \"$\"}}</b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill left-margin\">\r" +
    "\n" +
    "                        <h5>Carrier Payable</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex top-margin-thin bottom-margin\">\r" +
    "\n" +
    "                            <div class=\"width-100\">Paid To:</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div>\r" +
    "\n" +
    "                                {{vm.quote.carrier}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-repeat=\"billingEntry in vm.carrierPayableBillingEntries\" \r" +
    "\n" +
    "                            class=\"flex\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"fill\">\r" +
    "\n" +
    "                                {{billingEntry.description}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"left-margin\">\r" +
    "\n" +
    "                                {{billingEntry.carrierPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.quote.eProducerAccountId && vm.brokerPayableBillingEntries.length\">\r" +
    "\n" +
    "                            <h5>Broker Payable</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex top-margin-thin bottom-margin\">\r" +
    "\n" +
    "                                <div class=\"width-100\">Paid To:</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{vm.quote.brokerName}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{vm.quote.brokerLookupCode}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-repeat=\"billingEntry in vm.brokerPayableBillingEntries\" \r" +
    "\n" +
    "                                class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"fill\">\r" +
    "\n" +
    "                                    Broker Commission\r" +
    "\n" +
    "                                    <span ng-show=\"billingEntry.billingEntryType == 'Premium'\"> - Premium</span>\r" +
    "\n" +
    "                                    <span ng-show=\"billingEntry.billingEntryType == 'Fee'\"> - {{billingEntry.description}}</span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                                <div class=\"left-margin\">\r" +
    "\n" +
    "                                    {{billingEntry.brokerPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>            \r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <header class=\"flex\">\r" +
    "\n" +
    "                <span class=\"v-center right-margin-auto\">Payment</span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.processPayment\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\" />\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"alert alert-warning text-center top-margin\" \r" +
    "\n" +
    "                ng-show=\"!vm.processPayment\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                By not processing payment now, you'll be creating an open invoice for the entire balance.\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"row\" \r" +
    "\n" +
    "                ng-show=\"vm.processPayment\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                    <h5>Billing Information</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        ng-show=\"vm.quote.billToType == 'BillClient'\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.selectedCustomerPaymentContactIndex\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-label=\"Payment Received From\"\r" +
    "\n" +
    "                        options=\"vm.customerPaymentContactOptions\"\r" +
    "\n" +
    "                        ng-change=\"vm.paymentContactSelected()\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        ng-show=\"vm.quote.billToType != 'BillClient'\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.selectedBrokerPaymentContactIndex\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-label=\"Payment Received From\"\r" +
    "\n" +
    "                        options=\"vm.brokerPaymentContactOptions\"\r" +
    "\n" +
    "                        ng-change=\"vm.paymentContactSelected()\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.companyName\"\r" +
    "\n" +
    "                        aa-label=\"Company\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.firstName\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && (vm.payment.paymentMethod === 'CreditCard' || !vm.payment.companyName)\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.lastName\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && (vm.payment.paymentMethod === 'CreditCard' || !vm.payment.companyName)\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <address\r" +
    "\n" +
    "                        object=\"vm.payment\"\r" +
    "\n" +
    "                        is-payment=\"true\"\r" +
    "\n" +
    "                        ng-required=\"vm.processPayment && (vm.payment.paymentMethod === 'CreditCard' || vm.payment.paymentMethod === 'ACH')\">\r" +
    "\n" +
    "                    </address>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"col-sm-6\">\r" +
    "\n" +
    "                    <h5>Payment</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.payment.paymentMethod\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.paymentMethods\"\r" +
    "\n" +
    "                        ng-change=\"vm.checkForPaymentTransactionFees()\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.payment.paymentMethod === 'ACH'\"\r" +
    "\n" +
    "                        class=\"row\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.accountNumber\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.confirmAccountNumber\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'ACH'\"\r" +
    "\n" +
    "                            ng-match=\"vm.payment.accountNumber\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.routingNumber\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'ACH'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.accountType\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'ACH'\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.achAccountTypeOptions\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div ng-show=\"vm.payment.paymentMethod === 'CreditCard'\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.cardNumber\"\r" +
    "\n" +
    "                            maxlength=\"16\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'CreditCard'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                aa-field-group=\"vm.payment.expirationMonth\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.creditCardExpirationMonth\"\r" +
    "\n" +
    "                                ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'CreditCard'\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                aa-field-group=\"vm.payment.expirationYear\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.creditCardExpirationYear\"\r" +
    "\n" +
    "                                ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'CreditCard'\"></select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.securityCode\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod == 'CreditCard'\"\r" +
    "\n" +
    "                            maxlength=\"4\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"vm.payment.paymentMethod === 'Check'\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.checkNumber\"\r" +
    "\n" +
    "                            ng-required=\"vm.processPayment && vm.payment.paymentMethod === 'Check'\"\r" +
    "\n" +
    "                            maxlength=\"15\">\r" +
    "\n" +
    "                        </input>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"vm.payment.paymentMethod === 'Other' || vm.payment.paymentMethod === 'Return' ||\r" +
    "\n" +
    "                            vm.payment.paymentMethod === 'Check' || vm.payment.paymentMethod === 'WireTransfer'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.payment.createdDateTime\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                            aa-label=\"Processing Date\" \r" +
    "\n" +
    "                            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"text-center top-margin\"\r" +
    "\n" +
    "                            ng-show=\"vm.payment.paymentMethod === 'Other' || vm.payment.paymentMethod === 'Return'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <b>\r" +
    "\n" +
    "                                Please provide additional information in the comments\r" +
    "\n" +
    "                            </b>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea \r" +
    "\n" +
    "                    aa-field-group=\"vm.payment.comments\" \r" +
    "\n" +
    "                    rows=\"2\"\r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-12\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white left-margin-auto\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"left-margin\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.purchase()\" \r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Purchase\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/quoteDetailModal/quoteDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form name=\"quoteForm\">\r" +
    "\n" +
    "    <header class=\"modal-header no-margin\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 ng-show=\"!vm.quote.id && !vm.isDisabled\">New Quote</h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.quote.id && !vm.isDisabled\">Edit Quote</h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.isDisabled\">Quote</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"above text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <system-account-logo ng-if=\"!vm.isCarrier && vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                                     system-account-id=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                                     company-name=\"vm.quote.carrierCompanyName\"\r" +
    "\n" +
    "                                     show-labels=\"true\">\r" +
    "\n" +
    "                </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <system-account-logo ng-if=\"vm.isCarrier && vm.quote.agencySystemAccountId\"\r" +
    "\n" +
    "                                     system-account-id=\"vm.quote.agencySystemAccountId\"\r" +
    "\n" +
    "                                     company-name=\"vm.quote.agencyCompanyName\"\r" +
    "\n" +
    "                                     show-labels=\"true\">\r" +
    "\n" +
    "                </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex small\">\r" +
    "\n" +
    "                    <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                        Name on Bond:\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{vm.quote.nameOnBond}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex small\">\r" +
    "\n" +
    "                    <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                        Quote Type:\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"vm.quote.quoteType == 'NewBusiness'\">New Business</span>\r" +
    "\n" +
    "                    <span ng-show=\"vm.quote.quoteType== 'Renewal'\">Renewal</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex small\">\r" +
    "\n" +
    "                    <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                        Bond Type:\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{vm.quote.bondType | limitTo:50}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex small\">\r" +
    "\n" +
    "                    <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                        Bond Amount:\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{vm.quote.bondAmount | currency:\"$\"}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex small\">\r" +
    "\n" +
    "                    <span class=\"v-center width-100 inline-block\">\r" +
    "\n" +
    "                        Effective Date:\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{vm.quote.desiredEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"fill left-margin-super top-margin-big\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select aa-field-group=\"vm.quote.status\"\r" +
    "\n" +
    "                        ng-change=\"vm.updateCarrierStatus()\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        aa-fg-class=\"left-margin-auto width-100\"\r" +
    "\n" +
    "                        ng-disabled=\"!vm.canEdit || vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"Approved\">Approved</option>\r" +
    "\n" +
    "                    <option value=\"Pending\">Pending</option>\r" +
    "\n" +
    "                    <option value=\"Declined\" disabled>Declined</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                          aa-field-group=\"vm.quote.comments\"\r" +
    "\n" +
    "                          ng-disabled=\"!vm.canEdit || vm.isDisabled\"\r" +
    "\n" +
    "                          aa-label=\"Internal Comments\"\r" +
    "\n" +
    "                          aa-fg-class=\"top-margin\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\"\r" +
    "\n" +
    "             ng-if=\"!vm.quote.carrierSystemAccountId && !vm.currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select aa-field-group=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                aa-label=\"Carrier\"\r" +
    "\n" +
    "                ng-change=\"vm.loadCarrierSettings()\"\r" +
    "\n" +
    "                options=\"vm.carrierOptions\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-if=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "             class=\"text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ng-form name=\"quoteForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <select aa-field-group=\"vm.quote.writingCompanyId\"\r" +
    "\n" +
    "                        convert-to-number\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        ng-change=\"vm.setWritingCompany(vm.quote.writingCompanyId)\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        options=\"vm.writingCompanies\"\r" +
    "\n" +
    "                        aa-option-value=\"id\"\r" +
    "\n" +
    "                        aa-option-text=\"name\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                        ng-disabled=\"!vm.canEdit || vm.isDisabled || vm.quote.quoteType == 'Renewal'\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select aa-field-group=\"vm.quote.bondNumberGroupId\"\r" +
    "\n" +
    "                        convert-to-number\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                        options=\"vm.bondNumberGroups\"\r" +
    "\n" +
    "                        aa-option-value=\"id\"\r" +
    "\n" +
    "                        aa-option-text=\"name\"\r" +
    "\n" +
    "                        ng-required=\"vm.quote.quoteType != 'Renewal'\"\r" +
    "\n" +
    "                        ng-disabled=\"!vm.canEdit || vm.isDisabled || vm.quote.quoteType == 'Renewal'\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select aa-field-group=\"vm.quote.indemnityRequirements\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                        ng-disabled=\"!vm.canEdit || vm.isDisabled\">\r" +
    "\n" +
    "                    <option value=\"None\">Not Required</option>\r" +
    "\n" +
    "                    <option value=\"ApplicantOnly\">Applicant Only</option>\r" +
    "\n" +
    "                    <option value=\"ApplicantSpouse\">Applicant &amp; Spouse</option>\r" +
    "\n" +
    "                    <option value=\"ApplicantSpouseCorporate\">Applicant, Spouse & Corporate</option>\r" +
    "\n" +
    "                    <option value=\"ApplicantCorporate\">Applicant & Corporate</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.term\"\r" +
    "\n" +
    "                       ng-required=\"vm.applicationType == 'SingleBond'\"\r" +
    "\n" +
    "                       aa-label=\"Term (yrs)\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"float\"\r" +
    "\n" +
    "                       ng-show=\"vm.applicationType == 'SingleBond' || vm.quote.application.applicationType\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\"\r" +
    "\n" +
    "                 ng-show=\"vm.applicationType === 'BondAccount'\">\r" +
    "\n" +
    "                <div class=\"col-xs-12\">\r" +
    "\n" +
    "                    <h5>Account Limits</h5>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.singleBondLimit\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                       ng-show=\"vm.applicationType === 'BondAccount'\"\r" +
    "\n" +
    "                       ng-required=\"vm.applicationType === 'BondAccount'\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.aggregateBondLimit\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                       ng-show=\"vm.applicationType === 'BondAccount'\"\r" +
    "\n" +
    "                       ng-required=\"vm.applicationType === 'BondAccount'\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div class=\"col-xs-12\">\r" +
    "\n" +
    "                    <h5>Rate</h5>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select aa-field-group=\"vm.quote.rateType\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-md-6\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        ng-disabled=\"!vm.canEdit || vm.isDisabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"RatePerThousand\">Rate per Thousand</option>\r" +
    "\n" +
    "                    <option value=\"Tiered\">Tiered</option>\r" +
    "\n" +
    "                    <option value=\"Fixed\">Fixed</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.fixedRate\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                       ng-change=\"vm.rateChange()\"\r" +
    "\n" +
    "                       ng-required=\"vm.quote.rateType=='Fixed'\"\r" +
    "\n" +
    "                       ng-show=\"vm.quote.rateType == 'Fixed'\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.ratePerThousand\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                       ng-required=\"vm.quote.rateType =='RatePerThousand'\"\r" +
    "\n" +
    "                       ng-show=\"vm.quote.rateType == 'RatePerThousand'\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.quote.minimumPremium\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                       aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                       ng-disabled=\"!vm.canEdit || vm.isDisabled\"\r" +
    "\n" +
    "                       ng-required=\"vm.quote.rateType !== 'Fixed'\"\r" +
    "\n" +
    "                       ng-show=\"vm.quote.rateType === 'RatePerThousand'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"clearfix\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.quote.rateType === 'Tiered'\">\r" +
    "\n" +
    "                    <div class=\"col-sm-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.newTierRate\"\r" +
    "\n" +
    "                               aa-label=\"Rate per thousand\"\r" +
    "\n" +
    "                               aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                               ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"red small\"\r" +
    "\n" +
    "                              ng-show=\"!vm.newTierRate && vm.tierAdding\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Tier rate is required\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-4\">\r" +
    "\n" +
    "                        <div class=\"field-flex-box\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.newThreshold\"\r" +
    "\n" +
    "                                   aa-label=\"For bond amount up to\"\r" +
    "\n" +
    "                                   aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                   ng-disabled=\"!vm.canEdit || vm.isDisabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button ng-click=\"vm.addNewTierRate()\">Add</button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"red small\"\r" +
    "\n" +
    "                              ng-show=\"!vm.newThreshold && vm.tierAdding\">\r" +
    "\n" +
    "                            Tier threshold is required\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.quote.minimumPremium\"\r" +
    "\n" +
    "                           aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                           aa-fg-class=\"col-md-4\"\r" +
    "\n" +
    "                           ng-disabled=\"!vm.canEdit || vm.isDisabled\"\r" +
    "\n" +
    "                           ng-required=\"vm.quote.rateType !== 'Fixed'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-12 field-wrapper\">\r" +
    "\n" +
    "                        <table>\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th colspan=\"3\">Tiers</th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tbody>\r" +
    "\n" +
    "                                <tr ng-repeat=\"tier in vm.quote.rateTiers\">\r" +
    "\n" +
    "                                    <td>\r" +
    "\n" +
    "                                        <span ng-show=\"$index == 0\"> &lt;</span>\r" +
    "\n" +
    "                                        <span ng-show=\"$index > 0\">\r" +
    "\n" +
    "                                            {{vm.quote.rateTiers[$index - 1].thresholdAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                            -\r" +
    "\n" +
    "                                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{tier.thresholdAmount | currency:\"$\"}}\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <td> {{tier.ratePerThousand | currency:\"$\"}} </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <td class=\"text-right\">\r" +
    "\n" +
    "                                        <button ng-click=\"vm.removeTierRate($index)\">\r" +
    "\n" +
    "                                            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                                            <span>Remove</span>\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <tr class=\"noRecords\"\r" +
    "\n" +
    "                                    ng-show=\"!vm.quote.rateTiers || vm.quote.rateTiers.length == 0\">\r" +
    "\n" +
    "                                    <td colspan=\"3\">\r" +
    "\n" +
    "                                        <span>No Tiers Assigned</span>\r" +
    "\n" +
    "                                        <p>\r" +
    "\n" +
    "                                            <strong class=\"red\">\r" +
    "\n" +
    "                                                You must have at least one tier for tiered rates\r" +
    "\n" +
    "                                            </strong>\r" +
    "\n" +
    "                                        </p>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </tbody>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div class=\"col-xs-12\">\r" +
    "\n" +
    "                    <h5>Commission</h5>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <commission-dropdown class=\"col-sm-6 flex\"\r" +
    "\n" +
    "                                     controller=\"vm.commissionDropdownController\"\r" +
    "\n" +
    "                                     commission-percent=\"vm.quote.commissionPercent\"\r" +
    "\n" +
    "                                     commission-fixed-amount=\"vm.quote.commission\"\r" +
    "\n" +
    "                                     writing-company-id=\"vm.quote.writingCompanyId\"\r" +
    "\n" +
    "                                     commission-type=\"vm.quote.commissionType\"\r" +
    "\n" +
    "                                     carrier-system-account-id=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                                     bond-type-id=\"vm.quote.bondTypeId\"\r" +
    "\n" +
    "                                     agency-system-account-id=\"vm.quote.agencySystemAccountId\"\r" +
    "\n" +
    "                                     dropdown-class=\"fill\"\r" +
    "\n" +
    "                                     other-class=\"fill left-margin\"\r" +
    "\n" +
    "                                     ng-disabled=\"!vm.canEdit || vm.isDisabled\">\r" +
    "\n" +
    "                </commission-dropdown>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <fee-list\r" +
    "\n" +
    "                    fees=\"vm.quote.fees\"\r" +
    "\n" +
    "                    class=\"block top-margin\"\r" +
    "\n" +
    "                    heading=\"Fees\"\r" +
    "\n" +
    "                    is-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "                </fee-list>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-if=\"!vm.isCarrier && vm.quote.brokerEProducerAccountId\">\r" +
    "\n" +
    "                <h5>Broker</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.quote.billToType\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                            ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.quote.brokerCommissionCalculationType\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"commissionCalculationType\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                            aa-unselected-text=\"No commission\"\r" +
    "\n" +
    "                            ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.quote.brokerCommissionPercent\"\r" +
    "\n" +
    "                           aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                           ng-required=\"vm.quote.brokerCommissionCalculationType && vm.quote.brokerCommissionCalculationType != 'FixedAmount'\"\r" +
    "\n" +
    "                           ng-show=\"vm.quote.brokerCommissionCalculationType && vm.quote.brokerCommissionCalculationType != 'FixedAmount'\"\r" +
    "\n" +
    "                           aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                           ng-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input ng-show=\"vm.quote.brokerCommissionCalculationType == 'FixedAmount'\"\r" +
    "\n" +
    "                           ng-required=\"vm.quote.brokerCommissionCalculationType == 'FixedAmount'\"\r" +
    "\n" +
    "                           aa-field-group=\"vm.quote.brokerCommission\"\r" +
    "\n" +
    "                           aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                           aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                           ng-disabled=\"vm.isDisabled\"/>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <!-- <div ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <h5>Cost Breakdown</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Gross Written Premium\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $350.00 <span class=\"small\">2% of Bond Amount</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Commission\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $105.00 <span class=\"small\">30% GWP</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Admin Fee\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $50.00\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Surety Payable\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $245.00\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Broker Commission\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $15.50\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Producer Credit\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $400.00\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill text-right right-margin\">\r" +
    "\n" +
    "                        Revenue\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"fill text-left\">\r" +
    "\n" +
    "                        $139.50 <span class=\"small\">40% of GWP</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex top-margin\">\r" +
    "\n" +
    "                    <div class=\"left-margin-auto flex\">\r" +
    "\n" +
    "                        <div class=\"fill text-right right-margin-big right-margin-thin\">Bill To:</div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            Broker (Net)\r" +
    "\n" +
    "                            <div>Davis Bonding</div>\r" +
    "\n" +
    "                            <div>101 East Dr.</div>\r" +
    "\n" +
    "                            <div>Suite 302C</div>\r" +
    "\n" +
    "                            <div>Boise ID, 83701</div>\r" +
    "\n" +
    "                            <div class=\"top-margin\">Collecting: $384.50</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"right-margin-auto flex\">\r" +
    "\n" +
    "                        <div class=\"fill text-right left-margin-big right-margin-thin\">Surety Payable:</div>\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <div>CapSpecialty</div>\r" +
    "\n" +
    "                            <div>3322 Badger Ave.</div>\r" +
    "\n" +
    "                            <div>Madison WI, 76615</div>\r" +
    "\n" +
    "                            <div class=\"top-margin\">Payout: $245.00</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div> -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <h5>Additional Information</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <textarea ng-show=\"!vm.currentUser.systemAccount.isCarrier\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.quote.printedComments\"\r" +
    "\n" +
    "                        aa-label=\"Additional Information to Client\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isDisabled\">\r" +
    "\n" +
    "                </textarea>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </ng-form>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"red right-margin-big text-left\">\r" +
    "\n" +
    "            <div ng-show=\"!vm.canEdit\">\r" +
    "\n" +
    "                You are unable to modify the rate for this quote because you don't \r" +
    "\n" +
    "                have any underwriting authority with this carrier\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"vm.rateLowerThanPremium\">\r" +
    "\n" +
    "                <b>Please Note</b> - Entered rate is lower than Writing Company Minimum\r" +
    "\n" +
    "                {{vm.defaultMinimumPremium}}.\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button class=\"left-margin-auto v-center white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{vm.isDisabled ? 'Close' : 'Cancel'}}\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button type=\"submit\"\r" +
    "\n" +
    "                class=\"v-center\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "                ng-show=\"!vm.isDisabled\"\r" +
    "\n" +
    "                ng-disabled=\"!vm.quote.carrierSystemAccountId\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>\r" +
    "\n"
  );


  $templateCache.put('app/modals/quoteDocumentsModal/quoteDocumentsModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"modalForm\" \r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Quote Documents</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"flex fill text-left\">\r" +
    "\n" +
    "        <div class=\"flex column right-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                suppress-scroll-x=\"true\"\r" +
    "\n" +
    "                class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "                wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table \r" +
    "\n" +
    "                    ng-show=\"vm.quoteDocumentOptions.length\"\r" +
    "\n" +
    "                    class=\"top-margin small\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <th></th>\r" +
    "\n" +
    "                            <th class=\"fit-content\">Include</th>\r" +
    "\n" +
    "                            <th>Document Name</th>\r" +
    "\n" +
    "                            <th>Document Type</th>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <tbody \r" +
    "\n" +
    "                        class=\"sortable\" \r" +
    "\n" +
    "                        ng-sortable=\"{ \r" +
    "\n" +
    "                            animation: 350, \r" +
    "\n" +
    "                            chosenClass: 'sortable-chosen',\r" +
    "\n" +
    "                            fallbackClass: 'table-sortable-fallback', \r" +
    "\n" +
    "                            handle: '.grab-handle', \r" +
    "\n" +
    "                            forceFallback: true, \r" +
    "\n" +
    "                            onEnd:vm.endSortDocuments \r" +
    "\n" +
    "                        }\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <tr ng-repeat=\"document in vm.quoteDocumentOptions\" >\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <td class=\"grab-handle\">\r" +
    "\n" +
    "                                <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <td class=\"row-select-column\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"document.includeInDownload\" \r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                    aa-fg-class=\"inline-block\" />\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <td>\r" +
    "\n" +
    "                                {{document.name}} \r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <td>\r" +
    "\n" +
    "                                {{document.documentCategory}}\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </tbody>\r" +
    "\n" +
    "                </table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                <empty-state \r" +
    "\n" +
    "                    ng-show=\"!vm.quoteDocumentOptions.length\"\r" +
    "\n" +
    "                    message=\"There are no documents configured as quote options\">\r" +
    "\n" +
    "                </empty-state>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-show=\"vm.quoteDocumentOptions.length\"\r" +
    "\n" +
    "                ng-click=\"vm.loadDouments()\"\r" +
    "\n" +
    "                class=\"top-margin-big width-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Create Documents\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"left-padding fill flex column left-border\">\r" +
    "\n" +
    "            <div ng-show=\"!vm.documentViewerUrl\">\r" +
    "\n" +
    "                <b>No Documents Selected</b>\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    Select documents from the list on the left to be created for this quote. Once loaded you can download or set as an attachment to the application.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <document-viewer\r" +
    "\n" +
    "                class=\"fill flex column width-100p\"\r" +
    "\n" +
    "                ng-if=\"vm.documentViewerUrl\"\r" +
    "\n" +
    "                url=\"vm.documentViewerUrl\">\r" +
    "\n" +
    "            </document-viewer>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"left-margin-auto top-margin flex\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white v-center\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            ng-click=\"vm.downloadDocuments()\"\r" +
    "\n" +
    "            ng-disabled=\"!vm.documentViewerUrl\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Download\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/rateModal/rateModal.html',
    "<div busy-indicator=\"{promise: vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        <h4>New Rate</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <rate-form \r" +
    "\n" +
    "        on-save=\"vm.save(rate)\" \r" +
    "\n" +
    "        on-cancel=\"vm.cancel()\" \r" +
    "\n" +
    "        class=\"text-left\" \r" +
    "\n" +
    "        id=\"vm.rateId\">\r" +
    "\n" +
    "    </rate-form>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/reassignBondModal/reassignBondModal.html',
    "<div busy-indicator=\"{promise: vm.loadingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <h4>Bond Re-assignment</h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"reassignBondForm\">\r" +
    "\n" +
    "    <section ng-show=\"vm.selectedCustomer\">\r" +
    "\n" +
    "        <h4>Are you sure?</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p class=\"text-center\">You are about to re-assign this bond to another customer. Please review carefully before continuing.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <h5>Bond</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"row top-margin\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        Bond#:\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.bond.bondNumber}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        Principal:\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.bond.nameOnBond}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        Bond Type:                        \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.bond.bondType}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        Bond Amount:                        \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.bond.bondAmount | currency:'$'}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <h5>New Customer</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row top-margin\">\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        Name:\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.selectedCustomer.name}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"vm.selectedCustomer.lookupCode\" \r" +
    "\n" +
    "                        class=\"col-xs-6 text-right\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Lookup Code:\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-xs-6 text-left\">\r" +
    "\n" +
    "                        <b> {{vm.selectedCustomer.lookupCode}}</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                </div>           \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"!vm.selectedCustomer\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div busy-indicator=\"{promise: vm.customerSearchPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p class=\"small\">\r" +
    "\n" +
    "            Here you can re-assign this bond to another customer record. Re-assigning bonds also has an impact on related records to the bond. Invoices, Bond Transactions, Attachments, Payments, etc will also transfer to the new customer record. You should closely review both customer records following a bond re-assignment. \r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.customerSearchPhrase\"\r" +
    "\n" +
    "            aa-label=\"Search Customers\"\r" +
    "\n" +
    "            ng-change=\"vm.searchCustomers(vm.customerSearchPhrase)\"\r" +
    "\n" +
    "            ng-model-options=\"{debounce: 450}\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table class=\"table top-margin\">\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"customer in vm.matchingCustomers\">\r" +
    "\n" +
    "                    <td class=\"text-left\">\r" +
    "\n" +
    "                        <b>#{{customer.id}} - {{customer.name}}</b>\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-show=\"customer.lookupCode\" \r" +
    "\n" +
    "                            class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"v-center\">\r" +
    "\n" +
    "                                Lookup Code: \r" +
    "\n" +
    "                                <b> {{customer.lookupCode}}</b>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"button-column\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-click=\"vm.selectCustomer(customer)\"\r" +
    "\n" +
    "                            ng-disabled=\"vm.bond.customerId == customer.Id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            Select\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.customerSearchPhrase && !vm.matchingCustomers.length\" \r" +
    "\n" +
    "            message=\"No matching customers were found\"/>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-show=\"vm.selectedCustomer\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.reassignBond()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Confirm\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/reconsiderApplicationModal/reconsiderApplicationModal.html',
    "<div ng-form=\"purchaseBondForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Reconsider Declined Application</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        You sure you want to reconsider this declined application?\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"charcoal\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button ng-click=\"vm.reconsiderApplication()\">\r" +
    "\n" +
    "            Reconsider Application\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/modals/reinstatementRequestModal/reinstatementRequestModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Reinstate Bond</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!vm.confirmRescind && !vm.confirmDecline\"\r" +
    "\n" +
    "        class=\"text-left small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset>\r" +
    "\n" +
    "            <uib-tab heading=\"Reinstatement Detail\">\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                            class=\"col-sm-12 bottom-margin\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.agentSubmissionType\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center right-margin\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                                options=\"vm.agentSubmissionTypeOptions\"\r" +
    "\n" +
    "                                aa-wrapper-class=\"no-margin flex\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"col-md-6\" \r" +
    "\n" +
    "                            ng-show=\"vm.isCarrier && vm.request.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            Requested Reinstatement Date: <strong>{{vm.request.requestedEffectiveDate | dateOnly}}</strong><br/>\r" +
    "\n" +
    "                            Agency Comments: <strong>{{vm.request.agencyComments}}</strong>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"col-md-6 requested-by\" \r" +
    "\n" +
    "                            ng-show=\"vm.request.user\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Requested By: \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            <b>{{vm.request.user.fullName}}</b> \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            {{vm.request.user.email}} \r" +
    "\n" +
    "                            <br/>\r" +
    "\n" +
    "                            {{vm.request.user.systemAccount.phone}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row top-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"col-md-6\" \r" +
    "\n" +
    "                            ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.request.requestedEffectiveDate\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                                ng-required=\"!vm.isCarrier\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"col-md-6\" \r" +
    "\n" +
    "                            ng-if=\"!vm.isCarrier || (vm.isCarrier && !vm.request.id)\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-field-group=\"vm.request.attorneyInFactUserId\" \r" +
    "\n" +
    "                                aa-label=\"Attorney-in-Fact\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                                options=\"vm.aifOptions\" \r" +
    "\n" +
    "                                required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-show=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\" \r" +
    "\n" +
    "                        class=\"top-margin row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.request.effectiveDate\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                            ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                            ng-required=\"vm.isCarrier\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.reasonId\" \r" +
    "\n" +
    "                            options=\"vm.reinstatementReasons\" \r" +
    "\n" +
    "                            aa-label=\"Reason Code\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                            aa-unselected-text=\"Select a reason code...\"\r" +
    "\n" +
    "                            aa-option-text=\"code\" \r" +
    "\n" +
    "                            aa-option-value=\"id\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            ng-required=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.request.premiumIncrease\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            ng-required=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\"/>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.request.commission\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            ng-required=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\"/>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <textarea\r" +
    "\n" +
    "                            rows=\"3\" \r" +
    "\n" +
    "                            aa-field-group=\"vm.request.carrierComments\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-12\"\r" +
    "\n" +
    "                            aa-label=\"Comments\"\r" +
    "\n" +
    "                            ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"!vm.isCarrier\" \r" +
    "\n" +
    "                        class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <textarea \r" +
    "\n" +
    "                            rows=\"3\" \r" +
    "\n" +
    "                            id=\"textarea\" \r" +
    "\n" +
    "                            aa-label=\"Comments\" \r" +
    "\n" +
    "                            aa-field-group=\"vm.request.agencyComments\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-12\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab  \r" +
    "\n" +
    "                heading=\"Fees ({{vm.totalFees | currency:'$'}})\"\r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section class=\"text-left small\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <fee-list \r" +
    "\n" +
    "                        fees=\"vm.request.fees\"\r" +
    "\n" +
    "                        total=\"vm.totalFees\">\r" +
    "\n" +
    "                    </fee-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"charcoal pull-left\" \r" +
    "\n" +
    "            ng-if=\"vm.request.id && vm.isCarrier\" \r" +
    "\n" +
    "            ng-click=\"vm.decline()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Decline Request\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"charcoal pull-left\" \r" +
    "\n" +
    "            ng-if=\"vm.request.id && !vm.isCarrier\" \r" +
    "\n" +
    "            ng-click=\"vm.rescindRequest()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Rescind Request\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\" \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span ng-show=\"vm.isSubmitting\">Submit</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isUpdating\">Update</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isCreating\">Reinstate</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isApproving\">Approve Request</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/relatedCustomersModal/relatedCustomersModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <h4>Customer Relationships</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "            <customer-search-dropdown\r" +
    "\n" +
    "                class=\"fill\"\r" +
    "\n" +
    "                search-control=\"vm.customerSearchControl\"\r" +
    "\n" +
    "                disable-relationships=\"true\">\r" +
    "\n" +
    "            </customer-search-dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.addChildCustomer()\"\r" +
    "\n" +
    "                class=\"left-margin top-margin-auto bottom-margin-thin small\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                <span>Add Relationship</span>\r" +
    "\n" +
    "             </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.customerRelationships.childCustomers.length\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>Lookup Code</th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                            <div>Relationship <span class=\"validation-indicator\" style=\"color:red\">*</span></div>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>Customer</th>\r" +
    "\n" +
    "                    <th>Comments</th>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr \r" +
    "\n" +
    "                    ng-repeat=\"relatedCustomer in vm.customerRelationships.childCustomers\"\r" +
    "\n" +
    "                    ng-class=\"{'line-through disabled': relatedCustomer.isRemoved}\">\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                        {{relatedCustomer.lookupCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                        aa-field-group=\"relatedCustomer.relationshipType\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-fg-class=\"no-margin fixed-width-md left-margin-auto\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        ng-required=\"true\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <option value=\"ParentSubsidiary\">Parent Company</option>\r" +
    "\n" +
    "                        <option value=\"EmployerEmployee\">Employer</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            target=\"{{'customerDetail/' + relatedCustomer.customerId}}\" \r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: relatedCustomer.customerId, sectionView: '' })\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{relatedCustomer.customerName}}\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"relatedCustomer.comments\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-top-margin\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-right\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            class=\"mini\"\r" +
    "\n" +
    "                            ng-click=\"vm.removeChildCustomer(relatedCustomer, $index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"!vm.customerRelationships.childCustomers.length\"\r" +
    "\n" +
    "            message=\"There are no child customer records associated and no relationship will be created\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.close()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"primary\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/renameDataViewModal/renameDataViewModal.html',
    "<ng-form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>{{vm.heading}}</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\" \r" +
    "\n" +
    "            class=\"alert alert-danger bottom-margin-super text-center\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.name\"\r" +
    "\n" +
    "            aa-label=\"View Name\" \r" +
    "\n" +
    "            focus-me\r" +
    "\n" +
    "            required/>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/modals/renewalModal/renewalModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <h4>\r" +
    "\n" +
    "        Bond Renewals\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"renewalModalForm\"\r" +
    "\n" +
    "    ng-disabled=\"vm.errorMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"!vm.isConfirmationPaneVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <div    \r" +
    "\n" +
    "            ng-show=\"vm.errorMessage\"\r" +
    "\n" +
    "            class=\"alert alert-danger\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            {{vm.errorMessage}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <div class=\"sub-heading flex small\">\r" +
    "\n" +
    "                <span class=\"width-85 left-margin-auto text-center\">Eff Date</span>\r" +
    "\n" +
    "                <span class=\"width-85 left-margin text-center\">Exp Date</span>\r" +
    "\n" +
    "                <span class=\"width-175 left-margin text-center\">Premium</span>\r" +
    "\n" +
    "                <span class=\"width-175 left-margin text-center\">Commission</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section\r" +
    "\n" +
    "            class=\"top-margin\"\r" +
    "\n" +
    "            ng-repeat=\"bond in vm.bonds\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div class=\"v-center fill text-left\">\r" +
    "\n" +
    "                    {{bond.bondNumber}} - \r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"nowrap\" \r" +
    "\n" +
    "                        ng-bind-html=\"bond | bondStatus\">\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"small left-margin-thin\"\r" +
    "\n" +
    "                        ng-show=\"vm.bonds.length > 1\"\r" +
    "\n" +
    "                        ng-click=\"vm.remove($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"small\">\r" +
    "\n" +
    "                        {{bond.bondAmount | currency:'$'}} {{bond.bondType}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"small\">\r" +
    "\n" +
    "                        Current Term: {{bond.currentEffectiveDate | dateOnly}} - {{bond.currentExpirationDate | dateOnly}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"bond.newEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                    aa-fg-class=\"left-margin small v-center width-85 text-center\" \r" +
    "\n" +
    "                    required \r" +
    "\n" +
    "                    disabled />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"bond.newExpirationDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                    aa-fg-class=\"left-margin small v-center width-85 text-center\" \r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <premium-dropdown\r" +
    "\n" +
    "                    class=\"width-175 left-margin v-center\"\r" +
    "\n" +
    "                    premium=\"bond.premium\"\r" +
    "\n" +
    "                    is-bond-account-premium=\"bond.isBondAccountPremium\"\r" +
    "\n" +
    "                    premium-options=\"bond.premiumOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    dropdown-class=\"no-margin\"\r" +
    "\n" +
    "                    other-class=\"top-margin-thin\">\r" +
    "\n" +
    "                </premium-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <commission-dropdown\r" +
    "\n" +
    "                    class=\"width-175 left-margin v-center\"\r" +
    "\n" +
    "                    commission-percent=\"bond.commissionPercent\"\r" +
    "\n" +
    "                    commission-type=\"bond.commissionType\"\r" +
    "\n" +
    "                    commission-fixed-amount=\"bond.commission\"\r" +
    "\n" +
    "                    bond-id=\"bond.id\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    dropdown-class=\"no-margin\"\r" +
    "\n" +
    "                    other-class=\"top-margin-thin\">\r" +
    "\n" +
    "                </commission-dropdown>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"text-right\">\r" +
    "\n" +
    "                <a ng-click=\"vm.addFee(bond)\"> \r" +
    "\n" +
    "                    Add Fee\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"flex\"\r" +
    "\n" +
    "                    ng-repeat=\"fee in bond.fees\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"fee.description\"\r" +
    "\n" +
    "                        aa-label=\"Fee Description\"\r" +
    "\n" +
    "                        aa-fg-class=\"small fill\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <fee-code-dropdown                \r" +
    "\n" +
    "                        fee-code=\"fee.code\"\r" +
    "\n" +
    "                        class=\"small left-margin\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </fee-code-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"fee.amount\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        aa-fg-class=\"small left-margin width-85\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"top-margin-auto height-25 mini left-margin\"\r" +
    "\n" +
    "                        ng-click=\"vm.removeFee(bond, $index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <footer>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.showConfirmationPane()\"\r" +
    "\n" +
    "                ng-disabled=\"vm.errorMessage\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Next\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.isConfirmationPaneVisible\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <section class=\"bottom-margin text-left flex\">\r" +
    "\n" +
    "            <span class=\"v-center\">\r" +
    "\n" +
    "                How do you want to handle invoices?\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-fg-class=\"v-center left-margin\"\r" +
    "\n" +
    "                aa-field-group=\"vm.invoiceCreationType\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <option value=\"Combined\">Combine invoices</option>\r" +
    "\n" +
    "                <option value=\"Individual\">Individual invoices</option>\r" +
    "\n" +
    "                <option value=\"None\">Don't create an invoice</option>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <section \r" +
    "\n" +
    "            class=\"text-left top-margin\"\r" +
    "\n" +
    "            ng-repeat=\"bond in vm.bonds\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div class=\"bottom-margin-auto fill\">\r" +
    "\n" +
    "                    {{bond.bondNumber}}\r" +
    "\n" +
    "                    <div class=\"small\">\r" +
    "\n" +
    "                        {{bond.bondAmount | currency:'$'}} {{bond.bondType}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"small\">\r" +
    "\n" +
    "                        Renewal Term: {{bond.newEffectiveDate | dateOnly}} - {{bond.newExpirationDate | dateOnly}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"left-margin-auto flex small\">\r" +
    "\n" +
    "                    <div class=\"text-right\">\r" +
    "\n" +
    "                        <div>Premium:</div>\r" +
    "\n" +
    "                        <div>Commission:</div>\r" +
    "\n" +
    "                        <div>Fees:</div>\r" +
    "\n" +
    "                        <div>Broker Commission:</div>\r" +
    "\n" +
    "                        <div class=\"top-margin\">Total Due:</div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"left-margin text-left\">\r" +
    "\n" +
    "                        <div>{{vm.getRenewalCalculation(bond.id).premium | currency: '$'}}</div>\r" +
    "\n" +
    "                        <div>{{vm.getRenewalCalculation(bond.id).commission | currency: '$'}}</div>\r" +
    "\n" +
    "                        <div>{{vm.getRenewalCalculation(bond.id).totalFees | currency: '$'}}</div>\r" +
    "\n" +
    "                        <div>{{vm.getRenewalCalculation(bond.id).brokerCommission | currency: '$'}}</div>\r" +
    "\n" +
    "                        <div class=\"top-margin\">{{vm.getRenewalCalculation(bond.id).totalAmountDue | currency: '$'}}</div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"flex top-margin\">\r" +
    "\n" +
    "            <div class=\"bottom-margin-auto fill text-left\">\r" +
    "\n" +
    "                Grand Totals\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex left-margin-auto\">\r" +
    "\n" +
    "                <div class=\"text-right small\">\r" +
    "\n" +
    "                    <div>Premium:</div>\r" +
    "\n" +
    "                    <div>Commission:</div>\r" +
    "\n" +
    "                    <div>Fees:</div>\r" +
    "\n" +
    "                    <div>Broker Commission:</div>\r" +
    "\n" +
    "                    <div class=\"top-margin\">Total Due:</div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"left-margin text-left small\">\r" +
    "\n" +
    "                    <div>{{vm.renewalCalculationSummary.totalPremium | currency: '$'}}</div>\r" +
    "\n" +
    "                    <div>{{vm.renewalCalculationSummary.totalCommission | currency: '$'}}</div>\r" +
    "\n" +
    "                    <div>{{vm.renewalCalculationSummary.totalFees | currency: '$'}}</div>\r" +
    "\n" +
    "                    <div>{{vm.renewalCalculationSummary.totalBrokerCommission | currency: '$'}}</div>\r" +
    "\n" +
    "                    <div class=\"top-margin\">{{vm.renewalCalculationSummary.totalAmountDue | currency: '$'}}</div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <footer>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.hideConfirmationPane()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Back\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.submit()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Complete Renewals\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/modals/riderRequestModal/riderRequestModal.html',
    "<div\r" +
    "\n" +
    "    id=\"modal-request-rider-loading-mask\"\r" +
    "\n" +
    "    busy-indicator=\"vm.busyIndicator\">\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"riderForm\"\r" +
    "\n" +
    "    class=\"rider\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Bond Rider</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!vm.confirmRescind && !vm.confirmDecline && !vm.confirmRequest\"\r" +
    "\n" +
    "        class=\"text-left small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset>\r" +
    "\n" +
    "            <uib-tab heading=\"Rider\">\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-show=\"vm.bond.renewalStatus === 'ApplicationSubmitted'\" \r" +
    "\n" +
    "                            class=\"col-sm-12\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                                <b>Open Renewal Application</b>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                This bond has an open renewal application. Any changes to this bond will also update the renewal application.  \r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                <b>Note: </b> You should review all renewal quotes to ensure premium and eligibility are correct considering the changes being made.\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-sm-12\"\r" +
    "\n" +
    "                            ng-show=\"!vm.request.changes.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                                Rider must include at least one change. Please indicate what is being changed before proceeding.\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-sm-12\"\r" +
    "\n" +
    "                            ng-show=\"vm.request.requestedEffectiveDate < vm.originalEffectiveDate\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                                The effective date of the rider can't precede the effective date of the bond. Bond effective: {{ vm.bond.effectiveDate | date: 'MM/dd/yyyy' }}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-md-12\"\r" +
    "\n" +
    "                            ng-show=\"vm.request.requestedByUser\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Requested By: <br />\r" +
    "\n" +
    "                            <b>{{ vm.request.requestedByUser }}</b> <br />\r" +
    "\n" +
    "                            {{ vm.request.requestedByEmail }} <br />\r" +
    "\n" +
    "                            {{ vm.request.requestedByPhone }}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"col-sm-12 top-margin\" \r" +
    "\n" +
    "                            ng-show=\"vm.isCarrier && vm.request.agencyComments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Agency Comments:\r" +
    "\n" +
    "                            <b>{{ vm.request.agencyComments }}</b>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-if=\"!vm.isCarrier\"\r" +
    "\n" +
    "                            class=\"col-sm-12 bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.agentSubmissionType\"\r" +
    "\n" +
    "                                aa-fg-class=\"v-center right-margin\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"radio\"\r" +
    "\n" +
    "                                options=\"vm.agentSubmissionTypeOptions\"\r" +
    "\n" +
    "                                aa-wrapper-class=\"no-margin flex\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"col-sm-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                ng-show=\"vm.showRequestedEffectiveDate\"\r" +
    "\n" +
    "                                id=\"modal-request-rider-requested-effective-date\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.requestedEffectiveDate\"\r" +
    "\n" +
    "                                aa-label=\"Date changes will become effective\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                ng-disabled=\"vm.disableRequestedEffectiveDate\"\r" +
    "\n" +
    "                                ng-required=\"vm.requireRequestedEffectiveDate\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                id=\"modal-request-rider-request-attorney-in-fact-user-id\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.attorneyInFactUserId\"\r" +
    "\n" +
    "                                aa-label=\"Attorney-in-Fact\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                options=\"vm.aifOptions\"\r" +
    "\n" +
    "                                ng-required=\"true\">\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-show=\"!vm.isCarrier && vm.agentSubmissionType == 'Execute'\" \r" +
    "\n" +
    "                            class=\"col-sm-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                id=\"modal-request-rider-premium-change\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.premiumChange\"\r" +
    "\n" +
    "                                aa-label=\"Change in Premium\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                                ng-required=\"vm.agentSubmissionType == 'Execute'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                id=\"modal-request-rider-commission-change\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.commissionChange\"\r" +
    "\n" +
    "                                aa-label=\"Change in Commission\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                                ng-required=\"vm.agentSubmissionType == 'Execute'\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"bottom-margin\">\r" +
    "\n" +
    "                        <bond-change-manager\r" +
    "\n" +
    "                            bond=\"vm.bond\"\r" +
    "\n" +
    "                            changes=\"vm.request.changes\">\r" +
    "\n" +
    "                        </bond-change-manager>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-if=\"vm.isCarrier\" \r" +
    "\n" +
    "                            class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                id=\"modal-request-rider-effective-date\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.effectiveDate\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                id=\"modal-request-rider-premium-change\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.premiumChange\"\r" +
    "\n" +
    "                                aa-label=\"Change in Premium\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                id=\"modal-request-rider-commission-change\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.request.commissionChange\"\r" +
    "\n" +
    "                                aa-label=\"Change in Commission\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"moneyPositiveOrNegative\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            id=\"modal-request-rider-reason\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.request.reasonId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-label=\"Reason code\"\r" +
    "\n" +
    "                            options=\"vm.riderReasons\"\r" +
    "\n" +
    "                            aa-option-text=\"description\"\r" +
    "\n" +
    "                            aa-option-value=\"id\"\r" +
    "\n" +
    "                            ng-required=\"vm.isCarrier || vm.agentSubmissionType == 'Execute'\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <textarea\r" +
    "\n" +
    "                            id=\"modal-request-rider-carrier-comments\"\r" +
    "\n" +
    "                            rows=\"3\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.request.carrierComments\"\r" +
    "\n" +
    "                            aa-label=\"Comments (seen by agency)\"\r" +
    "\n" +
    "                            ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"top-margin\" \r" +
    "\n" +
    "                        ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <textarea\r" +
    "\n" +
    "                            rows=\"3\"\r" +
    "\n" +
    "                            id=\"textarea\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.request.agencyComments\"\r" +
    "\n" +
    "                            aa-label=\"Comments\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab\r" +
    "\n" +
    "                heading=\"Fees ({{ vm.totalFees | currency:'$' }})\"\r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <fee-list\r" +
    "\n" +
    "                    fees=\"vm.request.fees\"\r" +
    "\n" +
    "                    total=\"vm.totalFees\"\r" +
    "\n" +
    "                    total-changed=\"vm.feeTotalChanged(total)\" >\r" +
    "\n" +
    "                </fee-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer ng-show=\"!vm.confirmRescind && !vm.confirmDecline && !vm.confirmRequest\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"modal-request-rider-rescind-button\"\r" +
    "\n" +
    "            class=\"charcoal pull-left\"\r" +
    "\n" +
    "            ng-click=\"vm.confirmRescind = true\"\r" +
    "\n" +
    "            ng-show=\"vm.request.id && !vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Rescind Request\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"modal-request-rider-decline-button\"\r" +
    "\n" +
    "            class=\"charcoal pull-left\"\r" +
    "\n" +
    "            ng-click=\"vm.confirmDecline = true\"\r" +
    "\n" +
    "            ng-show=\"vm.request.id && vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Decline Request\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"modal-request-rider-next-button\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.nextButtonClicked()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Next\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- Confirm Request-->\r" +
    "\n" +
    "    <section ng-show=\"vm.confirmRequest\">\r" +
    "\n" +
    "        <div>\r" +
    "\n" +
    "            <h3>One last thing.</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p class=\"text-center\">\r" +
    "\n" +
    "                Please review the language that will be printed on the rider document. Make any changes needed and then click finish.\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h5 class=\"text-left top-margin-big\">Language printed on rider form</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-repeat=\"change in vm.request.changes\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"{{'modal-request-rider-change-description-' + $index}}\"\r" +
    "\n" +
    "                aa-field-group=\"change.description\"\r" +
    "\n" +
    "                aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                aa-label=\"Change description\"\r" +
    "\n" +
    "                ng-required=\"vm.confirmRequest\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer ng-show=\"vm.confirmRequest\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.backToRequest()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            id=\"modal-request-rider-submit-button\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"\r" +
    "\n" +
    "            type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-show=\"vm.isSubmitting\">Submit</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isUpdating\">Update</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isCreating\">Execute Rider</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.isApproving\">Approve Request</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- Confirm Rescind-->\r" +
    "\n" +
    "    <section ng-show=\"vm.confirmRescind\" class=\"text-center\">\r" +
    "\n" +
    "        <h3>Are you sure you want to rescind the request?</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p class=\"text-center\">\r" +
    "\n" +
    "            Rescinding this rider request will permanently remove the requested changes.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"top-margin-big\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.confirmRescind = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                id=\"modal-request-rider-rescind-confirmation-button\"\r" +
    "\n" +
    "                ng-click=\"vm.rescindRequest()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Rescind Request\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- Confirm Decline-->\r" +
    "\n" +
    "    <section\r" +
    "\n" +
    "        ng-show=\"vm.confirmDecline\"\r" +
    "\n" +
    "        class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Are you sure you want to decline this request?</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p class=\"text-center\">\r" +
    "\n" +
    "            This will permanently remove the requested changes.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"top-margin-big\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                id=\"modal-request-rider-decline-confirmation-button\"\r" +
    "\n" +
    "                class=\"charcoal\"\r" +
    "\n" +
    "                ng-click=\"vm.decline()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Decline Request\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.confirmDecline = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Nevermind\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/sendBillingReportModal/sendBillingReportModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<header class=\"modal-header\">\r" +
    "\n" +
    "    <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    Send Billing Report\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"text-left\"\r" +
    "\n" +
    "    ng-form=\"epicConnectForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.hasDuplicateEntries\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        Some of the selected entries were included in a previous billing report and <b>will be excluded</b>.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.hasIncompleteEntries\"\r" +
    "\n" +
    "        class=\"alert alert-danger\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        Some of the selected entries are incomplete or have not been entered into Epic. \r" +
    "\n" +
    "        Proceeding could result in dollar sign activities due to the billing team being \r" +
    "\n" +
    "        unable to relate entries to billable actions in Epic.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p>\r" +
    "\n" +
    "        <b>Important Note:</b>\r" +
    "\n" +
    "        <br/>\r" +
    "\n" +
    "        Sending this billing report will mark all included entries as being sent to the billing team. \r" +
    "\n" +
    "        Please ensure the selected entries are complete and the corresponding action has been completed\r" +
    "\n" +
    "        in Epic prior to sending.\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <hr/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"top-margin-big\">\r" +
    "\n" +
    "        <h5 class=\"flex sub-heading\">\r" +
    "\n" +
    "            <span class=\"fill\">Recipients</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"mini\"\r" +
    "\n" +
    "                ng-click=\"vm.addRecipient()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-repeat=\"email in vm.recipientEmailAddresses track by $index\" \r" +
    "\n" +
    "            class=\"top-margin-thin flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"fill\">\r" +
    "\n" +
    "                <input  \r" +
    "\n" +
    "                    aa-field-group=\"vm.recipientEmailAddresses[$index]\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                    aa-label=\"Email Address\"\r" +
    "\n" +
    "                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                    aa-fg-class=\"no-margin\"\r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"left-margin-thin mini\"\r" +
    "\n" +
    "                ng-click=\"vm.removeRecipient($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button class=\"white\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button aa-submit-form=\"vm.send()\">\r" +
    "\n" +
    "            Send\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/sharedViewsModal/sharedViewsModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Shared Views</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-left\"\r" +
    "\n" +
    "        ng-show=\"vm.views.length\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"flex bottom-margin\"\r" +
    "\n" +
    "            ng-class=\"{'top-margin': $first}\" \r" +
    "\n" +
    "            ng-repeat=\"view in vm.views track by view.id\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <user-avatar\r" +
    "\n" +
    "                class=\"v-center right-margin\"    \r" +
    "\n" +
    "                user-id=\"view.createdByUserId\"></user-avatar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                <b>{{view.name}}</b>\r" +
    "\n" +
    "                <div class=\"small\">Created By: {{view.createdByUser}}</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"mini left-margin-auto v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.copyView(view)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Copy View\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"!vm.isLoading && !vm.views.length\"\r" +
    "\n" +
    "        message = \"There are no views shared for this table\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/modals/shippingInfoModal/shippingInfoModal.html',
    "<form \r" +
    "\n" +
    "    novalidate \r" +
    "\n" +
    "    name=\"modalForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4>Shipping Information</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"text-left\" \r" +
    "\n" +
    "        ng-show=\"vm.bondTransaction.mailTo\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"alert alert-warning\" \r" +
    "\n" +
    "            ng-show=\"vm.bondTransaction.expedited\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <strong>Expedited</strong> \r" +
    "\n" +
    "            processing was requested for this transaction\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div class=\"pull-left\">Mail To: </div>\r" +
    "\n" +
    "        <div class=\"pull-left left-margin\">\r" +
    "\n" +
    "            <div>{{vm.bondTransaction.mailTo}}</div>\r" +
    "\n" +
    "            <div>{{vm.bondTransaction.mailAddress}}</div>\r" +
    "\n" +
    "            <div>{{vm.bondTransaction.mailSuiteAptNumber}}</div>\r" +
    "\n" +
    "            <div>{{vm.bondTransaction.mailCity}}, {{vm.bondTransaction.mailState}} {{vm.bondTransaction.mailZip}}</div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-show=\"!vm.bondTransaction.mailTo\" \r" +
    "\n" +
    "        class=\"alert alert-danger text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        No shipping information was specified for this transaction\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/modals/systemAccountContactModal/contactModal.html',
    "\r" +
    "\n" +
    "<ng-form name=\"contactForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 ng-show=\"!vm.contact.id\">New Contact Information</h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.contact.id\">Edit Contact Information</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"text-left\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.name\" \r" +
    "\n" +
    "                    maxlength=\"200\" \r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.department\" \r" +
    "\n" +
    "                    maxlength=\"200\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.phone\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"phone\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.extension\" \r" +
    "\n" +
    "                    maxlength=\"10\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.fax\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"phone\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.contact.email\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"email\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\" \r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/modals/uploadModal/uploadModal.html',
    "<div busy-indicator=\"{promise:processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form \r" +
    "\n" +
    "    novalidate \r" +
    "\n" +
    "    name=\"modalForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-show=\"vm.hasError\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Uploading File<span ng-show=\"vm.files.length > 1\">s</span></h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"vm.files.length > 1\">\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <b class=\"v-center\">Overall Progress</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"v-center left-margin-auto\">\r" +
    "\n" +
    "                <b ng-show=\"!vm.sent\">0 Kb</b>\r" +
    "\n" +
    "                <b ng-show=\"vm.sent\">{{vm.sent | filesize}}</b>\r" +
    "\n" +
    "                 of \r" +
    "\n" +
    "                <b>{{vm.total | filesize}}</b>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <uib-progressbar \r" +
    "\n" +
    "            max=\"vm.total\" \r" +
    "\n" +
    "            value=\"vm.sent\"\r" +
    "\n" +
    "            type=\"{{vm.hasError ? 'danger' : 'success'}}\">\r" +
    "\n" +
    "        </uib-progressbar>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-class=\"{'bottom-margin-big' : !$last}\" \r" +
    "\n" +
    "            ng-repeat=\"file in vm.files\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <b class=\"v-center\">{{file.file.name}}</b>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <b \r" +
    "\n" +
    "                    ng-show=\"file.isError\"\r" +
    "\n" +
    "                    class=\"red v-center left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                    Error\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <b \r" +
    "\n" +
    "                    ng-show=\"file.uploadResponseReceived && !file.isError\"\r" +
    "\n" +
    "                    class=\"green v-center left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                    Complete\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <b \r" +
    "\n" +
    "                    ng-show=\"!file.uploadResponseReceived && file.progress === 100\"\r" +
    "\n" +
    "                    class=\"v-center left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Processing File\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span\r" +
    "\n" +
    "                    ng-show=\"!file.uploadResponseReceived && file.progress !== 100\" \r" +
    "\n" +
    "                    class=\"v-center left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"!file.bytesSent\">0 Kb</b>\r" +
    "\n" +
    "                    <b ng-show=\"file.bytesSent\">{{file.bytesSent | filesize}}</b>\r" +
    "\n" +
    "                    of\r" +
    "\n" +
    "                    <b>{{file.totalBytes | filesize}}</b>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <uib-progressbar \r" +
    "\n" +
    "                class=\"no-bottom-margin\"\r" +
    "\n" +
    "                max=\"file.totalBytes\" \r" +
    "\n" +
    "                value=\"file.bytesSent\" \r" +
    "\n" +
    "                type=\"{{file.isError ? 'danger' : 'success'}}\">\r" +
    "\n" +
    "            </uib-progressbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"red\" \r" +
    "\n" +
    "                ng-show=\"file.isError\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{file.errorMessage}} \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.hasError\" \r" +
    "\n" +
    "        class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white v-center left-margin-auto\"\r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/modals/userDetailModal/userDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"modalForm\"\r" +
    "\n" +
    "    class=\"flex column height-100p\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4 ng-show=\"!vm.newUser\">\r" +
    "\n" +
    "            Edit User\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.newUser\">\r" +
    "\n" +
    "            New User\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller modal-scroller fill\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"flex\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditUsers\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.isLockedOut\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                aa-fg-class=\"no-top-margin left-margin-auto\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"row\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditUsers\">\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        Personal Info\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.id\"\r" +
    "\n" +
    "                        disabled aa-label=\"User Id\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.firstName\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.lastName\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        Login Info\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.email\"\r" +
    "\n" +
    "                        aa-label=\"Email/Username\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.password\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                        name=\"userPassword\"\r" +
    "\n" +
    "                        ng-pattern=\"/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/\"\r" +
    "\n" +
    "                        ng-pattern-msg=\"Password requires 1 upper-case, 1 lower-case, 1 numeric and must be at least 8 characters long\"\r" +
    "\n" +
    "                        ng-required=\"vm.newUser || vm.confirmPassword\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.confirmPassword\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                        ng-required=\"vm.newUser || vm.user.password\"\r" +
    "\n" +
    "                        ng-match=\"vm.user.password\" />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"text-left\">\r" +
    "\n" +
    "            <header class=\"flex\">\r" +
    "\n" +
    "                <span class=\"v-center\">\r" +
    "\n" +
    "                    Attorney-in-Fact\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.isAttorneyInFact\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"v-center left-margin-auto\" />\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"alert alert-danger\"\r" +
    "\n" +
    "                ng-show=\"vm.signatureImageRequired\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                You must upload a signature image for Attorney-in-Fact users\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"vm.user.isAttorneyInFact\">\r" +
    "\n" +
    "                <h5>\r" +
    "\n" +
    "                    Signature Image\r" +
    "\n" +
    "                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <p>\r" +
    "\n" +
    "                    Attach an image of the signature to be used on forms\r" +
    "\n" +
    "                    that can be filed with printed signatures.\r" +
    "\n" +
    "                </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"flex\"\r" +
    "\n" +
    "                    ng-show=\"!vm.signatureUploaderFiles[0]\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"top-margin width-300 left-margin-auto right-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <img\r" +
    "\n" +
    "                            ng-show=\"vm.user.signatureImageFileId\"\r" +
    "\n" +
    "                            ng-src=\"{{ vm.signatureImagePath }}\"\r" +
    "\n" +
    "                            alt=\"User Signature\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"top-margin\">\r" +
    "\n" +
    "                            <uploader\r" +
    "\n" +
    "                                controls=\"vm.signatureUploaderControls\"\r" +
    "\n" +
    "                                accepted-types=\"images\"\r" +
    "\n" +
    "                                files=\"vm.signatureUploaderFiles\">\r" +
    "\n" +
    "                            </uploader>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <main \r" +
    "\n" +
    "                    class=\"top-margin\"\r" +
    "\n" +
    "                    ng-show=\"vm.signatureUploaderFiles[0]\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <h5 class=\"flex\">\r" +
    "\n" +
    "                        New signature attached\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ng-click=\"vm.removeSignatureUploaderFile()\"\r" +
    "\n" +
    "                            class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <p>\r" +
    "\n" +
    "                        Filename:\r" +
    "\n" +
    "                        <strong>{{vm.signatureUploaderFiles[0].file.name}}</strong>\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "                </main>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section\r" +
    "\n" +
    "            class=\"text-left\"\r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                Servicing Roles\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <input aa-field-group=\"vm.user.isProducer\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin col-md-6\"\r" +
    "\n" +
    "                    label-class=\"fill\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.user.isClientServiceExecutive\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin col-md-6\"\r" +
    "\n" +
    "                    label-class=\"fill\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.user.isClientServiceManager\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin col-md-6\"\r" +
    "\n" +
    "                    label-class=\"fill\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.user.isClientServiceAgent\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin col-md-6\"\r" +
    "\n" +
    "                    label-class=\"fill\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section \r" +
    "\n" +
    "            class=\"text-left\"\r" +
    "\n" +
    "            ng-show=\"vm.isEpicIntegrationEnabled\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <header class=\"flex\">\r" +
    "\n" +
    "                <img \r" +
    "\n" +
    "                    class=\"v-center epic-integration-icon\"\r" +
    "\n" +
    "                    src=\"/img/icons/Epic_logo.png\" />\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span class=\"v-center\">\r" +
    "\n" +
    "                    Epic Integration Settings\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.user.epicUserCode\"\r" +
    "\n" +
    "                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <section\r" +
    "\n" +
    "            class=\"text-left\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditUsers\">\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                User Groups\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-repeat=\"userGroup in vm.userGroupOptions\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"userGroup.isSelected\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-label=\"{{userGroup.name}}\"/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <notification-selection \r" +
    "\n" +
    "            class=\"block top-margin-big\"\r" +
    "\n" +
    "            user=\"vm.user\">\r" +
    "\n" +
    "        </notification-selection>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer class=\"flex modal-footer\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-click=\"vm.close()\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-submit-form=\"vm.save_click()\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            value=\"Save\"\r" +
    "\n" +
    "            id=\"saveButton\"\r" +
    "\n" +
    "            class=\"left-margin\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/userGroupDetailModal/userGroupDetailModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"groupForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        <button ng-click=\"vm.close()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>User Group</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.group.name\"\r" +
    "\n" +
    "            aa-label=\"Group Name\"\r" +
    "\n" +
    "            required\r" +
    "\n" +
    "            focus-me/>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Permissions\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditUsers\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditUserSettings\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditUserGroups\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditObligees\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditDocumentLibrary\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <div ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canEditApiConfiguration\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                        label-class=\"fill text-left\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditBondTypes\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditSystemAccounts\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                    aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                    label-class=\"fill text-left\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canReturnPayments\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                        label-class=\"fill text-left\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canEditBillingEntriesReport\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        aa-fg-class=\"top-margin-thin\"\r" +
    "\n" +
    "                        label-class=\"fill text-left\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-click=\"vm.close()\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Close\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            value=\"Save\"\r" +
    "\n" +
    "            class=\"left-margin\"/>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/modals/writingCompanyModal/writingCompanyModal.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form \r" +
    "\n" +
    "    name=\"writingCompanyForm\" \r" +
    "\n" +
    "    novalidate>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <header class=\"modal-header\">\r" +
    "\n" +
    "        <button ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <h4 ng-show=\"!vm.writingCompany.id\">New Writing Company</h4>\r" +
    "\n" +
    "        <h4 ng-show=\"vm.writingCompany.id\">Edit Writing Company</h4>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row text-left\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.name\" \r" +
    "\n" +
    "                maxlength=\"1000\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.stateOfIncorporation\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.fein\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.primaryPhone\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.primaryFax\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.backdateMaxDays\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.predateMaxDays\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.priority\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                aa-unselected-text=\"Set Priority...\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                options=\"vm.priorityOptions\" \r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.defaultMinimumPremium\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <address \r" +
    "\n" +
    "                object=\"vm.writingCompany\" \r" +
    "\n" +
    "                class=\"col-sm-12\" \r" +
    "\n" +
    "                county-required=\"true\" \r" +
    "\n" +
    "                required >\r" +
    "\n" +
    "            </address>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.naicNumber\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.amBestNumber\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.amBestRating\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.amBestFinancialStrengthRating\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.writingCompany.amBestOutlook\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\" col-sm-6\">\r" +
    "\n" +
    "                <h5 class=\"text-left\">Corporate Seal</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"alert alert-danger small top-margin\" \r" +
    "\n" +
    "                    ng-show=\"vm.sealInvalid\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    A Corporate Seal must be chosen before saving the writing company\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uploader\r" +
    "\n" +
    "                    controls=\"vm.sealImageUploader\"\r" +
    "\n" +
    "                    accepted-types=\"images\"\r" +
    "\n" +
    "                    files=\"vm.sealUploadFiles\" >\r" +
    "\n" +
    "                </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <main \r" +
    "\n" +
    "                    class=\"top-margin\"\r" +
    "\n" +
    "                    ng-show=\"vm.sealUploadFiles.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5 class=\"flex\">\r" +
    "\n" +
    "                        New file attached\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ng-click=\"vm.removeSealUploadFile()\"\r" +
    "\n" +
    "                            class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <p>\r" +
    "\n" +
    "                        Filename:\r" +
    "\n" +
    "                        <strong>{{vm.sealUploadFiles[0].file.name}}</strong>\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "                </main>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <main \r" +
    "\n" +
    "                    ng-show=\"!vm.sealUploadFiles.length\" \r" +
    "\n" +
    "                    class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <img ng-src=\"{{ vm.sealPicturePath }}\" />\r" +
    "\n" +
    "                </main>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"text-left col-sm-6\">\r" +
    "\n" +
    "                <h5>Power of Attorney</h5>\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"alert alert-danger small top-margin\" \r" +
    "\n" +
    "                    ng-show=\"vm.poaInvalid\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    A POA document must be chosen before saving the writing company\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"field-flex-box\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <ui-select \r" +
    "\n" +
    "                        ng-model=\"vm.selectedPoaDocument\" \r" +
    "\n" +
    "                        on-select=\"vm.onSelected($item)\" \r" +
    "\n" +
    "                        ng-init=\"$select.label = 'Search Documents'\" \r" +
    "\n" +
    "                        reset-search-input=\"false\" \r" +
    "\n" +
    "                        name=\"documentSelection\" \r" +
    "\n" +
    "                        ng-model-options=\"{debounce:300}\"\r" +
    "\n" +
    "                        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-match \r" +
    "\n" +
    "                            placeholder=\"Search for documents by name...\" \r" +
    "\n" +
    "                            label=\"Search Documents\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{$select.selected.name}}\r" +
    "\n" +
    "                        </ui-select-match>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <ui-select-choices \r" +
    "\n" +
    "                            repeat=\"document in vm.documentSearch track by $index\" \r" +
    "\n" +
    "                            refresh=\"vm.searchDocuments($select.search)\" \r" +
    "\n" +
    "                            refresh-delay=\"0\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div ng-bind-html=\"document.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                        </ui-select-choices>\r" +
    "\n" +
    "                    </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button ng-click=\"vm.selectDocument()\">Select</button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <p>\r" +
    "\n" +
    "                    Filename: \r" +
    "\n" +
    "                    <strong>{{vm.writingCompany.powerOfAttorneyDocument.name}}</strong>\r" +
    "\n" +
    "                </p>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"white\" \r" +
    "\n" +
    "            ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Cancel\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.save_click()\" \r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/agentSpecific/application/application.html',
    "<div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-form=\"applicationForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"page-title\"\r" +
    "\n" +
    "            ng-init=\"vm.formController = applicationForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                Application \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span ng-show=\"vm.application.applicationNumber\">\r" +
    "\n" +
    "                    #{{vm.application.applicationNumber}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <em ng-show=\"!vm.application.applicationNumber\">\r" +
    "\n" +
    "                    - New Application\r" +
    "\n" +
    "                </em>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"!vm.isProduction && !vm.application.id && !vm.isRenewal\">\r" +
    "\n" +
    "                    <i class=\"fa fa-bolt left-margin-big orange\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        no-label\r" +
    "\n" +
    "                        aa-fg-class=\"test-selector\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.selectedTestApplicantId\"\r" +
    "\n" +
    "                        aa-unselected-text=\"Select an Equifax Test Case\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.testApplicants\"\r" +
    "\n" +
    "                        ng-change=\"vm.changeApplicant()\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <dropdown\r" +
    "\n" +
    "                options=\"vm.actionDropdownOptions\"\r" +
    "\n" +
    "                button-text=\"Actions\"\r" +
    "\n" +
    "                button-class=\"small white\"\r" +
    "\n" +
    "                button-id=\"application-form-actions-button\"\r" +
    "\n" +
    "                right-button-icon=\"fa fa-caret-down\"\r" +
    "\n" +
    "                menu-position=\"right\">\r" +
    "\n" +
    "            </dropdown>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"page-body sticky-page width-100p flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                suppress-scroll-x=\"true\"\r" +
    "\n" +
    "                class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "                wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section class=\"above\">\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"fill\">\r" +
    "\n" +
    "                            <div class=\"flex height-25\">\r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Status: \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    ng-show=\"vm.application.status === 'Submitted'\"\r" +
    "\n" +
    "                                    class=\"v-center red\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Application Submitted\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    ng-show=\"vm.application.status === 'NotSubmitted'\"\r" +
    "\n" +
    "                                    class=\"v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    Not Submitted\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex height-25\">\r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Application Type: \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    ng-show=\"vm.isRenewal\"\r" +
    "\n" +
    "                                    class=\"v-center\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Renewal\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    ng-show=\"!vm.isRenewal\"\r" +
    "\n" +
    "                                    class=\"v-center\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    New Business\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                class=\"flex height-25\"\r" +
    "\n" +
    "                                ng-show=\"vm.isRenewal\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Bond Number: \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span class=\"v-center\">\r" +
    "\n" +
    "                                    {{ vm.bondNumber }}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex height-25\">\r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Bond Type: \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span class=\"v-center\">\r" +
    "\n" +
    "                                    {{ vm.bondType.name }}\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <a  \r" +
    "\n" +
    "                                        ng-disabled=\"vm.application.status != 'NotSubmitted'\"\r" +
    "\n" +
    "                                        ng-click=\"vm.showBondTypeSelectionModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex height-25\">\r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Bond Amount: \r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.application.bondAmount\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                    ng-show=\"vm.bondType.bondAmountType == 'UserDefined' || vm.bondType.bondAmountType == 'Fixed'\"\r" +
    "\n" +
    "                                    ng-disabled=\"vm.bondType.bondAmountType == 'Fixed'\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center width-100\"\r" +
    "\n" +
    "                                    min=\"{{vm.bondType.minimumBondAmount}}\"\r" +
    "\n" +
    "                                    max=\"{{vm.bondType.maximumBondAmount}}\"\r" +
    "\n" +
    "                                    ng-required=\"vm.bondType.bondAmountType == 'UserDefined'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <span\r" +
    "\n" +
    "                                    class=\"v-center left-margin\"\r" +
    "\n" +
    "                                    ng-class=\"{'red' : vm.bondType.bondAmountType == 'UserDefined' && (vm.application.bondAmount < vm.bondType.minimumBondAmount || vm.aapplication.bondAmount > vm.bondType.maximumBondAmount)}\"\r" +
    "\n" +
    "                                    ng-show=\"vm.bondType.bondAmountType == 'UserDefined'\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                    Between: {{vm.bondType.minimumBondAmount | currency:'$'}} - {{vm.bondType.maximumBondAmount | currency: \"$\"}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <select\r" +
    "\n" +
    "                                    aa-field-group=\"vm.application.bondAmount\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                    aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                                    aa-label=\"Bond Amount\"\r" +
    "\n" +
    "                                    ng-show=\"vm.bondType.bondAmountType == 'Variable'\"\r" +
    "\n" +
    "                                    aa-unselected-text=\"Select bond amount\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center width-100\"\r" +
    "\n" +
    "                                    ng-required=\"vm.bondType.bondAmountType == 'Variable'\"\r" +
    "\n" +
    "                                    options=\"vm.bondTypeVariableBondAmounts\">\r" +
    "\n" +
    "                                </select>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex height-25 top-margin-thin\">\r" +
    "\n" +
    "                                <span class=\"v-center width-150 inline-block\">\r" +
    "\n" +
    "                                    Bond Effective Date:\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.application.desiredEffectiveDate\"\r" +
    "\n" +
    "                                    id=\"application-desired-effective-date\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center width-100 text-center\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"date\"\r" +
    "\n" +
    "                                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                    ng-required=\"vm.bondType\"\r" +
    "\n" +
    "                                    ng-disabled=\"vm.isRenewal\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <span\r" +
    "\n" +
    "                                    class=\"red v-center left-margin\"\r" +
    "\n" +
    "                                    ng-show=\"vm.application.desiredEffectiveDate < vm.formOptions.minimumEffectiveDate\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                    The earliest date a carrier allows for this bond type is\r" +
    "\n" +
    "                                    {{ vm.formOptions.minimumEffectiveDate | date: 'MM/dd/yyyy' }}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                <span\r" +
    "\n" +
    "                                    class=\"red v-center left-margin\"\r" +
    "\n" +
    "                                    ng-show=\"vm.application.desiredEffectiveDate > vm.formOptions.maximumEffectiveDate\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                    The latest date a carrier allows for this bond type is\r" +
    "\n" +
    "                                    {{ vm.formOptions.maximumEffectiveDate | date: 'MM/dd/yyyy' }}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-show=\"!vm.isRenewal\" \r" +
    "\n" +
    "                                class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"flex height-25\">\r" +
    "\n" +
    "                                    <span class=\"v-center width-150\">\r" +
    "\n" +
    "                                        Broker:\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"fill\">\r" +
    "\n" +
    "                                    <input\r" +
    "\n" +
    "                                        aa-field-group=\"vm.isEProducerBrokerReferral\"\r" +
    "\n" +
    "                                        aa-label=\"Assign to Broker\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                        aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                        aa-label-class=\"small\"/>    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <ui-select \r" +
    "\n" +
    "                                        class=\"top-margin-big width-300\"\r" +
    "\n" +
    "                                        ng-show=\"vm.isEProducerBrokerReferral\"\r" +
    "\n" +
    "                                        ng-model=\"vm.referringEProducerBrokerAccount\" \r" +
    "\n" +
    "                                        ng-init=\"$select.label = 'Search Brokers...'\" \r" +
    "\n" +
    "                                        reset-search-input=\"false\" \r" +
    "\n" +
    "                                        name=\"brokerSearch\" \r" +
    "\n" +
    "                                        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <ui-select-match \r" +
    "\n" +
    "                                            placeholder=\"Search brokers...\" \r" +
    "\n" +
    "                                            label=\"Broker Account\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            {{$select.selected.name}}\r" +
    "\n" +
    "                                        </ui-select-match>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <ui-select-choices \r" +
    "\n" +
    "                                            repeat=\"account in vm.brokerSearchResults track by $index\" \r" +
    "\n" +
    "                                            refresh=\"vm.searchBrokers($select.search)\" \r" +
    "\n" +
    "                                            refresh-delay=\"100\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <span ng-bind-html=\"account.lookupCode | highlight: $select.search\"></span>\r" +
    "\n" +
    "                                            <span ng-show=\"account.lookupCode\"> - </span>\r" +
    "\n" +
    "                                            <span ng-bind-html=\"account.name | highlight: $select.search\"></span>\r" +
    "\n" +
    "                                        </ui-select-choices>\r" +
    "\n" +
    "                                    </ui-select>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-show=\"!vm.isRenewal\"\r" +
    "\n" +
    "                                class=\"flex\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <div class=\"flex height-25\">\r" +
    "\n" +
    "                                    <span class=\"v-center width-150\">\r" +
    "\n" +
    "                                        Principal Type:\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                                    ng-change=\"vm.changePrincipalType()\"\r" +
    "\n" +
    "                                    aa-label=\"Principal is a company\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                    aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                    aa-label-class=\"small\"/>                    \r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex column\">\r" +
    "\n" +
    "                            <user-dropdown\r" +
    "\n" +
    "                                ng-model=\"vm.application.producerUserId\"\r" +
    "\n" +
    "                                class=\"fixed-width-md top-margin\"\r" +
    "\n" +
    "                                is-producer=\"true\"\r" +
    "\n" +
    "                                label=\"Producer\"\r" +
    "\n" +
    "                                ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"Default\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <user-dropdown\r" +
    "\n" +
    "                                ng-model=\"vm.application.clientServiceExecutiveUserId\"\r" +
    "\n" +
    "                                class=\"fixed-width-md top-margin\"\r" +
    "\n" +
    "                                is-client-service-executive=\"true\"\r" +
    "\n" +
    "                                label=\"Client Service Executive\"\r" +
    "\n" +
    "                                ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"Default\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <user-dropdown\r" +
    "\n" +
    "                                ng-model=\"vm.application.clientServiceManagerUserId\"\r" +
    "\n" +
    "                                class=\"fixed-width-md top-margin\"\r" +
    "\n" +
    "                                is-client-service-manager=\"true\"\r" +
    "\n" +
    "                                label=\"Client Service Manager\"\r" +
    "\n" +
    "                                ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"Default\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <user-dropdown\r" +
    "\n" +
    "                                ng-model=\"vm.application.clientServiceAgentUserId\"\r" +
    "\n" +
    "                                class=\"fixed-width-md top-margin\"\r" +
    "\n" +
    "                                is-client-service-agent=\"true\"\r" +
    "\n" +
    "                                label=\"Client Service Agent\"\r" +
    "\n" +
    "                                ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                allow-unselected=\"true\"\r" +
    "\n" +
    "                                unselected-text=\"Default\">\r" +
    "\n" +
    "                            </user-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.hideNotRequired\"\r" +
    "\n" +
    "                                aa-label=\"Only show what's required\"\r" +
    "\n" +
    "                                aa-label-class=\"small\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                ng-show=\"!vm.isRenewal\"\r" +
    "\n" +
    "                                aa-fg-class=\"nowrap top-margin-auto\" />  \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <company\r" +
    "\n" +
    "                    ng-if=\"vm.isCompanyPrincipalType\"\r" +
    "\n" +
    "                    class=\"section\"\r" +
    "\n" +
    "                    company=\"company\"\r" +
    "\n" +
    "                    ng-repeat=\"company in vm.application.companies track by $index\"\r" +
    "\n" +
    "                    hide-optional-fields=\"vm.hideNotRequired\"\r" +
    "\n" +
    "                    required-fields=\"vm.formOptions.required\"\r" +
    "\n" +
    "                    changed=\"vm.setNameOnBondOptions(company, changedProperty)\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isRenewal\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <company-heading>\r" +
    "\n" +
    "                        <i class=\"fa fa-building\"></i><span>Company Information</span>\r" +
    "\n" +
    "                    </company-heading>\r" +
    "\n" +
    "                </company>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section class=\"above text-right\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        type=\"button\"\r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.application.addPerson()\"\r" +
    "\n" +
    "                        ng-show=\"vm.application.applicationType != 'SingleBondRenewal' && (vm.bondType.multiplePeopleAllowed || vm.application.principalType === 'Company')\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"!vm.isCompanyPrincipalType\">\r" +
    "\n" +
    "                            Add Individual\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span ng-show=\"vm.isCompanyPrincipalType\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Add Owner\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <person\r" +
    "\n" +
    "                    class=\"section\"\r" +
    "\n" +
    "                    person=\"person\"\r" +
    "\n" +
    "                    ng-repeat=\"person in vm.application.people track by $index\"\r" +
    "\n" +
    "                    hide-optional-fields=\"vm.hideNotRequired\"\r" +
    "\n" +
    "                    required-fields=\"vm.formOptions.required\"\r" +
    "\n" +
    "                    changed=\"vm.setNameOnBondOptions(person, changedProperty)\"\r" +
    "\n" +
    "                    spousal-indemnity-required=\"vm.formOptions.requiresSpousalIndemnity\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.isRenewal\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <person-heading>\r" +
    "\n" +
    "                        <i class=\"fa fa-user\"></i>\r" +
    "\n" +
    "                        <span ng-show=\"!vm.isCompanyPrincipalType\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Personal Information\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-show=\"vm.isCompanyPrincipalType\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Business Owner\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ng-show=\"vm.application.people.length > 1 && vm.application.applicationType != 'SingleBondRenewal'\"\r" +
    "\n" +
    "                            class=\"pull-right\"\r" +
    "\n" +
    "                            ng-click=\"vm.application.removePerson($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Remove Individual\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </person-heading>\r" +
    "\n" +
    "                </person>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-if=\"!vm.isRenewal && (!vm.application.obligee || vm.application.obligee.isGeneric)\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-question\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Obligee Information\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <obligee-form\r" +
    "\n" +
    "                        entry-mode=\"application\"\r" +
    "\n" +
    "                        obligee=\"vm.application.obligee\" />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.formOptions.underwritingQuestions.length\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-question\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Underwriting Questions\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <additional-questions \r" +
    "\n" +
    "                        questions=\"vm.formOptions.underwritingQuestions\" \r" +
    "\n" +
    "                        question-responses=\"vm.questionResponses\" />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Sign &amp; Submit\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.selectedNameOnBond\"\r" +
    "\n" +
    "                        aa-label=\"Printed name on bond\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        is-string\r" +
    "\n" +
    "                        aa-unselected-text=\"Select name to appear on bond\"\r" +
    "\n" +
    "                        options=\"vm.nameOnBondOptions\"\r" +
    "\n" +
    "                        ng-required=\"vm.application.applicationType != 'SingleBondRenewal'\"\r" +
    "\n" +
    "                        ng-if=\"!vm.isRenewal\"\r" +
    "\n" +
    "                        id=\"application-name-on-bond-select\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.application.nameOnBond\"\r" +
    "\n" +
    "                        aa-label=\"Printed name on bond\"\r" +
    "\n" +
    "                        ng-required=\"vm.tselectedNameOnBond === 'Other'\"\r" +
    "\n" +
    "                        ng-show=\"vm.selectedNameOnBond === 'Other' || vm.isRenewal\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.isRenewal\" \r" +
    "\n" +
    "                        id=\"application-name-on-bond-other-input\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.application.attorneyInFactUserId\"\r" +
    "\n" +
    "                        aa-label=\"Attorney-In-Fact\"\r" +
    "\n" +
    "                        aa-unselected-text=\"Select attorney in fact...\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.aifOptions\"\r" +
    "\n" +
    "                        id=\"application-attorney-in-fact-select\"\r" +
    "\n" +
    "                        required>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"sticky-page-footer\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                class=\"white right-margin\"\r" +
    "\n" +
    "                id=\"application-not-submitted-save-button\"\r" +
    "\n" +
    "                ng-if=\"vm.application.status != 'Submitted'\"\r" +
    "\n" +
    "                ng-click=\"vm.saveApplication_click(false)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Save\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                class=\"white right-margin\"\r" +
    "\n" +
    "                id=\"application-submitted-save-button\"\r" +
    "\n" +
    "                ng-if=\"vm.application.status == 'Submitted'\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.saveApplication_click(false)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Save\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                value=\"Save & Submit\"\r" +
    "\n" +
    "                id=\"application-save-button\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.saveApplication_click(true)\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/applicationReport/applicationReport.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        Applications\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body fill-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-form=\"applicationsSearchForm\"\r" +
    "\n" +
    "        class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "            aa-label=\"Search Applications\"\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Refresh\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            id=\"applications-report-refresh-button\"\r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-report-filter-dropdown\r" +
    "\n" +
    "            class=\"flex left-margin\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </application-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                uib-tooltip=\"Actions\"\r" +
    "\n" +
    "                tooltip-placement=\"top-right\"\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                id=\"customer-grid-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportApplicationReport()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                        Download Excel\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "     \r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <table \r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "                class=\"bottom-margin group-by-tbody nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr\r" +
    "\n" +
    "                        class=\"sortable\"\r" +
    "\n" +
    "                        ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <th  \r" +
    "\n" +
    "                            class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                            ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <table-sort-button\r" +
    "\n" +
    "                                text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                            </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>       \r" +
    "\n" +
    "                <tbody ng-repeat=\"application in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\" \r" +
    "\n" +
    "                            ng-switch=\"column.name\"\r" +
    "\n" +
    "                            ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                            ng-class=\"column.cellClass\">    \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <span ng-switch-when=\"ApplicationNumber\">\r" +
    "\n" +
    "                                <a show-application-detail-modal=\"application.id\">\r" +
    "\n" +
    "                                    {{application.applicationNumber}}\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span \r" +
    "\n" +
    "                                ng-switch-when=\"Status\"\r" +
    "\n" +
    "                                ng-bind-html=\"application | applicationStatus\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ApplicationType\">\r" +
    "\n" +
    "                                {{application | applicationType}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"NameOnBond\">\r" +
    "\n" +
    "                                {{application.nameOnBond}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                ng-switch-when=\"CustomerName\"\r" +
    "\n" +
    "                                ui-sref=\"main.customerDetail({ customerId: application.customerId, sectionView: 'applications' })\"\r" +
    "\n" +
    "                                target=\"_blank\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{application.customerName}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CustomerLookupCode\">{{application.customerLookupCode}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"DesiredEffectiveDate\">{{application.desiredEffectiveDate | dateOnly}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BondAmount\">{{application.bondAmount | currency: '$' }}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BondType\">{{application.bondType | characters : 40 : '...'}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Producer\">{{application.producer}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceExecutive\">{{application.clientServiceExecutive}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceManager\">{{application.clientServiceManager}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceAssociate\">{{application.clientServiceAssociate}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"OriginSystem\">{{application.originSystemDescription}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"SfaaCode\">{{application.sfaaCode}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Broker\">{{application.broker}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrokerLookupCode\">{{application.brokerLookupCode}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeName\">{{application.obligeeName}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailAddress\">{{application.obligeeMailAddress}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailCity\">{{application.obligeeMailCity}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailState\">{{application.obligeeMailState}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailZip\">{{application.obligeeMailZip}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RequisitioningState\">{{application.requisitioningState}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ApprovedQuoteCount\">{{application.approvedQuoteCount}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"PendingQuoteCount\">{{application.pendingQuoteCount}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Tags\">\r" +
    "\n" +
    "                                <span class=\"tag-container\">\r" +
    "\n" +
    "                                    <span \r" +
    "\n" +
    "                                        ng-repeat=\"tag in application.tags\"\r" +
    "\n" +
    "                                        class=\"tag\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        {{tag}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>    \r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No applications found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        class=\"top-margin\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/applicationReport/applicationReportFilterDropdown/applicationReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"filterDropdownForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul  \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>     \r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Application Type</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.modifiedFilters.basicFilters.applicationType\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                            <option value=\"\">All</option>\r" +
    "\n" +
    "                            <option value=\"SingleBond\">New Business</option>\r" +
    "\n" +
    "                            <option value=\"SingleBondRenewal\">Renewal</option>                 \r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Open/Closed</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.openClosedStatus\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\">All</option>\r" +
    "\n" +
    "                        <option value=\"Open\">Open</option>\r" +
    "\n" +
    "                        <option value=\"Closed\">Closed</option>\r" +
    "\n" +
    "                        <option value=\"Purchased\">Purchased</option>              \r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.status\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\">All</option>\r" +
    "\n" +
    "                        <option value=\"NotSubmitted\">Not Submitted</option>\r" +
    "\n" +
    "                        <option value=\"Submitted\">Submitted</option>\r" +
    "\n" +
    "                        <option value=\"Approved\">Approved</option>\r" +
    "\n" +
    "                        <option value=\"ManualReview\">Manual Review</option>                \r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Bond Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumBondAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumBondAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Effective Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumEffectiveDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumEffectiveDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Created Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumCreatedDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumCreatedDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Submission Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumSubmissionDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumSubmissionDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "                    Tagged With\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.tag\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\"></option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option \r" +
    "\n" +
    "                            ng-repeat=\"tag in vm.tagOptions\" \r" +
    "\n" +
    "                            value=\"{{tag.value}}\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{tag.label}}\r" +
    "\n" +
    "                        </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/billingEntry/billingEntryReport.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"billingEntriesReportForm\">\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Billing Entries \r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body width-100p sticky-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-x=\"true\"\r" +
    "\n" +
    "            class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "            wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"flex bottom-margin top-margin\"\r" +
    "\n" +
    "                ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "                        aa-label=\"Search Billing Entries\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search Billing Entries\"\r" +
    "\n" +
    "                        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <record-counter\r" +
    "\n" +
    "                    class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "                    record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "                </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a id=\"purchased-bonds-report-refresh-button\"\r" +
    "\n" +
    "                    class=\"v-center left-margin\"\r" +
    "\n" +
    "                    ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <billing-entry-report-filter-dropdown \r" +
    "\n" +
    "                    class=\"flex left-margin\"\r" +
    "\n" +
    "                    table=\"vm.table\">\r" +
    "\n" +
    "                </billing-entry-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <column-selection-dropdown-menu \r" +
    "\n" +
    "                    class=\"left-margin v-center\"\r" +
    "\n" +
    "                    table=\"vm.table\">\r" +
    "\n" +
    "                </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                    uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Actions\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                        <li>\r" +
    "\n" +
    "                            <button class=\"small\"\r" +
    "\n" +
    "                                    aa-submit-form=\"vm.exportBillingEntries()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                                Download Excel\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "                    </ul>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                suppress-scroll-y=\"true\"\r" +
    "\n" +
    "                class=\"scroller no-right-padding\"\r" +
    "\n" +
    "                wheel-propagation=\"true\"\r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <table class=\"group-by-tbody bottom-margin nowrap\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                        <tr\r" +
    "\n" +
    "                            class=\"sortable\"\r" +
    "\n" +
    "                            ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <th \r" +
    "\n" +
    "                                class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                                ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                                ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                                ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <table-sort-button\r" +
    "\n" +
    "                                    text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                    sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                    table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                    uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                                </table-sort-button>\r" +
    "\n" +
    "                            </th>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "                    <tbody ng-repeat=\"billingEntry in vm.table.data\">\r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <td\r" +
    "\n" +
    "                                ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                                ng-switch=\"column.name\"\r" +
    "\n" +
    "                                ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                                ng-class=\"column.cellClass\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span ng-switch-when=\"Id\">{{billingEntry.id}}</span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BilledTo\">\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        class=\"nowrap\"\r" +
    "\n" +
    "                                        ng-show=\"!vm.eProducerAccountId\"\r" +
    "\n" +
    "                                        ui-sref=\"main.customerDetail({ customerId: billingEntry.customerId, sectionView: 'billingEntries' })\"\r" +
    "\n" +
    "                                        target=\"{{'customerDetail/' + billingEntry.customerId}}\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{billingEntry.customerName}}\r" +
    "\n" +
    "                                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        class=\"nowrap\"\r" +
    "\n" +
    "                                        ng-show=\"vm.eProducerAccountId\"\r" +
    "\n" +
    "                                        ui-sref=\"main.eProducerAccountDetail({ id: billingEntry.eProducerAccountId })\"\r" +
    "\n" +
    "                                        target=\"{{'eProducerAccountDetail/' + billingEntry.eProducerAccountId}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{billingEntry.eProducerAccountName}}\r" +
    "\n" +
    "                                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"CustomerLookupCode\">\r" +
    "\n" +
    "                                    {{billingEntry.customerLookupCode}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Customer\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        class=\"nowrap\"\r" +
    "\n" +
    "                                        ui-sref=\"main.customerDetail({ customerId: billingEntry.customerId, sectionView: 'billingEntries' })\"\r" +
    "\n" +
    "                                        target=\"{{'customerDetail/' + billingEntry.customerId}}\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{billingEntry.customerName}}\r" +
    "\n" +
    "                                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Broker\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        class=\"nowrap\"\r" +
    "\n" +
    "                                        ng-show=\"billingEntry.eProducerAccountName\"\r" +
    "\n" +
    "                                        ui-sref=\"main.eProducerAccountDetail({ id: billingEntry.eProducerAccountId })\"\r" +
    "\n" +
    "                                        target=\"{{'eProducerAccountDetail/' + billingEntry.eProducerAccountId}}\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        {{billingEntry.eProducerAccountName}}\r" +
    "\n" +
    "                                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"CarrierLogo\">\r" +
    "\n" +
    "                                    <system-account-logo\r" +
    "\n" +
    "                                        system-account-id=\"billingEntry.carrierSystemAccountId\"\r" +
    "\n" +
    "                                        show-labels=\"false\">\r" +
    "\n" +
    "                                    </system-account-logo>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"EffectiveDate\">\r" +
    "\n" +
    "                                    {{billingEntry.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"ExpirationDate\">\r" +
    "\n" +
    "                                    {{billingEntry.expirationDate | dateOnly}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"TransactionEffectiveDate\">\r" +
    "\n" +
    "                                    {{billingEntry.transactionEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Carrier\">\r" +
    "\n" +
    "                                    {{billingEntry.carrier}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"WritingCompany\">\r" +
    "\n" +
    "                                    {{billingEntry.writingCompany}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BrokerLookupCode\">\r" +
    "\n" +
    "                                    {{billingEntry.brokerLookupCode}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Producer\">\r" +
    "\n" +
    "                                    <span ng-show=\"!vm.canEditBillingEntries\">\r" +
    "\n" +
    "                                        {{billingEntry.producer}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                                    <user-dropdown \r" +
    "\n" +
    "                                        ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                        ng-model=\"billingEntry.producerUserId\"\r" +
    "\n" +
    "                                        is-producer=\"true\"\r" +
    "\n" +
    "                                        label=\"\"\r" +
    "\n" +
    "                                        class=\"no-margin width-100\"\r" +
    "\n" +
    "                                        allow-unselected=\"true\"\r" +
    "\n" +
    "                                        unselected-text=\"\">\r" +
    "\n" +
    "                                    </user-dropdown>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"ClientServiceExecutive\">\r" +
    "\n" +
    "                                    {{billingEntry.clientServiceExecutive}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"ClientServiceManager\">\r" +
    "\n" +
    "                                    {{billingEntry.clientServiceManager}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"ClientServiceAssociate\">\r" +
    "\n" +
    "                                    {{billingEntry.clientServiceAssociate}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    class=\"flex\"\r" +
    "\n" +
    "                                    ng-switch-when=\"ProductionCredit\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                        aa-field-group=\"billingEntry.productionCredit\"\r" +
    "\n" +
    "                                        aa-fg-class=\"left-margin-auto right-margin-auto small-switch\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-if=\"!vm.canEditBillingEntries\">\r" +
    "\n" +
    "                                        {{billingEntry.productionCredit ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BondNumber\">\r" +
    "\n" +
    "                                    <a show-bond-detail-modal=\"{{billingEntry.bondId}}\">\r" +
    "\n" +
    "                                        {{billingEntry.bondNumber}}\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BillingEntryType\">\r" +
    "\n" +
    "                                    {{billingEntry.billingEntryType}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Description\">\r" +
    "\n" +
    "                                    {{billingEntry.description}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"TransactionType\">\r" +
    "\n" +
    "                                    {{billingEntry | transactionType}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"CreatedDateTime\">\r" +
    "\n" +
    "                                    {{billingEntry.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Code\">\r" +
    "\n" +
    "                                    {{billingEntry.code}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"IsOriginalEntry\">\r" +
    "\n" +
    "                                    {{billingEntry.isOriginalEntry ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Invoices\">\r" +
    "\n" +
    "                                    <span ng-repeat=\"invoice in billingEntry.invoices\">\r" +
    "\n" +
    "                                        <a show-invoice-detail-modal=\"invoice.id\">\r" +
    "\n" +
    "                                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                                        </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <br ng-if=\"!$last\"/>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BillToType\">\r" +
    "\n" +
    "                                    <span ng-show=\"billingEntry.billToType == 'BillClient'\">Bill Client</span>\r" +
    "\n" +
    "                                    <span ng-show=\"billingEntry.billToType == 'BillBrokerNet'\">Bill Broker (Net)</span>\r" +
    "\n" +
    "                                    <span ng-show=\"billingEntry.billToType == 'BillBrokerGross'\">Bill Broker (Gross)</span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"OriginalAmount\">\r" +
    "\n" +
    "                                    {{billingEntry.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"AmountReceived\">\r" +
    "\n" +
    "                                    {{billingEntry.amountReceived | currency: '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"AmountDue\">\r" +
    "\n" +
    "                                    {{billingEntry.amountDue | currency: '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"BrokerCommission\">\r" +
    "\n" +
    "                                    {{billingEntry.brokerCommission | currency: '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"Commission\">\r" +
    "\n" +
    "                                    {{billingEntry.commission | currency: '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span\r" +
    "\n" +
    "                                    class=\"flex\" \r" +
    "\n" +
    "                                    ng-switch-when=\"IsProductionCreditPaid\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                        aa-field-group=\"billingEntry.isProductionCreditPaid\"\r" +
    "\n" +
    "                                        aa-fg-class=\"left-margin-auto right-margin-auto small-switch\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"none\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-if=\"!vm.canEditBillingEntries\">\r" +
    "\n" +
    "                                        {{billingEntry.isProductionCreditPaid ? \"Yes\" : \"No\"}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span ng-switch-when=\"CarrierPayableOriginalAmount\">\r" +
    "\n" +
    "                                    {{billingEntry.carrierPayableOriginalAmount | currency : '$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    class=\"flex\"\r" +
    "\n" +
    "                                    ng-switch-when=\"CarrierPayableAmountDue\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                        aa-field-group=\"billingEntry.carrierPayablePaid\"\r" +
    "\n" +
    "                                        aa-fg-class=\"inline-block small-switch\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                                        aa-label=\"Paid\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span class=\"inline-block left-margin-auto\">\r" +
    "\n" +
    "                                        {{billingEntry.carrierPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    class=\"flex\"\r" +
    "\n" +
    "                                    ng-switch-when=\"BrokerPayableAmountDue\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "                                        aa-field-group=\"billingEntry.brokerPayablePaid\"\r" +
    "\n" +
    "                                        aa-fg-class=\"inline-block small-switch\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                                        aa-label=\"Paid\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span class=\"inline-block left-margin-auto\">\r" +
    "\n" +
    "                                        {{billingEntry.brokerPayableAmountDue | currency : '$'}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </tbody>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <empty-state\r" +
    "\n" +
    "                ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "                message=\"No records found\">\r" +
    "\n" +
    "            </empty-state>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <table-pagination-footer \r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "                table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "            </table-pagination-footer>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"sticky-page-footer flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            ng-if=\"vm.canEditBillingEntries\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            value=\"Save\"\r" +
    "\n" +
    "            id=\"billing-entries-report-save-button\"\r" +
    "\n" +
    "            class=\"left-margin-auto v-center\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save()\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingEntryReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"billingEntryReportFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> \r" +
    "\n" +
    "            ({{vm.filterCount}}) \r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul  \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>     \r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Invoiced</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.invoiced\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\"> Any </option>\r" +
    "\n" +
    "                        <option value=\"true\"> Invoiced </option>\r" +
    "\n" +
    "                        <option value=\"false\"> Not Invoiced </option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Billing Entry Type</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.billingEntryType\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"\"> Any </option>\r" +
    "\n" +
    "                        <option value=\"Premium\"> Premium </option>\r" +
    "\n" +
    "                        <option value=\"Fee\"> Fee </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Original vs Corrections</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.isOriginalEntry\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                        <option value=\"\">All</option>\r" +
    "\n" +
    "                        <option value=\"true\">Original Entries</option>\r" +
    "\n" +
    "                        <option value=\"false\">Corrections</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Credit Producer</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.productionCredit\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                        <option value=\"\">All</option>\r" +
    "\n" +
    "                        <option value=\"true\">Yes</option>\r" +
    "\n" +
    "                        <option value=\"false\">No</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Billing Code</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.code\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.codeOptions\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Transaction Type</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.transactionType\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"transactionType\"\r" +
    "\n" +
    "                        unselected-text=\"Any\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.modifiedFilters.basicFilters.producerUserId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-fg-class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                            options=\"vm.producerUserOptions\"\r" +
    "\n" +
    "                            aa-label=\"Producer\"\r" +
    "\n" +
    "                            aa-unselected-text=\"Any\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.startDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.endDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Billed Amount </label>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmountBilled\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmountBilled\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Amount Received Between </label>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmountReceived\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmountReceived\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Amount Due Between </label>\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmountDue\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmountDue\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button class=\"white small\"\r" +
    "\n" +
    "                        ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        type=\"submit\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/bondLines/bondLinesReport.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        Bond Lines\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body fill-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"bondLinesSearchForm\"\r" +
    "\n" +
    "        class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "            aa-label=\"Search Bond Lines\"\r" +
    "\n" +
    "            uib-tooltip=\"Search Bond Lines\"\r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Refresh\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            id=\"bond-lines-report-refresh-button\"\r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <bond-line-report-filter-dropdown\r" +
    "\n" +
    "            class=\"left-margin flex\"\r" +
    "\n" +
    "            ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </bond-line-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                uib-tooltip=\"Actions\"\r" +
    "\n" +
    "                tooltip-placement=\"top-right\"\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                id=\"customer-grid-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button class=\"small\"\r" +
    "\n" +
    "                            aa-submit-form=\"vm.exportBondLinesExcelReport()\">\r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                        Download Excel\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <table \r" +
    "\n" +
    "                class=\"bottom-margin group-by-tbody\"\r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr\r" +
    "\n" +
    "                        class=\"sortable\"\r" +
    "\n" +
    "                        ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <th\r" +
    "\n" +
    "                            class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                            ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <table-sort-button\r" +
    "\n" +
    "                                text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                            </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody ng-repeat=\"bondLine in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\" \r" +
    "\n" +
    "                            ng-switch=\"column.name\"\r" +
    "\n" +
    "                            ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                            ng-class=\"column.cellClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"Id\">{{bondLine.id}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"LookupCode\">{{bondLine.lookupCode}}</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"Customer\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                <a\r" +
    "\n" +
    "                                    ui-sref=\"main.customerDetail({ customerId: bondLine.customerId, sectionView: '' })\"\r" +
    "\n" +
    "                                    target=\"_blank\">    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    {{bondLine.customerName}}\r" +
    "\n" +
    "                                    <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Status\">{{bondLine.status}}</span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"EffectiveDate\">{{bondLine.effectiveDate | dateOnly}}</span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"ExpirationDate\">{{bondLine.expirationDate | dateOnly}}</span>                  \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceManager\">{{bondLine.clientServiceManager}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceExecutive\">{{bondLine.clientServiceExecutive}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceAssociate\">{{bondLine.clientServiceAssociate}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Producer\">{{bondLine.producer}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Carrier\">{{bondLine.carrier}} </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CarrierAccountNumber\">{{bondLine.carrierAccountNumber}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"AggregateBondLimit\">{{bondLine.aggregateBondLimit | currency:'$'}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"SingleBondLimit\">{{bondLine.singleBondLimit | currency:'$'}}</span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"DetailsButton\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"mini\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showBondAccountModal(bondLine)\">\r" +
    "\n" +
    "                                    Details\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No bond lines found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        class=\"top-margin\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/bondLines/bondLinesReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"filterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}}) \r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul\r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li\r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "                    Status\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.status\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                        <option value=\"\"> Any </option>\r" +
    "\n" +
    "                        <option value=\"Active\"> Active </option>\r" +
    "\n" +
    "                        <option value=\"Cancelled\"> Cancelled </option>\r" +
    "\n" +
    "                        <option value=\"Suspended\"> Suspended </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Carrier </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.carrierSystemAccountId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.carrierOptions\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Single Limit Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumSingleLimit\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumSingleLimit\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Aggregate Limit Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAggregateAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAggregateAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Expiration Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.startExpirationDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.endExpirationDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/bondTypeSelection/bondTypeSelection.html',
    "<div>\r" +
    "\n" +
    "    <bond-type-selection \r" +
    "\n" +
    "        on-select=\"vm.bondTypeSelected(bondTypeId)\">\r" +
    "\n" +
    "    </bond-type-selection>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/carrierDetail/carrierDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section class=\"above\">\r" +
    "\n" +
    "    <h2>\r" +
    "\n" +
    "        <img class=\"system-account-logo\" ng-src=\"{{ vm.smLogoPicturePath }}\"/>    \r" +
    "\n" +
    "        {{vm.carrier.companyName}}\r" +
    "\n" +
    "    </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"row\">\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-bind-html=\"vm.carrier | physicalAddress:'two-line'\" \r" +
    "\n" +
    "            class=\"col-sm-6 col-md-4 col-lg-3\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"col-sm-6 col-md-8 col-lg-9\">\r" +
    "\n" +
    "            <div ng-show=\"vm.carrier.websiteURL\">\r" +
    "\n" +
    "                <strong>Website:</strong> \r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    ng-href=\"{{ vm.carrier.websiteURL }}\" \r" +
    "\n" +
    "                    target=\"_blank\">{{ vm.carrier.websiteURL }}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <strong>Active Since:</strong>     \r" +
    "\n" +
    "                {{ vm.carrier.createdDateTime | date:'MM/d/yyyy' }}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"carrierDetail\">\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <h5>Writing Companies</h5>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <table \r" +
    "\n" +
    "            class=\"top-margin\"\r" +
    "\n" +
    "            ng-show=\"vm.carrier.writingCompanies.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>Name</th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        Epic PPE Code\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        Epic ICO\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"writingCompany in vm.carrier.writingCompanies\">\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{writingCompany.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"writingCompany.epicPremiumPayableCode\"\r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin width-150\"\r" +
    "\n" +
    "                            maxlength=\"100\" />\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"writingCompany.epicIssuingCompanyCode\" \r" +
    "\n" +
    "                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                            aa-fg-class=\"no-margin width-150\"\r" +
    "\n" +
    "                            maxlength=\"100\" />\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"!vm.carrier.writingCompanies.length\"\r" +
    "\n" +
    "            message=\"No writing companies found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ui-sref=\"main.agencyCarriers\"\r" +
    "\n" +
    "            class=\"white left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            class=\"left-margin\"\r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            value=\"Save\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.submit()\"/>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/carriers/carrierPage.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"bottom-margin flex\"\r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search Carriers...\"\r" +
    "\n" +
    "        uib-tooltip=\"Search Carriers\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Carrier\"\r" +
    "\n" +
    "                    sort-expression=\"CarrierName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"carrier in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ui-sref=\"main.agencyCarrierDetail({ carrierSystemAccountId: carrier.carrierSystemAccountId })\">\r" +
    "\n" +
    "                    {{carrier.carrierName}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/customerDetail/customerDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\" ></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"customerDetailForm\">\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Customer Detail\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            class=\"small\"\r" +
    "\n" +
    "            ng-show=\"vm.summaryChanged\"\r" +
    "\n" +
    "            ng-click=\"vm.saveSummary()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"dropdown actions left-margin\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                id=\"customer-detail-action-dropdown-button\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                class=\"small white\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li ng-show=\"vm.showEpicConnectionButton\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.showEpicConnectionModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <img class=\"v-center epic-integration-icon\"\r" +
    "\n" +
    "                                src=\"/img/icons/Epic_logo.png\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Epic Connection\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button ui-sref=\"main.bondTypeSelection({ customerId: vm.customerId})\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-file\"></i> \r" +
    "\n" +
    "                        New Application\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        id=\"customer-detail-action-dropdown-issue-bond-button\"\r" +
    "\n" +
    "                        ng-click=\"vm.showIssueBondModal()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                        Issue Bond\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        id=\"customer-detail-action-dropdown-new-bond-account\"\r" +
    "\n" +
    "                        ng-click=\"vm.showBondAccountsModal()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-handshake\"></i>\r" +
    "\n" +
    "                        New Bond Line\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    ng-show=\"vm.showEProducerAssignmentOptions\"\r" +
    "\n" +
    "                    class=\"divider\">\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"vm.showEProducerAssignmentOptions\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.showBrokerAssignmentModal()\">\r" +
    "\n" +
    "                        <i class=\"fa fa-tv\"></i>\r" +
    "\n" +
    "                        Assign to Broker\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"vm.showEProducerAssignmentOptions\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.showEProducerUserAcccessModal()\">\r" +
    "\n" +
    "                        <i class=\"fa fa-key\"></i>\r" +
    "\n" +
    "                        Grant EProducer Access\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"vm.customer.isActive\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.archiveCustomer()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        Archive Customer\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"!vm.customer.isActive\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.activateCustomer()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                        Activate Customer\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"vm.customer.isActive && !vm.customer.hasRelatedCustomers\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.viewCustomerRelationships()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-sitemap\"></i>\r" +
    "\n" +
    "                        Create Customer Relationship\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-show=\"vm.customer.hasRelatedCustomers\">\r" +
    "\n" +
    "                    <button ng-click=\"vm.viewCustomerRelationships()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-sitemap\"></i>\r" +
    "\n" +
    "                        View Customer Relationships\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body customer-detail\">\r" +
    "\n" +
    "        <section class=\"above\">\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div class=\"col-md-12\">\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"alert alert-danger\"\r" +
    "\n" +
    "                        ng-show=\"vm.showCustomerArchivedAlert\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <b>Archived Customer</b>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        This customer has been archived and will be hidden in customer lists. \r" +
    "\n" +
    "                        You will find the activate button under the actions menu.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h3 \r" +
    "\n" +
    "                        id=\"customer-detail-customer-name\"\r" +
    "\n" +
    "                        class=\"no-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{vm.customer.name}}\r" +
    "\n" +
    "                    </h3>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-show=\"vm.customer.customerType=='Individual'\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{vm.customer.people[0] | phone}} / {{vm.customer.people[0].email}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <span ng-bind-html=\"vm.customer.people[0] | physicalAddress:'two-line'\"></span>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-show=\"vm.customer.customerType=='Company'\" >\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{vm.customer.company | phone}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <span ng-bind-html=\"vm.customer.company | physicalAddress:'two-line'\"></span>\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <tag-container customer-id=\"vm.customerId\"></tag-container>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <broker-card \r" +
    "\n" +
    "                    ng-show=\"vm.hasBrokerRelationship\"\r" +
    "\n" +
    "                    class=\"block left-margin-big\"\r" +
    "\n" +
    "                    broker=\"vm.customer.eProducerAccount\"\r" +
    "\n" +
    "                    on-remove-click=\"vm.removeBroker()\">\r" +
    "\n" +
    "                </broker-card>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <eproducer-user-access-card\r" +
    "\n" +
    "                    class=\" left-margin-big\"\r" +
    "\n" +
    "                    ng-show=\"vm.hasEProducerUser\"\r" +
    "\n" +
    "                    eproducer-user=\"vm.customer.eProducerAccount.eProducerUsers[0]\"\r" +
    "\n" +
    "                    on-remove-click=\"vm.removeApplicantEProducerAccount()\">\r" +
    "\n" +
    "                </eproducer-user-access-card>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <related-customer-card\r" +
    "\n" +
    "                    class=\"block left-margin-big\"\r" +
    "\n" +
    "                    customer=\"vm.customer\"\r" +
    "\n" +
    "                    on-change=\"vm.reloadCustomer()\"\r" +
    "\n" +
    "                    ng-show=\"vm.customer.hasRelatedCustomers\">\r" +
    "\n" +
    "                </related-customer-card>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"left-margin-auto text-right\">\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        ng-show=\"vm.customer.lookupCode\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Lookup Code: {{vm.customer.lookupCode}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <balance-card\r" +
    "\n" +
    "                        ng-show=\"vm.customer\"\r" +
    "\n" +
    "                        class=\"top-margin block\"\r" +
    "\n" +
    "                        balance=\"vm.customer.balance\"\r" +
    "\n" +
    "                        unapplied-payment-total=\"vm.customer.unappliedPaymentTotal\"\r" +
    "\n" +
    "                        invoiced-balance=\"vm.customer.invoicedBalance\"\r" +
    "\n" +
    "                        not-invoiced-total=\"vm.customer.notInvoicedTotal\"\r" +
    "\n" +
    "                        debit-total=\"vm.customer.debitTotal\"\r" +
    "\n" +
    "                        credit-total=\"vm.customer.creditTotal\"\r" +
    "\n" +
    "                        epic-reconciliation-date-time=\"vm.customer.epicReconciliationDateTime\"\r" +
    "\n" +
    "                        balance-card-busy-indicator=\"vm.balanceCardBusyIndicator\">\r" +
    "\n" +
    "                    </balance-card>\r" +
    "\n" +
    "                </div>    \r" +
    "\n" +
    "            </div>                \r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset \r" +
    "\n" +
    "            active=\"vm.activeTab\" \r" +
    "\n" +
    "            ng-show=\"vm.showTabs\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Summary\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.customer.name\"\r" +
    "\n" +
    "                        aa-fg-class=\"fixed-width-md top-margin-big\" \r" +
    "\n" +
    "                        ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <textarea \r" +
    "\n" +
    "                        class=\"white\" \r" +
    "\n" +
    "                        aa-field-group=\"vm.customer.comments\"\r" +
    "\n" +
    "                        ng-change=\"vm.summaryFieldChanged()\"></textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <div class=\"col-sm-3\">\r" +
    "\n" +
    "                                <h5>Servicing Roles</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.customer.producerUserId\"\r" +
    "\n" +
    "                                    class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                                    is-producer=\"true\"\r" +
    "\n" +
    "                                    label=\"Producer\"\r" +
    "\n" +
    "                                    ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.customer.clientServiceExecutiveUserId\"\r" +
    "\n" +
    "                                    class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-executive=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Executive\"\r" +
    "\n" +
    "                                    ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.customer.clientServiceManagerUserId\"\r" +
    "\n" +
    "                                    class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-manager=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Manager\"\r" +
    "\n" +
    "                                    ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.customer.clientServiceAgentUserId\"\r" +
    "\n" +
    "                                    class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-agent=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Agent\"\r" +
    "\n" +
    "                                    ng-change=\"vm.summaryFieldChanged()\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-sm-3\">   \r" +
    "\n" +
    "                                <h5>Primary Contact</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div ng-show=\"vm.primaryContact\">\r" +
    "\n" +
    "                                    {{vm.primaryContact.prefix}} \r" +
    "\n" +
    "                                    {{vm.primaryContact.firstName}} \r" +
    "\n" +
    "                                    {{vm.primaryContact.middleName}} \r" +
    "\n" +
    "                                    {{vm.primaryContact.lastName}} \r" +
    "\n" +
    "                                    {{vm.primaryContact.suffix}}\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    {{vm.primaryContact.mailAddress}} {{vm.primaryContact.mailSuiteAptNumber}}\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    {{vm.primaryContact.mailCity}}\r" +
    "\n" +
    "                                    <span ng-show=\"vm.primaryContact.mailState\">\r" +
    "\n" +
    "                                        , {{vm.primaryContact.mailState}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                    <span ng-show=\"vm.primaryContact.mailZip\">\r" +
    "\n" +
    "                                        , {{vm.primaryContact.mailZip}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    <span ng-show=\"vm.primaryContact.cellPhone || vm.primaryContact.homePhone\">\r" +
    "\n" +
    "                                        Phone: \r" +
    "\n" +
    "                                        {{vm.primaryContact.cellPhone}}\r" +
    "\n" +
    "                                        <span ng-show=\"vm.primaryContact.cellPhone && vm.primaryContact.homePhone\">|</span>\r" +
    "\n" +
    "                                        {{vm.primaryContact.homePhone}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"!vm.primaryContact.cellPhone && !vm.primaryContact.homePhone\">\r" +
    "\n" +
    "                                        <i>Phone: Unknown</i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <span ng-show=\"vm.primaryContact.email\">\r" +
    "\n" +
    "                                        {{vm.primaryContact.email}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"!vm.primaryContact.email\">\r" +
    "\n" +
    "                                        <i>Email: Unknown</i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div ng-show=\"!vm.primaryContact\">\r" +
    "\n" +
    "                                    <i>No primary contact</i>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"col-sm-3\">\r" +
    "\n" +
    "                                <h5>Billing Contact</h5>\r" +
    "\n" +
    "                                                                \r" +
    "\n" +
    "                                <div ng-show=\"vm.billingContact\">\r" +
    "\n" +
    "                                    {{vm.billingContact.prefix}} \r" +
    "\n" +
    "                                    {{vm.billingContact.firstName}} \r" +
    "\n" +
    "                                    {{vm.billingContact.middleName}} \r" +
    "\n" +
    "                                    {{vm.billingContact.lastName}} \r" +
    "\n" +
    "                                    {{vm.billingContact.suffix}}\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    {{vm.billingContact.mailAddress}} {{vm.billingContact.mailSuiteAptNumber}}\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "                                    {{vm.billingContact.mailCity}}\r" +
    "\n" +
    "                                    <span ng-show=\"vm.billingContact.mailState\">, {{vm.billingContact.mailState}}</span>\r" +
    "\n" +
    "                                    <span ng-show=\"vm.billingContact.mailZip\">, {{vm.billingContact.mailZip}}</span>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"vm.billingContact.cellPhone || vm.billingContact.homePhone\">\r" +
    "\n" +
    "                                        Phone: \r" +
    "\n" +
    "                                        {{vm.billingContact.cellPhone}}\r" +
    "\n" +
    "                                        <span ng-show=\"vm.billingContact.cellPhone && vm.billingContact.homePhone\">|</span>\r" +
    "\n" +
    "                                        {{vm.billingContact.homePhone}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"!vm.billingContact.cellPhone && !vm.billingContact.homePhone\">\r" +
    "\n" +
    "                                        <i>Phone: Unknown</i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"vm.billingContact.email\">\r" +
    "\n" +
    "                                        {{vm.billingContact.email}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <span ng-show=\"!vm.billingContact.email\">\r" +
    "\n" +
    "                                        <i>Email: Unknown</i>\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div ng-show=\"!vm.billingContact\">\r" +
    "\n" +
    "                                    <i>No billing contact</i>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-show=\"vm.hasBrokerRelationship\" \r" +
    "\n" +
    "                                class=\"col-sm-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <h5>Broker Contact</h5>\r" +
    "\n" +
    "                                {{vm.customer.eProducerAccount.name}}\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                {{vm.customer.eProducerAccount.mailAddress}} {{vm.customer.eProducerAccount.mailSuiteAptNumber}}\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                {{vm.customer.eProducerAccount.mailCity}}, {{vm.customer.eProducerAccount.mailState}}, {{vm.customer.eProducerAccount.mailZip}}\r" +
    "\n" +
    "                                <br/>\r" +
    "\n" +
    "                                {{vm.customer.eProducerAccount.phone}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Contacts\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <contact-list\r" +
    "\n" +
    "                        customer-id=\"vm.customerId\"\r" +
    "\n" +
    "                        contacts=\"vm.customer.people\" \r" +
    "\n" +
    "                        ng-if=\"vm.customer\" >\r" +
    "\n" +
    "                    </contact-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Company\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-form=\"companyForm\"\r" +
    "\n" +
    "                        ng-if=\"vm.customer.company\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                            <div class=\"fill\">\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"mini charcoal\"\r" +
    "\n" +
    "                                    ng-click=\"vm.deleteCompany()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button\r" +
    "\n" +
    "                                class=\"mini\"\r" +
    "\n" +
    "                                aa-submit-form=\"vm.saveCompany()\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                Save\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <company company=\"vm.customer.company\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div ng-show=\"vm.customer.customerType === 'Individual'\">\r" +
    "\n" +
    "                        <div class=\"text-right\">\r" +
    "\n" +
    "                            <button\r" +
    "\n" +
    "                                class=\"mini\"\r" +
    "\n" +
    "                                ng-click=\"vm.addCompany()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                                Add Company\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <empty-state message=\"This is customer is classified as an Individual. Adding company information will change the customer record to be a company. \" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                select=\"vm.applicationTabSelected()\"\r" +
    "\n" +
    "                heading=\"Applications\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.applicationBusyIndicator\"></div>\r" +
    "\n" +
    "                   \r" +
    "\n" +
    "                <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.applicationsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.applicationsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search Applications\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search by principal or bond type\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.applicationsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadApplications()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <application-list-filter-dropdown \r" +
    "\n" +
    "                        class=\"left-margin flex\"\r" +
    "\n" +
    "                        table=\"vm.applicationsTable\">\r" +
    "\n" +
    "                    </application-list-filter-dropdown>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                   \r" +
    "\n" +
    "                <application-list table=\"vm.applicationsTable\"></application-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                select=\"vm.attachmentTabSelected()\"\r" +
    "\n" +
    "                heading=\"Attachments\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.attachmentsBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.attachmentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.attachmentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search Attachments\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search by filename or bond number\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.attachmentsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadAttachments()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <attachment-list-filter-dropdown \r" +
    "\n" +
    "                        class=\"left-margin flex\"\r" +
    "\n" +
    "                        table=\"vm.attachmentsTable\">\r" +
    "\n" +
    "                    </attachment-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <show-activity-modal-button \r" +
    "\n" +
    "                        customer-id=\"vm.customerId\"\r" +
    "\n" +
    "                        class=\"left-margin v-center\"\r" +
    "\n" +
    "                        button-class=\"mini\"\r" +
    "\n" +
    "                        on-submit=\"vm.loadAttachments()\"\r" +
    "\n" +
    "                        default-options=\"attachment\">\r" +
    "\n" +
    "                    </show-activity-modal-button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <attachment-list\r" +
    "\n" +
    "                    table=\"vm.attachmentsTable\"\r" +
    "\n" +
    "                    on-attachment-modified=\"vm.loadAttachments()\" />\r" +
    "\n" +
    "                </attachment-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                select=\"vm.bondTabSelected()\"\r" +
    "\n" +
    "                heading=\"Bonds\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.bondsBusyIndicator\"></div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search bonds\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search by bond number, principal, bond type or writing company\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-fg-class=\"v-center left-margin right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.showCancelledBondAccounts\"\r" +
    "\n" +
    "                        aa-label=\"Show Cancelled Bond Lines\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label-class=\"small\" \r" +
    "\n" +
    "                        ng-change=\"vm.showCancelledBondAccountsChanged()\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.bondsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadBonds()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <bond-list-filter-dropdown \r" +
    "\n" +
    "                        class=\"left-margin flex\"\r" +
    "\n" +
    "                        table=\"vm.bondsTable\">\r" +
    "\n" +
    "                    </bond-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                        uib-dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"left-padding\"\r" +
    "\n" +
    "                            uib-dropdown-toggle>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.exportBonds()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    Export List\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showBulkDownloadOptionsModal()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    Download Document Packets\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    ng-disabled=\"!vm.canAssignPrincipal\"\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showAssignPrincipalModal()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    Assign Principal\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showAssignToAccountModal()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    Assign to Bond Line\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showRenewalModal()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    Quick Renew\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                        </ul>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section\r" +
    "\n" +
    "                    ng-show=\"vm.showCancelledBondAccounts || bondAccount.status != 'Cancelled'\"\r" +
    "\n" +
    "                    class=\"flex bottom-margin no-top-margin\" \r" +
    "\n" +
    "                    ng-repeat=\"bondAccount in vm.bondAccountListItems\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <img \r" +
    "\n" +
    "                        class=\"v-center right-margin height-25\"\r" +
    "\n" +
    "                        ng-src=\"{{vm.carrierLogoUrls[bondAccount.carrierSystemAccountId]}}\" \r" +
    "\n" +
    "                        tooltip=\"{{bondAccount.carrierName}}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center\"\r" +
    "\n" +
    "                        ng-click=\"vm.editBondAccount(bondAccount)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{bondAccount.carrierName}}\r" +
    "\n" +
    "                        <span ng-show=\"bondAccount.carrierAccountNumber\"> #{{bondAccount.carrierAccountNumber}}</span>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        class=\"v-center\"\r" +
    "\n" +
    "                        ng-class=\"{\r" +
    "\n" +
    "                            'red' : bondAccount.status == 'Cancelled' || bondAccount.status == 'Suspended',\r" +
    "\n" +
    "                            'orange' : bondAccount.status == 'Pending'\r" +
    "\n" +
    "                        }\"\r" +
    "\n" +
    "                        ng-show=\"bondAccount.status != 'Active'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        &nbsp;\r" +
    "\n" +
    "                        -\r" +
    "\n" +
    "                        {{bondAccount.status}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"left-margin v-center small\">\r" +
    "\n" +
    "                        <span>\r" +
    "\n" +
    "                            Limits: \r" +
    "\n" +
    "                                <span ng-show=\"bondAccount.singleBondLimit\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    {{bondAccount.singleBondLimit | currency:'$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <i ng-show=\"!bondAccount.singleBondLimit\">Unknown</i>\r" +
    "\n" +
    "                                / \r" +
    "\n" +
    "                                <span ng-show=\"bondAccount.aggregateBondLimit\">\r" +
    "\n" +
    "                                    {{bondAccount.aggregateBondLimit | currency:'$'}}\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                <i ng-show=\"!bondAccount.aggregateBondLimit\">Unknown</i>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"left-margin\">\r" +
    "\n" +
    "                            <span ng-show=\"bondAccount.effectiveDate\">\r" +
    "\n" +
    "                                {{bondAccount.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <i ng-show=\"!bondAccount.effectiveDate\">Unknown</i>\r" +
    "\n" +
    "                            -\r" +
    "\n" +
    "                            <span ng-show=\"bondAccount.expirationDate\">\r" +
    "\n" +
    "                                {{bondAccount.expirationDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <i ng-show=\"!bondAccount.expirationDate\">Unknown</i>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ng-click=\"vm.searchBondsByBondLine(bondAccount)\"\r" +
    "\n" +
    "                            class=\"left-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Search Bonds\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <span>\r" +
    "\n" +
    "                                Current Aggregate: {{bondAccount.totalBondAmount | currency:'$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span class=\"left-margin\">\r" +
    "\n" +
    "                                Rate per Thousand: {{bondAccount.ratePerThousand | currency: '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <bond-list \r" +
    "\n" +
    "                    show-name-on-bond-column=\"true\"\r" +
    "\n" +
    "                    on-bond-account-updated=\"vm.bondAccountUpdated()\"\r" +
    "\n" +
    "                    table=\"vm.bondsTable\">\r" +
    "\n" +
    "                </bond-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                id=\"customer-detail-invoice-tab-header\"\r" +
    "\n" +
    "                heading=\"Invoices\" \r" +
    "\n" +
    "                select=\"vm.invoicesTabSelected()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.invoicesBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.invoiceTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.invoiceTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search invoices\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search by bond number or description\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.invoiceTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadInvoices()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <invoice-list-filter-dropdown\r" +
    "\n" +
    "                        class=\"left-margin flex\"\r" +
    "\n" +
    "                        table=\"vm.invoiceTable\">\r" +
    "\n" +
    "                    </invoice-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                        uib-dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"left-padding\"\r" +
    "\n" +
    "                            uib-dropdown-toggle>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.showVoidInvoicePrompt()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <i class=\"fa fa-slash\"></i>\r" +
    "\n" +
    "                                    Void\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button\r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.mergeInvoices()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                    <i class=\"fa fa-layer-group\"></i>\r" +
    "\n" +
    "                                    Merge Invoices\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                        </ul>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <invoice-list \r" +
    "\n" +
    "                    table=\"vm.invoiceTable\"\r" +
    "\n" +
    "                    customer-id=\"vm.customerId\">\r" +
    "\n" +
    "                </invoice-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                id=\"customer-detail-billing-entries-tab-header\"\r" +
    "\n" +
    "                heading=\"Billing Entries\" \r" +
    "\n" +
    "                select=\"vm.billingEntriesTabSelected()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.billingEntriesBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <billing-entries-list\r" +
    "\n" +
    "                    table=\"vm.billingEntriesTable\"\r" +
    "\n" +
    "                    on-credit-assigned=\"vm.creditAssigned()\"\r" +
    "\n" +
    "                    on-create-new-invoice=\"vm.createNewInvoice()\"\r" +
    "\n" +
    "                    on-download-excel=\"vm.exportBillingEntries()\"\r" +
    "\n" +
    "                    class=\"block top-margin\">\r" +
    "\n" +
    "                </billing-entries-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab \r" +
    "\n" +
    "                heading=\"Payments\" \r" +
    "\n" +
    "                select=\"vm.paymentsTabSelected()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div busy-indicator=\"vm.paymentsBusyIndicator\"></div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.paymentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.paymentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search payments\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search comments or processor id\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.paymentsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadPayments()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <payment-list-filter-dropdown\r" +
    "\n" +
    "                        class=\"left-margin v-center\"\r" +
    "\n" +
    "                        table=\"vm.paymentsTable\">\r" +
    "\n" +
    "                    </payment-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                        uib-dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"left-padding\"\r" +
    "\n" +
    "                            uib-dropdown-toggle>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                            <li>\r" +
    "\n" +
    "                                <button \r" +
    "\n" +
    "                                    class=\"small\"\r" +
    "\n" +
    "                                    ng-click=\"vm.newPayment()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                                    New Payment\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                        </ul>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <payment-list \r" +
    "\n" +
    "                    table=\"vm.paymentsTable\"\r" +
    "\n" +
    "                    on-payment-reassigned=\"vm.paymentReassigned()\">\r" +
    "\n" +
    "                </payment-list>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab\r" +
    "\n" +
    "                heading=\"Activities\"\r" +
    "\n" +
    "                select=\"vm.activitiesSelected()\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div busy-indicator=\"vm.activityBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-form=\"activityListForm\"\r" +
    "\n" +
    "                    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                        aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                        ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                        aa-label=\"Search Activities\"\r" +
    "\n" +
    "                        uib-tooltip=\"Search by bond number, description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <record-counter\r" +
    "\n" +
    "                        class=\"v-center small\"\r" +
    "\n" +
    "                        record-count=\"vm.activitiesTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        class=\"v-center left-margin small\"\r" +
    "\n" +
    "                        ng-click=\"vm.loadActivities()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <activity-list-filter-dropdown\r" +
    "\n" +
    "                        class=\"left-margin flex\"\r" +
    "\n" +
    "                        table=\"vm.activitiesTable\">\r" +
    "\n" +
    "                    </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <show-activity-modal-button \r" +
    "\n" +
    "                        customer-id=\"vm.customerId\"\r" +
    "\n" +
    "                        class=\"left-margin v-center\"\r" +
    "\n" +
    "                        button-class=\"mini\"\r" +
    "\n" +
    "                        on-submit=\"vm.loadActivities()\">\r" +
    "\n" +
    "                    </show-activity-modal-button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <activity-list \r" +
    "\n" +
    "                    table=\"vm.activitiesTable\"\r" +
    "\n" +
    "                    customer-id=\"vm.customerId\"\r" +
    "\n" +
    "                    on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "                </activity-list>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/customers/customerGrid.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        Customers\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body fill-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"customerListSearchForm\"\r" +
    "\n" +
    "        class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "            aa-label=\"Search Customers\"\r" +
    "\n" +
    "            uib-tooltip=\"Search by name or lookup code\"\r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Refresh\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            id=\"customer-list-refresh-button\"\r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <customer-list-filter-dropdown\r" +
    "\n" +
    "            class=\"left-margin flex\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </customer-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                uib-tooltip=\"Actions\"\r" +
    "\n" +
    "                tooltip-placement=\"top-right\"\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                id=\"customer-grid-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        id=\"customer-rid-new-customer-button\"\r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.showNewCustomerModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                        New Customer\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportCustomerList()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                        Download Excel\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <table \r" +
    "\n" +
    "                class=\"bottom-margin group-by-tbody\"\r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr \r" +
    "\n" +
    "                        class=\"sortable\"\r" +
    "\n" +
    "                        ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <th \r" +
    "\n" +
    "                            class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                            ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <table-sort-button\r" +
    "\n" +
    "                                text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                            </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody ng-repeat=\"customer in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\" \r" +
    "\n" +
    "                            ng-switch=\"column.name\"\r" +
    "\n" +
    "                            ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                            ng-class=\"column.cellClass\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-switch-when=\"Id\">{{customer.id}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"LookupCode\">{{customer.lookupCode}}</span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                ng-switch-when=\"Name\"\r" +
    "\n" +
    "                                ui-sref=\"main.customerDetail({ customerId: customer.id, sectionView: '' })\"> \r" +
    "\n" +
    "                                {{customer.name}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <span \r" +
    "\n" +
    "                                ng-switch-when=\"Address\"\r" +
    "\n" +
    "                                ng-bind-html=\"customer | physicalAddress : 'two-line'\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <a\r" +
    "\n" +
    "                                ng-switch-when=\"BrokerName\"\r" +
    "\n" +
    "                                ui-sref=\"main.eProducerAccountDetail({ id: customer.eProducerAccountId })\"\r" +
    "\n" +
    "                                ng-show=\"customer.brokerName\"\r" +
    "\n" +
    "                                target=\"_blank\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span>{{customer.brokerName}}</span>\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <a\r" +
    "\n" +
    "                                ng-switch-when=\"BrokerLookupCode\"\r" +
    "\n" +
    "                                ui-sref=\"main.eProducerAccountDetail({ id: customer.eProducerAccountId })\"\r" +
    "\n" +
    "                                ng-show=\"customer.brokerLookupCode\"\r" +
    "\n" +
    "                                target=\"_blank\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span>{{customer.brokerLookupCode}}</span>\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-switch-when=\"CreatedDateTime\">{{customer.createdDateTime | localDateTime}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Producer\">{{customer.producer}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceExecutive\">{{customer.clientServiceExecutive}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceManager\">{{customer.clientServiceManager}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceAssociate\">{{customer.clientServiceAssociate}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"Balance\">{{customer.balance | currency : '$'}}</span>   \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-switch-when=\"UnappliedPaymentTotal\">{{customer.unappliedPaymentTotal | currency : '$'}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"DebitTotal\">{{customer.debitTotal | currency : '$'}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"CreditTotal\">{{customer.creditTotal | currency : '$'}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"NotInvoicedTotal\">{{customer.notInvoicedTotal | currency : '$'}}</span>                    \r" +
    "\n" +
    "                            <span ng-switch-when=\"InvoicedBalance\">{{customer.invoicedBalance | currency : '$'}}</span>   \r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"No customers were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        class=\"top-margin\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/customerSelection/customerSelection.html',
    "<div ng-form=\"vm.customerLookup\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"text-center\">\r" +
    "\n" +
    "            <h3>New or existing Customer?</h3>\r" +
    "\n" +
    "            <button class=\"large charcoal\" \r" +
    "\n" +
    "                ui-sref=\"main.customerSelection({ appType: appType, customerType: 'new' })\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                New Customer\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button class=\"large\" \r" +
    "\n" +
    "                ui-sref=\"main.customerSelection({ appType: appType, customerType: 'existing' })\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Existing Customer\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"newCustomer\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-user-plus\"></i> New Customer\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"newCustomerType\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"radio\" \r" +
    "\n" +
    "            options=\"vm.customerTypes\"/>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div ng-show=\"newCustomerType == 'Company'\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.newCompanyCustomer.companies[0].name\" \r" +
    "\n" +
    "                ng-required=\"newCustomerType=='Company'\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer(true)\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"vm.newCustomerType == 'Individual'\" \r" +
    "\n" +
    "            class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.newIndividualCustomer.people[0].firstName\" \r" +
    "\n" +
    "                ng-required=\"vm.newCustomerType=='Individual'\"\r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer(true)\" \r" +
    "\n" +
    "                aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.newIndividualCustomer.people[0].lastName\" \r" +
    "\n" +
    "                ng-required=\"vm.newCustomerType=='Individual'\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer(true)\"\r" +
    "\n" +
    "                aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.newIndividualCustomer.people[0].cellPhone\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                ng-required=\"vm.newCustomerType=='Individual'\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer(true)\" \r" +
    "\n" +
    "                aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.newIndividualCustomer.people[0].email\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                ng-required=\"vm.newCustomerType=='Individual'\"\r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer(true)\"\r" +
    "\n" +
    "                aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-submit-form=\"addNewCustomer()\" \r" +
    "\n" +
    "        type=\"submit\" \r" +
    "\n" +
    "        value=\"Next\" \r" +
    "\n" +
    "        ng-show=\"newCustomer\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"existingCustomer\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-search\"></i> \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Customer Lookup\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <h4 class=\"col-md-12\">By Company:</h4>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"lookupCustomer.companyName\" \r" +
    "\n" +
    "                ng-change=\"lookupCustomer()\"\r" +
    "\n" +
    "                aa-fg-class=\"col-md-12\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"col-md-12\">By Individual:</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.lookupCustomer.firstName\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer()\" \r" +
    "\n" +
    "                type=\"text\" aa-fg-class=\"col-sm-3\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.lookupCustomer.lastName\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer()\" \r" +
    "\n" +
    "                type=\"text\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.lookupCustomer.email\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer()\" \r" +
    "\n" +
    "                type=\"text\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"vm.email\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.lookupCustomer.cellPhone\" \r" +
    "\n" +
    "                ng-change=\"vm.lookupCustomer()\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"vm.phone\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"matchingCustomers.length > 0\">\r" +
    "\n" +
    "        <div busy-indicator=\"{promise:lookupPromise}\"></div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-list\"></i> \r" +
    "\n" +
    "            Matching Customers\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <p class=\"text-center\">These customers match the information you provided</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table>\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>Customer Name</th>\r" +
    "\n" +
    "                <th>Address</th>\r" +
    "\n" +
    "                <th>Phone Number</th>\r" +
    "\n" +
    "                <th>Email Address</th>\r" +
    "\n" +
    "                <th>&nbsp;</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"customer in matchingCustomers\">\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        class=\"fa\" \r" +
    "\n" +
    "                        ng-class=\"{'fa-building': customer.customerType == 'Company', 'fa-user': customer.customerType == 'Individual'}\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{customer.name}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{ customer.people[0].mailAddress }} {{ customer.people[0].mailSuiteAptNumber }}<br/>\r" +
    "\n" +
    "                    {{ customer.people[0].mailCity }}, {{ customer.people[0].mailState }} {{ customer.people[0].mailZip }}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>{{ customer.people[0].cellPhone }}</td>\r" +
    "\n" +
    "                <td>{{ customer.people[0].email }}</td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    <button ng-click=\"selectCustomer(customer.id)\">Select</button>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <tbody \r" +
    "\n" +
    "                ng-show=\"totalRecords == 0\" \r" +
    "\n" +
    "                class=\"ng-hide\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tr class=\"noRecords\">\r" +
    "\n" +
    "                    <td colspan=\"5\">\r" +
    "\n" +
    "                        <span>No Records Found</span>\r" +
    "\n" +
    "                        <p>Either no records exist or your search resulted in no records</p>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/epicBillingReport/epicBillingReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>Epic Billing Report</header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body width-100p fill-page flex column\">\r" +
    "\n" +
    "    <section \r" +
    "\n" +
    "        class=\"above flex top-margin\"\r" +
    "\n" +
    "        ng-form=\"reportForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"fill flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"v-center right-margin-big flex\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.startDate\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                    aa-fg-class=\"right-margin v-center\"\r" +
    "\n" +
    "                    required \r" +
    "\n" +
    "                    focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.endDate\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "                    aa-fg-class=\" v-center\"\r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"left-margin-big right-margin-big v-center\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.onlyNotEnteredInEpic\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-fg-class=\"small\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"right-margin-big v-center\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.onlyUnsentItems\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-fg-class=\"small\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"bottom-margin-thin small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.runReport()\" >\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Run Report\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "        class=\"dropdown actions\" \r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            uib-dropdown-toggle\r" +
    "\n" +
    "            class=\"small white left\"\r" +
    "\n" +
    "            ng-disabled=\"!vm.reportEntries.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"visible-xs\">\r" +
    "\n" +
    "                <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"hidden-xs\">\r" +
    "\n" +
    "                Actions <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.selectItemsReadyToSend()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Select items ready to Send\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.showSendToBillingTeam()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Send to Billing Team\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.toggleSentToBillingTeam()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    Toggle Sent to Billing Team Status\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.downloadReport()\" >\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    Download Selected\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "            class=\"bottom-padding-big\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.reportEntries.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table class=\"small group-by-tbody\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            type=\"checkbox\"\r" +
    "\n" +
    "                            ng-change=\"vm.allSelectedChanged()\"\r" +
    "\n" +
    "                            ng-model=\"vm.allSelected\" />\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Incomplete Record</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th     \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>A3 Billing Entry #</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th     \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Client Name</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Client Code</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Bill To</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <b>Broker Code (BREC)</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Broker Commission $</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <b>Gross/Net</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\"><b>BPAY Code</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\"><b>Broker Commission (BPAY)</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\"><b>Bond #</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <b>EPIC LOB Code</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <b>Transaction Code</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Correction</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Description</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Policy Effective Date</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Policy Expiration Date</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Transaction Effective Date</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Transaction Processed Date</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Gross Amount</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Billed Agency Commission $</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Carrier Code (ICO)</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "               \r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Premium Payable Code (PPE)</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <th class=\"fit-content text-center\">\r" +
    "\n" +
    "                        <b>\r" +
    "\n" +
    "                            Entered in Epic\r" +
    "\n" +
    "                            <i \r" +
    "\n" +
    "                                class=\"left-margin-thin fa fa-info-circle\"\r" +
    "\n" +
    "                                tooltip-placement=\"left\"\r" +
    "\n" +
    "                                uib-tooltip-html=\"vm.buildIncludeInEpicHelpToolTip()\" >\r" +
    "\n" +
    "                            </i>\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <b>Notes</b>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"reportEntry in vm.reportEntries\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            type=\"checkbox\"\r" +
    "\n" +
    "                            ng-change=\"vm.selectionChanged()\"\r" +
    "\n" +
    "                            ng-model=\"reportEntry.$selected\" />\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i\r" +
    "\n" +
    "                            class=\"fa fa-check\"\r" +
    "\n" +
    "                            ng-class=\"{'green': reportEntry.includedInEpicBillingReport, 'disabled' : !reportEntry.includedInEpicBillingReport}\"\r" +
    "\n" +
    "                            uib-tooltip-html=\"vm.buildIncludedInEpicBillingReportTooltip(reportEntry)\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i \r" +
    "\n" +
    "                            class=\"fa fa-exclamation-triangle\"\r" +
    "\n" +
    "                            ng-class=\"{'red': vm.isIncomplete(reportEntry), 'disabled': !vm.isIncomplete(reportEntry)}\"\r" +
    "\n" +
    "                            uib-tooltip-html=\"vm.buildIncompleteTooltipTemplate(reportEntry)\" >\r" +
    "\n" +
    "                        </i>    \r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.id}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"nowrap\">\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: reportEntry.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{reportEntry.customerName}}\r" +
    "\n" +
    "                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center nowrap\">\r" +
    "\n" +
    "                        {{reportEntry.customerLookupCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.billTo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"text-center nowrap\">\r" +
    "\n" +
    "                        {{reportEntry.bRecCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.bRecBrokerCommission | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.netOrGross}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center nowrap\">\r" +
    "\n" +
    "                        {{reportEntry.bPayCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.bPayBrokerCommission | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <a show-bond-detail-modal=\"reportEntry.bondId\">\r" +
    "\n" +
    "                            {{reportEntry.bondNumber}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>	\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.epicLineOfBusinessCode}}\r" +
    "\n" +
    "                    </td>				\r" +
    "\n" +
    "                    <td class=\"text-center\"> \r" +
    "\n" +
    "                        {{reportEntry.code}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        <span ng-show=\"!reportEntry.isOriginalEntry\"> X </span>\r" +
    "\n" +
    "                    </td>			\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.description;}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>		\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.expirationDate | dateOnly }}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.transactionEffectiveDate | dateOnly }}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.createdDateTime | dateOnly }}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.originalAmount | currency:'$'}}\r" +
    "\n" +
    "                    </td>			\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.commission | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.epicIssuingCompanyCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        {{reportEntry.epicPremiumPayableCode}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"text-center\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            type=\"checkbox\"\r" +
    "\n" +
    "                            ng-model=\"reportEntry.enteredInEpic\" \r" +
    "\n" +
    "                            ng-change=\"vm.enteredInEpicChanged(reportEntry, $index)\" />\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"nowrap\">\r" +
    "\n" +
    "                        <span ng-repeat=\"bondChange in reportEntry.bondChanges\">\r" +
    "\n" +
    "                            {{bondChange.description}}\r" +
    "\n" +
    "                            <br ng-if=\"!$last\" > \r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </tr>           \r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>    \r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <empty-state     \r" +
    "\n" +
    "            ng-show=\"!vm.reportEntries.length && vm.reportHasRan\"\r" +
    "\n" +
    "            message=\"There are no billing report entries for this date range\" />\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/epicIntegrationLogs/epicIntegrationLogs.html',
    "<div busy-indicator=\"{ promise: vm.loadingPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section \r" +
    "\n" +
    "    class=\"above flex top-margin\"\r" +
    "\n" +
    "    ng-form=\"reportForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-field-group=\"vm.startDate\"\r" +
    "\n" +
    "        aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "        aa-fg-class=\"top-margin-thin fixed-width-md right-margin v-center\"\r" +
    "\n" +
    "        required \r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-field-group=\"vm.endDate\"\r" +
    "\n" +
    "        aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "        aa-fg-class=\"top-margin-thin fixed-width-md right-margin v-center\"\r" +
    "\n" +
    "        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"v-center mini\"\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        aa-submit-form=\"vm.runReport()\" >\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        Run Report\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section ng-show=\"vm.reportHasRan\">\r" +
    "\n" +
    "    <table\r" +
    "\n" +
    "        ng-show=\"vm.logEntries.length\"\r" +
    "\n" +
    "        class=\"small group-by-tbody\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th></th>\r" +
    "\n" +
    "                <th><b>Date/Time</b></th>\r" +
    "\n" +
    "                <th class=\"text-center\"><b>Result</b></th>\r" +
    "\n" +
    "                <th><b>Integration Task</b></th>\r" +
    "\n" +
    "                <th><b>Message</b></th>\r" +
    "\n" +
    "                <th><b>Related Record</b></th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "        <tbody ng-repeat=\"logEntry in vm.logEntries\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <td ng-click=\"logEntry.showDetail = !logEntry.showDetail\">\r" +
    "\n" +
    "                    <i ng-class=\"{'fa fa-caret-right': !logEntry.showDetail, 'fa fa-caret-down': logEntry.showDetail}\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{logEntry.createdDateTime | date:'MM/dd/yyyy hh:mm a'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-center\">\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-show=\"logEntry.succeeded == true\" \r" +
    "\n" +
    "                        class=\"green\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        Success\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        ng-show=\"logEntry.succeeded == false\"\r" +
    "\n" +
    "                        class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Error\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ClientInsertParent'\">Customer Export</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'NewBusinessInsertParent'\">Bond Export</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'EpicBillingReportSent'\">Billing Report</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ClientCompanyUpdateParent'\">Company Update</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ContactUpdateParent'\">Contact Update</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ContactInsertParent'\">Contact Insert</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ClientBrokerUpdateParent'\">Broker Update</span>\r" +
    "\n" +
    "                    <span ng-show=\"logEntry.actionType == 'ClientNameRolesUpdateParent'\">Name, Roles Update</span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{logEntry.message}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td class=\"text-right\">\r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        ng-show=\"\r" +
    "\n" +
    "                            logEntry.actionType == 'ClientInsertParent' ||\r" +
    "\n" +
    "                            logEntry.actionType == 'ContactInsertParent' ||\r" +
    "\n" +
    "                            logEntry.actionType == 'ContactUpdateParent' ||\r" +
    "\n" +
    "                            logEntry.actionType == 'ClientCompanyUpdateParent' ||\r" +
    "\n" +
    "                            logEntry.actionType == 'ClientBrokerUpdateParent' ||\r" +
    "\n" +
    "                            logEntry.actionType == 'ClientNameRolesUpdateParent'\r" +
    "\n" +
    "                        \" \r" +
    "\n" +
    "                        ui-sref=\"main.customerDetail({ customerId: logEntry.customerId })\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Customer Detail\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        ng-show=\"logEntry.actionType == 'NewBusinessInsertParent'\" \r" +
    "\n" +
    "                        show-bond-detail-modal=\"logEntry.bondId\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Bond Detail\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr ng-show=\"logEntry.showDetail\">\r" +
    "\n" +
    "                <td></td>\r" +
    "\n" +
    "                <td colspan=\"5\">\r" +
    "\n" +
    "                    <div ng-repeat=\"childLog in logEntry.children\">\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            class=\"green\" \r" +
    "\n" +
    "                            ng-show=\"childLog.succeeded\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <i class=\"fa fa-check\"></i>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            class=\"red\"\r" +
    "\n" +
    "                            ng-show=\"!childLog.succeeded\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{childLog.message}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state     \r" +
    "\n" +
    "        ng-show=\"!vm.logEntries.length\"\r" +
    "\n" +
    "        message=\"There are no integration log entries for this date range\" />\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/states/agentSpecific/eProducerBrokerDetail/eProducerAccountsDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"eProducerAccountDetailForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-title flex\">\r" +
    "\n" +
    "        <header class=\"fill\">\r" +
    "\n" +
    "            Broker\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"red\" \r" +
    "\n" +
    "                ng-show=\"vm.account.isLocked\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                - Account Is Locked\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"red\" \r" +
    "\n" +
    "                ng-show=\"!vm.account.isApproved\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                - Pending Approval\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.account.isApproved\"\r" +
    "\n" +
    "            aa-fg-class=\"v-center right-margin-big\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.account.isLocked\"\r" +
    "\n" +
    "            aa-fg-class=\"v-center\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body width-100p sticky-page flex column\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-x=\"true\"\r" +
    "\n" +
    "            class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "            wheel-propagation=\"true\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <section class=\"above\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill\">\r" +
    "\n" +
    "                        <h3 class=\"no-margin\">{{vm.account.name}}</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.account.eProducerAccountBrokerCategoryId\"\r" +
    "\n" +
    "                            aa-fg-class=\"fixed-width-md top-margin-big\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-label=\"Type:\"\r" +
    "\n" +
    "                            options=\"vm.brokerCategoryOptions\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <textarea\r" +
    "\n" +
    "                            aa-label=\"Notes\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.account.comments\"\r" +
    "\n" +
    "                            aa-fg-class=\"max-width-750 top-margin-big\"\r" +
    "\n" +
    "                            maxlength=\"1000\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <tag-container \r" +
    "\n" +
    "                            class=\"top-margin-big\"\r" +
    "\n" +
    "                            eproducer-account-id=\"vm.accountId\">\r" +
    "\n" +
    "                        </tag-container>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-label=\"Lookup Code\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.account.lookupCode\"\r" +
    "\n" +
    "                            aa-label-strategy=\"inline\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <balance-card\r" +
    "\n" +
    "                            ng-show=\"vm.account\"\r" +
    "\n" +
    "                            class=\"top-margin block\"\r" +
    "\n" +
    "                            balance=\"vm.account.balance\"\r" +
    "\n" +
    "                            unapplied-payment-total=\"vm.account.unappliedPaymentTotal\"\r" +
    "\n" +
    "                            invoiced-balance=\"vm.account.invoicedBalance\"\r" +
    "\n" +
    "                            not-invoiced-total=\"vm.account.notInvoicedTotal\"\r" +
    "\n" +
    "                            debit-total=\"vm.account.debitTotal\"\r" +
    "\n" +
    "                            credit-total=\"vm.account.creditTotal\"\r" +
    "\n" +
    "                            balance-card-busy-indicator=\"vm.balanceCardBusyIndicator\">\r" +
    "\n" +
    "                        </balance-card>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div> \r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tabset data-a3-id=\"eproducer-account-detail-tabset\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uib-tab heading=\"Broker Info\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section class=\"text-left\">\r" +
    "\n" +
    "                        <header>Company Information</header>\r" +
    "\n" +
    "                        <eproducer-broker-summary account=\"vm.account\"></eproducer-broker-summary>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>Servicing Roles</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <div class=\"fill right-margin\">\r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.account.producerUserId\"\r" +
    "\n" +
    "                                    class=\"top-margin-big\"\r" +
    "\n" +
    "                                    is-producer=\"true\"\r" +
    "\n" +
    "                                    label=\"Producer\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.account.clientServiceExecutiveUserId\"\r" +
    "\n" +
    "                                    class=\"top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-executive=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Executive\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <div class=\"fill left-margin\">\r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.account.clientServiceManagerUserId\"\r" +
    "\n" +
    "                                    class=\"top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-manager=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Manager\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>         \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <user-dropdown\r" +
    "\n" +
    "                                    ng-model=\"vm.account.clientServiceAssociateUserId\"\r" +
    "\n" +
    "                                    class=\"top-margin-big\"\r" +
    "\n" +
    "                                    is-client-service-agent=\"true\"\r" +
    "\n" +
    "                                    label=\"Client Service Agent\"\r" +
    "\n" +
    "                                    allow-unselected=\"true\"\r" +
    "\n" +
    "                                    unselected-text=\"Default\">\r" +
    "\n" +
    "                                </user-dropdown>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    heading=\"Contacts\"\r" +
    "\n" +
    "                    select=\"vm.contactsTabSelected()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <div busy-indicator=\"vm.contactsBusyIndicator\"></div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <contact-list\r" +
    "\n" +
    "                            eproducer-account-id=\"vm.accountId\"\r" +
    "\n" +
    "                            contacts=\"vm.contacts\" \r" +
    "\n" +
    "                            ng-if=\"vm.contacts\" \r" +
    "\n" +
    "                            hide-include-on-bonds=\"true\">\r" +
    "\n" +
    "                        </contact-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    select=\"vm.applicationTabSelected()\"\r" +
    "\n" +
    "                    heading=\"Applications\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                    <div busy-indicator=\"vm.applicationBusyIndicator\"></div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.applicationsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                            ng-change=\"vm.applicationsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                            aa-label=\"Search Applications\"\r" +
    "\n" +
    "                            uib-tooltip=\"Search by principal or bond type\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <record-counter\r" +
    "\n" +
    "                            class=\"v-center small\"\r" +
    "\n" +
    "                            record-count=\"vm.applicationsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                        </record-counter>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"v-center left-margin small\"\r" +
    "\n" +
    "                            ng-click=\"vm.loadApplications()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <application-list-filter-dropdown \r" +
    "\n" +
    "                            class=\"left-margin flex\"\r" +
    "\n" +
    "                            table=\"vm.applicationsTable\">\r" +
    "\n" +
    "                        </application-list-filter-dropdown>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <application-list table=\"vm.applicationsTable\"></application-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    select=\"vm.attachmentTabSelected()\"\r" +
    "\n" +
    "                    heading=\"Attachments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div busy-indicator=\"vm.attachmentsBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-form=\"attachmentsForm\"\r" +
    "\n" +
    "                        class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.attachmentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                            ng-change=\"vm.attachmentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                            aa-label=\"Search Attachments\"\r" +
    "\n" +
    "                            uib-tooltip=\"Search by filename or bond number\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <records-counter\r" +
    "\n" +
    "                            class=\"v-center small\"\r" +
    "\n" +
    "                            records-count=\"vm.attachmentsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                        </records-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"v-center left-margin small\"\r" +
    "\n" +
    "                            ng-click=\"vm.loadAttachments()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <attachment-list-filter-dropdown \r" +
    "\n" +
    "                            class=\"left-margin flex\"\r" +
    "\n" +
    "                            table=\"vm.attachmentsTable\">\r" +
    "\n" +
    "                        </attachment-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <show-activity-modal-button \r" +
    "\n" +
    "                            eproducer-account-id=\"vm.account.id\"\r" +
    "\n" +
    "                            class=\"left-margin v-center\"\r" +
    "\n" +
    "                            button-class=\"mini\"\r" +
    "\n" +
    "                            on-submit=\"vm.loadAttachments()\"\r" +
    "\n" +
    "                            default-options=\"attachment\">\r" +
    "\n" +
    "                        </show-activity-modal-button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <attachment-list\r" +
    "\n" +
    "                        table=\"vm.attachmentsTable\"\r" +
    "\n" +
    "                        on-attachment-modified=\"vm.loadAttachments()\" />\r" +
    "\n" +
    "                    </attachment-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    select=\"vm.bondTabSelected()\"\r" +
    "\n" +
    "                    heading=\"Bonds\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div busy-indicator=\"vm.bondsBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-form=\"bondListForm\">\r" +
    "\n" +
    "                        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                                aa-field-group=\"vm.bondsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                                ng-change=\"vm.bondsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                                aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                                aa-label=\"Search bonds\"\r" +
    "\n" +
    "                                uib-tooltip=\"Search by bond number, principal, bond type or writing company\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <record-counter\r" +
    "\n" +
    "                                class=\"v-center small\"\r" +
    "\n" +
    "                                record-count=\"vm.bondsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                            </record-counter>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"v-center left-margin small\"\r" +
    "\n" +
    "                                ng-click=\"vm.loadBonds()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <bond-list-filter-dropdown \r" +
    "\n" +
    "                                class=\"left-margin flex\"\r" +
    "\n" +
    "                                table=\"vm.bondsTable\">\r" +
    "\n" +
    "                            </bond-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                                uib-dropdown>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    class=\"left-padding\"\r" +
    "\n" +
    "                                    uib-dropdown-toggle>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                                    <li>\r" +
    "\n" +
    "                                        <button\r" +
    "\n" +
    "                                            class=\"small\"\r" +
    "\n" +
    "                                            ng-click=\"vm.exportBonds()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                            Export List\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </li>\r" +
    "\n" +
    "                                </ul>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <bond-list \r" +
    "\n" +
    "                            show-customer-name-column=\"true\"\r" +
    "\n" +
    "                            table=\"vm.bondsTable\">\r" +
    "\n" +
    "                        </bond-list>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    heading=\"Invoices\" \r" +
    "\n" +
    "                    select=\"vm.invoiceTabSelected()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div busy-indicator=\"vm.invoicesBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-form=\"invoiceSearchForm\"\r" +
    "\n" +
    "                        class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.invoiceTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                            ng-change=\"vm.invoiceTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                            aa-label=\"Search invoices\"\r" +
    "\n" +
    "                            uib-tooltip=\"Search by bond number or description\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <record-counter\r" +
    "\n" +
    "                            class=\"v-center small\"\r" +
    "\n" +
    "                            record-count=\"vm.invoiceTable.pager.totalRecordsCount\">\r" +
    "\n" +
    "                        </record-counter>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"v-center left-margin small\"\r" +
    "\n" +
    "                            ng-click=\"vm.loadInvoices()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <invoice-list-filter-dropdown\r" +
    "\n" +
    "                            class=\"left-margin flex\"\r" +
    "\n" +
    "                            table=\"vm.invoiceTable\">\r" +
    "\n" +
    "                        </invoice-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                            uib-dropdown>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"left-padding\"\r" +
    "\n" +
    "                                uib-dropdown-toggle>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        class=\"small\"\r" +
    "\n" +
    "                                        ng-click=\"vm.showVoidInvoicePrompt()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                        <i class=\"fa fa-slash\"></i>\r" +
    "\n" +
    "                                        Void\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        class=\"small\"\r" +
    "\n" +
    "                                        ng-click=\"vm.mergeInvoices()\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        <i class=\"fa fa-layer-group\"></i>\r" +
    "\n" +
    "                                        Merge Invoices\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button\r" +
    "\n" +
    "                                        class=\"small\"\r" +
    "\n" +
    "                                        ng-click=\"vm.createNewInvoice()\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                                        New Invoice\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                            </ul>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <invoice-list \r" +
    "\n" +
    "                        eproducer-account-id=\"vm.accountId\"\r" +
    "\n" +
    "                        table=\"vm.invoiceTable\">\r" +
    "\n" +
    "                    </invoice-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    id=\"broker-detail-billing-entries-tab-header\"\r" +
    "\n" +
    "                    heading=\"Billing Entries\" \r" +
    "\n" +
    "                    select=\"vm.billingEntriesTabSelected()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div busy-indicator=\"vm.billingEntriesBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <billing-entries-list\r" +
    "\n" +
    "                        table=\"vm.billingEntriesTable\"\r" +
    "\n" +
    "                        on-credit-assigned=\"vm.creditAssigned()\"\r" +
    "\n" +
    "                        on-create-new-invoice=\"vm.createNewInvoice()\"\r" +
    "\n" +
    "                        on-download-excel=\"vm.exportBillingEntries()\"\r" +
    "\n" +
    "                        class=\"block top-margin\">\r" +
    "\n" +
    "                    </billing-entries-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <uib-tab\r" +
    "\n" +
    "                    heading=\"Payments\"\r" +
    "\n" +
    "                    select=\"vm.paymentsTabSelected()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div busy-indicator=\"vm.paymentsBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.paymentsTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                            ng-change=\"vm.paymentsTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                            aa-label=\"Search payments\"\r" +
    "\n" +
    "                            uib-tooltip=\"Search comments or processor id\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <record-counter\r" +
    "\n" +
    "                            class=\"v-center small\"\r" +
    "\n" +
    "                            record-count=\"vm.paymentsTable.pager.totalRecordCount\">\r" +
    "\n" +
    "                        </record-counter>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"v-center left-margin small\"\r" +
    "\n" +
    "                            ng-click=\"vm.loadPayments()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <payment-list-filter-dropdown\r" +
    "\n" +
    "                            class=\"left-margin v-center\"\r" +
    "\n" +
    "                            table=\"vm.paymentsTable\">\r" +
    "\n" +
    "                        </payment-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "                            uib-dropdown>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                class=\"left-padding\"\r" +
    "\n" +
    "                                uib-dropdown-toggle>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        class=\"small\"\r" +
    "\n" +
    "                                        ng-click=\"vm.newPayment()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                                        New Payment\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                            </ul>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <payment-list \r" +
    "\n" +
    "                        table=\"vm.paymentsTable\"\r" +
    "\n" +
    "                        on-payment-reassigned=\"vm.paymentReassigned()\">\r" +
    "\n" +
    "                    </payment-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <uib-tab\r" +
    "\n" +
    "                    heading=\"Activities\"\r" +
    "\n" +
    "                    select=\"vm.activitiesSelected()\"> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div busy-indicator=\"vm.activitiesBusyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-form=\"activityListForm\"\r" +
    "\n" +
    "                        class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "                            aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "                            ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "                            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "                            aa-label=\"Search Activities\"\r" +
    "\n" +
    "                            uib-tooltip=\"Search by bond number, description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <record-counter\r" +
    "\n" +
    "                            class=\"v-center small\"\r" +
    "\n" +
    "                            record-count=\"vm.activitiesTable.pager.totalRecordsCount\">\r" +
    "\n" +
    "                        </record-counter>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            class=\"v-center left-margin small\"\r" +
    "\n" +
    "                            ng-click=\"vm.loadActivities()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <activity-list-filter-dropdown\r" +
    "\n" +
    "                            class=\"left-margin flex\"\r" +
    "\n" +
    "                            table=\"vm.activitiesTable\">\r" +
    "\n" +
    "                        </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <show-activity-modal-button \r" +
    "\n" +
    "                            class=\"left-margin v-center\"\r" +
    "\n" +
    "                            button-class=\"mini\"\r" +
    "\n" +
    "                            eproducer-account-id=\"vm.accountId\"\r" +
    "\n" +
    "                            on-submit=\"vm.loadActivities()\">\r" +
    "\n" +
    "                        </show-activity-modal-button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <activity-list \r" +
    "\n" +
    "                        table=\"vm.activitiesTable\"\r" +
    "\n" +
    "                        eproducer-account-id=\"vm.accountId\"\r" +
    "\n" +
    "                        on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "                    </activity-list>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <uib-tab \r" +
    "\n" +
    "                    heading=\"Settings\"\r" +
    "\n" +
    "                    class=\"left-margin-auto\"\r" +
    "\n" +
    "                    select=\"vm.loadEProducerSettings()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div busy-indicator=\"vm.settingsLoadingIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <ul class=\"vertical-tab-nav\">\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'Commission'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'Commission'\">\r" +
    "\n" +
    "                                    Commission\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'Logins'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'Logins'\">\r" +
    "\n" +
    "                                    Logins\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'Branding'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'Branding'\">\r" +
    "\n" +
    "                                    White-Label Branding\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'FavoriteBondTypes'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'FavoriteBondTypes'\">\r" +
    "\n" +
    "                                    Favorite Bond Types\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'Payments'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'Payments'\">\r" +
    "\n" +
    "                                    Payments\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                            <li ng-class=\"{active: vm.eProducerSettingsActivePane == 'CarrierVisibility'}\">\r" +
    "\n" +
    "                                <a ng-click=\"vm.eProducerSettingsActivePane = 'CarrierVisibility'\">\r" +
    "\n" +
    "                                    Carrier Visibility\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </li>\r" +
    "\n" +
    "                        </ul>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"fill\">\r" +
    "\n" +
    "                            <div ng-show=\"vm.eProducerSettingsActivePane == 'Commission'\">\r" +
    "\n" +
    "                                <section class=\"top-margin\">\r" +
    "\n" +
    "                                    <select aa-field-group=\"vm.account.billToType\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"billToType\"\r" +
    "\n" +
    "                                            aa-fg-class=\"fixed-width-md top-margin-big\"></select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <select aa-field-group=\"vm.account.commissionCalculationType\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                            options=\"vm.commissionCalculationTypeOptions\"\r" +
    "\n" +
    "                                            aa-fg-class=\"fixed-width-md top-margin-big\"></select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <input aa-field-group=\"vm.account.commission\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                                        ng-required=\"vm.account.commissionCalculationType\"\r" +
    "\n" +
    "                                        aa-fg-class=\"fixed-width-md top-margin-big\" />\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-show=\"vm.eProducerSettingsActivePane == 'Logins'\">\r" +
    "\n" +
    "                                <div class=\"text-right top-margin\">\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        class=\"mini\" \r" +
    "\n" +
    "                                        ng-click=\"vm.addUser()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        Add User\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <eproducer-user-list \r" +
    "\n" +
    "                                    class=\"top-margin block\" \r" +
    "\n" +
    "                                    users=\"vm.users\">\r" +
    "\n" +
    "                                </eproducer-user-list>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"vm.eProducerSettingsActivePane == 'Branding'\"\r" +
    "\n" +
    "                                ng-form=\"eProducerBrandingForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <section class=\"top-margin\">\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                            aa-field-group=\"vm.branding.enabled\"\r" +
    "\n" +
    "                                            aa-label=\"White-Label Branding Enabled\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <help\r" +
    "\n" +
    "                                            class=\"v-center left-margin\"\r" +
    "\n" +
    "                                            message=\"Enabling broker branding will override your agencies logos, address, phone numbers and more with the branding info set below.\">\r" +
    "\n" +
    "                                        </help>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <section>\r" +
    "\n" +
    "                                    <div class=\"row\">\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-field-group=\"vm.branding.companyName\"\r" +
    "\n" +
    "                                            aa-label=\"Company Name\"\r" +
    "\n" +
    "                                            aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                                            maxlength=\"1000\"\r" +
    "\n" +
    "                                            ng-required=\"vm.branding.enabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-field-group=\"vm.branding.phone\"\r" +
    "\n" +
    "                                            aa-label=\"Phone\"\r" +
    "\n" +
    "                                            aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                                            maxlength=\"15\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                                            aa-label=\"Phone Number\"\r" +
    "\n" +
    "                                            ng-required=\"vm.branding.enabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-field-group=\"vm.branding.email\"\r" +
    "\n" +
    "                                            aa-label=\"Email\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                                            aa-fg-class=\"col-xs-3\"\r" +
    "\n" +
    "                                            ng-required=\"vm.branding.enabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <div class=\"col-xs-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <address\r" +
    "\n" +
    "                                                hide-county=\"true\"\r" +
    "\n" +
    "                                                object=\"vm.branding\"\r" +
    "\n" +
    "                                                ng-required=\"vm.branding.enabled\">\r" +
    "\n" +
    "                                            </address>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <section>\r" +
    "\n" +
    "                                    <header>\r" +
    "\n" +
    "                                        <i class=\"fa fa-camera \"></i> Logos\r" +
    "\n" +
    "                                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "                                        <div class=\"fill right-margin\">\r" +
    "\n" +
    "                                            <h4>Large Logo</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <div class=\"flex\">\r" +
    "\n" +
    "                                                <main\r" +
    "\n" +
    "                                                    ng-show=\"!vm.lgLogo\"\r" +
    "\n" +
    "                                                    class=\"text-center width-300 left-margin-auto right-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <img\r" +
    "\n" +
    "                                                        ng-src=\"{{ vm.lgLogoPicturePath }}\"\r" +
    "\n" +
    "                                                        alt=\"{{ vm.account.companyName }}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <div class=\"top-margin\">\r" +
    "\n" +
    "                                                        <uploader\r" +
    "\n" +
    "                                                            show-upload-modal=\"false\"\r" +
    "\n" +
    "                                                            controls=\"vm.largeLogoUploader\"\r" +
    "\n" +
    "                                                            accepted-types=\"images\"\r" +
    "\n" +
    "                                                            files=\"vm.largeLogoFiles\">\r" +
    "\n" +
    "                                                        </uploader>   \r" +
    "\n" +
    "                                                    </div>\r" +
    "\n" +
    "                                                </main>\r" +
    "\n" +
    "                                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <main \r" +
    "\n" +
    "                                                class=\"top-margin\"\r" +
    "\n" +
    "                                                ng-show=\"vm.lgLogo\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <h5 class=\"flex\">\r" +
    "\n" +
    "                                                    New file attached\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <a \r" +
    "\n" +
    "                                                        ng-click=\"vm.removeLargeLogoUpload()\"\r" +
    "\n" +
    "                                                        class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                        Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                                    </a>\r" +
    "\n" +
    "                                                </h5>    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <p>\r" +
    "\n" +
    "                                                    Filename:\r" +
    "\n" +
    "                                                    <strong>{{vm.lgLogo.name}}</strong>\r" +
    "\n" +
    "                                                </p>\r" +
    "\n" +
    "                                            </main>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <div class=\"fill left-margin\">\r" +
    "\n" +
    "                                            <h4>Small Logo</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <div class=\"flex\">\r" +
    "\n" +
    "                                                <main\r" +
    "\n" +
    "                                                    ng-show=\"!vm.smLogo\"\r" +
    "\n" +
    "                                                    class=\"text-center width-300 left-margin-auto right-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <img\r" +
    "\n" +
    "                                                        ng-src=\"{{ vm.smLogoPicturePath }}\"\r" +
    "\n" +
    "                                                        alt=\"{{ vm.account.companyName }}\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <div class=\"top-margin\">\r" +
    "\n" +
    "                                                        <uploader\r" +
    "\n" +
    "                                                            show-upload-modal=\"false\"\r" +
    "\n" +
    "                                                            controls=\"vm.smallLogoUploader\"\r" +
    "\n" +
    "                                                            accepted-types=\"images\"\r" +
    "\n" +
    "                                                            files=\"vm.smallLogoFiles\">\r" +
    "\n" +
    "                                                        </uploader>   \r" +
    "\n" +
    "                                                    </div>\r" +
    "\n" +
    "                                                </main>\r" +
    "\n" +
    "                                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <main \r" +
    "\n" +
    "                                                class=\"top-margin\"\r" +
    "\n" +
    "                                                ng-show=\"vm.smLogo\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <h5 class=\"flex\">\r" +
    "\n" +
    "                                                    New file attached\r" +
    "\n" +
    "                                                    \r" +
    "\n" +
    "                                                    <a \r" +
    "\n" +
    "                                                        ng-click=\"vm.removeSmallLogoUpload()\"\r" +
    "\n" +
    "                                                        class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                        Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                                    </a>\r" +
    "\n" +
    "                                                </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <p>\r" +
    "\n" +
    "                                                    Filename:\r" +
    "\n" +
    "                                                    <strong>{{vm.smLogo.name}}</strong>\r" +
    "\n" +
    "                                                </p>\r" +
    "\n" +
    "                                            </main>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-show=\"vm.eProducerSettingsActivePane == 'FavoriteBondTypes'\" \r" +
    "\n" +
    "                                ng-form=\"favoriteBondTypesForm\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <section class=\"top-margin\">\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                            aa-field-group=\"vm.favoriteBondTypeConfiguration.favoriteBondTypeFilteringEnabled\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                        <help\r" +
    "\n" +
    "                                            class=\"v-center left-margin\"\r" +
    "\n" +
    "                                            message=\"Enabling this will limit the number of bond types that are found in search dropdowns in eProducer to only include bond types in the broker's favorite list.\">\r" +
    "\n" +
    "                                        </help>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                <section>\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "                                        <ui-select\r" +
    "\n" +
    "                                            class=\"block fill\"\r" +
    "\n" +
    "                                            ng-model=\"vm.favoriteBondTypeSearch.selected\"\r" +
    "\n" +
    "                                            ng-init=\"$select.label = 'Search Bond Types'\"\r" +
    "\n" +
    "                                            reset-search-input=\"false\"\r" +
    "\n" +
    "                                            name=\"favoriteBondTypeSearch\"\r" +
    "\n" +
    "                                            theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                            <ui-select-match\r" +
    "\n" +
    "                                                placeholder=\"Search for bond type name...\"\r" +
    "\n" +
    "                                                label=\"Search Bond Types\">\r" +
    "\n" +
    "                                                    {{$select.selected.name}}\r" +
    "\n" +
    "                                            </ui-select-match>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                            <ui-select-choices\r" +
    "\n" +
    "                                                repeat=\"bondType in vm.favoriteBondTypeSearch.matches track by $index\"\r" +
    "\n" +
    "                                                refresh=\"vm.searchBondTypes($select.search)\"\r" +
    "\n" +
    "                                                refresh-delay=\"0\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                <div ng-bind-html=\"bondType.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                            </ui-select-choices>\r" +
    "\n" +
    "                                        </ui-select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                        <button \r" +
    "\n" +
    "                                            class=\"top-margin-auto bottom-margin-thin small\"\r" +
    "\n" +
    "                                            ng-click=\"vm.addFavoriteBondType()\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                            Add\r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                <section>\r" +
    "\n" +
    "                                    <table ng-show=\"vm.favoriteBondTypeConfiguration.favoriteBondTypes.length\">\r" +
    "\n" +
    "                                        <thead>\r" +
    "\n" +
    "                                            <tr>\r" +
    "\n" +
    "                                                <th colspan=\"2\">Favorite Bond Types</th>\r" +
    "\n" +
    "                                            </tr>\r" +
    "\n" +
    "                                        </thead>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                        <tr ng-repeat=\"favoriteBondType in vm.favoriteBondTypeConfiguration.favoriteBondTypes\">\r" +
    "\n" +
    "                                            <td>{{favoriteBondType.bondTypeName}}</td>\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <td class=\"text-right\">\r" +
    "\n" +
    "                                                <button ng-click=\"vm.removeFavoriteBondType($index)\">\r" +
    "\n" +
    "                                                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                                </button>\r" +
    "\n" +
    "                                            </td>\r" +
    "\n" +
    "                                        </tr>\r" +
    "\n" +
    "                                    </table>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                    <empty-state\r" +
    "\n" +
    "                                        ng-show=\"!vm.favoriteBondTypeConfiguration.favoriteBondTypes.length\"\r" +
    "\n" +
    "                                        message=\"This eProducer Broker doesn't have any favorite bond types specified.\">\r" +
    "\n" +
    "                                    </empty-state>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"vm.eProducerSettingsActivePane == 'Payments'\"\r" +
    "\n" +
    "                                ng-form=\"settingsForm\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <section class=\"top-margin\">\r" +
    "\n" +
    "                                    <p>\r" +
    "\n" +
    "                                        You can allow a broker agent to complete the purchase process without requiring them to pay in advance.\r" +
    "\n" +
    "                                        Instead, we'll generate invoices which will allow the broker to pay at their regular intervals.\r" +
    "\n" +
    "                                    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <input\r" +
    "\n" +
    "                                        aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                        aa-field-group=\"vm.account.allowDeferredPayment\"\r" +
    "\n" +
    "                                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"vm.eProducerSettingsActivePane == 'CarrierVisibility'\"\r" +
    "\n" +
    "                                ng-form=\"CarrierVisibilityForm\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <section class=\"top-margin\">\r" +
    "\n" +
    "                                    <p>\r" +
    "\n" +
    "                                        If enabled, the eProducer broker's agency codes will be visible to carriers on bond screens and reports.\r" +
    "\n" +
    "                                    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-fg-class=\"v-center\"\r" +
    "\n" +
    "                                            aa-field-group=\"vm.account.isBrokerVisibleToCarrier\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        <input \r" +
    "\n" +
    "                                            aa-field-group=\"vm.account.agencyCode\" \r" +
    "\n" +
    "                                            aa-fg-class=\"width-150\" />\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <input \r" +
    "\n" +
    "                                            aa-field-group=\"vm.account.agencySubCode\" \r" +
    "\n" +
    "                                            aa-label=\"Sub-Code\"\r" +
    "\n" +
    "                                            aa-fg-class=\"width-150\" />\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </section>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </uib-tab>\r" +
    "\n" +
    "            </uib-tabset>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"sticky-page-footer flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"left-margin-auto v-center\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.save_click()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/eProducerBrokers/eProducerAccountsFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"filterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul  \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>     \r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">\r" +
    "\n" +
    "                    Category\r" +
    "\n" +
    "                </label>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.brokerCategoryId\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.brokerCategoryOptions\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/eProducerBrokers/eProducerAccountsGrid.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        Brokers\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body fill-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"searchForm\"\r" +
    "\n" +
    "        class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "            aa-label=\"Search by company name...\" \r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Refresh\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            id=\"broker-list-refresh-button\"\r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <eproducer-accounts-filter-dropdown\r" +
    "\n" +
    "            class=\"flex left-margin\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </eproducer-accounts-filter-dropdown>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                uib-tooltip=\"Actions\"\r" +
    "\n" +
    "                tooltip-placement=\"top-right\"\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                id=\"broker-grid-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"small\"\r" +
    "\n" +
    "                        ng-click=\"vm.addNewBroker()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-plus\" aria-hidden=\"true\"></i>\r" +
    "\n" +
    "                        Add Broker \r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <table class=\"group-by-tbody bottom-margin\">    \r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr\r" +
    "\n" +
    "                        class=\"sortable\"\r" +
    "\n" +
    "                        ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <th \r" +
    "\n" +
    "                            class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                            ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <table-sort-button\r" +
    "\n" +
    "                                text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                            </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody ng-repeat=\"broker in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td \r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-switch=\"column.name\"\r" +
    "\n" +
    "                            ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                            ng-class=\"column.cellClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"Id\">{{broker.id}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"LookupCode\">{{broker.lookupCode}}</span>\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                ng-switch-when=\"Name\"\r" +
    "\n" +
    "                                ui-sref=\"main.eProducerAccountDetail({ id: broker.id })\"\r" +
    "\n" +
    "                                target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                {{broker.name}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsLocked\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    ng-show=\"broker.isLocked\"\r" +
    "\n" +
    "                                    class=\"red\">\r" +
    "\n" +
    "                                    Locked\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsApproved\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span ng-show=\"broker.isApproved\">\r" +
    "\n" +
    "                                    Approved\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <span \r" +
    "\n" +
    "                                    class=\"red\"\r" +
    "\n" +
    "                                    ng-show=\"!broker.isApproved\">\r" +
    "\n" +
    "                                    Pending\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span \r" +
    "\n" +
    "                                ng-switch-when=\"MailAddress\"\r" +
    "\n" +
    "                                ng-bind-html=\"broker | mailingAddress : 'two-line'\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                ng-switch-when=\"DeleteButton\"\r" +
    "\n" +
    "                                ng-click=\"vm.deleteBroker(broker)\"\r" +
    "\n" +
    "                                ng-disabled=\"!broker.isDeletable\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{!broker.isDeletable ? 'This broker cannot be deleted because it has customers associated to it' : 'Delete broker' }}\"            \r" +
    "\n" +
    "                                tooltip-append-to-body=\"true\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"Phone\">\r" +
    "\n" +
    "                                {{broker.phone}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Fax\">\r" +
    "\n" +
    "                                {{broker.fax}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span\r" +
    "\n" +
    "                                ng-bind-html=\"broker | physicalAddress : 'two-line'\" \r" +
    "\n" +
    "                                ng-switch-when=\"PhysicalAddress\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ReferredBy\">\r" +
    "\n" +
    "                                {{broker.referredBy}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Producer\">\r" +
    "\n" +
    "                                {{broker.producer}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CSE\">\r" +
    "\n" +
    "                                {{broker.clientServiceExecutive}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CSM\">\r" +
    "\n" +
    "                                {{broker.clientServiceManager}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CSA\">\r" +
    "\n" +
    "                                {{broker.clientServiceAssociate}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CreatedDateTime\">\r" +
    "\n" +
    "                                {{broker.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"AllowDeferredPayment\">\r" +
    "\n" +
    "                                {{broker.allowDeferredPayment ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"AgencyCode\">\r" +
    "\n" +
    "                                {{broker.agencyCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"AgencySubCode\">\r" +
    "\n" +
    "                                {{broker.agencySubCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsBrokerVisibleToCarrier\">\r" +
    "\n" +
    "                                {{broker.isBrokerVisibleToCarrier ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrandingEnabled\">\r" +
    "\n" +
    "                                {{broker.brandingEnabled ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrandingCompanyName\">\r" +
    "\n" +
    "                                {{broker.brandingCompanyName}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrandingPhone\">\r" +
    "\n" +
    "                                {{broker.brandingPhone}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrandingEmail\">\r" +
    "\n" +
    "                                {{broker.brandingEmail}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BillToType\">\r" +
    "\n" +
    "                                {{broker.billToTypeDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Commission\">\r" +
    "\n" +
    "                                {{broker.commission}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CommissionCalculationType\">\r" +
    "\n" +
    "                                {{broker.commissionCalculationTypeDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Comments\">\r" +
    "\n" +
    "                                {{broker.comments}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Balance\">\r" +
    "\n" +
    "                                {{broker.balance | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"UnappliedPaymentTotal\">\r" +
    "\n" +
    "                                {{broker.unappliedPaymentTotal | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"InvoicedBalance\">\r" +
    "\n" +
    "                                {{broker.invoicedBalance | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"NotInvoicedTotal\">\r" +
    "\n" +
    "                                {{broker.notInvoicedTotal | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CreditTotal\">\r" +
    "\n" +
    "                                {{broker.creditTotal | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"DebitTotal\">\r" +
    "\n" +
    "                                {{broker.debitTotal | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"LegalName\">\r" +
    "\n" +
    "                                {{broker.legalName}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Tags\">\r" +
    "\n" +
    "                                <span class=\"tag-container\">\r" +
    "\n" +
    "                                    <span \r" +
    "\n" +
    "                                        ng-repeat=\"tag in broker.tags\"\r" +
    "\n" +
    "                                        class=\"tag\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        {{tag}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Category\">\r" +
    "\n" +
    "                                {{broker.brokerCategory}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </td>                        \r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No brokers were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        class=\"top-margin\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/invoices/invoiceReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"flex bottom-margin\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search Invoices\"\r" +
    "\n" +
    "           uib-tooltip=\"Search Invoices\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "           class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "           record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "       </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"invoices-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <invoice-report-filter-dropdown class=\"flex left-margin\"\r" +
    "\n" +
    "                                    ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "                                    table=\"vm.table\">\r" +
    "\n" +
    "    </invoice-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportInvoices()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <table class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"expand-indicator-column\">&nbsp;</th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Invoice #\"\r" +
    "\n" +
    "                            sort-expression=\"Invoices.Id\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content text-left\">Bill To</th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Date\"\r" +
    "\n" +
    "                            sort-expression=\"Invoices.CreatedDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Status\"\r" +
    "\n" +
    "                            sort-expression=\"Invoices.Status\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>Comments</th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Amount Due\"\r" +
    "\n" +
    "                            sort-expression=\"Invoices.TotalAmountDue\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"invoice in vm.table.data\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        class=\"expand-indicator-column cursor-pointer\"\r" +
    "\n" +
    "                        ng-click=\"vm.table.toggleIsExpanded($index)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i ng-class=\"{\r" +
    "\n" +
    "                                'fa fa-caret-right': !vm.table.rows[$index].isExpanded, \r" +
    "\n" +
    "                                'fa fa-caret-down': vm.table.rows[$index].isExpanded}\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            show-invoice-detail-modal=\"invoice.id\"\r" +
    "\n" +
    "                            ng-show=\"invoice.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: invoice.customerId, sectionView: 'invoices' })\"\r" +
    "\n" +
    "                            ng-show=\"invoice.customerId && !invoice.eProducerAccountId\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"!invoice.eProducerAccountId\">{{invoice.customerName}}</span>\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.eProducerAccountDetail({ id: invoice.eProducerAccountId })\"\r" +
    "\n" +
    "                            ng-show=\"invoice.eProducerAccountId\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-show=\"invoice.eProducerAccountId\">Broker - {{invoice.eProducerAccountName}}</span>\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        {{invoice.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        {{invoice.status}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{invoice.comments}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        {{invoice.totalAmountDue | currency:'$'}}\r" +
    "\n" +
    "                    </td>                    \r" +
    "\n" +
    "                    <td class=\"button-column\">\r" +
    "\n" +
    "                        <pdf-download\r" +
    "\n" +
    "                            uib-tooltip=\"Download Invoice\"\r" +
    "\n" +
    "                            url=\"vm.getInvoiceDownloadUrl(invoice.id)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                            Download PDF\r" +
    "\n" +
    "                        </pdf-download>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                <tr \r" +
    "\n" +
    "                    class=\"table-details-row\"\r" +
    "\n" +
    "                    ng-show=\"vm.table.rows[$index].isExpanded\">\r" +
    "\n" +
    "                    <td colspan=\"8\">\r" +
    "\n" +
    "                        <table class=\"small no-stripe\">\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Billing #</th>\r" +
    "\n" +
    "                                <th>Description</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Credit</th>\r" +
    "\n" +
    "                                <th class=\"fit-content\">Amount Due</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <tr ng-repeat-start=\"lineItem in invoice.lineItems\"\r" +
    "\n" +
    "                                ng-if=\"$index != 0 && invoice.lineItems[$index - 1].bondTransactionId != invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                                <td colspan=\"4\">\r" +
    "\n" +
    "                                    <!-- Spacer row between bond numbers -->\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <tr ng-if=\"$index == 0 || invoice.lineItems[$index - 1].bondTransactionId != invoice.lineItems[$index].bondTransactionId\">\r" +
    "\n" +
    "                                <td></td>\r" +
    "\n" +
    "                                <td>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        {{lineItem.bondNumber}} - {{lineItem.bondAmount | currency:'$'}} {{lineItem.bondType}}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        Term: {{ lineItem.effectiveDate | dateOnly }} - {{ lineItem.expirationDate | dateOnly }} Eff Date: {{ lineItem.transactionEffectiveDate | dateOnly }}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        Principal: {{ lineItem.nameOnBond }}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    <div>\r" +
    "\n" +
    "                                        Writing Company: {{ lineItem.writingCompany }}\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td></td>\r" +
    "\n" +
    "                                <td></td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <tr ng-repeat-end>\r" +
    "\n" +
    "                                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                    {{lineItem.billingEntryId}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-top-padding no-bottom-padding\">\r" +
    "\n" +
    "                                    {{ lineItem.code }} - {{ lineItem.description }}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                    <span ng-show=\"lineItem.amountReceived != 0\">\r" +
    "\n" +
    "                                        {{lineItem.amountReceived | currency:'$'}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"no-top-padding no-bottom-padding fit-content\">\r" +
    "\n" +
    "                                    <span ng-show=\"lineItem.amountDue != 0\">\r" +
    "\n" +
    "                                        {{lineItem.amountDue | currency:'$'}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    class=\"no-right-padding\"\r" +
    "\n" +
    "                                    colspan=\"4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div class=\"flex\">\r" +
    "\n" +
    "                                        <table class=\"no-stripe width-auto left-margin-auto\">\r" +
    "\n" +
    "                                            <tr>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    Invoice Amount:\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    {{invoice.originalAmount | currency: '$'}}\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                            </tr>\r" +
    "\n" +
    "                                            <tr>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    Payments Received:\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    {{invoice.totalAmountReceived | currency: '$'}}\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                            </tr>\r" +
    "\n" +
    "                                            <tr>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    Outstanding Balance:\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                                <td class=\"text-right\">\r" +
    "\n" +
    "                                                    <b>{{invoice.totalAmountDue | currency:'$'}}</b>\r" +
    "\n" +
    "                                                </td>\r" +
    "\n" +
    "                                            </tr>\r" +
    "\n" +
    "                                        </table>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        message=\"No invoices found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/invoices/invoiceReportFilterDropdown/invoiceReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"invoiceReportFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Open\"> Open </option>\r" +
    "\n" +
    "                    <option value=\"Paid\"> Paid </option>\r" +
    "\n" +
    "                    <option value=\"Voided\"> Voided </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Amount Due Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Invoice Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/leads/leadsEntryReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"flex bottom-margin\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search by individuals name or name on bond\"\r" +
    "\n" +
    "           uib-tooltip=\"Search by individuals name or name on bond\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"leads-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <lead-entry-report-filter-dropdown class=\"flex left-margin\"\r" +
    "\n" +
    "                                       ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "                                       table=\"vm.table\">\r" +
    "\n" +
    "    </lead-entry-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportLeadEntries()\">\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div>\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <table class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Lead Type\"\r" +
    "\n" +
    "                            sort-expression=\"leadType\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>   \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"text-left\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Date/Time\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <table-sort-button \r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>   \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Lowest Premium\"\r" +
    "\n" +
    "                            sort-expression=\"lowestPremium\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Highest Premium\"\r" +
    "\n" +
    "                            sort-expression=\"highestPremium\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carriers Quoted\"\r" +
    "\n" +
    "                            sort-expression=\"carrierCount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Quotes Presented\"\r" +
    "\n" +
    "                            sort-expression=\"prequalifiedQuoteCount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Desired Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"desiredEffectiveDate\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                        <th class=\"fit-content\"></th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody ng-repeat=\"lead in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.leadType}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.bondType}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                ui-sref=\"main.customerDetail({ customerId: item.application.customerId, sectionView: 'applications' })\" \r" +
    "\n" +
    "                                ng-show=\"item.applicationId\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    {{lead.nameOnBond}}\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-show=\"!item.applicationId\">{{lead.nameOnBond}}</span>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.lowestPremium | currency:'$'}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.highestPremium | currency:'$'}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.carrierCount}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.prequalifiedQuoteCount}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"fit-content\">\r" +
    "\n" +
    "                            {{lead.desiredEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"button-column\">\r" +
    "\n" +
    "                            <button show-lead-detail-modal=\"lead.id\">Details</button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"!vm.table.isVisible\"\r" +
    "\n" +
    "        message=\"No records found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/agentSpecific/leads/leadsReportFilterDropdown/leadsEntryReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"leadEntryReportFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "    uib-dropdown-menu\r" +
    "\n" +
    "    class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Lead Type</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.leadType\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Inquiry\"> Inquiry </option>\r" +
    "\n" +
    "                    <option value=\"Prospect\"> Prospect </option>\r" +
    "\n" +
    "                    <option value=\"Submission\"> Submission </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">  Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Bond Amount </label>\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minBondAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maxBondAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/agentSpecific/monthEndAccounting/monthEndReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section \r" +
    "\n" +
    "    class=\"above top-margin\"\r" +
    "\n" +
    "    ng-form=\"reportForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center flex\">\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.startDate\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "            aa-fg-class=\"right-margin v-center\"\r" +
    "\n" +
    "            required \r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.endDate\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"date\" \r" +
    "\n" +
    "            aa-fg-class=\"right-margin v-center\"\r" +
    "\n" +
    "            required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"small v-center\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.exportMonthEndEntries()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "            Download\r" +
    "\n" +
    "        </button>      \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/states/agentSpecific/payments/paymentsReport.html',
    "<payment-report></payment-report>"
  );


  $templateCache.put('app/states/agentSpecific/settings/settings.html',
    "<ng-form name=\"settingsForm\">\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Settings\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"page-body width-100p bottom-margin sticky-page flex column\"\r" +
    "\n" +
    "        ng-form=\"settingsForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex height-100p\">\r" +
    "\n" +
    "            <ul class=\"vertical-tab-nav small\">\r" +
    "\n" +
    "                <li class=\"heading\">General</li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'OurAgency'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'OurAgency'\">\r" +
    "\n" +
    "                        Our Agency\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'ActivityTypes'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'ActivityTypes'\">\r" +
    "\n" +
    "                        Activity Types\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'AttachmentTypes'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'AttachmentTypes'\">\r" +
    "\n" +
    "                        Attachment Types\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'EProducer'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'EProducer'\">\r" +
    "\n" +
    "                        EProducer\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'AttorneyInFact'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'AttorneyInFact'\">\r" +
    "\n" +
    "                        Attorney In Fact\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"heading\">Integrations</li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'Epic Integration'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'EpicIntegration'\">\r" +
    "\n" +
    "                        Epic Integration\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li class=\"heading\">Billing & Payments</li>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'InvoiceSettings'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'InvoiceSettings'\">\r" +
    "\n" +
    "                        Invoice Settings\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'Payments'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'Payments'\">\r" +
    "\n" +
    "                        Payments\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'Fees'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'Fees'; vm.feesPaneSelected = true;\">\r" +
    "\n" +
    "                        Fees\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li ng-class=\"{active: vm.activePane == 'ProductionCredit'}\">\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'ProductionCredit'\">\r" +
    "\n" +
    "                        Production Credit\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    class=\"heading\"\r" +
    "\n" +
    "                    ng-show=\"currentUser.permissions.canEditUsers || currentUser.permissions.canEditUserSettings || currentUser.permissions.canEditUserGroups\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Access Control\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li\r" +
    "\n" +
    "                    ng-class=\"{active: vm.activePane == 'UserGroups'}\"\r" +
    "\n" +
    "                    ng-show=\"currentUser.permissions.canEditUserGroups\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'UserGroups'; vm.userGroupPaneSelected = true;\">\r" +
    "\n" +
    "                        User Groups\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                <li\r" +
    "\n" +
    "                    ng-class=\"{active: vm.activePane == 'Users'}\"\r" +
    "\n" +
    "                    ng-show=\"currentUser.permissions.canEditUsers || currentUser.permissions.canEditUserSettings\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <a ng-click=\"vm.activePane = 'Users'; vm.userPaneSelected = true;\">\r" +
    "\n" +
    "                        Users\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <li \r" +
    "\n" +
    "                    ng-show=\"currentUser.permissions.canEditSystemAccounts\"\r" +
    "\n" +
    "                    class=\"heading\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Follow-Ups\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <ng-container ng-show=\"currentUser.permissions.canEditSystemAccounts\">\r" +
    "\n" +
    "                    <li ng-class=\"{active: vm.activePane == 'FollowUpStrategies'}\">\r" +
    "\n" +
    "                        <a ng-click=\"vm.activePane = 'FollowUpStrategies'; vm.followUpStrategiesPaneSelected = true;\">\r" +
    "\n" +
    "                            Follow-Up Strategies\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </li>\r" +
    "\n" +
    "                    <li ng-class=\"{active: vm.activePane == 'EmailTemplates'}\">\r" +
    "\n" +
    "                        <a ng-click=\"vm.activePane = 'EmailTemplates'; vm.emailTemplatesPaneSelected = true;\">\r" +
    "\n" +
    "                            Email Templates\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </li>\r" +
    "\n" +
    "                </ng-container>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <perfect-scrollbar\r" +
    "\n" +
    "                suppress-scroll-x=\"true\"\r" +
    "\n" +
    "                class=\"scroller fill top-margin\"\r" +
    "\n" +
    "                wheel-propagation=\"true\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <div ng-show=\"vm.activePane == 'OurAgency'\">\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>            \r" +
    "\n" +
    "                            Company Information\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.companyName\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                required \r" +
    "\n" +
    "                                maxlength=\"1000\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.legalName\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                required \r" +
    "\n" +
    "                                maxlength=\"500\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.fein\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                maxlength=\"50\" \r" +
    "\n" +
    "                                aa-label=\"FEIN\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.stateOfIncorporation\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"state\"></select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.phone\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                maxlength=\"15\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                                aa-label=\"Phone Number\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.email\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                                maxlength=\"1000\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                                aa-label=\"Email Address\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <address \r" +
    "\n" +
    "                            object=\"vm.settings\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </address>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Company Logos\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <div class=\"fill right-margin v-center\">\r" +
    "\n" +
    "                                <div class=\"left-margin-auto right-margin-auto width-300\"> \r" +
    "\n" +
    "                                    <div \r" +
    "\n" +
    "                                        ng-show=\"!vm.lgLogo\"\r" +
    "\n" +
    "                                        class=\"text-center bottom-margin\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        <img \r" +
    "\n" +
    "                                            ng-src=\"{{ vm.lgLogoPicturePath }}\" \r" +
    "\n" +
    "                                            alt=\"{{ vm.settings.companyName }}\" />\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <uploader\r" +
    "\n" +
    "                                        show-upload-modal=\"false\"\r" +
    "\n" +
    "                                        controls=\"vm.largeLogoUploader\"\r" +
    "\n" +
    "                                        accepted-types=\"images\"\r" +
    "\n" +
    "                                        files=\"vm.largeLogoFiles\">\r" +
    "\n" +
    "                                    </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <main \r" +
    "\n" +
    "                                        class=\"top-margin\"\r" +
    "\n" +
    "                                        ng-hide=\"!vm.lgLogo\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <h5 class=\"flex\">\r" +
    "\n" +
    "                                            New file attached\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <a \r" +
    "\n" +
    "                                                ng-click=\"vm.removeLargeLogoUpload()\"\r" +
    "\n" +
    "                                                class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                            </a>\r" +
    "\n" +
    "                                        </h5>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <p>\r" +
    "\n" +
    "                                            Filename:\r" +
    "\n" +
    "                                            <strong>{{vm.lgLogo.name}}</strong>\r" +
    "\n" +
    "                                        </p>\r" +
    "\n" +
    "                                    </main>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"fill left-margin v-center\">\r" +
    "\n" +
    "                                <div class=\"left-margin-auto right-margin-auto width-300\">\r" +
    "\n" +
    "                                    <div \r" +
    "\n" +
    "                                        ng-show=\"!vm.smLogo\"\r" +
    "\n" +
    "                                        class=\"text-center bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <img \r" +
    "\n" +
    "                                            ng-src=\"{{ vm.smLogoPicturePath }}\" \r" +
    "\n" +
    "                                            alt=\"{{ vm.settings.companyName }}\" />\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <uploader\r" +
    "\n" +
    "                                        show-upload-modal=\"false\"\r" +
    "\n" +
    "                                        controls=\"vm.smallLogoUploader\"\r" +
    "\n" +
    "                                        accepted-types=\"images\"\r" +
    "\n" +
    "                                        files=\"vm.smallLogoFiles\">\r" +
    "\n" +
    "                                    </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <main \r" +
    "\n" +
    "                                        class=\"top-margin\"\r" +
    "\n" +
    "                                        ng-hide=\"!vm.smLogo\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <h5 class=\"flex\">\r" +
    "\n" +
    "                                            New file attached\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <a \r" +
    "\n" +
    "                                                ng-click=\"vm.removeSmallLogoUpload()\"\r" +
    "\n" +
    "                                                class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                            </a>\r" +
    "\n" +
    "                                        </h5>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <p>\r" +
    "\n" +
    "                                            Filename:\r" +
    "\n" +
    "                                            <strong>{{vm.smLogo.name}}</strong>\r" +
    "\n" +
    "                                        </p>\r" +
    "\n" +
    "                                    </main>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Billing Contact\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Please provide the contact info for the primary individual that we should reach out to for our billing purposes.\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.billingContact\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                maxlength=\"500\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.billingPhone\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                maxlength=\"500\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.billingEmail\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                                maxlength=\"125\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Additional Information\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.websiteURL\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"url\" \r" +
    "\n" +
    "                            maxlength=\"2000\" \r" +
    "\n" +
    "                            aa-label=\"Website URL\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <textarea \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.description\" \r" +
    "\n" +
    "                            maxlength=\"500\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'ActivityTypes'\">\r" +
    "\n" +
    "                    <div ng-form=\"newActivityTypeForm\">\r" +
    "\n" +
    "                        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.newActivityType\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md small v-center\"\r" +
    "\n" +
    "                                aa-label-strategy=\"inline\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"left-margin v-center small btn-sm\"\r" +
    "\n" +
    "                                type=\"submit\"\r" +
    "\n" +
    "                                ng-click=\"vm.addActivityType()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table ng-show=\"vm.settings.activityTypes.length > 0\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th colspan=\"2\">Activity Type</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-repeat=\"activityType in vm.settings.activityTypes\">\r" +
    "\n" +
    "                                <td ng-class=\"{'line-through' : activityType.isRemoved}\">\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-fg-class=\"fixed-width-md no-margin small\"\r" +
    "\n" +
    "                                        aa-field-group=\"activityType.name\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"placeholderOnly\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"button-column\">\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        ng-disabled=\"activityType.systemActivityType\"\r" +
    "\n" +
    "                                        ng-click=\"vm.deleteActivityType(activityType, $index)\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"vm.settings.activityTypes.length == 0\" \r" +
    "\n" +
    "                        message=\"No activity types have been created\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'AttachmentTypes'\">\r" +
    "\n" +
    "                    <div ng-form=\"newAttachmentTypeForm\">\r" +
    "\n" +
    "                        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "                            <input\r" +
    "\n" +
    "                                aa-field-group=\"vm.newAttachmentType\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md small v-center\"\r" +
    "\n" +
    "                                aa-label-strategy=\"inline\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"left-margin v-center small btn-sm\"\r" +
    "\n" +
    "                                type=\"submit\"\r" +
    "\n" +
    "                                ng-click=\"vm.addAttachmentType()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table ng-show=\"vm.settings.attachmentTypes.length > 0\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th colspan=\"2\">Attachment Type</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-repeat=\"attachmentType in vm.settings.attachmentTypes\">\r" +
    "\n" +
    "                                <td ng-class=\"{'line-through' : attachmentType.isRemoved}\">\r" +
    "\n" +
    "                                    <input \r" +
    "\n" +
    "                                        aa-fg-class=\"fixed-width-md no-margin small\"\r" +
    "\n" +
    "                                        aa-field-group=\"attachmentType.name\"\r" +
    "\n" +
    "                                        aa-label-strategy=\"placeholderOnly\" />\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"button-column\">\r" +
    "\n" +
    "                                    <button ng-click=\"vm.deleteAttachmentType(attachmentType, $index)\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <empty-state\r" +
    "\n" +
    "                        ng-show=\"vm.settings.attachmentType.length == 0\" \r" +
    "\n" +
    "                        message=\"No attachment types have been created\">\r" +
    "\n" +
    "                    </empty-state>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'EpicIntegration'\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <img \r" +
    "\n" +
    "                                class=\"v-center epic-integration-icon\"\r" +
    "\n" +
    "                                src=\"/img/icons/Epic_logo.png\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Epic\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicIntegrationEnabled\"\r" +
    "\n" +
    "                            aa-label=\"Epic Integration Enabled\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center left-margin-auto\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Structure</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicAgencyCode\"\r" +
    "\n" +
    "                            aa-label=\"Agency Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\" \r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicBranchCode\"\r" +
    "\n" +
    "                            aa-label=\"Branch Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDepartmentCode\"\r" +
    "\n" +
    "                            aa-label=\"Department Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Profit Center</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultProfitCenterCode\"\r" +
    "\n" +
    "                            aa-label=\"Default Profit Center Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicTransactionProfitCenterCode\"\r" +
    "\n" +
    "                            aa-label=\"Transaction Profit Center Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicAccountProfitCenterCode\"\r" +
    "\n" +
    "                            aa-label=\"Account Profit Center Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3 col-sm-4\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Line of Business</h5>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultLineOfBusinessCode\"\r" +
    "\n" +
    "                            aa-label=\"Default Line of Business Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Servicing Rules</h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultProducerCode\"\r" +
    "\n" +
    "                            aa-label=\"Default Producer Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultClientServiceManagerCode\"\r" +
    "\n" +
    "                            aa-label=\"Default CSM Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.epicIntegrationEnabled\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultClientServiceExecutiveCode\"\r" +
    "\n" +
    "                            aa-label=\"Default CSE Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.epicDefaultClientServiceAgentCode\"\r" +
    "\n" +
    "                            aa-label=\"Default CSA Code\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-md-3\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.activePane == 'Payments'\"\r" +
    "\n" +
    "                    class=\"no-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Credit Card\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.creditCardProcessor\"\r" +
    "\n" +
    "                            aa-fg-class=\"fixed-width-md\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3\"\r" +
    "\n" +
    "                            options=\"vm.creditCardProcessors\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.creditCardProcessor=='AuthorizeNet'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Authorize.Net Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.authorizeNetLogin\"\r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='AuthorizeNet'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.authorizeNetTransactionId\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='AuthorizeNet'\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"password\" \r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.creditCardProcessor=='EProcessingNetwork'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                EProcessing Network Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.eProcessingNetworkAccountNumber\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='EProcessingNetwork'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.eProcessingNetworkRestrictKey\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='EProcessingNetwork'\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"password\" \r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.creditCardProcessor=='WorldPay'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                WorldPay Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.xpressPayBillTypePk\"\r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='WorldPay'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.creditCardProcessor=='Vantiv'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Vantiv Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivUsername\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivPassword\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivMerchantId\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowCCPaymentsThroughEProducer || vm.settings.allowCCPaymentsThroughA3) && vm.settings.creditCardProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.creditCardProcessor=='Dummy'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Dummy Payment Processor\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                                <strong>Warning:</strong> This is a dummy payment processor. Do not use in production environments.\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <h5>\r" +
    "\n" +
    "                            Where Credit Cards are Allowed \r" +
    "\n" +
    "                        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowCCPaymentsThroughPaymentForm\" \r" +
    "\n" +
    "                            aa-label=\"Allow Credit Card Payments In Payment Portal\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowCCPaymentsThroughEProducer\" \r" +
    "\n" +
    "                            aa-label=\"Allow Credit Card Payments In EProducer\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowCCPaymentsThroughA3\" \r" +
    "\n" +
    "                            aa-label=\"Allow Credit Card Payments in A3\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            ACH\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.achProcessor\"\r" +
    "\n" +
    "                            ng-required=\"vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3\"\r" +
    "\n" +
    "                            aa-fg-class=\"fixed-width-md\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.achProcessors\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.achProcessor=='Vantiv'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Vantiv Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivAchUsername\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3) && vm.settings.achProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivAchPassword\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3) && vm.settings.achProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.vantivAchMerchantId\" \r" +
    "\n" +
    "                                ng-required=\"(vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3) && vm.settings.achProcessor=='Vantiv'\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.achProcessor=='CheckCommerce'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Check Gateway Configuration\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.checkGatewayUsername\" \r" +
    "\n" +
    "                                ng-required=\"vm.settings.achProcessor == 'CheckCommerce' && (vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3)\"\r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.settings.checkGatewayPassword\" \r" +
    "\n" +
    "                                ng-required=\"vm.settings.achProcessor == 'CheckCommerce' && (vm.settings.allowACHPaymentsThroughEProducer || vm.settings.allowACHPaymentsThroughA3)\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"password\" \r" +
    "\n" +
    "                                aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-show=\"vm.settings.achProcessor=='Dummy'\">\r" +
    "\n" +
    "                            <h5>\r" +
    "\n" +
    "                                Dummy Payment Processor\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"alert alert-danger\">\r" +
    "\n" +
    "                                <strong>Warning:</strong> This is a dummy payment processor. Do not use in production environments.\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <h5>\r" +
    "\n" +
    "                            Where ACH is Allowed\r" +
    "\n" +
    "                        </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowACHPaymentsThroughPaymentForm\" \r" +
    "\n" +
    "                            aa-label=\"Allow ACH Payments In Payment Portal\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowACHPaymentsThroughEProducer\" \r" +
    "\n" +
    "                            aa-label=\"Allow ACH Payments Through EProducer\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowACHPaymentsThroughA3\" \r" +
    "\n" +
    "                            aa-label=\"Allow ACH Payments Through A3\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Other Payment Types\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.allowOtherPaymentsThroughA3\" \r" +
    "\n" +
    "                            aa-label=\"Allow Other Payments Through A3\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"top-margin\"\r" +
    "\n" +
    "                            label-class=\"width-350\" />\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'EProducer'\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        EProducer\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.eProducerPortalURL\" \r" +
    "\n" +
    "                            aa-label=\"eProducer Portal URL\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-10\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.eProducerGoogleAnalyticsCode\" \r" +
    "\n" +
    "                            aa-label=\"Google Analytics Code\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-2\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.noBondTypeRedirectUrl\" \r" +
    "\n" +
    "                            aa-label=\"No Bond Type Redirect URL\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-12\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'AttorneyInFact'\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        Attorney In Fact\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <user-dropdown \r" +
    "\n" +
    "                        ng-model=\"vm.settings.defaultAttorneyInFactUserId\"\r" +
    "\n" +
    "                        is-attorney-in-fact=\"true\"\r" +
    "\n" +
    "                        label=\"Default Attorney in Fact\"\r" +
    "\n" +
    "                        allow-unselected=\"true\"\r" +
    "\n" +
    "                        unselected-text=\"No Default\">\r" +
    "\n" +
    "                    </user-dropdown>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'InvoiceSettings'\">\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        Invoice Payment Information \r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <p class=\"bottom-margin-big\">\r" +
    "\n" +
    "                        This information will be displayed at the bottom of invoices created for you in A3\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Payment To</h5>\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.paymentMadeOutToName\"  \r" +
    "\n" +
    "                        aa-label=\"Payment Made Out To\"\r" +
    "\n" +
    "                        aa-fg-class=\"max-width-500\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Payment Mail To Address</h5>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailAddress\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-12\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailSuiteAptNumber\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-12\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailZip\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailCity\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailCounty\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentMailState\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>Contact Information</h5>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentPhone\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentEmail\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.paymentPageUrl\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"url\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-xs-12\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <h5>Instructions</h5>\r" +
    "\n" +
    "                    <textarea \r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.paymentInstructions1\" \r" +
    "\n" +
    "                        aa-label=\"Payment Instructions 1\"\r" +
    "\n" +
    "                        maxlength=\"1000\">\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <textarea \r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.paymentInstructions2\" \r" +
    "\n" +
    "                        aa-label=\"Payment Instructions 2\"\r" +
    "\n" +
    "                        maxlength=\"1000\">\r" +
    "\n" +
    "                    </textarea>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'UserGroups'\">\r" +
    "\n" +
    "                    <user-group-list ng-if=\"vm.userGroupPaneSelected\"></user-group-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'Users'\">\r" +
    "\n" +
    "                    <user-list ng-if=\"vm.userPaneSelected\"></user-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.activePane == 'Fees'\"\r" +
    "\n" +
    "                     class=\"no-top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <header>\r" +
    "\n" +
    "                            Shipping &amp; Processing\r" +
    "\n" +
    "                        </header>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.settings.expeditedProcessingFee\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                            aa-fg-class=\"fixed-width-md\" />\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <section>\r" +
    "\n" +
    "                        <auto-fee-list ng-if=\"vm.feesPaneSelected\"></auto-fee-list>\r" +
    "\n" +
    "                    </section>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'FollowUpStrategies'\">\r" +
    "\n" +
    "                    <follow-up-strategy-list ng-if=\"vm.followUpStrategiesPaneSelected\"></follow-up-strategy-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'EmailTemplates'\">\r" +
    "\n" +
    "                    <email-template-list ng-if=\"vm.emailTemplatesPaneSelected\"></email-template-list>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.activePane == 'ProductionCredit'\">\r" +
    "\n" +
    "                    <header>Rules</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.autoAssignProductionCreditOnFirstTermOnly\" \r" +
    "\n" +
    "                        aa-label=\"Auto assign production credit to only first term\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                        aa-fg-class=\"top-margin-big\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.productionCreditTermCutoffRule\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-label-strategy=\"inline\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <option value=\"TermExpirationDate\">Term Expiration Date</option>\r" +
    "\n" +
    "                        <option value=\"RenewalApplicationStarted\">Once Renewal Application Started</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.settings.productionCreditEarnedWhenRule\"\r" +
    "\n" +
    "                        aa-label=\"When is production credit considered to be earned\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        aa-label-strategy=\"inline\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <option value=\"WhenBilled\">When Billed</option>\r" +
    "\n" +
    "                        <option value=\"WhenFullPaymentReceived\">When Full Payment Received</option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </perfect-scrollbar>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"sticky-page-footer flex\">\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            class=\"left-margin-auto\"\r" +
    "\n" +
    "            aa-submit-form=\"vm.saveClick()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/states/carrierSpecific/adverseActions/adverseActions.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"searchForm\"\r" +
    "\n" +
    "    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.search\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search application...\" \r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"v-center small left-margin\" \r" +
    "\n" +
    "        uib-tooltip=\"Show advanced search options\" \r" +
    "\n" +
    "        ng-click=\"vm.showFiltersModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Advanced Search\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        uib-tooltip=\"Export CSV\"    \r" +
    "\n" +
    "        ng-click=\"vm.exportReport()\" \r" +
    "\n" +
    "        class=\"white v-center small left-margin-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        uib-tooltip=\"Export PDF\" \r" +
    "\n" +
    "        ng-click=\"vm.exportAdverseActionNotificationsPdf()\" \r" +
    "\n" +
    "        class=\"white v-center small left-margin\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <i class=\"fa fa-file-pdf\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<filter-tags\r" +
    "\n" +
    "    class=\"block top-margin\"\r" +
    "\n" +
    "    filters=\"vm.filterCollection\"\r" +
    "\n" +
    "    filter-removed=\"vm.removeFilter(filter)\"\r" +
    "\n" +
    "    all-filters-removed=\"vm.resetAllFilters()\">\r" +
    "\n" +
    "</filter-tags>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid \r" +
    "\n" +
    "    control-object=\"vm.boundGrid\" \r" +
    "\n" +
    "    total-records=\"vm.totalRecords\" \r" +
    "\n" +
    "    endpoint=\"Application\" \r" +
    "\n" +
    "    enable-paging=\"true\" \r" +
    "\n" +
    "    query=\"vm.reportQuery\" \r" +
    "\n" +
    "    aggregate-search=\"vm.search\" \r" +
    "\n" +
    "    class=\"small top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bound-column \r" +
    "\n" +
    "        header-text=\"Application #\"  \r" +
    "\n" +
    "        dataitem=\"applicationNumber\" \r" +
    "\n" +
    "        enable-sorting=\"false\">\r" +
    "\n" +
    "    </bound-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Agency\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"false\" \r" +
    "\n" +
    "        dataitem=\"customer.systemAccount.companyName\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"customer/systemAccount/companyName\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.customer.systemAccount.companyName}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Principal\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"true\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"nameOnBond\"  \r" +
    "\n" +
    "        filter-expression=\"nameOnBond\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.nameOnBond}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Submission Date/Time\"  \r" +
    "\n" +
    "        dataitem=\"createdDateTime\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"createdDateTime\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.submissionDateTime | date:'MMM d, y h:mm:ss a'}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Bond Amount\"  \r" +
    "\n" +
    "        dataitem=\"bondAmount\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"bondAmount\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.bondAmount | currency:'$'}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Bond Type\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        filter-expression=\"bondType/name\"  \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"bondType/name\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.bondType.name}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"\"\r" +
    "\n" +
    "        enable-sorting=\"false\" \r" +
    "\n" +
    "        css-class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            show-application-detail-modal=\"item.id\" \r" +
    "\n" +
    "            uib-tooltip=\"Application Details\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Details\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "</bound-grid>"
  );


  $templateCache.put('app/states/carrierSpecific/agencyAppointmentDetail/agencyAppointmentsDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"detailForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex bottom-margin-big text-left\">\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-if=\"vm.appointment.agencySystemAccountId\"\r" +
    "\n" +
    "            class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <system-account-logo \r" +
    "\n" +
    "                class=\"right-margin-big large v-center\"\r" +
    "\n" +
    "                system-account-id=\"vm.appointment.agencySystemAccountId\">\r" +
    "\n" +
    "            </system-account-logo>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"v-center\">\r" +
    "\n" +
    "                <h3 class=\"bottom-margin-thin no-top-margin\">\r" +
    "\n" +
    "                    {{vm.appointment.agency}}\r" +
    "\n" +
    "                </h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    ng-show=\"vm.appointment.mailAddress\"\r" +
    "\n" +
    "                    ng-bind-html=\"vm.appointment | mailingAddress:'two-line'\">\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div>{{vm.appointment.phone}}</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.appointment.isActive\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "            aa-fg-class=\"no-margin left-margin-auto bottom-margin-auto\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Agency\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"!vm.appointment.id\">\r" +
    "\n" +
    "            <select\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencySystemAccountId\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                aa-label=\"Agency\"\r" +
    "\n" +
    "                options=\"vm.agencyOptions\"\r" +
    "\n" +
    "                aa-option-text=\"companyName\"\r" +
    "\n" +
    "                aa-option-value=\"id\"\r" +
    "\n" +
    "                required>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencyCode\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.territory\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>Commission</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.defaultCommissionPercent\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.renewalCommissionPercent\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>Agency Permissions</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencyCanSetIrpm\"\r" +
    "\n" +
    "                aa-label=\"Agency Can Set IRPM\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                switch-class=\"left-margin-auto\"\r" +
    "\n" +
    "                aa-fg-class=\"small-switch col-sm-3\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencyCanAddQuote\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                switch-class=\"left-margin-auto\"\r" +
    "\n" +
    "                aa-fg-class=\"small-switch col-sm-3\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencyCanSetPoa\"\r" +
    "\n" +
    "                aa-label=\"Agency Can Set POA\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                switch-class=\"left-margin-auto\"\r" +
    "\n" +
    "                aa-fg-class=\"small-switch col-sm-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.appointment.agencyCanEditQuote\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                switch-class=\"left-margin-auto\"\r" +
    "\n" +
    "                aa-fg-class=\"small-switch col-sm-3\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ui-sref=\"main.agencyAppointments\"\r" +
    "\n" +
    "        class=\"white\">\r" +
    "\n" +
    "        Cancel\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        value=\"Save\" />\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/agencyAppointments/agencyAppointments.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"appointmentsTableForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        id=\"appointment-search-input\"\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search by agency\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>    \r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            uib-tooltip=\"Actions\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "            uib-dropdown-toggle\r" +
    "\n" +
    "            id=\"appointments-list-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Actions\r" +
    "\n" +
    "            <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewAppointment\"\r" +
    "\n" +
    "                    ui-sref=\"main.agencyAppointmentsDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Appointment\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<perfect-scrollbar\r" +
    "\n" +
    "    suppress-scroll-x=\"true\"\r" +
    "\n" +
    "    class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <table\r" +
    "\n" +
    "            class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"fit-content\">Status</th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Agency\"\r" +
    "\n" +
    "                            sort-expression=\"AgencySystemAccount.CompanyName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Agency Code\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"!item.isActive\"\r" +
    "\n" +
    "                            class=\"red\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Inactive\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"item.isActive\">\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <a ui-sref=\"main.agencyAppointmentsDetail({ id: item.id })\">\r" +
    "\n" +
    "                            {{item.agency}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>{{item.agencyCode}}</td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "        message=\"No appointments were found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/applications/carrierOpenApplications.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"bottom-margin flex\" \r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search Open Applications\"\r" +
    "\n" +
    "        uib-tooltip=\"Search Open Applications\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <carrier-open-applications-report-filter-dropdown\r" +
    "\n" +
    "        class=\"flex left-margin-auto\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        table=\"vm.table\">\r" +
    "\n" +
    "    </carrier-open-applications-report-filter-dropdown> \r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.exportOpenApplicationsToExcel()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Status\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.Status\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Application #\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.Id\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Agency\"\r" +
    "\n" +
    "                    sort-expression=\"AgencySystemAccounts.CompanyName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Principal\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.NameOnBond\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Submission Date/Time\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.SubmissionDateTime\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Amount\"\r" +
    "\n" +
    "                    sort-expression=\"Applications.BondAmount\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Type\"\r" +
    "\n" +
    "                    sort-expression=\"BondTypes.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th></th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"application in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">   \r" +
    "\n" +
    "                <span ng-bind-html=\"application | applicationStatus\"></span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                {{application.applicationNumber}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                {{application.agency}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                {{application.nameOnBond}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{application.submissionDateTime | localDateTime }}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{application.bondAmount | currency: \"$\"}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{application.bondType}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                 <button\r" +
    "\n" +
    "                    show-application-detail-modal=\"application.id\" \r" +
    "\n" +
    "                    uib-tooltip=\"Application Details\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                     Details\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/states/carrierSpecific/applications/carrierOpenApplicationsReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"invoiceReportFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\">Agency</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                       aa-field-group=\"vm.modifiedFilters.agencySystemAccountId\"\r" +
    "\n" +
    "                       aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                      options=\"vm.agencyOptions\"\r" +
    "\n" +
    "                      aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">State</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    id=\"address-physical-state\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.requisitioningState\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-unselected-text=\"All States\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>  \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"NotSubmitted\"> Not Submitted </option>\r" +
    "\n" +
    "                    <option value=\"Submitted\"> Submitted </option>\r" +
    "\n" +
    "                    <option value=\"Closed\"> Closed </option>\r" +
    "\n" +
    "                    <option value=\"Completed\"> Completed </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Bond Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Effective Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Sfaa code </label>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.sfaaCodeId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Submission Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startSubmissionDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endSubmissionDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/carrierSpecific/bondNumberDetail/bondNumberDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"mainForm\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"alert alert-success\"\r" +
    "\n" +
    "        ng-show=\"vm.integratedCarrier\">\r" +
    "\n" +
    "        <strong>Great News!</strong> A3 is integrated to your systems and can\r" +
    "\n" +
    "        automatically pull bond numbers through that integration. However, you\r" +
    "\n" +
    "        still must confiure at least one bond number group which will be used as\r" +
    "\n" +
    "        a backup in the event that an error occurs.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header class=\"flex\">\r" +
    "\n" +
    "            <span class=\"fill\">\r" +
    "\n" +
    "                Bond Number Configuration\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.isActive\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                aa-fg-class=\"v-center\" />\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"bond-number-detail-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.name\"\r" +
    "\n" +
    "                aa-fg-class=\"col-xs-6\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.prefix\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.nextNumber\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-6\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.suffix\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.bondNumberGroup.lastNumber\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-3 col-xs-6\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"white\"\r" +
    "\n" +
    "        ui-sref=\"main.bondNumbersGrid\">\r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "        value=\"Save\" />\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/bondNumbers/bondNumberGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"alert alert-success\"\r" +
    "\n" +
    "    ng-show=\"vm.integratedCarrier\">\r" +
    "\n" +
    "    <strong>Great News!</strong> A3 is integrated to your systems and can\r" +
    "\n" +
    "    automatically pull bond numbers through that integration. However, you still\r" +
    "\n" +
    "    must confiure at least one bond number group which will be used as a backup\r" +
    "\n" +
    "    in the event that an error occurs.\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"bondNumberGroupForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        id=\"bond-grid-search-input\"\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search bond number groups\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            uib-tooltip=\"Actions\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "            uib-dropdown-toggle\r" +
    "\n" +
    "            id=\"bond-number-list-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Actions\r" +
    "\n" +
    "            <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewBondNumberGroup\"\r" +
    "\n" +
    "                    ui-sref=\"main.bondNumbersDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Bond Number Group\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<perfect-scrollbar\r" +
    "\n" +
    "    suppress-scroll-x=\"true\"\r" +
    "\n" +
    "    class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-y=\"true\"\r" +
    "\n" +
    "        class=\"scroller no-right-padding\"\r" +
    "\n" +
    "        wheel-propagation=\"true\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "        <table \r" +
    "\n" +
    "            class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>Status</th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Name\"\r" +
    "\n" +
    "                            sort-expression=\"Name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Next Number\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Last Number\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"!item.isActive\"\r" +
    "\n" +
    "                            class=\"red\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Inactive\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"item.isActive\">\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <a ui-sref=\"main.bondNumbersDetail({ id: item.id })\">\r" +
    "\n" +
    "                            {{item.name}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{ item.prefix }}{{ item.nextNumber }}{{ item.suffix }}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span ng-show=\"item.lastNumber\">\r" +
    "\n" +
    "                            {{ item.prefix }}{{ item.lastNumber }}{{ item.suffix }}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "        message=\"No bond number groups were found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/carrierApi/carrierApiConfigurationPage.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"pageForm\">\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "            <h4 class=\"no-top-margin\">Authentication Token</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-show=\"vm.hasActiveAuthenticationToken\"\r" +
    "\n" +
    "                ng-click=\"vm.promptToDeactivateToken()\"\r" +
    "\n" +
    "                class=\"left-margin-auto mini v-center\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                Deactiviate Token\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-show=\"vm.hasActiveAuthenticationToken\"\r" +
    "\n" +
    "                ng-click=\"vm.promptToRegenerateToken()\"\r" +
    "\n" +
    "                class=\"left-margin mini v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Generate New Token\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"!vm.hasActiveAuthenticationToken\"\r" +
    "\n" +
    "            class=\"text-center\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            No authentication token active\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    ng-click=\"vm.createAuthenticationToken()\"\r" +
    "\n" +
    "                    class=\"small top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Create Token\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.newToken\">\r" +
    "\n" +
    "            This is the new token that has been created. This is the only time that this token will be visible. \r" +
    "\n" +
    "            In order to view a token in the future, you must regenerate the token which will void this one.\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <b>{{vm.newToken}}</b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"flex\" \r" +
    "\n" +
    "            ng-show=\"vm.hasActiveAuthenticationToken && !vm.newToken\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"text-large green fa fa-check v-center\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"left-margin fill v-center\">\r" +
    "\n" +
    "                There is an active api authentication token. You can only view tokens at the time they are created.\r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "                Token created: <i>{{vm.configuration.apiTokenCreatedDateTime | localDateTime}}</i>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <h4 class=\"no-top-margin\">Options</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-fg-class=\"top-margin-big\"\r" +
    "\n" +
    "            aa-field-group=\"vm.configuration.transactionApiEnabled\"\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "            aa-label=\"Bond Transaction Api Enabled\" />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <h4 class=\"no-top-margin\">Firewall</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Only requests from the following IP addresses will be allowed\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"vm.invalidIpAddress\" \r" +
    "\n" +
    "            class=\"alert alert-danger top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            IP Address is not valid\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex top-margin-big bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-fg-class=\"v-center fixed-width-md\"\r" +
    "\n" +
    "                aa-field-group=\"vm.newIpAddress\" \r" +
    "\n" +
    "                aa-label=\"IP Address\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.addApprovedIpAddress()\"\r" +
    "\n" +
    "                class=\"mini left-margin right-margin-auto v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Approve IP Address\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.configuration.approvedIpAddresses.length\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>Approved IP Address</th>\r" +
    "\n" +
    "                    <th>&nbsp;</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>                    \r" +
    "\n" +
    "                <tr \r" +
    "\n" +
    "                    ng-class=\"{'line-through': ipAddress.isRemoved}\"\r" +
    "\n" +
    "                    ng-repeat=\"ipAddress in vm.configuration.approvedIpAddresses\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{ipAddress.ipAddress}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.removeIpAddress(ipAddress, $index)\">\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"!vm.configuration.approvedIpAddresses.length\"\r" +
    "\n" +
    "            message=\"No approved Ip addresses have been specified. Only approved IP addresses can authenticate to the api.\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            class=\"left-margin-auto\"\r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            aa-submit-form=\"vm.saveConfiguration()\" \r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/carrierSpecific/carrierCompanyProfile/carrierProfile.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<section class=\"above\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"row\">\r" +
    "\n" +
    "        <div class=\"col-md-10\">\r" +
    "\n" +
    "            <h2>{{vm.carrier.companyName}}</h2>\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <div ng-bind-html=\"vm.carrier | physicalAddress:'two-line'\" class=\"col-sm-6 col-md-4 col-lg-3\"></div>\r" +
    "\n" +
    "                <div class=\"col-sm-6 col-md-8 col-lg-9\">\r" +
    "\n" +
    "                    <div ng-show=\"vm.carrier.websiteURL\">\r" +
    "\n" +
    "                        <strong>Website:</strong> \r" +
    "\n" +
    "                        <a ng-href=\"{{ vm.carrier.websiteURL }}\" \r" +
    "\n" +
    "                            target=\"_blank\">{{ vm.carrier.websiteURL }}\r" +
    "\n" +
    "                       </a>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <strong>Active Since:</strong> \r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{ vm.carrier.createdDateTime | date:'MM/d/yyyy' }}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "</section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<uib-tabset>\r" +
    "\n" +
    "    <uib-tab heading=\"Company Information\">\r" +
    "\n" +
    "        <form novalidate name=\"accountForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <i class=\"fa fa-info-circle\"></i> \r" +
    "\n" +
    "                    Company Information\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.companyName\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        maxlength=\"1000\" required /> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.legalName\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                        maxlength=\"500\" \r" +
    "\n" +
    "                        required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.fein\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        maxlength=\"50\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select aa-field-group=\"vm.carrier.stateOfIncorporation\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" required>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.phone\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        maxlength=\"15\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"phone\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.email\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        maxlength=\"1000\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"email\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    Company Logos\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <div class=\"fill right-margin v-center\">\r" +
    "\n" +
    "                        <div class=\"left-margin-auto right-margin-auto width-300\"> \r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"!vm.lgLogo\"\r" +
    "\n" +
    "                                class=\"text-center bottom-margin\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <img \r" +
    "\n" +
    "                                    ng-src=\"{{ vm.lgLogoPicturePath }}\" \r" +
    "\n" +
    "                                    alt=\"{{ vm.settings.companyName }}\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <uploader\r" +
    "\n" +
    "                                show-upload-modal=\"false\"\r" +
    "\n" +
    "                                controls=\"vm.largeLogoUploader\"\r" +
    "\n" +
    "                                accepted-types=\"images\"\r" +
    "\n" +
    "                                files=\"vm.largeLogoFiles\">\r" +
    "\n" +
    "                            </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <main \r" +
    "\n" +
    "                                class=\"top-margin\"\r" +
    "\n" +
    "                                ng-hide=\"!vm.lgLogo\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <h5 class=\"flex\">\r" +
    "\n" +
    "                                    New file attached\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        ng-click=\"vm.removeLargeLogoUpload()\"\r" +
    "\n" +
    "                                        class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </h5>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <p>\r" +
    "\n" +
    "                                    Filename:\r" +
    "\n" +
    "                                    <strong>{{vm.lgLogo.name}}</strong>\r" +
    "\n" +
    "                                </p>\r" +
    "\n" +
    "                            </main>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill left-margin v-center\">\r" +
    "\n" +
    "                        <div class=\"left-margin-auto right-margin-auto width-300\">\r" +
    "\n" +
    "                            <div \r" +
    "\n" +
    "                                ng-show=\"!vm.smLogo\"\r" +
    "\n" +
    "                                class=\"text-center bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <img \r" +
    "\n" +
    "                                    ng-src=\"{{ vm.smLogoPicturePath }}\" \r" +
    "\n" +
    "                                    alt=\"{{ vm.settings.companyName }}\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <uploader\r" +
    "\n" +
    "                                show-upload-modal=\"false\"\r" +
    "\n" +
    "                                controls=\"vm.smallLogoUploader\"\r" +
    "\n" +
    "                                accepted-types=\"images\"\r" +
    "\n" +
    "                                files=\"vm.smallLogoFiles\">\r" +
    "\n" +
    "                            </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <main \r" +
    "\n" +
    "                                class=\"top-margin\"\r" +
    "\n" +
    "                                ng-hide=\"!vm.smLogo\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <h5 class=\"flex\">\r" +
    "\n" +
    "                                    New file attached\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <a \r" +
    "\n" +
    "                                        ng-click=\"vm.removeSmallLogoUpload()\"\r" +
    "\n" +
    "                                        class=\"left-margin small v-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                    </a>\r" +
    "\n" +
    "                                </h5>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <p>\r" +
    "\n" +
    "                                    Filename:\r" +
    "\n" +
    "                                    <strong>{{vm.smLogo.name}}</strong>\r" +
    "\n" +
    "                                </p>\r" +
    "\n" +
    "                            </main>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <i class=\"fa fa-building\"></i> Address\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                <address object=\"vm.carrier\" required></address>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <h5>Invoice Payment Information \r" +
    "\n" +
    "                                <help message=\"This information will be displayed at the bottom of invoices created for you in A3\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                </help>\r" +
    "\n" +
    "                        </h5>\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMailAddress\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-6\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMailSuiteAptNumber\"\r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-6\"  />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMailCity\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select aa-field-group=\"vm.carrier.paymentMailState\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-2\">\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMailZip\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMailCounty\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentMadeOutToName\"  \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-5\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentEmail\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-3\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.paymentPhone\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-4\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-sm-6\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    Claims Information\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <h5>Contact</h5>\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.claimsPhone\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\" \r" +
    "\n" +
    "                            maxlength=\"20\" required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.claimsFax\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"phone\"\r" +
    "\n" +
    "                            maxlength=\"20\" required/>\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.claimsEmail\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"email\" \r" +
    "\n" +
    "                            maxlength=\"250\" required/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"col-sm-6\">\r" +
    "\n" +
    "                        <h5>Mailing Address</h5>\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.claimsMailAddress\" required />\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.claimsMailSuiteAptNumber\" />\r" +
    "\n" +
    "                        <div class=\"row\">\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.claimsMailZip\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"zip\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-6\" required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.carrier.claimsMailCity\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-6\" required />\r" +
    "\n" +
    "                            <select aa-field-group=\"vm.carrier.claimsMailState\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"state\" \r" +
    "\n" +
    "                                aa-fg-class=\"col-xs-12\" required>\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <i class=\"fa fa-user\"></i> Billing Contact\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                Please provide the contact info for the primary individual that we should reach out to for our billing purposes.\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.billingContact\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        maxlength=\"500\" required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.billingPhone\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\"\r" +
    "\n" +
    "                        maxlength=\"500\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"phone\" required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.billingEmail\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        maxlength=\"125\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"email\" required />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <i class=\"fa fa-info-circle \"></i> Additional Information\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-sm-12\">\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.carrier.websiteURL\" required/>\r" +
    "\n" +
    "                        <textarea aa-field-group=\"vm.carrier.description\" \r" +
    "\n" +
    "                            aa-label=\"Why choose your company?\">\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input type=\"submit\" \r" +
    "\n" +
    "                aa-submit-form=\"vm.save_click()\" \r" +
    "\n" +
    "                value=\"Save\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "    <!-- /COMPANY INFORMATION -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tab heading=\"Writing Companies\" \r" +
    "\n" +
    "        deselect=\"vm.cancelWritingCompany()\">\r" +
    "\n" +
    "        <section class=\"above text-right\">\r" +
    "\n" +
    "            <button ng-click=\"vm.showWritingCompanyModal()\">\r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                <span>New Writing Company</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section ng-show=\"!vm.showWritingCompanyForm\">\r" +
    "\n" +
    "            <table ng-show=\"vm.carrier.writingCompanies.length\">\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>Writing Companies</th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody>\r" +
    "\n" +
    "                    <tr ng-repeat=\"company in vm.carrier.writingCompanies\">\r" +
    "\n" +
    "                        <td>{{ company.name }}</td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "                            <button ng-click=\"vm.showWritingCompanyModal(company, $index)\">\r" +
    "\n" +
    "                                <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                            <button ng-click=\"vm.deleteWritingCompany(company, $index)\">\r" +
    "\n" +
    "                                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "            <!-- if zero records -->\r" +
    "\n" +
    "            <div class=\"initial-state\" \r" +
    "\n" +
    "                ng-show=\"!vm.carrier.writingCompanies.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"empty-backdrop\">\r" +
    "\n" +
    "                    <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "                    <table>\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th>&nbsp;</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"initial-state-body\">\r" +
    "\n" +
    "                    <div class=\"icon\">\r" +
    "\n" +
    "                        <i class=\"fa fa-inbox\"></i>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <p>There aren't any writing companies added to your account, yet.</p>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "    <!-- /WRITING COMPANIES -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tab heading=\"System Settings\">\r" +
    "\n" +
    "        <form name=\"systemSettingsForm\" novalidate>\r" +
    "\n" +
    "        <section class=\"ng-hide\">\r" +
    "\n" +
    "            <!-- Todo -->\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                <b>Multiple Quote Display Rule</b>\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <select aa-field-group=\"vm.carrier.multipleQuoteDisplayRule\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\">\r" +
    "\n" +
    "                    <option value=\"WritingCompanyWithHighestPriorityThenCheapest\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Writing company with highest priority, then cheapest\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"WritingCompanyWithHighestPriorityThenMostExpensive\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Writing company with highest priority, then most expensive\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"Cheapest\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Cheapest\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"MostExpensive\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Most expensive\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                    <option value=\"ShowAll\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Show all\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <header>\r" +
    "\n" +
    "                <b>New Business Reason Code</b>\r" +
    "\n" +
    "            </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                <input aa-field-group=\"vm.carrier.newBusinessReasonCode\"\r" +
    "\n" +
    "                     aa-label=\"New Business Code\"\r" +
    "\n" +
    "                     aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                     required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input aa-field-group=\"vm.carrier.newBusinessReasonDescription\" \r" +
    "\n" +
    "                    aa-label=\"New Business Code Description\" \r" +
    "\n" +
    "                    aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                    required/>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <b>Renewal Reason Code</b>\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.renewalReasonCode\" \r" +
    "\n" +
    "                        aa-label=\"Renewal Code\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.carrier.renewalReasonDescription\"\r" +
    "\n" +
    "                        aa-label=\"Renewal Code Description\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" \r" +
    "\n" +
    "                        required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <b>Cancellation Reason Codes</b>\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.cancellationReason.code\" \r" +
    "\n" +
    "                        aa-label=\"Cancellation Code\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.cancellationReason.description\" \r" +
    "\n" +
    "                        aa-label=\"Code Description\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-2 col-xs-12\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.addCancellationReason()\" \r" +
    "\n" +
    "                            class=\"col-xs-12\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Add\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table ng-show=\"vm.carrier.cancellationReasons.length\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>Code</th>\r" +
    "\n" +
    "                        <th>Description</th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "                    <tbody>\r" +
    "\n" +
    "                    <tr ng-repeat=\"reason in vm.carrier.cancellationReasons\">\r" +
    "\n" +
    "                        <td>{{reason.code}}</td>\r" +
    "\n" +
    "                        <td>{{reason.description}}</td>\r" +
    "\n" +
    "                        <td class=\"text-right\"><button ng-click=\"vm.removeCancellationReason($index)\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Remove\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    </tbody>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "                <!-- if zero rocords -->\r" +
    "\n" +
    "                <empty-state \r" +
    "\n" +
    "                    ng-show=\"vm.activityHistoryTable.isEmptyStateVisible\"\r" +
    "\n" +
    "                    message=\"No history entries found\">\r" +
    "\n" +
    "                </empty-state>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <b>Rider Reason Codes</b>\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.riderReason.code\" \r" +
    "\n" +
    "                        aa-label=\"Reason Code\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\"/>\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.riderReason.description\" \r" +
    "\n" +
    "                        aa-label=\"Code Description\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\"/>\r" +
    "\n" +
    "                    <div class=\"col-sm-2 col-xs-12\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.addRiderReason()\" \r" +
    "\n" +
    "                            class=\"col-xs-12\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Add\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table ng-show=\"vm.carrier.riderReasons.length\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>Code</th>\r" +
    "\n" +
    "                        <th>Description</th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "                    <tbody>\r" +
    "\n" +
    "                    <tr ng-repeat=\"reason in vm.carrier.riderReasons\">\r" +
    "\n" +
    "                        <td>{{reason.code}}</td>\r" +
    "\n" +
    "                        <td>{{reason.description}}</td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "                            <button ng-click=\"vm.removeRiderReason($index)\">\r" +
    "\n" +
    "                                Remove\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    </tbody>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "                <!-- if zero rocords -->\r" +
    "\n" +
    "                <div class=\"initial-state\" ng-show=\"!vm.carrier.riderReasons.length\">\r" +
    "\n" +
    "                    <div class=\"empty-backdrop\">\r" +
    "\n" +
    "                        <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "                        <table>\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th>&nbsp;</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tbody>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            </tbody>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <div class=\"icon\">\r" +
    "\n" +
    "                            <i class=\"fa fa-inbox\"></i>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <p>Example help text. Something to suggest to the user what this is for.</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    <b>Reinstatement Reason Codes</b>\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.reinstatementReason.code\" \r" +
    "\n" +
    "                        aa-label=\"Reason Code\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input aa-field-group=\"vm.reinstatementReason.description\" \r" +
    "\n" +
    "                        aa-label=\"Code Description\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-5\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-sm-2 col-xs-12\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.addReinstatementReason()\"\r" +
    "\n" +
    "                        class=\"col-xs-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                         Add\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table ng-show=\"vm.carrier.reinstatementReasons.length\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                        <tr>\r" +
    "\n" +
    "                            <th>Code</th>\r" +
    "\n" +
    "                            <th>Description</th>\r" +
    "\n" +
    "                            <th>&nbsp;</th>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "                    <tbody>\r" +
    "\n" +
    "                        <tr ng-repeat=\"reason in vm.carrier.reinstatementReasons\">\r" +
    "\n" +
    "                            <td>{{reason.code}}</td>\r" +
    "\n" +
    "                            <td>{{reason.description}}</td>\r" +
    "\n" +
    "                            <td class=\"text-right\">\r" +
    "\n" +
    "                                <button ng-click=\"vm.removeReinstatementReason($index)\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Remove\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </tbody>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "                <!-- if zero rocords -->\r" +
    "\n" +
    "                <div class=\"initial-state\" \r" +
    "\n" +
    "                    ng-show=\"!vm.carrier.reinstatementReasons.length\">\r" +
    "\n" +
    "                    <div class=\"empty-backdrop\">\r" +
    "\n" +
    "                        <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "                        <table>\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th>&nbsp;</th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            <tbody>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <td>&nbsp;</td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </tbody>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"initial-state-body\">\r" +
    "\n" +
    "                        <div class=\"icon\">\r" +
    "\n" +
    "                            <i class=\"fa fa-inbox\"></i>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <p>Example help text. Something to suggest to the user what this is for.</p>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input type=\"submit\" \r" +
    "\n" +
    "                value=\"Save\" \r" +
    "\n" +
    "                aa-submit-form=\"vm.saveSystemSettings()\"/>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "    <!-- /SYSTEM SETTINGS-->\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tab heading=\"Contacts\" \r" +
    "\n" +
    "        deselect=\"vm.cancelContact()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section class=\"above text-right\">\r" +
    "\n" +
    "            <button ng-click=\"vm.showContactModal()\">\r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i> \r" +
    "\n" +
    "                <span>\r" +
    "\n" +
    "                    New Contact\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <section>\r" +
    "\n" +
    "            <table ng-show=\"vm.carrier.systemAccountContacts.length\">\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th><i class=\"fa fa-user\"></i>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Contacts\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "                <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"contact in vm.carrier.systemAccountContacts\">\r" +
    "\n" +
    "                    <td>{{ contact.name }}</td>\r" +
    "\n" +
    "                    <td class=\"text-right\">\r" +
    "\n" +
    "                        <button ng-click=\"vm.showContactModal(contact, $index)\">\r" +
    "\n" +
    "                            <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                        <button ng-click=\"vm.deleteContact(contact, $index)\">\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "            <!-- if zero rocords -->\r" +
    "\n" +
    "            <div class=\"initial-state\" \r" +
    "\n" +
    "                ng-show=\"!vm.carrier.systemAccountContacts.length\">\r" +
    "\n" +
    "                <div class=\"empty-backdrop\">\r" +
    "\n" +
    "                    <div class=\"empty-backdrop-grad\"></div>\r" +
    "\n" +
    "                    <table>\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th>&nbsp;</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td>&nbsp;</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"initial-state-body\">\r" +
    "\n" +
    "                    <div class=\"icon\">\r" +
    "\n" +
    "                        <i class=\"fa fa-inbox\"></i>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <p>There aren't any contacts added to your account, yet.</p>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "    <!-- /CONTACTS -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tab heading=\"Indemnity\">\r" +
    "\n" +
    "        <form name=\"indemnityForm\">\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Indemnity language</header>\r" +
    "\n" +
    "                <text-angular \r" +
    "\n" +
    "                    name=\"htmlcontent\" \r" +
    "\n" +
    "                    ng-model=\"vm.carrier.indemnityLanguage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                </text-angular>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            <input type=\"submit\" \r" +
    "\n" +
    "                value=\"Save\" \r" +
    "\n" +
    "                aa-submit-form=\"vm.saveIndemnity()\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <uib-tab heading=\"Documents\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>Adverse Action Notice</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <document-picker document-filter=\"Any\"\r" +
    "\n" +
    "                                     control=\"vm.adverseActionNotificationPicker\"\r" +
    "\n" +
    "                                     documents=\"vm.carrierAdverseActionDocuments\"\r" +
    "\n" +
    "                                     show-status=\"false\"\r" +
    "\n" +
    "                                     allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                     files-to-upload=\"vm.adverseActionFilesToUpload\"\r" +
    "\n" +
    "                                     on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                     on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                     document-category=\"vm.adverseActionNotificationDocumentCategory\">\r" +
    "\n" +
    "                    </document-picker>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>Continuation Certificate</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <document-picker document-filter=\"Any\"\r" +
    "\n" +
    "                                     control=\"vm.continuationCertificatePicker\"\r" +
    "\n" +
    "                                     documents=\"vm.carrierContinuationCertificateDocuments\"\r" +
    "\n" +
    "                                     show-status=\"false\"\r" +
    "\n" +
    "                                     allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                     files-to-upload=\"vm.continuationCertificatesToUpload\"\r" +
    "\n" +
    "                                     on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                     on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                     document-category=\"vm.continuationCertificateDocumentCategory\">\r" +
    "\n" +
    "                    </document-picker>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>Verification Certificate</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <document-picker document-filter=\"Any\"\r" +
    "\n" +
    "                                     control=\"vm.verificationCertificatePicker\"\r" +
    "\n" +
    "                                     documents=\"vm.carrierVerificationCertificateDocuments\"\r" +
    "\n" +
    "                                     show-status=\"false\"\r" +
    "\n" +
    "                                     allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                     files-to-upload=\"vm.verificationCertificatesToUpload\"\r" +
    "\n" +
    "                                     on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                     on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                     document-category=\"vm.verificationCertificateDocumentCategory\">\r" +
    "\n" +
    "                    </document-picker>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button ng-click=\"vm.saveDocuments()\">\r" +
    "\n" +
    "            Save\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </uib-tab>\r" +
    "\n" +
    "</uib-tabset>"
  );


  $templateCache.put('app/states/carrierSpecific/declinedApplications/declinedApplications.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"searchForm\"\r" +
    "\n" +
    "    class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.search\" \r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search applications...\" \r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\" \r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"v-center small left-margin\"\r" +
    "\n" +
    "        uib-tooltip=\"Show advanced search options\" \r" +
    "\n" +
    "        ng-click=\"vm.showFiltersModal()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Advanced Search\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        uib-tooltip=\"Export CSV\" \r" +
    "\n" +
    "        ng-click=\"vm.exportReport()\" \r" +
    "\n" +
    "        class=\"white v-center left-margin-auto small\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<filter-tags\r" +
    "\n" +
    "    class=\"block top-margin\"\r" +
    "\n" +
    "    filters=\"vm.filterCollection\"\r" +
    "\n" +
    "    filter-removed=\"vm.removeFilter(filter)\"\r" +
    "\n" +
    "    all-filters-removed=\"vm.resetAllFilters()\">\r" +
    "\n" +
    "</filter-tags>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid \r" +
    "\n" +
    "    control-object=\"vm.boundGrid\" \r" +
    "\n" +
    "    endpoint=\"Application\" \r" +
    "\n" +
    "    enable-paging=\"true\" \r" +
    "\n" +
    "    query=\"vm.reportQuery\" \r" +
    "\n" +
    "    aggregate-search=\"vm.search\" \r" +
    "\n" +
    "    class=\"small top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bound-column \r" +
    "\n" +
    "        header-text=\"Application #\"  \r" +
    "\n" +
    "        dataitem=\"applicationNumber\" \r" +
    "\n" +
    "        enable-sorting=\"false\">\r" +
    "\n" +
    "    </bound-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Agency\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"false\" \r" +
    "\n" +
    "        dataitem=\"customer.systemAccount.companyName\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"customer/systemAccount/companyName\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.customer.systemAccount.companyName}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Principal\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"true\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"nameOnBond\"  \r" +
    "\n" +
    "        filter-expression=\"nameOnBond\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.nameOnBond}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Submission Date/Time\"  \r" +
    "\n" +
    "        dataitem=\"submissionDateTime\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"submissionDateTime\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.submissionDateTime | date:'MMM d, y h:mm:ss a'}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Bond Amount\"  \r" +
    "\n" +
    "        dataitem=\"bondAmount\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"bondAmount\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.bondAmount | currency:'$'}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Bond Type\" \r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        filter-expression=\"bondType/name\"  \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"bondType/name\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.bondType.name}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"\" \r" +
    "\n" +
    "        enable-sorting=\"false\" \r" +
    "\n" +
    "        css-class=\"text-right nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            show-application-detail-modal=\"item.id\" \r" +
    "\n" +
    "            uib-tooltip=\"Application Details\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Details\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "</bound-grid>"
  );


  $templateCache.put('app/states/carrierSpecific/rateDetail/rateDetail.html',
    "<rate-form\r" +
    "\n" +
    "    on-save=\"vm.saved(rate)\"\r" +
    "\n" +
    "    on-cancel=\"vm.canceled()\"\r" +
    "\n" +
    "    id=\"vm.id\">\r" +
    "\n" +
    "</rate-form>"
  );


  $templateCache.put('app/states/carrierSpecific/rates/rateGrid.html',
    "<div\r" +
    "\n" +
    "    ng-form=\"searchForm\"\r" +
    "\n" +
    "    class=\"bottom-margin flex\">\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        id=\"rate-grid-search-input\"\r" +
    "\n" +
    "        aa-field-group=\"search\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search for rates by name...\"\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        id=\"rate-grid-new-record-button\"\r" +
    "\n" +
    "        class=\"left-margin-auto small v-center\"\r" +
    "\n" +
    "        ui-sref=\"main.ratesDetail\"\r" +
    "\n" +
    "        uib-tooltip=\"Add new rate\">\r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        Add New Rate\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid\r" +
    "\n" +
    "    id=\"rate-grid\"\r" +
    "\n" +
    "    endpoint=\"Rate\"\r" +
    "\n" +
    "    query=\"rateQuery\"\r" +
    "\n" +
    "    enable-paging=\"true\"\r" +
    "\n" +
    "    aggregate-search=\"search\">\r" +
    "\n" +
    "    <template-column\r" +
    "\n" +
    "        header-text=\"Rate Name\"\r" +
    "\n" +
    "        enable-sorting=\"true\"\r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        sort-on=\"name\"\r" +
    "\n" +
    "        filter-expression=\"name\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            id=\"rate-grid-edit-link-{{item.name | nospace}}\"\r" +
    "\n" +
    "            ui-sref=\"main.ratesDetail({ id: item.id })\">\r" +
    "\n" +
    "            {{item.name}}\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bound-column\r" +
    "\n" +
    "        header-text=\"Comments\"\r" +
    "\n" +
    "        dataitem=\"comments\"\r" +
    "\n" +
    "        enable-aggregate-filter=\"true\">\r" +
    "\n" +
    "    </bound-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button-column\r" +
    "\n" +
    "        show-edit-button=\"false\"\r" +
    "\n" +
    "        show-delete-button=\"true\"\r" +
    "\n" +
    "        show-audit-button=\"false\">\r" +
    "\n" +
    "    </button-column>\r" +
    "\n" +
    "</bound-grid>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/requiredFieldSetDetail/requiredFieldSetDetail.html',
    "<div busy-indicator=\"{ promise: vm.processingPromise }\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"mainForm\">\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"requiredfield-set-detail-name\"\r" +
    "\n" +
    "                aa-field-group=\"vm.item.name\"\r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\"\r" +
    "\n" +
    "                required\r" +
    "\n" +
    "                focus-me />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personFirstName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMiddleName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personLastName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSSN\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personDateOfBirth\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personDriversLicenseNumber\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personDriversLicenseState\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personHomePhone\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personCellPhone\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personFax\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personEmail\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personGender\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personPhysicalAddress\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personPhysicalState\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personPhysicalCity\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personPhysicalZip\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personPhysicalCounty\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMailAddress\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMailState\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMailZip\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMailCity\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMailCounty\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personMaritalStatus\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseFirstName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseMiddleName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-fg-class=\"field-wrapper \" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseLastName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseSsn\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseEmail\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseCellPhone\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseDateOfBirth\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personSpouseGender\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personResidenceType\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personDateMovedToResidence\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.personYearsExperience\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyName\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyDBA\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyType\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhone\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyFax\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhysicalAddress\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhysicalState\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhysicalCity\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhysicalZip\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyPhysicalCounty\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyMailAddress\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyMailState\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyMailZip\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyMailCounty\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyMailCity\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-3\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyDateFormed\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyNumberOfOwners\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyStateOfIncorporation\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyFEIN\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.item.companyNumberOfEmployees\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"white\"\r" +
    "\n" +
    "        ui-sref=\"main.requiredFieldSetGrid\">\r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "        value=\"Save\" />\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/requiredFieldSets/requiredFieldSetGrid.html',
    "<div\r" +
    "\n" +
    "    ng-form=\"searchForm\"\r" +
    "\n" +
    "    class=\"bottom-margin flex\">\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        id=\"requiredfield-grid-search-input\"\r" +
    "\n" +
    "        aa-field-group=\"search\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-label=\"Search for required field sets by name...\"\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        id=\"requiredfield-grid-new-record-button\"\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        ui-sref=\"main.requiredFieldSetDetail\"\r" +
    "\n" +
    "        uib-tooltip=\"Add new required field set\">\r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        Add New Required Field Set\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid\r" +
    "\n" +
    "    id=\"required-field-grid\"\r" +
    "\n" +
    "    endpoint=\"RequiredFieldSet\"\r" +
    "\n" +
    "    query=\"vm.requiredFieldSetQuery\"\r" +
    "\n" +
    "    enable-paging=\"true\"\r" +
    "\n" +
    "    aggregate-search=\"search\">\r" +
    "\n" +
    "    <template-column\r" +
    "\n" +
    "        header-text=\"Required Field Set Name\"\r" +
    "\n" +
    "        dataitem=\"name\"\r" +
    "\n" +
    "        enable-sorting=\"true\"\r" +
    "\n" +
    "        sort-on=\"name\"\r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        filter-expression=\"name\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            sorting=\"{{item.name}}\"\r" +
    "\n" +
    "            id=\"requiredfield-grid-edit-link-{{item.name | nospace}}\"\r" +
    "\n" +
    "            ui-sref=\"main.requiredFieldSetDetail({ id: item.id })\">\r" +
    "\n" +
    "            {{item.name}}\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bound-column\r" +
    "\n" +
    "        header-text=\"Comments\"\r" +
    "\n" +
    "        dataitem=\"comments\">\r" +
    "\n" +
    "    </bound-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button-column\r" +
    "\n" +
    "        show-edit-button=\"false\"\r" +
    "\n" +
    "        show-delete-button=\"true\"\r" +
    "\n" +
    "        show-audit-button=\"false\">\r" +
    "\n" +
    "    </button-column>\r" +
    "\n" +
    "</bound-grid>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/underwritingConfigurationDetail/underwritingConfigurationDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form\r" +
    "\n" +
    "    name=\"detailForm\"\r" +
    "\n" +
    "    novalidate>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "        <header> Underwriting Configuration </header>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body\">\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <h3\r" +
    "\n" +
    "                class=\"no-top-margin bottom-margin-big right-margin-auto\"\r" +
    "\n" +
    "                ng-show=\"vm.config.id\">\r" +
    "\n" +
    "                {{vm.config.bondTypeName}}\r" +
    "\n" +
    "            </h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.config.isActive\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                aa-fg-class=\"no-margin bottom-margin-auto\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <uib-tabset>\r" +
    "\n" +
    "            <uib-tab heading=\"Configuration\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>General Information</header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <ui-select\r" +
    "\n" +
    "                        ng-model=\"vm.selectedBondType\"\r" +
    "\n" +
    "                        ng-if=\"!vm.config.id\"\r" +
    "\n" +
    "                        ng-init=\"$select.label = 'Search Bond Types'\"\r" +
    "\n" +
    "                        reset-search-input=\"false\"\r" +
    "\n" +
    "                        name=\"bondType\"\r" +
    "\n" +
    "                        ng-change=\"vm.bondTypeSelected()\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        theme=\"app/components/uiSelect/a3\"\r" +
    "\n" +
    "                        aa-label=\"Search Bond Types\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-match\r" +
    "\n" +
    "                            placeholder=\"Search by for bond type...\"\r" +
    "\n" +
    "                            label=\"Bond Type\">\r" +
    "\n" +
    "                            {{$select.selected.name}}\r" +
    "\n" +
    "                        </ui-select-match>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <ui-select-choices\r" +
    "\n" +
    "                            repeat=\"bondType in vm.bondTypeSearch.matches track by $index\"\r" +
    "\n" +
    "                            refresh=\"vm.searchBondTypes($select.search)\"\r" +
    "\n" +
    "                            refresh-delay=\"100\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-bind-html=\"bondType.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                        </ui-select-choices>\r" +
    "\n" +
    "                    </ui-select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div class=\"flex gap\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.writingCompanyId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            aa-option-text=\"name\"\r" +
    "\n" +
    "                            aa-option-value=\"id\"\r" +
    "\n" +
    "                            options=\"vm.writingCompanies\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.bondNumberGroupId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            options=\"vm.bondNumberGroupOptions\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.requiredFieldSetId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            options=\"vm.requiredFieldSets\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.term\"\r" +
    "\n" +
    "                            aa-label=\"Term (yrs)\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            required\r" +
    "\n" +
    "                            min=\"1\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>Statuses</header>\r" +
    "\n" +
    "                    <p>\r" +
    "\n" +
    "                        These indicate the status of the quote created automatically\r" +
    "\n" +
    "                        when an application is submitted. The\r" +
    "\n" +
    "                        <em>Pass</em> status is used when all underwriting rules pass\r" +
    "\n" +
    "                        otherwise the <em>Fail</em> status is applied.\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div class=\"flex gap\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.passStatus\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            options=\"vm.quoteStatusOptions\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.failStatus\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            options=\"vm.quoteStatusOptions\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>Min/Max Bond Amount</header>\r" +
    "\n" +
    "                    <div class=\"flex gap\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.minimumBondAmount\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            compare-float=\"vm.config.maximumBondAmount\"\r" +
    "\n" +
    "                            comparison-type=\"lessThanOrEqual\"/>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.maximumBondAmount\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            compare-float=\"vm.config.minimumBondAmount\"\r" +
    "\n" +
    "                            comparison-type=\"greaterThanOrEqual\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>About the quote</header>\r" +
    "\n" +
    "                    <p>\r" +
    "\n" +
    "                        This is used to describe any additional coverage or products\r" +
    "\n" +
    "                        that are included with the quote that's generated from this\r" +
    "\n" +
    "                        underwriting configuration\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <textarea\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.highlightComments\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            aa-label=\"Also Includes\"></textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Underwriting\">\r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.indemnityRequired}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Indemnity\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.indemnityRequired\"\r" +
    "\n" +
    "                            aa-label=\"Requires Indemnity\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"flex\" \r" +
    "\n" +
    "                        ng-show=\"vm.indemnityRequired\"> \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.indemnityRequirements\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <option value=\"None\">Not Required</option>\r" +
    "\n" +
    "                            <option value=\"ApplicantOnly\">Applicant Only</option>\r" +
    "\n" +
    "                            <option value=\"ApplicantSpouse\">Applicant &amp; Spouse</option>\r" +
    "\n" +
    "                            <option value=\"ApplicantSpouseCorporate\">Applicant, Spouse & Corporate</option>\r" +
    "\n" +
    "                            <option value=\"ApplicantCorporate\">Applicant & Corporate</option>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "             \r" +
    "\n" +
    "                <section ng-class=\"{inactive: !vm.config.requiresCreditReport}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Credit Requirement\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.requiresCreditReport\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"flex gap\" \r" +
    "\n" +
    "                        ng-show=\"vm.config.requiresCreditReport\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.minimumVantage\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            maxlength=\"3\"\r" +
    "\n" +
    "                            ng-required=\"vm.config.requiresCreditReport\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.minimumFico\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            maxlength=\"3\"\r" +
    "\n" +
    "                            disabled />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.maximumNrs\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            aa-fg-class=\"fill\"\r" +
    "\n" +
    "                            maxlength=\"3\"\r" +
    "\n" +
    "                            disabled />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.experienceRequired}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Experience\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.experienceRequired\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        ng-show=\"vm.experienceRequired\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.minimumYearsInBusiness\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"integer\"\r" +
    "\n" +
    "                            ng-required=\"vm.experienceRequired\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.hasUnderwritingQuestions}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Questions\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.hasUnderwritingQuestions\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"top-margin-super\" \r" +
    "\n" +
    "                        ng-show=\"vm.hasUnderwritingQuestions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ui-select\r" +
    "\n" +
    "                                ng-model=\"vm.questionSearch.selected\"\r" +
    "\n" +
    "                                ng-init=\"$select.labelStrategy = 'none'\"\r" +
    "\n" +
    "                                reset-search-input=\"false\"\r" +
    "\n" +
    "                                name=\"questionSelection\"\r" +
    "\n" +
    "                                theme=\"app/components/uiSelect/a3\"\r" +
    "\n" +
    "                                class=\"fill v-center height-25\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <ui-select-match\r" +
    "\n" +
    "                                    placeholder=\"Search for application questions by unique name...\"\r" +
    "\n" +
    "                                    label=\"Search Questions\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    {{$select.selected.question}}\r" +
    "\n" +
    "                                </ui-select-match>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                <ui-select-choices\r" +
    "\n" +
    "                                    repeat=\"question in vm.questionSearch.matches track by $index\"\r" +
    "\n" +
    "                                    refresh=\"vm.searchQuestions(vm.questionSearch, $select.search)\"\r" +
    "\n" +
    "                                    refresh-delay=\"0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div ng-bind-html=\"question.question | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                </ui-select-choices>\r" +
    "\n" +
    "                            </ui-select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"v-center left-margin height-25\"\r" +
    "\n" +
    "                                ng-click=\"vm.addQuestion()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <table \r" +
    "\n" +
    "                            ng-form=\"questions\"\r" +
    "\n" +
    "                            ng-show=\"vm.config.underwritingQuestions.length\"\r" +
    "\n" +
    "                            class=\"top-margin-big\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <thead>\r" +
    "\n" +
    "                                <tr>\r" +
    "\n" +
    "                                    <th>Question</th>\r" +
    "\n" +
    "                                    <th class=\"fit-content\">Unique Name</th>\r" +
    "\n" +
    "                                    <th class=\"fit-content\">Required</th>\r" +
    "\n" +
    "                                    <th class=\"fit-content\">Required Value</th>\r" +
    "\n" +
    "                                    <th></th>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </thead>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <tbody>\r" +
    "\n" +
    "                                <tr\r" +
    "\n" +
    "                                    ng-repeat=\"question in vm.config.underwritingQuestions\"\r" +
    "\n" +
    "                                    ng-class=\"{'is-removed': question.isRemoved}\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                    <td>{{question.questionText}}</td>\r" +
    "\n" +
    "                                    <td class=\"fit-content\">\r" +
    "\n" +
    "                                        {{question.questionName}}\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                    <td class=\"fit-content\">\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-field-group=\"question.isRequired\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                                            aa-fg-class=\"small\"\r" +
    "\n" +
    "                                            aa-label-strategy=\"none\" />\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                    <td class=\"fit-content\">\r" +
    "\n" +
    "                                        <input\r" +
    "\n" +
    "                                            aa-field-group=\"question.requiredValue\"\r" +
    "\n" +
    "                                            ng-if=\"question.questionType === 'TextBox' || question.questionType === 'TextArea'\"\r" +
    "\n" +
    "                                            ng-required=\"question.isRequired\"\r" +
    "\n" +
    "                                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                            aa-wrapper-class=\"no-margin\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                        <select\r" +
    "\n" +
    "                                            aa-field-group=\"question.requiredValue\"\r" +
    "\n" +
    "                                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                            ng-if=\"question.questionType === 'DropDown' || question.questionType === 'Radio'\"\r" +
    "\n" +
    "                                            aa-option-text=\"text\"\r" +
    "\n" +
    "                                            aa-option-value=\"text\"\r" +
    "\n" +
    "                                            options=\"question.options\"\r" +
    "\n" +
    "                                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                            aa-wrapper-class=\"no-margin\"\r" +
    "\n" +
    "                                            ng-required=\"question.isRequired\">\r" +
    "\n" +
    "                                        </select>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                    <td class=\"fit-content\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <button \r" +
    "\n" +
    "                                            class=\"mini\"\r" +
    "\n" +
    "                                            ng-click=\"vm.removeQuestion($index, question)\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                                        </button>\r" +
    "\n" +
    "                                    </td>\r" +
    "\n" +
    "                                </tr>\r" +
    "\n" +
    "                            </tbody>\r" +
    "\n" +
    "                        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <empty-state \r" +
    "\n" +
    "                            ng-show=\"!vm.config.underwritingQuestions.length\"\r" +
    "\n" +
    "                            message=\"No underwriting questions are specified\">\r" +
    "\n" +
    "                        </empty-state>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <uib-tab heading=\"Rate\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">Rate</span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            class=\"left-margin-auto v-center mini\"\r" +
    "\n" +
    "                            id=\"underwriting-detail-new-rate\"\r" +
    "\n" +
    "                            ng-click=\"vm.showNewRateModal()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            New Rate\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <select\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.rateId\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                            aa-label=\"Base Rate\"\r" +
    "\n" +
    "                            options=\"vm.rates\"\r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.overrideMinimumPremium}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Minimum Premium\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.overrideMinimumPremium\"\r" +
    "\n" +
    "                            aa-label=\"Override Default Minimum Premium\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        ng-show=\"vm.overrideMinimumPremium\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.minimumPremium\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                            ng-required=\"vm.overrideMinimumPremium\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.overrideCommission}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            New Business Commission Override\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.overrideCommission\"\r" +
    "\n" +
    "                            aa-label=\"Override Default Commission\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        ng-show=\"vm.overrideCommission\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.commissionOverride\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                            ng-required=\"vm.overrideCommission\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.overrideRenewalCommission}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Renewal Commission Override\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.overrideRenewalCommission\"\r" +
    "\n" +
    "                            aa-label=\"Override Renewal Commission\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"flex\"\r" +
    "\n" +
    "                        ng-show=\"vm.overrideRenewalCommission\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.config.renewalCommissionOverride\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"percent\"\r" +
    "\n" +
    "                            ng-required=\"vm.overrideRenewalCommission\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.overrideRate}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">Rate Override</span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.overrideRate\"\r" +
    "\n" +
    "                            aa-label=\"Rate Override Enabled\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />                        \r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"vm.overrideRate\">\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"left-margin-auto v-center mini\"\r" +
    "\n" +
    "                                id=\"underwriting-detail-add-rate-override\"\r" +
    "\n" +
    "                                ng-click=\"vm.addRateOverride()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Add Rate Override\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-sortable=\"vm.rateOverrideSortableOptions\"\r" +
    "\n" +
    "                            class=\"top-margin-big\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                ng-repeat=\"rateOverride in vm.config.rateOverrides track by $index\"\r" +
    "\n" +
    "                                ng-class=\"{'is-removed': rateOverride.isRemoved, 'alt': $odd}\"\r" +
    "\n" +
    "                                class=\"div-list no-highlight\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div class=\"div-list-grab-handle\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-grip-lines-vertical margin-auto\"></i>\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <div class=\"fill\">\r" +
    "\n" +
    "                                        <div class=\"flex bottom-margin-big\">\r" +
    "\n" +
    "                                            <select\r" +
    "\n" +
    "                                                aa-field-group=\"rateOverride.rateId\"\r" +
    "\n" +
    "                                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                                aa-label=\"Rate\"\r" +
    "\n" +
    "                                                options=\"vm.rates\"\r" +
    "\n" +
    "                                                aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                aa-fg-class=\"v-center height-25\"\r" +
    "\n" +
    "                                                required>\r" +
    "\n" +
    "                                            </select>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                            <a \r" +
    "\n" +
    "                                                class=\"left-margin v-center small\"\r" +
    "\n" +
    "                                                ng-click=\"vm.removeRateOverride($index, rateOverride)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                            </a>\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <button \r" +
    "\n" +
    "                                                class=\"left-margin-auto v-center mini\"\r" +
    "\n" +
    "                                                id=\"underwriting-detail-add-rate-override-condition\"\r" +
    "\n" +
    "                                                ng-click=\"vm.addRateOverrideCondition(rateOverride)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                Add Condition\r" +
    "\n" +
    "                                            </button>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "             \r" +
    "\n" +
    "                                        <table\r" +
    "\n" +
    "                                            class=\"small\" \r" +
    "\n" +
    "                                            ng-show=\"rateOverride.conditions.length\"\r" +
    "\n" +
    "                                            ng-form=\"rateOverrideConditionsForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <thead>\r" +
    "\n" +
    "                                                <tr>\r" +
    "\n" +
    "                                                    <th>Condition Type</th>\r" +
    "\n" +
    "                                                    <th colspan=\"2\">Condition</th>\r" +
    "\n" +
    "                                                    <th>&nbsp;</th>\r" +
    "\n" +
    "                                                </tr>\r" +
    "\n" +
    "                                            </thead>\r" +
    "\n" +
    "                                            <tbody>\r" +
    "\n" +
    "                                                <tr \r" +
    "\n" +
    "                                                    ng-class=\"{'is-removed': condition.isRemoved}\"\r" +
    "\n" +
    "                                                    ng-repeat=\"condition in rateOverride.conditions track by $index\">\r" +
    "\n" +
    "                                                    \r" +
    "\n" +
    "                                                    <td class=\"width-150\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                        <select\r" +
    "\n" +
    "                                                            aa-field-group=\"condition.conditionType\"\r" +
    "\n" +
    "                                                            aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                                            aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                            required>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                            <option value=\"UnderwritingQuestion\">Question</option>\r" +
    "\n" +
    "                                                            <option value=\"BondAmount\">Bond Amount</option>\r" +
    "\n" +
    "                                                        </select>\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                    <td ng-if=\"condition.conditionType == 'UnderwritingQuestion'\">\r" +
    "\n" +
    "                                                        \r" +
    "\n" +
    "                                                        <ui-select\r" +
    "\n" +
    "                                                            ng-model=\"condition.questionSearch.selected\"\r" +
    "\n" +
    "                                                            ng-init=\"$select.labelStrategy = 'none'\"\r" +
    "\n" +
    "                                                            reset-search-input=\"false\"\r" +
    "\n" +
    "                                                            on-select=\"vm.setRateOverrideConditionMasterApplicationQuestion(condition, $item)\"\r" +
    "\n" +
    "                                                            name=\"questionSelection\"\r" +
    "\n" +
    "                                                            theme=\"app/components/uiSelect/a3\"\r" +
    "\n" +
    "                                                            class=\"height-25 no-margin\"\r" +
    "\n" +
    "                                                            required>\r" +
    "\n" +
    "                                                        \r" +
    "\n" +
    "                                                            <ui-select-match\r" +
    "\n" +
    "                                                                placeholder=\"Search for application questions by unique name...\"\r" +
    "\n" +
    "                                                                label=\"Search Questions\">\r" +
    "\n" +
    "                                                                {{$select.selected.question}}\r" +
    "\n" +
    "                                                            </ui-select-match>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                            <ui-select-choices\r" +
    "\n" +
    "                                                                repeat=\"question in condition.questionSearch.matches track by $index\"\r" +
    "\n" +
    "                                                                refresh=\"vm.searchQuestions(condition.questionSearch, $select.search)\"\r" +
    "\n" +
    "                                                                refresh-delay=\"0\">\r" +
    "\n" +
    "                                                                \r" +
    "\n" +
    "                                                                <div ng-bind-html=\"question.question | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                                            </ui-select-choices>\r" +
    "\n" +
    "                                                        </ui-select>\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <td ng-if=\"condition.conditionType == 'UnderwritingQuestion'\">\r" +
    "\n" +
    "                                                        \r" +
    "\n" +
    "                                                        <input\r" +
    "\n" +
    "                                                            aa-field-group=\"condition.requiredValue\"\r" +
    "\n" +
    "                                                            ng-show=\"condition.questionType === 'TextBox' || condition.questionType === 'TextArea'\"\r" +
    "\n" +
    "                                                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                            aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                            ng-required=\"condition.questionType === 'TextBox' || condition.questionType === 'TextArea'\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                        <select\r" +
    "\n" +
    "                                                            aa-field-group=\"condition.requiredValue\"\r" +
    "\n" +
    "                                                            aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                                            ng-show=\"condition.questionType === 'DropDown' || condition.questionType === 'Radio'\"\r" +
    "\n" +
    "                                                            options=\"condition.options\"\r" +
    "\n" +
    "                                                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                            aa-option-text=\"text\"\r" +
    "\n" +
    "                                                            aa-option-value=\"text\"\r" +
    "\n" +
    "                                                            aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                            ng-required=\"condition.questionType === 'DropDown' || condition.questionType === 'Radio'\">\r" +
    "\n" +
    "                                                        </select>\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                    <td ng-if=\"condition.conditionType == 'BondAmount'\">    \r" +
    "\n" +
    "                                                        <input\r" +
    "\n" +
    "                                                            aa-field-group=\"condition.minimumBondAmount\"\r" +
    "\n" +
    "                                                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                            aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                            required />\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                    <td ng-if=\"condition.conditionType == 'BondAmount'\">\r" +
    "\n" +
    "                                                        <input\r" +
    "\n" +
    "                                                            aa-field-group=\"condition.maximumBondAmount\"\r" +
    "\n" +
    "                                                            aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                                                            aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                            aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                            required />\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                                    <td class=\"text-right\">\r" +
    "\n" +
    "                                                        \r" +
    "\n" +
    "                                                        <button \r" +
    "\n" +
    "                                                            class=\"mini\"\r" +
    "\n" +
    "                                                            ng-click=\"vm.removeRateOverrideCondition($index, rateOverride, condition)\">\r" +
    "\n" +
    "                                                            \r" +
    "\n" +
    "                                                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                                        </button>\r" +
    "\n" +
    "                                                    </td>\r" +
    "\n" +
    "                                                </tr>\r" +
    "\n" +
    "                                            </tbody>\r" +
    "\n" +
    "                                        </table>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <div\r" +
    "\n" +
    "                                            class=\"text-center\"\r" +
    "\n" +
    "                                            ng-show=\"!rateOverride.conditions.length\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                            <b>\r" +
    "\n" +
    "                                                No override conditions exist\r" +
    "\n" +
    "                                            </b>\r" +
    "\n" +
    "                                        </div>\r" +
    "\n" +
    "                                    </div>  \r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <empty-state\r" +
    "\n" +
    "                            ng-show=\"!vm.config.rateOverrides.length\"\r" +
    "\n" +
    "                            message=\"No rate overrides exist\">\r" +
    "\n" +
    "                        </empty-state>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uib-tab heading=\"Forms\">\r" +
    "\n" +
    "                <section ng-class=\"{'inactive': !vm.hasBondFormAttachments}\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">Attached Forms</span>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"vm.hasBondFormAttachments\"\r" +
    "\n" +
    "                            aa-label=\"Has Attached Forms\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"left-margin-auto v-center\" />                        \r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div ng-show=\"vm.hasBondFormAttachments\">\r" +
    "\n" +
    "                        <div class=\"flex top-margin-big bottom-margin-super\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <ui-select\r" +
    "\n" +
    "                                ng-model=\"vm.documentSearch.selected\"\r" +
    "\n" +
    "                                ng-init=\"$select.labelStrategy = 'none'\"\r" +
    "\n" +
    "                                reset-search-input=\"false\"\r" +
    "\n" +
    "                                name=\"documentSelection\"\r" +
    "\n" +
    "                                class=\"height-25 fill no-margin\"\r" +
    "\n" +
    "                                theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                                <ui-select-match\r" +
    "\n" +
    "                                    placeholder=\"Search for documents by name...\"\r" +
    "\n" +
    "                                    label=\"Search Documents\">\r" +
    "\n" +
    "                                    {{$select.selected.name}}\r" +
    "\n" +
    "                                </ui-select-match>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                                <ui-select-choices\r" +
    "\n" +
    "                                    repeat=\"document in vm.documentSearch.matches track by $index\"\r" +
    "\n" +
    "                                    refresh=\"vm.searchDocuments($select.search)\"\r" +
    "\n" +
    "                                    refresh-delay=\"0\">\r" +
    "\n" +
    "                                    <div\r" +
    "\n" +
    "                                        ng-bind-html=\"document.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                </ui-select-choices>\r" +
    "\n" +
    "                            </ui-select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                class=\"height-25 left-margin\"\r" +
    "\n" +
    "                                ng-click=\"vm.addDocument()\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                Add\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"div-list\"\r" +
    "\n" +
    "                            ng-class=\"{'is-removed': bondFormAttachment.isRemoved, 'alt':  $odd}\" \r" +
    "\n" +
    "                            ng-repeat=\"bondFormAttachment in vm.config.bondFormAttachments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex bottom-margin-big\">\r" +
    "\n" +
    "                                <b class=\"v-center\">\r" +
    "\n" +
    "                                    {{bondFormAttachment.documentName}}\r" +
    "\n" +
    "                                </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    class=\"small left-margin v-center\"\r" +
    "\n" +
    "                                    ng-click=\"vm.removeDocument(bondFormAttachment, $index)\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                <button \r" +
    "\n" +
    "                                    class=\"left-margin-auto v-center mini\"\r" +
    "\n" +
    "                                    id=\"underwriting-detail-add-bond-form-attachment-condition\"\r" +
    "\n" +
    "                                    ng-click=\"vm.addBondFormAttachmentCondition(bondFormAttachment)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    Add Condition\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                            <table\r" +
    "\n" +
    "                                class=\"small\"\r" +
    "\n" +
    "                                ng-show=\"bondFormAttachment.conditions.length\" \r" +
    "\n" +
    "                                ng-form=\"bondFormAttachmentConditionsForm\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <thead>\r" +
    "\n" +
    "                                    <tr>\r" +
    "\n" +
    "                                        <th>Question</th>\r" +
    "\n" +
    "                                        <th>Required Value</th>\r" +
    "\n" +
    "                                        <th></th>\r" +
    "\n" +
    "                                    </tr>\r" +
    "\n" +
    "                                </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <tbody>\r" +
    "\n" +
    "                                    <tr \r" +
    "\n" +
    "                                        ng-class=\"{'is-removed': condition.isRemoved}\"\r" +
    "\n" +
    "                                        ng-repeat=\"condition in bondFormAttachment.conditions track by $index\">\r" +
    "\n" +
    "                                        <td>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                            <ui-select\r" +
    "\n" +
    "                                                ng-model=\"condition.questionSearch.selected\"\r" +
    "\n" +
    "                                                ng-init=\"$select.labelStrategy = 'none'\"\r" +
    "\n" +
    "                                                class=\"height-25 no-margin\"\r" +
    "\n" +
    "                                                reset-search-input=\"false\"\r" +
    "\n" +
    "                                                on-select=\"vm.setBondAttachmentConditionMasterApplicationQuestion(condition, $item)\"\r" +
    "\n" +
    "                                                name=\"questionSelection\"\r" +
    "\n" +
    "                                                required\r" +
    "\n" +
    "                                                theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <ui-select-match\r" +
    "\n" +
    "                                                    placeholder=\"Search for application questions by unique name...\"\r" +
    "\n" +
    "                                                    label=\"Search Questions\">\r" +
    "\n" +
    "                                                    {{$select.selected.question}}\r" +
    "\n" +
    "                                                </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <ui-select-choices\r" +
    "\n" +
    "                                                    repeat=\"question in condition.questionSearch.matches track by $index\"\r" +
    "\n" +
    "                                                    refresh=\"vm.searchQuestions(condition.questionSearch, $select.search)\"\r" +
    "\n" +
    "                                                    refresh-delay=\"0\">\r" +
    "\n" +
    "                                                    \r" +
    "\n" +
    "                                                    <div ng-bind-html=\"question.question | highlight: $select.search\"></div>\r" +
    "\n" +
    "                                                </ui-select-choices>\r" +
    "\n" +
    "                                            </ui-select>\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td>\r" +
    "\n" +
    "                                            <input\r" +
    "\n" +
    "                                                aa-field-group=\"condition.requiredValue\"\r" +
    "\n" +
    "                                                ng-show=\"condition.questionType === 'TextBox' || condition.questionType === 'TextArea'\"\r" +
    "\n" +
    "                                                aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                ng-required=\"condition.questionType === 'TextBox' || condition.questionType === 'TextArea'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                            <select\r" +
    "\n" +
    "                                                aa-field-group=\"condition.requiredValue\"\r" +
    "\n" +
    "                                                aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                                                ng-show=\"condition.questionType === 'DropDown' || condition.questionType === 'Radio'\"\r" +
    "\n" +
    "                                                options=\"condition.options\"\r" +
    "\n" +
    "                                                aa-label-strategy=\"inline\"\r" +
    "\n" +
    "                                                aa-option-text=\"text\"\r" +
    "\n" +
    "                                                aa-option-value=\"text\"\r" +
    "\n" +
    "                                                aa-wrapper-class=\"no-margin field-wrapper\"\r" +
    "\n" +
    "                                                ng-required=\"condition.questionType === 'DropDown' || condition.questionType === 'Radio'\">\r" +
    "\n" +
    "                                            </select>\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        <td class=\"text-right\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                            <button\r" +
    "\n" +
    "                                                ng-click=\"vm.removeBondFormAttachmentCondition(bondFormAttachment, condition, $index)\"\r" +
    "\n" +
    "                                                class=\"mini\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                            </button>\r" +
    "\n" +
    "                                        </td>\r" +
    "\n" +
    "                                    </tr>\r" +
    "\n" +
    "                                </tbody>\r" +
    "\n" +
    "                            </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div\r" +
    "\n" +
    "                                class=\"text-center\"\r" +
    "\n" +
    "                                ng-show=\"!bondFormAttachment.conditions.length\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <b>\r" +
    "\n" +
    "                                    No conditions exist\r" +
    "\n" +
    "                                </b>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <empty-state\r" +
    "\n" +
    "                            ng-show=\"!vm.config.bondFormAttachments.length\"\r" +
    "\n" +
    "                            message=\"No Additional Documents Attached\">\r" +
    "\n" +
    "                        </empty-state>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </uib-tab>\r" +
    "\n" +
    "        </uib-tabset>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"top-margin-big flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white left-margin-auto\"\r" +
    "\n" +
    "                ui-sref=\"main.underwritingConfigurationGrid\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                value=\"Save\"\r" +
    "\n" +
    "                class=\"left-margin\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.save()\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</ng-form>\r" +
    "\n"
  );


  $templateCache.put('app/states/carrierSpecific/underwritingConfigurations/underwritingConfigurationGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"underwritingGridForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        id=\"underwriting-grid-search-input\"\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search underwriting configurations\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            uib-tooltip=\"Actions\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "            uib-dropdown-toggle\r" +
    "\n" +
    "            id=\"underwriting-list-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Actions\r" +
    "\n" +
    "            <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewUnderwritingConfiguration\"\r" +
    "\n" +
    "                    ui-sref=\"main.underwritingConfigurationDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    Add New Configuration\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "<perfect-scrollbar\r" +
    "\n" +
    "    suppress-scroll-x=\"true\"\r" +
    "\n" +
    "    class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-y=\"true\"\r" +
    "\n" +
    "        class=\"scroller no-right-padding\"\r" +
    "\n" +
    "        wheel-propagation=\"true\"\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "        <table \r" +
    "\n" +
    "            class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th class=\"fit-content\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Id\"\r" +
    "\n" +
    "                            sort-expression=\"UnderwritingConfigurations.Id\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"BondTypes.Name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Status\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Term\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Rate\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Writing Company\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Credit\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        Bond Amount\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>&nbsp;</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        {{item.id}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <a ui-sref=\"main.underwritingConfigurationDetail({ id: item.id })\">\r" +
    "\n" +
    "                            {{item.bondType}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"!item.isActive\"\r" +
    "\n" +
    "                            class=\"red\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Inactive\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"item.isActive\">\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <ng-pluralize\r" +
    "\n" +
    "                            count=\"item.term\"\r" +
    "\n" +
    "                            when=\"{'1': '1yr' ,'other': '{}yrs' }\">\r" +
    "\n" +
    "                        </ng-pluralize>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{item.rate}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        {{item.writingCompany}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span ng-show=\"item.minimumVantage\"> > {{item.minimumVantage}}</span>\r" +
    "\n" +
    "                        <span ng-show=\"!item.minimumVantage\">Any</span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span ng-show=\"!item.maximumBondAmount && !item.maximumBondAmount\">Any</span>\r" +
    "\n" +
    "                        <span ng-show=\"item.minimumBondAmount\"> >= {{item.minimumBondAmount | currency: '$'}} </span>\r" +
    "\n" +
    "                        <span ng-show=\"item.maximumBondAmount\"> <= {{item.maximumBondAmount | currency: '$'}} </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            class=\"mini\"\r" +
    "\n" +
    "                            uib-tooltip=\"Copy Configuration\"\r" +
    "\n" +
    "                            ng-click=\"vm.copy(item)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "                        </button><button \r" +
    "\n" +
    "                            uib-tooltip=\"Delete\" \r" +
    "\n" +
    "                            class=\"mini\"\r" +
    "\n" +
    "                            ng-click=\"vm.promptToDelete(item)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state \r" +
    "\n" +
    "        ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "        message=\"No underwriting configurations were found\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/a3Statements/a3BillingStatementsGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid \r" +
    "\n" +
    "    on-loaded=\"vm.gridLoaded(records)\" \r" +
    "\n" +
    "    control-object=\"vm.boundGrid\" \r" +
    "\n" +
    "    endpoint=\"SystemAccountStatement\" \r" +
    "\n" +
    "    enable-paging=\"true\" \r" +
    "\n" +
    "    query=\"vm.reportQuery\" \r" +
    "\n" +
    "    aggregate-search=\"vm.search\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Statement Date Range\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"endDate\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{ item.startDate | dateOnly }} - {{ item.endDate | dateOnly }}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Original Balance\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"totalDue\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{ item.totalDue | currency: \"$\" }}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Total Paid\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"totalPaid\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{ item.totalPaid | currency: \"$\" }}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Current Balance\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"currentBalance\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{ item.currentBalance | currency: \"$\" }}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"Due Date\" \r" +
    "\n" +
    "        enable-sorting=\"true\" \r" +
    "\n" +
    "        sort-on=\"dueDate\" \r" +
    "\n" +
    "        css-class=\"date-column\" \r" +
    "\n" +
    "        header-css-class=\"date-column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{item.dueDate | date:'MM/dd/yyyy'}}\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column \r" +
    "\n" +
    "        header-text=\"\" \r" +
    "\n" +
    "        enable-sorting=\"false\" \r" +
    "\n" +
    "        css-class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            pdf-download \r" +
    "\n" +
    "            url=\"{{item.$downloadUrl}}\" \r" +
    "\n" +
    "            filename=\"A3_Statement.pdf\" \r" +
    "\n" +
    "            uib-tooltip=\"Download Statement\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "</bound-grid>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/applicationQuestionDetail/applicationQuestionsDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-form=\"form\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"above\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.question.isActive\" \r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-fg-class=\"pull-right\" />\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-question \"></i>Question\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.question.isAccountApplicationQuestion\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                aa-fg-class=\"text-right col-sm-12\" \r" +
    "\n" +
    "                aa-input-wrapper-class=\"pull-right\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.question.name\" \r" +
    "\n" +
    "                aa-label=\"Unique Name\" \r" +
    "\n" +
    "                focus-me \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <strong class=\"col-sm-6 text-center\">\r" +
    "\n" +
    "                Each question must have a unique name. This name will be used to reference the question on forms as well as underwriting configurations.\r" +
    "\n" +
    "            </strong>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.question.question\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-12\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-field-group=\"vm.question.type\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                options=\"vm.questionTypes\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\">\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.question.requiredText\" \r" +
    "\n" +
    "                required \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input \r" +
    "\n" +
    "                aa-field-group=\"vm.question.sampleResponse\" \r" +
    "\n" +
    "                aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.question.type == 'DropDown' || vm.question.type == 'Radio'\">\r" +
    "\n" +
    "            <h5>\r" +
    "\n" +
    "                <span ng-show=\"vm.question.type == 'DropDown'\">Dropdown</span>\r" +
    "\n" +
    "                <span ng-show=\"vm.question.type == 'Radio'\">Radio Button</span>\r" +
    "\n" +
    "                Choices\r" +
    "\n" +
    "            </h5>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"red\" \r" +
    "\n" +
    "                ng-show=\"formSubmitted && !areChoicesValid(question)\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                At least 1 choice is required\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"field-flex-box\">\r" +
    "\n" +
    "                <input aa-field-group=\"vm.question.choiceToAdd\"/>\r" +
    "\n" +
    "                <button ng-click=\"addChoice(vm.question, vm.question.choiceToAdd)\">\r" +
    "\n" +
    "                    Add\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <table>\r" +
    "\n" +
    "                <tbody>\r" +
    "\n" +
    "                    <tr ng-repeat=\"choice in vm.question.masterApplicationQuestionChoices\">\r" +
    "\n" +
    "                        <td class=\"col-75px\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                ng-click=\"moveChoiceDown(vm.question.masterApplicationQuestionChoices, $index)\" \r" +
    "\n" +
    "                                ng-show=\"$index < vm.question.masterApplicationQuestionChoices.length - 1\" \r" +
    "\n" +
    "                                class=\"btn btn-default btn-xs pull-left\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button \r" +
    "\n" +
    "                                ng-click=\"moveChoiceUp(vm.question.masterApplicationQuestionChoices, $index)\" \r" +
    "\n" +
    "                                ng-show=\"$index > 0\" \r" +
    "\n" +
    "                                class=\"btn btn-xs btn-default pull-right\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <i class=\"fa fa-caret-up\"></i>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>{{choice.text}}</td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "                            <button ng-click=\"removeChoice(vm.question.masterApplicationQuestionChoices, $index)\">\r" +
    "\n" +
    "                                <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "                                <span>Remove</span>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <tr \r" +
    "\n" +
    "                        ng-show=\"vm.question.masterApplicationQuestionChoices.length <= 0\" \r" +
    "\n" +
    "                        class=\"noRecords\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <td colspan=\"3\">\r" +
    "\n" +
    "                            <span>No Question Choices</span>\r" +
    "\n" +
    "                            <p>This question has no choices</p>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-question \"></i>Follow-Up Questions\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button ng-click=\"vm.addSubQuestion()\">Add Follow-Up Question</button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-repeat=\"subQuestion in vm.question.subQuestions\">\r" +
    "\n" +
    "            <hr ng-show=\"!$first\"/>\r" +
    "\n" +
    "            <ng-form name=\"subQuestionForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-right\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"small gray button-remove\" \r" +
    "\n" +
    "                        ng-click=\"vm.removeSubQuestion($index)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Remove\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.name\" \r" +
    "\n" +
    "                        required \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                        aa-label=\"Unique Name\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.showWhenParentValueEquals\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-3\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <strong class=\"col-sm-6 text-center\">\r" +
    "\n" +
    "                        Each question must have a unique name. This name will be used to reference the question on forms as well as underwriting configurations.\r" +
    "\n" +
    "                    </strong>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.question\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-12\" \r" +
    "\n" +
    "                        required/>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.type\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        options=\"vm.questionTypes\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.requiredText\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" \r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"subQuestion.sampleResponse\" \r" +
    "\n" +
    "                        required \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-4\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"subQuestion.type == 'DropDown' || subQuestion.type == 'Radio'\">\r" +
    "\n" +
    "                    <h5>\r" +
    "\n" +
    "                        <span ng-show=\"subQuestion.type=='DropDown'\">Dropdown</span>\r" +
    "\n" +
    "                        <span ng-show=\"subQuestion.type=='Radio'\">Radio Button</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Choices\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        class=\"red\"\r" +
    "\n" +
    "                        ng-show=\"formSubmitted && !areChoicesValid(subQuestion)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        At least 1 choice is required\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"field-flex-box\">\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.choiceToAdd\"/>\r" +
    "\n" +
    "                        <button ng-click=\"vm.addChoice(subQuestion, choiceToAdd)\">Add</button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <table>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                        <tr ng-repeat=\"choice in vm.subQuestion.masterApplicationQuestionChoices\">\r" +
    "\n" +
    "                            <td class=\"col-75px\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <button \r" +
    "\n" +
    "                                    ng-click=\"moveChoiceDown(vm.subQuestion.masterApplicationQuestionChoices, $index)\" \r" +
    "\n" +
    "                                    ng-show=\"$index < vm.subQuestion.masterApplicationQuestionChoices.length - 1\" \r" +
    "\n" +
    "                                    class=\"btn btn-default btn-xs pull-left\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <button \r" +
    "\n" +
    "                                    ng-click=\"moveChoiceUp(vm.subQuestion.masterApplicationQuestionChoices, $index)\" \r" +
    "\n" +
    "                                    ng-show=\"$index > 0\" \r" +
    "\n" +
    "                                    class=\"btn btn-xs btn-default pull-right\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <i class=\"fa fa-caret-up\"></i>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            <td>{{choice.text}}</td>\r" +
    "\n" +
    "                            <td class=\"text-right\">\r" +
    "\n" +
    "                                <button ng-click=\"removeChoice(vm.subQuestion.masterApplicationQuestionChoices, $index)\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "                                    <span>Remove</span>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        <tr \r" +
    "\n" +
    "                            ng-show=\"vm.subQuestion.masterApplicationQuestionChoices.length <= 0\" \r" +
    "\n" +
    "                            class=\"noRecords\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <td colspan=\"3\">\r" +
    "\n" +
    "                                <span>No Question Choices</span>\r" +
    "\n" +
    "                                <p>This question has no choices</p>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </ng-form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section ng-show=\"!vm.newRecord\">\r" +
    "\n" +
    "        <header>Assigned Bond Types</header>\r" +
    "\n" +
    "        <p>Here are the bond types that are using this question. \r" +
    "\n" +
    "            Remember that this does not include questions used by carriers as part of underwriting.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"col-md-4\" \r" +
    "\n" +
    "                ng-repeat=\"btq in vm.question.bondTypeMasterApplicationQuestions\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <a ui-sref=\"main.bondTypesDetail({ id: btq.bondType.id })\">{{btq.bondType.name}}</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>Mapping Examples</header>\r" +
    "\n" +
    "        <p>Here are examples of how placeholders should be named to map the responses of these questions to A3 forms.</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.question.name\">\r" +
    "\n" +
    "            <span ng-non-bindable>{{[</span>ApplicationQuestionResponse.{{vm.question.name}}<span ng-non-bindable>]}}</span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-show=\"vm.subQuestion.name\" \r" +
    "\n" +
    "            ng-repeat=\"subQuestion in vm.question.subQuestions\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span ng-non-bindable>{{[</span>ApplicationQuestionResponse.{{subQuestion.name}}<span ng-non-bindable>]}}</span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"white\" \r" +
    "\n" +
    "        ui-sref=\"main.applicationQuestionsGrid\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "         type=\"submit\" \r" +
    "\n" +
    "         value=\"Save\" \r" +
    "\n" +
    "         aa-submit-form=\"vm.save()\" />\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/applicationQuestions/applicationQuestionsGrid.html',
    "<div \r" +
    "\n" +
    "    ng-form=\"searchForm\"\r" +
    "\n" +
    "    class=\"bottom-margin flex\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "    <input \r" +
    "\n" +
    "        aa-field-group=\"vm.search\" \r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "        aa-fg-class=\"v-center table-search-field\" \r" +
    "\n" +
    "        aa-label=\"Search for question by text...\"\r" +
    "\n" +
    "        focus-me>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        class=\"left-margin-auto v-center small\"\r" +
    "\n" +
    "        ui-sref=\"main.applicationQuestionsDetail\" \r" +
    "\n" +
    "        uib-tooltip=\"Add a new application question\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        <span class=\"hidden-xs\">\r" +
    "\n" +
    "            Add New Question\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<bound-grid \r" +
    "\n" +
    "    endpoint=\"MasterApplicationQuestion\"\r" +
    "\n" +
    "    enable-paging=\"true\"\r" +
    "\n" +
    "    query=\"vm.gridQuery\"\r" +
    "\n" +
    "    aggregate-search=\"vm.search\"\r" +
    "\n" +
    "    label-field=\"question\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <template-column\r" +
    "\n" +
    "        header-text=\"Question\"\r" +
    "\n" +
    "        enable-sorting=\"true\"\r" +
    "\n" +
    "        sort-on=\"question\"\r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        filter-expression=\"question\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a ui-sref=\"main.applicationQuestionsDetail({ id: item.id })\">\r" +
    "\n" +
    "            {{item.question}}\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </template-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bound-column \r" +
    "\n" +
    "        header-text=\"Unique Name\"\r" +
    "\n" +
    "        enable-sorting=\"true\"\r" +
    "\n" +
    "        enable-aggregate-filter=\"true\"\r" +
    "\n" +
    "        dataitem=\"name\"\r" +
    "\n" +
    "        enable-inline-edit=\"false\">\r" +
    "\n" +
    "    </bound-column>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button-column \r" +
    "\n" +
    "        show-edit-button=\"false\"\r" +
    "\n" +
    "        show-delete-button=\"true\"\r" +
    "\n" +
    "        show-audit-button=\"false\"\r" +
    "\n" +
    "        css-class=\"fit-content\">\r" +
    "\n" +
    "    </button-column>\r" +
    "\n" +
    "</bound-grid> "
  );


  $templateCache.put('app/states/common/bondTransactions/bondTransactionsReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"bottom-margin flex\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search Bond Transactions\"\r" +
    "\n" +
    "           uib-tooltip=\"Search Bond Transactions\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"bond-transactions-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <transaction-report-filter-dropdown class=\"flex left-margin\"\r" +
    "\n" +
    "                                        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "                                        table=\"vm.table\">\r" +
    "\n" +
    "    </transaction-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportExcelReport()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Number\"\r" +
    "\n" +
    "                    sort-expression=\"Bonds.bondNumber\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Principal\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.nameOnBond\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Transaction Type\"\r" +
    "\n" +
    "                    sort-expression=\"TransactionType\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Transaction Date\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.createdDateTime\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Amount\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.BondAmount\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Type\"\r" +
    "\n" +
    "                    sort-expression=\"BondTypes.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                    text=\"Carrier\"\r" +
    "\n" +
    "                    sort-expression=\"CarrierSystemAccount.companyName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                    text=\"Agency\"\r" +
    "\n" +
    "                    sort-expression=\"AgencySystemAccount.companyName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Effective Date\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.transactionEffectiveDate\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Premium\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.Premium\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Fees\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.Fees\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Commission\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.Commission\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"transaction in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{transaction.bondNumber}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"nowrap\">\r" +
    "\n" +
    "                <span ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ui-sref=\"main.customerDetail({ customerId: transaction.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                        target=\"_blank\" >\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        {{transaction.nameOnBond}} \r" +
    "\n" +
    "                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"transaction.customerLooupCode\">\r" +
    "\n" +
    "                        Loop Code: {{transaction.customerLookupCode}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                <span ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                    {{transaction.nameOnBond}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{transaction | transactionType}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <span ng-bind-html=\"transaction.createdDateTime | localDateTime : 'twoLine'\"></span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{transaction.bondAmount | currency: '$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{transaction.bondTypeName}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <system-account-logo\r" +
    "\n" +
    "                    system-account-id=\"transaction.carrierSystemAccountId\"\r" +
    "\n" +
    "                    company-name=\"transaction.carrierName\"\r" +
    "\n" +
    "                    writing-company-name=\"transaction.writingCompany\"\r" +
    "\n" +
    "                    show-labels=\"true\">\r" +
    "\n" +
    "                </system-account-logo>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                {{transaction.agencyName}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{transaction.transactionEffectiveDate | dateOnly}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{transaction.premium | currency: '$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{transaction.fees | currency: '$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{transaction.commission | currency: '$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"button-column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button show-bond-detail-modal=\"transaction.bondId\">\r" +
    "\n" +
    "                    Details\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    ng-show=\"transaction.transactionType !== 'Import'\"\r" +
    "\n" +
    "                    show-download-documents-modal=\"transaction.id\"\r" +
    "\n" +
    "                    uib-tooltip=\"Download Documents\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    ng-show=\"!vm.isCarrier && vm.epicIntegrationEnabled\"\r" +
    "\n" +
    "                    ng-disabled=\"transaction.exportToEpicStatus == 'Success' || transaction.exportToEpicStatus == 'Error'\"\r" +
    "\n" +
    "                    export-to-epic=\"{transaction: transaction, busyIndicator: vm.busyIndicator}\"\r" +
    "\n" +
    "                    uib-tooltip=\"Export to Epic\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-cloud-upload-alt\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/states/common/bondTransactions/transactionReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"transactionReportFilterDropdown\">\r" +
    "\n" +
    "    <!-- ng-form=\"newTransactionFilterDropdown\"> -->\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}})</b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\"> Carrier </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.carrierSystemAccountId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.carrierOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\">Agency</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.agencySystemAccountId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.agencyOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Transaction Date</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Effective Date</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Bond Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumBondAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumBondAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Commission Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumCommissionAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumCommissionAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"moneyPositiveOrNegative\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Refund Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumRefundAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumRefundAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Premium Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumPremiumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumPremiumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Transaction Type </label>\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.transactionType\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <option value=\"\">Any</option>\r" +
    "\n" +
    "                    <option value=\"NewBusiness\">New Business</option>\r" +
    "\n" +
    "                    <option value=\"Rider\">Rider</option>\r" +
    "\n" +
    "                    <option value=\"Cancellation\">Cancellation</option>\r" +
    "\n" +
    "                    <option value=\"Reinstatement\">Reinstatement</option>\r" +
    "\n" +
    "                    <option value=\"Renewal\">Renewal</option>\r" +
    "\n" +
    "                    <option value=\"Correction\">Correction</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Bond Type Contains</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.requestedBondTypeName\" \r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"text\"\r" +
    "\n" +
    "                    ng-required=\"false\" \r" +
    "\n" +
    "                    maxlength=\"30\" />\r" +
    "\n" +
    "                </input>\r" +
    "\n" +
    "            </div>  \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Broker Contains</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.requestedBrokerContains\" \r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"text\"\r" +
    "\n" +
    "                    ng-required=\"false\" \r" +
    "\n" +
    "                    maxlength=\"30\" />\r" +
    "\n" +
    "                </input>\r" +
    "\n" +
    "            </div>  \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">State</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    id=\"address-physical-state\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.requestedState\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-unselected-text=\"All States\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>  \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">SFAA Code </label>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.sfaaCodeId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Tagged With\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.tag\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\"></option>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option \r" +
    "\n" +
    "                        ng-repeat=\"tag in vm.tagOptions\" \r" +
    "\n" +
    "                        value=\"{{tag.value}}\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{tag.label}}\r" +
    "\n" +
    "                    </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Bond Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.bondStatus\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"Active\"> Active </option>\r" +
    "\n" +
    "                    <option value=\"Cancelled\"> Cancelled </option>\r" +
    "\n" +
    "                    <option value=\"PendingCancellation\"> PendingCancellation </option>\r" +
    "\n" +
    "                    <option value=\"PendingReinstatement\"> PendingReinstatement </option>\r" +
    "\n" +
    "                    <option value=\"Expired\"> Expired </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/bondTypeDetail/bondTypeDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<ng-form name=\"detailForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            Bond Type Detail\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ng-click=\"vm.showHistoryModal()\" \r" +
    "\n" +
    "            class=\"small\" \r" +
    "\n" +
    "            ng-show=\"!vm.newItem\"\r" +
    "\n" +
    "            uib-tooltip=\"View history\" \r" +
    "\n" +
    "            tooltip-placement=\"right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-history\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body sticky-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-x=\"true\"\r" +
    "\n" +
    "            class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "            wheel-propagation=\"true\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section class=\"above flex\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.isActive\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                    aa-label=\"Active\"\r" +
    "\n" +
    "                    aa-fg-class=\"left-margin-auto\" />\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <header>\r" +
    "\n" +
    "                    Basic Information\r" +
    "\n" +
    "                </header>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <table class=\"form-table\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>Name:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.name\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                focus-me \r" +
    "\n" +
    "                                required/>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>Description:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <textarea \r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.description\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\">\r" +
    "\n" +
    "                            </textarea>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>Keywords:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.keywords\" \r" +
    "\n" +
    "                                aa-label-strategy=\"none\"/>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>Internal Comments:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <textarea \r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.internalComments\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\">\r" +
    "\n" +
    "                            </textarea>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>SFAA Code:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <select\r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.sfaaCodeId\"\r" +
    "\n" +
    "                                options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                            </select>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td>Epic Line Of Business Code:</td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.bondType.epicLineOfBusinessCode\"\r" +
    "\n" +
    "                                aa-label-strategy=\"none\" \r" +
    "\n" +
    "                                ng-maxlength=\"25\"/>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                       \r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Forms</header>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <div ng-if=\"vm.documentCategories && vm.documentCategories.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <uib-tabset>\r" +
    "\n" +
    "                        <uib-tab heading=\"Bond Forms\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div ng-form=\"bondForms\">\r" +
    "\n" +
    "                                <input\r" +
    "\n" +
    "                                    aa-field-group=\"vm.bondType.signatureDateOnBondForm\"\r" +
    "\n" +
    "                                    aa-label=\"Is the signature date (transaction date time) be mapped on the form?\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                                    aa-fg-class=\"top-margin\" \r" +
    "\n" +
    "                                    help-message=\"If 'No' then the effective date will be used as the 'Signed by date' on the POA. If 'Yes' then the 'Signed by date' (transaction date time) will be used on the POA.\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <document-picker\r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.bondFormDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.bondFormDocuments\"\r" +
    "\n" +
    "                                placeholder=\"No Documents Assigned\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.bondFormsToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.bondFormDocumentCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-tab heading=\"Cover Letter\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <document-picker\r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.coverLetterDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.coverLetterDocuments\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.coverLettersToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.coverLetterDocumentCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-tab heading=\"Application Form\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <document-picker\r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.applicationFormDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.applicationFormDocuments\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.applicationsToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.applicationFormDocumentCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-tab heading=\"Renewal Cover Letter\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <document-picker \r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.renewalCoverLetterDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.renewalCoverLetterDocuments\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.renewalCoverLettersToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.renewalCoverLetterCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-tab heading=\"Continuation Certificate\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <document-picker \r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.continuationCertificateDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.continuationCertificateDocuments\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.continuationCertificatesToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.continuationCertificateCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <uib-tab heading=\"Verification Certificate\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <document-picker \r" +
    "\n" +
    "                                document-filter=\"Any\"\r" +
    "\n" +
    "                                control=\"vm.verificationCertificateDocumentPicker\"\r" +
    "\n" +
    "                                documents=\"vm.verificationCertificateDocuments\"\r" +
    "\n" +
    "                                show-status=\"true\"\r" +
    "\n" +
    "                                allow-multiple-selections=\"true\"\r" +
    "\n" +
    "                                files-to-upload=\"vm.verificationCertificatesToUpload\"\r" +
    "\n" +
    "                                on-document-selected=\"vm.documentSelected(selectedDocument)\"\r" +
    "\n" +
    "                                on-document-removed=\"vm.documentRemoved(documentId)\"\r" +
    "\n" +
    "                                document-category=\"vm.verificationCertificateCategory\"\r" +
    "\n" +
    "                                hide-empty-state=\"true\">\r" +
    "\n" +
    "                            </document-picker>\r" +
    "\n" +
    "                        </uib-tab>\r" +
    "\n" +
    "                    </uib-tabset>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Obligee</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <ui-select\r" +
    "\n" +
    "                    ng-show=\"!vm.bondType.obligee\"\r" +
    "\n" +
    "                    ng-model=\"vm.bondType.obligee\"\r" +
    "\n" +
    "                    ng-init=\"$select.label = 'Search Obligees'\"\r" +
    "\n" +
    "                    reset-search-input=\"false\"\r" +
    "\n" +
    "                    name=\"obligee\"\r" +
    "\n" +
    "                    theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <ui-select-match \r" +
    "\n" +
    "                        placeholder=\"Search for obligee...\"\r" +
    "\n" +
    "                        label=\"Obligee\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{$select.selected.name}}\r" +
    "\n" +
    "                    </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <ui-select-choices \r" +
    "\n" +
    "                        repeat=\"obligee in vm.obligeeSearch | filter: {masterObligeeId: null} track by $index \" \r" +
    "\n" +
    "                        refresh=\"vm.searchObligees($select.search)\" \r" +
    "\n" +
    "                        refresh-delay=\"100\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"obligee-drop-down-name\" \r" +
    "\n" +
    "                            ng-bind-html=\"obligee.name | highlight: $select.search\">\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div ng-if=\"!!obligee.physicalAddress\">\r" +
    "\n" +
    "                            {{obligee.physicalAddress}} {{obligee.physicalSuiteAptNumber}} <br/>\r" +
    "\n" +
    "                            {{obligee.physicalCity}},\r" +
    "\n" +
    "                            {{obligee.physicalState}}\r" +
    "\n" +
    "                            {{obligee.physicalZip}} <br/>\r" +
    "\n" +
    "                            {{obligee.phone}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </ui-select-choices>\r" +
    "\n" +
    "                </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"top-margin\" \r" +
    "\n" +
    "                    ng-show=\"!vm.bondType.obligee\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div class=\"flex\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            class=\"left-margin-auto right-margin-auto\" \r" +
    "\n" +
    "                            ng-click=\"vm.showObligeeModal()\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Create New\r" +
    "\n" +
    "                        </button>    \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"top-margin\"\r" +
    "\n" +
    "                    ng-show=\"vm.bondType.obligee\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h5>\r" +
    "\n" +
    "                        Selected Obligee \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ng-click=\"vm.showObligeeModal(vm.bondType.obligee)\" \r" +
    "\n" +
    "                            ng-show=\"vm.currentUser.permissions.canEditObligees\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <i class=\"fa fa-edit\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    {{vm.bondType.obligee.name}} - <a ng-click=\"vm.clearObligee()\">Clear Obligee</a>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div ng-if=\"!!vm.bondType.obligee.physicalAddress\">\r" +
    "\n" +
    "                        {{vm.bondType.obligee.physicalAddress}} {{item.bondType.obligee.physicalSuiteAptNumber}} <br />\r" +
    "\n" +
    "                        {{vm.bondType.obligee.physicalCity}},\r" +
    "\n" +
    "                        {{vm.bondType.obligee.physicalState}}\r" +
    "\n" +
    "                        {{vm.bondType.obligee.physicalZip}} <br />\r" +
    "\n" +
    "                        {{vm.bondType.obligee.phone}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Bond Amount</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.bondAmountType\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                    options=\"vm.bondAmountTypeOptions\" \r" +
    "\n" +
    "                    ng-change=\"vm.bondAmountTypeChanged()\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.bondType.bondAmountType == 'Fixed'\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.bondAmount\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.bondType.bondAmountType == 'Variable'\">\r" +
    "\n" +
    "                    <div class=\"field-flex-box\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.newVariableBondAmount\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button ng-click=\"vm.addBondAmount(detailForm)\">Add</button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <table>\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th colspan=\"2\">Bond Amount(s)</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tr \r" +
    "\n" +
    "                            ng-class=\"{'line-through': amount.isRemoved}\"\r" +
    "\n" +
    "                            ng-repeat=\"amount in vm.bondType.bondTypeVariableBondAmounts\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <td>\r" +
    "\n" +
    "                                {{amount.amount | currency:\"$\":0}}\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <td class=\"text-right\">\r" +
    "\n" +
    "                                <button ng-click=\"vm.removeBondAmount(amount, $index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "                                    <span>Remove</span>\r" +
    "\n" +
    "                                </button>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                        <tr \r" +
    "\n" +
    "                            ng-show=\"!vm.bondType.bondTypeVariableBondAmounts.length && vm.bondType.bondAmountType == 'Variable'\" \r" +
    "\n" +
    "                            class=\"noRecords\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <td colspan=\"2\">\r" +
    "\n" +
    "                                <span>No Variable Bond Amounts</span>\r" +
    "\n" +
    "                                <p>This bond type has no variable amounts defined</p>\r" +
    "\n" +
    "                            </td>\r" +
    "\n" +
    "                        </tr>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.bondType.bondAmountType == 'UserDefined'\">\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.minimumBondAmount\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\"\r" +
    "\n" +
    "                        compare-float=\"vm.bondType.maximumBondAmount\"\r" +
    "\n" +
    "                        comparison-type=\"lessThanOrEqual\"/>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.maximumBondAmount\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" \r" +
    "\n" +
    "                        compare-float=\"vm.bondType.minimumBondAmount\"\r" +
    "\n" +
    "                        comparison-type=\"greaterThanOrEqual\"/>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Expiration</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.statutoryExpirationDateType\" \r" +
    "\n" +
    "                    aa-fg-class=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option Value=\"Continuous\">Bond is Continuous</option>\r" +
    "\n" +
    "                    <option value=\"None\">Term (# of years from effective date)</option>\r" +
    "\n" +
    "                    <option value=\"FixedDate\">Term (Expires on a fixed date)</option>\r" +
    "\n" +
    "                    <option value=\"Birthday\">Term (Expires on principal's birthday)</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"row\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.statutoryExpirationDateType == 'FixedDate'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.statutoryExpirationDateMonth\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.months\" \r" +
    "\n" +
    "                        aa-option-value=\"value\" \r" +
    "\n" +
    "                        aa-option-text=\"label\"  \r" +
    "\n" +
    "                        aa-label=\"Expiration Month\" \r" +
    "\n" +
    "                        aa-fg-class=\"select col-sm-4\" \r" +
    "\n" +
    "                        ng-required=\"vm.bondType.statutoryExpirationDateType == 'FixedDate'\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.statutoryExpirationDateDay\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.days\"\r" +
    "\n" +
    "                        aa-option-value=\"value\"\r" +
    "\n" +
    "                        aa-option-text=\"label\"\r" +
    "\n" +
    "                        aa-label=\"Expiration Day\"\r" +
    "\n" +
    "                        aa-fg-class=\"select col-sm-4\"\r" +
    "\n" +
    "                        ng-required=\"vm.bondType.statutoryExpirationDateType == 'FixedDate'\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.expirationYearType\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.years\" \r" +
    "\n" +
    "                        aa-option-value=\"value\" \r" +
    "\n" +
    "                        aa-option-text=\"label\" \r" +
    "\n" +
    "                        aa-label=\"Expiration Year\" \r" +
    "\n" +
    "                        aa-fg-class=\"select col-sm-4\" \r" +
    "\n" +
    "                        ng-required=\"vm.bondType.statutoryExpirationDateType == 'FixedDate'\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Renewal</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.isRenewable\" \r" +
    "\n" +
    "                    aa-label=\"Can bond be renewed?\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.requiresContinuationCertificate\" \r" +
    "\n" +
    "                    aa-label=\"Does obligee require continuation certificate?\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.isRenewable\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.requiresVerificationCertificate\" \r" +
    "\n" +
    "                    aa-label=\"Should document packet include verification certificate?\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.isRenewable\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.requiresNewBondUponExpiration\" \r" +
    "\n" +
    "                    aa-label=\"Should a new bond be executed instead of renewal?\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                    ng-show=\"!vm.bondType.isRenewable\" />\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Cancellation</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.isCancellable\" \r" +
    "\n" +
    "                    aa-label=\"Can the bond be cancelled?\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.cancellationProvisionDays\" \r" +
    "\n" +
    "                    maxlegnth=\"3\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"integer\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.isCancellable\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.cancellationNotificationReceipientType\" \r" +
    "\n" +
    "                    aa-label=\"Who must be notified?\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.isCancellable\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"Obligee\">Obligee Only</option>\r" +
    "\n" +
    "                    <option value=\"ObligeeAndPrincipal\">Obligee and Principal</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.cancellationNotificationMethodTypes\" \r" +
    "\n" +
    "                    aa-label=\"How must the they be notified?\" \r" +
    "\n" +
    "                    ng-show=\"vm.bondType.isCancellable\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"StandardMail\">Standard Mail</option>\r" +
    "\n" +
    "                    <option value=\"CertifiedMail\">Certified Mail</option>\r" +
    "\n" +
    "                    <option value=\"RegisteredMail\">Registered Mail</option>\r" +
    "\n" +
    "                    <option value=\"WrittenNotice\">Written Notice</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Application Questions</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"field-flex-box\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <ui-select \r" +
    "\n" +
    "                        ng-model=\"vm.questionSearch.selected\" \r" +
    "\n" +
    "                        ng-init=\"$select.label = 'Search Questions'\" \r" +
    "\n" +
    "                        reset-search-input=\"false\" \r" +
    "\n" +
    "                        name=\"questionSelection\"\r" +
    "\n" +
    "                        theme=\"app/components/uiSelect/a3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-match \r" +
    "\n" +
    "                            placeholder=\"Search for application questions by unique name...\" \r" +
    "\n" +
    "                            label=\"Search Questions\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{$select.selected.name}}\r" +
    "\n" +
    "                        </ui-select-match>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <ui-select-choices \r" +
    "\n" +
    "                            repeat=\"question in vm.questionSearch track by $index\" \r" +
    "\n" +
    "                            refresh=\"vm.searchQuestions($select.search)\" \r" +
    "\n" +
    "                            refresh-delay=\"0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-bind-html=\"question.name | highlight: $select.search\"></div>\r" +
    "\n" +
    "                        </ui-select-choices>\r" +
    "\n" +
    "                    </ui-select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"col-sm-1\"\r" +
    "\n" +
    "                        ng-click=\"vm.addQuestion()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Add\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table ng-show=\"vm.bondType.bondTypeMasterApplicationQuestions.length\">\r" +
    "\n" +
    "                    <thead>\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <th>Question</th>\r" +
    "\n" +
    "                        <th style=\"width: 160px;\">&nbsp;</th>\r" +
    "\n" +
    "                        <th>&nbsp;</th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                    </thead>\r" +
    "\n" +
    "                    <tr \r" +
    "\n" +
    "                        ng-class=\"{'line-through': question.isRemoved}\"\r" +
    "\n" +
    "                        ng-repeat=\"question in vm.bondType.bondTypeMasterApplicationQuestions\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <a \r" +
    "\n" +
    "                                target=\"_blank\" \r" +
    "\n" +
    "                                ui-sref=\"main.applicationQuestionsDetail({ id: question.masterApplicationQuestionId })\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                {{question.name}}\r" +
    "\n" +
    "                                <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <div class=\"small\">{{question.questionText}}</div>\r" +
    "\n" +
    "                            </a>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td>\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"question.isRequired\" \r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                                aa-label=\"Required\" \r" +
    "\n" +
    "                                aa-fg-class=\"\" \r" +
    "\n" +
    "                                aa-input-wrapper-class=\"pull-right\" />\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                        <td class=\"text-right\">\r" +
    "\n" +
    "                            <button ng-click=\"vm.removeQuestion(question, $index)\">\r" +
    "\n" +
    "                                <i class=\"fa fa-times\"></i> \r" +
    "\n" +
    "                                <span>Remove</span>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <empty-state\r" +
    "\n" +
    "                    ng-show=\"!vm.bondType.bondTypeMasterApplicationQuestions.length\" \r" +
    "\n" +
    "                    message=\"This bond type has no application questions\">\r" +
    "\n" +
    "                </empty-state>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Additional Information</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.hideInEProducer\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.isElectronic\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                        aa-label=\"Electronic Surety Bond (ESB)\"\r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.allowsElectronicSignature\" \r" +
    "\n" +
    "                        aa-label=\"AIF electronic signature allowed\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.allowsPrincipalElectronicSignature\" \r" +
    "\n" +
    "                        aa-label=\"Principal electronic signature allowed\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalWitnessSignatureRequired\" \r" +
    "\n" +
    "                        aa-label=\"Principal Witness Signature Required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalWitnessPrintedNameRequired\" \r" +
    "\n" +
    "                        aa-label=\"Principal Witness Printed Name Required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalNotaryAcknowledgementRequired\" \r" +
    "\n" +
    "                        aa-label=\"Principal Notary Acknowledgement Required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.agencyNotaryAcknowledgementRequired\" \r" +
    "\n" +
    "                        aa-label=\"Agency Notary Acknowledgement Required\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalCorporateSealRequired\" \r" +
    "\n" +
    "                        aa-label=\"Principal Corporate Seal Required\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalRetainsOriginal\" \r" +
    "\n" +
    "                        aa-label=\"Principal Retains Original\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.principalSignatureLocationRequired\" \r" +
    "\n" +
    "                        aa-label=\"Principal Signature Location Required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.allowsBackdating\" \r" +
    "\n" +
    "                        aa-label=\"Allows Backdating\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <section>\r" +
    "\n" +
    "                <header>Application Settings</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.companyInformationIsApplicable\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-show=\"vm.bondType.companyInformationIsApplicable\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <h5>Company Required Fields</h5>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyNameFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyDbaFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyTypeFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyPhoneFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyDateFormedFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyNumberOfOwnersFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyNumberOfEmployeesFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyFaxFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyEmailFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyAddressFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyCountyFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyStateOfIncorporationFieldStatus\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.companyFEINFieldStatus\" \r" +
    "\n" +
    "                            aa-label=\"Is Company FEIN Required\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                            options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                            aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                        </select>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5>Principal Required Fields</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personMiddleNameFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Middle name required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personPrefixFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Prefix required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSuffixFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Suffix required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSSNFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"SSN required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personDateOfBirthFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Date of birth required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personDriversLicenseNumberFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Drivers license number required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personDriversLicenseStateFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Drivers license state required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personHomePhoneFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Home phone required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personCellPhoneFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Cell phone required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personFaxFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Fax required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personGenderFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Gender required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personAddressFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Address required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personCountyFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"County required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personJobTitleFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Job title required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personMaritalStatusFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Marital status required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseNameFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse name required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseSsnFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse SSN required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseEmailFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse email required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseCellPhoneFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse cell phone required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseDateOfBirthFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse date of birth required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personSpouseGenderFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Spouse gender required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personResidenceTypeFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Residence type required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personDateMovedToResidenceFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Date moved required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personEmployerNameFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Employer name required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personEmployerAddressFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Employer address required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personEmployerCountyFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Employer county required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personEmployerPhoneFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Employer phone required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personYearsExperienceFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Industry Experience required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personOwnershipPercentFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"Company ownership percent required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <select \r" +
    "\n" +
    "                        aa-field-group=\"vm.bondType.personIsUSCitizenFieldStatus\" \r" +
    "\n" +
    "                        aa-label=\"US Citizen response required\" \r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                        options=\"vm.fieldStatusOptions\" \r" +
    "\n" +
    "                        aa-fg-class=\"col-sm-6 col-md-4\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5>Multiple Principals</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-field-group=\"vm.bondType.multiplePeopleAllowed\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h5>Allowed Name On Bond</h5>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"row\">\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowFirstMiddleLast\" \r" +
    "\n" +
    "                            aa-label=\"First, Middle & Last Name\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowCompanyDBA\" \r" +
    "\n" +
    "                            aa-label=\"Company DBA\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowOtherA3\" \r" +
    "\n" +
    "                            aa-label=\"Other in A3 Dashboard\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowCombinePrincipals\" \r" +
    "\n" +
    "                            aa-label=\"Combine multiple individuals\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-md-6\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowFirstLast\" \r" +
    "\n" +
    "                            aa-label=\"First & Last Name\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowCompanyName\" \r" +
    "\n" +
    "                            aa-label=\"Company Name\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondAllowOtherEProducer\" \r" +
    "\n" +
    "                            aa-label=\"Other in eProducer\" \r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"col-md-12\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <textarea \r" +
    "\n" +
    "                            aa-field-group=\"vm.bondType.nameOnBondInstructions\" \r" +
    "\n" +
    "                            required>\r" +
    "\n" +
    "                        </textarea>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </section>  \r" +
    "\n" +
    "\r" +
    "\n" +
    "        </perfect-scrollbar>    \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"sticky-page-footer\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <button \r" +
    "\n" +
    "            ui-sref=\"main.bondTypes\" \r" +
    "\n" +
    "            class=\"white right-margin\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-submit-form=\"vm.saveClick()\" \r" +
    "\n" +
    "            type=\"submit\" \r" +
    "\n" +
    "            value=\"Save\" />\r" +
    "\n" +
    "    </div>   \r" +
    "\n" +
    "</ng-form>"
  );


  $templateCache.put('app/states/common/bondTypes/bondTypesReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"bottom-margin flex\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search for bond types by name...\"\r" +
    "\n" +
    "           uib-tooltip=\"Search Bond types\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"bond-types-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <bond-types-report-filter-dropdown \r" +
    "\n" +
    "        class=\"flex left-margin\"\r" +
    "\n" +
    "        table=\"vm.table\">\r" +
    "\n" +
    "    </bond-types-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.exportExcelReport()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"samll\"\r" +
    "\n" +
    "                    ui-sref=\"main.bondTypesDetail\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Bond Type\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"samll\"\r" +
    "\n" +
    "                    ng-click=\"vm.requestNewBondType()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Bond Type Request\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Sfaa Code\"\r" +
    "\n" +
    "                    sort-expression=\"SfaaCodes.Code\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <i\r" +
    "\n" +
    "                    class=\"fa fa-exclamation-triangle red\"\r" +
    "\n" +
    "                    ng-show=\"item.flaggedForReview\"\r" +
    "\n" +
    "                    uib-tooltip=\"Flagged for review\"\r" +
    "\n" +
    "                    tooltip-placement=\"right\">\r" +
    "\n" +
    "                </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a ui-sref=\"main.bondTypesDetail({ id: item.id })\">\r" +
    "\n" +
    "                    {{item.name}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    " \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"!item.isActive\"\r" +
    "\n" +
    "                    class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    - Inactive\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{item.sfaaCode}} - {{item.sfaaDescription | limitTo:50}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <td class=\"fit-content text-right\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                        ng-click=\"vm.promptToDelete(item,$index)\" \r" +
    "\n" +
    "                        uib-tooltip=\"Delete Bond Type\"\r" +
    "\n" +
    "                        ng-disabled=\"item.hasRelatedRecords\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table> \r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/bondTypes/bondTypesReportFilterDropdown/bondTypesReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"bondTypesReportFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}}) </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Requesting State</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select \r" +
    "\n" +
    "                    id=\"address-physical-state\"\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.requisitioningState\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"state\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-unselected-text=\"All States\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>  \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "		<li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Is Active</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isActive\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\"> Any </option>\r" +
    "\n" +
    "                    <option value=\"true\"> Active </option>\r" +
    "\n" +
    "                    <option value=\"false\"> Inactive </option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Created Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.startDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.endDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> SFAA Code </label>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.sfaaCodeId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/changePassword/changePassword.html',
    "<div class=\"a-container\">\r" +
    "\n" +
    "    <div class=\"a-background\">\r" +
    "\n" +
    "        <div id=\"particles-js\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"a-message\"\r" +
    "\n" +
    "        ng-show=\"(vm.formSubmitted && !vm.password) || (changePasswordForm.confirmPassword.$dirty && changePasswordForm.confirmPassword.$invalid) || vm.message\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"vm.message\">\r" +
    "\n" +
    "            {{ vm.message }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"vm.formSubmitted && !vm.password\">\r" +
    "\n" +
    "            Please provide your new password\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"(changePasswordForm.confirmPassword.$dirty && changePasswordForm.confirmPassword.$invalid)\">\r" +
    "\n" +
    "            Your passwords don't match\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-box\">\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            novalidate\r" +
    "\n" +
    "            name=\"changePasswordForm\"\r" +
    "\n" +
    "            ng-submit=\"vm.changePassword(changePasswordForm.$valid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p>Just give us your new password and we'll log you in</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                class=\"form-control\"\r" +
    "\n" +
    "                placeholder=\"Password\"\r" +
    "\n" +
    "                ng-model=\"vm.password\"\r" +
    "\n" +
    "                type=\"password\"\r" +
    "\n" +
    "                ng-class=\"{ error: vm.formSubmitted && !vm.password }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                class=\"form-control\"\r" +
    "\n" +
    "                placeholder=\"Confirm Password\"\r" +
    "\n" +
    "                ng-model=\"vm.confirmPassword\"\r" +
    "\n" +
    "                type=\"password\"\r" +
    "\n" +
    "                match=\"vm.password\"\r" +
    "\n" +
    "                name=\"confirmPassword\"\r" +
    "\n" +
    "                ng-class=\"{ error: (changePasswordForm.confirmPassword.$dirty && changePasswordForm.confirmPassword.$invalid) || vm.formSubmitted }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"button-container\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    type=\"button\"\r" +
    "\n" +
    "                    value=\"Cancel\"\r" +
    "\n" +
    "                    class=\"btn\"\r" +
    "\n" +
    "                    data-ng-click=\"vm.cancel()\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    value=\"OK\"\r" +
    "\n" +
    "                    class=\"btn\"\r" +
    "\n" +
    "                    id=\"submit\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-footer\"></div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/columnSelector/dashboardWidgetColumnSelector.html',
    "<div \r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isDropdownOpen\"\r" +
    "\n" +
    "    on-toggle=\"vm.dropdownToggled(open)\"\r" +
    "\n" +
    "    class=\"dropdown column-selector-dropdown\"\r" +
    "\n" +
    "    ng-form=\"columnSelect\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <a uib-dropdown-toggle>\r" +
    "\n" +
    "        Columns \r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"column-selection-item\" \r" +
    "\n" +
    "            ng-repeat=\"column in vm.widgetColumns\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"column.visible\"\r" +
    "\n" +
    "                aa-label=\"{{column.label}}\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"check\"/>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"footer\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"gray small\" \r" +
    "\n" +
    "                ng-click=\"vm.hideColumnsSelectionDropdown()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\" \r" +
    "\n" +
    "                ng-click=\"vm.applyColumnSelection()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/dashboard.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"dropdown\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                editable-text=\"vm.currentDashboard.name\"\r" +
    "\n" +
    "                onaftersave=\"vm.setDashboardName(vm.currentDashboard.name)\"\r" +
    "\n" +
    "                e-form=\"vm.dashboardNameForm\"\r" +
    "\n" +
    "                class=\"no-left-padding\">\r" +
    "\n" +
    "                {{ vm.currentDashboard.name || \"Select Dashboard\" }}\r" +
    "\n" +
    "                <i class=\"fa fa-caret-down\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul\r" +
    "\n" +
    "                class=\"dropdown-menu dropdown-menu-left sortable\"\r" +
    "\n" +
    "                ng-sortable=\"{\r" +
    "\n" +
    "                    animation: 350, \r" +
    "\n" +
    "                    chosenClass: 'sortable-chosen', \r" +
    "\n" +
    "                    handle: '.grab-handle', \r" +
    "\n" +
    "                    forceFallback: true, \r" +
    "\n" +
    "                    onEnd: vm.endSortDashboards \r" +
    "\n" +
    "                }\">\r" +
    "\n" +
    "                <li\r" +
    "\n" +
    "                    ng-repeat=\"dashboard in vm.dashboardConfiguration.dashboards\">\r" +
    "\n" +
    "                    <i class=\"fa fa-ellipsis-v grab-handle\"></i>\r" +
    "\n" +
    "                    <a ng-click=\"vm.selectDashboard($index)\">\r" +
    "\n" +
    "                        {{dashboard.name}}\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <nav>\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-click=\"vm.dashboardNameForm.$show()\"\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible\">\r" +
    "\n" +
    "                <i class=\"fa fa-pencil-alt\"></i>\r" +
    "\n" +
    "                Rename\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible || vm.dashboardConfiguration.dashboards.length <= 1\"\r" +
    "\n" +
    "                ng-click=\"vm.removeDashboard()\">\r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                Delete\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span\r" +
    "\n" +
    "                class=\"nav-divider\"\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible\">\r" +
    "\n" +
    "                |\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible\"\r" +
    "\n" +
    "                ng-click=\"vm.cloneDashboard()\">\r" +
    "\n" +
    "                <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "                Clone\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span\r" +
    "\n" +
    "                class=\"nav-divider\"\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible\">\r" +
    "\n" +
    "                |\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-hide=\"vm.dashboardNameForm.$visible\"\r" +
    "\n" +
    "                ng-click=\"vm.addDashboard()\">\r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                New Dashboard\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </nav>\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        ng-click=\"vm.refresh()\"\r" +
    "\n" +
    "        class=\"refresh-button\"\r" +
    "\n" +
    "        uib-tooltip=\"Refresh Dashboard\"\r" +
    "\n" +
    "        tooltip-placement=\"left\">\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"small right-margin-big dashboard-auto-dashboard-dropdown\"\r" +
    "\n" +
    "        options=\"vm.refreshDropdownOptions\"\r" +
    "\n" +
    "        ,\r" +
    "\n" +
    "        selected-option=\"vm.dashboardConfiguration.options.selectedRefreshOption\"\r" +
    "\n" +
    "        ,\r" +
    "\n" +
    "        on-selected=\"vm.refreshOptionSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-click=\"vm.showWidgetModal()\"\r" +
    "\n" +
    "        class=\"text-right small\">\r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "        <span>Add Widget</span>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div gridster=\"vm.gridsterOpts\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.currentDashboard.widgets.length == 0\"\r" +
    "\n" +
    "        class=\"no-results no-wigdets\">\r" +
    "\n" +
    "        <div class=\"initial-state\">\r" +
    "\n" +
    "            <div class=\"initial-state-body\">\r" +
    "\n" +
    "                <h3>There aren't any widgets in this dashboard, yet.</h3>\r" +
    "\n" +
    "                <p>&nbsp;</p>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    ng-click=\"vm.showWidgetModal()\"\r" +
    "\n" +
    "                    class=\"white\">\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    <span>Add Widget</span>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul ng-show=\"vm.currentDashboard.widgets.length > 0\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            gridster-item=\"widget\"\r" +
    "\n" +
    "            ng-repeat=\"widget in vm.currentDashboard.widgets track by widget.key\"\r" +
    "\n" +
    "            ng-class=\"vm.getWidgetBorderColor(widget.options.color)\">\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                dashboard-widget=\"widget\"\r" +
    "\n" +
    "                widget-index=\"$index\"\r" +
    "\n" +
    "                remove-widget=\"vm.removeWidget($index)\"\r" +
    "\n" +
    "                clone-widget=\"vm.cloneWidget($index)\"\r" +
    "\n" +
    "                class=\"dashboard-widget-wrapper\"></div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgetBases/chartWidget/chartWidget.html',
    "<div class=\"chart-widget\">\r" +
    "\n" +
    "    <div class=\"dashboard-widget-header\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h2 class=\"dashboard-widget-title\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                editable-text=\"vm.widget.options.title\" \r" +
    "\n" +
    "                onaftersave=\"vm.setTitle(vm.widget.options.title)\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                {{ vm.widget.options.title }}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"dashboard-widget-hover-options\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                uib-dropdown \r" +
    "\n" +
    "                class=\"dropdown text-option\" \r" +
    "\n" +
    "                ng-show=\"vm.dashboards.length > 1\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    uib-dropdown-toggle \r" +
    "\n" +
    "                    uib-tooltip=\"Send to\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-share\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <ul class=\"dropdown-menu dropdown-menu-left\">\r" +
    "\n" +
    "                    <li ng-repeat=\"dashboard in vm.dashboards\">\r" +
    "\n" +
    "                        <a ng-click=\"vm.moveWidget($index)\">{{dashboard.name}}</a>\r" +
    "\n" +
    "                    </li>\r" +
    "\n" +
    "                </ul>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <dashboard-widget-colors \r" +
    "\n" +
    "                class=\"flex\"\r" +
    "\n" +
    "                widget=\"vm.widget\">\r" +
    "\n" +
    "            </dashboard-widget-colors>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.cloneWidget()\" \r" +
    "\n" +
    "                uib-tooltip=\"Clone\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.removeWidget()\" \r" +
    "\n" +
    "                uib-tooltip=\"Remove\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"dashboard-widget-body\">\r" +
    "\n" +
    "        <div ng-transclude></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgetBases/standardWidget/standardWidget.html',
    "<div class=\"standard-widget\">\r" +
    "\n" +
    "    <div class=\"dashboard-widget-header\">\r" +
    "\n" +
    "        <h2 class=\"dashboard-widget-title\">\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                editable-text=\"vm.widget.options.title\" \r" +
    "\n" +
    "                onaftersave=\"vm.setTitle(vm.widget.options.title)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                {{ vm.widget.options.title }}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"dashboard-widget-hover-options\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                uib-dropdown \r" +
    "\n" +
    "                class=\"dropdown text-option\" \r" +
    "\n" +
    "                ng-show=\"vm.dashboards.length > 1\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    uib-dropdown-toggle \r" +
    "\n" +
    "                    uib-tooltip=\"Send to\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-share\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <ul class=\"dropdown-menu dropdown-menu-left\">\r" +
    "\n" +
    "                    <li ng-repeat=\"dashboard in vm.dashboards\">\r" +
    "\n" +
    "                        <a ng-click=\"vm.moveWidget($index)\">{{dashboard.name}}</a>\r" +
    "\n" +
    "                    </li>\r" +
    "\n" +
    "                </ul>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <dashboard-widget-colors \r" +
    "\n" +
    "                class=\"flex\"\r" +
    "\n" +
    "                widget=\"vm.widget\">\r" +
    "\n" +
    "            </dashboard-widget-colors>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.cloneWidget()\" \r" +
    "\n" +
    "                uib-tooltip=\"Clone\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.removeWidget()\" \r" +
    "\n" +
    "                uib-tooltip=\"Remove\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"dashboard-widget-body\">\r" +
    "\n" +
    "        <div ng-transclude></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgetBases/valueWidget/valueWidget.html',
    "<div class=\"value-widget\">\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"dashboard-widget-header\" \r" +
    "\n" +
    "        ng-show=\"vm.editMode == false\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <div class=\"margin-left-auto dashboard-widget-hover-options\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    uib-dropdown \r" +
    "\n" +
    "                    class=\"dropdown text-option\" \r" +
    "\n" +
    "                    ng-show=\"vm.dashboards.length > 1\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        uib-dropdown-toggle \r" +
    "\n" +
    "                        uib-tooltip=\"Send to\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-share\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <ul class=\"dropdown-menu dropdown-menu-left\">\r" +
    "\n" +
    "                        <li ng-repeat=\"dashboard in vm.dashboards\">\r" +
    "\n" +
    "                            <a ng-click=\"vm.moveWidget($index)\">{{dashboard.name}}</a>\r" +
    "\n" +
    "                        </li>\r" +
    "\n" +
    "                    </ul>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <dashboard-widget-colors widget=\"vm.widget\"></dashboard-widget-colors>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.cloneWidget()\" \r" +
    "\n" +
    "                    uib-tooltip=\"Clone\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <i class=\"fa fa-copy\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-click=\"vm.removeWidget()\" \r" +
    "\n" +
    "                    uib-tooltip=\"Remove\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"dashboard-widget-body\">\r" +
    "\n" +
    "        <div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"label-wrapper\">\r" +
    "\n" +
    "                <h3 class=\"thin\">\r" +
    "\n" +
    "                    <span \r" +
    "\n" +
    "                        editable-text=\"vm.widget.options.title\" \r" +
    "\n" +
    "                        onshow=\"vm.toggleWidgetTitleFormBg()\" \r" +
    "\n" +
    "                        onaftersave=\"vm.setTitle(vm.widget.options.title)\" \r" +
    "\n" +
    "                        onhide=\"vm.toggleWidgetTitleFormBg()\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{ vm.widget.options.title }}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </h3>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"widget-edit-background\" \r" +
    "\n" +
    "                ng-show=\"vm.editMode\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-transclude></div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgetColorSelector/dashboardWidgetColors.html',
    "<div \r" +
    "\n" +
    "    uib-dropdown \r" +
    "\n" +
    "    class=\"dropdown text-option narrow-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        uib-dropdown-toggle \r" +
    "\n" +
    "        uib-tooltip=\"Border Color\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-paint-brush\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li ng-repeat=\"color in vm.widgetColors\" \r" +
    "\n" +
    "            class=\"color-option {{color.value}}\" \r" +
    "\n" +
    "            ng-class=\"{'selected': vm.widget.options.color.value == color.value}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ng-click=\"vm.setWidgetBorderColor(color)\">\r" +
    "\n" +
    "                <span>\r" +
    "\n" +
    "                </span> \r" +
    "\n" +
    "                {{color.label}}\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgetFilter/dashboardWidgetFilter.html',
    "<div \r" +
    "\n" +
    "    uib-dropdown \r" +
    "\n" +
    "    class=\"dropdown filter-btn\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <a uib-dropdown-toggle>\r" +
    "\n" +
    "        Filters\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"vm.filterCollection.getActiveFilterCount()\">\r" +
    "\n" +
    "            ({{vm.filterCollection.getActiveFilterCount()}})\r" +
    "\n" +
    "        </span> \r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"filters dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-repeat=\"filter in vm.filterCollection.getEnabledFilters()\">\r" +
    "\n" +
    "            <span class=\"tag-button-wrapper\">\r" +
    "\n" +
    "                <span class=\"tag-button\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i \r" +
    "\n" +
    "                        ng-click=\"vm.removeFilter(filter)\" \r" +
    "\n" +
    "                        class=\"fa fa-times\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span>{{filter | filterString}}</span>\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li ng-show=\"!vm.filterCollection.getActiveFilterCount()\">\r" +
    "\n" +
    "            <i class=\"no-filters-indicator\">\r" +
    "\n" +
    "                No filters applied\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <a ng-click=\"vm.showFiltersModal()\">\r" +
    "\n" +
    "                <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "                Edit Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/activityList/activityListWidget.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"activityListForm\"\r" +
    "\n" +
    "        class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "            aa-field-group=\"vm.activitiesTable.queryOptions.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.activitiesTable.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "            aa-label=\"Search Activities\"\r" +
    "\n" +
    "            uib-tooltip=\"Search by bond number, description or user\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small\"\r" +
    "\n" +
    "            record-count=\"vm.activitiesTable.pager.totalRecords\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <activity-list-filter-dropdown\r" +
    "\n" +
    "            class=\"left-margin flex\"\r" +
    "\n" +
    "            table=\"vm.activitiesTable\">\r" +
    "\n" +
    "        </activity-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <show-activity-modal-button\r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            button-class=\"mini\">\r" +
    "\n" +
    "        </show-activity-modal-button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar class=\"scroller\">\r" +
    "\n" +
    "        <activity-list\r" +
    "\n" +
    "            table=\"vm.activitiesTable\"\r" +
    "\n" +
    "            on-activities-modified=\"vm.loadActivities()\">\r" +
    "\n" +
    "        </activity-list>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/activityTrackerAgent/dashboardWidgetActivityTracker.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"filterForm\"\r" +
    "\n" +
    "    class=\"meta-bar small flex bottom-margin-thick\">\r" +
    "\n" +
    "   \r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.transactionType\"\r" +
    "\n" +
    "        options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "        on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"fill text-right v-center\">\r" +
    "\n" +
    "        Date range: {{ vm.dateFilter.startDate | dateOnly }} - {{ vm.dateFilter.endDate | dateOnly }}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"chartdiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/activityTrackerCarrier/dashboardWidgetActivityTrackerCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"meta-bar small clearfix\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <form \r" +
    "\n" +
    "        name=\"chartForm\" \r" +
    "\n" +
    "        class=\"text-left\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"col-xs-8 ng-hide\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"col-xs-4 field-wrapper\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    date-range-picker \r" +
    "\n" +
    "                    class=\"date-picker\" \r" +
    "\n" +
    "                    type=\"text\" \r" +
    "\n" +
    "                    ng-model=\"vm.filters.date\" \r" +
    "\n" +
    "                    options=\"{locale: {format: 'MMMM D, YYYY'}, opens: 'left'}\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"chartdiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondFormCount/dashboardWidgetBondFormCount.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">{{vm.value | number}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.status\"\r" +
    "\n" +
    "    options=\"vm.statusOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.statusOptionSelection(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondListAgent/dashboardWidgetBondsAgent.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"v-center h-center\"\r" +
    "\n" +
    "            ui-sref=\"main.purchasedBondsReport\">\r" +
    "\n" +
    "            View Report\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Number\"\r" +
    "\n" +
    "                            sort-expression=\"BondNumber\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Status\"\r" +
    "\n" +
    "                            sort-expression=\"status\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                            class=\"text-center\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.term.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Term\"\r" +
    "\n" +
    "                            sort-expression=\"expirationDate\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                            class=\"text-center\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\"\r" +
    "\n" +
    "                            sort-expression=\"quote/carrierSystemAccount/companyName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted On\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.shippingButton.visible\"></th>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bond in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        {{bond.bondNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <span ng-bind-html=\"bond | bondStatus\"> </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: bond.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            {{bond.nameOnBond}}\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{bond.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.bondAmount.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        {{bond.bondAmount | currency: '$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.term.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        {{bond.effectiveDate | dateOnly}} -\r" +
    "\n" +
    "                        {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        {{bond.quote.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{bond.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{bond.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.shippingButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-show=\"bond.bondTransactions[0].mailTo\"\r" +
    "\n" +
    "                            ng-click=\"vm.showShippingModal(bond.bondTransactions[0])\"\r" +
    "\n" +
    "                            class=\"nowrap\">\r" +
    "\n" +
    "                            Shipping Info\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"bond.id\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No bonds were found matching your search\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondRenewAgent/dashboardWidgetBondsToRenewAgent.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Number\"\r" +
    "\n" +
    "                            sort-expression=\"bondNumber\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Status\"\r" +
    "\n" +
    "                            sort-expression=\"status\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.daysTillCancellation.visible\">\r" +
    "\n" +
    "                        Days till Cancellation\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.renewalStatus.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Renewal Status\"\r" +
    "\n" +
    "                            sort-expression=\"renewalStatus\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.bondAmount.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.term.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Term\"\r" +
    "\n" +
    "                            sort-expression=\"expirationDate\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible \">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\"\r" +
    "\n" +
    "                            sort-expression=\"quote/carrierSystemAccount/companyName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bond in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        {{bond.bondNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <span ng-bind-html=\"bond | bondStatus\"></span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.daysTillCancellation.visible\">\r" +
    "\n" +
    "                        {{bond.dateOfCancellationNotice | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.renewalStatus.visible\">\r" +
    "\n" +
    "                        <span ng-show=\"bond.renewalStatus === 'None'\">\r" +
    "\n" +
    "                            Not Started\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-show=\"bond.renewalStatus === 'ApplicationSubmitted'\">\r" +
    "\n" +
    "                            Started\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: bond.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            {{bond.nameOnBond}}\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{bond.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.bondAmount.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        {{bond.bondAmount | currency: '$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.term.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        {{bond.effectiveDate | dateOnly}} -\r" +
    "\n" +
    "                        {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        {{bond.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"\r" +
    "\n" +
    "                        class=\"text-right\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"bond.id\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondsByCarrier/dashboardWidgetBondsByCarrierBreakdownAgent.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    id=\"pieChartDiv{{vm.widgetIndex}}\"\r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondsCarrier/dashboardWidgetBondsCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"v-center h-center\"\r" +
    "\n" +
    "            ui-sref=\"main.purchasedBondsReport\">\r" +
    "\n" +
    "            View Report\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table\r" +
    "\n" +
    "            class=\"bottom-margin\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Number\"\r" +
    "\n" +
    "                            sort-expression=\"BondNumber\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                            class=\"text-center\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\"\r" +
    "\n" +
    "                            sort-expression=\"quote/carrierSystemAccount/companyName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bond in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        {{bond.bondNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.principal.visible\">\r" +
    "\n" +
    "                        {{bond.nameOnBond}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        {{bond.bondAmount | currency: '$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        {{bond.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{bond.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"bond.id\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No bonds were found matching your search\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionAgent/dashboardWidgetBondTransactionsAgent.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"v-center h-center\"\r" +
    "\n" +
    "            ui-sref=\"main.transactionsReport\">\r" +
    "\n" +
    "            View Report\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Number\"\r" +
    "\n" +
    "                            sort-expression=\"bondNumber\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.transactionType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Type\"\r" +
    "\n" +
    "                            sort-expression=\"transactionType\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bond/bondType/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\"\r" +
    "\n" +
    "                            sort-expression=\"bond/carrierSystemAccount/companyName\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.premium.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Premium\"\r" +
    "\n" +
    "                            sort-expression=\"premium\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.refundedPremium.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Refunded Premium\"\r" +
    "\n" +
    "                            sort-expression=\"refundedPremium\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.commission.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Commission\"\r" +
    "\n" +
    "                            sort-expression=\"commission\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.fees.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Fees\"\r" +
    "\n" +
    "                            sort-expression=\"fees\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.effectiveDate.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"effectiveDate\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Occurred\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Occurred On\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.shippingButton.visible\"></th>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.bondDetailsButton.visible\"></th>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.documentsButton.visible\"></th>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.exportButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bondTransaction in vm.table.data\">\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <i\r" +
    "\n" +
    "                            class=\"fa fa-flag red\"\r" +
    "\n" +
    "                            ng-show=\"bondTransaction.expedited\"\r" +
    "\n" +
    "                            tooltip-placement=\"right\"\r" +
    "\n" +
    "                            uib-tooltip=\"Expedited Shipping and Processing Requested\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.bondNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.transactionType.visible\">\r" +
    "\n" +
    "                        <a show-bond-detail-modal=\"bondTransaction.bondId\">\r" +
    "\n" +
    "                            {{bondTransaction | transactionType}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: bondTransaction.bond.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            {{bondTransaction.nameOnBond}}\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.bond.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.bond.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.premium.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.premium | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.refundedPremium.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.refundedPremium | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.commission.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.commission | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.fees.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.fees | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.effectiveDate.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.shippingButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-disabled=\"!bondTransaction.mailTo\"\r" +
    "\n" +
    "                            ng-click=\"showShippingModal(r)\"\r" +
    "\n" +
    "                            class=\"nowrap\">\r" +
    "\n" +
    "                            Shipping Info\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.bondDetailsButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"bondTransaction.bondId\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.documentsButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-show=\"bondTransaction.transactionType !== 'Import'\"\r" +
    "\n" +
    "                            show-download-documents-modal=\"bondTransaction.id\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Documents\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        class=\"fit-content\"\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.exportButton.visible\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-show=\"vm.epicIntegrationIsActive\"\r" +
    "\n" +
    "                            ng-disabled=\"bondTransaction.exportToEpicStatus == 'Success' || transaction.exportToEpicStatus == 'Error'\"\r" +
    "\n" +
    "                            export-to-epic=\"{transaction: transaction, busyIndicator: vm.busyIndicator}\"\r" +
    "\n" +
    "                            uib-tooltip=\"Export to Epic\">\r" +
    "\n" +
    "                            <i class=\"fa fa-cloud-upload-alt\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        class=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionCarrier/dashboardWidgetBondTransactionsCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"v-center h-center\"\r" +
    "\n" +
    "            ui-sref=\"main.oldtransactionsReport\">\r" +
    "\n" +
    "            View Report\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center block\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Number\"\r" +
    "\n" +
    "                            sort-expression=\"bondNumber\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.transactionType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Type\"\r" +
    "\n" +
    "                            sort-expression=\"transactionType\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.effectiveDate.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"effectiveDate\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Occurred\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Occurred On\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bondTransaction in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondNumber.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.bondNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.transactionType.visible\">\r" +
    "\n" +
    "                        {{bondTransaction | transactionType}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.nameOnBond}}\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-show=\"bondTransaction.bond.quote.application.applicationType === 'BondAccount'\">\r" +
    "\n" +
    "                            (Account)\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.effectiveDate.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{bondTransaction.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"bondTransaction.bondId\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        class=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionCount/dashboardWidgetTransactionCount.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">{{vm.value | number}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.transactionType\"\r" +
    "\n" +
    "    options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionCountCarrier/dashboardWidgetTransactionCountCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">{{vm.value | number}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.transactionType\"\r" +
    "\n" +
    "    options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionSum/dashboardWidgetTransactionSum.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">\r" +
    "\n" +
    "    {{vm.value | currency: '$'}}\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.transactionType\"\r" +
    "\n" +
    "    options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTransactionSumCarrier/dashboardWidgetTransactionSumCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">\r" +
    "\n" +
    "    {{vm.value | currency: '$'}}\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.transactionType\"\r" +
    "\n" +
    "    options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTypeChangesCount/dashboardWidgetBondTypeChangesCount.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\"> {{vm.value | number}} </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.type\"\r" +
    "\n" +
    "    options=\"vm.typeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.typeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTypeConfigurationCount/dashboardWidgetBondTypeConfigurationCount.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">{{vm.value | number}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTypeConfigurationList/dashboardWidgetBondTypeConfigurationList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"    \r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.flaggedForReview.visible\"></th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.isActive.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Is Active\"\r" +
    "\n" +
    "                            sort-expression=\"isActive\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>                   \r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.sfaaCode.visible\" \r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"SFAA Code\" \r" +
    "\n" +
    "                            sort-expression=\"sfaaCode/code\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">                        \r" +
    "\n" +
    "                        </table-sort-button></table-sort-button>                  \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.sfaaDescription.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Sfaa Description\" \r" +
    "\n" +
    "                            sort-expression=\"sfaaCode/description\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">                        \r" +
    "\n" +
    "                        </table-sort-button></table-sort-button>                            \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Created\" \r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">                        \r" +
    "\n" +
    "                        </table-sort-button></table-sort-button>                                \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.createdDateTime.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Created Date\" \r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">                        \r" +
    "\n" +
    "                        </table-sort-button></table-sort-button>                              \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.progress.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Progress\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bondType in vm.table.data\">\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.flaggedForReview.visible\" \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-exclamation-triangle red\" \r" +
    "\n" +
    "                            ng-show=\"bondType.flaggedForReview\"   \r" +
    "\n" +
    "                            uib-tooltip=\"Flagged for review\" \r" +
    "\n" +
    "                            tooltip-placement=\"right\">\r" +
    "\n" +
    "                        </i>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.name.visible\">\r" +
    "\n" +
    "                        <a ui-sref=\"main.bondTypesDetail({ id: bondType.id })\">     \r" +
    "\n" +
    "                            {{bondType.name}}\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.isActive.visible\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"bondType.isActive.visible\" \r" +
    "\n" +
    "                            class=\"green\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </span>                        \r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"!bondType.isActive.visible\" \r" +
    "\n" +
    "                            class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                Inactive\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.sfaaCode.visible\" \r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{bondType.sfaaCode.code}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.sfaaDescription.visible\">\r" +
    "\n" +
    "                        {{bondType.sfaaCode.description | limitTo : 40}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{bondType.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{bondType.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.progress.visible\" \r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <configuration-progress-indicator bond-type=\"bondType\"></configuration-progress-indicator>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTypeProgress/dashboardWidgetBondTypeProgress.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"chartdiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/bondTypesByState/dashboardWidgetBondTypeConfigurationsByState.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"chartForm\"\r" +
    "\n" +
    "    class=\"meta-bar small flex clearfix\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill text-center v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.type\"\r" +
    "\n" +
    "        options=\"vm.statusOptions\"\r" +
    "\n" +
    "        on-selected=\"vm.statusSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"mapDiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/breakdownByAgent/dashboardWidgetBondsByAgentBreakdownCarrier.html',
    "\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"pieChartDiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/cancellationRequests/dashboardWidgetCancellationRequests.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\" \r" +
    "\n" +
    "                            sort-expression=\"bond/nameOnBond\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\" \r" +
    "\n" +
    "                            sort-expression=\"carrierSystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Agency\"\r" +
    "\n" +
    "                            sort-expression=\"agencySystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.requestedCancellationDate.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Cancellation Date\"\r" +
    "\n" +
    "                            sort-expression=\"requestedCancellationDate\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"cancellation in vm.table.data\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: cancellation.bond.customerId, sectionView:'bonds'})\" \r" +
    "\n" +
    "                            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                            target=\"_blank\" >\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{cancellation.bond.nameOnBond}}\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                            {{cancellation.bond.nameOnBond}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\">\r" +
    "\n" +
    "                        {{cancellation.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\">\r" +
    "\n" +
    "                        {{cancellation.agencySystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.requestedCancellationDate.visible\">\r" +
    "\n" +
    "                        {{cancellation.requestedCancellationDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{cancellation.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"cancellation.bondId\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        class=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/importBonds/dashboardWidgetImportBonds.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"!vm.importPreviewBonds.length\"  \r" +
    "\n" +
    "    class=\"initial-state\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"initial-state-body\">\r" +
    "\n" +
    "        <h4>Upload a bond import CSV template to begin</h4>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <file-selector\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            file-types=\".csv\"\r" +
    "\n" +
    "            on-file-read=\"vm.templateFileRead\"\r" +
    "\n" +
    "            on-file-selected=\"vm.templateFileSelected\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Upload CSV Template\r" +
    "\n" +
    "        </file-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"white\"\r" +
    "\n" +
    "            ng-click=\"vm.downloadImportTemplate()\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            Download Blank Template\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.totalInvalidBonds\" \r" +
    "\n" +
    "    class=\"alert alert-danger\">\r" +
    "\n" +
    "    <b>Unable to Import</b>\r" +
    "\n" +
    "    <br/>\r" +
    "\n" +
    "    Unable to import bonds because one or more of the items below has an error and cannot be processed.\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"importFilterForm\"\r" +
    "\n" +
    "    ng-show=\"vm.importPreviewBonds.length\" \r" +
    "\n" +
    "    class=\"small flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center\">\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-field-group=\"vm.showExisting\" \r" +
    "\n" +
    "            aa-label=\"Show Existing Matches\"\r" +
    "\n" +
    "            aa-fg-class=\"v-center\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center\">\r" +
    "\n" +
    "        &nbsp;({{vm.totalExistingMatches}})\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"v-center left-margin\">\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group-strategy=\"check\" \r" +
    "\n" +
    "            aa-field-group=\"vm.showErrorsOnly\"\r" +
    "\n" +
    "            aa-fg-class=\"v-center\"\r" +
    "\n" +
    "            aa-label=\"Show Errors Only\"\r" +
    "\n" +
    "            ng-disabled=\"!vm.totalInvalidBonds\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center\"> \r" +
    "\n" +
    "        &nbsp;({{vm.totalInvalidBonds}})\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"left-margin-auto flex\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <div class=\"v-center right-margin\">\r" +
    "\n" +
    "            {{vm.totalValidBonds}} Importable records\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                class=\"v-center\"\r" +
    "\n" +
    "                uib-dropdown-toggle>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"white\"\r" +
    "\n" +
    "                        ng-click=\"vm.clearResults()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Clear Results\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-disabled=\"vm.hasErrors || vm.importCompleted\" \r" +
    "\n" +
    "                        class=\"white\"\r" +
    "\n" +
    "                        ng-click=\"vm.importBonds()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Import\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<perfect-scrollbar \r" +
    "\n" +
    "    class=\"scroller\" \r" +
    "\n" +
    "    wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table ng-show=\"vm.importPreviewBonds.length\">\r" +
    "\n" +
    "        <thead>\r" +
    "\n" +
    "            <th></th>\r" +
    "\n" +
    "            <th></th>\r" +
    "\n" +
    "            <th>Bond Number</th>\r" +
    "\n" +
    "            <th>Term</th>\r" +
    "\n" +
    "            <th>Principal</th>\r" +
    "\n" +
    "            <th>Bond Type</th>\r" +
    "\n" +
    "            <th>Writing Company</th>\r" +
    "\n" +
    "            <th>Premium</th>\r" +
    "\n" +
    "            <th>Commission</th>\r" +
    "\n" +
    "            <th>Fees</th>\r" +
    "\n" +
    "            <th>Balance</th>\r" +
    "\n" +
    "        </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tbody ng-repeat=\"bond in vm.importPreviewBonds | filter: vm.filterPreviewBonds\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        ng-show=\"bond.hasError\" \r" +
    "\n" +
    "                        class=\"red fa fa-exclamation-triangle\"\r" +
    "\n" +
    "                        uib-tooltip=\"{{bond.errorMessage}}\"\r" +
    "\n" +
    "                        tooltip-placement=\"right\"></i>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <td class=\"fit-content\">\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        class=\"orange\" \r" +
    "\n" +
    "                        ng-show=\"bond.alreadyExists\"\r" +
    "\n" +
    "                        uib-tooltip=\"This bond and term already exists in A3. You can proceed with this import however this row will be skipped so not to duplicate bond/term records.\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Already Exists\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span\r" +
    "\n" +
    "                        class=\"green\"\r" +
    "\n" +
    "                        ng-show=\"bond.imported\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Imported\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        show-bond-detail-modal=\"bond.id\"\r" +
    "\n" +
    "                        ng-show=\"bond.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{bond.bondNumber}} \r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"!bond.id\">\r" +
    "\n" +
    "                        {{bond.bondNumber}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.effectiveDate | dateOnly}} - {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        ui-sref=\"main.customerDetail({ customerId: bond.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                        ng-show=\"bond.id\" \r" +
    "\n" +
    "                        target=\"_blank\" >\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{bond.nameOnBond}}\r" +
    "\n" +
    "                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"!bond.id\">\r" +
    "\n" +
    "                        {{bond.nameOnBond}}\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.bondType}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.writingCompany}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.premium | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.commission | currency:'$'}} ({{bond.commissionPercent}}%)\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.fees | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{bond.outstandingBalance | currency:'$'}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "        </tbody>\r" +
    "\n" +
    "    </table>\r" +
    "\n" +
    "</perfect-scrollbar> "
  );


  $templateCache.put('app/states/common/dashboard/widgets/indemnitySignatures/dashboardWidgetPendingIndemnitySignatures.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.name.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Customer\"\r" +
    "\n" +
    "                            sort-expression=\"customer/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.submissionDateTime.visible\"\r" +
    "\n" +
    "                        class=\"hidden-sm hidden-md\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"submissionDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.indemnityModalButton.visible\"></th>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.applicationDetailButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"application in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.name.visible\">\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: application.customerId, sectionView: '' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            {{ application.customer.name }}\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span class=\"small\">\r" +
    "\n" +
    "                            {{ application.quotes[0].indemnitySignatures.length\r" +
    "\n" +
    "                            }} pending\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.submissionDateTime.visible\"\r" +
    "\n" +
    "                        class=\"hidden-md\">\r" +
    "\n" +
    "                        {{\r" +
    "\n" +
    "                        application.quotes[0].indemnitySignatures[0].emailSentDateTime\r" +
    "\n" +
    "                        | timeAgo }}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.indemnityModalButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ng-click=\"vm.showIndemnityModal(application.quotes[0])\">\r" +
    "\n" +
    "                            <i class=\"fa fa-envelope\"></i>\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.applicationDetailButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-application-detail-modal=\"application.id\"\r" +
    "\n" +
    "                            >Application Detail</button\r" +
    "\n" +
    "                        >\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/leads/dashboardWidgetLeads.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.leadType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Lead Type\"\r" +
    "\n" +
    "                            sort-expression=\"leadType\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.name.visible\">\r" +
    "\n" +
    "                        Name\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.phone.visible\">\r" +
    "\n" +
    "                        Phone\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.email.visible\">\r" +
    "\n" +
    "                        Email\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.premium.visible\">\r" +
    "\n" +
    "                        Lowest Premium\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.quoteCount.visible\">\r" +
    "\n" +
    "                        Carrier/Quotes\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted Date\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"lead in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.leadType.visible\">\r" +
    "\n" +
    "                        {{lead.leadType}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.name.visible\">\r" +
    "\n" +
    "                        {{lead.people[0] | fullName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.phone.visible\">\r" +
    "\n" +
    "                        {{lead.people[0] | phone}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.email.visible\">\r" +
    "\n" +
    "                        {{lead.people[0].email}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{lead.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.premium.visible\">\r" +
    "\n" +
    "                        {{lead.lowestPremium | currency:'$'}}\r" +
    "\n" +
    "                        <span ng-show=\"lead.highestPremium.visible\"> - </span>\r" +
    "\n" +
    "                        {{lead.highestPremium | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        {{lead.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.quoteCount.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        {{lead.carrierCount}}/{{lead.prequalifiedQuoteCount}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{lead.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{lead.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-lead-detail-modal=\"{{lead.id}}\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        class=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/leadTotal/dashboardWidgetLeadTotals.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">{{vm.value | number}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.leadType\"\r" +
    "\n" +
    "    options=\"vm.leadTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.leadTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/leadTotalsCarrier/dashboardWidgetLeadTotalsCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "<div class=\"value\">\r" +
    "\n" +
    "    {{vm.value | number}}\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    <span ng-bind-html=\"vm.overComparison\"></span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    class=\"small\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.leadType\"\r" +
    "\n" +
    "    options=\"vm.leadTypeOptions\"\r" +
    "\n" +
    "    on-selected=\"vm.leadTypeSelected(option)\">\r" +
    "\n" +
    "</dropdown>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/newlyActiveBondTypes/dashboardWidgetNewlyActiveBondTypes.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small\">\r" +
    "\n" +
    "    <div class=\"meta-bar small clearfix\">\r" +
    "\n" +
    "        <div class=\"sub-total col-xs-4\">\r" +
    "\n" +
    "            {{vm.totalRecords}} items total\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"col-xs-8\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar class=\"scroller\" wheel-propagation=\"true\" ng-show=\"vm.records.length > 0\">\r" +
    "\n" +
    "        <div class=\"small widget-table-summary\"></div>\r" +
    "\n" +
    "        <table>\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "                <th>Bond Type</th>\r" +
    "\n" +
    "                <th>Date/Time</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "            <tr ng-repeat=\"record in vm.records\">\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    <a ui-sref=\"main.bondTypesDetail({ id: record.bondType.id })\">\r" +
    "\n" +
    "                        {{record.bondType.name}}\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "                <td>\r" +
    "\n" +
    "                    {{record.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                </td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"!vm.records.length\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"list-pagination small\" \r" +
    "\n" +
    "    ng-show=\"vm.records.length > 0\">\r" +
    "\n" +
    "    <div class=\"col-xs-6\">\r" +
    "\n" +
    "        <div class=\"hidden-xs\">\r" +
    "\n" +
    "            <am-select \r" +
    "\n" +
    "                options=\"vm.recordsPerPageOptions\" \r" +
    "\n" +
    "                selected-value=\"vm.recordsPerPage\" \r" +
    "\n" +
    "                option-changed=\"vm.setRecordPerPage(selectedOption)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            </am-select> \r" +
    "\n" +
    "            <span class=\"hidden-sm hidden-md\">\r" +
    "\n" +
    "                items per page\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div class=\"hidden-sm hidden-md hidden-lg\">\r" +
    "\n" +
    "            Page {{vm.currentPage}} of {{vm.totalPages}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"col-xs-6\" ng-show=\"vm.totalPages > 1\">\r" +
    "\n" +
    "        <div class=\"hidden-xs\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                uib-pagination total-items=\"vm.totalRecords\" \r" +
    "\n" +
    "                max-size=\"7\" \r" +
    "\n" +
    "                items-per-page=\"vm.recordsPerPage\" \r" +
    "\n" +
    "                ng-model=\"vm.currentPage\" \r" +
    "\n" +
    "                ng-change=\"vm.loadGrid()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div class=\"hidden-sm hidden-md hidden-lg\">\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                uib-pagination total-items=\"vm.totalRecords\" \r" +
    "\n" +
    "                max-size=\"0\" \r" +
    "\n" +
    "                items-per-page=\"vm.recordsPerPage\" \r" +
    "\n" +
    "                ng-model=\"vm.currentPage\" \r" +
    "\n" +
    "                ng-change=\"vm.loadGrid()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/openApplicationCarrier/dashboardWidgetOpenApplicationsListCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"    \r" +
    "\n" +
    "        wheel-propagation=\"true\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.number.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"#\"\r" +
    "\n" +
    "                            sort-expression=\"id\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Status\"\r" +
    "\n" +
    "                            sort-expression=\"status\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\" \r" +
    "\n" +
    "                            sort-expression=\"nameOnBond\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.sfaaCode.visible\"> \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"SFAA Code\" \r" +
    "\n" +
    "                            sort-expression=\"bondType/sfaaCode/code\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.approvedQuotesCount.visible\" >\r" +
    "\n" +
    "                        # Approved Quotes\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.pendingQuotesCount.visible\" >\r" +
    "\n" +
    "                        # Pending Quotes\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.state.visible\" > \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"State\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/obligee/requisitioningState\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.submittedDate.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Submitted On\"\r" +
    "\n" +
    "                            sort-expression=\"submissionDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.submittedDateAgo.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                        text=\"Submitted On\"\r" +
    "\n" +
    "                            sort-expression=\"submissionDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.agency.visible\" > \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Agency\"\r" +
    "\n" +
    "                            sort-expression=\"customer/systemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondAmount.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Amount\"\r" +
    "\n" +
    "                            sort-expression=\"bondAmount\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.desiredEffectiveDate.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"desiredEffectiveDate\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.detailsButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"app in vm.table.data\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.number.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        {{app.status}}\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"green\" \r" +
    "\n" +
    "                            ng-show=\"app.approvedQuoteCount > 0\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            - Approved\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"orange\"  \r" +
    "\n" +
    "                            ng-show=\"app.quotes.length === app.pendingQuoteCount\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            - Pending\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        {{app.nameOnBond}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.sfaaCode.visible\">\r" +
    "\n" +
    "                        {{app.bondType.sfaaCode.code}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.approvedQuotesCount.visible\">\r" +
    "\n" +
    "                        {{app.approvedQuoteCount}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.pendingQuotesCount.visible\">\r" +
    "\n" +
    "                        {{app.pendingQuoteCount}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{app.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.state.visible\">\r" +
    "\n" +
    "                        {{app.bondType.obligee.requisitioningState}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.submittedDate.visible\">\r" +
    "\n" +
    "                        {{app.submissionDateTime | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.submittedDateAgo.visible\">\r" +
    "\n" +
    "                        {{app.submissionDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.agency.visible\">\r" +
    "\n" +
    "                        {{app.customer.systemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondAmount.visible\">\r" +
    "\n" +
    "                        {{app.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.desiredEffectiveDate.visible\">\r" +
    "\n" +
    "                        {{app.desiredEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\" \r" +
    "\n" +
    "                        class=\"text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button show-application-detail-modal=\"app.id\">                                    \r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/openApplicationCountAgent/dashboardWidgetOpenApplicationsCountAgent.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceOptionSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">\r" +
    "\n" +
    "    {{vm.value | number}}\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    Realtime Count\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    options=\"vm.statusOptions\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.status\"\r" +
    "\n" +
    "    on-selected=\"vm.statusOptionSelection(option)\">\r" +
    "\n" +
    "</dropdown>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/openApplicationCountCarrier/dashboardWidgetOpenApplicationsCountCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    options=\"vm.dateReferenceOptions\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.dateReference\"\r" +
    "\n" +
    "    on-selected=\"vm.dateReferenceOptionSelected(option)\">\r" +
    "\n" +
    "</dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value\">\r" +
    "\n" +
    "    {{vm.value | number}}\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"value-comparison-wrapper\">\r" +
    "\n" +
    "    Realtime Count\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<dropdown\r" +
    "\n" +
    "    options=\"vm.statusOptions\"\r" +
    "\n" +
    "    selected-option=\"vm.widget.options.status\"\r" +
    "\n" +
    "    on-selected=\"vm.statusOptionSelection(option)\">\r" +
    "\n" +
    "</dropdown>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/openApplicationList/dashboardWidgetOpenApplicationsList.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter\r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\"\r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\"\r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.number.visible\"\r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"#\"\r" +
    "\n" +
    "                            sort-expression=\"id\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.status.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Status\"\r" +
    "\n" +
    "                            sort-expression=\"status\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.customer.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Customer\"\r" +
    "\n" +
    "                            sort-expression=\"customer/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.email.visible\">\r" +
    "\n" +
    "                        Email\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.approvedQuotesCount.visible\">\r" +
    "\n" +
    "                        # Approved Quotes\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.pendingQuotesCount.visible\">\r" +
    "\n" +
    "                        # Pending Quotes\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Created\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Created Date\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"bondType/name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.phone.visible\">\r" +
    "\n" +
    "                        Phone\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.detailsButton.visible\"></th>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.editButton.visible\"></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"application in vm.table.data\">\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        class=\"fit-content\"\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.number.visible\">\r" +
    "\n" +
    "                        {{application.applicationNumber}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.status.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-bind-html=\"application | applicationStatus\"></span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.customer.visible\">\r" +
    "\n" +
    "                        <a\r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: application.customerId, sectionView: 'applications' })\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            {{application.customer.name}}\r" +
    "\n" +
    "                            <i\r" +
    "\n" +
    "                                class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.email.visible\">\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-show=\"application.customer.customerType == 'Individual'\">\r" +
    "\n" +
    "                            {{application.people[0].email}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-show=\"application.customer.customerType == 'Company'\">\r" +
    "\n" +
    "                            {{application.companies[0].email}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.approvedQuotesCount.visible\">\r" +
    "\n" +
    "                        {{application.approvedQuoteCount}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.pendingQuotesCount.visible\">\r" +
    "\n" +
    "                        {{application.pendingQuoteCount}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTimeAgo.visible\">\r" +
    "\n" +
    "                        {{application.createdDateTime | timeAgo}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{application.createdDateTime | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondType.visible\">\r" +
    "\n" +
    "                        {{application.bondType.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.phone.visible\"\r" +
    "\n" +
    "                        class=\"fig-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span\r" +
    "\n" +
    "                            ng-show=\"application.customer.customerType == 'Individual'\">\r" +
    "\n" +
    "                            {{application.people[0] | phone}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        <span ng-show=\"application.customer.customerType == 'Company'\">\r" +
    "\n" +
    "                            {{application.companies[0].phone}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.detailsButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            class=\"mini\" \r" +
    "\n" +
    "                            show-application-detail-modal=\"application.id\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td\r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.editButton.visible\"\r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <button \r" +
    "\n" +
    "                            ng-click=\"vm.editApplication()\" \r" +
    "\n" +
    "                            class=\"mini\" \r" +
    "\n" +
    "                            ng-show=\"!vm.isDisabled\" \r" +
    "\n" +
    "                            ng-disabled=\"application.applicationType === 'SingleBondRenewal'\"\r" +
    "\n" +
    "                            ui-sref=\"main.application({\r" +
    "\n" +
    "                                applicationId: application.id, \r" +
    "\n" +
    "                                appType: application.applicationType === 'BondAccount' ? 'BondAccount' : 'SingleBond', \r" +
    "\n" +
    "                                customerId: application.customerId\r" +
    "\n" +
    "                            })\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Edit\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/payments/dashboardWidgetPayments.html',
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <payment-report \r" +
    "\n" +
    "            filters=\"vm.widget.options.filters\"\r" +
    "\n" +
    "            on-table-ready=\"vm.tableReady(table)\"\r" +
    "\n" +
    "            on-table-changed=\"vm.tableChanged(table)\">\r" +
    "\n" +
    "        </payment-report>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/reinstatementRequests/dashboardWidgetReinstatementRequests.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\" \r" +
    "\n" +
    "        wheel-propagation=\"true\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Principal\" \r" +
    "\n" +
    "                            sort-expression=\"bond/nameOnBond\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Carrier\" \r" +
    "\n" +
    "                            sort-expression=\"carrierSystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Agency\"\r" +
    "\n" +
    "                            sort-expression=\"agencySystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.requestedCancellationDate.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"requestedEffectiveDate\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.createdDateTime.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"request in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: request.bond.customerId, sectionView:'bonds'})\" \r" +
    "\n" +
    "                            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{request.bond.nameOnBond}}\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                            {{request.bond.nameOnBond}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\">\r" +
    "\n" +
    "                        {{request.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\">\r" +
    "\n" +
    "                        {{request.agencySystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.requestedCancellationDate.visible\">\r" +
    "\n" +
    "                        {{request.requestedEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{request.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"request.bondId\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        class=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/riderRequests/dashboardWidgetRiderRequests.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "   \r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector\r" +
    "\n" +
    "            class=\"v-center left-margin-auto\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\" \r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Principal\"\r" +
    "\n" +
    "                            sort-expression=\"bond/nameOnBond\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\">                        \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Carrier\"\r" +
    "\n" +
    "                            sort-expression=\"carrierSystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Agency\"\r" +
    "\n" +
    "                            sort-expression=\"agencySystemAccount/companyName\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.requestedEffectiveDate.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Effective Date\"\r" +
    "\n" +
    "                            sort-expression=\"requestedEffectiveDate\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.createdDateTime.visible\">                        \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text= \"Submitted\"\r" +
    "\n" +
    "                            sort-expression=\"createdDateTime\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th></th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"request in vm.table.data\">\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.nameOnBond.visible\">\r" +
    "\n" +
    "                        <a \r" +
    "\n" +
    "                            ui-sref=\"main.customerDetail({ customerId: request.bond.customerId, sectionView:'bonds'})\" \r" +
    "\n" +
    "                            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                            target=\"_blank\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            {{request.bond.nameOnBond}}\r" +
    "\n" +
    "                            <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "                            {{request.bond.nameOnBond}}\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.carrier.visible && !vm.isCarrier\">\r" +
    "\n" +
    "                        {{request.carrierSystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.agency.visible && vm.isCarrier\">\r" +
    "\n" +
    "                        {{request.agencySystemAccount.companyName}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.requestedEffectiveDate.visible\">\r" +
    "\n" +
    "                        {{request.requestedEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.createdDateTime.visible\">\r" +
    "\n" +
    "                        {{request.createdDateTime | localDateTime}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td class=\"fit-content\">\r" +
    "\n" +
    "                        <button show-bond-detail-modal=\"request.bondId\">\r" +
    "\n" +
    "                            Details\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records were found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/stateActivity/dashboardWidgetStateActivity.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"filterForm\"\r" +
    "\n" +
    "    class=\"meta-bar small flex bottom-margin-thick\">\r" +
    "\n" +
    "   \r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill text-center v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.transactionType\"\r" +
    "\n" +
    "        options=\"vm.transactionTypeOptions\"\r" +
    "\n" +
    "        on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill text-center v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.selectedSfaaCode\"\r" +
    "\n" +
    "        options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "        on-selected=\"vm.sfaaCodeSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"fill text-center v-center\">\r" +
    "\n" +
    "        Date range: {{ vm.dateFilter.startDate | dateOnly }} - {{ vm.dateFilter.endDate | dateOnly }}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"mapDiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/dashboard/widgets/stateActivityCarrier/dashboardWidgetStateActivityCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-form=\"filterForm\"\r" +
    "\n" +
    "    class=\"meta-bar small flex bottom-margin-thick\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill text-center v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.transactionType\"\r" +
    "\n" +
    "        options=\"vm.transactionTypes\"\r" +
    "\n" +
    "        on-selected=\"vm.transactionTypeSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <dropdown\r" +
    "\n" +
    "        class=\"fill text-center v-center\"\r" +
    "\n" +
    "        selected-option=\"vm.widget.options.filters.selectedSfaaCode\"\r" +
    "\n" +
    "        options=\"vm.sfaaCodeOptions\"\r" +
    "\n" +
    "        on-selected=\"vm.sfaaCodeSelected(option)\">\r" +
    "\n" +
    "    </dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"fill text-center v-center\">\r" +
    "\n" +
    "        Date range: {{ vm.dateFilter.startDate | dateOnly }} - {{vm.dateFilter.endDate | dateOnly }}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    id=\"mapDiv{{vm.widgetIndex}}\" \r" +
    "\n" +
    "    class=\"chart-div\">\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/dashboard/widgets/underwritingConfigurationsCarrier/dashboardWidgetUnderwritingConfigurationsCarrier.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"small flex column height-100p\">\r" +
    "\n" +
    "    <div class=\"meta-bar flex small\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\"> \r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"v-center h-center\"\r" +
    "\n" +
    "            ui-sref=\"main.purchasedBondsReport\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            View Report\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-column-selector \r" +
    "\n" +
    "            class=\"v-center\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            columns-Changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-column-selector>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dashboard-widget-filter \r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            widget=\"vm.widget\" \r" +
    "\n" +
    "            filter-collection=\"vm.filterCollection\" \r" +
    "\n" +
    "            filters-changed=\"vm.loadGrid()\">\r" +
    "\n" +
    "        </dashboard-widget-filter>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <perfect-scrollbar \r" +
    "\n" +
    "        class=\"scroller fill bottom-margin\" \r" +
    "\n" +
    "        wheel-propagation=\"true\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondTypeIsActive.visible\"> \r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Bond Type Status\"\r" +
    "\n" +
    "                            sort-expression=\"isActive\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button> \r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.bondTypeName.visible\"> \r" +
    "\n" +
    "                        <table-sort-button    \r" +
    "\n" +
    "                            text=\"Bond Type\"\r" +
    "\n" +
    "                            sort-expression=\"name\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table-sorter\"> \r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.requisitioningState.visible\" >\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"State\" \r" +
    "\n" +
    "                            sort-expression=\"obligee/requisitioningState\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table-sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th ng-show=\"vm.widget.options.columns.sfaaCode.visible\">\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"SFAA\" \r" +
    "\n" +
    "                            sort-expression=\"sfaaCode/code\" \r" +
    "\n" +
    "                            table-sorter=\"vm.table-sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <th \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.numberOfConfigurations.visible\" \r" +
    "\n" +
    "                        class=\"text-center\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        # of U/W Configurations\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "                <tr ng-repeat=\"bondtype in vm.table.data\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondTypeIsActive.visible\">    \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"bondtype.isActive\" \r" +
    "\n" +
    "                            class=\"green\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Active\r" +
    "\n" +
    "                        </span> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"bondtype.isActive == false\" \r" +
    "\n" +
    "                            class=\"red\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Inactive\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.bondTypeName.visible\">\r" +
    "\n" +
    "                        {{bondtype.name}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.requisitioningState.visible\">\r" +
    "\n" +
    "                        {{bondtype.obligee.requisitioningState}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td ng-show=\"vm.widget.options.columns.sfaaCode\">\r" +
    "\n" +
    "                        {{bondtype.sfaaCode.code}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td \r" +
    "\n" +
    "                        ng-show=\"vm.widget.options.columns.numberOfConfigurations\" \r" +
    "\n" +
    "                        class=\"fit-content\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        {{bondtype.underwritingConfigurations.length}}\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No records found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/documentEditor/documentEditor.html',
    "<div \r" +
    "\n" +
    "    ng-show=\"vm.loading\" \r" +
    "\n" +
    "    class=\"editor-loading-mask\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        <img src=\"/img/icon_a3.png\"/>\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            <img src=\"/img/hourglass.svg\"/> \r" +
    "\n" +
    "            One minute, We're loading up your document.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "        <a ui-sref=\"main.documentLibrary({reloadDocumentLibrarySettings:true})\">\r" +
    "\n" +
    "            << Back to the Document library\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    ng-show=\"vm.saving\" \r" +
    "\n" +
    "    class=\"editor-loading-mask\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        <img src=\"/img/icon_a3.png\" />\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            <img src=\"/img/hourglass.svg\" /> \r" +
    "\n" +
    "            Let's save your work.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<iframe \r" +
    "\n" +
    "    ng-src=\"{{vm.documentEditorUrlWithId}}\" \r" +
    "\n" +
    "    class=\"document-editor-frame\" \r" +
    "\n" +
    "    id=\"documentEditorFrame\">\r" +
    "\n" +
    "</iframe>"
  );


  $templateCache.put('app/states/common/documentLibrary/documentFilterDropdown/documentFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"documentFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "            ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>     \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Status\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.status\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\">Any</option>\r" +
    "\n" +
    "                    <option value=\"Untouched\">Untouched</option>\r" +
    "\n" +
    "                    <option value=\"PrimaryReview\">PrimaryReview</option>\r" +
    "\n" +
    "                    <option value=\"SecondaryReview\">SecondaryReview</option>\r" +
    "\n" +
    "                    <option value=\"Approved\">Approved</option>\r" +
    "\n" +
    "                    <option value=\"ProblemReported\">ProblemReported</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Document Category\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.documentCategoryId\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.documentCategoryOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Created Date\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"between-label\">\r" +
    "\n" +
    "                    and\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Modified Date\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumLastModifiedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                <div class=\"between-label\">\r" +
    "\n" +
    "                    and\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumLastModifiedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" \r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\"\r" +
    "\n" +
    "                    aa-fg-class=\"width-85\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">\r" +
    "\n" +
    "                Related Bond Type\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.bondType\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/documentLibrary/documentLibrary.html',
    "<div \r" +
    "\n" +
    "    class=\"document-library\"\r" +
    "\n" +
    "    ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    <div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex bottom-margin\">\r" +
    "\n" +
    "   \r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "            aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "            aa-label=\"Search documents\"\r" +
    "\n" +
    "            uib-tooltip=\"Search by form name\"\r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            ng-show=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "            class=\"v-center small\">\r" +
    "\n" +
    "                {{vm.table.pager.totalRecordsCountFormatted}} records\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <document-filter-dropdown\r" +
    "\n" +
    "            class=\"left-margin flex\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </document-filter-dropdown>\r" +
    "\n" +
    "  </div> \r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section class=\"top-margin bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"bottom-margin\">\r" +
    "\n" +
    "            <uploader\r" +
    "\n" +
    "                on-files-added=\"vm.filesAddedForUpload(files)\"\r" +
    "\n" +
    "                controls=\"vm.uploaderControls\">\r" +
    "\n" +
    "            </uploader>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <uploader\r" +
    "\n" +
    "                auto-upload=\"true\"\r" +
    "\n" +
    "                controls=\"vm.versionUpdateUploaderControls\"\r" +
    "\n" +
    "                on-success=\"vm.documentVersionUpdated()\"\r" +
    "\n" +
    "                hidden=\"true\">\r" +
    "\n" +
    "            </uploader>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <table ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "            <thead>\r" +
    "\n" +
    "                <tr>\r" +
    "\n" +
    "                    <th>\r" +
    "\n" +
    "                        <table-sort-button\r" +
    "\n" +
    "                            text=\"Name\"\r" +
    "\n" +
    "                            sort-expression=\"name\"\r" +
    "\n" +
    "                            table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                        </table-sort-button>\r" +
    "\n" +
    "                    </th>\r" +
    "\n" +
    "                    <th>Status</th>\r" +
    "\n" +
    "                    <th>Category</th>\r" +
    "\n" +
    "                    <th class=\"hidden-xs hidden-sm\">Last Modified</th>\r" +
    "\n" +
    "                    <th>&nbsp;</th>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </thead>\r" +
    "\n" +
    "            <tbody>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tr ng-repeat=\"document in vm.table.data\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <div>{{document.name}}</div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <span ng-show=\"document.status === 'Untouched'\">Untouched</span>\r" +
    "\n" +
    "                        <span ng-show=\"document.status === 'PrimaryReview'\">Primary Review</span>\r" +
    "\n" +
    "                        <span ng-show=\"document.status === 'SecondaryReview'\">Secondary Review</span>\r" +
    "\n" +
    "                        <span ng-show=\"document.status === 'Approved'\" class=\"green\"><i class=\"fa fa-check\"></i> Approved</span>\r" +
    "\n" +
    "                        <span ng-show=\"document.status === 'ProblemReported'\" class=\"red\"><i class=\"fa fa-times\"></i> Problem Reported</span>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td>\r" +
    "\n" +
    "                        <div>{{document.documentCategoryName}}</div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td class=\"hidden-xs hidden-sm\">{{document.lastModifiedDateTime | localDateTime}}</td>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <td class=\"text-right\">\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"dropdown\" \r" +
    "\n" +
    "                            uib-dropdown>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <button uib-dropdown-toggle>\r" +
    "\n" +
    "                                <span class=\"visible-xs\"><i class=\"fa fa-caret-down\"></i></span>\r" +
    "\n" +
    "                                <span class=\"hidden-xs\">Actions <i class=\"fa fa-caret-down\"></i></span>\r" +
    "\n" +
    "                            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <pdf-download \r" +
    "\n" +
    "                                        url=\"vm.downloadUrls[$index]\" \r" +
    "\n" +
    "                                        filename=\"A3_Document.pdf\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i class=\"fa fa-download\"></i> Download\r" +
    "\n" +
    "                                    </pdf-download>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <pdf-download \r" +
    "\n" +
    "                                        url=\"vm.sampleDownloadUrls[$index]\" \r" +
    "\n" +
    "                                        filename=\"A3_Document_Sample.pdf\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                                        <i class=\"fa fa-eye\"></i> Preview\r" +
    "\n" +
    "                                    </pdf-download>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button ng-click=\"vm.editDocument(document)\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-pencil-alt\"></i> \r" +
    "\n" +
    "                                        <span>Edit</span>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button ng-click=\"vm.uploadNewVersion(document)\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-upload\"></i> \r" +
    "\n" +
    "                                        <span>Upload new version</span>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button \r" +
    "\n" +
    "                                        ng-click=\"vm.promptToDelete(document)\" \r" +
    "\n" +
    "                                        ng-show=\"vm.currentUser.permissions.canDeleteFromDocumentLibrary\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                                        <i class=\"fa fa-trash\"></i> \r" +
    "\n" +
    "                                        <span>Delete Document</span>\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                                <li class=\"divider\"></li>\r" +
    "\n" +
    "                                <li>\r" +
    "\n" +
    "                                    <button ng-click=\"vm.showDetails(document.id)\">\r" +
    "\n" +
    "                                        <i class=\"fa fa-info\"></i> Details\r" +
    "\n" +
    "                                    </button>\r" +
    "\n" +
    "                                </li>\r" +
    "\n" +
    "                            </ul>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </td>\r" +
    "\n" +
    "                </tr>\r" +
    "\n" +
    "            </tbody>\r" +
    "\n" +
    "        </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state \r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "            message=\"We can't find anything matching your search\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer \r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<document-library-details-pane\r" +
    "\n" +
    "    document-details=\"vm.documentDetails\"\r" +
    "\n" +
    "    is-open=\"vm.isDetailsPaneVisible\"\r" +
    "\n" +
    "    on-hide=\"vm.hideDetails()\">\r" +
    "\n" +
    "</document-library-details-pane>"
  );


  $templateCache.put('app/states/common/forgotPassword/forgotPassword.html',
    "<div class=\"a-container\">\r" +
    "\n" +
    "    <div class=\"a-background\">\r" +
    "\n" +
    "        <div id=\"particles-js\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"a-message\"\r" +
    "\n" +
    "        ng-show=\"forgotPasswordForm.email.$error.required && formSubmitted || (forgotPasswordForm.email.$error.email && formSubmitted) || vm.message\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span>\r" +
    "\n" +
    "            {{ vm.message }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span ng-show=\"forgotPasswordForm.email.$error.required\">\r" +
    "\n" +
    "            Please enter your email address\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"forgotPasswordForm.email.$error.required == false && forgotPasswordForm.email.$error.email\">\r" +
    "\n" +
    "            Email is invalid\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-box\">\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            novalidate name=\"forgotPasswordForm\"\r" +
    "\n" +
    "            ng-submit=\"vm.sendResetRequest(forgotPasswordForm.email.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p>We forget passwords too. Let's email you a link to reset your password</p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"emailInput\"\r" +
    "\n" +
    "                placeholder=\"Email address\"\r" +
    "\n" +
    "                type=\"email\"\r" +
    "\n" +
    "                name=\"email\"\r" +
    "\n" +
    "                ng-model=\"vm.email\"\r" +
    "\n" +
    "                focus-me=\"vm.emailFocus\"\r" +
    "\n" +
    "                ng-class=\"{ error: forgotPasswordForm.email.$error.required && vm.formSubmitted || (forgotPasswordForm.email.$error.email && vm.formSubmitted)}\"\r" +
    "\n" +
    "                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"button-container fx\"\r" +
    "\n" +
    "                id=\"mainControls\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    type=\"button\"\r" +
    "\n" +
    "                    value=\"Cancel\"\r" +
    "\n" +
    "                    class=\"btn\"\r" +
    "\n" +
    "                    id=\"forgot-password-button\"\r" +
    "\n" +
    "                    data-ng-click=\"vm.cancel()\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    id=\"okButton\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    value=\"Send Reset Request\"\r" +
    "\n" +
    "                    class=\"btn\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"spinner\">\r" +
    "\n" +
    "                    <div class=\"cube1\"></div>\r" +
    "\n" +
    "                    <div class=\"cube2\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"a-footer\">\r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li>Privacy</li>\r" +
    "\n" +
    "            <li>Terms & Conditions</li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/login/login.html',
    "<div class=\"a-container\">\r" +
    "\n" +
    "    <div class=\"a-background\">\r" +
    "\n" +
    "        <div id=\"particles-js\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"a-message\"\r" +
    "\n" +
    "        ng-show=\"vm.message || (loginForm.password.$error.required && vm.formSubmitted) || (loginForm.email.$error.required && vm.formSubmitted) || (loginForm.email.$error.required == false && loginForm.email.$error.email && vm.formSubmitted)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span>{{ vm.message }}</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"vm.formSubmitted && (loginForm.password.$error.required || loginForm.email.$error.required)\"\r" +
    "\n" +
    "            id=\"noEmailPasswordMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Please provide an email address and password\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"loginForm.password.$error.required == false && loginForm.email.$error.required == false && loginForm.email.$error.email\"\r" +
    "\n" +
    "            id=\"invalidEmailMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Email is invalid\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-box\">\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            novalidate\r" +
    "\n" +
    "            name=\"loginForm\"\r" +
    "\n" +
    "            ng-submit=\"vm.login(loginForm.$valid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"padding\">\r" +
    "\n" +
    "                <img src=\"/img/icons/a3-logo.png\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"emailInput\"\r" +
    "\n" +
    "                placeholder=\"Email address\"\r" +
    "\n" +
    "                type=\"email\"\r" +
    "\n" +
    "                name=\"email\"\r" +
    "\n" +
    "                ng-model=\"vm.loginData.email\"\r" +
    "\n" +
    "                focus-me=\"vm.emailFocus\"\r" +
    "\n" +
    "                ng-class=\"{ error: loginForm.email.$error.required && vm.formSubmitted || (loginForm.email.$error.required == false && loginForm.email.$error.email && vm.formSubmitted)}\"\r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"passwordInput\"\r" +
    "\n" +
    "                placeholder=\"Password\"\r" +
    "\n" +
    "                type=\"password\"\r" +
    "\n" +
    "                name=\"password\"\r" +
    "\n" +
    "                ng-model=\"vm.loginData.password\"\r" +
    "\n" +
    "                focus-me=\"vm.incorrectPassword\"\r" +
    "\n" +
    "                ng-class=\"{ error: loginForm.password.$error.required && vm.formSubmitted}\"\r" +
    "\n" +
    "                required/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                id=\"loginButton\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                value=\"Sign In\"\r" +
    "\n" +
    "                class=\"btn fx\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                value=\"Forgot password?\"\r" +
    "\n" +
    "                class=\"btn\"\r" +
    "\n" +
    "                id=\"forgot-password-button\"\r" +
    "\n" +
    "                ng-click=\"vm.forgotPassword()\"/>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-footer\"></div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/login/userSelection.html',
    "<div class=\"a-container\">\r" +
    "\n" +
    "    <div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "    <div class=\"a-background\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-box user-selection\">\r" +
    "\n" +
    "        <div class=\"padding\">\r" +
    "\n" +
    "            <img src=\"/img/icons/a3-logo.png\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3 class=\"text-center\">Account Selection</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"user-selection-choices\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"bottom-margin user-choice\"\r" +
    "\n" +
    "                ng-click=\"vm.selectUser(userOption.id)\" \r" +
    "\n" +
    "                ng-repeat=\"userOption in vm.userOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <system-account-logo\r" +
    "\n" +
    "                        class=\"right-margin v-center\"\r" +
    "\n" +
    "                        system-account-id=\"userOption.systemAccountId\"> \r" +
    "\n" +
    "                    </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"fill v-center\">\r" +
    "\n" +
    "                        <div class=\"text-large\">{{userOption.systemAccountCompanyName}}</div>\r" +
    "\n" +
    "                        <div>{{userOption.accountType}}</div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"a-footer\"></div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/obligeeDetail/obligeesDetail.html',
    "<obligee-form\r" +
    "\n" +
    "    busy-indicator=\"vm.busyIndicator\"\r" +
    "\n" +
    "    entry-mode=\"master\"\r" +
    "\n" +
    "    obligee=\"vm.obligee\"\r" +
    "\n" +
    "    on-save=\"vm.save(obligee)\"\r" +
    "\n" +
    "    on-cancel=\"vm.cancel()\">\r" +
    "\n" +
    "</obligee-form>"
  );


  $templateCache.put('app/states/common/obligees/obligeesGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"listSearchForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search Obligees\"\r" +
    "\n" +
    "        uib-tooltip=\"Search by name\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewObligessButton\"\r" +
    "\n" +
    "                    ui-sref=\"main.obligeesDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Obligee\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"Obligees.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ui-sref=\"main.obligeesDetail({ id: item.id })\">\r" +
    "\n" +
    "                    {{item.name}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <button ng-click=\"vm.showHistory(item)\">\r" +
    "\n" +
    "                    <i class=\"fa fa-history\"></i>\r" +
    "\n" +
    "                </button><!--\r" +
    "\n" +
    "\r" +
    "\n" +
    "            --><button \r" +
    "\n" +
    "                    ng-disabled=\"!item.canBeDeleted\"\r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(item)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No obligees were found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>"
  );


  $templateCache.put('app/states/common/purchasedBonds/purchasedBonds.html',
    "<div busy-indicator=\"vm.table.busyIndicator\"></div>\r" +
    "\n" +
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-title\">\r" +
    "\n" +
    "    <header>\r" +
    "\n" +
    "        Bonds\r" +
    "\n" +
    "    </header>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"page-body fill-page flex column\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <view-dropdown-menu table=\"vm.table\"></view-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-form=\"searchForm\"\r" +
    "\n" +
    "        class=\"flex bottom-margin top-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input \r" +
    "\n" +
    "            aa-field-group=\"vm.table.searchPhrase\"\r" +
    "\n" +
    "            aa-fg-class=\"table-search-field v-center\"   \r" +
    "\n" +
    "            ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "            aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "            aa-label=\"Search Purchased Bonds Entries\"\r" +
    "\n" +
    "            uib-tooltip=\"Search Bond Entries\" \r" +
    "\n" +
    "            focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <record-counter\r" +
    "\n" +
    "            class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "            record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "        </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            uib-tooltip=\"Refresh\"\r" +
    "\n" +
    "            tooltip-placement=\"top-right\"\r" +
    "\n" +
    "            id=\"purchased-bonds-report-refresh-button\"\r" +
    "\n" +
    "            class=\"v-center left-margin\"\r" +
    "\n" +
    "            ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <purchased-bonds-report-dropdown-filter\r" +
    "\n" +
    "            class=\"flex left-margin\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </purchased-bonds-report-dropdown-filter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <column-selection-dropdown-menu \r" +
    "\n" +
    "            class=\"left-margin v-center\"\r" +
    "\n" +
    "            table=\"vm.table\">\r" +
    "\n" +
    "        </column-selection-dropdown-menu>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"v-center dropdown actions\"\r" +
    "\n" +
    "            uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                uib-tooltip=\"Actions\"\r" +
    "\n" +
    "                tooltip-placement=\"top-right\"\r" +
    "\n" +
    "                class=\"left-margin-big v-center mini\"\r" +
    "\n" +
    "                uib-dropdown-toggle\r" +
    "\n" +
    "                id=\"purchasedBonds-action-dropdown-button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Actions\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportPurchasedBondReport()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                            Download Excel\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>  \r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    " \r" +
    "\n" +
    "    <perfect-scrollbar\r" +
    "\n" +
    "        suppress-scroll-x=\"true\"\r" +
    "\n" +
    "        class=\"scroller content-scroller bottom-margin\"\r" +
    "\n" +
    "        wheel-propagation=\"true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <perfect-scrollbar\r" +
    "\n" +
    "            suppress-scroll-y=\"true\"\r" +
    "\n" +
    "            class=\"scroller no-right-padding\"\r" +
    "\n" +
    "            wheel-propagation=\"true\"\r" +
    "\n" +
    "            ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <table\r" +
    "\n" +
    "                ng-show=\"vm.table.isVisible\" \r" +
    "\n" +
    "                class=\"group-by-tbody bottom-margin nowrap\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <thead>\r" +
    "\n" +
    "                    <tr\r" +
    "\n" +
    "                        class=\"sortable\"\r" +
    "\n" +
    "                        ng-sortable=\"vm.table.columnSortableOptions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <th \r" +
    "\n" +
    "                            class=\"orderable-column grab-handle\"\r" +
    "\n" +
    "                            ng-class=\"column.headingClass\"\r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-show=\"column.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <table-sort-button\r" +
    "\n" +
    "                                text=\"{{column.heading}}\"\r" +
    "\n" +
    "                                sort-expression=\"{{column.sortExpression}}\"\r" +
    "\n" +
    "                                table-sorter=\"vm.table.sorter\"\r" +
    "\n" +
    "                                uib-tooltip=\"{{column.tooltip}}\">\r" +
    "\n" +
    "                            </table-sort-button>\r" +
    "\n" +
    "                        </th>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </thead>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <tbody ng-repeat=\"bond in vm.table.data\">\r" +
    "\n" +
    "                    <tr>\r" +
    "\n" +
    "                        <td \r" +
    "\n" +
    "                            ng-repeat=\"column in vm.table.currentView.columns\"\r" +
    "\n" +
    "                            ng-switch=\"column.name\"\r" +
    "\n" +
    "                            ng-if=\"column.isVisible\"\r" +
    "\n" +
    "                            ng-class=\"column.cellClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"BondNumber\"> {{bond.bondNumber}}</span>\r" +
    "\n" +
    "                            <span \r" +
    "\n" +
    "                                ng-switch-when=\"Status\" \r" +
    "\n" +
    "                                ng-bind-html=\"bond | bondStatus\">\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"PurchasedDate\">{{bond.createdDateTime | date:'MM/dd/yyyy'}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"LookupCode\">{{bond.customerLookupCode}}</span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"NameOnBond\">\r" +
    "\n" +
    "                                {{bond.nameOnBond}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"EffectiveDate\">\r" +
    "\n" +
    "                                {{bond.effectiveDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"ExpirationDate\">\r" +
    "\n" +
    "                                {{bond.expirationDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                       \r" +
    "\n" +
    "                            <span ng-switch-when=\"BondType\">\r" +
    "\n" +
    "                                {{bond.bondType | characters : 25 : '...'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <span ng-switch-when=\"Carrier\">\r" +
    "\n" +
    "                                {{bond.carrier}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CarrierLogo\">\r" +
    "\n" +
    "                                <system-account-logo\r" +
    "\n" +
    "                                    system-account-id=\"bond.carrierSystemAccountId\"\r" +
    "\n" +
    "                                    show-labels=\"false\">\r" +
    "\n" +
    "                                </system-account-logo>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Agency\">\r" +
    "\n" +
    "                                {{bond.agency}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span  \r" +
    "\n" +
    "                                ng-switch-when=\"WritingCompany\">\r" +
    "\n" +
    "                                {{bond.writingCompany}}\r" +
    "\n" +
    "                            </span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceManager\">\r" +
    "\n" +
    "                                {{bond.clientServiceManager}}\r" +
    "\n" +
    "                            </span> \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <span ng-switch-when=\"LegacyId\">\r" +
    "\n" +
    "                                {{bond.legacyId}}\r" +
    "\n" +
    "                            </span>                             \r" +
    "\n" +
    "                            <span ng-switch-when=\"Producer\">\r" +
    "\n" +
    "                                {{bond.producer}}\r" +
    "\n" +
    "                            </span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceExecutive\">\r" +
    "\n" +
    "                                {{bond.clientServiceExecutive}}\r" +
    "\n" +
    "                            </span> \r" +
    "\n" +
    "                            <span ng-switch-when=\"ClientServiceAssociate\">\r" +
    "\n" +
    "                                {{bond.clientServiceAssociate}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Customer\">\r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    ui-sref=\"main.customerDetail({ customerId: bond.customerId, sectionView: 'bonds' })\"\r" +
    "\n" +
    "                                    target=\"_blank\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                        {{bond.customerName}}\r" +
    "\n" +
    "                                        <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RenewalStatus\">\r" +
    "\n" +
    "                                {{bond.renewalStatusDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CancellationStatus\">\r" +
    "\n" +
    "                                {{bond.cancellationStatusDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ReinstatementStatus\">\r" +
    "\n" +
    "                                {{bond.reinstatementStatusDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CancellationDate\">\r" +
    "\n" +
    "                                {{bond.cancellationDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ReinstatementDate\">\r" +
    "\n" +
    "                                {{bond.reinstatementDate | dateOnly}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RiderStatus\">\r" +
    "\n" +
    "                                {{bond.riderStatusDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsRenewable\">\r" +
    "\n" +
    "                                {{bond.isRenewable ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BondAmount\">\r" +
    "\n" +
    "                                {{bond.bondAmount | currency : '$'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RequiresContinuationCertificate\">\r" +
    "\n" +
    "                                {{bond.requiresContinuationCertificate ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RequiresVerificationCertificate\">\r" +
    "\n" +
    "                                {{bond.requiresVerificationCertificate ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"SfaaCode\">\r" +
    "\n" +
    "                                {{bond.sfaaCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsElectronic\">\r" +
    "\n" +
    "                                {{bond.isElectronic ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"OriginSystem\">\r" +
    "\n" +
    "                                {{bond.originSystemDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"IsCancellable\">\r" +
    "\n" +
    "                                {{bond.isCancellable ? 'Yes' : 'No'}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeName\">\r" +
    "\n" +
    "                                {{bond.obligeeName}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailAddress\">\r" +
    "\n" +
    "                                {{bond.obligeeMailAddress}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailCity\">\r" +
    "\n" +
    "                                {{bond.obligeeMailCity}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailState\">\r" +
    "\n" +
    "                                {{bond.obligeeMailState}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"ObligeeMailZip\">\r" +
    "\n" +
    "                                {{bond.obligeeMailZip}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"RequisitioningState\">\r" +
    "\n" +
    "                                {{bond.requisitioningState}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Tags\">\r" +
    "\n" +
    "                                <span class=\"tag-container\">\r" +
    "\n" +
    "                                    <span \r" +
    "\n" +
    "                                        ng-repeat=\"tag in bond.tags\"\r" +
    "\n" +
    "                                        class=\"tag\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                        {{tag}}\r" +
    "\n" +
    "                                    </span>\r" +
    "\n" +
    "                                </span>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"Broker\">\r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    ng-hide=\"!bond.brokerName\"\r" +
    "\n" +
    "                                    ui-sref=\"main.eProducerAccountDetail({ id: bond.eProducerAccountId })\"\r" +
    "\n" +
    "                                    target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                    {{bond.brokerName}}\r" +
    "\n" +
    "                                    <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrokerLookupCode\">\r" +
    "\n" +
    "                                {{bond.brokerLookupCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrokerAgencyCode\">\r" +
    "\n" +
    "                                {{bond.brokerAgencyCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"BrokerSubAgencyCode\">\r" +
    "\n" +
    "                                {{bond.brokerAgencySubCode}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CancellationProvisionDays\">\r" +
    "\n" +
    "                                {{bond.cancellationProvisionDays}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CancellationNotificationReceipientType\">\r" +
    "\n" +
    "                                {{bond.cancellationNotificationReceipientTypeDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                            <span ng-switch-when=\"CancellationNotificationMethodTypes\">\r" +
    "\n" +
    "                                {{bond.cancellationNotificationMethodTypesDescription}}\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <span ng-switch-when=\"DetailButton\">\r" +
    "\n" +
    "                                <button show-bond-detail-modal=\"bond.bondId\">Details</button>\r" +
    "\n" +
    "                            </span>\r" +
    "\n" +
    "                        </td>\r" +
    "\n" +
    "                    </tr>\r" +
    "\n" +
    "                </tbody>\r" +
    "\n" +
    "            </table>\r" +
    "\n" +
    "        </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <empty-state\r" +
    "\n" +
    "            ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "            message=\"No bonds found\">\r" +
    "\n" +
    "        </empty-state>\r" +
    "\n" +
    "    </perfect-scrollbar>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <table-pagination-footer\r" +
    "\n" +
    "        ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "        table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "    </table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/purchasedBonds/purchasedBondsReportDropdown/purchasedBondsReportDropdownFilter.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"filterDropdownForm\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        uib-tooltip=\"Filters\"\r" +
    "\n" +
    "        tooltip-placement=\"top-right\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "             ({{vm.filterCount}})\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25 filter-dropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"btn-group top-padding right-padding bottom-padding left-padding fill flex\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('ConditionSet')\"\r" +
    "\n" +
    "                class=\"small fill nowrap\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType == 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType != 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Advanced Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-click=\"vm.setSearchType('Basic')\"\r" +
    "\n" +
    "                class=\"small fill nowarp\"\r" +
    "\n" +
    "                ng-class=\"{'active' : vm.modifiedFilters.searchType != 'ConditionSet', 'inactive' : vm.modifiedFilters.searchType == 'ConditionSet'}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Basic Search\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul  \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType != 'ConditionSet'\" \r" +
    "\n" +
    "            class=\"min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "                class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"small v-center\"\r" +
    "\n" +
    "                    ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                            \r" +
    "\n" +
    "                    Clear Filters\r" +
    "\n" +
    "                </a>     \r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\">Status</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.status\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "                        <option value=\"\"> Any </option>\r" +
    "\n" +
    "                        <option value=\"Active\"> Active </option>\r" +
    "\n" +
    "                        <option value=\"Cancelled\"> Cancelled </option>\r" +
    "\n" +
    "                        <option value=\"PendingCancellation\"> PendingCancellation </option>\r" +
    "\n" +
    "                        <option value=\"PendingReinstatement\"> PendingReinstatement </option>\r" +
    "\n" +
    "                        <option value=\"Expired\"> Expired </option>\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <label class=\"small\"> Carrier </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.carrierSystemAccountId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.carrierOptions\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li \r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                class=\"input-field-row\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <label class=\"small\"> Agency </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <select\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.agencySystemAccountId\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                        options=\"vm.agencyOptions\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\">\r" +
    "\n" +
    "                    </select>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Bond Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumAmount\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"input-field-row\">\r" +
    "\n" +
    "                <label class=\"small\"> Effective Date Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.minimumEffectiveDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.modifiedFilters.basicFilters.maximumEffectiveDate\"\r" +
    "\n" +
    "                        aa-label-strategy=\"none\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <query-builder-condition-set\r" +
    "\n" +
    "            class=\"query-builder\" \r" +
    "\n" +
    "            ng-if=\"vm.modifiedFilters.searchType == 'ConditionSet'\"\r" +
    "\n" +
    "            condition-set=\"vm.modifiedFilters.conditionSet\"\r" +
    "\n" +
    "            property-option-groups=\"vm.table.queryConditionPropertyGroups\">\r" +
    "\n" +
    "        </query-builder-condition-set>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul>\r" +
    "\n" +
    "            <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <li class=\"button-row\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"white small\"\r" +
    "\n" +
    "                    ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Cancel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Apply\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/riders/riderReport.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div class=\"bottom-margin flex\"\r" +
    "\n" +
    "     ng-form=\"searchForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input aa-fg-class=\"table-search-field v-center\"\r" +
    "\n" +
    "           aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "           ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "           aa-label-strategy=\"placeholderOnly\"\r" +
    "\n" +
    "           aa-label=\"Search Bond Rider Transactions\"\r" +
    "\n" +
    "           uib-tooltip=\"Search Bond Rider Transactions\"\r" +
    "\n" +
    "           focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        class=\"v-center small left-margin-auto\"\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a id=\"rider-report-refresh-button\"\r" +
    "\n" +
    "       class=\"v-center left-margin\"\r" +
    "\n" +
    "       ng-click=\"vm.table.refresh()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-redo\"></i>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <rider-report-filter-dropdown class=\"flex left-margin\"\r" +
    "\n" +
    "                                  ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "                                  table=\"vm.table\">\r" +
    "\n" +
    "    </rider-report-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center dropdown actions\"\r" +
    "\n" +
    "         uib-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a class=\"left-padding\"\r" +
    "\n" +
    "           uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                        aa-submit-form=\"vm.exportExcelReport()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table\r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"group-by-tbody small bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>&nbsp;</th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Number\"\r" +
    "\n" +
    "                    sort-expression=\"Bonds.bondNumber\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Transaction Date\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.createdDateTime\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Principal\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.nameOnBond\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Effective Date\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.transactionEffectiveDate\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Premium\"\r" +
    "\n" +
    "                    sort-expression=\"premium\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th \r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Fees\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.fees\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"fit-content\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Commission\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.commission\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th class=\"text-left\">\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Bond Type\"\r" +
    "\n" +
    "                    sort-expression=\"BondTypes.name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Writing Company\"\r" +
    "\n" +
    "                    sort-expression=\" WritingCompanies.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th \r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Agency\"\r" +
    "\n" +
    "                    sort-expression=\"AgencySystemAccount.CompanyName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th \r" +
    "\n" +
    "                class=\"fit-content\"\r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Reason Code\"\r" +
    "\n" +
    "                    sort-expression=\"BondTransactions.reasonCode\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th></th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    <tbody ng-repeat=\"rider in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                class=\"expand-indicator-column cursor-pointer\"\r" +
    "\n" +
    "                id=\"{{'rider-transaction-list-expand-cell-' + $index}}\"\r" +
    "\n" +
    "                ng-click=\"vm.table.toggleIsExpanded($index)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i ng-class=\"{\r" +
    "\n" +
    "                    'fa fa-caret-right': !vm.table.rows[$index].isExpanded, \r" +
    "\n" +
    "                    'fa fa-caret-down': vm.table.rows[$index].isExpanded\r" +
    "\n" +
    "                }\"></i>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content text-left\">\r" +
    "\n" +
    "                {{rider.bondNumber}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <span ng-bind-html=\"rider.createdDateTime | localDateTime : 'twoLine'\"></span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\" \r" +
    "\n" +
    "                class=\"fit-content text-left\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{rider.nameOnBond}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\" \r" +
    "\n" +
    "                class=\"fit-content text-left\">\r" +
    "\n" +
    "               <a\r" +
    "\n" +
    "                    ui-sref=\"main.customerDetail({ customerId: rider.customerId, sectionView: 'bonds'})\"\r" +
    "\n" +
    "                    ng-show=\"!(rider.eProducerAccountId && rider.eProducerAccountType === eProducerAccountType.Broker)\"\r" +
    "\n" +
    "                    target=\"_blank\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <span ng-show=\"!rider.eProducerAccountId\">{{rider.nameOnBond}}</span>\r" +
    "\n" +
    "                    <i class=\"left-margin-thin fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "               </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "               <a\r" +
    "\n" +
    "                    ui-sref=\"main.eProducerAccountDetail({ id: rider.eProducerAccountId })\"\r" +
    "\n" +
    "                    ng-show=\"rider.eProducerAccountId && rider.eProducerAccountType === eProducerAccountType.Broker\"\r" +
    "\n" +
    "                    target=\"_blank\">\r" +
    "\n" +
    "               \r" +
    "\n" +
    "                    <i class=\"left-margin-thin small fa fa-external-link-alt\"></i>\r" +
    "\n" +
    "                    <span ng-show=\"rider.eProducerAccountId && rider.eProducerAccountType === eProducerAccountType.Broker\">Broker - {{rider.eProducerAccountName}}</span>\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{rider.transactionEffectiveDate | dateOnly}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{rider.premium - rider.refundedPremium | currency:'$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{rider.fees | currency:'$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                {{rider.commission | currency:'$'}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"nowrap\">\r" +
    "\n" +
    "                {{rider.bondTypeName | characters: 30}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\" \r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{rider.writingCompany}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                <system-account-logo\r" +
    "\n" +
    "                    system-account-id=\"rider.carrierSystemAccountId\"\r" +
    "\n" +
    "                    company-name=\"rider.carrierName\"\r" +
    "\n" +
    "                    writing-company-name=\"rider.writingCompany\"\r" +
    "\n" +
    "                    show-labels=\"true\">\r" +
    "\n" +
    "                </system-account-logo>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\" \r" +
    "\n" +
    "                class=\"fit-content\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{rider.agencyName}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td \r" +
    "\n" +
    "                class=\"fit-content\"\r" +
    "\n" +
    "                ng-show=\"vm.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                {{rider.reasonCode}} - {{rider.reasonText}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    show-bond-detail-modal=\"rider.bondId\" \r" +
    "\n" +
    "                    uib-tooltip=\"Bond Details\">Details</button><button\r" +
    "\n" +
    "                    show-download-documents-modal=\"rider.bondId\" \r" +
    "\n" +
    "                    uib-tooltip=\"Download Documents\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <tr ng-show=\"vm.table.rows[$index].isExpanded\">\r" +
    "\n" +
    "            <td colspan=\"100\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <h5 class=\"sub-heading\">\r" +
    "\n" +
    "                        Changes\r" +
    "\n" +
    "                    </h5>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"text-left\"\r" +
    "\n" +
    "                        ng-repeat=\"change in rider.bondChanges\"\r" +
    "\n" +
    "                        id=\"{{'rider-list-change-list-' + $parent.$index + '-' + $index}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{change.description}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state\r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "    message=\"No records found\">\r" +
    "\n" +
    "</empty-state>"
  );


  $templateCache.put('app/states/common/riders/riderReportFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"newRiderFilterDropdown\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\"> ({{vm.filterCount}})</b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    " \r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"!vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\">Carrier</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.carrierSystemAccountId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.carrierOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.isCarrier\"\r" +
    "\n" +
    "            class=\"input-field-row\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label class=\"small\">Agency</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.agencySystemAccountId\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.agencyOptions\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "         \r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Transaction Date</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumCreatedDateTime\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "         \r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Effective Date</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\">and</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumEffectiveDate\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"dateString\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\"> Bond Amount Between </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.minimumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"between-label\"> and </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.maximumAmount\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"money\" />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/termsAndConditions/termsConditions.html',
    "<section>\r" +
    "\n" +
    "    <div class=\"terms-and-conditions\">\r" +
    "\n" +
    "        <h2>TERMS AND CONDITIONS AGREEMENT</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            The following Terms and Conditions constitute a binding legal contract between you and Agency Multiplied, Inc. (&quot;A3&quot;).  Please carefully read these terms as they affect your legal rights.  You (&quot;User&quot;) are invited to consult with counsel prior to accepting the terms of this agreement.  Before you use the services provided by A3 you must read and accept all of the terms and conditions in this agreement.\r" +
    "\n" +
    "            This agreement outlines the terms that govern your use of A3 software, specifically the dashboard and widget, together with all updates, additional terms, software licenses, and all of the A3 rules and policies referred to in this agreement.  Collectively these constitute the Terms and Conditions between you and A3.  By using A3 software you agree to these terms.  If you do not agree to these terms just click on the &quot;Decline&quot; button, and do not use the A3 services. You must accept and abide by these terms of use as presented to you in this agreement; changes, additions, or deletions are not acceptable, and A3 reserves the right to refuse access to the dashboard services for non-compliance with any part of this agreement.\r" +
    "\n" +
    "            By entering and using the A3 dashboard, you agree to abide by the following terms and conditions:\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2>PRIVACY</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            A3 protects User privacy and is dedicated to safeguarding your information. By using the A3&quot;s dashboard, you are agreeing that A3 may store the information you provide as part of the registration or surety bond request process and as described in the privacy policy below.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Privacy Policy</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Personal information is collected by A3, for the purposes of:\r" +
    "\n" +
    "        <dl>\r" +
    "\n" +
    "            <dt>1)</dt>\r" +
    "\n" +
    "            <dd>Obtaining quotes for User for surety bonds;</dd>\r" +
    "\n" +
    "            <dt>2)</dt>\r" +
    "\n" +
    "            <dd>Sharing with parties as approved by the owner of the personal information; and</dd>\r" +
    "\n" +
    "            <dt>3)</dt>\r" +
    "\n" +
    "            <dd>Responding to authorized requests related to information audits and legislative requirements.</dd>\r" +
    "\n" +
    "        </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            In addition, where our A3 products or services are involved, we use personal information as an integral part of an ongoing process of maintenance of our accounts, including but not limited to: considering, establishing and maintaining an A3 User profile, assessing the online use of the application as it relates to performance and pricing policies and execution, investigating, evaluating and handling potential system security breach claims, providing evidentiary support for recovering losses under indemnities and guarantees provided in support of A3 profile guarantees. In using personal information for these purposes A3 sometimes discloses personal information to: A3 employees directly involved in handling A3 business and those responsible for corporate governance, regulators and auditors, courts of law, legal counsel retained to advise or assist A3 with respect to A3 matters, financial institutions for processing your online payment and companies related to A3.\r" +
    "\n" +
    "            Individual online usage patterns will not be disclosed to either advertisers or to third parties. A3 will not disclose the name of your organization, or the names, addresses, telephone numbers, email addresses, or other personal identifying information regarding document signers or Users, without your consent.  A3 does not process or store your credit card information on any of its servers. This information is managed by our payment gateway provider.\r" +
    "\n" +
    "            By using this service, you are agreeing that A3 will store the documents administered within its online servers and make them accessible to you and the Users you share these documents with.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        By using A3 software you are agreeing that:\r" +
    "\n" +
    "        <dl>\r" +
    "\n" +
    "            <dt>a)</dt>\r" +
    "\n" +
    "            <dd>A3 has your consent to use the personal information you provide about yourself for the purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "            <dt>b)</dt>\r" +
    "\n" +
    "            <dd>When you provide Personal Information about others you have that person&quot;s consent to disclose information about them to A3 for the purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "            <dt>c)</dt>\r" +
    "\n" +
    "            <dd>The specific personal information you are authorized to access under your User ID and Password will be used only for purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "            <dt>d)</dt>\r" +
    "\n" +
    "            <dd>A3 has your consent to send you, the other persons whose consent you have and your business associates information relevant to the A3 services via email or any other electronic or print method.</dd>\r" +
    "\n" +
    "            <dt>e)</dt>\r" +
    "\n" +
    "            <dd>A3 has your consent to make your Surety bond requests available to its partner surety bonding companies (&quot;Partner Carriers&quot;), for the purpose of presenting a no obligation offer to you.</dd>\r" +
    "\n" +
    "            <dt>f)</dt>\r" +
    "\n" +
    "            <dd>A3 will retain personal information for the same period of time it is requested by Users to retain the documents containing the personal information. By using A3 software, you are agreeing that A3 may store the information you provided as part of the registration process and as described in this Privacy Policy.</dd>\r" +
    "\n" +
    "        </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Sharing of Information Received</h3>\r" +
    "\n" +
    "        A3 shares Personal Information only as described below and with controls that either are subject to the A3 Privacy Policy or follow practices at least as protective as those described in this agreement.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <dl>\r" +
    "\n" +
    "            <dt>a)</dt>\r" +
    "\n" +
    "            <dd>PARTNER BUSINESSES WE DO NOT CONTROL: We work closely with companies with which you have established a business relationship. In some cases, these businesses may invite you and in other cases, you may invite them to be part of your working group. Or other organisation users may request the verification of the authenticity of documents signed and sealed by you. We may also share the information related to the surety bond transactions with any party that you have submitted a request to receive an offer for surety bonds or wishes to verify of the authenticity of documents signed or sealed by you.</dd>\r" +
    "\n" +
    "            <dt>b)</dt>\r" +
    "\n" +
    "            <dd>THIRD-PARTY SERVICE PROVIDERS: We employ other companies and individuals to perform functions on our behalf. Examples include data centers, email, postal mail and e-mail service providers, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card and other electronic payments, credit report bureaus, electronic signature service providers and other providing customer service. They may have access to your personal information needed to perform their functions, but may not use it for other purposes.</dd>\r" +
    "\n" +
    "            <dt>c)</dt>\r" +
    "\n" +
    "            <dd>A3 PARTNER CARRIER OFFERS: Sometimes we may send surety bond related offers to selected Agency Users on behalf of our Partner Carriers. These Partner Carriers offers are intended to provide the Agency Users with alternative options to choose from. You are under no obligation to accept these offers.</dd>\r" +
    "\n" +
    "            <dt>d)</dt>\r" +
    "\n" +
    "            <dd>PROMOTIONAL OFFERS: Sometimes we send offers to selected groups of A3 customers on behalf of our businesses. If you do not want to receive such offers, please contact us to remove you from any marketing communication.</dd>\r" +
    "\n" +
    "            <dt>e)</dt>\r" +
    "\n" +
    "            <dd>BUSINESS TRANSFERS: As we continue to develop our business, we might sell or buy subsidiaries, or business units. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Statement (unless, of course, the customer consents otherwise). Also, in the unlikely event that A3 or substantially all of its assets are acquired, customer information will of course be one of the transferred assets.</dd>\r" +
    "\n" +
    "            <dt>f)</dt>\r" +
    "\n" +
    "            <dd>PROTECTION OF A3 AND OTHERS: We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our terms and services and other agreements; or protect the rights, property, or safety of A3, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Policy statement.</dd>\r" +
    "\n" +
    "            <dt>g)</dt>\r" +
    "\n" +
    "            <dd>WITH YOUR CONSENT: Other than as set out above, you will receive notice when information about you might go to third parties, and you will have an opportunity to choose not to share the information.</dd>\r" +
    "\n" +
    "        </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Any questions or concerns about this Privacy Statement or about A3&quot;s policies or practices in dealing with personal information should be directed to A3: Attention: Privacy 4285 Commercial St. SE, Suite 110, Salem, OR 97302\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Security</h3>\r" +
    "\n" +
    "        A3 is committed to security. We use secured data centers with redundant power and internet connectivity. A3 employs commercially reasonable precautions to maintain the security of the system, including the use of industry standard encryption methods to prevent unauthorized access to customer documents and account information stored on our servers. The Service also protects information with the HTTPS security protocol during transmission to your web browser.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Cookies</h3>\r" +
    "\n" +
    "        Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device and to provide features such as, personalized user interface and seamless navigation throughout the A3 Web sites, and session tracking.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2>USER REPRESENTATIONS AND OBLIGATIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Amendment</h3>\r" +
    "\n" +
    "        A3 and the Websites retain the right to amend the terms of this agreement without prior consent of or notice to the User.  You can review the most current version of the Terms and Conditions at any time by clicking the &quot;Terms of Conditions&quot; link located at the bottom of the Website pages.  Additionally, when using particular A3 owned or operated services, you are subject to any posted guidelines or rules applicable to such services, which may be posted from time to time.\r" +
    "\n" +
    "        You are responsible for reviewing the current version of the Terms and Conditions periodically.  A3 may amend this agreement by (a) posting a revised Customer Terms and Conditions on the A3 Websites, or (b) sending information regarding the amendment at login or to the email address you provide to A3.  Continued use of the Service will be deemed acceptance of the amended Terms and Conditions.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Proper Use</h3>\r" +
    "\n" +
    "        You agree to comply with the terms and conditions of this agreement and with all applicable local, state, federal and international laws and regulations and all Internet regulations, policies and procedures, including all export and other laws regarding the transmission of technical data exported from any country through the Service. You agree that you will not use the Service for illegal purposes, to disrupt to the Service, or to distribute content that violates the privacy, intellectual property or other proprietary rights of any third party, or for purposes that A3 reasonably determines to be unlawful, obscene, defamatory, harassing, fraudulent, abusive, threatening, harmful, vulgar, or otherwise objectionable. You are wholly responsible for the contents of your transactions through the Service.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>No Resale of Services Permitted:</h3>\r" +
    "\n" +
    "        You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of A3&quot;s software, use of the service, or access to the service without the written consent of A3.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Eligibility</h3>\r" +
    "\n" +
    "        The A3 service is available only to persons who can form a legal binding contract. Website is not available for use by minors (under 18) without parental consent.  By entering the Website and entering this TERMS AND CONDITIONS agreement, you represent and warrant that you are at least 18 years of age (or, as applicable, the age of majority in the state in which you reside), and that you possess the legal right and ability to enter into this TERMS AND CONDITIONS and you certify that you are of legal age and not restricted to using the Website by any United States or International law, or otherwise by any other law, TERMS AND CONDITIONS, or regulation.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Account Registration</h3>\r" +
    "\n" +
    "        A3 may require you to register for a password-protected account. You represent and warrant that all of the information you provide to A3 is true, accurate and complete, and that you have legal right to use the e-mail address(es) you provide. You agree to keep your information current by updating your account information. You agree to maintain the confidentiality of your password and account. You are responsible for all activities that occur under your account. A3 reserves the right to deactivate or close your account if your account remains inactive for more than twelve months.\r" +
    "\n" +
    "        Account Cancellation\r" +
    "\n" +
    "        You may cancel your account and terminate this TERMS AND CONDITIONS at any time.  A3recommends printing or copying any important documents because you will lose access to documents, messages or other content contained in your account after cancellation.  A3 reserves the right to terminate any account that violates the terms of this TERMS AND CONDITIONS.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Communication</h3>\r" +
    "\n" +
    "        You agree that the official time for all transactions using the Service will be the timestamps recorded by the A3&quot;s servers. You also agree that all electronic communications or notices sent to the e-mail address you provide to A3, placed in your account, or posted on the Web Site will be considered &quot;in writing&quot; and received within five (5) business days of its dissemination.  A3 disclaims all responsibility for all failures in communication caused by failures of third parties to properly process or deliver A3&quot;s electronic communications.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2>A3'S RIGHTS AND DISCLAIMERS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Relationship to Documents</h3>\r" +
    "\n" +
    "        Unless A3 is named as a direct party, A3&quot;s relationship to all documents and transactions completed using the Service is that of a trusted, disinterested third party. Accordingly, A3 disclaims any representations of any kind regarding the documents or transactions that pass through the Service. You are responsible for recognizing the parties of interest in all transactions you complete using the Service and assessing all associated risks.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Maintenance</h3>\r" +
    "\n" +
    "        The Service will occasionally be unavailable during system maintenance and upgrades. A3 will make commercially reasonable efforts to ensure that the Service will be available during any system maintenance period.  However, A3 disclaims all responsibility for any interruptions or problems with the Service caused by external forces affecting the reliability of the Internet or computer systems.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Limitation of Liability</h3>\r" +
    "\n" +
    "        YOU EXPRESSLY UNDERSTAND AND AGREE THAT A3 SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH A3 HAS BEEN ADVISED OF THE POSSIBILITY THEREOF), AND INCLUDING DAMAGES RESULTING FROM: (i) ANY DOCUMENT OR TRANSACTION SENT THROUGH THE SERVICE WHERE A3 WAS NOT A DIRECT PARTY; (ii) THE USE OR INABILITY TO USE THE SERVICE, INCLUDING ERRORS, INTERRUPTIONS OR DELAYS; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR DOCUMENTS OR TRANSMISSIONS; OR (iv) ANY OTHER MATTER RELATING TO THE SERVICE. A3&quot;s LIABILITY SHALL NOT EXCEED, IN THE AGGREGATE, A SUM EQUAL TO THE AMOUNT OF FEES (IF ANY) PAID TO A3 FOR USING THE SERVICE TO COMPLETE THE TRANSACTION UNDER THIS TERMS AND CONDITIONS.  YOU UNDERSTAND AND AGREE TO THAT YOU WILL AT ALL TIMES INDEMNIFY, AND KEEP INDEMNIFIED, A3, AND HOLD AND SAFE A3 HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY, LOSS, COSTS, DAMAGES, CHARGES AND EXPENSES OF WHATSOEVER KIND AND NATURE, INCLUDING EXPENSES, COLLECTIONS FEES AND ATTORNEYS&quot; FEE, WHICH IT MAY, AT ANY TIME, SUSTAIN OR INCUR BY REASON OF USING A3 OR UNDERTAKING OR ENFORCING THIS AGREEMENT.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Disclaimer of Warranty</h3>\r" +
    "\n" +
    "        YOU EXPRESSLY UNDERSTAND AND AGREE THAT:\r" +
    "\n" +
    "        1. YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. A3 PROVIDES THE SERVICE ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. A3 EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.\r" +
    "\n" +
    "        2. A3 DOES NOT WARRANT AND EXPRESSLY DISCLAIMS THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THE SERVICE WILL BE UNINTERRUPTED, RELIABLE, TIMELY, SECURE, ERROR-FREE OR FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, (iii) ANY ERRORS ON THE A3 WEB SITE OR SERVICE WILL BE CORRECTED; AND (iv) THAT DOCUMENTS OR MATERIALS THAT YOU STORE VIA THE SERVICE WILL REMAIN ACCESSIBLE\r" +
    "\n" +
    "        3. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR SOLE DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.\r" +
    "\n" +
    "        4. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM A3, OR THROUGH OR FROM THE A3 WEB SITE OR SERVICE, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TERMS AND CONDITIONS.\r" +
    "\n" +
    "        5. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF CERTAIN WARRANTIES OR THE LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Intellectual Property Rights</h3>\r" +
    "\n" +
    "        You acknowledge and agree that A3 owns all right, title and interest in the Service and the web site, including, without limitation, all intellectual property rights. You acknowledge and agree that the Service and the website contain proprietary and confidential information and trade secrets including, but not limited to text, graphics, logos, images, software, and icons, that are protected by United States and international intellectual property laws and other applicable laws.\r" +
    "\n" +
    "        You agree not to display or use A3 Marks in any manner without A3&quot;s prior written permission.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>A3&quot;s Bonding Services Terms</h3>\r" +
    "\n" +
    "        By using the services provided by A3 and its websites, you expressly understand and agree to the following:\r" +
    "\n" +
    "        a)	You shall provide correct and accurate answers to all questions asked by the A3 system on behalf of the member bonding companies or its agents.  You expressly understand and agree that intentional misinformation is considered insurance fraud.\r" +
    "\n" +
    "        b)	You understand false statements may result in forfeiture of benefits and possible prosecution by the US Attorney-General - (Any person who knowingly and with the intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing false, or misleading information is guilty of a felony of the third degree.\r" +
    "\n" +
    "        c)	Obtaining surety bond quotes is 100% free and there is no cost should you decline the offer.\r" +
    "\n" +
    "        d)	The quote you may receive is a conditional offer and is subject to change based on a multitude of considerations.\r" +
    "\n" +
    "        e)	The quote may include a broker fee. Any possible broker fees are part of the quote you may receive.\r" +
    "\n" +
    "        f)	You may or may not receive a quote immediately from an online surety bond request, as some applicants require further review by a human in order to obtain the lowest rate.\r" +
    "\n" +
    "        g)	Online applications are contracts between the principal applying for said bond and the bond agency. By agreeing to the TERMS AND CONDITIONS, you hereby understand and agree that the bond premium is fully earned upon issuance of the bond and is not refundable.\r" +
    "\n" +
    "        h)	If your bond is cancelled mid-term, you also understand and agree that any and all fees and premiums are also fully earned, and are not refundable. In addition, fees may earn interest. This undeclared income is non-refundable and fully earned as well as any fees charged.\r" +
    "\n" +
    "        i)	Completion of an online surety bond request form constitutes permission for the agency and their bonding companies to obtain consumer information from any source which will be used to determine bonding eligibility. This includes obtaining credit reports at the time of application, in any review, or renewal, at the time of any potential or actual claim, or for any other legitimate purposes as determined by the bonding company in its reasonable discretion. This information will be held in the strictest of confidence. Soft credit reports that do not count as inquires on your report will be used whenever possible.\r" +
    "\n" +
    "        j)	This agreement shall serve as the writing required pursuant to the rules set forth by your Department of Insurance.\r" +
    "\n" +
    "        k)	You expressly understand and consent that the information submitted using the A3 system may be shared with 3rd parties in order to assess risk and or approve your bond requests.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Electronic Signature Service: Policies</h3>\r" +
    "\n" +
    "        A3 uses a third party electronic signature service.  If you use the Service and whenever applicable, you are agreeing to conduct business transactions with electronic documents and signatures instead of paper-based documents and signatures. You are under no obligation to transact business electronically. To withdraw your consent to conduct electronic transactions, simply stop using the Electronic Signature Service portion of the transaction and contact the sending party to explore other options. You may read and sign each document on a case-by-case basis. Each decision to view or sign a document electronically does not affect the legal effect of any transactions already completed using either electronic or paper-based documents or signatures. You hereby agree to read every document before electronically signing it. You also agree to communicate all issues regarding the content of a document directly with the sending party. Finally, you agree to notify the sending parties and the Service of any change in your e-mail address in order to prevent interruptions to your communications. You acknowledge and understand that electronic signatures are legally binding in the United States and other countries. You also understand that printed copies of electronic documents might not be considered legal originals, but rather copies of the digitally signed documents or held within the A3 Service.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Electronic Signature Service: Requirements</h3>\r" +
    "\n" +
    "        Use of the Service requires a standards-compliant web-browser which supports the HTTPS protocol, HTML, Java Runtime Environment and cookies. Electronic signatures are void where prohibited by law.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Legal Document Service: Legal Information Disclaimer</h3>\r" +
    "\n" +
    "        The Service offers documents and other information that may be relevant to the legal needs of our users. However, the legal information available on or through the Service does not constitute legal advice as it does not draw legal conclusions or apply the law to the specific circumstances of each user.  If you require legal advice, please consult a law services professional in your area.  Your use of the Service cannot replace the advice of a lawyer or create a lawyer-client relationship between you and A3.  Although A3 makes reasonable efforts to ensure the accuracy of the legal information it provides, the law is constantly changing, and A3 cannot guarantee the correctness or completeness of the legal information.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2>GENERAL PROVISIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Entire Agreement</h3>\r" +
    "\n" +
    "        This Agreement constitutes the entire agreement between you and A3 and its related Websites and governs your use of the A3&quot;s Service. This Agreement supersedes all prior agreements and understandings between you and A3 and its related Websites, whether written or oral.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Indemnification</h3>\r" +
    "\n" +
    "        You agree to hold harmless and indemnify A3 and its subsidiaries, affiliates, officers, agents, directors, managers, employees, and successors from any claims, losses, damages, suits, demands, liabilities, judgments, litigation costs and attorneys&quot; fees arising from or in any way related to your use of the Service, your breach of any of your obligations under this Agreement, or your violation of any applicable laws or regulations.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Severability and Waiver</h3>\r" +
    "\n" +
    "        If a court of competent jurisdiction declares any term or provision in this Agreement invalid or unenforceable, you and A3 nevertheless agree that the court should construe that term or provision consistent with applicable law as nearly as possible to reflect the parties&quot; original intentions, and that the remaining terms and provisions remain in full force and effect. Failure by A3 to exercise or enforce any provision of this Agreement shall not constitute a waiver of any such provision.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Choice of Law</h3>\r" +
    "\n" +
    "        This Agreement shall be governed by the laws of the State of Oregon, USA without regard to its conflict of law provisions. You and A3 agree to submit to the personal and exclusive jurisdiction of the courts located within Marion County, Oregon, USA.\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h3>Mediation</h3>\r" +
    "\n" +
    "        All claims, disputes, and controversies arising out of or in relation to the performance, interpretation, application, or enforcement of this agreement, including but not limited to breach thereof, shall be referred to mediation before, and as a condition precedent to, the initiation of any adjudicative action or proceeding, including arbitration.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</section>"
  );


  $templateCache.put('app/states/common/userDetail/usersDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"userForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-title\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <span ng-show=\"vm.newUser\">\r" +
    "\n" +
    "                Add New User\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-show=\"!vm.newUser\">\r" +
    "\n" +
    "                User - {{ vm.user.firstName }} {{ vm.user.lastName }}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"pull-right\">\r" +
    "\n" +
    "            <input\r" +
    "\n" +
    "                aa-field-group=\"vm.user.isLockedOut\"\r" +
    "\n" +
    "                aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                aa-fg-class=\"no-top-margin\" />\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"page-body\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-list\"></i>Personal Info\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.id\"\r" +
    "\n" +
    "                        disabled aa-label=\"User Id\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.firstName\"\r" +
    "\n" +
    "                        required\r" +
    "\n" +
    "                        focus-me />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.lastName\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-user\"></i>Login Info\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.email\"\r" +
    "\n" +
    "                        aa-label=\"Email/Username\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"email\"\r" +
    "\n" +
    "                        required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.user.password\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                        name=\"userPassword\"\r" +
    "\n" +
    "                        ng-pattern=\"/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/\"\r" +
    "\n" +
    "                        ng-pattern-msg=\"Password requires 1 upper-case, 1 lower-case, 1 numeric and must be at least 8 characters long\"\r" +
    "\n" +
    "                        ng-required=\"vm.newUser || vm.confirmPassword\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.confirmPassword\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"password\"\r" +
    "\n" +
    "                        ng-required=\"vm.newUser || vm.user.password\"\r" +
    "\n" +
    "                        ng-match=\"vm.user.password\" />\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-unlock\"></i>Permissions\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <div class=\"col-md-6\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.user.canEditUsers\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.user.canEditObligees\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input aa-field-group=\"vm.user.canEditDocumentLibrary\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                                <input aa-field-group=\"vm.user.canEditActivitySettings\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "                                <input \r" +
    "\n" +
    "                                    aa-field-group=\"vm.user.canEditApiConfiguration\"\r" +
    "\n" +
    "                                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"col-md-6\">\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.user.canEditUserGroups\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.user.canEditBondTypes\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                aa-field-group=\"vm.user.canEditSystemAccounts\"\r" +
    "\n" +
    "                                aa-label=\"Can Edit Our Company Info\"\r" +
    "\n" +
    "                                aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section class=\"text-left\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <span class=\"v-center\">\r" +
    "\n" +
    "                            Attorney-in-Fact\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <input \r" +
    "\n" +
    "                            aa-field-group=\"vm.user.isAttorneyInFact\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"toggleSwitch\"\r" +
    "\n" +
    "                            aa-fg-class=\"v-center left-margin-auto\" />\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        class=\"alert alert-danger\"\r" +
    "\n" +
    "                        ng-show=\"vm.signatureImageRequired\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        You must upload a signature image for Attorney-in-Fact users\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"vm.user.isAttorneyInFact\">\r" +
    "\n" +
    "                        <h5>\r" +
    "\n" +
    "                            Signature Image\r" +
    "\n" +
    "                        </h5>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <p>\r" +
    "\n" +
    "                            Attach an image of the signature to be used on forms\r" +
    "\n" +
    "                            that can be filed with printed signatures.\r" +
    "\n" +
    "                        </p>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            class=\"flex\"\r" +
    "\n" +
    "                            ng-show=\"!vm.signatureUploaderFiles[0]\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <div class=\"top-margin width-300 left-margin-auto right-margin-auto\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                <img\r" +
    "\n" +
    "                                    ng-show=\"vm.user.signatureImageFileId\"\r" +
    "\n" +
    "                                    ng-src=\"{{ vm.signatureImagePath }}\"\r" +
    "\n" +
    "                                    alt=\"User Signature\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                <div class=\"top-margin\">\r" +
    "\n" +
    "                                    <uploader\r" +
    "\n" +
    "                                        controls=\"vm.signatureUploaderControls\"\r" +
    "\n" +
    "                                        accepted-types=\"images\"\r" +
    "\n" +
    "                                        files=\"vm.signatureUploaderFiles\">\r" +
    "\n" +
    "                                    </uploader>\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <main \r" +
    "\n" +
    "                            class=\"top-margin\"\r" +
    "\n" +
    "                            ng-show=\"vm.signatureUploaderFiles[0]\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <h5 class=\"flex\">\r" +
    "\n" +
    "                                New signature attached\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <a \r" +
    "\n" +
    "                                    ng-click=\"vm.removeSignatureUploaderFile()\"\r" +
    "\n" +
    "                                    class=\"left-margin small v-center\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                                    Remove <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                                </a>\r" +
    "\n" +
    "                            </h5>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <p>\r" +
    "\n" +
    "                                Filename:\r" +
    "\n" +
    "                                <strong>{{vm.signatureUploaderFiles[0].file.name}}</strong>\r" +
    "\n" +
    "                            </p>\r" +
    "\n" +
    "                        </main>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <section ng-show=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <header>Servicing Roles</header>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.user.isProducer\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-lg-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.user.isClientServiceExecutive\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-lg-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.user.isClientServiceManager\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-lg-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.user.isClientServiceAgent\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-fg-class=\"col-lg-6\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <section ng-show=\"vm.isEpicIntegrationEnabled\">\r" +
    "\n" +
    "                    <header class=\"flex\">\r" +
    "\n" +
    "                        <img class=\"v-center epic-integration-icon\"\r" +
    "\n" +
    "                            src=\"/img/icons/Epic_logo.png\" />\r" +
    "\n" +
    "                        Epic Integration Settings\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    <div class=\"row\">\r" +
    "\n" +
    "                        <input aa-field-group=\"vm.user.epicUserCode\"\r" +
    "\n" +
    "                            aa-fg-class=\"field-wrapper col-xs-12\" />\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <section>\r" +
    "\n" +
    "                    <header>\r" +
    "\n" +
    "                        <i class=\"fa fa-users\"></i>User Groups\r" +
    "\n" +
    "                    </header>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div ng-repeat=\"userGroup in vm.userGroupOptions\">\r" +
    "\n" +
    "                        <input\r" +
    "\n" +
    "                            aa-field-group=\"userGroup.isSelected\"\r" +
    "\n" +
    "                            aa-field-group-strategy=\"check\"\r" +
    "\n" +
    "                            aa-label=\"{{userGroup.name}}\"/>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </section>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-md-6\">\r" +
    "\n" +
    "                <notification-selection user=\"vm.user\">\r" +
    "\n" +
    "                </notification-selection>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ui-sref=\"main.usersGrid\"\r" +
    "\n" +
    "            class=\"white\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-submit-form=\"vm.save_click()\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            value=\"Save\"\r" +
    "\n" +
    "            id=\"saveButton\" />\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/common/userGroupDetail/groupsDetail.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form \r" +
    "\n" +
    "    novalidate \r" +
    "\n" +
    "    name=\"groupForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-list\"></i>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            User Group\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <input\r" +
    "\n" +
    "            aa-field-group=\"vm.group.name\"\r" +
    "\n" +
    "            aa-label=\"Group Name\"\r" +
    "\n" +
    "            required\r" +
    "\n" +
    "            focus-me/>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <i class=\"fa fa-unlock\"></i>Permissions\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditUsers\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditObligees\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditDocumentLibrary\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canEditActivitySettings\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canEditApiConfiguration\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"col-sm-6\">\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditUserGroups\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditBondTypes\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input\r" +
    "\n" +
    "                    aa-field-group=\"vm.group.canEditSystemAccounts\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"check\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canReturnPayments\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.isCarrier\">\r" +
    "\n" +
    "                    <input\r" +
    "\n" +
    "                        aa-field-group=\"vm.group.canEditBillingEntriesReport\"\r" +
    "\n" +
    "                        aa-field-group-strategy=\"check\" />\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ui-sref=\"main.groupsGrid\"\r" +
    "\n" +
    "        class=\"white\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Back\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-submit-form=\"vm.save()\"\r" +
    "\n" +
    "        type=\"submit\"\r" +
    "\n" +
    "        value=\"Save\"/>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/common/userGroups/groupsGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"listSearchForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search Groups\"\r" +
    "\n" +
    "        uib-tooltip=\"Search by name\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewUserButton\" \r" +
    "\n" +
    "                    ui-sref=\"main.groupsDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New Group\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"UserGroups.Name\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                &nbsp;\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ui-sref=\"main.groupsDetail({ id: item.id })\">\r" +
    "\n" +
    "                    {{item.name}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <td class=\"fit-content\">\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    ng-disabled=\"!item.canBeDeleted\"\r" +
    "\n" +
    "                    ng-click=\"vm.promptToDelete(item)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No users groups were found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/userProfile/userProfile.html',
    "<ng-form name=\"profileForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div busy-indicator=\"{promise:vm.processingPromise}\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <section>\r" +
    "\n" +
    "        <div class=\"profile-user-image\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"ng-img-crop\" \r" +
    "\n" +
    "                ng-show=\"vm.isProfilePictureChanged\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <ui-cropper \r" +
    "\n" +
    "                    image=\"vm.userProfilePicture\" \r" +
    "\n" +
    "                    area-type=\"square\" \r" +
    "\n" +
    "                    result-image=\"vm.croppedImage\" \r" +
    "\n" +
    "                    result-image-size=\"{w: 200,h: 200}\">\r" +
    "\n" +
    "                </ui-cropper>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <label \r" +
    "\n" +
    "                for=\"fileInput\" \r" +
    "\n" +
    "                ng-show=\"!vm.isProfilePictureChanged\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <img \r" +
    "\n" +
    "                    class=\"profile-image\" \r" +
    "\n" +
    "                    ng-show=\"!vm.userProfilePicture\" \r" +
    "\n" +
    "                    ng-src=\"/img/unknown.profile.png\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <img \r" +
    "\n" +
    "                    ng-src=\"{{vm.userProfilePicture}}\" \r" +
    "\n" +
    "                    ng-show=\"vm.userProfilePicture\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button \r" +
    "\n" +
    "                    class=\"mini\" \r" +
    "\n" +
    "                    ng-show=\"vm.userProfilePicture\" \r" +
    "\n" +
    "                    ng-click=\"vm.promptRemoveProfileImage()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    type=\"file\" \r" +
    "\n" +
    "                    id=\"fileInput\" \r" +
    "\n" +
    "                    style=\"display: none;\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Change Profile Picture\r" +
    "\n" +
    "            </label>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"profile-user-info\">\r" +
    "\n" +
    "            <h4>{{vm.user.fullName}}</h4>\r" +
    "\n" +
    "            <span>{{vm.user.email}}</span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                ng-hide=\"vm.currentUser.isSingleSignOn\"\r" +
    "\n" +
    "                class=\"transparent change-password-button\" \r" +
    "\n" +
    "                ng-click=\"vm.promptChangePassword()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Change Password\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <select \r" +
    "\n" +
    "                aa-label=\"Current Theme\" \r" +
    "\n" +
    "                aa-field-group=\"vm.user.theme\" \r" +
    "\n" +
    "                aa-field-group-strategy=\"select\" \r" +
    "\n" +
    "                aa-option-value=\"value\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <option \r" +
    "\n" +
    "                    value=\"a3\" \r" +
    "\n" +
    "                    default>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Default\r" +
    "\n" +
    "                </option>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <option value=\"silver\">Silver</option>\r" +
    "\n" +
    "                <option value=\"dark\">Dark</option>\r" +
    "\n" +
    "            </select>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"row\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-fg-class=\"col-md-6\" \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.firstName\" \r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <input \r" +
    "\n" +
    "                    aa-fg-class=\"col-md-6\" \r" +
    "\n" +
    "                    aa-field-group=\"vm.user.lastName\" \r" +
    "\n" +
    "                    required />\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </section>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <notification-selection \r" +
    "\n" +
    "        class=\"section\"\r" +
    "\n" +
    "        user=\"vm.user\">\r" +
    "\n" +
    "    </notification-selection>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button ng-click=\"vm.save()\">Save</button>\r" +
    "\n" +
    "</ng-form>\r" +
    "\n"
  );


  $templateCache.put('app/states/common/users/userListFilterDropdown.html',
    "<div\r" +
    "\n" +
    "    class=\"dropdown actions left-margin-auto flex\"\r" +
    "\n" +
    "    uib-dropdown\r" +
    "\n" +
    "    auto-close=\"outsideClick\"\r" +
    "\n" +
    "    is-open=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-form=\"invoiceListFilterDropdownForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <a \r" +
    "\n" +
    "        class=\"v-center\"\r" +
    "\n" +
    "        ng-class=\"{'red': vm.filterCount}\"\r" +
    "\n" +
    "        uib-dropdown-toggle>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-filter\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <b ng-show=\"vm.filterCount\">({{vm.filterCount}})</b>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul \r" +
    "\n" +
    "        uib-dropdown-menu\r" +
    "\n" +
    "        class=\"dropdown-menu dropdown-menu-right min-width-25\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "            class=\"button-row text-right\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"small v-center\"\r" +
    "\n" +
    "                ng-click=\"vm.clearFilters()\">\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                Clear Filters\r" +
    "\n" +
    "            </a>     \r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row\">\r" +
    "\n" +
    "            <label class=\"small\">Is Locked Out</label>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.isLockedOut\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <option value=\"\">Any</option>\r" +
    "\n" +
    "                    <option value=\"true\">Locked Out</option>\r" +
    "\n" +
    "                    <option value=\"false\">Active</option>\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"input-field-row top-padding-small\">\r" +
    "\n" +
    "            <label class=\"small\">User Group</label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex\">\r" +
    "\n" +
    "                <select\r" +
    "\n" +
    "                    aa-field-group=\"vm.modifiedFilters.userGroupId\"\r" +
    "\n" +
    "                    aa-label-strategy=\"none\"\r" +
    "\n" +
    "                    aa-field-group-strategy=\"select\"\r" +
    "\n" +
    "                    options=\"vm.userGroupOptions\">\r" +
    "\n" +
    "                </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"divider\"></li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li class=\"button-row\">\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"white small\"\r" +
    "\n" +
    "                ng-click=\"vm.cancel()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Cancel\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "            <button \r" +
    "\n" +
    "                class=\"small\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                aa-submit-form=\"vm.applyFilters()\">                                    \r" +
    "\n" +
    "\r" +
    "\n" +
    "                Apply\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/common/users/usersGrid.html',
    "<div busy-indicator=\"vm.busyIndicator\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-form=\"userListSearchForm\"\r" +
    "\n" +
    "    class=\"flex bottom-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        aa-fg-class=\"table-search-field v-center right-margin-auto\"\r" +
    "\n" +
    "        aa-field-group=\"vm.table.queryOptions.searchPhrase\"\r" +
    "\n" +
    "        ng-change=\"vm.table.searchPhraseChanged()\"\r" +
    "\n" +
    "        aa-label-strategy=\"placeholderOnly\" \r" +
    "\n" +
    "        aa-label=\"Search Users\"\r" +
    "\n" +
    "        uib-tooltip=\"Search by name or email\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <record-counter\r" +
    "\n" +
    "        record-count=\"vm.table.pager.totalRecordCount\"\r" +
    "\n" +
    "        class=\"v-center small\">\r" +
    "\n" +
    "    </record-counter>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <user-list-filter-dropdown\r" +
    "\n" +
    "        class=\"v-center left-margin\"\r" +
    "\n" +
    "        table=\"vm.table\">\r" +
    "\n" +
    "    </user-list-filter-dropdown>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"v-center dropdown actions\"\r" +
    "\n" +
    "        uib-dropdown>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <a \r" +
    "\n" +
    "            class=\"left-padding\"\r" +
    "\n" +
    "            uib-dropdown-toggle>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"fa fa-ellipsis-v\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"small\"\r" +
    "\n" +
    "                    id=\"addNewUserButton\" \r" +
    "\n" +
    "                    ui-sref=\"main.usersDetail\" >\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                    New User\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "            <li>\r" +
    "\n" +
    "                <button class=\"small\"\r" +
    "\n" +
    "                    aa-submit-form=\"vm.exportUsersExcelReport()\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                    <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "                    Download Excel\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </li>\r" +
    "\n" +
    "        </ul>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table \r" +
    "\n" +
    "    class=\"group-by-tbody bottom-margin\" \r" +
    "\n" +
    "    ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <thead>\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <th>Status</th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Name\"\r" +
    "\n" +
    "                    sort-expression=\"Users.FirstName,Users.LastName\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "            <th>\r" +
    "\n" +
    "                <table-sort-button\r" +
    "\n" +
    "                    text=\"Email\"\r" +
    "\n" +
    "                    sort-expression=\"users.Email\"\r" +
    "\n" +
    "                    table-sorter=\"vm.table.sorter\">\r" +
    "\n" +
    "                </table-sort-button>\r" +
    "\n" +
    "            </th>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </thead>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <tbody ng-repeat=\"item in vm.table.data\">\r" +
    "\n" +
    "        <tr>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"item.isLockedOut\"\r" +
    "\n" +
    "                    class=\"red\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Locked Out\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"!item.isLockedOut\">\r" +
    "\n" +
    "                    Active\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                <a ui-sref=\"main.usersDetail({ id: item.id })\">\r" +
    "\n" +
    "                    {{item.firstName}} {{item.lastName}}\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "            <td>\r" +
    "\n" +
    "                {{item.email}}\r" +
    "\n" +
    "            </td>\r" +
    "\n" +
    "        </tr>\r" +
    "\n" +
    "    </tbody>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<empty-state \r" +
    "\n" +
    "    ng-show=\"vm.table.isEmptyStateVisible\" \r" +
    "\n" +
    "    message=\"No users were found\">\r" +
    "\n" +
    "</empty-state>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer table-pager=\"vm.table.pager\"></table-pagination-footer>\r" +
    "\n"
  );


  $templateCache.put('app/states/mainStructure/content/content.html',
    "<perfect-scrollbar \r" +
    "\n" +
    "    class=\"scroller\" \r" +
    "\n" +
    "    wheel-propagation=\"true\" \r" +
    "\n" +
    "    id=\"content-scroller\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"page-title\" \r" +
    "\n" +
    "        ng-show=\"vm.currentState.embeddedTitleBar !== true\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header>{{vm.currentState.title}}</header>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"page-body\" \r" +
    "\n" +
    "        ui-view>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</perfect-scrollbar>\r" +
    "\n"
  );


  $templateCache.put('app/states/mainStructure/footer/footer.html',
    "<div class=\"flex v-center left-padding\">\r" +
    "\n" +
    "    <system-account-logo\r" +
    "\n" +
    "        class=\"v-center right-margin\"\r" +
    "\n" +
    "        system-account-id=\"vm.currentUser.systemAccount.id\">\r" +
    "\n" +
    "    </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"v-center\">\r" +
    "\n" +
    "        <div class=\"system-account-name\">\r" +
    "\n" +
    "            {{vm.currentUser.systemAccount.companyName}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        <div class=\"x-small\">\r" +
    "\n" +
    "            {{vm.currentUser.systemAccount.accountType}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/mainStructure/header/header.html',
    "<div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-click=\"vm.toggleSidebar()\"\r" +
    "\n" +
    "        class=\"transparent toggle-collapse\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-chevron-left side-menu-collapsed-hidden\"></i>\r" +
    "\n" +
    "        <i class=\"fa fa-chevron-right side-menu-collapsed-show\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul class=\"navbar-left\">\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <button ui-sref=\"main.documentLibrary\">\r" +
    "\n" +
    "                <i class=\"fa fa-folder\"></i>\r" +
    "\n" +
    "                <span class=\"hidden-xs\">Document Library</span>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            uib-dropdown\r" +
    "\n" +
    "            ng-if=\"!vm.currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"\"\r" +
    "\n" +
    "                ui-sref=\"main.bondTypeSelection({ appType: 'singleBond', customerType: '' })\"\r" +
    "\n" +
    "                id=\"header-new-application-button\"\r" +
    "\n" +
    "                type=\"button\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-plus\"></i>\r" +
    "\n" +
    "                <span class=\"hidden-xs\">New Application</span>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <ul class=\"dropdown-menu dropdown-menu-right\">\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <a ui-sref=\"main.customerSelection({ appType: 'singleBond', customerType: '' })\">\r" +
    "\n" +
    "                    <i class=\"fa fa-file-o\"></i> Single Bond</a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <li>\r" +
    "\n" +
    "                    <a ui-sref=\"main.customerSelection({ appType: 'bondAccount', customerType: '' })\">\r" +
    "\n" +
    "                    <i class=\"fa fa-files-o\"></i> Bond Account</a>\r" +
    "\n" +
    "                </li>\r" +
    "\n" +
    "            </ul>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "        <li>\r" +
    "\n" +
    "            <h3\r" +
    "\n" +
    "                ng-show=\"vm.environment !== 'Prod'\"\r" +
    "\n" +
    "                class=\"red no-margin\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"left-margin-big\"></span>\r" +
    "\n" +
    "                {{ vm.environment }}\r" +
    "\n" +
    "            </h3>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul class=\"navbar-right\">\r" +
    "\n" +
    "        <browser-notification-dropdown></browser-notification-dropdown>\r" +
    "\n" +
    "        <logged-in-user-dropdown></logged-in-user-dropdown>        \r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <global-search></global-search>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/mainStructure/main/main.html',
    "<div\r" +
    "\n" +
    "    class=\"themed {{vm.currentUser.user.theme || 'a3'}}\"\r" +
    "\n" +
    "    ng-class=\"{\r" +
    "\n" +
    "        'nav-open': vm.navVisible, \r" +
    "\n" +
    "        'side-menu-collapsed' : vm.currentUser.user.sideMenuIsCollapsed\r" +
    "\n" +
    "    }\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <toast></toast>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"structure-wrapper\" \r" +
    "\n" +
    "        ng-class=\"{'pad-main-view': vm.paddingTop}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ui-view=\"header\" \r" +
    "\n" +
    "            class=\"top-menu\" \r" +
    "\n" +
    "            role=\"banner\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ui-view=\"content\"\r" +
    "\n" +
    "            id=\"content\" \r" +
    "\n" +
    "            class=\"page\" \r" +
    "\n" +
    "            ng-class=\"{'embedded-title-bar': vm.currentState.embeddedTitleBar === true}\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ui-view=\"sidebar\" \r" +
    "\n" +
    "            id=\"sidebar\" \r" +
    "\n" +
    "            class=\"side-menu\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ui-view=\"footer\" \r" +
    "\n" +
    "            id=\"footer\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        aa-notify \r" +
    "\n" +
    "        class=\"validation-notification\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/mainStructure/sidebarNav/sidebar.html',
    "<div class=\"menu-content\">\r" +
    "\n" +
    "    <a\r" +
    "\n" +
    "        class=\"main-logo\"\r" +
    "\n" +
    "        ui-sref=\"main.dashboard\">\r" +
    "\n" +
    "        <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "            <img\r" +
    "\n" +
    "                src=\"/img/logo.png\"\r" +
    "\n" +
    "                alt=\"logo\"\r" +
    "\n" +
    "                ng-show=\"currentUser.user.theme == 'silver'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <img\r" +
    "\n" +
    "                src=\"/img/agency_logo_alt.png\"\r" +
    "\n" +
    "                alt=\"logo\"\r" +
    "\n" +
    "                ng-show=\"currentUser.user.theme != 'silver'\" />\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span class=\"side-menu-collapsed-show\">\r" +
    "\n" +
    "            <img\r" +
    "\n" +
    "                src=\"/img/icon_a3.png\"\r" +
    "\n" +
    "                alt=\"logo\"\r" +
    "\n" +
    "                ng-show=\"currentUser.user.theme == 'silver'\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <img\r" +
    "\n" +
    "                src=\"/img/icon_a3_alt.png\"\r" +
    "\n" +
    "                alt=\"logo\"\r" +
    "\n" +
    "                ng-show=\"currentUser.user.theme != 'silver'\" />\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        id=\"nav\"\r" +
    "\n" +
    "        main-navigation-class=\"current\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.dashboard' }\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"dashboardLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.dashboard\"\r" +
    "\n" +
    "                uib-tooltip=\"Dashboard\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-tachometer-alt\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\"> Dashboard </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{'current': currentState.name === 'main.carrierApplications'}\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ui-sref=\"main.carrierApplications\"\r" +
    "\n" +
    "                uib-tooltip=\"Open Applications\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-id-card\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "                    Open Applications\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{'current': currentState.name === 'main.customers'}\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"sidebar-customers-button\"\r" +
    "\n" +
    "                ui-sref=\"main.customers\"\r" +
    "\n" +
    "                uib-tooltip=\"Customers\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-id-card\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\"> Customers </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.eProducerAccounts' || currentState.name === 'main.eProducerAccountDetail'}\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isAgency || currentUser.systemAccount.isMGA\"\r" +
    "\n" +
    "            uib-tooltip=\"Broker Accounts\"\r" +
    "\n" +
    "            tooltip-placement=\"right\"\r" +
    "\n" +
    "            tooltip-append-to-body=\"true\">\r" +
    "\n" +
    "            <a ui-sref=\"main.eProducerAccounts\">\r" +
    "\n" +
    "                <i class=\"fa fa-tv\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "                    Brokers\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.purchasedBondsReport' || currentState.name === 'main.transactionsReport' || currentState.name === 'main.paymentReport' || currentState.name === 'main.leadReport' || currentState.name === 'main.monthEndReport' || currentState.name === 'main.declinedApplicationsReport' || currentState.name === 'main.adverseActionsReport' || currentState.name === 'main.ridersReport' || curentState.name === 'main.epicIntegrationLogs' || curentState.name === 'main.epicBillingReport' }\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-click=\"vm.setSubMenu('reports')\"\r" +
    "\n" +
    "                uib-tooltip=\"Reports\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-list-alt\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\"> Reports </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-include=\"'reportsSubMenu.html'\"\r" +
    "\n" +
    "                class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{'current': currentState.name === 'main.agencyCarriers'}\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"sidebar-carriers-button\"\r" +
    "\n" +
    "                ui-sref=\"main.agencyCarriers\"\r" +
    "\n" +
    "                uib-tooltip=\"Carriers\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-building\"></i> \r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "                    Carriers\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li \r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isCarrier\"\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.carrierProfile' || currentState.name === 'main.groupsGrid' || currentState.name === 'main.usersGrid' || currentState.name === 'main.a3BillingStatements' || currentState.name === 'main.agencyAppointments' || currentState.name === 'main.underwritingConfigurationGrid'|| currentState.name === 'main.ratesGrid' || currentState.name === 'main.bondNumbersGrid' || currentState.name === 'main.requiredFieldSetGrid' || currentState.name === 'main.agencyCarriers'}\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-click=\"vm.setSubMenu('settings')\"\r" +
    "\n" +
    "                uib-tooltip=\"Settings\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-cogs\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span\r" +
    "\n" +
    "                    class=\"side-menu-collapsed-hidden\"\r" +
    "\n" +
    "                    ng-show=\"currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "                    Settings\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-include=\"'carrierSettingsSubMenu.html'\"\r" +
    "\n" +
    "                class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.obligeesGrid' || currentState.name === 'main.bondTypes' || currentState.name === 'main.applicationQuestionsGrid' }\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                ng-click=\"vm.setSubMenu('sharedConfigurations')\"\r" +
    "\n" +
    "                uib-tooltip=\"Shared Configurations\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "                <i class=\"fa fa-share-alt\"></i>\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "                    Shared Configurations\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-include=\"'sharedConfigurationsSubMenu.html'\"\r" +
    "\n" +
    "                class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            class=\"top-margin-auto\" \r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier && currentUser.permissions.canEditSystemAccounts\"\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.agencySettings'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"agencySettingsLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.agencySettings\"\r" +
    "\n" +
    "                uib-tooltip=\"Settings\"\r" +
    "\n" +
    "                tooltip-placement=\"right\"\r" +
    "\n" +
    "                tooltip-append-to-body=\"true\"\r" +
    "\n" +
    "                tooltip-enable=\"currentUser.user.sideMenuIsCollapsed\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-cogs\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"side-menu-collapsed-hidden\">\r" +
    "\n" +
    "                    Settings\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<page-slide\r" +
    "\n" +
    "    ps-open=\"vm.showSubMenu\"\r" +
    "\n" +
    "    ps-zindex=\"2000\"\r" +
    "\n" +
    "    ps-speed=\".1\"\r" +
    "\n" +
    "    ps-size=\"275px\"\r" +
    "\n" +
    "    ps-click-outside=\"true\"\r" +
    "\n" +
    "    ps-class=\"sub-menu-slide\"\r" +
    "\n" +
    "    ps-container=\"content\"\r" +
    "\n" +
    "    ps-side=\"left\"\r" +
    "\n" +
    "    ps-body-class=\"true\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.subMenu === 'settings' && currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <h4>\r" +
    "\n" +
    "                <i class=\"fa fa-cogs\"></i>\r" +
    "\n" +
    "                Settings\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-include=\"'carrierSettingsSubMenu.html'\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.subMenu === 'reports'\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <h4>\r" +
    "\n" +
    "                <i class=\"fa fa-list-alt\"></i>\r" +
    "\n" +
    "                Reports\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-include=\"'reportsSubMenu.html'\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.subMenu === 'sharedConfigurations'\">\r" +
    "\n" +
    "        <header>\r" +
    "\n" +
    "            <h4>\r" +
    "\n" +
    "                <i class=\"fa fa-share-alt\"></i>\r" +
    "\n" +
    "                Shared Configurations\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-include=\"'sharedConfigurationsSubMenu.html'\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</page-slide>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<script\r" +
    "\n" +
    "    type=\"text/ng-template\"\r" +
    "\n" +
    "    id=\"reportsSubMenu.html\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul class=\"sub-menu\">\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.applicationReport' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isAgency\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.applicationReport\">Applications</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.billingEntryReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.billingEntryReport\">Billing Entries</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.purchasedBondLinesReport' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isAgency\">\r" +
    "\n" +
    "            <a ui-sref=\"main.purchasedBondLinesReport\">Bond Lines</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.purchasedBondsReport' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.purchasedBondsReport\">Bonds</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.transactionsReport' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.transactionsReport\">Transactions</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.ridersReport' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.ridersReport\">Riders</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.paymentReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.paymentReport\">Payments</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.invoiceReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.invoiceReport\">Invoices</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.leadReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.leadReport\">Leads</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.monthEndReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.monthEndReport\">Month End Accounting</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.declinedApplicationsReport' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.declinedApplicationsReport\">Declined Applications</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.adverseActionsReport' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.adverseActionsReport\">Adverse Actions</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.epicIntegrationLogs' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier && vm.isEpicIntegrationEnabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.epicIntegrationLogs\">Epic Integration</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.epicBillingReport' }\"\r" +
    "\n" +
    "            ng-show=\"!currentUser.systemAccount.isCarrier && vm.isEpicIntegrationEnabled\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.epicBillingReport\">Epic Billing Report</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</script>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<script\r" +
    "\n" +
    "    type=\"text/ng-template\"\r" +
    "\n" +
    "    id=\"carrierSettingsSubMenu.html\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        class=\"sub-menu\"\r" +
    "\n" +
    "        ng-show=\"currentUser.systemAccount.isCarrier\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.carrierProfile' }\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"carrierProfileLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.carrierProfile\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Account Settings\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.agencyAppointments' }\">\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"appointedAgenciesLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.agencyAppointments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Appointed Agencies\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.underwritingConfigurationGrid' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.underwritingConfigurationGrid\">Underwriting</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.ratesGrid' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.ratesGrid\">Rates</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.bondNumbersGrid' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.bondNumbersGrid\">Bond Numbers</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.requiredFieldSetGrid' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.requiredFieldSetGrid\">Required Field Sets</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.groupsGrid' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditUserGroups\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"userGroupsLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.groupsGrid\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                User Groups\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.usersGrid' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditUsers\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                id=\"usersLinkButton\"\r" +
    "\n" +
    "                ui-sref=\"main.usersGrid\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Users\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.a3BillingStatements' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.a3BillingStatements\">A3 Statements</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-class=\"{ 'current': currentState.name === 'main.carrierApiConfiguration' }\"\r" +
    "\n" +
    "            ng-show=\"currentUser.permissions.canEditApiConfiguration\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a ui-sref=\"main.carrierApiConfiguration\">Api Configuration</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</script>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "<script\r" +
    "\n" +
    "    type=\"text/ng-template\"\r" +
    "\n" +
    "    id=\"sharedConfigurationsSubMenu.html\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ul class=\"sub-menu\">\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.obligeesGrid' || currentState.name === 'main.obligees.detail' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.obligeesGrid\">Obligees</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.bondTypes' || currentState.name === 'main.bondTypesDetail' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.bondTypes\">Bond Types</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <li ng-class=\"{ 'current': currentState.name === 'main.applicationQuestionsGrid' || currentState.name === 'main.applicationQuestionsDetail' }\">\r" +
    "\n" +
    "            <a ui-sref=\"main.applicationQuestionsGrid\">Application Questions</a>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "</script>\r" +
    "\n"
  );

}]);
