import { IHttpService, IQService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { CheckStrategyAssignmentConflictsDto } from "./types/checkStrategyAssignmentConflictsDto";
import { CheckStrategyAssignmentConflictsResult } from "./types/checkStrategyAssignmentConflictsResult";
import { ApplicationFollowUpStrategiesBondTypes } from "./types/model/applicationFollowUpStrategiesBondTypes";
import { ApplicationFollowUpStrategiesSfaaCodes } from "./types/model/applicationFollowUpStrategiesSfaaCodes";
import { ApplicationFollowUpStrategy } from "./types/model/applicationFollowUpStrategy";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { TableQueryOptions } from "./types/tableQuery";
import { ApplicationFollowUpListItem } from "../components/followUpStrategyList/followUpStrategyList";
import { PageResponse } from "./types/pageResponse";
import { IQueryStringBuilder } from "../utilities/queryStringBuilder/iQueryStringBuilder";

export class ApplicationFollowUpStrategyService {

    public static $inject = [
        Injectables.$http, 
        Injectables.ODataFactory, 
        Injectables.SystemSettings,
        Injectables.$q,
        Injectables.QueryStringBuilder
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings,
        private $q: IQService,
        private readonly queryStringBuilder: IQueryStringBuilder) {
    }

    public checkAssignmentConflicts(strategyId: number, applicationFollowUpStrategiesBondTypes: ApplicationFollowUpStrategiesBondTypes[], applicationFollowUpStrategiesSfaaCodes: ApplicationFollowUpStrategiesSfaaCodes[]): IPromise<void> {
        const request: CheckStrategyAssignmentConflictsDto = {
            applicationFollowUpStrategiesBondTypes: applicationFollowUpStrategiesBondTypes,
            applicationFollowUpStrategiesSfaaCodes: applicationFollowUpStrategiesSfaaCodes,
            strategyId: strategyId,
        };

        return this.$http
            .post<A3ApiResponse<CheckStrategyAssignmentConflictsResult>>(
                this.systemSettings.apiBaseUrl + 'ApplicationFollowUpActions/CheckStrategyAssignmentConflicts',
                request)
            .then((response) => {
                if (response.data.value.hasConflicts) {
                    const deferred = this.$q.defer();
                    deferred.reject();
                    return deferred.promise.then(() => { });
                }
                else {
                    return this.$q.when();
                }
            });
    }

    public getFollowUpStrategies(tableQueryOptions: TableQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as TableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        const queryString = this.queryStringBuilder.buildQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}ApplicationFollowUpActions/GetApplicationFollowUpStrategies${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<ApplicationFollowUpListItem>>>(url)
            .then(response => response.data.value);
    }

    public deleteById(id: number) {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationFollowUpActions/DeleteFollowUpStrategy`;

        return this.$http.post(url, id)
            .then(() => {});
    }

    public getById(id: number): IPromise<ApplicationFollowUpStrategy> {
        const svc = this.odata.getService<ApplicationFollowUpStrategy>(ODataEndpoint.ApplicationFollowUpStrategy);

        svc.query.filter(`id eq ${id}`);
        svc.query.expand('emails,applicationFollowUpStrategiesBondTypes($expand=bondType),applicationFollowUpStrategiesSfaaCodes($expand=sfaaCode)');

        return svc.get()
            .then((response) => response.data.value[0]);
    }

    public save(strategy: ApplicationFollowUpStrategy): IPromise<void> {
        const svc = this.odata.getService<ApplicationFollowUpStrategy>(ODataEndpoint.ApplicationFollowUpStrategy);

        return svc.save(strategy).then(() => {});
    }
}

app.service(Injectables.ApplicationFollowUpStrategyService, ApplicationFollowUpStrategyService);