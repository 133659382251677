import { AttachmentService } from "../../api/attachmentService";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { AttachmentModalData } from "./attachmentModalData";
import { AttachmentModalOptions } from "./attachmentModalOptions";
import { AttachmentModalResult } from "./attachmentModalResult";
import app from "../../main";
import { IQService } from "angular";
import { UploadControls, UploaderItem } from "../../components/uploader/uploader";

export class AttachmentModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.AttachmentService,
        Injectables.$q,
        Injectables.ToastMessageCreator

    ];

    constructor(
        private readonly $uibModalInstance: Modal<AttachmentModalResult>,
        private readonly options: AttachmentModalOptions,
        private readonly attachmentService: AttachmentService,
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator
    ){
        this.busyIndicator = {}
    }

    public errorMessage: string;
    public attachment: AttachmentModalData;
    public uploaderControls: UploadControls;
    public busyIndicator: BusyIndicator;
    
    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public filesAdded = (files: UploaderItem[]) => {
        this.attachment.filename = files[0].file.name;
        this.attachment.fileSize = files[0].file.size;
    }

    public save_click(): void{
        if (!this.attachment.id && !this.uploaderControls.getFiles().length) {
            this.errorMessage = 'No file is selected to upload';
            return;
        }

        if (this.uploaderControls.getFiles().length) {
            
            this.uploaderControls.uploadAll()
                .then(uploaderItem => {
                    this.attachment.fileId = uploaderItem[0].response[0];
                    this.save();
                });
        } else {
            this.save();
        }
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.attachmentService.save(this.attachment)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('The attachment was successfully uploaded');
                this.$uibModalInstance.close({} as AttachmentModalResult);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to save the attachment');
            });
    }

    public loadAttachment(attachmentId: number): void {
        this.busyIndicator.message = 'Loading Attachment...';
        this.busyIndicator.promise = this.attachmentService.getAttachmentModalData(attachmentId)
            .then((attachment) => {
                this.attachment = attachment;
            });
    }

    public getDefaultNewAttachment(): AttachmentModalData {
        const defaultAttachment: AttachmentModalData = {
            visibleToAgent: true,
            visibleInEProducer: false,
            applicationId: this.options.applicationId,
            customerId: this.options.customerId,
            eProducerAccountId: this.options.eProducerAccountId,
            bondId: this.options.bondId,
            fileId: null,
            comments: null,
            filename: null,
            fileSize: null
        };

        return defaultAttachment;
    }

    public $onInit(): void {
        
        if (this.options.attachmentId) {
            this.loadAttachment(this.options.attachmentId);
        } else {
            this.attachment = this.getDefaultNewAttachment();
        }

    }
}

app.controller('AttachmentModalController', AttachmentModalController);
