enum DataViewType {
    Customers = 'Customers',
    Brokers = 'Brokers',
    Bonds = 'Bonds',
    BondLines= 'BondLines',
    Applications = 'Applications',
    BillingEntriesReport = 'BillingEntriesReport',
    BillingEntriesList = 'BillingEntriesList'
}

export default DataViewType;