import { Injectables } from "../../configuration/injectables";
import { Modal } from "../../components/modals/modal";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { IQService } from "angular";
import { ApplicationFollowUpStrategyService } from "../../api/applicationFollowUpStrategyService";
import { BondTypeService } from "../../api/bondTypeService";
import { EmailTemplateService } from "../../api/emailTemplateService";
import { ApplicationFollowUpStrategiesBondTypes } from "../../api/types/model/applicationFollowUpStrategiesBondTypes";
import { ApplicationFollowUpStrategiesSfaaCodes } from "../../api/types/model/applicationFollowUpStrategiesSfaaCodes";
import { ApplicationFollowUpStrategy, ApplicationFollowUpStrategyType } from "../../api/types/model/applicationFollowUpStrategy";
import { ApplicationFollowUpStrategyEmail } from "../../api/types/model/applicationFollowUpStrategyEmail";
import { BondType } from "../../api/types/model/bondType";
import { EmailTemplate } from "../../api/types/model/emailTemplate";
import { SelectOption } from "../../api/types/selectOption";

export class FollowUpStrategyDetailModal {
    
    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options,
        Injectables.ApplicationFollowUpStrategyService, 
        Injectables.EmailTemplateService, 
        Injectables.BondTypeService, 
        Injectables.$q,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: {id?: number},
        private readonly applicationFollowUpStrategyService: ApplicationFollowUpStrategyService,
        private readonly emailTemplateService: EmailTemplateService, 
        private readonly bondTypeService: BondTypeService, 
        private readonly $q: IQService,
        private readonly toastMessageCreator: ToastMessageCreator)
    {
    }

    public followUpStrategyId: number;
    public busyIndicator: BusyIndicator;
    public strategy: ApplicationFollowUpStrategy;
    public hasConflicts: boolean;
    public strategyTypeOptions: SelectOption<ApplicationFollowUpStrategyType>[];
    public emailTemplates: EmailTemplate[];
    public bondTypeSearch: BondType[];

    public save = () => {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.applicationFollowUpStrategyService.checkAssignmentConflicts(this.strategy.id, this.strategy.applicationFollowUpStrategiesBondTypes, this.strategy.applicationFollowUpStrategiesSfaaCodes)
            .then(() => {
                    
                this.buildApplicationFollowUpStrategiesSfaaCodes();

                return this.applicationFollowUpStrategyService.save(this.strategy)
                    .then(() => {
                        this.$uibModalInstance.close();
                    }).catch(() => {
                        this.toastMessageCreator.createErrorMessage('An error occurred trying to save the follow-up strategy');
                    });
            })
            .catch((conflicts) => {
                this.markConflicts(conflicts);
                return this.$q.reject();
            });
    }

    public close = () => {
        this.$uibModalInstance.dismiss();
    }
    
    public checkForConflicts = () => {
        this.hasConflicts = false;

        for (var j = 0; j < this.strategy.applicationFollowUpStrategiesBondTypes.length; j++) {
            if (this.strategy.applicationFollowUpStrategiesBondTypes[j]['$assignedStrategy']) {
                this.hasConflicts = true;
                return;
            }
        }
    }

    public markConflicts = (conflicts) => {

        for (var k = 0; k < this.strategy.applicationFollowUpStrategiesBondTypes.length; k++) {
            delete this.strategy.applicationFollowUpStrategiesBondTypes[k]['$assignedStrategy'];
        }

        for (var i = 0; i < conflicts.applicationFollowUpStrategiesBondTypes.length; i++) {
            for (var j = 0; j < this.strategy.applicationFollowUpStrategiesBondTypes.length; j++) {
                if (conflicts.applicationFollowUpStrategiesBondTypes[i].bondTypeId === this.strategy.applicationFollowUpStrategiesBondTypes[j].bondTypeId)
                    this.strategy.applicationFollowUpStrategiesBondTypes[j]['$assignedStrategy'] = conflicts.applicationFollowUpStrategiesBondTypes[i].assignedStrategy;
            }
        }

        this.checkForConflicts();
    }

    public buildApplicationFollowUpStrategiesSfaaCodes = () => {
        if(!this.strategy['$sfaaCodes'] || this.strategy['$sfaaCodes'].length < 1) {
            return;
        }

        if(this.strategy.applicationFollowUpStrategiesSfaaCodes) {
            delete this.strategy.applicationFollowUpStrategiesSfaaCodes;
        }
        
        if(!this.strategy.applicationFollowUpStrategiesSfaaCodes) {
            this.strategy.applicationFollowUpStrategiesSfaaCodes = [];
        }

        for (var j = 0; j < this.strategy['$sfaaCodes'].length; j ++) {
            this.strategy.applicationFollowUpStrategiesSfaaCodes.push({ 
                    sfaaCodeId: this.strategy['$sfaaCodes'][j].id, 
                    applicationFollowUpStrategyId: this.strategy.id, 
                    sfaaCode:  this.strategy['$sfaaCodes'][j] 
                } as ApplicationFollowUpStrategiesSfaaCodes
            );
        }
    }

    public loadApplicationFollowUpStrategiesSfaaCodes = () => {
        
        if (!this.strategy.applicationFollowUpStrategiesSfaaCodes) {
            return;
        }

        if (this.strategy['$sfaaCodes']) {
            delete this.strategy['$sfaaCodes'];
        }

        if (!this.strategy['$sfaaCodes']) {
            this.strategy['$sfaaCodes'] = [];
        }

        for (var i = 0; i < this.strategy.applicationFollowUpStrategiesSfaaCodes.length; i++) {
            this.strategy['$sfaaCodes'].push(this.strategy.applicationFollowUpStrategiesSfaaCodes[i].sfaaCode);
        }
    }

    public addBondType = () => {
        
        for (var i = 0; i < this.strategy.applicationFollowUpStrategiesBondTypes.length; i++) {
            if (this.strategy.applicationFollowUpStrategiesBondTypes[i].bondTypeId === this.bondTypeSearch['selected'].id)
                return;
        }

        this.strategy.applicationFollowUpStrategiesBondTypes.push({ 
            bondTypeId: this.bondTypeSearch['selected'].id, 
            applicationFollowUpStrategyId: this.strategy.id, 
            bondType: this.bondTypeSearch['selected'] 
        } as ApplicationFollowUpStrategiesBondTypes);

        delete this.bondTypeSearch['selected'];
    }

    public removeBondType = (index: number) => {
        this.strategy.applicationFollowUpStrategiesBondTypes.splice(index, 1);
        this.checkForConflicts();
    }

    public searchBondTypes = (search: string) => {
        return this.bondTypeService.dropdownSearch(search)
        .then((response) => {
            this.bondTypeSearch = response;
        });
    }

    public addEmail = () => {
        this.strategy.emails.push({} as ApplicationFollowUpStrategyEmail);
    }

    public removeEmail = (index: number) => {
        this.strategy.emails.splice(index, 1);
    }

    public getById = (id: number) => {
        return this.applicationFollowUpStrategyService.getById(id)
            .then((strategy) => {    
                this.strategy = strategy;
                this.loadApplicationFollowUpStrategiesSfaaCodes();
            });
    }

    public loadEmailTemplates = () => {
        return this.emailTemplateService.load('name,id')
            .then((templates) => {
                this.emailTemplates = templates;
            });
    }

    public $onInit = () => {
        this.followUpStrategyId = this.options.id;
        this.busyIndicator = {
            message: 'Loading...'
        };

        var promises = [];

        this.strategyTypeOptions = [
            { value: ApplicationFollowUpStrategyType.Prospect, label: 'Prospects' }, 
            { value: ApplicationFollowUpStrategyType.Application, label: 'Applications' }
        ];

        if (this.followUpStrategyId) {
            promises.push(this.getById(this.followUpStrategyId));
        } else {
            this.strategy = { 
                emails: [], 
                applicationFollowUpStrategiesBondTypes: [], 
                applicationFollowUpStrategiesSfaaCodes: [], 
                strategyType: ApplicationFollowUpStrategyType.Application 
            } as ApplicationFollowUpStrategy;
        }

        promises.push(this.loadEmailTemplates());

        this.busyIndicator.promise = this.$q.all(promises);
    }
}
