import { AttachmentService } from "../../api/attachmentService";
import { A3RootScope } from "../../api/types/a3RootScope";
import { Attachment } from "../../api/types/attachment";
import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { Table } from "../../utilities/tables/table";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { AttachmentTableListItem } from "./attachmentTableListItem";
import { AttachmentTableQueryOptions } from "./attachmentTableQueryOptions";
import app from "../../main";
import { IPromise } from "angular";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";

class AttachmentListController {

    public static $inject = [
        Injectables.ModalOpener, 
        Injectables.AttachmentService, 
        Injectables.CurrentUserResolver,
        Injectables.ToastMessageCreator
    ];

    public table: Table<AttachmentTableListItem, AttachmentTableQueryOptions>;
    public onAttachmentModified: () => void;
    public applicationId?: number;
    public customerId?: number;
    public eproducerAccountId?: number;
    public bondId?: number;
    public attachments?: Attachment[];
    public activityId?: number;

    public processingPromise: IPromise<void>;
    public isCarrier: boolean;

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly attachmentService: AttachmentService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly toastMessageCreator: ToastMessageCreator) {
    }

    public editAttachment(attachment: AttachmentTableListItem, index: number): void {
        if (this.isCarrier) {
            this.modalOpener.showAttachmentModal(
                    attachment.id,
                    this.applicationId,
                    this.customerId,
                    this.eproducerAccountId,
                    this.bondId
                )
                .result
                .then(() => {
                    this.onAttachmentModified();
                })
                .catch(() => {});
        } else {
            this.modalOpener.showActivityModal(
                    attachment.activityId, 
                    this.customerId, 
                    this.bondId, 
                    null, 
                    this.eproducerAccountId
                )
                .result
                .then(() => {
                    if (typeof this.onAttachmentModified === 'function') {
                        this.onAttachmentModified();
                    }
                })
                .catch(() => {});
        }
        
    }

    public createdByAnotherSystemAccount(attachment: AttachmentTableListItem): boolean {
        return attachment.systemAccountId !== this.currentUserResolver.getCurrentUser().systemAccount.id;
    }

    public deleteAttachment(attachment: Attachment, index: number): void {
        this.modalOpener.confirmModal('Delete Attachment', 'Are you sure you want to delete this attachment? <br><b>' + attachment.filename + '</b>', 'Delete', 'Cancel')
            .result
            .then(() => {
                this.confirmDelete(attachment, index);
            })
            .catch(() => {});
    }

    public confirmDelete(attachment: Attachment, index: number): void {
        this.processingPromise = this.attachmentService.deleteAttachment(attachment.id)
            .then(() => {
                if (typeof this.onAttachmentModified === 'function') {
                    this.onAttachmentModified();
                }
                
                this.toastMessageCreator.createSuccessMessage('The attachment was deleted successfully');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to delete the attachment');
            });
    }
    
    public getAttachmentUrl(attachmentId: number): string {
        return this.attachmentService.getAttachmentDownloadUrl(attachmentId);
    }

    public $onInit(): void {
        this.isCarrier = this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }
}

const attachmentList = {
    bindings: { 
        table: '<', 
        applicationId: '<?', 
        customerId: '<?', 
        bondId: '<?', 
        activityId: '<?', 
        eproducerAccountId: '<?',
        onAttachmentModified: '&'
    },
    controller: AttachmentListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/attachmentList/attachmentList.html'
};

app.component('attachmentList', attachmentList);
