import { ActivityService } from "../../../api/activityService";
import { ApplicationService } from "../../../api/applicationService";
import { AttachmentService } from "../../../api/attachmentService";
import { BondService } from "../../../api/bondService";
import { CustomersService } from "../../../api/customerService";
import { EProducerService } from "../../../api/eProducerService";
import { InvoiceService } from "../../../api/invoiceService";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BondAccountListItem } from "../../../api/types/bondAccounts/bondAccountListItem";
import { BondAccountService } from "../../../api/bondAccountService";
import { CustomerDetail } from "../../../api/types/customerDetail";
import { BondStatus } from "../../../api/types/model/bond";
import { Company } from "../../../api/types/model/company";
import { CustomerType } from "../../../api/types/model/customer";
import { InvoiceStatus } from "../../../api/types/model/invoiceStatus";
import { Person } from "../../../api/types/model/person";
import { ActivityListItem } from "../../../components/activityList/activityListItem";
import { ActivityTableQueryOptions } from "../../../components/activityList/activityTableQueryOptions";
import { AttachmentTableListItem } from "../../../components/attachmentList/attachmentTableListItem";
import { AttachmentTableQueryOptions } from "../../../components/attachmentList/attachmentTableQueryOptions";
import { BondListTableQueryOptions } from "../../../components/bondList/bondListTableQueryOptions";
import { BondListItem } from "../../../components/bondListFilterDropdown/bondListItem";
import { InvoiceListItem } from "../../../components/invoiceList/invoiceListItem";
import { InvoiceTableQueryOptions } from "../../../components/invoiceList/invoiceTableQueryOptions";
import { Injectables } from "../../../configuration/injectables";
import { IssueBondModalResult } from "../../../modals/issueBondModal/IssueBondModalResult";
import { ModalOpener } from "../../../modals/modalOpener";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { ITimeoutService, IQService, IPromise } from "angular";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import PaymentTableQueryOptions from "../../../api/types/payments/paymentTableQueryOptions";
import { PaymentListItem } from "../../../components/paymentList/paymentListItem";
import { PaymentStatus } from "../../../api/types/payments/paymentStatus";
import { PaymentService } from "../../../api/paymentService";
import { BillingEntryService } from "../../../api/billingEntryService";
import { NewInvoiceRequest } from "../../../api/types/invoices/newInvoiceRequest";
import ApplicationListItem from "../../../components/applicationList/applicationListItem";
import ApplicationListTableQueryOptions from "../../../components/applicationList/applicationListQueryOptions";
import BillingEntriesListTable from "../../../components/billingEntriesList/billingEntriesListTable";
import { UserService } from "../../../api/userService";

export class CustomerDetailController {
    public static $inject = [
        Injectables.EProducerService,
        Injectables.CustomersService,
        Injectables.$stateParams,
        Injectables.ApplicationService,
        Injectables.$timeout,
        Injectables.ModalOpener,
        Injectables.SystemAccountService,
        Injectables.$q,
        Injectables.ActivityService,
        Injectables.BondAccountService,
        Injectables.BondService,
        Injectables.IDebouceDelayer,
        Injectables.InvoiceService,
        Injectables.AttachmentService,
        Injectables.ToastMessageCreator,
        Injectables.PaymentService,
        Injectables.BillingEntries,
        Injectables.UserService
    ];

    constructor(
        private readonly eProducerService: EProducerService,
        private readonly customerService: CustomersService,
        private readonly $stateParams: CustomerDetailStateParams,
        private readonly applicationService: ApplicationService,
        private readonly $timeout: ITimeoutService,
        private readonly modalOpener: ModalOpener,
        private readonly systemAccountService: SystemAccountService,
        private readonly $q: IQService,
        private readonly activityService: ActivityService,
        private readonly bondAccountService: BondAccountService,
        private readonly bondService: BondService,
        debouceDelayer: IDebounceDelayer,
        private readonly invoiceService: InvoiceService,
        private readonly attachmentService: AttachmentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly paymentService: PaymentService,
        private readonly billingEntriesService: BillingEntryService,
        private readonly userService: UserService
    ) {
        this.applicationsTable = new Table(debouceDelayer);
        this.applicationsTable.queryOptions.status = "Open";
        this.applicationsTable.onChange = this.loadApplications;

        this.bondsTable = new Table(debouceDelayer);
        this.bondsTable.queryOptions.status = BondStatus.Active;
        this.bondsTable.onChange = this.loadBonds;

        this.activitiesTable = new Table(debouceDelayer);
        this.activitiesTable.onChange = this.loadActivities;

        this.invoiceTable = new Table(debouceDelayer);
        this.invoiceTable.queryOptions.status = InvoiceStatus.Open;
        this.invoiceTable.onChange = this.loadInvoices;

        this.attachmentsTable = new Table(debouceDelayer);
        this.attachmentsTable.onChange = this.loadAttachments;

        this.paymentsTable = new Table(debouceDelayer);
        this.paymentsTable.queryOptions.paymentStatus = PaymentStatus.Approved;
        this.paymentsTable.onChange = this.loadPayments;

        this.billingEntriesTable = new BillingEntriesListTable(
            this.loadBillingEntries,
            userService,
            toastMessageCreator,
            debouceDelayer,
            modalOpener
        );
    }

    public readonly applicationsTable: Table<ApplicationListItem, ApplicationListTableQueryOptions>;
    public readonly bondsTable: Table<BondListItem, BondListTableQueryOptions>;
    public readonly activitiesTable: Table<ActivityListItem,ActivityTableQueryOptions>;
    public readonly invoiceTable: Table<InvoiceListItem,InvoiceTableQueryOptions>;
    public readonly attachmentsTable: Table<AttachmentTableListItem,AttachmentTableQueryOptions>;
    public readonly paymentsTable: Table<PaymentListItem, PaymentTableQueryOptions>;
    public readonly billingEntriesTable: BillingEntriesListTable;

    public customerId: number;
    public customer: CustomerDetail;
    public customerLoaded: boolean;
    public formSubmitted: boolean;
    public balanceCardBusyIndicator: BusyIndicator;
    public eProducerBusyIndicator: BusyIndicator;
    public activeTab: number;
    public showTabs: boolean;
    public busyIndicator: BusyIndicator;

    public summaryChanged: boolean;
    public epicIntegrationActive: boolean;
    public carrierLogoUrls: string[];
        
    public applicationsLoaded: boolean;
    public applicationBusyIndicator: BusyIndicator;
    
    public attachmentsBusyIndicator: BusyIndicator;
    public attachmentsLoaded: boolean;
    
    public showCancelledBondAccounts: boolean;
    public bondsBusyIndicator: BusyIndicator;
    public bondsLoaded: boolean;
    public bondAccountListItems: BondAccountListItem[];
    
    public invoicesBusyIndicator: BusyIndicator;
    public invoicesLoaded: boolean;
    public invoiceSearchPhrase: string;
    
    public billingEntriesLoaded: boolean;
    public billingEntriesBusyIndicator: BusyIndicator;
    
    public paymentsBusyIndicator: BusyIndicator;
    public paymentsLoaded: boolean;
    
    public activityBusyIndicator: BusyIndicator;
    public activitiesLoaded: boolean;
    

    public get showCustomerArchivedAlert(): boolean {
        return this.customer && !this.customer.isActive;
    }

    public get showEProducerAssignmentOptions(): boolean {
        return (
            this.customer &&
            !this.hasBrokerRelationship &&
            !this.hasEProducerUser
        );
    }

    public get showEpicConnectionButton(): boolean {
        return this.epicIntegrationActive;
    }

    public get hasEProducerUser(): boolean {
        return (
            this.customer &&
            this.customer.eProducerAccount &&
            this.customer.eProducerAccount.eProducerAccountType === "Applicant"
        );
    }

    public get hasBrokerRelationship(): boolean {
        return (
            this.customer &&
            this.customer.eProducerAccount &&
            this.customer.eProducerAccount.eProducerAccountType === "Broker"
        );
    }

    public get billingContact(): Person {
        if (!this.customer || !this.customer.people) {
            return;
        }

        return this.customer.people.filter(
            (p) => p.isBillingContact === true
        )[0];
    }

    public get primaryContact(): Person {
        if (!this.customer || !this.customer.people) {
            return;
        }

        return this.customer.people.filter(
            (p) => p.isPrimaryContact === true
        )[0];
    }

    public resetApplicantPassword(email) {
        this.eProducerBusyIndicator = {
            message: "Resetting Password...",
            promise: this.eProducerService
                .resetUserPassword(email)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage(
                        "Password reset email was sent successfully"
                    );
                })
                .catch((err) => {
                    this.toastMessageCreator.createErrorMessage(err.message);
                })
        };
    }

    public showEpicConnectionModal() {
        this.modalOpener
            .showEpicCustomerConnectionModal(this.customerId)
            .result.then(() => {
                this.busyIndicator = {
                    message: "Loading...",
                    promise: this.loadCustomer(this.customerId)
                };
            })
            .catch(() => {});
    }

    public showEProducerUserAcccessModal() {
        this.modalOpener
            .showGrantCustomerEproducerAccessModal(this.customerId)
            .result.then(() => {
                this.busyIndicator = {
                    message: "Loading...",
                    promise: this.loadCustomer(this.customerId)
                };
            })
            .catch(() => {});
    }

    public removeBroker() {
        this.modalOpener
            .confirmModal(
                "Remove from Broker",
                "Are you sure you want to remove this customer record from it's broker?",
                "Remove Broker",
                "Cancel"
            )
            .result
            .then(() =>
                this.busyIndicator = {
                    message: "Removing Broker...",
                    promise: this.customerService.removeEProducerAccount(this.customerId)
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage("Broker has been removed");
                            this.customer.eProducerAccount = null;
                        })
                }
            )
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("Unable to remove broker access");
            });
    }

    public removeApplicantEProducerAccount() {
        this.modalOpener.confirmModal(
                "Remove User Access",
                "Are you sure you want to remove this customer's access to EProducer?",
                "Remove Access",
                "Cancel")
            .result
            .then(() =>
                this.eProducerBusyIndicator = {
                    message: "Processing...",
                    promise: this.customerService.removeEProducerAccount(this.customerId)
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage("EProducer access has been removed");
                            this.customer.eProducerAccount = null;
                        })
                }
            )
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("Unable to remove EProducer access");
            });
    }

    public showBrokerAssignmentModal() {
        this.modalOpener
            .showBrokerAssignmentModal(this.customerId)
            .result.then(() => {
                this.busyIndicator = {
                    message: "Loading...",
                    promise: this.loadCustomer(this.customerId)
                };
            })
            .catch(() => {});
    }

    /* ATTACHMENTS */
    public attachmentTabSelected(): void {
        if (this.attachmentsLoaded) {
            return;
        }

        this.loadAttachments();
    }

    private loadAttachments = () => {
        this.attachmentsBusyIndicator = {
            message: "Loading Attachments...",
            promise: this.attachmentService
                .getCustomerAttachments(
                    this.customerId,
                    this.attachmentsTable.queryOptions
                )
                .then((response) => {
                    this.attachmentsTable.setData(
                        response.items,
                        response.totalRecordCount
                    );
                    this.attachmentsLoaded = true;
                })
        };
    };
    /* ATTACHMENTS END */

    /* BONDS */
    public bondTabSelected() {
        if (this.bondsLoaded) {
            return;
        }

        this.loadBonds();
    }

    public get canAssignPrincipal(): boolean {
        if (!this.bondsTable.getSelectedRecords()) {
            return false;
        }

        for (let i = 0; i < this.bondsTable.getSelectedRecords().length; i++) {
            if (
                this.bondsTable.getSelectedRecords()[i].allowPrincipalAssignment
            ) {
                return true;
            }
        }

        return false;
    }

    public showBondAccountsModal() {
        this.modalOpener
            .showBondAccountModal(this.customerId)
            .result.then(() => {
                this.loadBonds();
            })
            .catch(() => {});
    }

    public loadBonds = () => {
        const promises = [
            
            this.bondAccountService
                .getBondAccountListItemsByCustomerId(this.customerId)
                .then((bondAccounts) => {
                    this.bondAccountListItems = bondAccounts;

                    for (let i = 0; i < this.bondAccountListItems.length; i++) {
                        const bondAccount = this.bondAccountListItems[i];

                        this.loadCarrierLogo(
                            bondAccount.carrierSystemAccountId
                        );
                    }
                }),

            this.bondService
                .getBondsByCustomerId(
                    this.customerId,
                    this.bondsTable.queryOptions
                )
                .then((response) => {
                    this.bondsTable.setData(
                        response.items,
                        response.totalRecordCount
                    );
                    this.bondsLoaded = true;
                })
        ];

        this.bondsBusyIndicator = {
            message: 'Loading Bonds...',
            promise: this.$q.all(promises) as IPromise<any>
        };
    }

    public showAssignToAccountModal() {
        if (!this.bondsTable.getSelectedRecords().length) {
            return;
        }

        const bondIds = this.bondsTable
            .getSelectedRecords()
            .map((bond) => bond.id);

        this.modalOpener
            .showBondAccountAssignmentModal(bondIds)
            .result.then(() => {
                this.loadBonds();
                this.loadCustomerBalances();
            })
            .catch(() => {});
    }

    public bondAccountUpdated() {
        this.loadBonds();
    }

    public showAssignPrincipalModal() {
        const bonds = this.bondsTable.getSelectedRecords();

        if (!bonds.length) {
            return;
        }

        const customerId = bonds[0].customerId;
        const bondIds = bonds.map((bond) => bond.id);

        this.modalOpener
            .showAssignPrincipalModal(customerId, bondIds)
            .result.then(() => {
                this.loadBonds();
            })
            .catch(() => {});
    }

    public showBulkDownloadOptionsModal() {
        if (!this.bondsTable.getSelectedRecords().length) {
            return;
        }

        const bondIds = this.bondsTable
            .getSelectedRecords()
            .map((bond) => bond.id);

        this.modalOpener
            .showPacketBulkDownloadModal(bondIds)
            .result.then(function () {})
            .catch(function () {});
    }

    public searchBondsByBondLine(bondAccount: BondAccountListItem) {
        const searchPhrase = bondAccount.carrierAccountNumber || bondAccount.id.toString();
        
        this.bondsTable.queryOptions.searchPhrase = searchPhrase;
        this.loadBonds()
    }

    public editBondAccount(bondAccount: BondAccountListItem) {
        this.modalOpener
            .showBondAccountModal(this.customerId, bondAccount.id)
            .result.then(() => {
                    this.loadBonds()
            })
            .catch(() => {});
    }

    public showRenewalModal() {
        const selectedBondIds = this.bondsTable
            .getSelectedRecords()
            .map((bond) => bond.id);

        if (!selectedBondIds.length) {
            return;
        }

        this.modalOpener
            .showRenewalModal(selectedBondIds)
            .result.then(() => {
                this.loadBonds();
                this.loadCustomerBalances();
            })
            .catch(() => {});
    }

    public exportBonds() {
        this.bondsBusyIndicator = {
            message: "Loading...",
            promise: this.bondService.getBondListReportByCustomerId(
                this.customerId,
                this.bondsTable.queryOptions
            )
        };
    }
    /* BOND - END */

    private loadCarrierLogo(carrierSystemAccountId: number) {
        this.systemAccountService
            .getSmallLogo(carrierSystemAccountId)
            .then((dataUrl: string) => {
                this.carrierLogoUrls[carrierSystemAccountId] = dataUrl;
            });
    }

    /* Applications */
    public applicationTabSelected() {
        if (this.applicationsLoaded) {
            return;
        }

        this.loadApplications();
    }

    public loadApplications = () => {
        this.applicationsTable.queryOptions.customerId = this.customerId;

        this.applicationBusyIndicator = {
            message: "Loading Applications...",
            promise: this.applicationService
                .getApplicationList(this.applicationsTable.queryOptions)
                .then((response) => {
                    this.applicationsTable.setData(
                        response.items,
                        response.totalRecordCount
                    );
                    this.applicationsLoaded = true;
                })
        };
    }

    /* Invoices */
    public mergeInvoices() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords();
        if (!selectedInvoices || !selectedInvoices.length) {
            return;
        }

        if (selectedInvoices.length === 1) {
            this.toastMessageCreator.createErrorMessage("You must select more than one invoice to merge");
            return;
        }

        if (selectedInvoices.filter((invoice) => invoice.status == InvoiceStatus.Voided).length) {
            this.toastMessageCreator.createErrorMessage("You cannot merge voided invoices");
            return;
        }

        const message =
            `Merging invoices is permanent. The original invoices will be voided. Are you sure you want to merge ${selectedInvoices.length} invoice${(selectedInvoices.length > 1 ? "s" : "")} ?`;

        this.modalOpener
            .confirmModal("Merge Invoice", message, "Merge", "Cancel")
            .result.then(() => {
                this.invoicesBusyIndicator = {
                    message: "Merging Invoices...",
                    promise: this.invoiceService.mergeInvoices(selectedInvoices.map((invoice) => invoice.id))
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage("The selected invoices have been merged");

                            if (this.paymentsLoaded) {
                                this.loadPayments();
                            }

                            if (this.billingEntriesLoaded) {
                                this.billingEntriesTable.load();
                            }

                            this.loadInvoices();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage("An error occurred while trying to merge these invoices");
                        })
                };
            })
            .catch(() => {});
    }

    public createNewInvoice() {
        const selectedBillingEntries = this.billingEntriesTable.getSelectedRecords();
        if (!selectedBillingEntries || !selectedBillingEntries.length) {
            return;
        }

        const newInvoice = {
            billingEntryIds: selectedBillingEntries.map((billingEntry) => billingEntry.id)
        } as NewInvoiceRequest;

        this.billingEntriesBusyIndicator.message = 'Creating Invoice...'
        this.billingEntriesBusyIndicator.promise = this.invoiceService.createInvoice(newInvoice)
            .then((invoiceId) => {
                this.toastMessageCreator.createSuccessMessage('Invoice was created successfully');
                this.billingEntriesTable.load();

                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }

                this.modalOpener.showInvoiceDetailModal(invoiceId);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to create this invoice');
            });
    }

    public invoicesTabSelected() {
        if (this.invoicesLoaded) {
            return;
        }

        this.loadInvoices();
    }

    public loadInvoices = () => {
        this.invoiceTable.queryOptions.customerId = this.customerId;

        this.invoicesBusyIndicator = {
            message: "Loading Invoices...",
            promise: this.invoiceService
                .getInvoiceListItems(this.invoiceTable.queryOptions)
                .then((response) => {
                    this.invoiceTable.setData(
                        response.items,
                        response.totalRecordCount
                    );
                    this.invoicesLoaded = true;
                })
        };
    };

    public showVoidInvoicePrompt() {
        const selectedInvoices = this.invoiceTable.getSelectedRecords();
        if (!selectedInvoices || !selectedInvoices.length) {
            return;
        }

        const message =
            "Voiding invoices is permanent. Any payments assoicated to the invoices you are voiding will be unassigned. Are you sure you want to void " +
            selectedInvoices.length +
            " invoice" +
            (selectedInvoices.length > 1 ? "s" : "") +
            "?";

        this.modalOpener
            .confirmModal("Void Invoice(s)", message, "Void", "Cancel")
            .result.then(() => {
                this.invoicesBusyIndicator = {
                    message: "Voiding Invoices...",
                    promise: this.invoiceService
                        .voidInvoice(
                            selectedInvoices.map((invoice) => invoice.id)
                        )
                        .then(() => {
                            this.toastMessageCreator.createSuccessMessage(
                                "The selected invoices have been voided"
                            );

                            if (this.invoicesLoaded) {
                                this.loadInvoices();
                            }

                            if (this.billingEntriesLoaded) {
                                this.billingEntriesTable.load();
                            }
                            
                            this.loadPayments();
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage(
                                "An error occurred while trying to void the invoice"
                            );
                        })
                };
            })
            .catch(() => {});
    }
    /* Invoices End */

    /* Billing Entries Start */
    public billingEntriesTabSelected() {
        if (this.billingEntriesLoaded) {
            return;
        }

        this.billingEntriesTable.load();
    }

    public loadBillingEntries = () => {

        return this.billingEntriesService.getBillingEntryListItems(this.billingEntriesTable.getQueryOptions())
            .then((response) => {
                this.billingEntriesTable.setData(
                    response.items,
                    response.totalRecordCount
                );

                this.billingEntriesLoaded = true;
            });
    }

    public exportBillingEntries = () => {
        this.billingEntriesBusyIndicator.message = 'Loading...';
        this.billingEntriesBusyIndicator.promise = this.billingEntriesService.getBillingEntriesListDownload(this.billingEntriesTable.getQueryOptions(), this.billingEntriesTable.getSelectedColumnNames())
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to download billing entries');
            });
    }
    /* Billing Entries End */

    public loadCustomerBalances = () => {
        this.balanceCardBusyIndicator = {
            message: "Loading...",
            promise: this.customerService
                .getCustomerBalances(this.customerId)
                .then((customerBalances) => {
                    this.customer.balance = customerBalances.balance;
                    this.customer.unappliedPaymentTotal = customerBalances.unappliedPaymentTotal;
                    this.customer.invoicedBalance = customerBalances.invoicedBalance;
                    this.customer.notInvoicedTotal = customerBalances.notInvoicedTotal;
                    this.customer.debitTotal = customerBalances.debitTotal;
                    this.customer.creditTotal = customerBalances.creditTotal;
                })
        };
    }

    public paymentsTabSelected() {
        if (this.paymentsLoaded) {
            return;
        }

        this.loadPayments();
    }

    private loadPayments = () => {
        this.paymentsTable.queryOptions.customerId = this.customerId;

        const promise = this.paymentService
            .getPaymentListItems(this.paymentsTable.queryOptions)
            .then((response) => {
                this.paymentsTable.setData(response.items,response.totalRecordCount);
                this.paymentsLoaded = true;
            });

        // make sure that loading mask AND customer loading mask doesn't show at same time
        if (this.customerLoaded) {
            this.paymentsBusyIndicator = {
                message: "Loading...",
                promise: promise
            };
        }
    }

    public creditAssigned() {
        if (this.invoicesLoaded) {
            this.loadInvoices();
        }

        if (this.paymentsLoaded) {
            this.loadPayments();
        }

        this.loadCustomerBalances();
    }

    public paymentReassigned() {
        this.loadPayments();
        
        if (this.invoicesLoaded) {
            this.loadInvoices();
        }

        if (this.billingEntriesLoaded) {
            this.billingEntriesTable.load();
        }

        this.loadCustomerBalances();
    }

    public addCompany() {
        this.customer.company = { customerId: this.customerId } as Company;
        this.customer.customerType = CustomerType.Company;
    }

    public saveCompany() {
        this.busyIndicator = {
            message: "Saving...",
            promise: this.customerService
                .saveCompany(this.customer.company)
                .then((id) => {
                    this.customer.company.id = id;
                    this.toastMessageCreator.createSuccessMessage(
                        "Company information saved successfully"
                    );
                })
                .catch((err) => {
                    if (typeof err.data == "string"){
                        this.toastMessageCreator.createErrorMessage('An error occurred trying to save the customer contact');
                    } else {
                        this.toastMessageCreator.createErrorMessage(err.data.message);
                    }
                })
        };
    }

    public deleteCompany() {
        if (!this.customer.company) {
            return;
        }

        this.modalOpener
            .confirmModal(
                "Delete Company",
                "Are you sure you want to delete the company information for this customer? This will convert the customer into an Individual custmoer type.",
                "Delete",
                "Cancel"
            )
            .result.then(() => {
                if (!this.customer.company.id) {
                    this.removeCompanyFromUi();
                    return;
                }

                this.busyIndicator = {
                    message: "Deleting Company...",
                    promise: this.customerService
                        .deleteCompany(this.customerId)
                        .then(() => {
                            this.removeCompanyFromUi();
                            this.toastMessageCreator.createSuccessMessage(
                                "Company information was successfully deleted"
                            );
                        })
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage(
                                "An error occurred trying to delete the company information"
                            );
                        })
                };
            })
            .catch(() => {});
    }

    public removeCompanyFromUi() {
        this.customer.customerType = CustomerType.Individual;
        this.customer.company = null;
    }

    public loadCustomer = (id: number) => {
        this.customerLoaded = false;

        return this.customerService.getCustomerForCustomerDetail(id).then(
            (customer) => {
                this.customer = customer;
                this.customerLoaded = true;
            },
            (err) => {
                this.toastMessageCreator.createErrorMessage(
                    "An error occurred trying to load the customer"
                );
            }
        );
    }

    public activateCustomer() {
        this.busyIndicator = {
            message: "Activating...",
            promise: this.customerService
                .activateCustomer(this.customerId)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage(
                        "Customer has been activated"
                    );
                    this.customer.isActive = true;
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage(
                        "An error occurred trying to activate the customer"
                    );
                })
        };
    }

    public archiveCustomer() {
        this.busyIndicator = {
            message: "Archiving...",
            promise: this.customerService
                .archiveCustomer(this.customerId)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage(
                        "Customer has been archived"
                    );
                    this.customer.isActive = false;
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage(
                        "An error occurred trying to archive the customer"
                    );
                })
        };
    }

    public setInitialTab(sectionView: string) {
        switch (sectionView) {
            case "applications":
                this.activeTab = 3;
                break;
            case "attachments":
                this.activeTab = 4;
                break;
            case "bonds":
                this.activeTab = 5;
                break;
            case "invoices":
                this.activeTab = 6;
                break;
            case "billingEntries":
                this.activeTab = 7;
                break;
            case "payments":
                this.activeTab = 8;
                break;
            default:
                this.activeTab = 0;
                break;
        }

        this.showTabs = true;
    }

    public newPayment() {
       
        this.modalOpener
            .newPaymentModal(this.customerId, null)
            .result.then(() => {
                if (this.invoicesLoaded) {
                    this.loadInvoices();
                }

                if (this.billingEntriesLoaded) {
                    this.billingEntriesTable.load();
                }

                this.loadPayments();
                this.loadCustomerBalances();
            })
            .catch(() => {});
    }

    public summaryFieldChanged(): void {
        this.summaryChanged = true;
    }

    public saveSummary(): void {
        
        const summary = {
            customerId: this.customerId,
            name: this.customer.name,
            producerUserId: this.customer.producerUserId,
            clientServiceManagerUserId: this.customer.clientServiceManagerUserId,
            clientServiceAgentUserId: this.customer.clientServiceAgentUserId,
            clientServiceExecutiveUserId: this.customer.clientServiceExecutiveUserId,
            comments: this.customer.comments,
            exportToEpicStatus: this.customer.exportToEpicStatus,
        };

        this.busyIndicator = {
            message: "Saving...",
            promise: this.customerService
                .saveCustomerDetailSummary(summary)
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage(
                        "Customer record saved successfully"
                    );
                    this.summaryChanged = false;
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage(
                        "An error occurred while trying to save the customer record"
                    );
                })
        };
    }

    private loadIntegrationSettings(): IPromise<void> {
        return this.systemAccountService
            .isEpicIntegrationEnabled()
            .then((epicIntegrationActive) => {
                this.epicIntegrationActive = epicIntegrationActive;
            });
    }

    public showIssueBondModal() {
        this.modalOpener
            .showIssueBondModal(this.customerId)
            .result.then((result: IssueBondModalResult) => {
                this.bondsLoaded = false;
                this.bondTabSelected();
                this.loadCustomerBalances();

                this.modalOpener.bondDetailModal(result.bondId);
            })
            .catch(() => {});
    }

    /* ACTIVITIES */
    public activitiesSelected(): void {
        if (this.activitiesLoaded) {
            return;
        }

        this.loadActivities();
    }

    private loadActivities = () => {
        this.activityBusyIndicator = {
            message: "Loading Activities...",
            promise: this.activityService
                .getCustomerActivities(
                    this.customerId,
                    this.activitiesTable.queryOptions
                )
                .then((response) => {
                    this.activitiesTable.setData(
                        response.items,
                        response.totalRecordCount
                    );
                    this.activitiesLoaded = true;
                })
        };
    };
    /* ACTIVITIES END */

    public reloadCustomer = () => {
        this.busyIndicator = {
            message: "Loading...",
            promise: this.loadCustomer(this.customerId)
        };
    };

    public viewCustomerRelationships = () => {
        this.modalOpener
            .showCustomerRelaionshipsModal(this.customerId)
            .result.then((result) => {
                if (result.relationshipsChanged) {
                    this.reloadCustomer();
                }
            })
            .catch(() => {});
    };

    public $onInit(): void {
        this.customerId = this.$stateParams.customerId;
        this.billingEntriesTable.customerId = this.customerId;

        this.carrierLogoUrls = [];

        this.showCancelledBondAccounts = false;

        this.billingEntriesBusyIndicator = {};
        this.busyIndicator = {
            message: "Loading...",
            promise: this.$q
                .all([
                    this.loadCustomer(this.$stateParams.customerId),
                    this.loadIntegrationSettings()
                ])
                .then(() => {
                    this.$timeout(() => {
                        this.setInitialTab(this.$stateParams.sectionView);

                        if (this.$stateParams.applicationId) {
                            this.modalOpener
                                .applicationDetailModal(this.$stateParams.applicationId)
                                .result
                                .then(() => {})
                                .catch(() => {});
                        }
                    });
                })
        };
    }
}
export interface CustomerDetailStateParams {
    customerId: number;
    applicationId: number;
    sectionView: string;
}
