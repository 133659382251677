import { BondTransactionService } from "../../api/bondTransactionService";
import { DocumentService } from "../../api/documentService";
import { SystemAccountService } from "../../api/systemAccountService";
import { BondTransaction } from "../../api/types/model/bondTransaction";
import { BondTransactionDocumentVersion } from "../../api/types/model/bondTransactionDocumentVersion";
import { BondType } from "../../api/types/model/bondType";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { TransactionTypeFilter } from "../../filters/transactionTypeFilter/transactionTypeFilterType";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { ModalOpener } from "../modalOpener";
import { Document } from "../../api/types/model/document";
import app from "../../main";
import { IDeferred, IFilterService, IPromise, IQService } from "angular";
import { DownloadDocumentsResult } from "./DownloadDocumentsResult";
import { DownloadDocumentsOptions } from "./DownloadDocumentsOptions";
import { NewBondTransactionDocument } from "./NewBondTransactionDocument";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import DocumentSearchResult from "../../api/types/documents/documentSearchResult";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { BondTransactionWithDocumentList } from "../../api/types/bondTransactionWithDocumentList";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { BondTransactionDocumentListDocument } from "../../api/types/bondTransactionDocumentListDocument";
import { UploadControls, UploaderItem } from "../../components/uploader/uploader";

export class DownloadTransactionDocumentsModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.BondTransactionService,
        Injectables.ModalOpener,
        Injectables.$filter,
        Injectables.CurrentUserResolver,
        Injectables.DocumentService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings,
        Injectables.IDebouceDelayer
    ];

    constructor(
        private readonly $uibModalInstance: Modal<DownloadDocumentsResult>,
        private readonly options: DownloadDocumentsOptions,
        private readonly bondTransactionService: BondTransactionService,
        private readonly modalOpener: ModalOpener,
        private readonly $filter: IFilterService,
        private readonly currentUserResolver: CurrentUserResolver,           
        private readonly documentService: DocumentService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings,
        private readonly debounceDelayer: IDebounceDelayer
    ) { }

    public newDocumentToUploadCategoryId: number;
    public uploader: UploadControls;
    public uploaderFiles: UploaderItem[];
    public busyIndicator: BusyIndicator;
    public transaction: BondTransactionWithDocumentList;
    public includeAifSignature: boolean;
    public includeNotaryAcknowledgement: boolean;
    public bondType: BondType;
    public addDocument: boolean;
    public transactionType: string;
    public aifOptions: SelectOption<number>[];
    public matchingDocuments: DocumentSearchResult[];
    public selectedNewDocument: Document;
    public documentCategories: SelectOption[];

    get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public get newDocumentToUpload(): UploaderItem {
        if (!this.uploaderFiles) {
            return null;
        }

        return this.uploaderFiles[0];
    }

    public newUploadDocumentsAdded = () => {
        this.newDocumentToUploadCategoryId = null;
        this.selectedNewDocument = null;
    }

    public addDocumentSaveClick() : void {
        if (this.uploader.getFiles().length) {
            this.uploader.setUrl(`${this.systemSettings.apiBaseUrl}documentLibrary/Upload?categoryId=${this.newDocumentToUploadCategoryId}`);
            this.uploader.uploadAll()
                .then(uploadedItems => {
                    this.addDocumentSave(uploadedItems[0].response);
                });
        } else if (this.selectExistingDocument) {
            this.addDocumentSave(this.selectedNewDocument.id);
        }
    }

    public addDocumentSave(documentId: number): void {
        this.documentService.addNewDocumentToTransactionPacket(this.transaction.id, documentId)
            .then(() => this.loadTransaction(this.options.transactionId))
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Document has beed added to this transaction packet');
                this.closeAddDocument();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occured trying to add this document to the transaction');
            });
    }

    public closeAddDocument(): void {
        this.addDocument = !this.addDocument;
    }

    public addDocumentClicked(): void {
        this.addDocument = !this.addDocument;
        this.selectedNewDocument = null;
        this.uploader?.clearAll();

        this.busyIndicator.promise = this.documentService.getCategoryOptions()
            .then(categories => this.documentCategories = categories);
    }

    public selectExistingDocument(): void {
        this.uploader.clearAll();
    }

    public endSortDocuments(args): void {
        // ng-sortable handles the sorting of this.transaction.bondTransactionDocumentVersions
    }

    public searchDocuments(keyword: string): void {
        if (!keyword || !this.addDocument) {
            return;
        }

        this.debounceDelayer.delay(() => {
            this.documentService.searchDocuments(keyword)
                .then((documents) => {
                    this.matchingDocuments = documents;
                });
        }, 400);
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public useMostRecentVersion(bondTransactionDocumentVersionId: number): void {
        this.busyIndicator.promise = this.modalOpener.confirmModal('Update Document', 'Are you sure you want to update this document with the most recent version in the document library? This cannot be undone.', 'Update', 'Cancel')
            .result
            .then(() => {
                return this.bondTransactionService.updateBondTransactionDocumentVersion(bondTransactionDocumentVersionId);
            })
            .then(() => {
                return this.loadTransaction(this.transaction.id);
            })
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('Document has been updated to the most recent version');
            })
            .catch(() => {});
    }

    public removeDocumentFromPacket(bondTransactionDocumentVersionId: number, index: number): void {
        this.busyIndicator.promise = this.modalOpener.confirmModal('Remove Document', 'Are you sure you want to permanently remove this document from this packet?', 'Remove', 'Cancel')
            .result
            .then(() => {
                return this.documentService.removeDocumentFromTransactionPacket(bondTransactionDocumentVersionId);
            })
            .then(() => {
                this.transaction.documents.splice(index, 1);
                this.toastMessageCreator.createSuccessMessage('Document has been removed from the packet');
            })
            .catch(() => {});
    }

    public download(): void {
        const sequenceIds: number[] = [];
        const documentVersionIds: number[] = [];

        for (const bondTransactionDocumentListDocument of this.transaction.documents) {
            if (bondTransactionDocumentListDocument.includeInDownload) {
                documentVersionIds.push(bondTransactionDocumentListDocument.documentVersionId);
            }

            sequenceIds.push(bondTransactionDocumentListDocument.id);
        }

        this.busyIndicator.promise = this.bondTransactionService.downloadDocuments(
            this.options.transactionId, 
            documentVersionIds, 
            this.includeAifSignature, 
            this.includeNotaryAcknowledgement, 
            this.transaction.attorneyInFactUserId, 
            sequenceIds)
                .then(() => {
                    this.$uibModalInstance.close(new DownloadDocumentsResult());
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to download the document set');
                });
    }

    public loadTransaction(transactionId: number): IPromise<void> {
        return this.bondTransactionService.getTransactionWithDocumentList(transactionId).then((transaction) => {
            this.transaction = transaction;
            this.includeAifSignature = this.transaction.allowsElectronicSignature;
            this.includeNotaryAcknowledgement = this.transaction.agencyNotaryAcknowledgementRequired;
            this.transactionType = this.$filter<TransactionTypeFilter>('transactionType')(this.transaction);
        });
    }

    public requestCorrection(document: BondTransactionDocumentListDocument): void {
        this.modalOpener.showDocumentCorrectionModal(document.documentId, document.documentName, this.transaction.bondId, document.id)
            .result    
            .then(() => {
                document.hasRequestedCorrection = true;
            })
            .catch(() => {});
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.loadTransaction(this.options.transactionId)
        }
    }
}

app.controller('DownloadTransactionDocumentsModalController', DownloadTransactionDocumentsModalController);
