import app from "../../main";
import deepCopy from "../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../utilities/tables/queryBuilderTable";
import TableColumn from "../dataViews/tableColumn";

class ColumnSelectionDropdownMenu {
    
    public static $inject = [];

    constructor() {}

    public search: string;
    public table: QueryBuilderTable<any>;
    public scrollDownIndicatorVisible: boolean;
    public filteredColumns: TableColumn<any>[];
    public isOpen: boolean;

    public columnOptionClicked = ($event, column: TableColumn<any>) => {
        $event.stopPropagation();

        column.isVisible = column.isVisible !== true;
        this.table.columnVisibilityChanged(column);
    }

    public toggled = (open) => {
        this.isOpen = open;
        this.search = "";
        this.searchChanged();
    }

    public onScroll = (scrollTop: number, scrollHeight: number) => {
        this.scrollDownIndicatorVisible = scrollTop < scrollHeight;
    }

    public searchChanged = () => {
        if (!this.search) {
            this.filteredColumns = deepCopy(this.table.columnDropdownOptions);
        } else {
            this.filteredColumns = this.table.columnDropdownOptions.filter(column => column.heading?.toLowerCase().includes(this.search.toLowerCase()) || column.label?.includes(this.search.toLowerCase()));
        }

        this.setScrollDownIndicatorVisible();
    }

    private setScrollDownIndicatorVisible = () => {
        this.scrollDownIndicatorVisible = this.filteredColumns.length > 19;
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        table: '<'
    },
    controller: ColumnSelectionDropdownMenu,
    controllerAs: 'vm',
    templateUrl: 'app/components/columnSelectionDropdownMenu/columnSelectionDropdownMenu.html'
};

app.component('columnSelectionDropdownMenu', component);