import { BondService } from "../../../api/bondService";
import { SearchType } from "../../../api/types/searchType";
import { ModalOpener } from "../../../modals/modalOpener";
import { CurrentUser } from "../../../api/types/authentication/currentUser";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { PurchasedBondItem } from "./purchasedBondItem";
import DataView from "../../../components/dataViews/dataView";
import DataViewType from "../../../components/dataViews/dataViewType";
import TableColumn from "../../../components/dataViews/tableColumn";
import QueryBuilderLogicType from "../../../components/queryBuilder/queryBuilderLogicType";
import QueryConditionPropertyOption from "../../../components/queryBuilder/queryConditionPropertyOption";
import QueryConditionPropertyType from "../../../components/queryBuilder/queryConditionPropertyType";
import deepCopy from "../../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { UserService } from "../../../api/userService";
import QueryConditionPropertyGroup from "../../../components/queryBuilder/queryConditionPropertyGroup";

export class ReportPurchasedBondsController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.BondService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator,
        Injectables.ModalOpener,
        Injectables.UserService
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly bondService: BondService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly modalOpener: ModalOpener,
        private readonly userService: UserService
    ) {
        const columnOptions: TableColumn<any>[] = [{
            name: 'BondNumber',
            heading: 'Bond #',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Bonds.BondNumber',
            index: 0
        },{
            name: 'Status',
            heading: 'Status',
            headingClass: '',
            cellClass: 'nowrap',
            isVisible: true,
            locked: true,
            index: 1
        },{
            name: 'PurchasedDate',
            heading: 'Created Date/Time',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Bonds.CreatedDateTime',
            index: 2
        },{
            name: 'LookupCode',
            heading: 'Customer Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Customers.LookupCode',         
            index: 3
        },{
            name: 'LegacyId',
            heading: 'LegacyId',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'Bonds.LegacyId',         
            index: 3
        },{
            name: 'NameOnBond',
            heading: 'Name on Bond',
            isVisible: false,
            sortExpression: 'Bonds.NameOnBond',
            index: 4
        },{
            name: 'EffectiveDate',
            heading: 'Effective',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Bonds.EffectiveDate',
            index: 5
        },{
            name: 'ExpirationDate',
            heading: 'Expiration',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Bonds.ExpirationDate',
            index: 5
        },{
            name: 'BondType',
            heading: 'Bond Type',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: false,
            sortExpression: 'BondTypes.Name',
            index: 6
        },{
            name: 'Carrier',
            heading: 'Carrier',
            headingClass: 'fit-content',
            cellClass: 'fit-content nowrap text-left',
            isVisible: false,
            sortExpression: 'CarrierSystemAccount.CompanyName',
            disabled: this.currentUserResolver.getCurrentUser().systemAccount.isCarrier,
            index: 7
        },{
            name: 'Agency',
            heading: 'Agency',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: false,
            sortExpression: 'Bonds.Agency',
            disabled: this.currentUserResolver.getCurrentUser().systemAccount.isAgency,
            index: 8
        },{
            name: 'WritingCompany',
            heading: 'Writing Company',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: false,
            sortExpression: 'WritingCompanies.Name',
            index: 9
        },{
            name: 'ClientServiceManager',
            heading: 'CSM',
            tooltip: 'Client Service Manager',
            headingClass: 'fit-content text-left',
            cellClass: 'nowrap',
            isVisible: false,
            sortExpression: 'ClientServiceManager.FullName',
            disabled: this.currentUserResolver.getCurrentUser().systemAccount.isCarrier,
            index: 10
        },{
            name: 'CarrierLogo',
            heading: '',
            label: 'Carrier Logo',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'Producer',
            heading: 'Producer',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Producer.FullName',
            index: 11,
        },{
            name: 'ClientServiceExecutive',
            heading: 'CSE',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'ClientServiceExecutive.FullName',
            index: 11,
        },{
            name: 'ClientServiceAssociate',
            heading: 'CSA',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'ClientServiceAssociate.FullName',
            index: 11,
        },{
            name: 'Customer',
            heading: 'Customer',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: false,
            locked: false,
            sortExpression: 'Customers.Name',
            index: 11,
        },{
            name: 'RenewalStatus',
            heading: 'Renewal Status',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'CancellationStatus',
            heading: 'Cancellation Status',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'ReinstatementStatus',
            heading: 'Reinstatement Status',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'CancellationDate',
            heading: 'Cancellation Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.CancellationDate',
            index: 11,
        },{
            name: 'ReinstatementDate',
            heading: 'Reinstatement Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.ReinstatementDate',
            index: 11,
        },{
            name: 'RiderStatus',
            heading: 'Rider Status',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.RiderStatus',
            index: 11,
        },{
            name: 'IsRenewable',
            heading: 'Is Renewable',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'BondTypes.IsRenewable',
            index: 11,
        },{
            name: 'BondAmount',
            heading: 'Bond Amount',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.BondAmount',
            index: 11,
        },{
            name: 'RequiresContinuationCertificate',
            heading: 'Requires Continuation Certificate',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'BondTypes.RequiresContinuationCertificate',
            index: 11,
        },{
            name: 'RequiresVerificationCertificate',
            heading: 'Requires Verification Certificate',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'BondTypes.RequiresVerificationCertificate',
            index: 11,
        },{
            name: 'SfaaCode',
            heading: 'SFAA Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'SfaaCodes.Code',
            index: 11,
        },{
            name: 'IsElectronic',
            heading: 'Is Electronic',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.IsElectronic',
            index: 11,
        },{
            name: 'OriginSystem',
            heading: 'Origin System',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Bonds.OriginSystem',
            index: 11,
        },{
            name: 'IsCancellable',
            heading: 'Is Cancellable',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'BondTypes.IsCancellable',
            index: 11,
        },{
            name: 'ObligeeName',
            heading: 'Obligee Name',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.Name',
            index: 11,
        },{
            name: 'ObligeeMailAddress',
            heading: 'Obligee Mail Address',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.MailAddress',
            index: 11,
        },{
            name: 'ObligeeMailCity',
            heading: 'Obligee Mail City',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.MailCity',
            index: 11,
        },{
            name: 'ObligeeMailState',
            heading: 'Obligee Mail State',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.MailState',
            index: 11,
        },{
            name: 'ObligeeMailZip',
            heading: 'Obligee Mail Zip',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.MailZip',
            index: 11,
        },{
            name: 'RequisitioningState',
            heading: 'Requisitioning State',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'Obligees.RequisitioningState',
            index: 11,
        },{
            name: 'Tags',
            heading: 'Tags',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'Broker',
            heading: 'Broker',
            headingClass: 'fit-content text-left',
            cellClass: 'fit-content text-left',
            isVisible: false,
            locked: false,
            sortExpression: 'EProducerAccounts.Name',
            index: 11,
        },{
            name: 'BrokerLookupCode',
            heading: 'Broker Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'EProducerAccounts.LookupCode',
            index: 11,
        },{
            name: 'BrokerAgencyCode',
            heading: 'Broker Agency Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'EProducerAccounts.AgencyCode',
            index: 11,
        },{
            name: 'BrokerSubAgencyCode',
            heading: 'Broker Sub-Agency Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'EProducerAccounts.AgencySubCode',
            index: 11,
        },{
            name: 'CancellationProvisionDays',
            heading: 'Cancellation Provision Days',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            sortExpression: 'BondTypes.CancellationProvisionDays',
            index: 11,
        },{
            name: 'CancellationNotificationReceipientType',
            heading: 'NOC Receipients',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'CancellationNotificationMethodTypes',
            heading: 'NOC Delivery Method',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            locked: false,
            index: 11,
        },{
            name: 'DetailButton',
            heading: '',
            label: 'Details Button',
            headingClass: 'fit-content',
            cellClass: 'text-right',
            isVisible: true,
            locked: true,
            sortExpression: '',
            index: 100
        }];

        const queryConditionPropertyGroups: QueryConditionPropertyGroup[] = [
            {
                label: 'General',
                options: [
                    { label: 'Bond Number', value: 'Bonds.BondNumber', type: QueryConditionPropertyType.String },
                    { label: 'Created Date', value: 'Bonds.CreatedDateTime', type: QueryConditionPropertyType.Date },
                    { label: 'Name on Bond', value: 'Bonds.NameOnBond', type: QueryConditionPropertyType.String },
                    { label: 'Effective Date', value: 'Bonds.EffectiveDate', type: QueryConditionPropertyType.Date},
                    { label: 'Expiration Date', value: 'Bonds.ExpirationDate', type: QueryConditionPropertyType.Date},
                    { label: 'Bond Amount', value: 'Bonds.BondAmount', type: QueryConditionPropertyType.Money },
                    { label: 'Origin System', value: 'Bonds.OriginSystem', type: QueryConditionPropertyType.OriginSystem },
                    { label: 'Tag', value: 'Tags.Name', type: QueryConditionPropertyType.StringExists },
                    { label: 'Days Till Expiration', value: 'Bonds.DaysTillExpiration', type: QueryConditionPropertyType.Integer },
                    { label: 'Cancellation Date', value: 'Bonds.CancellationDate', type: QueryConditionPropertyType.Date },
                    { label: 'Reinstatement Date', value: 'Bonds.ReinstatementDate', type: QueryConditionPropertyType.Date },
                    { label: 'Legacy ID', value: 'Bonds.LegacyId', type: QueryConditionPropertyType.String },
                    { label: 'Agency', value: 'AgencySystemAccount.CompanyName', type: QueryConditionPropertyType.String },
                ]
            },
            {
                label: 'Bond Type',
                options: [
                    { label: 'Bond Type', value: 'BondTypes.Name', type: QueryConditionPropertyType.String },
                    { label: 'Is Renewable', value: 'BondTypes.IsRenewable', type: QueryConditionPropertyType.Boolean },
                    { label: 'SFAA Code', value: 'SfaaCodes.Code', type: QueryConditionPropertyType.String },
                    { label: 'Requires Continuation Cert', value: 'BondTypes.RequiresContinuationCertificate', type: QueryConditionPropertyType.Boolean },
                    { label: 'Requires Verification Cert', value: 'BondTypes.RequiresVerificationCertificate', type: QueryConditionPropertyType.Boolean },
                    { label: 'Requisitioning State', value: 'Obligees.RequisitioningState', type: QueryConditionPropertyType.State },
                    { label: 'Is Cancellable', value: 'BondTypes.IsCancellable', type: QueryConditionPropertyType.Boolean },
                    { label: 'Is Electronic', value: 'Bonds.IsElectronic', type: QueryConditionPropertyType.Boolean },
                    { label: 'Cancellation Provision Days', value: 'BondTypes.CancellationProvisionDays', type: QueryConditionPropertyType.Integer },
                ]
            },
            {
                label: 'Servicing Roles',
                options: [
                    { label: 'Producer', value: 'Bonds.ProducerUserId', type: QueryConditionPropertyType.Producer },
                    { label: 'CSE', value: 'Bonds.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive },
                    { label: 'CSM', value: 'Bonds.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager },
                    { label: 'CSA', value: 'Bonds.ClientServiceAgentUserId', type: QueryConditionPropertyType.ClientServiceAssociate },
                ]
            },
            {
                label: 'Carrier',
                options: [
                    { label: 'Writing Company', value: 'WritingCompanies.Name', type: QueryConditionPropertyType.String },
                    { label: 'Carrier', value: 'CarrierSystemAccount.CompanyName', type: QueryConditionPropertyType.String },
            
                ]
            },
            {
                label: 'Obligee',
                options: [
                    { label: 'Obligee', value: 'Obligees.Name', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail Address', value: 'Obligees.MailAddress', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail City', value: 'Obligees.MailCity', type: QueryConditionPropertyType.String },
                    { label: 'Obligee Mail State', value: 'Obligees.MailState', type: QueryConditionPropertyType.State },
                    { label: 'Obligee Mail Zip', value: 'Obligees.MailZip', type: QueryConditionPropertyType.String },
                ]
            },
            {
                label: 'Customer / Broker',
                options: [
                    { label: 'Customer', value: 'Customers.Name', type: QueryConditionPropertyType.String },
                    { label: 'Customer Lookup Code', value: 'Customers.LookupCode', type: QueryConditionPropertyType.String },
                    { label: 'Broker', value: 'EProducerAccounts.Name', type: QueryConditionPropertyType.String },
                    { label: 'Broker Lookup Code', value: 'EProducerAccounts.LookupCode', type: QueryConditionPropertyType.String },
                ]
            },
            {
                label: 'Status',
                options: [
                    { label: 'Status', value: 'Bonds.Status', type: QueryConditionPropertyType.BondStatus },
                    { label: 'Renewal Status', value: 'Bonds.RenewalStatus', type: QueryConditionPropertyType.RenewalStatus },
                    { label: 'Cancellation Status', value: 'Bonds.CancellationStatus', type: QueryConditionPropertyType.CancellationStatus },
                    { label: 'Reinstatement Status', value: 'Bonds.ReinstatementStatus', type: QueryConditionPropertyType.ReinstatementStatus },
                    { label: 'Rider Status', value: 'Bonds.RiderStatus', type: QueryConditionPropertyType.RiderStatus },
                ]
            }
        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.Bonds,
            recordsPerPage: 10,
            sortExpression: 'Customers.Name',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
                isActive: true
            },
            searchType: SearchType.Basic
        }];

        this.table = new QueryBuilderTable(
            this.loadData,
            debouceDelayer, 
            DataViewType.Bonds, 
            queryConditionPropertyGroups, 
            columnOptions, 
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener
        );
    }

    public readonly table: QueryBuilderTable<PurchasedBondItem>;
    public busyIndicator: BusyIndicator = {};
   
    get isCarrier(): boolean {
        return this.currentUser.systemAccount.isCarrier;
    }
  
    public currentUser: CurrentUser;

    public loadData = () => {
        return this.bondService
                .getPurchasedBondReport(this.table.getQueryOptions())
                .then((response) => {
                    this.table.setData(response.items, response.totalRecordCount);
                })
                .catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred while trying to load the bond list');
                })
    };
    
    public exportPurchasedBondReport = () =>{
        return this.bondService.downloadPurchasedBondEntriesExcelReport(this.table.getQueryOptions(), this.table.getSelectedColumnNames())
    };
    
    public $onInit(): void {
        this.currentUser = this.currentUserResolver.getCurrentUser(); 
        this.table.load();
    }
}
