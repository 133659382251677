import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IJQueryService } from "./iJQueryService";

export class JQueryService {

    public static $inject = [Injectables.JQuery];

    constructor(
        private readonly jQueryProvider: IJQueryService
    ) {}

    public getElement = (expression: string | Node | HTMLElement, ownerDocument_attributes?: Document | JQuery.PlainObject): JQuery<HTMLElement> => {
        return this.jQueryProvider.getElement(expression, ownerDocument_attributes);
    }

    public $onInit = () => {}
}

app.service(Injectables.JQueryService, JQueryService);
