import { SystemAccountService } from "../../../api/systemAccountService";
import { TableQueryOptions } from "../../../api/types/tableQuery";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class AgencyAppointmentsController {
    
    public static $inject = [
        Injectables.SystemAccountService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly systemAccountService: SystemAccountService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }
    
    public table: Table<AgencyAppointmentsTableListItem, AgencyAppointmentsTableQueryOptions>;
    public busyIndicator: BusyIndicator;
    
    private loadData = () => {
        this.busyIndicator.promise = this.systemAccountService.agencyAppointmentTableListItems(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load appointments');
            });
    }

    public $onInit = () => {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.loadData();
    }
}

export type AgencyAppointmentsTableListItem = {
    id: number;
    agency: string;
    agencyCode: string;
    isActive: boolean;
}

export type AgencyAppointmentsTableQueryOptions = TableQueryOptions & {}