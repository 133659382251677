import { ApplicationPremiumRange } from "./applicationPremiumRange";
import { Attachment } from "./model/attachment";
import { Bond } from "./model/bond";
import { AccountRequestBondType } from "./model/accountRequestBondType";
import { ApplicationStatus, PrincipalType, ApplicationType, SystemType } from "./model/application";
import { ApplicationDocumentVersion } from "./model/applicationDocmentVersion";
import { ApplicationFollowUp } from "./model/applicationFollowUp";
import { ApplicationQuestionResponse } from "./model/applicationQuestionResponse";
import { BondType } from "./model/bondType";
import { Company } from "./model/company";
import { Customer } from "./model/customer";
import { DeclinedApplication } from "./model/declinedApplication";
import { EProducerAccount } from "./model/eProducerAccount";
import { Memo } from "./model/memo";
import { Obligee } from "./model/obligee";
import { Person } from "./model/person";
import { User } from "./model/user";
import { Quote } from "./model/quote";
import { Tag } from "./model/tag";
import { Application } from "./model/application";

export class UiApplication implements Application {

    constructor(application?: Application) {
        if (application) {
            for (var prop in application) {
                if (application.hasOwnProperty(prop)) {
                    this[prop] = application[prop];
                }
            }
        } else {
            this.status = ApplicationStatus.NotSubmitted,
            this.accountRequestBondTypes = [];
            this.people = [{} as Person];
            this.companies = [];
            this.applicationQuestionResponses = [];
            this.attachments = [];
            this.nameOnBond = '';
            this.memos = [];
         
            this.principalType = PrincipalType.Individual;
        }
    }

    status: ApplicationStatus;
    customerId: number;
    nameOnBond: string;
    submissionSignature: string;
    submissionDateTime: Date;
    submissionIpAddress: string;
    customer: Customer;
    comments: string;
    applicationType: ApplicationType;
    requestedSingleBondLimit: number;
    requestedAggregateLimit: number;
    bondTypeId: number;
    bondAmount: number;
    desiredEffectiveDate: Date;
    submittedByUser: User;
    bondId: number;
    bond: Bond;
    submittedByUserId: number;
    ams360CustomerId: string;
    ams360CustomerNumber: number;
    tags: Tag[];
    obligeeId: number;
    attorneyInFactUserId: number;
    attorneyInFact: string;
    attorneyInFactEmail: string;
    attorneyInFactPhone: string;
    attorneyInFactAddress: string;
    attorneyInFactSuiteAptNumber: string;
    attorneyInFactCity: string;
    attorneyInFactState: string;
    attorneyInFactZip: string;
    attorneyInFactSignatureImageFileId: number;
    attorneyInFactSignatureFile: File;
    eProducerAccountId: number;
    originSystem: SystemType;
    eProducerAccount: EProducerAccount;
    attachments: Attachment[];
    applicationQuestionResponses: ApplicationQuestionResponse[];
    companies: Company[];
    people: Person[];
    accountRequestBondTypes: AccountRequestBondType[];
    applicationDocumentVersions: ApplicationDocumentVersion[];
    quotes: Quote[];
    memos: Memo[];
    applicationFollowUps: ApplicationFollowUp[];
    declinedApplications: DeclinedApplication[];
    principalType: PrincipalType;
    id: number;
    applicationNumber?: number;
    createdDateTime: Date;
    carrierStatus: string;
    obligee: Obligee;
    
    producerUserId?: number;
    clientServiceManagerUserId?: number;
    clientServiceExecutiveUserId?: number;
    clientServiceAgentUserId?: number;

    $ranges: ApplicationPremiumRange;
    $approvedQuoteCount: number;
    $pendingQuoteCount: number;
    $status: string;
    $creditReportPeople: Person[];

    $ownerCount: number;
   
    addPerson(): Person {
        if (this.principalType === PrincipalType.Company)
            this.$ownerCount++;

        let person = {} as Person;
        this.people.push(person);

        return person;
    }

    removePerson(index: number) {
        if (this.people.length > 1)
            this.people.splice(index, 1);

        if (this.principalType === PrincipalType.Company)
            this.$ownerCount--;
    }
}