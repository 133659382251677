import { IPromise } from "angular";
import { Modal } from "../../components/modals/modal";
import { AutoFeeService } from "../../api/autoFeeService";
import { BondTypeService } from "../../api/bondTypeService";
import { SfaaCodeService } from "../../api/sfaaCodeService";
import AutoFeeDetail, { AutoFeeDetailBondType, AutoFeeDetailSfaaCode } from "../../api/types/autoFeeDetail";
import { FeeType } from "../../api/types/model/autoFee";
import { BondType } from "../../api/types/model/bondType";
import { SelectOption } from "../../api/types/selectOption";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../configuration/injectables";
import { SearchControl } from "../../utilities/searchControl";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";

export class AutoFeeDetailModal {

    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options,
        Injectables.AutoFeeService,
        Injectables.BondTypeService,
        Injectables.ToastMessageCreator,
        Injectables.SfaaCodeService
    ];

    public newFee: boolean;
    public feeId?: number;
    public busyIndicator: BusyIndicator;
    public autoFee: AutoFeeDetail;
    public feeTypes: SelectOption<string>[];
    public bondTypeSearch: SearchControl<BondType>;
    public sfaaCodeOptions: SelectOption<number>[];
    public selectedSfaaCodeId: number;

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: {id?: number},
        private readonly autoFeeService: AutoFeeService,
        private readonly bondTypeService: BondTypeService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly sfaaCodeService: SfaaCodeService
    ) {
        this.bondTypeSearch = {} as SearchControl<BondType>;
    }

    public save(): void {
        this.busyIndicator = {
            message: 'Saving...',
            promise: this.autoFeeService.save(this.autoFee).then(() => {
                    this.toastMessageCreator.createSuccessMessage('Fee was saved successfully');
                    this.$uibModalInstance.close();
                }).catch(() => {
                    this.toastMessageCreator.createErrorMessage('An error occurred trying to save the fee');
                })
            }
    }

    public close() {
        this.$uibModalInstance.dismiss();
    }

    private loadAutoFee(): IPromise<void> {
        return this.autoFeeService.getById(this.feeId)
            .then(autoFee => {
                this.autoFee = autoFee;
            });
    }

    public addBondType(): void {
        if (!this.bondTypeSearch.selected ||
            this.autoFee.autoFeeBondTypes.some((bondType) => bondType.bondTypeId == this.bondTypeSearch.selected.id)) {
            return;
        }

        this.autoFee.autoFeeBondTypes.push({
            bondTypeId: this.bondTypeSearch.selected.id,
            autoFeeId: this.autoFee.id,
            bondTypeName: this.bondTypeSearch.selected.name
        } as AutoFeeDetailBondType);

        delete this.bondTypeSearch.selected;
    }

    public addSfaaCode(): void {
        if (this.autoFee.autoFeeSfaaCodes.some((code) => code.sfaaCodeId == this.selectedSfaaCodeId)) {
            return;
        }

        const selectedSfaaCode = this.sfaaCodeOptions.filter((sfaaCode) => sfaaCode.value == this.selectedSfaaCodeId )[0];

        this.autoFee.autoFeeSfaaCodes.push({
            sfaaCodeId: this.selectedSfaaCodeId,
            autoFeeId: this.autoFee.id,
            sfaaCodeDescription: selectedSfaaCode.label
        } as AutoFeeDetailSfaaCode);
    }

    public removeSfaaCode(sfaaCode: AutoFeeDetailSfaaCode, index: number): void {
        if (!sfaaCode.id) {
            this.autoFee.autoFeeSfaaCodes.splice(index, 1);
        } else {
            sfaaCode.isRemoved = sfaaCode.isRemoved !== true;
        }
    }

    public removeBondType(bondType: AutoFeeDetailBondType, index: number): void {
        if (!bondType.id) {
            this.autoFee.autoFeeBondTypes.splice(index, 1);
        } else {
            bondType.isRemoved = bondType.isRemoved !== true;
        }
    }

    public searchBondTypes(searchPhrase: string): IPromise<void> {
        if (!searchPhrase) {
            delete this.bondTypeSearch.selected;
            return;
        }

        return this.bondTypeService.dropdownSearch(searchPhrase)
            .then((response) => {
                this.bondTypeSearch.matches = response;
            });
    }

    public loadSfaaCodes = () => {
        return this.sfaaCodeService.getNewAllSfaaCodeOptions()
            .then((sfaaCodeOptions) => {
                this.sfaaCodeOptions = sfaaCodeOptions;
            });
    };

    public $onInit(): void {
        this.feeId = this.options.id;
        this.newFee = !this.feeId;
        
        this.feeTypes = [{
            label: 'Flat Fee',
            value: 'Flat'
        },{
            label: 'Percent of Premium',
            value: 'PercentOfPremium'
        }];

        this.loadSfaaCodes();

        if (!this.newFee) {
            this.busyIndicator = {
                message: 'Loading...',
                promise: this.loadAutoFee()
            }
        } else {
            this.autoFee = {
                autoFeeSfaaCodes: [],
                autoFeeBondTypes: [],
                appliesToAllBondTypes: true,
                feeType: FeeType.Flat,
                appliesToEProducerPrincipals: true,
                appliesToEproducerBrokers: true,
                appliesInA3: true,
            } as AutoFeeDetail;
        }
    }
}
