import { CustomerType } from "../api/types/model/customer";
import { SelectOption } from "../api/types/selectOption";
import { AmSelectOption } from "../components/amSelectDropdown/amSelect";
import DropdownOption from "../api/types/dropdownOption";

export type Constants = {
    carrierApplicationStatusHtml: ConstantsStatusHtml;
    agencyApplicationStatusHtml: ConstantsStatusHtml;
    applicationStatus: ConstantsApplicationStatus;
    rateTypes: ConstantsRateTypes;
    creditCardProcessors: ConstantsCreditCardProcessors;
    achProcessors: ConstantsAchProcessors;
    ruleOperatorTypes: ConstantsRuleOperatorTypes;
    ruleConditionTypes: ConstantsRuleConditionTypes;
    unknownProfilePicture: string;
    applicationFollowUpTypes: ConstantsApplicationFollowupTypes;
    stateAbbreviations: string[];
    states: ConstantsState[];
    companyTypeOptions: SelectOption<string>[];
    referredByTypesOptions: SelectOption<string>[];
    transactionTypeDropdownOptions: DropdownOption<string>[];
    recordsPerPageOptions: AmSelectOption[];
    paymentStatusOptions: SelectOption<string>[];
    paymentMethodOptions: SelectOption<string>[];
    customerTypeOptions: SelectOption<CustomerType>[];
};

export type ConstantsStatusDisplay = {
    html: string;
    text: string;
};

export type ConstantsStatusHtml = {
    manualReviewStatus: ConstantsStatusDisplay;
    declinedStatus: ConstantsStatusDisplay;
    declinedStatusAdverseAction: ConstantsStatusDisplay;
    approvedStatus: ConstantsStatusDisplay;
};

export type ConstantsApplicationStatus = {
    notSubmitted: string;
    submitted: string;
    closed: string;
    completed: string;
};

export type ConstantsRateTypes = {
    fixed: string;
    tiered: string;
    ratePerThousand: string;
};

export type ConstantsCreditCardProcessors = {
    authorizeNet: string;
    worldPay: string;
    eProcessingNetwork: string;
    vantiv: string;
    xPressPay: string;
    dummy: string;
};

export type ConstantsAchProcessors = {
    checkCommerce: string;
    vantiv: string;
    dummy: string;
};

export type ConstantsRuleOperatorTypes = {
    greaterThan: string;
    greaterThanOrEqualTo: string;
    lessThan: string;
    lessThanOrEqualTo: string;
    equalTo: string;
    notEqualTo: string;
};

export type ConstantsRuleConditionTypes = {
    and: string;
    or: string;
    bondAmount: string;
    vantageScore: string;
    yearsOfExperience: string;
    underwritingQuestion: string;
};

export type ConstantsApplicationFollowupTypes = {
    submission: string;
    followUp: string;
    purchase: string;
    prospectFollowUp: string;
    prospectCreation: string;
    brokerInvite: string;
};

export type ConstantsState = {
    abbreviation: string;
    name: string;
};

export const constants: Constants = {
    customerTypeOptions: [
        { label: CustomerType.Individual, value: CustomerType.Individual },
        { label: CustomerType.Company, value: CustomerType.Company }
    ],

    paymentMethodOptions: [
        { label: "Credit Card", value: "'CreditCard'" },
        { label: "ACH", value: "'ACH'" },
        { label: "Check", value: "'Check'" },
        { label: "Wire Transfer", value: "'WireTransfer'" },
        { label: "Other", value: "'Other'" },
    ],

    paymentStatusOptions: [
        {
            label: "Approved",
            value: "'Approved'"
        },
        {
            label: "Declined",
            value: "'Declined'"
        },
        {
            label: "Error",
            value: "'Error'"
        }
    ],

    agencyApplicationStatusHtml: {
        approvedStatus: {
            html: '<span class="green">Approved</span>',
            text: "Approved"
        },
        declinedStatus: {
            html: '<span class="red">Declined</span>',
            text: "Declined"
        },
        declinedStatusAdverseAction: {
            html: '<span class="red">Declined - Adverse Action</span>',
            text: "Declined - Adverse Action"
        },
        manualReviewStatus: { html: "Manual Review", text: "Manual Review" }
    },
    applicationStatus: {
        closed: "Closed",
        completed: "Completed",
        notSubmitted: "NotSubmitted",
        submitted: "Submitted"
    },
    carrierApplicationStatusHtml: {
        approvedStatus: {
            html: '<span class="green">Approved</span>',
            text: "Approved"
        },
        declinedStatus: {
            html: '<span class="red">Declined</span>',
            text: "Declined"
        },
        declinedStatusAdverseAction: {
            html: '<span class="red">Declined - Adverse Action</span>',
            text: "Declined - Adverse Action"
        },
        manualReviewStatus: { html: "Manual Review", text: "Manual Review" }
    },

    rateTypes: {
        fixed: "Fixed",
        ratePerThousand: "RatePerThousand",
        tiered: "Tiered"
    },

    creditCardProcessors: {
        authorizeNet: "AuthorizeNet",
        eProcessingNetwork: "EProcessingNetwork",
        worldPay: "WorldPay",
        vantiv: "Vantiv",
        xPressPay: "XPressPay",
        dummy: "Dummy"
    },

    achProcessors: {
        checkCommerce: "CheckCommerce",
        vantiv: "Vantiv",
        dummy: "Dummy"
    },

    ruleOperatorTypes: {
        equalTo: "EqualTo",
        greaterThan: "GreaterThan",
        greaterThanOrEqualTo: "GreaterThanOrEqualTo",
        lessThan: "LessThan",
        lessThanOrEqualTo: "LessThanOrEqualTo",
        notEqualTo: "NotEqualTo"
    },

    ruleConditionTypes: {
        and: "And",
        bondAmount: "BondAmount",
        or: "Or",
        underwritingQuestion: "underwritingQuestion",
        vantageScore: "VantageScore",
        yearsOfExperience: "YearsOfExperience"
    },

    unknownProfilePicture: "/img/unknown.profile.png",

    applicationFollowUpTypes: {
        brokerInvite: "BrokerInvite",
        followUp: "FollowUp",
        prospectCreation: "ProspectCreation",
        prospectFollowUp: "ProspectFollowUp",
        purchase: "Purchase",
        submission: "Submission"
    },

    transactionTypeDropdownOptions: [
        { label: "All", value: "All" },
        { isDivider: true },
        { label: "New Business", value: "NewBusiness" },
        { label: "Riders", value: "Rider" },
        { label: "Renewals", value: "Renewal" },
        { label: "Reinstatements", value: "Reinstatement" },
        { label: "Cancellations", value: "Cancellation" },
        { label: "Import", value: "Import"}
    ],

    stateAbbreviations: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
    ],

    states: [
        {
            abbreviation: "AL",
            name: "Alabama"
        },
        {
            abbreviation: "AK",
            name: "Alaska"
        },
        {
            abbreviation: "AZ",
            name: "Arizona"
        },
        {
            abbreviation: "AR",
            name: "Arkansas"
        },
        {
            abbreviation: "CA",
            name: "California"
        },
        {
            abbreviation: "CO",
            name: "Colorado"
        },
        {
            abbreviation: "CT",
            name: "Connecticut"
        },
        {
            abbreviation: "DE",
            name: "Delaware"
        },
        {
            abbreviation: "DC",
            name: "District Of Columbia"
        },
        {
            abbreviation: "FL",
            name: "Florida"
        },
        {
            abbreviation: "GA",
            name: "Georgia"
        },
        {
            abbreviation: "HI",
            name: "Hawaii"
        },
        {
            abbreviation: "ID",
            name: "Idaho"
        },
        {
            abbreviation: "IL",
            name: "Illinois"
        },
        {
            abbreviation: "IN",
            name: "Indiana"
        },
        {
            abbreviation: "IA",
            name: "Iowa"
        },
        {
            abbreviation: "KS",
            name: "Kansas"
        },
        {
            abbreviation: "KY",
            name: "Kentucky"
        },
        {
            abbreviation: "LA",
            name: "Louisiana"
        },
        {
            abbreviation: "ME",
            name: "Maine"
        },
        {
            abbreviation: "MD",
            name: "Maryland"
        },
        {
            abbreviation: "MA",
            name: "Massachusetts"
        },
        {
            abbreviation: "MI",
            name: "Michigan"
        },
        {
            abbreviation: "MN",
            name: "Minnesota"
        },
        {
            abbreviation: "MS",
            name: "Mississippi"
        },
        {
            abbreviation: "MO",
            name: "Missouri"
        },
        {
            abbreviation: "MT",
            name: "Montana"
        },
        {
            abbreviation: "NE",
            name: "Nebraska"
        },
        {
            abbreviation: "NV",
            name: "Nevada"
        },
        {
            abbreviation: "NH",
            name: "New Hampshire"
        },
        {
            abbreviation: "NJ",
            name: "New Jersey"
        },
        {
            abbreviation: "NM",
            name: "New Mexico"
        },
        {
            abbreviation: "NY",
            name: "New York"
        },
        {
            abbreviation: "NC",
            name: "North Carolina"
        },
        {
            abbreviation: "ND",
            name: "North Dakota"
        },
        {
            abbreviation: "OH",
            name: "Ohio"
        },
        {
            abbreviation: "OK",
            name: "Oklahoma"
        },
        {
            abbreviation: "OR",
            name: "Oregon"
        },
        {
            abbreviation: "PA",
            name: "Pennsylvania"
        },
        {
            abbreviation: "RI",
            name: "Rhode Island"
        },
        {
            abbreviation: "SC",
            name: "South Carolina"
        },
        {
            abbreviation: "SD",
            name: "South Dakota"
        },
        {
            abbreviation: "TN",
            name: "Tennessee"
        },
        {
            abbreviation: "TX",
            name: "Texas"
        },
        {
            abbreviation: "UT",
            name: "Utah"
        },
        {
            abbreviation: "VT",
            name: "Vermont"
        },
        {
            abbreviation: "VA",
            name: "Virginia"
        },
        {
            abbreviation: "WA",
            name: "Washington"
        },
        {
            abbreviation: "WV",
            name: "West Virginia"
        },
        {
            abbreviation: "WI",
            name: "Wisconsin"
        },
        {
            abbreviation: "WY",
            name: "Wyoming"
        }
    ],

    companyTypeOptions: [
        { label: "Proprietorship", value: "Proprietorship" },
        { label: "Partnership", value: "Partnership" },
		{ label: "LLC", value:  "LLC" },
        { label: "SCorp", value: "SCorp" },
        { label: "CCorp", value: "CCorp" }	
    ],

    referredByTypesOptions: [
        { label: "PIA Email", value: "PIA Email" },
        { label: "Social Media", value: "Social Media" },
        { label: "Friend/Colleague", value: "Friend/Colleague" },
        { label: "Email", value: "Email" },
        { label: "Trade Show/Conference", value: "Trade Show/Conference" },
        { label: "Other", value: "Other" }
    ],

    recordsPerPageOptions: [
        {
            text: "10",
            value: 10,
            selectedText: "10"
        },
        {
            text: "20",
            value: 20,
            selectedText: "20"
        },
        {
            text: "50",
            value: 50,
            selectedText: "50"
        }
    ]
};
