import { DocumentService } from "../../api/documentService";
import { DocumentCategory } from "../../api/types/model/documentCategory";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { IPromise, IScope } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import DocumentSearchResult from "../../api/types/documents/documentSearchResult";
import { UploadControls, UploaderItem } from "../uploader/uploader";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { DocumentStatus } from "../../api/types/model/document";

export interface DocumentPicker {
    save: () => IPromise<UploaderItem[]>;
}

class DocumentPickerController {
    public static fileIdCounter = 0;

    public documentCategory: DocumentCategory;
    public searchResults: DocumentSearchResult[] = [];
    public documents: DocumentPickerDocument[] = [];
    public showStatus: boolean;
    public placeholder: string;
    public allowMultipleSelections: boolean;
    public control: DocumentPicker;
    public documentFilter: 'PublicOnly' | 'PrivateOnly' | 'Any';
    public versionUpdateUploaderControls: UploadControls;
    public uploaderControls: UploadControls;
    public filesToUpload: UploaderItem[];

    public onDocumentSelected: (args: { selectedDocument: DocumentPickerDocument }) => void;
    public onDocumentRemoved: (args: { documentId: number }) => void;
    
    public get defaultMessage(): string {
        if (this.placeholder) {
            return this.placeholder;
        } else {
            return `Use Default ${this.documentCategoryName}`;
        }
    }

    public get showDefault(): boolean {
        return !this.documents?.length && !this.uploaderControls?.getFiles().length;
    }

    public get documentCategoryName(): string {
        return this.documentCategory?.name ?? '';
    }

    public get documentCategoryId(): number {
        return this.documentCategory?.id ?? undefined;
    }

    public get documentCount(): number {
        
        let count = this.documents?.length ?? 0;
        count += this.uploaderControls?.getFiles().length ?? 0;

        return count;
    }

    public get canAddDocument(): boolean {
        return this.allowMultipleSelections || this.documentCount === 0;
    }

    public get selectedDocument(): DocumentPickerDocument {
        if (!this.searchResults) {
            return undefined;
        } else {
            return this.searchResults['selected'];
        }
    }

    public set selectedDocument(document: DocumentPickerDocument) {
        this.searchResults['selected'] = document;
    }

    public get isDocumentSelected(): boolean {
        return this.selectedDocument !== undefined;
    }

    public get showSearch(): boolean {
        return this.canAddDocument;
    }

    public static $inject = [
        Injectables.DocumentService,
        Injectables.SystemSettings,
        Injectables.$scope,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private documentService: DocumentService,
        private systemSettings: SystemSettings,
        private $scope: IScope,
        private toastMessageCreator: ToastMessageCreator
    ) { }

    public $onInit(): void {
        this.loadSelectableDocuments();
        
        this.control = {
            save: () => this.uploaderControls.uploadAll() 
        };
    }

    private loadSelectableDocuments(): void {
        const unsubscribe = this.$scope.$watch('vm.documentCategory', () => {
            if (this.documentCategory !== undefined) {
                unsubscribe();
                this.searchDocuments('');
            }
        });
    }

    public searchDocuments(search: string): void {
        if (!search || !this.documentCategoryId) {
            return;
        }

        this.documentService.searchDocuments(search, this.documentCategoryId)
            .then(documents => {
                this.searchResults = this.filterDocuments(documents);
            });
    }

    private filterDocuments(documents: DocumentSearchResult[]): DocumentSearchResult[] {
        if (this.documentFilter === 'PrivateOnly') {
            return documents.filter(d => !d.isPublic);
        } else if (this.documentFilter === 'PublicOnly') {
            return documents.filter(d => d.isPublic);
        } else {
            return documents;
        }
    }

    public onClick($select): void {
        $select.searchInput.on('blur', () => {
            $select.searchInput.val(null);
        });
    }

    public addSelectedDocument(): void {
        if (!this.isDocumentSelected || !this.canAddDocument) {
            return;
        }

        const documentAlreadyAdded = this.documents.find(d => d.id === this.selectedDocument.id) !== undefined;

        if (this.onDocumentSelected && !documentAlreadyAdded) {
            this.onDocumentSelected({
                selectedDocument: this.selectedDocument
            });
        }

        this.selectedDocument = undefined;
    }

    public removeDocument(document: DocumentSearchResult): void {
        if (this.onDocumentRemoved) {
            this.onDocumentRemoved({ documentId: document.id });
        }
    }

    public getDocumentUrl(document: DocumentSearchResult): string {
        return this.documentService.getDocumentUrl(document.id);
    }

    public getPreviewDocumentUrl(document: DocumentSearchResult): string {
        return this.documentService.getSampleBondDocumentUrl(document.id);
    }

    public canDownloadDocument(document: DocumentSearchResult): boolean {
        return !!document?.id;
    }

    public canPreviewDocument(document: DocumentSearchResult): boolean {
        return !!document?.id;
    }

    public canEditDocument(document: DocumentSearchResult): boolean {
        return !!document?.id;
    }

    public canUploadNewVersion(document: DocumentSearchResult): boolean {
        return !document.isPublic;
    }

    public isDocumentUntouched(document: DocumentSearchResult): boolean {
        return document.status === DocumentStatus.Untouched;
    }

    public isDocumentInPrimaryReview(document: DocumentSearchResult): boolean {
        return document.status === DocumentStatus.PrimaryReview;
    }

    public isDocumentInSecondaryReview(document: DocumentSearchResult): boolean {
        return document.status === DocumentStatus.SecondaryReview;
    }

    public isDocumentApproved(document: DocumentSearchResult): boolean {
        return document.status === DocumentStatus.Approved; 
    }

    public isDocumentProblemReported(document: DocumentSearchResult): boolean {
        return document.status === DocumentStatus.ProblemReported;
    }
    
    public uploadNewVersion = (document: DocumentSearchResult) => {
        const documentToUpdate = {
            name: document.name,
            id: document.id,
            documentCategoryId: this.documentCategoryId
        };

        this.versionUpdateUploaderControls.setUrl(`${this.systemSettings.apiBaseUrl}documentLibrary/Update`);
        this.versionUpdateUploaderControls.clearFormData();
        this.versionUpdateUploaderControls.addpendFormData("document", documentToUpdate);
        this.versionUpdateUploaderControls.openFileDialog();
    }

    public documentVersionUpdated = () => {
        this.toastMessageCreator.createSuccessMessage("Form was updated succesfully");        
    }

    public filesAddedForUpload(files: UploaderItem[]) {
        this.uploaderControls.setUrl(`${this.systemSettings.apiBaseUrl}documentLibrary/Upload?categoryId=${this.documentCategoryId}`);
    }
}

const documentPickerComponent = {
    templateUrl: 'app/components/documentPicker/documentPicker.html',
    bindings: {
        placeholder: '@?',
        documentFilter: '@?',
        documentCategory: '=?',
        documents: '=?',
        showStatus: '=?',
        allowMultipleSelections: '=?',
        onDocumentSelected: '&?',
        onDocumentRemoved: '&?',
        control: '=?',
        hideEmptyState: '@?',
        filesToUpload: '=?'
    },
    controller: DocumentPickerController,
    controllerAs: 'vm'
};

app.component('documentPicker', documentPickerComponent);


export interface DocumentPickerDocument {
    id: number;
    name: string;
}