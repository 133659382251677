import { RiderRequest } from "../riderRequest";
import { BondCorrection } from "./bondCorrection";
import { IBaseEntity } from "./iBaseEntity";

export interface BondChange extends IBaseEntity { 
    id: number;
    changeType: BondChangeType;
    companyId: number;
    personId: number;
    bondAdditionalQuestionResponseId: number;
    newValue: string;
    originalValue: string;
    newValueText: string;
    originalValueText:  string
    bondCorrectionId: number;
    riderRequest: RiderRequest;
    riderRequestId: number;
    description: string;
    createdDateTime: Date;
}

export enum BondChangeType {
    NameOnBond = "NameOnBond",
    BondAmount = "BondAmount",
    BondNumber = "BondNumber",
    EffectiveDate = "EffectiveDate",
    ExpirationDate = "ExpirationDate",
    BondTypeId = "BondTypeId",
    CompanyName = "CompanyName",
    CompanyDba = "CompanyDba",
    CompanyType = "CompanyType",
    CompanyPhone = "CompanyPhone",
    CompanyFax = "CompanyFax",
    CompanyEmail = "CompanyEmail",
    CompanyPhysicalAddress = "CompanyPhysicalAddress",
    CompanyMailingAddress = "CompanyMailingAddress",
    CompanyDateFormed = "CompanyDateFormed",
    CompanyNumberOfOwners = "CompanyNumberOfOwners",
    CompanyStateOfIncorporation = "CompanyStateOfIncorporation",
    CompanyFein = "CompanyFein",
    CompanyNumberOfEmployees = "CompanyNumberOfEmployees",
    IndividualName = "IndividualName",
    IndividualSsn = "IndividualSsn",
    IndividualDateOfBirth = "IndividualDateOfBirth",
    IndividualDriversLicenseNumber = "IndividualDriversLicenseNumber",
    IndividualDriversLicenseState = "IndividualDriversLicenseState",
    IndividualHomePhone = "IndividualHomePhone",
    IndividualCellPhone = "IndividualCellPhone",
    IndividualFax = "IndividualFax",
    IndividualEmail = "IndividualEmail",
    IndividualPhysicalAddress = "IndividualPhysicalAddress",
    IndividualMailingAddress = "IndividualMailingAddress",
    IndividualJobTitle = "IndividualJobTitle",
    IndividualMaritalStatus = "IndividualMaritalStatus",
    IndividualSpouseName = "IndividualSpouseName",
    IndividualSpouseSsn = "IndividualSpouseSsn",
    IndividualSpouseEmail = "IndividualSpouseEmail",
    IndividualSpouseCellPhone = "IndividualSpouseCellPhone",
    IndividualSpouseDateOfBirth = "IndividualSpouseDateOfBirth",
    IndividualSpouseGender = "IndividualSpouseGender",
    IndividualGender = "IndividualGender",
    IndividualResidenceType = "IndividualResidenceType",
    IndividualDateMovedToResidence = "IndividualDateMovedToResidence",
    BondFormInformation = "BondFormInformation",
    IndividualEmployerName = "IndividualEmployerName",
    IndividualEmployerPhone = "IndividualEmployerPhone",
    IndividualOwnershipPercent = "IndividualOwnershipPercent",
    IndividualIsUSCitizen = "IndividualIsUSCitizen",
    ObligeeContact1 = "ObligeeContact1",
    ObligeeContact2 = "ObligeeContact2",
    ObligeeContact3 = "ObligeeContact3",
    ObligeeEmail = "ObligeeEmail",
    ObligeeFax = "ObligeeFax",
    ObligeeMailAddress = "ObligeeMailAddress",
    ObligeeName = "ObligeeName",
    ObligeePhone = "ObligeePhone",
    ObligeePhysicalAddress = "ObligeePhysicalAddress",
    ObligeeWebsiteURL = "ObligeeWebsiteURL",
    IndividualEmployerAddress = "IndividualEmployerAddress",
    IndividualYearsExperience = "IndividualYearsExperience",
    WritingCompanyId = "WritingCompanyId"
}