export interface ApplicationReportItem  {
    id: number;
    applicationNumber: string;
    customerId: number;
    customerName: string;
    customerLookupCode: string;
    applicationType: string;
    createdDate: Date;
    desiredEffectiveDate: Date;
    submissionDate: Date;
    bondAmount: number;
    bondType: string;
    bondId: number;
    nameOnBond: string;
    approvedQuotesCount: number;
    pendingQuotesCount: number;
    bondNumber: string;
}