import { SystemAccountService } from "../../../api/systemAccountService";
import { TableQueryOptions } from "../../../api/types/tableQuery";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import { Injectables } from "../../../configuration/injectables";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../../utilities/tables/table";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";

export class BondNumberGridController {

    public static $inject = [
        Injectables.CurrentUserResolver,
        Injectables.SystemAccountService,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemAccountService: SystemAccountService,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator
    ) { 
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public table: Table<BondNumberGroupTableItem, BondGroupTableQueryOptions>;
    public busyIndicator: BusyIndicator;
    public integratedCarrier: boolean;

    private getHasBondNumberIntegration() {
        return this.systemAccountService.getHasBondNumberIntegration(this.currentUserResolver.getCurrentUser().systemAccount.id)
            .then((response) => {
                this.integratedCarrier = response;
            });
    }

    private loadData = () => {
        this.busyIndicator.promise = this.systemAccountService.getBondNumberGroupTableItems(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load bond number groups');
            });
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.getHasBondNumberIntegration();
        this.loadData();
    }
}

export type BondNumberGroupTableItem = {
    id: number;
    name: string;
    nextNumber: number;
    prefix?: string;
    suffix?: string;
    lastNumber?: number;
    canBeDeleted?: boolean;
}

export type BondGroupTableQueryOptions = TableQueryOptions & {}