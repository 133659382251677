import app from "../../../main";
import isUndefinedOrNull from "../../../utilities/angularUtilities/isUndefinedOrNull";
import { Table } from "../../../utilities/tables/table";
import { AutoFeesDropdownFilterOptions } from "./autoFeesDropdownFilterOptions";
import { AutoFee } from "../../../api/types/model/autoFee";

class AutoFeesFilterDropdownController {
    public static $inject = [];

    constructor() {
        this.modifiedFilters = {} as AutoFeesDropdownFilterOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: AutoFeesDropdownFilterOptions;

    public table: Table<AutoFee, AutoFeesDropdownFilterOptions>;

    public clearFilters() {
        this.modifiedFilters = {
            autoApply: null,
			creditProducer: null,
			includeInBrokerCommissionCalculation: null
        } as AutoFeesDropdownFilterOptions;
        this.table.applyFilters({ ...this.modifiedFilters });

        this.setFilterCount();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.modifiedFilters = { ...this.table.queryOptions };
    }

    public applyFilters() {
        this.isOpen = false;
        this.table.applyFilters({ ...this.modifiedFilters });
        this.setFilterCount();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters.autoApply = this.table.queryOptions.autoApply;
        this.modifiedFilters.creditProducer = this.table.queryOptions.creditProducer;
        this.modifiedFilters.includeInBrokerCommissionCalculation = this.table.queryOptions.includeInBrokerCommissionCalculation;

        this.setFilterCount();
    };

    public setFilterCount() {
        this.filterCount = 0;

        if (!isUndefinedOrNull(this.table.queryOptions.autoApply)) {
            this.filterCount++;
        }

        if (this.table.queryOptions.creditProducer) {
            this.filterCount++;
        }

        if (this.table.queryOptions.includeInBrokerCommissionCalculation) {
            this.filterCount++;
        }
    }

    public $onInit() {
        this.setModifiedFilters();
    }
}

const autoFeesFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: AutoFeesFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/components/autoFeeList/autoFeesFilterDropdown/autoFeesFilterDropdown.html"
};

app.component(
    "autoFeesFilterDropdown",
    autoFeesFilterDropdownComponent
);
