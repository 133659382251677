import { ODataFactory, ODataEndpoint } from "../../../api/odata";
import { SystemAccountService } from "../../../api/systemAccountService";
import { BondType } from "../../../api/types/model/bondType";
import { MasterApplicationQuestion, QuestionType } from "../../../api/types/model/masterApplicationQuestion";
import { RuleConditionType } from "../../../api/types/model/ruleCondition";
import { WritingCompany } from "../../../api/types/model/writingCompany";
import { SelectOption } from "../../../api/types/selectOption";
import { UnderwritingConfigurationService } from "../../../api/underwritingConfigurationService";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { SearchControl } from "../../../utilities/searchControl";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { State } from "../../state";
import { IQService, IPromise } from "angular";
import { CurrentUserResolver } from "../../../utilities/currentUserResolver/currentUserResolver";
import { IndemnityRequirements } from "../../../api/types/model/quote";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import DocumentSearchResult from "../../../api/types/documents/documentSearchResult";
import { DocumentService } from "../../../api/documentService";
import { MasterApplicationQuestionChoice } from "../../../api/types/model/masterApplicationQuestionChoice";

export class UnderwritingConfigurationDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.CurrentUserResolver,
        Injectables.UnderwritingConfigurationService,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.$state,
        Injectables.SystemAccountService,
        Injectables.ModalOpener,
        Injectables.ToastMessageCreator,
        Injectables.DocumentService
    ];

    constructor(
        private readonly $stateParams: UnderwritingConfigurationDetailParams,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly underwritingConfiguraitonService: UnderwritingConfigurationService,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly $state: State,
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly documentService: DocumentService
    ) {}

    public busyIndicator: BusyIndicator;
    public config: UnderwritingConfigurationDetail;
    public quoteStatusOptions: SelectOption<string>[];
    public isCarrierBondNumberIntegrationEnabled: boolean;
    public documentSearch: SearchControl<DocumentSearchResult>;
    public bondTypeSearch: SearchControl<BondType>;
    public questionSearch: SearchControl<MasterApplicationQuestion>;
    public writingCompanies: WritingCompany[];
    public requiredFieldSets: SelectOption<number>[];
    public bondNumberGroupOptions: SelectOption<number>[];
    public rates: SelectOption<number>[];
    public hasUnderwritingQuestions: boolean;
    public indemnityRequired: boolean;
    public experienceRequired: boolean;
    public overrideMinimumPremium: boolean;
    public overrideCommission: boolean;
    public overrideRenewalCommission: boolean;
    public overrideRate: boolean;
    public selectedBondType: BondType;
    public hasBondFormAttachments: boolean;

    public rateOverrideSortableOptions = {
        animation: 350,
        chosenClass: 'sortable-chosen',
        fallbackClass: 'table-sortable-fallback',
        handle: '.div-list-grab-handle',
        forceFallback: true
    };

    public showNewRateModal(): void {
        this.busyIndicator.promise = this.modalOpener
            .showRateModal()
            .result.then((rate) => {
                return this.loadRates()
                    .then(() => {
                        this.config.rateId = rate.id;
                    });
            })
            .catch(() => {});
    }

    public searchBondTypes(searchPhrase: string): IPromise<void> {
        if (!searchPhrase) {
            return;
        }
        
        const bondTypeSvc = this.odata.getService<BondType>(
            ODataEndpoint.BondType
        );
        const query = this.odata.getQuery();
        query.filter("(contains(name,'" + searchPhrase + "'))");
        query.orderby("name");
        query.top(25);

        return bondTypeSvc.get(query).then((response) => {
            this.bondTypeSearch.matches = response.data.value;
        });
    }

    public bondTypeSelected(): void {
        this.config.bondTypeId = this.selectedBondType.id;
    }

    public searchDocuments(searchPhrase: string): void {
        if (!searchPhrase) {
            return;
        }

        this.documentService.searchDocuments(searchPhrase)
            .then((response) => {
                this.documentSearch.matches = response;
            });
    }

    public searchQuestions(searchControl: SearchControl<any>, searchPhrase: string): void {
        if (!searchPhrase) {
            return;
        }

        const questionSvc = this.odata.getService<MasterApplicationQuestion>(ODataEndpoint.MasterApplicationQuestion);
        
        const query = this.odata.getQuery();
        query.top(20);
        query.filter("contains(question,'" + searchPhrase +"') and isSubQuestion eq false");
        query.expand("masterApplicationQuestionChoices");

        questionSvc.get(query)
            .then((response) => {
                searchControl.matches = response.data.value;
            });
    }

    public addQuestion(): void {
        if (!this.questionSearch || !this.questionSearch.selected) {
            return;
        }

        if (this.config.underwritingQuestions) {
            for (let i = 0; i < this.config.underwritingQuestions.length; i++) {
                if (this.config.underwritingQuestions[i].masterApplicationQuestionId === this.questionSearch.selected.id) {
                    this.questionSearch.selected = null;
                    return;
                }
            }
        } else {
            this.config.underwritingQuestions = [];
        }

        this.config.underwritingQuestions.push({
            isRequired: true,
            underwritingConfigurationId: this.config.id,
            masterApplicationQuestionId: this.questionSearch.selected.id,
            questionName: this.questionSearch.selected.name,
            questionText: this.questionSearch.selected.question,
            options: this.questionSearch.selected.masterApplicationQuestionChoices,
            questionType: this.questionSearch.selected.type,
            requiredValue: '',
        });

        this.questionSearch.selected = null;
    }

    public removeQuestion(index: number, question: UnderwritingConfigurationQuestion): void {
        if (question.id) {
            question.isRemoved = question.isRemoved !== true;
        } else {
            this.config.underwritingQuestions.splice(index, 1);
        }
    }

    public addRateOverride(): void {
        if (!this.config.rateOverrides) {
            this.config.rateOverrides = [];
        }

        this.config.rateOverrides.push({
            conditions: []
        } as UnderwritingConfigurationRateOverride);
    }
    
    public removeRateOverride(index: number, rateOverride: UnderwritingConfigurationRateOverride): void {
        if (rateOverride.id) {
            rateOverride.isRemoved = rateOverride.isRemoved !== true;
        } else {
            this.config.rateOverrides.splice(index, 1);
        }
    }

    public addRateOverrideCondition(rateOverride: UnderwritingConfigurationRateOverride): void {
        const condition = {
            conditionType: RuleConditionType.UnderwritingQuestion,
            options: [],
            requiredValue: ''
        };
        condition["questionSearch"] = new SearchControl();
        rateOverride.conditions.push(condition as UnderwritingConfigurationRateOverrideCondition);
    }

    public removeRateOverrideCondition(index: number, rateOverride: UnderwritingConfigurationRateOverride, rateOverrideCondition: UnderwritingConfigurationRateOverrideCondition): void {
        if (rateOverrideCondition.id) {
            rateOverrideCondition.isRemoved = rateOverrideCondition.isRemoved !== true;
        } else {
            rateOverride.conditions.splice(index, 1);
        }
    }

    public removeDocument(attachment: UnderwritingConfigurationBondFormAttachment, index: number): void {
        if(attachment.id) {
            attachment.isRemoved = attachment.isRemoved !== true;
        } else {
            this.config.bondFormAttachments.splice(index, 1);
        }
    }

    public removeBondFormAttachmentCondition(bondFormAttachment: UnderwritingConfigurationBondFormAttachment, condition: UnderwritingConfigurationBondFormAttachmentCondition, index: number): void {
        if (condition.id) {
            condition.isRemoved = condition.isRemoved !== true;
        } else {
            bondFormAttachment.conditions.splice(index, 1);
        }
    }

    public addDocument(): void {
        if (!this.documentSearch || !this.documentSearch.selected) {
            return;
        }

        if (!this.config.bondFormAttachments) {
            this.config.bondFormAttachments = [];
        }

        for (let i = 0; i < this.config.bondFormAttachments.length; i++) {
            if (this.config.bondFormAttachments[i].documentId === this.documentSearch.selected.id) {
                this.documentSearch.selected = null;
                return;
            }
        }

        this.config.bondFormAttachments.push({
            underwritingConfigurationId: this.config.id,
            documentId: this.documentSearch.selected.id,
            documentName: this.documentSearch.selected.name,
            conditions: []
        } as UnderwritingConfigurationBondFormAttachment);

        this.documentSearch.selected = null;
    }

    public addBondFormAttachmentCondition(bondFormAttachment: UnderwritingConfigurationBondFormAttachment): void {
        if (!bondFormAttachment.conditions) {
            bondFormAttachment.conditions = [];
        }

        const condition = {};
        condition["questionSearch"] = new SearchControl();
        bondFormAttachment.conditions.push(
            condition as UnderwritingConfigurationBondFormAttachmentCondition
        );
    }

    public setBondAttachmentConditionMasterApplicationQuestion(condition: UnderwritingConfigurationBondFormAttachmentCondition, masterApplicationQuestion: MasterApplicationQuestion): void {
        condition.masterApplicationQuestionId = masterApplicationQuestion.id;
        condition.questionType = masterApplicationQuestion.type;
        condition.options = masterApplicationQuestion.masterApplicationQuestionChoices; 
    }

    private loadWritingCompanies(): IPromise<void> {
        return this.systemAccountService
            .getWritingCompanies(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then((writingCompanies) => {
                this.writingCompanies = writingCompanies;
            });
    }

    private loadRequiredFieldSets(): IPromise<void> {
        return this.systemAccountService
            .getRequiredFieldSets(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then((requiredFieldSets) => {
                this.requiredFieldSets = requiredFieldSets;
            });
    }

    private loadBondNumberGroups(): IPromise<void> {
        return this.systemAccountService
            .getBondNumberGroupOptions(this.currentUserResolver.getCurrentUser().user.systemAccountId, true)
            .then((bondNumberGroupOptions) => {
                this.bondNumberGroupOptions = bondNumberGroupOptions;
            });
    }

    private loadRates(): IPromise<void> {
        return this.systemAccountService
            .getRateOptions(this.currentUserResolver.getCurrentUser().user.systemAccountId)
            .then((rates) => {
                this.rates = rates;
            });
    }

    private loadUnderwritingConfiguration = (id: number) => {
        return this.underwritingConfiguraitonService.loadUnderwritingConfigurationDetail(id)
            .then((config) => {
                this.config = config;

                this.hasUnderwritingQuestions = !!this.config.underwritingQuestions?.length;
                this.indemnityRequired = !!this.config.indemnityRequirements && this.config.indemnityRequirements != IndemnityRequirements.None
                this.experienceRequired = !!this.config.minimumYearsInBusiness;
                this.overrideMinimumPremium = !!this.config.minimumPremium;
                this.overrideCommission = !!this.config.commissionOverride || this.config.commissionOverride == 0;
                this.overrideRenewalCommission = !!this.config.renewalCommissionOverride || this.config.renewalCommissionOverride == 0;
                this.overrideRate = !!this.config.rateOverrides.length
                this.hasBondFormAttachments = !!this.config.bondFormAttachments.length;

                if (this.overrideCommission) {
                    this.config.commissionOverride = this.config.commissionOverride * 100;
                }
                
                if (this.overrideRenewalCommission) {
                    this.config.renewalCommissionOverride = this.config.renewalCommissionOverride * 100;
                }

                // Set each rate override condition question ui-select search dropdown
                this.config.rateOverrides.map(rateOverride => {
                    rateOverride.conditions.map(condition => {
                        const searchControl = new SearchControl();
                        
                        if (condition.conditionType == RuleConditionType.UnderwritingQuestion) {
                            searchControl.selected = {
                                id: condition.masterApplicationQuestionId,
                                question: condition.questionText,
                            };
                        }

                        condition['questionSearch'] = searchControl;
                    });
                });

                // Set attachment condition question ui-select search dropdown
                this.config.bondFormAttachments.map(attachment => {
                    attachment.conditions.map(condition => {
                        const searchControl = new SearchControl();
                        
                        searchControl.selected = {
                            id: condition.masterApplicationQuestionId,
                            question: condition.questionText,
                        };
                    
                        condition['questionSearch'] = searchControl;
                    });
                });
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred loading this underwriting configuration');
            });
    }

    public setRateOverrideConditionMasterApplicationQuestion(condition: UnderwritingConfigurationRateOverrideCondition, masterApplicationQuestion: MasterApplicationQuestion): void {
        condition.masterApplicationQuestionId = masterApplicationQuestion.id;
        condition.questionType = masterApplicationQuestion.type;
        condition.options = masterApplicationQuestion.masterApplicationQuestionChoices; 
    }

    public save(): void {
        this.config.rateOverrides.map((rateOverride, index) => {
            rateOverride.sequence = index;
        });

        if (!this.overrideRate) {
            this.config.rateOverrides.map(rate => rate.isRemoved = true);
        }

        if (!this.indemnityRequired) {
            this.config.indemnityRequirements = IndemnityRequirements.None;
        }

        if (!this.overrideCommission) {
            this.config.commissionOverride = null;
        } else {
            this.config.commissionOverride = this.config.commissionOverride * 0.01;
        }

        if (!this.overrideRenewalCommission) {
            this.config.renewalCommissionOverride = null;
        } else {
            this.config.renewalCommissionOverride = this.config.renewalCommissionOverride * 0.01;
        }

        if (!this.experienceRequired) {
            this.config.minimumYearsInBusiness = null;
        }

        if (!this.hasUnderwritingQuestions) {
            this.config.underwritingQuestions.map(question => question.isRemoved = true);
        }

        if (!this.overrideMinimumPremium) {
            this.config.minimumPremium = null;
        }        

        if (!this.hasBondFormAttachments) {
            this.config.bondFormAttachments.map(attachment => attachment.isRemoved = true);
        }

        
        this.busyIndicator.message = "Saving...";
        this.busyIndicator.promise = this.underwritingConfiguraitonService.saveUnderwritingConfiguration(this.config)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage("Underwriting configuration saved successfully");
                this.$state.go("main.underwritingConfigurationGrid");
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage("An error occurred while saving the underwriting configuration");
            });
    }

    public $onInit(): void {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.documentSearch = new SearchControl();
        this.bondTypeSearch = new SearchControl();
        this.questionSearch = new SearchControl();

        this.quoteStatusOptions = [{
                label: "Pending",
                value: "Pending"
            }, {
                label: "Declined",
                value: "Declined"
            }, {
                label: "Approved",
                value: "Approved"
        }];

        const promises = [
            this.loadBondNumberGroups(),
            this.loadWritingCompanies(),
            this.loadRequiredFieldSets(),
            this.loadRates()
        ];        

        if (this.$stateParams.id) {
            this.busyIndicator.promise = this.loadUnderwritingConfiguration(this.$stateParams.id)
                .then(() => {
                    return this.$q.all(promises)
                });
        } else {
            this.busyIndicator.promise = this.$q.all(promises);
            this.config = {
                isActive: true,
                indemnityRequirements: IndemnityRequirements.None,
                underwritingQuestions: [],
                rateOverrides: [],
                bondFormAttachments: []
            } as UnderwritingConfigurationDetail;
        }
    }
}

export interface UnderwritingConfigurationDetailParams {
    id: number;
}

export interface UnderwritingConfigurationDetail {
    id: number;
    isActive: boolean;
    bondTypeId: number;
    bondTypeName: number;
    requiresCreditReport: number;
    minimumFico: number;
    maximumNrs: number;
    minimumYearsInBusiness: number;
    term: number;
    rateId: number;
    systemAccountId: number;
    indemnityRequirements: IndemnityRequirements;
    passStatus: number;
    failStatus: number;
    writingCompanyId: number;
    bondNumberGroupId: number;
    highlightComments: number;
    requiredFieldSetId: number;
    createdDateTime: number;
    minimumPremium: number;
    maximumBondAmount: number;
    minimumBondAmount: number;
    minimumVantage: number;
    commissionOverride: number;
    renewalCommissionOverride: number;
    underwritingQuestions: UnderwritingConfigurationQuestion[];
    rateOverrides: UnderwritingConfigurationRateOverride[];
    bondFormAttachments: UnderwritingConfigurationBondFormAttachment[];
}

export interface UnderwritingConfigurationQuestion {
    id?: number;
    underwritingConfigurationId?: number;
    masterApplicationQuestionId: number;
    requiredValue: string;
    isRequired: boolean;
    questionText: string;
    questionName: string;
    questionType: QuestionType;
    options: MasterApplicationQuestionChoice[];
    isRemoved?: boolean;
}

export interface UnderwritingConfigurationRateOverride {
    id?: number;
    underwritingConfigurationId?: number;
    rateId?: number;
    sequence?: number;
    isRemoved?: boolean;
    conditions: UnderwritingConfigurationRateOverrideCondition[];
}

export interface UnderwritingConfigurationRateOverrideCondition {
    id?: number;
    requiredValue: string;
    conditionType?: RuleConditionType;
    questionType?: QuestionType;
    masterApplicationQuestionId?: number;
    options: MasterApplicationQuestionChoice[];
    minimumBondAmount?: number;
    maximumBondAmount?: number;
    questionText?: string;
    isRemoved?: boolean;
}

export interface UnderwritingConfigurationBondFormAttachment {
    id?: number;
    underwritingConfigurationId?: number;
    documentId?: number;
    conditions: UnderwritingConfigurationBondFormAttachmentCondition[];
    documentName: string;
    isRemoved?: boolean;
}

export interface UnderwritingConfigurationBondFormAttachmentCondition {
    id?: number;
    requiredValue?: string;
    questionText?: string;
    questionType?: QuestionType;
    masterApplicationQuestionId?: number;
    options: MasterApplicationQuestionChoice[];
    isRemoved?: boolean;
}
