import { SelectOption } from "../../../../api/types/selectOption";
import { Injectables } from "../../../../configuration/injectables";
import app from "../../../../main";
import { QueryBuilderTableQueryOptions } from "../../../../api/types/queryBuilderTableOptions";
import { SearchType } from "../../../../api/types/searchType";
import { TagService } from "../../../../api/tagService";
import { QueryBuilderTable } from "../../../../utilities/tables/queryBuilderTable";
import { ApplicationReportItem } from "../applicationReportItem";

class ApplicationReportFilterDropdownController {
    
    public static $inject = [
        Injectables.TagService   
    ];

    constructor(
            private readonly tagService: TagService
        ) {
            this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: QueryBuilderTableQueryOptions;
    public tagOptions: SelectOption<string>[];
    public table: QueryBuilderTable<ApplicationReportItem>; 

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }

    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    private async loadTagOptions() {
        this.tagOptions = await this.tagService.loadTagOptions();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    }

    public $onInit() {
        this.loadTagOptions();
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
        
    }
}

const applicationReportFilterDropdownComponent = {
    bindings: {
        table: "<"
    },
    controller: ApplicationReportFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/agentSpecific/applicationReport/applicationReportFilterDropdown/applicationReportFilterDropdown.html"
};

app.component(
    "applicationReportFilterDropdown",
    applicationReportFilterDropdownComponent
);
