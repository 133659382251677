export class TableSorter {

    private _sortBy: string;
    public get sortBy(): string {
        return this._sortBy;
    }
    public set sortBy(val: string) {
        this._sortBy = val;
    }

    public onSortChanged: () => void;

    public sort(newOrderBy: string) {
        if (!this.sortBy) {
            this._sortBy = newOrderBy;
        } else if (
            this.sortBy.indexOf(' desc') > 0 &&
            this.sortBy.replace(' desc', '') == newOrderBy) {

            this._sortBy = newOrderBy;
        } else {
            this._sortBy = newOrderBy + ' desc';
        }

        if (typeof this.onSortChanged === 'function') {
            this.onSortChanged();
        }
    }

    public isSortedByDesc(sortExpression: string) {
        return this.sortBy == sortExpression;
    }

    public isSortedBy(sortExpression: string) {
        return this.sortBy == sortExpression + ' desc';
    }
}