import { CustomerBalances } from "../components/customerBalanceCard/customerBalances";
import { CustomerSearchResult } from "../components/customerSearchDropdown/customerSearchResult";
import { Injectables } from "../configuration/injectables";
import { CustomerRelationships } from "../modals/relatedCustomersModal/customerRelationships";
import { DefaultParentCustomerRelationship } from "../modals/relatedCustomersModal/defaultParentCustomerRelationship";
import { ODataFactory, ODataEndpoint } from "./odata";
import { Attachment } from "./types/attachment";
import { BondPrincipal } from "./types/bondPrincipal";
import { CustomerDetail } from "./types/customerDetail";
import { CustomerDetailSummary } from "./types/customerDetailSummary";
import { IntegrationType } from "./types/integrationType";
import { Company } from "./types/model/company";
import { Customer } from "./types/model/customer";
import { Person } from "./types/model/person";
import { SystemAccount } from "./types/model/systemAccount";
import { NewCustomer } from "./types/newCustomer";
import { PageResponse } from "./types/pageResponse";
import { RemoveEProducerAcountFromCustomerDto } from "./types/removeEProducerAccountFromCustomerDto";
import { SearchResultMatch } from "./types/searchResultMatch";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { ServicingRoleUserIds } from "./types/servicingRoleUserIds";
import * as angular from "angular";
import { IQService, IHttpService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { CustomerOrEproducerBrokerSelectionOption } from "../components/customerOrEproducerBrokerSelection/customerOrEproducerBrokerSelection";
import { PaymentContact } from "./types/paymentContact";
import { FileDownloader } from "./fileDownloader";
import QueryConditionSet from "../components/queryBuilder/queryConditionSet";
import { SearchType } from "./types/searchType";
import CustomerListItem from "../states/agentSpecific/customers/customerListItem";
import CustomerTableQueryOptions from "../states/agentSpecific/customers/customerTableQueryOptions";
import { SaveCustomerContactResponse } from "../components/customerContactList/saveCustomerContactResponse";
import { BrokerCardData } from "../components/brokerInfoCard/brokerCardData";

export class CustomersService {
    
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly odata: ODataFactory,
        readonly $q: IQService,
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {}

    public openApplicationFilter =
        "status eq 'Submitted' or status eq 'NotSubmitted'";

    public getCustomerBalances(customerId: number): IPromise<CustomerBalances> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerBalances?customerId=${customerId}`;

        return this.$http
            .get<A3ApiResponse<CustomerBalances>>(url)
            .then((response) => response.data.value);
    }

    public getPaymentContacts(customerId: number, eProducerAccountId: number): IPromise<PaymentContact[]> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerPaymentContacts?customerId=${customerId}&eProducerAccountId=${eProducerAccountId}`;
        
        return this.$http
            .get<A3ApiResponse<PaymentContact[]>>(url)
            .then((response) => response.data.value);
    }

    public disconnectFromEpic(customerId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/DisconnectFromEpic",
                { customerId: customerId }
            )
            .then(() => {});
    }

    public getCustomersLookupCode(customerId: number): IPromise<string> {
        return this.$http
            .get<A3ApiResponse<string>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/GetCustomersLookupCode?customerId=" +
                    customerId
            )
            .then((response) => {
                return response.data.value;
            });
    }

    public searchCustomersOrEproducerBrokers = (searchPhrase: string) => {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/SearchCustomersOrBrokers?searchPhrase=${searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<CustomerOrEproducerBrokerSelectionOption[]>>(url)
            .then((response) => response.data.value);
    }

    public processEpicCustomerConnectionRequest(lookupCode: string, customerId: number): IPromise<any> {
        lookupCode = lookupCode.replace(/&/g, "%26");
        const url = `${this.systemSettings.apiBaseUrl}EpicActions/ProcesssEpicCustomerConnectionRequest?lookupCode=${lookupCode}&customerId=${customerId}`
        return this.$http
            .get<A3ApiResponse<any>>(url)
            .then((response) =>  response.data.value);
    }

    public loadCustomerServicingRoleUserIds(customerId: number): IPromise<ServicingRoleUserIds> {
        
        return this.$http
            .get<A3ApiResponse<ServicingRoleUserIds>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/GetServicingRoleUserIds?customerId=" +
                    customerId
            )
            .then((response) => {
                return response.data.value;
            });
    }

    public loadDefaultPrincipal(customerId: number): IPromise<BondPrincipal> {
        return this.$http
            .get<A3ApiResponse<BondPrincipal>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/DefaultPrincipal?customerId=" +
                    customerId
            )
            .then((response) => {
                return response.data.value;
            });
    }

    public exportToEpic(customerId: number): IPromise<string> {
        return this.$http
            .post<A3ApiResponse<string>>(`${this.systemSettings.apiBaseUrl}EpicActions/ExportCustomer`, { customerId: customerId })
            .then((response) => {
                return response.data.value;
            });
    }

    public getAttachmentsByCustomerId(
        customerId: number
    ): IPromise<Attachment[]> {
        const svc = this.odata.getService<Customer>(ODataEndpoint.Customer);

        svc.query.expand(
            "attachments($expand=visibleToSystemAccount($select=id,companyName))"
        );
        svc.query.filter(`id eq ${customerId}`);
        svc.query.select("attachments");

        return svc.get().then((response) => response.data.value[0].attachments);
    }

    public getCustomerForCustomerDetail(id: number): IPromise<CustomerDetail> {
        return this.$http
            .get<A3ApiResponse<CustomerDetail>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/LoadCustomerDetail?customerId=" +
                    id
            )
            .then((response) => response.data.value);
    }

    public activateCustomer(customerId: number): IPromise<void> {
        return this.$http
            .put<void>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/Activate?customerId=" +
                    customerId,
                {}
            )
            .then(() => {});
    }

    public archiveCustomer(customerId: number): IPromise<void> {
        return this.$http
            .put<void>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/Archive?customerId=" +
                    customerId,
                {}
            )
            .then(() => {});
    }

    public createNewCustomer(newCustomer: NewCustomer): IPromise<number> {
        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/CreateNewCustomer",
                newCustomer
            )
            .then((response) => response.data.value);
    }

    public saveCustomerDetailSummary(
        summary: CustomerDetailSummary
    ): IPromise<void> {
        return this.$http
            .put<void>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/CustomerDetailSummary",
                summary
            )
            .then(() => {});
    }

    public saveCustomerContact(contact: Person): IPromise<SaveCustomerContactResponse> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/SaveContact`;
    
        return this.$http.post<A3ApiResponse<SaveCustomerContactResponse>>(url, contact)
            .then(response => response.data.value);
    }

    public deleteContact(id: number): IPromise<void> {
        return this.$http
            .delete(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/DeleteContact?id=" +
                    id
            )
            .then(() => {});
    }

    public saveCompany(company: Company): IPromise<number> {
        return this.$http
            .post<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl + "CustomerActions/SaveCompany",
                company
            )
            .then((response) => response.data.value);
    }

    public deleteCompany(customerId: number): IPromise<void> {
        return this.$http
            .delete(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/DeleteCompany?customerId=" +
                    customerId
            )
            .then(() => {});
    }

    public removeEProducerAccount(customerId: number): IPromise<void> {
        const request: RemoveEProducerAcountFromCustomerDto = {
            customerId: customerId
        };

        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "EProducerActions/RemoveEProducerAcountFromCustomer",
                request
            )
            .then(() => {});
    }

    public searchForDropdown = (
        search: string
    ): IPromise<CustomerSearchResult[]> => {
        search = search.replace(/&/g, "%26");

        return this.$http
            .get<A3ApiResponse<CustomerSearchResult[]>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/SearchForDropdown?searchPhrase=" +
                    search
            )
            .then((response) => response.data.value);
    };

    public search(search: string): IPromise<SearchResultMatch[]> {
        search = search.replace(/&/g, "%26");

        return this.$http
            .get<A3ApiResponse<SearchResultMatch[]>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/Search" +
                    "?search=" +
                    search
            )
            .then((response) => response.data.value);
    }

    public searchA3Customers(
        name: string,
        phone: string,
        email: string,
        maxRecords: number
    ): IPromise<Customer[]> {
        maxRecords = maxRecords || 10;

        if (!name && !phone && !email) {
            return this.$q.when([] as Customer[]);
        }

        const svc = this.odata.getService<Customer>(ODataEndpoint.Customer);

        svc.query.expand("companies,people($filter=includeOnBonds eq true)");
        svc.query.top(maxRecords);

        if (name) {
            name = name.replace(/&/g, "%26");

            svc.query.and(
                `contains(name,'${name}') or '${name}' eq lookupCode or people/any(p: contains(concat(concat(p/firstName,' '),p/lastName),'${name}')) or companies/any(c: contains(c/name,'${name}'))${
                    !isNaN(+name) && angular.isNumber(+name)
                        ? ` or id eq ${name}`
                        : ""
                }`
            );
        }
        if (phone) {
            svc.query.and(
                `people/any(p: contains(p/homePhone,'${phone}')) or people/any(p: contains(p/cellPhone, '${phone}')) or companies/any(c: contains(c/phone, '${phone}'))`
            );
        }
        if (email) {
            svc.query.and(
                `people/any(p: contains(p/email,'${email}')) or companies/any(c: contains(c/email, '${email}'))`
            );
        }

        return svc.get().then((response) => response.data.value);
    }

    public searchAllCustomers(
        name: string,
        phone: string,
        email: string,
        maxRecords: number,
        systemAccount: SystemAccount
    ): IPromise<Customer[]> {
        const promises = [
            this.searchA3Customers(name, phone, email, maxRecords),
            this.searchAms360Customers(name, systemAccount)
        ];

        return this.$q.all(promises).then((customers) => {
            const a3Customers = customers[0] as Customer[];
            const ams360Customers = customers[1] as Customer[];
            let allCustomers = [] as Customer[];

            if (a3Customers) {
                this.stampIntegration(a3Customers, IntegrationType.A3);
                allCustomers = allCustomers.concat(a3Customers);
            }

            if (ams360Customers) {
                this.stampIntegration(ams360Customers, IntegrationType.Ams360);
                allCustomers = allCustomers.concat(ams360Customers);
            }

            return allCustomers;
        });
    }

    public searchAms360Customers(
        search: string,
        systemAccount: SystemAccount
    ): IPromise<Customer[]> {
        if (!search) {
            this.$q.when(null as Customer[]);
        }

        if (search.length < 3 || !systemAccount.ams360IntegrationIsActive) {
            return this.$q.when([] as Customer[]);
        }

        return this.$http
            .get<A3ApiResponse<Customer[]>>(
                this.systemSettings.apiBaseUrl +
                    "CustomerActions/SearchAms360Customers?searchText=" +
                    search
            )
            .then((response) => response.data.value);
    }

    public stampIntegration(
        customers: Customer[],
        integrationType: IntegrationType
    ): void {
        for (const customer of customers) {
            customer.$integrationType = integrationType;
        }
    }

    public downloadCustomerEntriesExcelReport(tableQueryOptions: CustomerTableQueryOptions, columnNames: string[]): IPromise<PageResponse<CustomerListItem>> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerListExcelReport`;

        return this.fileDownloader.downloadFile(url, { ...tableQueryOptions, columnNames: columnNames } );
    }

    public getCustomerListDataWithQueryBuilder(tableQueryOptions: CustomerTableQueryOptions) {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerListDataFromQuery`;

        return this.$http.post<A3ApiResponse<PageResponse<CustomerListItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public loadCustomerRelationships(customerId: number): IPromise<CustomerRelationships> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerRelationships?customerId=${customerId}`;
        
        return this.$http.get<A3ApiResponse<CustomerRelationships>>(url)
            .then((response) => response.data.value);
    }

    public saveCustomerRelationships(customerRelationships: CustomerRelationships): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/SaveCustomerRelationships`;

        return this.$http.post(url, customerRelationships)
            .then(() => {});
    }

    public getCustomerDefaultParentRelationship(customerId: number): IPromise<DefaultParentCustomerRelationship> {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetCustomerDefaultParentRelationship?customerId=${customerId}`;
        
        return this.$http.get<A3ApiResponse<DefaultParentCustomerRelationship>>(url)
            .then((response) => response.data.value);
    }

    public getBroker(customerId: number) {
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/GetBroker?customerId=${customerId}`;
        
        return this.$http.get<A3ApiResponse<BrokerCardData>>(url)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.CustomersService, CustomersService);
