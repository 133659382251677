import { EProducerService } from "../../../api/eProducerService";
import { QueryBuilderTableQueryOptions } from "../../../api/types/queryBuilderTableOptions";
import { SearchType } from "../../../api/types/searchType";
import { SelectOption } from "../../../api/types/selectOption";
import { Injectables } from "../../../configuration/injectables";
import app from "../../../main";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import { EProducerAccountListItem } from "./eProducerAccountListItem";

class EProducerAccountsFilterDropdownController {
    public static $inject = [Injectables.EProducerService];

    constructor(private readonly eProducerService: EProducerService) {
        this.modifiedFilters = {} as QueryBuilderTableQueryOptions;
    }

    public isOpen: boolean;
    public filterCount = 0;
    public modifiedFilters: QueryBuilderTableQueryOptions;
    public brokerCategoryOptions: SelectOption<number>[];

    public table: QueryBuilderTable<EProducerAccountListItem>;

    public setSearchType = (searchType: SearchType) => {
        this.modifiedFilters.searchType = searchType;
    }

    public clearFilters() {
        this.table.clearFilters();
        this.isOpen = false;
    }

    public cancel() {
        this.isOpen = false;
        this.setModifiedFilters();
    }

    public applyFilters() {
        this.isOpen = false;

        this.table.applyFilters(this.modifiedFilters.searchType, this.modifiedFilters.basicFilters, this.modifiedFilters.conditionSet);
        this.setFilterCount();
    }

    public setFilterCount() {
        this.filterCount = this.table.getFilterCount();
    }

    private async getBrokerCategoryTypeOptions(): Promise<void> {
        this.brokerCategoryOptions = await this.eProducerService.getBrokerCateogryTypeOptions();
    }

    private setModifiedFilters = () => {
        this.modifiedFilters = this.table.getQueryOptions();
        this.setFilterCount();
    }

    public $onInit() {
        this.setModifiedFilters();
        this.table.onFilterChange = this.setModifiedFilters;
        this.getBrokerCategoryTypeOptions();
    }
}

const EProducerAccountsFilterDropdown = {
    bindings: {
        table: "<"
    },
    controller: EProducerAccountsFilterDropdownController,
    controllerAs: "vm",
    templateUrl: "app/states/agentSpecific/eProducerBrokers/eProducerAccountsFilterDropdown.html"
};

app.component("eproducerAccountsFilterDropdown", EProducerAccountsFilterDropdown);
