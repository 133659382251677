export default (
    element: JQuery, 
    labelText: string, 
    isRequired?: boolean
) => {
    element
        .parent()
        .parent()
        .find('span.toggle-switch-label')
        .append(labelText);
}