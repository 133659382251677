import { SearchType } from "../../api/types/searchType";
import { UserService } from "../../api/userService";
import { ModalOpener } from "../../modals/modalOpener";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import deepCopy from "../../utilities/immutable/deepCopy";
import { QueryBuilderTable } from "../../utilities/tables/queryBuilderTable"
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import DataViewType from "../dataViews/dataViewType";
import TableColumn from "../dataViews/tableColumn";
import QueryBuilderLogicType from "../queryBuilder/queryBuilderLogicType";
import QueryConditionPropertyOption from "../queryBuilder/queryConditionPropertyOption";
import QueryConditionPropertyType from "../queryBuilder/queryConditionPropertyType";
import BillingEntriesListItem from "./billingEntriesListItem";
import DataView from "../dataViews/dataView";
import { IPromise } from "angular";
import QueryConditionPropertyGroup from "../queryBuilder/queryConditionPropertyGroup";

export default class BillingEntriesListTable extends QueryBuilderTable<BillingEntriesListItem> {

    constructor(
        loadFunction: () => IPromise<void>,
        userService: UserService,
        toastMessageCreator: ToastMessageCreator,
        debouceDelayer: IDebounceDelayer,
        modalOpener: ModalOpener
    ) {

        const columnOptions: TableColumn<any>[] = [{
            name: 'Id',
            heading: 'Id',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BillingEntries.Id',
            index: 0
        },{
            name: 'BilledTo',
            heading: 'Billed To',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: true,
            index: 0
        },{
            name: 'Customer',
            heading: 'Customer',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: false,
            sortExpression: 'Customers.Name',
            index: 0
        },{
            name: 'CustomerLookupCode',
            heading: 'Customer Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'Customers.LookupCode',
            index: 0
        },{
            name: 'Broker',
            heading: 'Broker',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: false,
            sortExpression: 'EProducerAccounts.Name',
            index: 0
        },{
            name: 'BrokerLookupCode',
            heading: 'Broker Lookup Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'EProducerAccounts.LookupCode',
            index: 0
        },{
            name: 'CarrierLogo',
            heading: '',
            label: 'Carrier Logo',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            index: 0
        },{
            name: 'Carrier',
            heading: 'Carrier',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: false,
            sortExpression: 'CarrierSystemAccount.CompanyName',
            index: 0
        },{
            name: 'WritingCompany',
            heading: 'Writing Company',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: false,
            sortExpression: 'WritingCompanies.Name',
            index: 0
        },{
            name: 'EffectiveDate',
            heading: 'Effective Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BondTransactions.EffectiveDate',
            index: 0
        },{
            name: 'ExpirationDate',
            heading: 'Expiration Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BondTransactions.ExpirationDate',
            index: 0
        },{
            name: 'TransactionEffectiveDate',
            heading: 'Transaction Effective Date',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BondTransactions.TransactionEffectiveDate',
            index: 0
        },{
            name: 'Producer',
            heading: 'Producer',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ProducerUser.FullName',
            index: 0
        },{
            name: 'ClientServiceManager',
            heading: 'CSM',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceManagerUser.FullName',
            index: 0
        },{
            name: 'ClientServiceExecutive',
            heading: 'CSE',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceExecutiveUser.FullName',
            index: 0
        },{
            name: 'ClientServiceAssociate',
            heading: 'CSA',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'ClientServiceAssociateUser.FullName',
            index: 0
        },{
            name: 'ProductionCredit',
            heading: 'Credit Producer',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.ProductionCredit',
            index: 0
        },{
            name: 'BondNumber',
            heading: 'Bond Number',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BondTransactions.BondNumber',
            index: 0
        },{
            name: 'BillingEntryType',
            heading: 'Type',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.BillingEntryType',
            index: 0
        },{
            name: 'Description',
            heading: 'Description',
            headingClass: 'text-left',
            cellClass: 'text-left',
            isVisible: false,
            sortExpression: 'BillingEntries.Description',
            index: 0
        },{
            name: 'TransactionType',
            heading: 'Transaction Type',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            index: 0
        },{
            name: 'CreatedDateTime',
            heading: 'Created Date/Time',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.CreatedDateTime',
            index: 0
        },{
            name: 'Code',
            heading: 'Code',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BillingEntries.Code',
            index: 0
        },{
            name: 'IsOriginalEntry',
            heading: 'Original Entry',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.IsOriginalEntry',
            index: 0
        },{
            name: 'Invoices',
            heading: 'Invoices',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            index: 0
        },{
            name: 'BillToType',
            heading: 'Bill To Type',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            index: 0
        },{
            name: 'OriginalAmount',
            heading: 'Billed Amount',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BillingEntries.OriginalAmount',
            index: 0
        },{
            name: 'AmountReceived',
            heading: 'Amount Received',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.AmountReceived',
            index: 0
        },{
            name: 'AmountDue',
            heading: 'Amount Due',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BillingEntries.AmountDue',
            index: 0
        },{
            name: 'BrokerCommission',
            heading: 'Broker Commission',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.BrokerCommission',
            index: 0
        },{
            name: 'IsProductionCreditPaid',
            heading: 'Production Credit Paid',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.IsProductionCreditPaid',
            index: 0
        },{
            name: 'CarrierPayableOriginalAmount',
            heading: 'Carrier Original A/P',
            tooltip: 'Carrier Payable Original Amount',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: false,
            sortExpression: 'BillingEntries.CarrierPayableOriginalAmount',
            index: 0
        },{
            name: 'CarrierPayableAmountDue',
            heading: 'Carrier A/P Balance',
            tooltip: 'Carrier Payable Balance Due',
            headingClass: 'fit-content',
            cellClass: 'fit-content text-right',
            isVisible: false,
            sortExpression: 'BillingEntries.CarrierPayableAmountDue',
            index: 0
        },{
            name: 'BrokerPayableAmountDue',
            heading: 'Broker A/P Balance',
            tooltip: 'Broker Payable Balance Due',
            headingClass: 'fit-content',
            cellClass: 'fit-content text-right',
            isVisible: false,
            sortExpression: 'BillingEntries.BrokerPayableAmountDue',
            index: 0
        },{
            name: 'Commission',
            heading: 'Commission',
            headingClass: 'fit-content',
            cellClass: 'fit-content',
            isVisible: true,
            sortExpression: 'BillingEntries.Commission',
            index: 0
        }];
       
        const conditionPropertyGroups: QueryConditionPropertyGroup[] = [
            {
                label: 'General',
                options: [
                    { label: 'Id', value: 'BillingEntries.Id', type: QueryConditionPropertyType.Integer },
                    { label: 'Carrier', value: 'CarrierSystemAccount.CompanyName', type: QueryConditionPropertyType.String },
                    { label: 'Writing Company', value: 'WritingCompanies.Name', type: QueryConditionPropertyType.String },
                    { label: 'Effective Date', value: 'BondTransactions.EffectiveDate', type: QueryConditionPropertyType.Date },
                    { label: 'Expiration Date', value: 'BondTransactions.ExpirationDate', type: QueryConditionPropertyType.Date },
                    { label: 'Transaction Effective Date', value: 'BondTransactions.TransactionEffectiveDate', type: QueryConditionPropertyType.Date },
                    { label: 'Bond Number', value: 'BondTransactions.BondNumber', type: QueryConditionPropertyType.String },
                    { label: 'Type', value: 'BillingEntries.BillingEntryType', type: QueryConditionPropertyType.BillingEntryType },
                    { label: 'Description', value: 'BillingEntries.Description', type: QueryConditionPropertyType.String },
                    { label: 'Created Date/Time', value: 'BillingEntries.CreatedDateTime', type: QueryConditionPropertyType.Date },
                    { label: 'Code', value: 'BillingEntries.Code', type: QueryConditionPropertyType.String },
                    { label: 'Is Original Entry', value: 'BillingEntries.IsOriginalEntry', type: QueryConditionPropertyType.Boolean },
                    { label: 'Original Amount', value: 'BillingEntries.OriginalAmount', type: QueryConditionPropertyType.Money },
                    { label: 'Amount Received', value: 'BillingEntries.AmountReceived', type: QueryConditionPropertyType.Money },
                    { label: 'Amount Due', value: 'BillingEntries.AmountDue', type: QueryConditionPropertyType.Money },
                    { label: 'Credit Producer', value: 'BillingEntries.ProductionCredit', type: QueryConditionPropertyType.Boolean },
                    { label: 'Production Credit Paid', value: 'BillingEntries.IsProductionCreditPaid', type: QueryConditionPropertyType.Boolean },
                    { label: 'Carrier Original A/P', value: 'BillingEntries.CarrierPayableOriginalAmount', type: QueryConditionPropertyType.Money },
                    { label: 'Carrier A/P Balance', value: 'BillingEntries.CarrierPayableAmountDue', type: QueryConditionPropertyType.Money },
                    { label: 'Broker A/P Balance', value: 'BillingEntries.BrokerPayableAmountDue', type: QueryConditionPropertyType.Money },
                    { label: 'Bill To Type', value: 'BillingEntries.BillToType', type: QueryConditionPropertyType.BillToType },
                    { label: 'Transaction Type', value: 'BondTransactions.TransactionType', type: QueryConditionPropertyType.TransactionType },
                    { label: 'Commission', value: 'BillingEntries.Commission', type: QueryConditionPropertyType.Money },
                    { label: 'Invoice Number', value: 'Invoices.InvoiceNumber', type: QueryConditionPropertyType.Integer }
                ]
            },
            {
                label: 'Customer',
                options: [
                    { label: 'Customer', value: 'Customers.Name', type: QueryConditionPropertyType.String },
                    { label: 'Customer Lookup Code', value: 'Customers.LookupCode', type: QueryConditionPropertyType.String },
                ]
            },
            {
                label: 'Broker',
                options: [
                    { label: 'Broker', value: 'EProducerAccounts.Name', type: QueryConditionPropertyType.String },
                    { label: 'Broker Lookup Code', value: 'EProducerAccounts.LookupCode', type: QueryConditionPropertyType.String },        
                    { label: 'Broker Commission', value: 'BillingEntries.BrokerCommission', type: QueryConditionPropertyType.Money },
                ]
            }, 
            {
                label: 'Servicing Roles',
                options: [
                    { label: 'Producer', value: 'BillingEntries.ProducerUserId', type: QueryConditionPropertyType.Producer },
                    { label: 'CSM', value: 'Bonds.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager },
                    { label: 'CSE', value: 'Bonds.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive },
                    { label: 'CSA', value: 'Bonds.ClientServiceAgentUserId', type: QueryConditionPropertyType.ClientServiceAssociate },
                ]
            },
        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.BillingEntriesList,
            recordsPerPage: 10,
            sortExpression: 'BillingEntries.Id DESC',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
            },
            searchType: SearchType.Basic
        }];
        
        super(loadFunction,
            debouceDelayer,
            DataViewType.BillingEntriesList,
            conditionPropertyGroups,
            columnOptions,
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener,
            true
        );
    }

    public customerId: number;
    public eProducerAccountId: number;

    public getQueryOptions() {
        let queryOptions = super.getQueryOptions();

        queryOptions.basicFilters.customerId = this.customerId;
        queryOptions.basicFilters.eProducerAccountId = this.eProducerAccountId;

        return queryOptions;
    }
}