import app from "../../main";
import QueryBuilderLogicType from "./queryBuilderLogicType";
import QueryCondition from "./queryCondition";
import QueryConditionPropertyGroup from "./queryConditionPropertyGroup";
import QueryConditionPropertyOption from "./queryConditionPropertyOption";
import QueryConditionSet from './queryConditionSet';

export class QueryBuilderConditionSetController {
    public static $inject = [
    ];

    constructor() { }

    public isChild: boolean;
    public conditionSet: QueryConditionSet;
    public propertyOptionGroups: QueryConditionPropertyGroup[];
    public onRemoved?: () => {};
    public onChanged?: () => {};

    public addConditionSet = ($event) => {
        $event.stopPropagation();
        this.conditionSet.conditionSets.push({
            logicType: QueryBuilderLogicType.And,
            conditions: [],
            conditionSets: []
        } as QueryConditionSet);
        
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public childConditionSetRemoved = (index: number) => {
        this.conditionSet.conditionSets.splice(index, 1);
        
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public childConditionSetChanged = () => {
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public removeConditionSetClicked = ($event) => {
        $event.stopPropagation();

        if (this.onRemoved instanceof Function) {
            this.onRemoved();
        }

        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public setLogicType = (logicType: QueryBuilderLogicType, $event) => {
        $event.stopPropagation();
        this.conditionSet.logicType = logicType;
        
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public addCondition = ($event) => {
        $event.stopPropagation();
        this.conditionSet.conditions.push({
            values: []
        } as QueryCondition);
        
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public conditionRemoved = (index: number) => {
        this.conditionSet.conditions.splice(index, 1);

        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public queryConditionChanged = () => {
        if (this.onChanged instanceof Function) {
            this.onChanged();
        }
    }

    public $onInit() {
    }
}

const queryBuilderConditionSetComponent = {
    bindings: {
        conditionSet: '=',
        propertyOptionGroups: '<',
        isChild: '<',
        onRemoved: '&?',
        onChanged: '&?'
    },
    templateUrl: "app/components/queryBuilder/queryBuilderConditionSet.html",
    controller: QueryBuilderConditionSetController,
    controllerAs: "vm"
};

app.component("queryBuilderConditionSet", queryBuilderConditionSetComponent);