import { UserForDropdown } from "../components/userDropdown/userForDropdown";
import { UserSearchResult } from "../components/userSearchDropdown/userSearchResult";
import { Injectables } from "../configuration/injectables";
import { UserDetail } from "../states/common/userDetail/userDetail";
import { UserTableItem } from "../states/common/users/userTableItem";
import { UserTableQueryOptions } from "../states/common/users/userTableQueryOptions";
import { UtilityService } from "../utilities/utilityService";
import { AuthService } from "./authService";
import { ODataFactory, ODataEndpoint } from "./odata";
import { User } from "./types/model/user";
import { PageResponse } from "./types/pageResponse";
import { SelectOption } from "./types/selectOption";
import { SetIsSideMenuCollapsed } from "./types/setIsSideMenuCollapsed";
import { SetThemeDto } from "./types/setThemeDto";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import * as angular from "angular";
import { IHttpService, IPromise, IQService } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { UserForDropdownRequestOptions } from "../components/userDropdown/userForDropdownRequestOptions";
import { CacheStore } from "./cacheStore";
import { FileDownloader } from "./fileDownloader";
import DataView from '../components/dataViews/dataView';
import { CurrentUserResolver } from "../utilities/currentUserResolver/currentUserResolver";
import DataViewType from "../components/dataViews/dataViewType";
import PersistedUserDataView from "../components/dataViews/persistedUserDataView";
import PersistedUserDataViewColumn from "../components/dataViews/persistedUserDataViewColumn";
import SharedDataViewOption from "../components/dataViews/sharedDataViewOption";

export class UserService {

    public static $inject = [
        Injectables.ODataFactory, 
        Injectables.$http, 
        Injectables.AuthService, 
        Injectables.UtilityService,
        Injectables.SystemSettings,
        Injectables.CacheStore,
        Injectables.FileDownloader,
        Injectables.CurrentUserResolver
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly $http: IHttpService,
        private readonly authService: AuthService,
        private readonly utilityService: UtilityService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore,
        private readonly fileDownloader: FileDownloader,
        private readonly currentUserResolver: CurrentUserResolver) {
    }

    public updateViewSharingOption(dataViewId: number, isShared: boolean) {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/UpdateViewSharingOption`;
        
        return this.$http.post(url, { dataViewId, isShared })
            .then(() => {});
    }

    public copySharedView(dataViewId: number) {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/CopySharedView`;
        
        return this.$http.post(url, dataViewId)
            .then(() => {});
    }

    public loadSharedViews(dataViewType: DataViewType) {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/GetSharedDataViewOptions?dataViewType=${dataViewType}`;
        
        return this.$http.get<A3ApiResponse<SharedDataViewOption[]>>(url)
            .then((response) => response.data.value);
    }

    public deleteDataView(dataViewId: number) {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/DeleteUserDataView`;
        
        return this.$http.post(url, dataViewId)
            .then(() => {});
    }

    public getDataViews(dataViewType: DataViewType): IPromise<DataView[]> {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/GetUserDataView?userId=${this.currentUserResolver.getCurrentUser().user.userId}&dataViewType=${dataViewType}`
        
        return this.$http.get<A3ApiResponse<PersistedUserDataView[]>>(url)
            .then((response) => {
                return response.data.value.map(dataView => {
                    return {
                        id: dataView.id,
                        isDefault: dataView.isDefault,
                        dataViewType: dataView.dataViewType,
                        name: dataView.name,
                        conditionSet: JSON.parse(dataView.conditionSetJson),
                        basicFilters: JSON.parse(dataView.basicFiltersJson),
                        sortExpression: dataView.sortExpression,
                        recordsPerPage: dataView.recordsPerPage,
                        columns: dataView.columns,
                        index: dataView.index,
                        searchType: dataView.searchType,
                        isShared: dataView.isShared,
                        systemAccountId: dataView.systemAccountId
                    } as DataView;
                })
            });
    }

    public saveDataView(dataViewType: DataViewType, dataView: DataView): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/SaveUserDataView`;
        const userId = this.currentUserResolver.getCurrentUser().user.userId;
            
        const parsedColumns: PersistedUserDataViewColumn[] = dataView.columns
            .filter((column) => !!column.isVisible)
            .map((column) => ({
                name: column.name,
                index: column.index
            }));

        const conditionSetJson = !!dataView.conditionSet ? JSON.stringify({...dataView.conditionSet}) : undefined;
        const basicFiltersJson = !!dataView.basicFilters ? JSON.stringify(dataView.basicFilters) : undefined;

        const persistedDataView: PersistedUserDataView = {
            id: dataView.id > 0 ? dataView.id : 0,
            name: dataView.name,
            userId: userId,
            dataViewType: dataViewType,
            index: dataView.index,
            isDefault: dataView.isDefault,
            recordsPerPage: dataView.recordsPerPage,
            sortExpression: dataView.sortExpression,
            conditionSetJson: conditionSetJson,
            basicFiltersJson: basicFiltersJson,
            columns: parsedColumns,
            searchType: dataView.searchType,
            isShared: dataView.isShared,
            systemAccountId: dataView.systemAccountId
        };

        return this.$http
            .post<A3ApiResponse<number>>(url, persistedDataView)
            .then((response) => response.data.value);
    }

    public saveDataViews(dataViewType: DataViewType, dataViews: DataView[]): IPromise<number[]> {
        const url = `${this.systemSettings.apiBaseUrl}UserDataViewActions/SaveUserDataViews`;
        
        const persistedDataViews: PersistedUserDataView[] = dataViews
            .map((dataView) => {
                const parsedColumns: PersistedUserDataViewColumn[] = dataView.columns
                    .filter((column) => !!column.isVisible)
                    .map((column) => ({
                        name: column.name,
                        index: column.index
                    }));

                const conditionSetJson = !!dataView.conditionSet ? JSON.stringify({...dataView.conditionSet}) : undefined;
                const basicFiltersJson = !!dataView.basicFilters ? JSON.stringify(dataView.basicFilters) : undefined;

                return {
                    id: dataView.id > 0 ? dataView.id : 0,
                    name: dataView.name,
                    userId: this.currentUserResolver.getCurrentUser().user.userId,
                    dataViewType: dataViewType,
                    index: dataView.index,
                    isDefault: dataView.isDefault,
                    recordsPerPage: dataView.recordsPerPage,
                    sortExpression: dataView.sortExpression,
                    conditionSetJson: conditionSetJson,
                    basicFiltersJson: basicFiltersJson,
                    columns: parsedColumns,
                    searchType: dataView.searchType,
                    isShared: dataView.isShared,
                    systemAccountId: dataView.systemAccountId
                };
            });

        return this.$http
            .post<A3ApiResponse<number[]>>(url, {dataViewType, dataViews: persistedDataViews})
            .then((response) => response.data.value);
    }

    public sendTestNotification(): IPromise<void> {
        return this.$http
            .post(`${this.systemSettings.apiBaseUrl}UserActions/SendTestNotification`, null)
            .then(() => {});
    }
    
    public getUsersForDropdown(options: UserForDropdownRequestOptions): IPromise<SelectOption<number>[]> {
        return this.filterUsersDropdownOptions(this.getUsersForDropdownData(), options);
    }

    private getUsersForDropdownData(): IPromise<UserForDropdown[]> {
        const url = `${this.systemSettings.apiBaseUrl}UserActions/GetUsersForDropdown`;

        return this.cacheStore.getData(url, () => {
            return this.$http
                .get<A3ApiResponse<UserForDropdown[]>>(url)
                .then((response) => response.data.value)
        });
    }

    private filterUsersDropdownOptions(promise: IPromise<UserForDropdown[]>, options: UserForDropdownRequestOptions) {
        return promise
            .then((users) => {
                const selectOptions:SelectOption<number>[] = [];

                if (options.allowUnselected) {
                    selectOptions.push({ value: null, label: options.unselectedText });
                }

                // assume data is sorted by isLockedOut
                let previousUserLockedOut = false;

                for (let i = 0; i < users.length; i++) {
                    if (options.allUsers === true) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }

                    if(options.isProducer && users[i].isProducer) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }

                    if(options.isAttorneyInFact && users[i].isAttorneyInFact) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }

                    if(options.isClientServiceAgent && users[i].isClientServiceAgent) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }

                    if(options.isClientServiceManager && users[i].isClientServiceManager) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }

                    if(options.isClientServiceExecutive && users[i].isClientServiceExecutive) {
                        this.addUserToArrayForDropdown(selectOptions, users[i], previousUserLockedOut);
                        previousUserLockedOut = users[i].isLockedOut;
                        continue;
                    }
                }

                return selectOptions;
            });
    }

    private addUserToArrayForDropdown(selectOptions: SelectOption<number>[], user: UserForDropdown, previousUserLockedOut: boolean) {
        let label = user.fullName;

        if (user.isLockedOut) {
            
            if (previousUserLockedOut !== user.isLockedOut) {
                selectOptions.push({value: -1, label: '────────────────────', isDisabled: true});
            }

            label = 'INACTIVE - ' + user.fullName;
        }

        selectOptions.push({ 
            label: label,
            value: user.id 
        });
    }
    
    public deleteCurrentUserProfilePicture(): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}UserActions/DeleteCurrentUserProfilePicture`;
        return this.$http.post(url, null)
            .then(() => { });
    }

    public downloadUserProfileImage(userId: number = null): IPromise<string> {
        if (!userId) {
            userId = -1;
        }

        let url = '';
            
        if (userId >= 0) {
            url = `${this.systemSettings.apiBaseUrl}Download/UserProfileImage?userId=${userId}`;
        } else {
            url = `${this.systemSettings.apiBaseUrl}Download/CurrentUserProfileImage`;
        }            

        return this.cacheStore.getData(url, () => this.$http
            .get<string>(url)
            .then((response) => response.data)
        );
    }

    public downloadUsersEntriesExcelReport(TableQueryOptions: UserTableQueryOptions): IPromise<PageResponse<UserTableItem>> {
        const queryString = this.buildUserReportQueryString(TableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}UserActions/GetUserEntriesExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(url,null);
    }

    private buildUserReportQueryString(tableQueryOptions: UserTableQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as UserTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.isLockedOut) {
            queryString += `&isLockedOut=${tableQueryOptions.isLockedOut}`;
        }

        if (tableQueryOptions.userGroupId) {
            queryString += `&userGroupId=${tableQueryOptions.userGroupId}`;
        }

        return queryString;
    }

    public getAifUsers(): IPromise<User[]> {
        const svc = this.odata.getService<User>(ODataEndpoint.User);

        svc.query.filter('isAttorneyInFact eq true');

        return svc.get()
            .then((response) => response.data.value);
    }

    public getSignatureImage(id: number): IPromise<any> {
        const url = `${this.systemSettings.apiBaseUrl}Download/SignatureImage?userId=${id}`;

        return this.$http.get(url)
            .then(img => img.data as string);
    }
    
    public getUser(userId: number): IPromise<User> {
        const svc = this.odata.getService<User>(ODataEndpoint.User);

        svc.query.filter(`id eq ${userId}`).expand('userUserGroups($expand=userGroup)');

        return svc
            .get<A3ApiResponse<User[]>>()
            .then((response) => response.data.value[0]);
    }

    public getUserDetail(userId: number): IPromise<UserDetail> {
        return this.$http.get<A3ApiResponse<UserDetail>>(this.systemSettings.apiBaseUrl + 'UserActions/GetUserDetail?userId=' + userId)
            .then((response) => response.data.value);
    }

    public saveOwnUserProfile(user: User): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'UserActions/SaveOwnUserProfile', user)
            .then(() => {});
    }

    public saveUser(user: UserDetail): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'UserActions/SaveUserDetail', user)
            .then(() => {});
    }

    public setSideMenuIsCollapsed(userId: number, isCollapsed: boolean): IPromise<void> {
        const request = { id: userId, isCollapsed: isCollapsed } as SetIsSideMenuCollapsed;

        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'UserActions/SetIsSideMenuCollapsed', request)
            .then(() => {
                this.authService.setSideMenuIsCollapsed(isCollapsed);
                return;
            });
    }

    public getUsers(tableQueryOptions: UserTableQueryOptions): IPromise<PageResponse<UserTableItem>> {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'Users.CreatedDateTime';
        }

        let queryString = `?`;

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.isLockedOut || tableQueryOptions.isLockedOut === false) {
            queryString += `&isLockedOut=${tableQueryOptions.isLockedOut.toString()}`;
        }

        if (tableQueryOptions.userGroupId) {
            queryString += `&userGroupId=${tableQueryOptions.userGroupId}`;
        }

        return this.$http
            .get<A3ApiResponse<PageResponse<UserTableItem>>>(this.systemSettings.apiBaseUrl + 'UserActions/GetUsers' + queryString)
            .then(response => response.data.value);
    }

    public setTheme(userId: number, theme: string): IPromise<void> {
        if (!theme) {
            throw new Error('theme is not valid');
        }

        const request = { id: userId, theme: theme } as SetThemeDto;

        return this.$http.post(this.systemSettings.apiBaseUrl + 'UserActions/SetTheme', request)
            .then(() => {
                this.authService.setTheme(theme);
                return;
            });
    }

    public uploadUserProfileImage(file: string, originalFile: File): IPromise<string> {
        const blob = this.utilityService.dataURItoBlob(file);
        const data = new FormData();

        data.append('profileImage', blob, originalFile.name);

        // $http.post with Javascript FormData() http://uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs
        return this.$http
            .post<A3ApiResponse<string>>(this.systemSettings.apiBaseUrl + 'upload/UploadUserProfileImage',
                data,
                {
                    headers: {
                        'Content-Type': undefined
                    },
                    transformRequest: angular.identity,
                })
            .then((response) => response.data.value);
    }

    public searchUsers = (searchPhrase: string): IPromise<UserSearchResult[]> => {
        return this.$http.get<A3ApiResponse<UserSearchResult[]>>(this.systemSettings.apiBaseUrl + 'UserActions/SearchUsers?searchPhrase=' + searchPhrase)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.UserService, UserService);
