import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import defaultWrapper from "../defaultWrapper";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {

    const unselectedText = element.attr('aa-unselected-text') ? element.attr('aa-unselected-text') : '';
    
    var billingEntryTypeOptions = `
    
        <option value="">${unselectedText}</option>
        <option value="Premium">Premium</option>
        <option value="Fee">Fee</option>
    `;

    element.append(billingEntryTypeOptions);

    var aaFgClass = element.attr('aa-fg-class') ? element.attr('aa-fg-class') + ' select' : 'select';
    element.attr('aa-fg-class', aaFgClass);

    fieldGroupWrapper(element, defaultWrapper(element), jQueryProvider);
};