import { DocumentCategory } from "../../api/types/model/documentCategory";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { DocumentLibraryUploadCategorySelectionOptions } from "./DocumentLibraryUploadCategorySelectionOptions";

export class DocumentLibrarySelectCategoryModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options
    ];

    constructor(
        private readonly $uibModalInstance: Modal<DocumentCategory>,
        private readonly options: DocumentLibraryUploadCategorySelectionOptions
    ) {}

    public selectedUploadCategory: DocumentCategory;

    public selectCategory = (category: DocumentCategory) => {
        this.selectedUploadCategory = category;
    }

    public upload = () => {
        this.$uibModalInstance.close(this.selectedUploadCategory);
    };

    public cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    };

    public $onInit = () => {
        this.selectedUploadCategory = this.options.documentCategories[0];
    }
}
