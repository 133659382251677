import { ConstantsStatusDisplay, constants } from "../configuration/constants";
import { Injectables } from "../configuration/injectables";
import { UtilityService } from "../utilities/utilityService";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { SystemAccountService } from "./systemAccountService";
import { Application } from "./types/model/application";
import { UiApplication } from "./types/uiApplication";
import { ApplicationAccessFollowUpOptions } from "./types/applicationAccessEmail/applicationAccessFollowUpOptions";
import { ApplicationEmailTemplateOptions } from "./types/applicationEmailTemplateOptions";
import { ApplicationPremiumRange } from "./types/applicationPremiumRange";
import { DeclineApplicationDto } from "./types/declineApplicationDto";
import { EmailTemplateForSending } from "./types/emailTemplateForSending";
import { IndemnityDto } from "./types/modalIndemnityDto";
import { ApplicationStatus } from "./types/model/application";
import { DefaultQuote } from "./types/model/defaultQuote";
import { EProducerAccount } from "./types/model/eProducerAccount";
import { Fee } from "./types/model/fee";
import { IndemnityType } from "./types/model/indemnitySignature";
import { QuoteStatus, IndemnityRequirements } from "./types/model/quote";
import { RateType } from "./types/model/rate";
import { PeopleAndCompanyOptions } from "./types/peopleAndCompanyOptions";
import { Principal } from "./types/principal";
import { PurchaseQuoteResponseDto } from "./types/purchaseQuoteResponseDto";
import { Quote } from "./types/model/quote";
import { QuotePurchaseRequest } from "./types/quotePurchaseRequest";
import { SelectOption } from "./types/selectOption";
import app from "../main";
import { IHttpService, IPromise, IQService, ISCEService } from "angular";
import { CurrentUserResolver } from "../utilities/currentUserResolver/currentUserResolver";
import { IInArrayFilter } from "../filters/inArray/iInArrayFilterType";
import { SystemSettings } from "../configuration/settings/systemSettings";
import OpenApplicationCounterFilterType from "./types/openApplicationCounterFilterType";
import QuoteDetail from "./types/quoteDetail";
import { QuoteForPurchaseModal } from "./types/quoteForPurchase";
import { ApplicationReportDropdownOptions } from "../states/agentSpecific/applicationReport/applicationReportFilterDropdown/applicationReportFilterDropdownOptions";
import { ApplicationReportItem } from "../states/agentSpecific/applicationReport/applicationReportItem";
import ApplicationListTableQueryOptions from "../components/applicationList/applicationListQueryOptions";
import ApplicationListItem from "../components/applicationList/applicationListItem";
import { PageResponse } from "./types/pageResponse";
import QuoteListItem from "../components/quoteList/quoteListItem";
import { CarrierOpenApplicationsReportFilterOptions } from "../states/carrierSpecific/applications/carrierOpenApplicationsReportFilterOptions";
import { CarrierOpenApplicationsReportItem } from "../states/carrierSpecific/applications/carrierOpenApplicationsReportItem";
import { FileDownloader } from "./fileDownloader";
import { ApplicationDetail } from "./types/applicationDetail";
import { QueryBuilderTableQueryOptions } from "./types/queryBuilderTableOptions";

export class ApplicationService {
    public static $inject = [
        Injectables.$http,
        Injectables.ODataFactory,
        Injectables.$q,
        Injectables.SystemAccountService,
        Injectables.$filter,
        Injectables.UtilityService,
        Injectables.$sce,
        Injectables.CurrentUserResolver,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly $q: IQService,
        private readonly systemAccountService: SystemAccountService,
        private readonly $filter: IInArrayFilter<QuoteListItem, number>,
        private readonly utilityService: UtilityService,
        private readonly $sce: ISCEService,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {}

    public downloadQuoteDocument(quoteId: number): IPromise<void> {
        return this.$http
            .get(
                this.systemSettings.apiBaseUrl +
                    "DataDocument/GetQuoteDocument?quoteId=" +
                    quoteId,
                { responseType: "arraybuffer" }
            )
            .then((response) =>
                this.utilityService.processDocumentDownloadResponse(response)
            )
            .then(() => {});
    }

    public autoQuoteApplication(applicationId: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/AutoQuoteApplication`;

        return this.$http
            .post<A3ApiResponse<void>>(url, { applicationId: applicationId })
            .then((response) => {});
    }

    public getEProducerBrokerForApplication(
        eProducerAccountId: number
    ): IPromise<EProducerAccount> {
        const svc = this.odata.getService<EProducerAccount>(
            ODataEndpoint.EProducerAccount
        );

        svc.query.top(1);
        svc.query.filter("id eq " + eProducerAccountId);

        return svc.get().then((response) => response.data.value[0]);
    }

    public getForEditing(applicationId: number): IPromise<UiApplication> {
        const svc = this.odata.getService<Application>(
            ODataEndpoint.Application
        );

        svc.query.top(1);
        svc.query.expand(
            "people,companies,customer,obligee,applicationQuestionResponses,attachments," +
                "eProducerAccount,accountRequestBondTypes($expand=bondType($expand=obligee,bondTypeVariableBondAmounts))"
        );
        svc.query.filter("id eq " + applicationId);

        return svc.get().then((response) => {
            const uiApplication = new UiApplication(response.data.value[0]);

            uiApplication.submissionSignature = "";

            return uiApplication;
        });
    }

    public closeApplication(applicationId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/CloseApplication",
                { applicationId: applicationId }
            )
            .then(() => {});
    }

    public countQuotes(quotes: Quote[], status?: QuoteStatus): number {
        let count = 0;
        for (const quote of quotes) {
            if (!status || quote.status === status) {
                count++;
            }
        }

        return count;
    }

    public getDefaultQuote(applicationId: number): IPromise<DefaultQuote> {
        const url = this.systemSettings.apiBaseUrl + "ApplicationActions/GetDefaultQuote?applicationId=" + applicationId;

        return this.$http.get<A3ApiResponse<DefaultQuote>>(url)
            .then((response) => response.data.value);
    }

    public declineApplication(
        applicationId: number,
        comments: string,
        adverseActionRequired: boolean
    ): IPromise<void> {

        const url = this.systemSettings.apiBaseUrl + "ApplicationActions/Decline";

        const data: DeclineApplicationDto = {
            applicationId: applicationId,
            comments: comments,
            adverseActionRequired: adverseActionRequired
        };

        return this.$http.post(url, data).then(() => {});
    }

    public async deleteQuote(quoteId: number): Promise<void> {
        await this.$http.delete(this.systemSettings.apiBaseUrl + "QuoteActions/DeleteQuote?id=" + quoteId);
    }

    public downloadApplicationDocument(applicationId: number): IPromise<void> {
        return this.$http
            .get(
                this.systemSettings.apiBaseUrl +
                    "DataDocument/GetApplicationDocument?applicationId=" +
                    applicationId,
                { responseType: "arraybuffer" }
            )
            .then((response) =>
                this.utilityService.processDocumentDownloadResponse(response)
            )
            .then(() => {});
    }

    public downloadAdverseActionNotificationDocument(
        applicationIds: number[]
    ): IPromise<void> {
        const queryString: string = applicationIds.reduce(
            (prev, curr) => (prev += `applicationIds=${curr}&`),
            ""
        );

        return this.$http
            .get(
                this.systemSettings.apiBaseUrl +
                    "DataDocument/GetAdverseActionNotificationDocument?" +
                    queryString,
                { responseType: "arraybuffer" }
            )
            .then((response) =>
                this.utilityService.processDocumentDownloadResponse(response)
            )
            .then(() => {});
    }

    public downloadAdverseActionNotificationDocumentSet(
        query: string
    ): IPromise<void> {
        const url =
            this.systemSettings.apiBaseUrl +
            "DataDocument/GetAdverseActionNotificationDocumentSet?" +
            query;

        return this.$http
            .get(url, { responseType: "arraybuffer" })
            .then((response) =>
                this.utilityService.processDocumentDownloadResponse(response)
            )
            .then(() => {});
    }
    
    public getApplicationList(queryOptions: ApplicationListTableQueryOptions) {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/GetApplicationList${this.buildApplicationListQueryString(queryOptions)}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<ApplicationListItem>>>(url)
            .then((response) => response.data.value);
    }

    private buildApplicationListQueryString(tableQueryOptions: ApplicationListTableQueryOptions): string {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as ApplicationListTableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "Applications.Id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.customerId) {
            queryString += `&customerId=${tableQueryOptions.customerId}`;
        }

        if (tableQueryOptions.eProducerAccountId) {
            queryString += `&eProducerAccountId=${tableQueryOptions.eProducerAccountId}`;
        }
        
        return queryString;
    }

    public formatForListDisplay(applications: Application[]): void {
        for (const application of applications) {
            application["$ranges"] = this.getPremiumRange(application);
            application["$approvedQuoteCount"] =
                this.getApprovedQuoteCount(application);
            application["$pendingQuoteCount"] =
                this.getPendingQuoteCount(application);
            application["$status"] = this.$sce.getTrustedHtml(
                this.getAgencyStatus(application).html
            );
        }
    }

    public getAgencyStatus(application: Application): ConstantsStatusDisplay {
        if (!application.quotes) {
            throw new Error("quotes are not valid");
        }

        return this.getApplicationStatus(
            application,
            "agencyApplicationStatusHtml",
            false
        );
    }

    public getSingleApplicationDetail(applicationId: number): IPromise<ApplicationDetail> {
        const url = this.systemSettings.apiBaseUrl + "ApplicationActions/GetSingleApplicationDetail?applicationId=" + applicationId;

        return this.$http.get<A3ApiResponse<ApplicationDetail>>(url)
            .then((response) => response.data.value);
    }

    public getApplicationEmailTemplate(
        options: ApplicationEmailTemplateOptions
    ): IPromise<EmailTemplateForSending> {
        let queryStringParams = "?";

        if (options.applicationId) {
            queryStringParams += `applicationId=${options.applicationId}&`;
        }
        if (options.bondTypeId) {
            queryStringParams += `bondTypeId=${options.bondTypeId}&`;
        }
        if (options.bondAmount) {
            queryStringParams += `bondAmount=${options.bondAmount}&`;
        }
        if (options.emailTemplateId) {
            queryStringParams += `emailTemplateId=${options.emailTemplateId}&`;
        }
        if (options.leadId) {
            queryStringParams += `leadId=${options.leadId}&`;
        }

        // remove last &
        if (queryStringParams.charAt(queryStringParams.length - 1) === "&") {
            queryStringParams = queryStringParams.substr(
                0,
                queryStringParams.length - 1
            );
        }

        return this.$http
            .get<A3ApiResponse<EmailTemplateForSending>>(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/GetApplicationAccessEmailTemplate" +
                    queryStringParams
            )
            .then((response) => response.data.value);
    }

    public getApplicationStatus(
        application: Application,
        statusSource: string,
        isCarrier: boolean
    ): ConstantsStatusDisplay {
        if (isCarrier) {
            // check declined Applications
            for (const declination of application.declinedApplications) {
                
                const userSystemAccountId = this.currentUserResolver.getCurrentUser().user.systemAccountId;

                if (
                    declination.carrierSystemAccountId === userSystemAccountId &&
                    declination.applicationId === application.id
                ) {
                    if (declination.adverseActionRequired) {
                        return constants[statusSource].declinedStatusAdverseAction as ConstantsStatusDisplay;
                    } else {
                        return constants[statusSource].declinedStatus as ConstantsStatusDisplay;
                    }
                }
            }
        }

        // if appliction is not submitted then return application.status
        if (application.status !== ApplicationStatus.Submitted) {
            return constants[statusSource].approvedStatus as ConstantsStatusDisplay;
        } else {
            if (application.quotes.length === 0) {
                // NOT QUOTED (Manual Review)
                return constants[statusSource].manualReviewStatus as ConstantsStatusDisplay;
            } else {
                // loop through each quote
                for (const quote of application.quotes) {
                    // if approved quote is found then status is Approved and stop looking through other qutoes
                    if (quote.status === QuoteStatus.Approved) {
                        return constants[statusSource].approvedStatus as ConstantsStatusDisplay;
                    }
                }

                return constants[statusSource].manualReviewStatus as ConstantsStatusDisplay;
            }
        }
    }

    public getApprovedQuoteCount(application: Application): number {
        if (!application.quotes) {
            throw new Error("quotes are not valid");
        }

        return this.countQuotes(application.quotes, QuoteStatus.Approved);
    }

    public getBestQuotes(quotes: QuoteListItem[]): IPromise<QuoteListItem[]> {
        // group by writingCompanyId#term.  will make an object where
        // these are fields, the values being arrays of quotes
        // ex: {1025#4: [...], 1028#10: [...]}
        const groups = {} as { [key: string]: QuoteListItem[] };
        const knownKeys = [];
        for (let i = 0; i < quotes.length; i++) {
            const key = quotes[i].writingCompanyId + "#" + quotes[i].term;

            if (knownKeys.indexOf(key) === -1) {
                knownKeys.push(key);

                groups[key] = [];
            }

            groups[key].push(quotes[i]);
        }

        // create an empty container for quoteids for the
        // cheapest quotes
        const bestQuotes: number[] = [];

        // makes an array of arrays, with the first
        // position being the key, the second value
        // the array we are after
        for (const prop in groups) {
            if (Object.prototype.hasOwnProperty.call(groups, prop)) {
                const pendingQuotes = groups[prop].filter(
                    (quote) => quote.status === QuoteStatus.Pending
                );
                const approvedQuotes = groups[prop].filter(
                    (quote) => quote.status === QuoteStatus.Approved
                );

                const bestPending: QuoteListItem[] = [];
                const bestApproved: QuoteListItem[] = [];

                if (pendingQuotes.length > 0) {
                    bestPending.push(
                        pendingQuotes.reduce((previous, current) =>
                            current.totalPremium < previous.totalPremium
                                ? current
                                : previous
                        )
                    );
                }

                if (approvedQuotes.length > 0) {
                    bestApproved.push(
                        approvedQuotes.reduce((previous, current) =>
                            current.totalPremium < previous.totalPremium
                                ? current
                                : previous
                        )
                    );
                }

                // we need to omit any pending premiums that match on carrier and term but are not a
                // better rate than the already approved one
                const filteredPending: QuoteListItem[] = [];

                for (const ba of bestApproved) {
                    const foundBetterPending = bestPending.filter(
                        (quote) => quote.totalPremium < ba.totalPremium
                    );
                    for (const p of foundBetterPending) {
                        filteredPending.push(p);
                    }
                }
                // get the quote id's for approved and filtered pending
                for (const ba of bestApproved) {
                    bestQuotes.push(ba.id);
                }

                for (const p of filteredPending) {
                    bestQuotes.push(p.id);
                }
            }
        }

        // return original passed in list
        // filtered by the ids we want

        const quotesFoundInBestList = this.$filter("inArray")(
            quotes,
            bestQuotes,
            "id"
        );

        return this.setQuotesEditability(quotesFoundInBestList).then(
            (quotesWithEditabilitySet) =>
                this.sortQuotes(quotesWithEditabilitySet)
        );
    }

    private sortQuotes(quotes: QuoteListItem[]): QuoteListItem[] {
        const sortedQuotes = quotes.sort((a: QuoteListItem, b: QuoteListItem): number => {
            return (
                this.compare(a.term, b.term, false) ||
                this.compare(a.totalPremium, b.totalPremium, false)
            );
        });

        return sortedQuotes;
    }

    public compare(a: any, b: any, isDescending: boolean): number {
        if (a > b) {
            return +1 * (isDescending ? -1 : 1);
        }
        if (a < b) {
            return -1 * (isDescending ? -1 : 1);
        }
        return 0;
    }

    public getCarrierStatus(application: Application): ConstantsStatusDisplay {
        if (!application.quotes) {
            throw new Error("quotes are not valid");
        }

        return this.getApplicationStatus(
            application,
            "carrierApplicationStatusHtml",
            true
        );
    }

    public getIndemnitySignatures(quoteId: number): IPromise<IndemnityDto[]> {
        return this.$http
            .get<A3ApiResponse<IndemnityDto[]>>(
                this.systemSettings.apiBaseUrl +
                    "IndemnitySignatureActions/GetIndemnitySignaturesByQuoteId/" +
                    "?quoteId=" +
                    quoteId
            )
            .then((response) => response.data.value);
    }

    public getPeopleAndCompanyOptionsByQuoteId(
        quoteId: number
    ): IPromise<PeopleAndCompanyOptions> {
        return this.$http
            .get<A3ApiResponse<PeopleAndCompanyOptions>>(
                this.systemSettings.apiBaseUrl +
                    "QuoteActions/PeopleAndCompanyOptions" +
                    "?quoteId=" +
                    quoteId
            )
            .then((response) => response.data.value);
    }

    public getPendingQuoteCount(application: Application): number {
        if (!application.quotes) {
            throw new Error("quotes are not valid");
        }

        return this.countQuotes(application.quotes, QuoteStatus.Pending);
    }

    public getPremiumRange(application: Application): ApplicationPremiumRange {
        if (!application.quotes) {
            throw new Error("quotes are not valid");
        }

        const range: ApplicationPremiumRange = {
            minimumTotalDue: 0,
            maximumTotalDue: 0
        };

        for (const quote of application.quotes) {
            if (quote.status !== QuoteStatus.Approved) {
                continue;
            }

            if (range.minimumTotalDue > 0) {
                range.minimumTotalDue = Math.min(
                    quote.totalAmountDue,
                    range.minimumTotalDue
                );
            } else {
                range.minimumTotalDue = quote.totalAmountDue;
            }

            range.maximumTotalDue = Math.max(
                quote.totalAmountDue,
                range.maximumTotalDue
            );
        }

        return range;
    }

    public getPrincipal(applicationId: number): IPromise<Principal> {
        const url = this.systemSettings.apiBaseUrl + 'ApplicationActions/GetApplicationPrincipal?applicationId=' + applicationId; 

        return this.$http
            .get<A3ApiResponse<Principal>>(url)
            .then((response) => response.data.value);
    }

    public getQuoteForPurchase(quoteId: number): IPromise<QuoteForPurchaseModal> {
        return this.$http.get<A3ApiResponse<QuoteForPurchaseModal>>(this.systemSettings.apiBaseUrl + 'QuoteActions/GetQuoteForPurchase?quoteId=' + quoteId)
            .then((response) => response.data.value);
    }

    public getQuoteDetail(quoteId: number): IPromise<QuoteDetail> {
        return this.$http.get<A3ApiResponse<QuoteDetail>>(this.systemSettings.apiBaseUrl + 'QuoteActions/GetQuoteDetail?quoteId=' + quoteId)
            .then((response) => response.data.value);
        }

    public getQuotes(applicationId: number): IPromise<QuoteListItem[]> {
        const url = this.systemSettings.apiBaseUrl + "QuoteActions/GetQuotes?applicationId=" + applicationId;
        
        return this.$http
            .get<A3ApiResponse<PageResponse<QuoteListItem>>>(
                url
            )
            .then((response) => 
                response.data.value.items as QuoteListItem[]
            );
    }

    public openApplication(applicationId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/OpenApplication",
                { applicationId: applicationId }
            )
            .then(() => {});
    }

    public openCount(
        filterType: OpenApplicationCounterFilterType
    ): IPromise<number> {
        return this.$http
            .get<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl +
                    "Analytics/GetOpenApplicationCount?filterType=" +
                    filterType
            )
            .then((response) => response.data.value);
    }

    public purchaseQuote(quotePurchaseRequest: QuotePurchaseRequest): IPromise<PurchaseQuoteResponseDto> {
        const url = `${this.systemSettings.apiBaseUrl}QuoteActions/Purchase`;
        
        return this.$http.post<A3ApiResponse<PurchaseQuoteResponseDto>>(url, quotePurchaseRequest)
            .then((response) => {
                if (response.data.value.success) {
                    return response.data.value;
                } else {
                    return this.$q.reject(response.data.value);
                }
            });
    }

    public reconsiderApplication(applicationId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/Reconsider",
                { applicationId: applicationId } as DeclineApplicationDto
            )
            .then(() => {});
    }

    public async saveQuote(quote: QuoteDetail): Promise<void> {
        if (quote.id) {
            await this.$http.post(this.systemSettings.apiBaseUrl + 'QuoteActions/UpdateQuote', quote);
        } else {
            await this.$http.post(this.systemSettings.apiBaseUrl + 'QuoteActions/CreateQuote', quote);
        }
    }

    public sendApplicationEmail(
        options: ApplicationAccessFollowUpOptions
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/SendAccessEmail",
                options
            )
            .then(() => {});
    }

    public saveAndSendIndemnitySignatures(
        indemnitySignatures: IndemnityDto[]
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "IndemnitySignatureActions/SaveAndSendIndemnities",
                indemnitySignatures
            )
            .then(() => {});
    }

    public saveIndemnities(
        indemnitySignatures: IndemnityDto[]
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl +
                    "IndemnitySignatureActions/SaveIndemnities",
                indemnitySignatures
            )
            .then(() => {});
    }

    public getApplicationOptionsByCustomerId(
        customerId: number
    ): IPromise<SelectOption[]> {
        return this.$http
            .get<A3ApiResponse<any>>(
                this.systemSettings.apiBaseUrl +
                    "ApplicationActions/GetApplicationOptionsByCustomerId?customerId=" +
                    customerId
            )
            .then((response) => {
                const options: SelectOption[] = [];

                for (let i = 0; i < response.data.value.length; i++) {
                    options.push({
                        label: response.data.value[i].description,
                        value: response.data.value[i].id
                    });
                }

                return options;
            });
    }

    public setQuotesEditability(quotes: QuoteListItem[]): IPromise<QuoteListItem[]> {
        return this.systemAccountService
            .getCarriersThatAllowEditingQuotes()
            .then((carrierSystemAccounts) => {

                // loop through quotes
                for (const quote of quotes) {
                    // loop through carriers looking for matching systemAccountId
                    for (const carrierSystemAccount of carrierSystemAccounts) {
                        quote["$canEdit"] = quote["$canEdit"] || quote.carrierSystemAccountId === carrierSystemAccount.value;
                    }
                }

                return quotes;
            });
    }
    public getApplicationReportData(tableQueryOptions: QueryBuilderTableQueryOptions): IPromise<PageResponse<ApplicationReportItem>> {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/GetApplicationsReport`;

        return this.$http.post<A3ApiResponse<PageResponse<ApplicationReportItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public getApplicationExcelReport(tableQueryOptions: QueryBuilderTableQueryOptions, columnNames: string[]): IPromise<PageResponse<ApplicationReportItem>> {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/GetApplicationExcelReport`;

        return this.fileDownloader.downloadFile(url, { ...tableQueryOptions, columnNames: columnNames });
    }
    
    public getCarrierOpenApplicationExcelReport(tableQueryOptions: CarrierOpenApplicationsReportFilterOptions): IPromise<PageResponse<CarrierOpenApplicationsReportItem>> {
        const queryString = this. buildCarrierOpenApplicationListQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/GetCarrierOpenApplicationExcelReport${queryString}`;

        return this.fileDownloader.downloadFile(
            url,
            null
        );
    }

    public getCarrierOpenApplicationReportData(tableQueryOptions: CarrierOpenApplicationsReportFilterOptions): IPromise<PageResponse<CarrierOpenApplicationsReportItem>> {
        const queryString = this.buildCarrierOpenApplicationListQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}ApplicationActions/GetCarrierOpenApplicationsReport${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<CarrierOpenApplicationsReportItem>>>(url)
            .then((response) => response.data.value);
    }

    public buildCarrierOpenApplicationListQueryString(tableQueryOptions: CarrierOpenApplicationsReportFilterOptions) :string {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as CarrierOpenApplicationsReportFilterOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "Applications.CreatedDateTime desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        let queryString = "?";

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.agencySystemAccountId)
        {
            queryString += `&agencySystemAccountId=${tableQueryOptions.agencySystemAccountId}`;
        }

        if (tableQueryOptions.status) {
            queryString += `&status=${tableQueryOptions.status}`;
        }

        if (tableQueryOptions.requisitioningState) {
            queryString += `&requisitioningState=${tableQueryOptions.requisitioningState}`;
        }

        if (tableQueryOptions.minimumAmount) {
            queryString += `&minimumAmount=${tableQueryOptions.minimumAmount}`;
        }

        if (tableQueryOptions.maximumAmount) {
            queryString += `&maximumAmount=${tableQueryOptions.maximumAmount}`;
        }

        if (tableQueryOptions.startEffectiveDate) {
            queryString += `&startEffectiveDate=${tableQueryOptions.startEffectiveDate}`;
        }

        if (tableQueryOptions.endEffectiveDate) {
            queryString += `&endEffectiveDate=${tableQueryOptions.endEffectiveDate}`;
        }
        
        if (tableQueryOptions.startSubmissionDate) {
            queryString += `&startSubmissionDate=${tableQueryOptions.startSubmissionDate}`;
        }

        if (tableQueryOptions.endSubmissionDate) {
            queryString += `&endSubmissionDate=${tableQueryOptions.endSubmissionDate}`;
        }

        if (tableQueryOptions.sfaaCodeId ) {
              queryString += `&sfaaCodeId=${tableQueryOptions.sfaaCodeId}`;
        }

        return queryString;
    }

    public getIndemnityTypeOptions(): SelectOption<IndemnityType>[] {
        return [
            { label: "Principal", value: IndemnityType.Principal },
            { label: "Spouse", value: IndemnityType.Spouse },
            { label: "Corporate", value: IndemnityType.Corporate }
        ];
    }
}

app.service(Injectables.ApplicationService, ApplicationService);