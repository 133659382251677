import angular, { IController, IFilterCurrency, IFilterService, IPromise, IScope } from "angular";
import app from "../../main";
import { Injectables } from "../../configuration/injectables";
import { LocalStorageService } from "../../utilities/localStorage/localStorageService";
import deepCopy from "../../utilities/immutable/deepCopy";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import { IFileSizeFilter } from "../../filters/fileSizeFilter/fileSizeFilter";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { ModalOpener } from "../../modals/modalOpener";

export type UploaderItem = {
    file: File;
    progress: number;         // 0 to 100
    bytesSent: number;
    totalBytes: number;
    isError: boolean;         // did an error occur during upload?
    errorMessage?: string;    // reason for upload failure
    uploadResponseReceived: boolean;
    response?: any;
    data?: any;
}

export type UploadControls = {
    setUrl: (url: string) => void;
    uploadAll: () => IPromise<UploaderItem[]>;
    clearAll: () => void;
    openFileDialog: () => void;
    addpendFormData: (key: string, data: any) => void;
    clearFormData: () => void;
    getIncompleteFiles: () => UploaderItem[];
    getFiles: () => UploaderItem[];
    removeFile: (index: number) => void;
}

class UploaderController implements IController {
    static $inject = [
        Injectables.$element,
        Injectables.$http,
        Injectables.$q,
        Injectables.LocalStorageService,
        Injectables.$filter,
        Injectables.$scope,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings,
        Injectables.ModalOpener
    ];

    // Bound properties
    public uploadUrl: string;
    public multiple: boolean;                 // Allow multiple file selection
    public autoUpload: boolean;               // Automatically upload upon drop/selection
    public acceptedTypes: string;             // e.g. "image/*,application/pdf"
    public maxFileSize: number;               // Max size for each file (bytes)
    public maxNumberOfFiles: number;          // Maximum total number of files
    public onFilesAdded: (args: { files: UploaderItem[] }) => void;
    public onSuccess: (args: { files: UploaderItem[] }) => void;
    public onError: (args: { files: UploaderItem[] }) => void;
    public onFileUploaded: (args: { file: UploaderItem }) => void;
    public controls: UploadControls;
    public files: UploaderItem[];
    public showBusyIndicator: boolean;
    public showUploadModal: boolean;
    public hidden: boolean;

    public busyIndicator: BusyIndicator;
    
    private additionalFormData: {key: string, value: any}[];
    public invalid: boolean;
    public validationMessage: string;
    public invalidFileCountMessage: string;
    public invalidFile: File;
    private validImageTypes: string = "image/jpeg,image/png,image/gif";
    private validAttachmentTypes: string = "application/pdf,image/jpeg,image/png,image/gif,.txt,.doc,.docx,.xlxs,.xls,.csv,.msg";
    private validPdfTypes: string = "application/pdf"
    public _acceptedTypes: string;
    private _uploadUrl: string

    constructor(
        private readonly $element: ng.IAugmentedJQuery,
        private readonly $http: ng.IHttpService,
        private readonly $q: ng.IQService,
        private readonly localStorageService: LocalStorageService,
        private readonly $filter: IFilterService,
        private readonly $scope: IScope,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings,
        private readonly modalOpener: ModalOpener
    ) {
    }

    public $onInit() {
        this.files = [];
        this.additionalFormData = [];
        this.busyIndicator = { message: 'Uploading...' };

        // Default Binding Values
        this._uploadUrl = this.defaultString(this.uploadUrl, `${this.systemSettings.apiBaseUrl}upload/UploadFiles`);
        this.multiple = this.defaultBoolean(this.multiple, false);
        this.autoUpload = this.defaultBoolean(this.autoUpload, false);
        this._acceptedTypes = this.translateAcceptedTypeAliases(this.acceptedTypes);
        this.maxFileSize = this.defaultNumber(this.maxFileSize, 5000000); // 5Mb
        this.maxNumberOfFiles = this.defaultNumber(this.maxNumberOfFiles, 5);
        this.showBusyIndicator = this.defaultBoolean(this.showBusyIndicator, false);
        this.showUploadModal = this.defaultBoolean(this.showUploadModal, true);

        this.hidden = this.defaultBoolean(this.hidden, false);

        this.setupDragAndDrop();

        this.controls = {
            uploadAll: this.uploadAll,
            clearAll: this.clearAll,
            setUrl: this.setUrl,
            openFileDialog: this.openFileDialog,
            addpendFormData: this.appendFormData,
            clearFormData: this.clearFormData,
            getIncompleteFiles: this.getIncompleteFiles,
            getFiles: this.getFiles,
            removeFile: this.removeFile
        }
    }

    private translateAcceptedTypeAliases(acceptedTypes: string) {
        const alias = acceptedTypes?.toLowerCase();

        if (alias === "images") {
            return this.validImageTypes;
        } else if (alias === "attachments") {
            return this.validAttachmentTypes;
        } else if (alias === "pdf") {
            return this.validPdfTypes;
        }

        return this.defaultString(alias, this.validPdfTypes);
    }

    private defaultBoolean = (value: boolean | string, defaultValue: boolean) => {
        if (isUndefinedOrNull(value)) {
            return defaultValue;
        }

        return value === 'true' || value === true;
    }

    private defaultNumber = (value: number | string, defaultValue: number): number => {
        if (isUndefinedOrNull(value)) {
            return defaultValue;
        }

        const parsedValue = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(parsedValue)) {
            return defaultValue;
        }

        return parsedValue;
    }

    private defaultString = (value: string | null | undefined, defaultValue: string): string => {
        if (isUndefinedOrNull(value) || value.trim() === '') {
            return defaultValue;
        }

        return value;
    }

    private appendFormData = (key: string, value: any) => {
        if (!this.additionalFormData) {
            this.additionalFormData = [];
        }

        this.additionalFormData.push({ key, value });
    }

    private clearFormData = () => {
        this.additionalFormData = [];
    }

    private setUrl = (url: string) => {
        this._uploadUrl = url;
    }

    private getIncompleteFiles = () => {
        return this.files.filter(file => file.progress !== 100);
    }

    private getFiles = () => {
        return this.files;
    }

    private removeFile = (index: number) => {
        this.files.splice(index, 1);
    }

    private setupDragAndDrop = (): void => {
        const fileUploader = this.$element[0].querySelector(".file-uploader");
        const actionArea = this.$element[0].querySelector(".action-area");

        if (!fileUploader) {
            return;
        }

        actionArea.addEventListener('dragover', (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
        });

        actionArea.addEventListener('dragenter', (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            fileUploader.classList.add('drag-over');
        });

        actionArea.addEventListener('dragleave', (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            fileUploader.classList.remove('drag-over');
        });

        actionArea.addEventListener('drop', (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            fileUploader.classList.remove('drag-over');
            
            if (!event.dataTransfer) {
                return;
            }

            const files = deepCopy(event.dataTransfer.files);

            this.$scope.$applyAsync(() => {
                this.handleFileSelection(files);
            });
        });
    }

    public openFileDialog = (): void => {
        const fileInput = this.$element[0].querySelector('input[type="file"]') as HTMLInputElement;

        if (fileInput) {
            fileInput.click();
        }
    }

    public onFileInputChange = (event: Event): void => {
        const input = event.target as HTMLInputElement;
        
        if (input && input.files) {
            this.$scope.$applyAsync(() => {
                this.handleFileSelection(input.files);
                // Clear the file input so that re-selecting
                // the same file will trigger onFilesAdded again if needed
                input.value = '';
            });
        }
    }

    private handleFileSelection = (fileList: FileList): void => {
        
        const newFiles: UploaderItem[] = deepCopy(Array.from(fileList))
            .map(file => ({
                file: file,
                progress: 0,
                bytesSent: 0,
                totalBytes: file.size,
                isError: false,
                uploadResponseReceived: false
            }));

        this.resetValidation();

        const totalFileCount = newFiles.length + this.files.length;

        if (this.maxNumberOfFiles && totalFileCount > this.maxNumberOfFiles) {
            this.setInvalid(`Cannot upload more than ${this.maxNumberOfFiles} files`);
            this.invalidFileCountMessage = `${totalFileCount} total files selected`;
            return;
        } else {
            this.invalidFileCountMessage = '';
        }

        for (const item of newFiles) {
            if (this.validateFile(item.file)) {
                this.files.push(item);
            } else {
                return;
            }
        }

        if (this.onFilesAdded) {
            this.onFilesAdded({ files: newFiles });
        }

        if (this.autoUpload) {
            this.uploadAll();
        }
    }

    private validateFile = (file: File): boolean => {

        if (this._acceptedTypes && !this.isValidFileType(file)) {
            let message = 'Invalid file type';

            if (this._acceptedTypes.toLowerCase() === this.validPdfTypes) {
                message = 'Invalid file type. Only PDF documents are allowed.';
            } else if (this._acceptedTypes.toLowerCase() == this.validImageTypes) {
                message = 'Invalid file type. Only image files are allowed.';
            } else if (this._acceptedTypes.toLowerCase() == 'images/*') {
                message = 'Invalid file type. Only image files are allowed.';
            }

            this.setInvalid(message, file)
            
            return false;
        }

        if (this.maxFileSize && file.size > this.maxFileSize) {
            this.setInvalid(`Files must be smaller than ${this.$filter<IFileSizeFilter>("filesize")(this.maxFileSize)}`, file);
            return false;
        }

        return true;
    }

    private isValidFileType = (file: File): boolean => {
        // acceptedTypes could be a comma-separated list (like "image/*,application/pdf")
        // or a single item. Implement a simple check here.
        const accepted = this._acceptedTypes
            .split(',')
            .map(t => t.trim().toLowerCase());

        if (!accepted?.length) {
            return true;
        }

        // If wildcard used (e.g. "image/*"), we match partial
        // If an exact type is used (e.g. "application/pdf"), we match exactly
        const fileType = file.type.toLowerCase();

        let extension = '';
        const dotIndex = file.name.lastIndexOf('.');
        if(dotIndex !== -1) {
            extension = file.name.slice(dotIndex).toLowerCase();
        }

        for (const type of accepted) {
            if (type.startsWith('.') && extension === type) {
                return true;
            } else if (type.endsWith('/*')) {
                // e.g. "image/*"
                const prefix = type.replace('/*', '');
                if (fileType.startsWith(prefix)) {
                    return true;
                }
            } else if (fileType === type) {
                return true;
            }
        }

        return false;
    }

    private resetValidation = () => {
        this.invalid = false;
        this.invalidFile = null;
        this.validationMessage = '';
    }

    private setInvalid = (validationMessage: string, file?: File) => {
        this.invalid = true;
        this.validationMessage = validationMessage;
        this.invalidFile = file ?? null;
    }

    public uploadAll = (): IPromise<UploaderItem[]> => {
        const promises: IPromise<UploaderItem>[] = [];

        if (!this.files.length) {
            return this.$q.when([]);
        }

        if (this.showUploadModal) {
            this.modalOpener.uploadModal(this.files)
                .result
                .then(() => {})
                .catch(() => {});
        }

        this.files.forEach((fileData, index) => {
            promises.push(this.uploadFile(fileData, index));
        });

        this.busyIndicator.promise = this.$q.all(promises)
            .then((results) => {
                this.clearAll();
                
                if (this.onSuccess) {
                    this.onSuccess({files: this.files});
                }

                return results;
            })
            .catch((error) => {
                if (this.onError) {
                    this.onError({files: this.files});
                }

                if (!this.showUploadModal) {
                    this.toastMessageCreator.createErrorMessage('An error occurred while uploading the selected file(s)')
                }

                return this.$q.reject(error);
            });
        
        return this.busyIndicator.promise;
    }

    private uploadFile = (uploadItem: UploaderItem, fileIndex: number): IPromise<UploaderItem> => {
        const formData = new FormData();
        formData.append('file', uploadItem.file);
        
        this.additionalFormData.forEach(data => {
            if (typeof data.value === 'object') {
                const blob = new Blob([JSON.stringify(data.value)], {type: "text/json"});
                formData.append(data.key, blob);
            } else {
                formData.append(data.key, String(data.value));
            }
        });
        
        this.busyIndicator.message = `Uploading File ${fileIndex + 1} of ${this.files.length}...`;

        return this.$http.post(
                this._uploadUrl, 
                formData, 
                <any>{
                    headers: { 
                        'Content-Type': undefined,
                        "Authorization": "Bearer " + this.localStorageService.getAuthenticationData().token
                    },
                    uploadEventHandlers: {
                        progress: (event: ProgressEvent) => {
                            if (event.lengthComputable) {
                                uploadItem.bytesSent = event.loaded;
                                uploadItem.progress = Math.round((event.loaded * 100) / event.total);
                            }
                        },
                    },
                    transformRequest: angular.identity
                }
            )
            .then((response) => {
                uploadItem.progress = 100;
                uploadItem.response = response.data;
                uploadItem.uploadResponseReceived = true;

                if (this.onFileUploaded) {
                    this.onFileUploaded({ file: uploadItem });
                }

                return deepCopy(uploadItem);
            })
            .catch((error) => {
                uploadItem.isError = true;
                uploadItem.errorMessage = 'An error occurred while trying to upload this file';
                uploadItem.uploadResponseReceived = true;
                return this.$q.reject(error);
            });
    }

    private clearAll = (): void => {
        this.files = [];
        this.clearFormData();
        this.resetValidation();
    }
}

const component: ng.IComponentOptions = {
    bindings: {
        uploadUrl: '@?',
        multiple: '<?',         // boolean
        autoUpload: '<?',       // boolean
        acceptedTypes: '@?',    // string, e.g. "image/*,application/pdf"
        maxFileSize: '<?',      // number, e.g. 2000000
        maxNumberOfFiles: '<?', 
        onFilesAdded: '&?',      
        onSuccess: '&?',        
        onError: '&?', 
        onFileUploaded: '&?',
        files: '=?',
        controls: '=?',
        showBusyIndicator: '=?',
        showUploadModal: '=?',
        hidden: '<?'
    },
    controllerAs: 'vm',
    controller: UploaderController,
    templateUrl: 'app/components/uploader/uploader.html'
};

app.component('uploader', component);
