import app from "../../main";

class HelpIconController {

    public static $inject = [];

    constructor() {}

    public $onInit = () => {}
}

const HelpIconComponent = {
    bindings: { 
        message: '@',
        iconClass: '@?'
    },
    templateUrl: 'app/components/helpIcon/helpIcon.html',
    controllerAs: 'vm',
    controller: HelpIconController
};

app.component('helpIcon', HelpIconComponent);