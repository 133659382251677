enum QueryConditionPropertyType {
    String = 'String',
    StringExists = 'StringExists',
    PhoneExists = 'PhoneExists',
    Phone = 'Phone',
    Money = 'Money',
    Integer = 'Integer',
    Decimal = 'Decimal',
    Boolean = 'Boolean',
    Date = 'Date',
    Producer = 'Producer',
    ClientServiceExecutive = 'ClientServiceExecutive',
    ClientServiceManager = 'ClientServiceManager',
    ClientServiceAssociate = 'ClientServiceAssociate',
    Percent = 'Percent',
    BillToType = 'BillToType',
    CommissionCalculationType = 'CommissionCalculationType',
    BrokerCategory = 'BrokerCategory',
    ApplicationStatus = 'ApplicationStatus',
    BondStatus = 'BondStatus',
    RenewalStatus = 'RenewalStatus',
    CancellationStatus = 'CancellationStatus',
    ReinstatementStatus = 'ReinstatementStatus',
    RiderStatus = 'RiderStatus',
    OriginSystem = 'OriginSystem',
    State = 'State',
    BondAccountStatus = 'BondAccountStatus',
    TransactionType = 'TransactionType',
    BillingEntryType = 'BillingEntryType',
}

export default QueryConditionPropertyType;