import { WritingCompany } from "../../api/types/model/writingCompany";
import { SelectOption } from "../../api/types/selectOption";
import { WritingCompanyService } from "../../api/writingCompanyService";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { WritingCompanyOptions } from "./writingCompanyModalOptions";
import { UploadControls, UploaderItem } from "../../components/uploader/uploader";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";

export class WritingCompanyModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.WritingCompanyService,
        Injectables.ToastMessageCreator,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $uibModalInstance: Modal<WritingCompany>,
        private readonly options: WritingCompanyOptions,
        private readonly writingCompanyService: WritingCompanyService,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly systemSettings: SystemSettings) { 
    }

    public sealImageUploader: UploadControls;
    public sealPicturePath: File;
    public sealUploadFiles: UploaderItem[];
    public writingCompany: WritingCompany;
    public documentSearch: any;
    public selectedPoaDocument: any;
    public priorityOptions: SelectOption[];
    public poaInvalid: boolean;
    public sealInvalid: boolean;
    public busyIndicator: BusyIndicator;
    
    public removeSealUploadFile = () => {
        this.sealImageUploader.removeFile(0);
    }

    public cancel(): void {
        this.$uibModalInstance.dismiss('cancel');
    }

    public save_click() {
        if (this.sealImageUploader.getFiles().length) {
            this.sealImageUploader.setUrl(`${this.systemSettings.apiBaseUrl}upload/WritingCompanySeal`);
            this.sealImageUploader.uploadAll()
                .then((uploadedItems) => {
                    this.writingCompany.corporateSealFileId = uploadedItems[0].response;
                    
                    // clear image path so that old image doesn't show while saving
                    this.sealPicturePath = null; 
                    
                    this.save();
                });
        } else {
            this.save();
        }
    }

    public save(): void {
        this.poaInvalid = false;
        this.sealInvalid = false;

        if (!this.writingCompany.powerOfAttorneyDocument) {
            this.poaInvalid = true;
            return;
        }

        if (!this.writingCompany.corporateSealFileId) {
            this.sealInvalid = true;
            return;
        }

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.writingCompanyService.saveWritingCompany(this.writingCompany.id, this.writingCompany)
            .then((savedWritingCompany) => {
                if (savedWritingCompany) {
                    this.writingCompany.id = savedWritingCompany.id;
                }

                this.$uibModalInstance.close(this.writingCompany);
                this.toastMessageCreator.createSuccessMessage('Writing company saved successfully');
            });
    }

    public searchDocuments(search): void {
        this.writingCompanyService
            .searchDocuments(search)
            .then((documents) => {
                this.documentSearch = documents;
            });
    }

    public selectDocument(): void {
        if (!this.selectedPoaDocument) {
            return;
        }

        this.writingCompany.powerOfAttorneyDocument = this.selectedPoaDocument;
        this.writingCompany.powerOfAttorneyDocumentId = this.selectedPoaDocument.id;

        delete this.writingCompany.powerOfAttorneyDocument['rating'];
        delete this.writingCompany.powerOfAttorneyDocument['documentCategoryName'];
        delete this.writingCompany.powerOfAttorneyDocument['documentRatings'];
        delete this.writingCompany.powerOfAttorneyDocument['documentCategory'].name;
        delete this.writingCompany.powerOfAttorneyDocument['createdDateTime']; //was powerOfAttorneyDocument.modifiedDateTime not currently accessable

        this.selectedPoaDocument = null;
        this.poaInvalid = false;
    }

    public onSelected($item: any): void {
        this.selectedPoaDocument = $item;
    }

    public $onInit(): void {
        this.writingCompany = this.options.company;
        this.busyIndicator = { message: 'Loading...' };
        this.priorityOptions = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }];

        if (this.writingCompany?.id) {
            this.busyIndicator.promise = this.writingCompanyService.getSealPicture(this.writingCompany.id)
                .then((sealImage) => {
                    this.sealPicturePath = sealImage;
                });
        }
    }
}