import { IScope, IAttributes, INgModelController, IDirectiveFactory } from "angular";
import app from "../../main";

export interface ICompareDatesScope extends IScope {
    compareDate: string;
    comparisonType: DateComparisonTypes;
}

enum DateComparisonTypes {
    lessThanOrEqual = 'lessThanOrEqual',
    greaterThanOrEqual = 'greaterThanOrEqual',
    greaterThan = 'greaterThan',
    lessThan = 'lessThan'
}

const compareDatesDirective = () => {
    const link = (
        scope: ICompareDatesScope, 
        element: JQuery, 
        attrs: IAttributes, 
        ngModel: INgModelController
    ) => {
        
        if (!Object.values(DateComparisonTypes).includes(scope.comparisonType)) {
            throw new Error('comparison-type attribute is not found or is invalid for compare-date directive');
        }

        ngModel.$validators.compareDate = (modelValue) => {
            const currentDate = new Date(modelValue);
            const compareDate = new Date(scope.compareDate);
            
            if (isNaN(currentDate.getTime()) || isNaN(compareDate.getTime())) {
                return true;
            }

            switch (scope.comparisonType) {
                case DateComparisonTypes.lessThanOrEqual:
                    return currentDate <= compareDate;
                case DateComparisonTypes.greaterThanOrEqual:
                    return currentDate >= compareDate;
                case DateComparisonTypes.greaterThan:
                    return currentDate > compareDate;
                case DateComparisonTypes.lessThan:
                    return currentDate < compareDate;
                default:
                    throw new Error('comparison-type attribute is not found or is invalid for compare-date directive');
            }
        };

        scope.$watch('compareDate', () => {
            ngModel.$validate();
        });
    }

    return {
        require: 'ngModel',
        scope: {
            compareDate: '<',
            comparisonType: '@'
        },
        link: link
    };
}

app.directive('compareDate', compareDatesDirective as IDirectiveFactory);