import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { CurrentUserResolver } from "../utilities/currentUserResolver/currentUserResolver";
import { ODataFactory, ODataEndpoint } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { EmailTemplate } from "./types/model/emailTemplate";
import { UiElement } from "./types/uiElement";
import { TableQueryOptions } from "./types/tableQuery";
import { QueryStringBuilder } from "../utilities/queryStringBuilder/queryStringBuilder";
import { PageResponse } from "./types/pageResponse";
import { EmailTemplateListItem } from "../components/emailTemplateList/emailTemplateList";

export class EmailTemplateService {

    public static $inject = [
        Injectables.ODataFactory, 
        Injectables.CurrentUserResolver, 
        Injectables.SystemSettings,
        Injectables.QueryStringBuilder,
        Injectables.$http
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly systemSettings: SystemSettings,
        private readonly queryStringBuilder: QueryStringBuilder,
        private readonly $http: IHttpService) {
    }

    public buildTemplateHtmlBody(template: EmailTemplate): void {
        if (!template) { 
            throw new Error('template is not valid'); 
        }

        template.htmlBody = this.defaultHeader + template.htmlBody + this.defaultFooter;
    }

    public getEmailTemplateListItems(tableQueryOptions: TableQueryOptions) {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            } as TableQueryOptions;
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "id desc";
        }

        if (!tableQueryOptions.pageNumber) {
            tableQueryOptions.pageNumber = 1;
        }

        if (!tableQueryOptions.recordsPerPage) {
            tableQueryOptions.recordsPerPage = 10;
        }

        const queryString = this.queryStringBuilder.buildQueryString(tableQueryOptions);
        const url = `${this.systemSettings.apiBaseUrl}ApplicationFollowUpActions/GetEmailTemplateListItems${queryString}`;

        return this.$http.get<A3ApiResponse<PageResponse<EmailTemplateListItem>>>(url)
            .then(response => response.data.value);
    }

    public deleteById(id: number) {
        const url = `${this.systemSettings.apiBaseUrl}ApplicationFollowUpActions/DeleteEmailTemplate`;
        
        return this.$http.post(url, id)
            .then(() => {});
    }

    public async getById(id: number): Promise<EmailTemplate> {
        const svc = this.odata.getService<EmailTemplate>(ODataEndpoint.EmailTemplate);

        svc.query.filter(`id eq ${id}`);

        const response = await svc.get<A3ApiResponse<EmailTemplate[]>>();

        return response.data.value[0];
    }

    public getDefaultCallToAction(buttonText: string): string {
        buttonText = buttonText || 'Click Here';

        buttonText = this.defaultCallToActionButton.replace(/\{\{\[CTAText\]\}\}/g, buttonText);

        return buttonText;
    }

    public getDefaultCallToActionForPreview(buttonText: string): string {
        return this.populateEmailDefaultPlaceholders(this.getDefaultCallToAction(buttonText));
    }

    public getDefaultFooterForPreview(): string {
        return this.populateEmailDefaultPlaceholders(this.defaultFooter);
    }

    public getDefaultHeaderForPreview(): string {
        return this.populateEmailDefaultPlaceholders(this.defaultHeader);
    }

    public load(select: string): IPromise<EmailTemplate[]> {
        const svc = this.odata.getService<EmailTemplate>(ODataEndpoint.EmailTemplate);

        if (select) {
            svc.query.select(select);
        }

        return svc.get<A3ApiResponse<EmailTemplate[]>>()
            .then((response) => response.data.value);
    }

    public parseHtmlBodyToCallToAction(template: EmailTemplate): void {
        if (!template) {
            throw new Error('template is not valid');
        }

        const ctawrapper = template.$document.getElementsByClassName('cta-wrapper') as HTMLCollectionOf<UiElement>;
        const cta = template.$document.getElementsByClassName('cta') as HTMLCollectionOf<UiElement>;

        if (ctawrapper.length === 1 && cta.length === 1) {
            template.$includeCallToAction = true;
            template.$callToActionText = cta[0].innerText.replace(/\{/g, '').replace(/\}/g, '');
        } else {
            template.$includeCallToAction = false;
            template.$callToActionText = '';
        }
    }

    public parseHtmlBodyToContent(template: EmailTemplate): void {
        if (!template) {
            throw new Error('template is not valid');
        }

        const content = template.$document.getElementsByClassName('content');

        if (content.length === 1) {

            // if cta exists then remove it
            const ctawrapper = content[0].getElementsByClassName('cta-wrapper');
            if (ctawrapper.length === 1) {
                ctawrapper[0].innerHTML = '';
                content[0].removeChild(ctawrapper[0]);
            }

            template.$templateEditableBody = content[0].innerHTML;
        } else {
            template.$templateEditableBody = '';
        }
    }

    public parseHtmlBodyToFooter(template: EmailTemplate): void {
        if (!template) {
            throw new Error('template is not valid');
        }

        template.$templateFooter = this.getDefaultFooterForPreview();
    }

    public parseHtmlBodyToHeader(template: EmailTemplate): void {
        if (!template) {
            throw new Error('template is not valid');
        }

        template.$templateHeader = this.getDefaultHeaderForPreview();
    }

    public parseHtmlBodyToVmParts(template: EmailTemplate): void {
        if (!template) {
            throw new Error('template is not valid');
        }

        template.$document = document.implementation.createHTMLDocument(template.name);
        template.$document.documentElement.innerHTML = template.htmlBody;

        this.parseHtmlBodyToHeader(template);
        this.parseHtmlBodyToCallToAction(template); // order matters - _parseHtmlBodyToContent removes cta-wrapper element from $document
        this.parseHtmlBodyToContent(template);
        this.parseHtmlBodyToFooter(template);
    }

    public populateEmailDefaultPlaceholders(str: string): string {
        str = str.replace(/\[BondType\.Name\]/g, 'EMAIL EXAMPLE BOND');
        str = str.replace(/\[OpenTrackerUrl\]/g, this.systemSettings.a3WebBaseUrl + 'img/transparent.png');
        str = str.replace(/\[ReturnUrl\]/g, '');
        str = str.replace(/\[ApiBaseUrl\]/g, this.systemSettings.apiBaseUrl);
        str = str.replace(/\[WebBaseUrl\]/g, this.systemSettings.a3WebBaseUrl);
        str = str.replace(/\[SystemAccount\.LogoUrl]/g, this.systemSettings.apiBaseUrl + 'SystemAccountActions/GetLogo?id=' + this.currentUserResolver.getCurrentUser().user.systemAccountId);
        str = str.replace(/\[SystemAccount\.Id\]/g, this.currentUserResolver.getCurrentUser().user.systemAccountId.toString());
        str = str.replace(/\[SystemAccount\.Phone\]/g, this.currentUserResolver.getCurrentUser().systemAccount.phone);
        str = str.replace(/\[SystemAccount\.Email\]/g, this.currentUserResolver.getCurrentUser().systemAccount.email);
        str = str.replace(/\[SystemAccount\.MailAddress\]/g, this.currentUserResolver.getCurrentUser().systemAccount.mailAddress);
        str = str.replace(/\[SystemAccount\.MailSuiteAptNumber\]/g, this.currentUserResolver.getCurrentUser().systemAccount.mailSuiteAptNumber);
        str = str.replace(/\[SystemAccount\.MailCity\]/g, this.currentUserResolver.getCurrentUser().systemAccount.mailCity);
        str = str.replace(/\[SystemAccount\.MailState\]/g, this.currentUserResolver.getCurrentUser().systemAccount.mailState);
        str = str.replace(/\[SystemAccount\.MailZip\]/g, this.currentUserResolver.getCurrentUser().systemAccount.mailZip);
        str = str.replace(/\[SystemAccount\.CompanyName\]/g, this.currentUserResolver.getCurrentUser().systemAccount.companyName);
        str = str.replace(/\{\{/g, '');
        str = str.replace(/\}\}/g, '');

        return str;
    }

    public async save(template: EmailTemplate): Promise<void> {
        if (!template) {
            throw new Error('Template is not valid');
        }

        const svc = this.odata.getService<EmailTemplate>(ODataEndpoint.EmailTemplate);

        template.htmlBody = template.$templateEditableBody;

        if (template.$includeCallToAction) {                
            template.htmlBody += this.getDefaultCallToAction(template.$callToActionText);          
        }

        this.buildTemplateHtmlBody(template);

        await svc.save(template);
    }

    public defaultHeader = `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html lang="en" xmlns="http://www.w3.org/1999/xhtml">
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width"/>
            </head>
            <body>

                <table 
                    border="0" 
                    cellspacing="0" 
                    width="100%">
                    <tbody>
                        <tr>
                            <td></td>
                            <td width="650">
                                <table border="0" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td align="right">
                                                <img 
                                                    alt="{{[SystemAccount.CompanyName]}}" 
                                                    style="margin-bottom:20px; width:auto; height:100px;" 
                                                    src="{{[SystemAccount.LogoUrl]}}" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="content">
    `;

    public defaultFooter = `
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:center; font-size:11px; padding-top: 40px;">
                                                <p>
                                                    Phone: {{[SystemAccount.Phone]}} | Email: 
                                                    
                                                    <a 
                                                        href="mailto:{{[SystemAccount.Email]}}" 
                                                        target="_blank" 
                                                        rel="noopener noreferrer">
                                                        
                                                        {{[SystemAccount.Email]}}
                                                    </a>
                                                    <br>
                                                    {{[SystemAccount.MailAddress] [SystemAccount.MailSuiteAptNumber], [SystemAccount.MailCity], [SystemAccount.MailState] [SystemAccount.MailZip]}}
                                                </p>
                                                <p>
                                            
                                                    <a 
                                                        href="{{[WebBaseUrl]}}terms.html" 
                                                        target="_blank" 
                                                        rel="noopener noreferrer">
                                                        
                                                        Terms
                                                    </a> 
                                                    
                                                    | 
                                            
                                                    <a 
                                                        href="{{[WebBaseUrl]}}privacy.html" 
                                                        target="_blank" 
                                                        rel="noopener noreferrer">
                                                        
                                                        Privacy
                                                    </a> 
                                                    
                                                    | 
                                                    
                                                    <a 
                                                        href="{{[UnsubscribeUrl]}}" 
                                                        target="_blank">
                                                        
                                                        Unsubscribe
                                                    </a>
                                                </p>
                                                <p>
                                                    <a href="http://www.agencymultiplied.com">
                                                        
                                                        <img 
                                                            src="{{[WebBaseUrl]}}img/emails/email-icon_poweredByA3.png" 
                                                            height="27" 
                                                            width="86" 
                                                            style="width:86px; 
                                                            height:27px;"/>
                                                    </a>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
        
                <img 
                    alt="" 
                    src="{{[OpenTrackerUrl]}}"/>
            </body>
        </html>
    `;

    public defaultCallToActionButton = `
        <div class="cta-wrapper">
            <p style="text-align:center; padding-top:20px;">
                
                <a 
                    class="cta" 
                    target="_blank" 
                    href="{{[ReturnUrl]}}" 
                    style="display: inline-block; color: white; text-decoration: none; border-radius: 4px; font-size: 14px; font-weight: bold; line-height: 1; background-color: #009eef; margin: 0 auto; padding: 5px 25px 6px 25px;">{{[CTAText]}}</a>
            
                <br/>
                <br/>
                <br/>
                <br/>
            </p>
            <p style="text-align:left;">
                If you are having trouble with the link above then copy and paste the following url into your web browser\'s address bar.
                
                <br/>
                <br/>
                
                {{[ReturnUrl]}}
            </p>
        </div>
    `;
}

app.service('emailTemplateService', EmailTemplateService);