import { IHttpService, IPromise } from "angular";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { FileDownloader } from "./fileDownloader";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { BillingEntryReportItem } from "../states/agentSpecific/billingEntry/billingEntryReportItem";
import { BillingReportDropdownFilterOptions } from "../states/agentSpecific/billingEntry/billingEntryReportFilterDropdown/billingReportDropdownFilterOptions";
import { PageResponse } from "./types/pageResponse";
import BillingEntriesListItem from "../components/billingEntriesList/billingEntriesListItem";
import { EndOfMonthReportFilter } from "../states/agentSpecific/monthEndAccounting/endOfMonthReportFilter";
import { CreditBillingEntryForAssignment } from "../modals/billingEntryCreditAssignmentModal/creditBillingEntryForAssignment";
import { CreditBillingEntryAssignmentRequest } from "../modals/billingEntryCreditAssignmentModal/creditBillingEntryAssignmentRequest";
import { QueryBuilderTableQueryOptions } from "./types/queryBuilderTableOptions";

export class BillingEntryService {

    public static $inject = [
        Injectables.$http,
        Injectables.SystemSettings,
        Injectables.FileDownloader
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly fileDownloader: FileDownloader
    ) {
    }

    public getBillingEntriesReportData(tableQueryOptions: QueryBuilderTableQueryOptions): IPromise<PageResponse<BillingEntriesListItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesReport`;
        
        return this.$http.post<A3ApiResponse<PageResponse<BillingEntriesListItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public downloadBillingEntriesExcelReport(tableQueryOptions: QueryBuilderTableQueryOptions, columnNames: string[]): IPromise<PageResponse<BillingEntryReportItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesExcelReport`;

        return this.fileDownloader.downloadFile(url, { ...tableQueryOptions, columnNames: columnNames });
    }

    public saveBillingEntryReport(request): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/SaveBillingEntryReport`;

        return this.$http.post<void>(url, request)
            .then(() => {});
    }

    public downloadExcelMonthEndReport(startDate: Date, endDate: Date): IPromise<void> {
        const filter: EndOfMonthReportFilter = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        } as EndOfMonthReportFilter;
        
        return this.fileDownloader.downloadFile(this.systemSettings.apiBaseUrl + 'BillingEntryActions/GetEndOfMonthEntriesExcelReport', filter);
    }
    
    public getBillingEntriesListDownload(tableQueryOptions: BillingReportDropdownFilterOptions, columnNames: string[]) {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesListDownload`;

        return this.fileDownloader.downloadFile(url, {...tableQueryOptions, columnNames });
    }

    public getBillingEntryListItems(tableQueryOptions: BillingReportDropdownFilterOptions) {
        const url = `${this.systemSettings.apiBaseUrl}BillingEntryActions/GetBillingEntriesList`;
        
        return this.$http.post<A3ApiResponse<PageResponse<BillingEntriesListItem>>>(url, tableQueryOptions)
            .then((response) => response.data.value);
    }

    public getCreditBillingEntryForAssignment(creditBillingEntryId: number): IPromise<CreditBillingEntryForAssignment> {
        return this.$http
            .get<A3ApiResponse<CreditBillingEntryForAssignment>>(this.systemSettings.apiBaseUrl + 'BillingEntryActions/GetCreditBillingEntryForAssignment?billingEntryId=' + creditBillingEntryId)
            .then((response) => response.data.value);
    }

    public assignCreditBillingEntry(creditBillingEntryAssignmentRequest: CreditBillingEntryAssignmentRequest): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + 'BillingEntryActions/AssignCredit', creditBillingEntryAssignmentRequest)
            .then(() => {});
    }
}

app.service(Injectables.BillingEntries, BillingEntryService);
