import * as angular from "angular";
import { IDirectiveFactory, IDirectiveLinkFn, IScope, IAttributes, IController } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { Attachment } from "../../api/types/attachment";

const directive: IDirectiveFactory = (modalOpener: ModalOpener) => {

    const link: IDirectiveLinkFn = (
        scope: IScope,
        element: JQuery,
        attributes: IAttributes,
        controller:IController
    ) => {
        scope.$watch(attributes.showAttachmentViewerModal, () => {
            
            const canViewAttachment = (attachment: Attachment) => {
                const viewableExtensions = ["html", "css", "js", "jpeg", "jpg", "jfif", "png", "gif", "svg", "pdf", "txt"];

                const extension = attachment.filename
                    .split(".")
                    .pop()
                    .toLowerCase();

                return viewableExtensions.includes(extension);
            }

            var attachment = scope.$eval(attributes.showAttachmentViewerModal);

            if (!canViewAttachment(attachment)) {
                element[0].setAttribute('disabled', 'disabled');
            }

            element.bind('click', (e: Event) => {
                if (canViewAttachment(attachment)) {
                    scope.$applyAsync(() => {
                        modalOpener.showAttachmentViewerModal(attachment.id)
                        .result
                        .then(() => { })
                        .catch(() => { });
                    });
                }
                
                e.stopPropagation();
            });
        });

    }
    return {
        restrict: 'A',
        link: link
    };
}

directive.$inject = [Injectables.ModalOpener];

app.directive('showAttachmentViewerModal', directive);
