import { IPromise } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { Modal } from "../../components/modals/modal";
import { UserGroupDetail } from "../../states/common/userGroupDetail/userGroupDetail";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { UserGroupService } from "../../api/userGroupService";

export class UserGroupDetailModal {
    
    public static $inject = [
        Injectables.$uibModalInstance, 
        Injectables.Options,
        Injectables.ToastMessageCreator,
        Injectables.CurrentUserResolver,
        Injectables.UserGroupService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>, 
        private readonly options: {id?: number},
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly userGroupService: UserGroupService)
    {
    }

    public userGroupId?: number;
    public group: UserGroupDetail;
    public busyIndicator: BusyIndicator;

    public get isCarrier(): boolean {
        return this.currentUserResolver.getCurrentUser().systemAccount.isCarrier;
    }

    public loadData() {
        this.busyIndicator.message = 'Loading...';
        this.busyIndicator.promise = this.userGroupService.getUserGroupDetail(this.userGroupId)
            .then((group) => {
                this.group = group;
            });
    }

    public save(): void {
        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.userGroupService.saveUserGroup(this.group)
            .then(() => {
                this.toastMessageCreator.createSuccessMessage('User group saved successfully');
                this.$uibModalInstance.close();
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('A group already exists with this name');
            });
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public $onInit = () => {
        this.userGroupId = this.options.id;
        this.busyIndicator = {};

        if (this.userGroupId) {
            this.loadData();
        } else {
            this.group = { } as UserGroupDetail;
        }
    }
}
