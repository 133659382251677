import { SupportService } from "../../api/supportService";
import { BondTypeRequest } from "../../api/types/model/bondTypeRequest";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import app from "../../main";
import { IDeferred, IPromise } from "angular";
import { UploadControls } from "../../components/uploader/uploader";

export class BondTypeRequestModalController {

    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.ToastMessageCreator,
        Injectables.SupportService
    ];

    constructor(
        private readonly $uibModalInstance: Modal<void>,
        private readonly toastMessageCreator: ToastMessageCreator, 
        private readonly supportService: SupportService) {
    }

    public processingDefer: IDeferred<unknown>;
    public request: BondTypeRequest;
    public bondFormName: string;
    public submissionPromise: IPromise<any>;
    public busyIndicator: BusyIndicator;
    public uploaderControls: UploadControls;

    public removeFile() {
        this.uploaderControls.removeFile(0);
    }

    public cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    public submit_click = () => {
        if (this.uploaderControls.getFiles().length) {
            this.uploaderControls.uploadAll()
                .then(uploaderItem => {
                    this.request.bondFormFileId = uploaderItem[0].response[0];
                    this.submit();
                });
        } else {
            this.submit();
        }
    }

    public submit = () => {
        this.busyIndicator.promise = this.supportService.submitBondTypeRequest(this.request)
            .then(() => {
                this.$uibModalInstance.close();
                this.toastMessageCreator.createSuccessMessage('We have submitted your request for a new bond type. We\'ll get back to you a soon as we can.');
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to submit your request.');
            });
    }

    public $onInit = () => {
        this.busyIndicator = { 
            message: 'Submitting...'
        };
    }
}

app.controller('BondTypeRequestModalController', BondTypeRequestModalController);