import { SelectOption } from "../../api/types/selectOption";
import app from "../../main";
import QueryBuilderOperator from "./queryBuilderOperator";
import { getOperatorsByPropertyType } from "./queryBuilderOperatorManager";
import QueryCondition from "./queryCondition";
import QueryConditionPropertyGroup from "./queryConditionPropertyGroup";
import QueryConditionPropertyOption from "./queryConditionPropertyOption";
import QueryConditionPropertyType from "./queryConditionPropertyType";

export class QueryBuilderConditionController {
    public static $inject = [
    ];

    constructor() { }

    public queryCondition: QueryCondition;
    public propertyOptionsAreGroups: boolean;
    public propertyOptions: QueryConditionPropertyOption[];
    public propertyOptionGroups: QueryConditionPropertyGroup[];
    public operatorOptions: SelectOption<QueryBuilderOperator>[];
    public onRemoved: () => void;
    public onChanged: () => void;

    public get hasMultipleFields() {
        return this.queryCondition?.operator &&
                [QueryBuilderOperator.Between, QueryBuilderOperator.NotBetween].indexOf(this.queryCondition.operator) >= 0;
    }

    public propertyChanged = () => {
        if (this.queryCondition.property) {
            for(let i = 0; i < this.propertyOptionGroups.length; i++) {
                const propertyType = this.propertyOptionGroups[i].options.find((type) => type.value === this.queryCondition.property)?.type as QueryConditionPropertyType;

                if (propertyType) {
                    this.queryCondition.propertyType = propertyType;
                    break;
                }
            }
        } 

        this.setOperatorOptions();
        this.onChanged();
    }

    public operatorChanged = () => {
        this.onChanged();
    }

    public valueChanged = () => {
        this.onChanged();
    }

    private setOperatorOptions = () => {
        if (!this.queryCondition?.propertyType) {
            return; 
        }

        this.operatorOptions = getOperatorsByPropertyType(this.queryCondition.propertyType);
    }

    public remove ($event) {
        $event.stopPropagation();
        this.onRemoved();
    }

    public $onInit() {
        if (this.propertyOptionGroups.length > 1) {
            this.propertyOptionsAreGroups = true;
        } else {
            this.propertyOptions = this.propertyOptionGroups[0].options;
        }

        this.setOperatorOptions();
    }
};

const queryBuilderConditionComponent = {
    bindings: {
        propertyOptionGroups: '<',
        queryCondition: '=',
        onRemoved: '&',
        onChanged: '&'

    },
    templateUrl: "app/components/queryBuilder/queryBuilderCondition.html",
    controller: QueryBuilderConditionController,
    controllerAs: "vm"
};

app.component("queryBuilderCondition", queryBuilderConditionComponent);