import TableColumn from "../../components/dataViews/tableColumn";
import QueryConditionPropertyOption from "../../components/queryBuilder/queryConditionPropertyOption";
import QueryConditionSet from "../../components/queryBuilder/queryConditionSet";
import { TablePager } from "../../components/tablePager/tablePager";
import DataView from "../../components/dataViews/dataView";
import { TableSorter } from "../../components/tableSorter/tableSorter";
import { Injectables } from "../../configuration/injectables";
import { IDebounceDelayer } from "../debounceDelayer/iDebouceDelayer";
import { TableRow } from "./tableRow";
import deepCopy from "../immutable/deepCopy";
import DataViewType from "../../components/dataViews/dataViewType";
import { UserService } from "../../api/userService";
import { ToastMessageCreator } from "../toastMessages/toastMessageCreator";
import { ModalOpener } from "../../modals/modalOpener";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { QueryBuilderTableQueryOptions } from "../../api/types/queryBuilderTableOptions";
import { SearchType } from "../../api/types/searchType";
import { IPromise } from "angular";
import QueryBuilderLogicType from "../../components/queryBuilder/queryBuilderLogicType";
import { SharedViewModalResult } from "../../modals/sharedViewsModal/sharedViewsModal";
import QueryConditionPropertyGroup from "../../components/queryBuilder/queryConditionPropertyGroup";

const columnSortHandler = (columnA, columnB) => {
    const headingA = columnA.label || columnA.heading || '';
    const headingB = columnB.label || columnB.heading || '';

    return headingA.localeCompare(headingB);}

export class QueryBuilderTable<T> {
    
    constructor(
        private readonly loadData: () => IPromise<any>,
        private readonly debouceDelayer: IDebounceDelayer,
        public readonly dataViewType: DataViewType = null,
        queryConditionOptions: QueryConditionPropertyOption[] | QueryConditionPropertyGroup[] = [],
        public readonly columnOptions: TableColumn<any>[] = [],
        private readonly defaultViews: DataView[] = [],
        private readonly userService: UserService = null,
        private readonly toastMessageCreator: ToastMessageCreator = null,
        private readonly modalOpener: ModalOpener,
        public readonly areRowsSelectable: boolean = false
    ) {

        columnOptions.sort(columnSortHandler);

        this.setQueryConditionPropertyGroups(queryConditionOptions);
        
        this.busyIndicator = {};

        this.sorter = new TableSorter();
        this.pager = new TablePager();
        
        this.sorter.onSortChanged = this.sortChanged;
        this.pager.onPageChange = this.notifyOfChange;
        this.pager.onRecordsPerPageChanged = this.recordsPerPageChanged;

        this._rows = [];
        this.columnDropdownOptions = [];
    }

    public readonly busyIndicator: BusyIndicator;
    public readonly sorter: TableSorter;
    public readonly pager: TablePager;
    public viewOptions: DataView[] = [];
    public currentView: DataView;
    public columnDropdownOptions: TableColumn<any>[];
    public searchPhrase: string;
    public onFilterChange: () => void;
    public queryConditionPropertyGroups: QueryConditionPropertyGroup[] = [];

    private setQueryConditionPropertyGroups = (queryConditionOptions: QueryConditionPropertyOption[] | QueryConditionPropertyGroup[]) => {
        let ungroupedOptions: QueryConditionPropertyGroup = {
            label: '',
            options: []
        };

        for (let i = 0; i < queryConditionOptions.length; i++) {
            if (queryConditionOptions[i]['options']) {
                const group = queryConditionOptions[i] as QueryConditionPropertyGroup;

                group.options.sort((a, b) => {
                    const labelA = a.label;
                    const labelB = b.label;
        
                    return labelA.localeCompare(labelB);
                });

                this.queryConditionPropertyGroups.push(group);
            } else {
                ungroupedOptions.options.push(queryConditionOptions[i] as QueryConditionPropertyOption);
            }
        }

        if (ungroupedOptions.options.length) {
            this.queryConditionPropertyGroups.push(ungroupedOptions);
        }
    }

    public columnsReordered = () => {
        this.currentView.isModified = true;
    }

    public columnIsMoving = (evnt, originalEvent) => {
        if (this.areRowsSelectable && evnt.related && evnt.related.classList.contains('row-select-column')) {
            return false;
        }
    }

    public dataViewsReordered = () => {
        for(let i = 0; i < this.viewOptions.length; i++) {
            this.viewOptions[i].index = i;
        }

        this.saveDataViews();
    }

    public viewSortableOptions = {
        animation: 350,
        chosenClass: 'sortable-chosen',
        fallbackClass: 'table-sortable-fallback',
        handle: '.grab-handle',
        forceFallback: true,
        onEnd: this.dataViewsReordered
    };

    public columnSortableOptions = {
        animation: 350,
        chosenClass: 'sortable-chosen',
        fallbackClass: 'table-sortable-fallback',
        handle: '.grab-handle',
        filter: '.locked',
        forceFallback: true,
        onEnd: this.columnsReordered,
        onMove: this.columnIsMoving
    }
        
    private _allSelected: boolean;
    public get allSelected(): boolean {
        return this._allSelected;
    }
    public set allSelected(value: boolean) {
        this._allSelected = value;

        for(let i=0; i<this._rows.length; i++) {
            this._rows[i].isSelected = this._allSelected;

            if (this._rows[i].isSelectionDisabled) {
                this._rows[i].isSelected = false;
            }
        }
    }

    public resetView = () => {
        this.viewSelected(this.currentView, true);
    }   

    public columnVisibilityChanged = (column: TableColumn<any>) => {
        var viewColumn = this.currentView.columns.find(col => col.name == column.name);

        if (!viewColumn) {
            this.currentView.columns.push(deepCopy(column));
        }

        viewColumn.isVisible = viewColumn.isVisible !== true;
        this.currentView.isModified = true;
        this.setColumnDropdownOptions();
    }

    private setColumnDropdownOptions = () => {
        this.columnDropdownOptions = deepCopy(this.columnOptions.filter(column => !column.disabled));

        for (let i = 0; i < this.columnDropdownOptions.length; i++) {
            const dropdownOption = this.columnDropdownOptions[i];    
            const viewColumn = this.currentView.columns.find(col => col.name == dropdownOption.name);

            if (viewColumn) {
                dropdownOption.isVisible = viewColumn.isVisible || dropdownOption.locked;
            }

            if (!viewColumn) {
                const addedColumn = deepCopy(dropdownOption);
                addedColumn.isVisible = dropdownOption.locked;
                this.currentView.columns.push(addedColumn);
            }
        }

        this.columnDropdownOptions.sort(columnSortHandler);
    }
    
    public saveViewClicked = () => {
        this.saveDataViews();
    }

    public sharedViewsClicked = () => {
        this.modalOpener.showSharedViewsModal(this.dataViewType)
            .result
            .then((result: SharedViewModalResult) => {
                this.loadDataViews().then(() => {
                    this.setView(this.viewOptions.length - 1);
                });
            });
    }

    public shareView = (view: DataView) => {
        view.isShared = view.isShared !== true;

        this.userService.updateViewSharingOption(view.id, view.isShared);
    }
    
    public saveViewAsClicked = () => {
        this.renameView('Save View As', '').then((newName) => {
            const newView = deepCopy(this.currentView);
            newView.name = newName;
            newView.id = undefined;
            newView.isDefault = false;
            newView.isModified = false;
            newView.recordsPerPage = this.pager.recordsPerPage;
            newView.sortExpression = this.sorter.sortBy;
            this.setColumnsIndex();

            this.busyIndicator.message = 'Saving...';
            this.busyIndicator.promise = this.userService.saveDataView(this.dataViewType, newView)
                .then((viewId) => {
                    newView.id = viewId;
                    this.viewOptions.push(newView);
                    this.currentView = newView;
                });   
        });
    }

    private setColumnsIndex = () => {
        for(let i = 0; i < this.currentView.columns.length; i++) {
            this.currentView.columns[i].index = i;
        }
    }
    
    public renameViewClicked = () => {
        this.renameView('Rename View', this.currentView.name).then((newName) => {
            const currentViewOption = this.viewOptions.find(v => v.id == this.currentView.id);
            currentViewOption.name = newName;
            this.currentView.name = newName;                
            this.saveDataViews();
        });
    }
    
    public promptToDeleteView = () => {
        this.modalOpener.deleteConfirmationModal(
                'Delete View',
                'Are you sure you want to delete this view?',
                null,
                null,
                () => {
                    return this.userService.deleteDataView(this.currentView.id);
                },
                'View has been deleted',
                'An error occurred trying to delete this view')
            .result
            .then(() => {
                const index = this.viewOptions.findIndex(view => view.id == this.currentView.id);
                this.viewOptions.splice(index, 1);
                this.setView(0);
            });
    }

    public renameView = (modalHeading: string, suggestedName: string) => {
        const existingViewNames = this.viewOptions.map(view => view.name);
        return this.modalOpener.showDataViewRenameModal(modalHeading, suggestedName, existingViewNames)
            .result
            .then(result => {
                return result.name;
            });
    }

    public viewSelected = (view: DataView, forceSelection: boolean = false) => {
        if (view.name == this.currentView.name && !forceSelection) {
            return;
        }

        const viewIndex = this.viewOptions.findIndex(v => v.id == view.id);
        this.setView(viewIndex);
    }

    public setView = (index: number) => {
        this.currentView = deepCopy(this.viewOptions[index]);

        // if table is set to have selectable rows and selection column doesn't exist
        if (this.areRowsSelectable && !this.currentView.columns.some(col => col.name == 'row-select-column')) {
            this.currentView.columns.unshift({
                name: 'row-select-column',
                label: 'Row Selection Checkbox',
                headingClass: 'locked row-select-column',
                cellClass: 'row-select-column',
                isVisible: true,
                index: -1,
                locked: true
            });
        }

        if (!this.currentView.searchType) {
            this.currentView.searchType = SearchType.Basic;
        }

        if (!this.currentView.basicFilters) {
            this.currentView.basicFilters = {};
        }

        if (!this.currentView.conditionSet) {
            this.currentView.conditionSet = this.defaultConditionSet;
        }
        
        this.setColumnDropdownOptions();
        this.pager.recordsPerPage = this.currentView.recordsPerPage;
        this.sorter.sortBy = this.currentView.sortExpression;
        this.currentView.isModified = false;

        if (this.onFilterChange instanceof Function) {
            this.onFilterChange();
        }

        this.busyIndicator.message = 'Loading..';
        this.busyIndicator.promise = this.loadData();
    }

    private saveDataViews = () => {
        const currentViewIndex = this.viewOptions.findIndex(v => v.id == this.currentView.id);
        this.currentView.isModified = false;
        this.currentView.recordsPerPage = this.pager.recordsPerPage;
        this.currentView.sortExpression = this.sorter.sortBy;
        this.setColumnsIndex();
        this.viewOptions[currentViewIndex] = deepCopy(this.currentView);

        this.busyIndicator.message = 'Saving...';
        this.busyIndicator.promise = this.userService.saveDataViews(this.dataViewType, this.viewOptions)
            .then(newIds => {
                for(let i = 0; i < this.viewOptions.length; i++) {
                    this.viewOptions[i].id = newIds[i];
                }

                this.currentView.id = this.viewOptions[currentViewIndex].id;
            });    
    }

    private loadDataViews = () => {
        return this.userService.getDataViews(this.dataViewType)
            .then(dataViews => {

                try {
                    for(let i = 0; i < dataViews.length; i++) {

                        const visibleColumns = dataViews[i].columns;
                        let columns = [];
                        for(let j = 0; j < this.columnOptions.length; j++) {
                            let column = deepCopy(this.columnOptions[j]);
                            let matchingVisibleColumn = visibleColumns.find(c => c.name == column.name);
                            
                            if (matchingVisibleColumn) {
                                column.isVisible = true;
                                column.index = matchingVisibleColumn.index;
                            } else if (!column.locked) {
                                column.isVisible = false;
                            }

                            columns.push(column);
                        }
                        dataViews[i].columns = columns;
                        dataViews[i].columns.sort((a,b) => a.index - b.index);
                    }

                    const missingDefaultViews = this.defaultViews.filter(defaultView => !dataViews.find(view => view.isDefault && view.name == defaultView.name))

                    this.viewOptions = [
                        ...deepCopy(missingDefaultViews),
                        ...dataViews
                    ];

                    this.viewOptions.sort((a,b) => a.index - b.index);
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load data views');
            });
    }

    private _data : T[];
    public get data() : T[] {
        return this._data;
    }
    
    private _rows: TableRow[];
    public get rows(): TableRow[] {
        return this._rows;
    }

    public refresh = () => {
        this.notifyOfChange();
    }

    public load = () => {
        this.busyIndicator.message = 'Loading..';
        this.busyIndicator.promise = this.loadDataViews()
            .then(() => {
                const index = this.viewOptions.findIndex(view => view.id == this.currentView?.id);

                if (index >= 0) {
                    this.setView(index)
                } else {    
                    this.setView(0);
                }
            });        
    }

    private defaultConditionSet: QueryConditionSet = {
        logicType: QueryBuilderLogicType.And,
        conditions: [],
        conditionSets: []
    }

    public clearFilters = () => {
        this.applyFilters(SearchType.Basic);
    }

    private recordsPerPageChanged = () => {
        if (this.currentView.recordsPerPage != this.pager.recordsPerPage) {
            this.currentView.isModified = true;
            this.currentView.recordsPerPage = this.pager.recordsPerPage;
            this.notifyOfChange();
        }
    }

    private sortChanged = () => {
        if (this.currentView.sortExpression != this.sorter.sortBy) {
            this.currentView.isModified = true;
        }
        
        this.notifyOfChange();
    };

    public applyFilters = (searchType: SearchType, basicFilters: any = {}, conditionSet: QueryConditionSet = null) => {
        let haveFiltersChanged = false;

        if (!basicFilters) {
            basicFilters = {};
        }

        if (!conditionSet) {
            conditionSet = this.defaultConditionSet;
        }

        haveFiltersChanged = haveFiltersChanged || this.currentView.searchType != searchType;
        haveFiltersChanged = haveFiltersChanged || this.areObjectsEqual(this.currentView.basicFilters, basicFilters) == false;
        haveFiltersChanged = haveFiltersChanged || this.areConditionSetsEqual(this.currentView.conditionSet, conditionSet) == false;
        
        if (haveFiltersChanged) {
            this.currentView.isModified = true;
            this.currentView.searchType = searchType;
            this.currentView.basicFilters = deepCopy(basicFilters);
            this.currentView.conditionSet = deepCopy(conditionSet);

            this.notifyOfChange();

            if (this.onFilterChange instanceof Function) {
                this.onFilterChange();
            }
        }
    }

    public toggleIsExpanded = (index: number) => {
        this.rows[index].isExpanded = this.rows[index].isExpanded !== true;
    }

    public getSelectedRecords = (): T[] => {
        if (!this._data) {
            return [];
        }

        return this._data.filter((record, index) => this._rows[index].isSelected);
    }

    public setData(data: T[], totalRecordCount: number) {
        this._data = data;
        
        this._rows = this._data.map(() => { 
            return { 
                isSelected: false, 
                isExpanded: false,
                isSelectionDisabled: false
            };
        });

        this.pager.totalRecordCount = totalRecordCount;
    }

    public get isVisible(): boolean {
        return !!this._data && !!this._data.length;
    }

    public get isEmptyStateVisible(): boolean {
        return !this._data || !this._data.length;
    }

    public searchPhraseChanged = () => {
        this.debouceDelayer.delay(this.notifyOfChange, 400);
    }

    public getFilterCount() {
        if (this.currentView?.searchType == SearchType.ConditionSet) {
            return this.countConditionSetFilters([this.currentView?.conditionSet]);
        } else {
            return this.countBasicFilters();
        }
    }

    public getQueryOptions(): QueryBuilderTableQueryOptions {
        return {
            searchType: this.currentView?.searchType ?? SearchType.Basic,
            pageNumber: this.pager.currentPage, 
            recordsPerPage: this.pager.recordsPerPage, 
            orderBy: this.sorter.sortBy, 
            searchPhrase: this.searchPhrase, 
            conditionSet: deepCopy(this.currentView?.conditionSet) ?? this.defaultConditionSet,
            basicFilters: deepCopy(this.currentView?.basicFilters) ?? {}
        }
    }

    private countBasicFilters() {

        if (!this.currentView?.basicFilters) {
            return 0;
        }

        let count = 0;

        for (const key in this.currentView?.basicFilters) {
            if (this.currentView?.basicFilters.hasOwnProperty(key) && this.currentView?.basicFilters[key] !== undefined && this.currentView?.basicFilters[key] !== null) {
                count++;
            }
        }

        return count;
    }
    
    private countConditionSetFilters(conditionSets: QueryConditionSet[]) {
        if (!conditionSets) {
            return 0;
        }

        let count = 0;

        for(let i = 0; i < conditionSets.length; i++) {
            count += conditionSets[i].conditions.length;
            count += this.countConditionSetFilters(conditionSets[i].conditionSets);
        }
        
        return count;
    }

    private areConditionSetsEqual(set1: QueryConditionSet, set2: QueryConditionSet): boolean {
        // Compare the logicType property
        if (set1.logicType !== set2.logicType) {
            return false;
        }

        // Compare the conditionSets property recursively
        if (set1.conditionSets.length !== set2.conditionSets.length) {
            return false;
        }
        for (let i = 0; i < set1.conditionSets.length; i++) {
            if (!this.areConditionSetsEqual(set1.conditionSets[i], set2.conditionSets[i])) {
                return false;
            }
        }

        // Compare the conditions property
        if (set1.conditions.length !== set2.conditions.length) {
            return false;
        }
        for (let i = 0; i < set1.conditions.length; i++) {
            if (!this.areObjectsEqual(set1.conditions[i], set2.conditions[i])) {
                return false;
            }
        }

        // All values are equal
        return true;
    }

    private areObjectsEqual(obj1: any, obj2: any): boolean {
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
            return false;
        }
        
        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);
        
        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        }
        
        for (const key of obj1Keys) {
            const value1 = obj1[key];
            const value2 = obj2[key];
        
            if (value1 !== value2 && !(value1 === null && value2 === undefined) && !(value1 === undefined && value2 === null)) {
                return false;
            }
        }
        
        return true;
    }

    private notifyOfChange = () => {
        if (this.pager.recordsPerPage != this.currentView.recordsPerPage) {    
            this.currentView.isModified = true;
        }

        if (this.loadData instanceof Function) {
            this.busyIndicator.message = 'Loading...';
            this.busyIndicator.promise = this.loadData();
        }
    }

    public getSelectedColumnNames = () => {
        return this.currentView.columns
            .filter(column => column.isVisible)
            .map(column => column.name);
    }
}