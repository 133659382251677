import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import { ODataFactory, ODataEndpoint, ODataFilterCollection } from "./odata";
import A3ApiResponse from "./types/a3ApiResponse";
import { BondType } from "./types/model/bondType";
import { UnderwritingConfigurationTableListItem, UnderwritingConfigurationTableQueryOptions } from "../states/carrierSpecific/underwritingConfigurations/underwritingConfigurationGridController";
import { PageResponse } from "./types/pageResponse";
import { UnderwritingConfigurationDetail } from "../states/carrierSpecific/underwritingConfigurationDetail/underwritingConfigurationDetailController";

export class UnderwritingConfigurationService {

    public static $inject = [
        Injectables.$http, 
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings) {}

    public copyConfiguration(id: number): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}UnderwritingConfigurationActions/CopyUnderwritingConfiguration?id=${id}`;

        return this.$http
            .post<A3ApiResponse<number>>(url, null)
            .then((response) => response.data.value);
    }

    public deleteById(id: number) {
        const url = `${this.systemSettings.apiBaseUrl}UnderwritingConfigurationActions/DeleteById?id=${id}`;

        return this.$http
            .post(url, null)
            .then(() => {}); 
    }

    public getUnderwritingConfigurationTableListItems = (tableQueryOptions: UnderwritingConfigurationTableQueryOptions) => {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: '',
                searchPhrase: ''
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = '';
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = 'BondTypes.Name';
        }

        let queryString = `?`;

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        return this.$http
            .get<A3ApiResponse<PageResponse<UnderwritingConfigurationTableListItem>>>(this.systemSettings.apiBaseUrl + 'UnderwritingConfigurationActions/GetUnderwritingConfigurationTableItems' + queryString)
            .then(response => response.data.value); 
    }

    public loadUnderwritingConfigurationDetail = (id: number) => {
        const url = `${this.systemSettings.apiBaseUrl}UnderwritingConfigurationActions/GetUnderwritingConfigurationDetail?id=${id}`;

        return this.$http
            .get<A3ApiResponse<UnderwritingConfigurationDetail>>(url)
            .then((response) => response.data.value);
    }

    public getDashboardWidgetUnderwritingConfigurations(
        orderby: string, 
        currentPage: number,
        recordsPerPage: number,
        filterCollection: ODataFilterCollection
    ): IPromise<{data: BondType[], totalRecordCount: number}> {
        let select = 'name,id,isActive'; 
        let expand = 'underwritingConfigurations($select=id),obligee($select=requisitioningState),sfaaCode($select=code)';

        var query = this.odata.getQuery()
            .orderby(orderby)
            .skip((currentPage - 1) * recordsPerPage)
            .top(recordsPerPage)
            .expand(expand)
            .select(select);

        var filterExpr = filterCollection.getFilterExpression();
        if (filterExpr) {
            query.filter(filterExpr);
        }

        var dataSvc = this.odata.getService(ODataEndpoint.BondType);

        return dataSvc.get<A3ApiResponse<BondType[]>>(query)
            .then((response) => {
                return {
                    data: response.data.value,
                    totalRecordCount: response.data['@odata.count']
                };
            });
    }

    public saveUnderwritingConfiguration(config: UnderwritingConfigurationDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}UnderwritingConfigurationActions/Save`;

        return this.$http.post(url, config)
            .then(() => {})
    }
}

app.service(Injectables.UnderwritingConfigurationService, UnderwritingConfigurationService);
