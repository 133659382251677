import { IJQueryService } from "../../../utilities/jquery/iJQueryService";
import fieldGroupWrapper from "../fieldGroupWrapper";

export default (element: JQuery, jQueryProvider: IJQueryService) => {
    element.attr('type', 'checkbox');
    var fieldGroupClass = element.attr('aa-fg-class');

    var switchClass = element.attr('switch-class');

    var ngshow = element.attr('ng-show') || '';
    if (ngshow.length > 0) {
        ngshow = 'ng-show="' + ngshow + '"';
    }
    
    var labelClass = element.attr('label-class');

    var aaLabelStrategy = element.attr('aa-label-strategy') || '';
    if (!aaLabelStrategy.length) {
        element.attr('aa-label-strategy', 'toggleSwitchLabel');
    }
    
    var wrapper = `
        <div
            class="toggle-switch ${fieldGroupClass} "
            ${ngshow}>

            <label class="flex">
                <span class="v-center toggle-switch-label ${labelClass}"></span>
            
                <div 
                    class="toggle-switch-wrapper v-center ${switchClass} "
                    ng-class="{'active': ${element.attr("aa-field")} === true}">
                    
                    <input />

                    <span class="toggle-switch-slider"></span>
                </div>
            </label>
        </div>`;
    
    fieldGroupWrapper(element, wrapper, jQueryProvider);
};