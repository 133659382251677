import app from "../../main";

const directive = () => ({
    restrict: 'A',
    scope: {
        fileChange: '&'
    },
    link: (scope, element) => {
        element.bind('change', (event: Event) => {
            scope.fileChange({ $event: event });
        });
    }
});

app.directive('fileChange', directive);