import { IPromise } from "angular";
import { CustomersService } from "../../api/customerService";
import { Person } from "../../api/types/model/person";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { EProducerService } from "../../api/eProducerService";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

class ContactListController {

    public static $inject = [
        Injectables.ModalOpener,
        Injectables.CustomersService,
        Injectables.EProducerService,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly modalOpener: ModalOpener,
        private readonly customerService: CustomersService,
        private readonly eproducerService: EProducerService,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {
    }
    
    public busyIndicator: BusyIndicator;
    private customerId?: number;
    private eproducerAccountId?: number ;
    private expandedContacts: boolean[];
    private modifiedContacts: boolean[];
    private originalContactCopies: Person[];
    public contacts: Person[];
    public hideIncludeOnBonds: boolean;
    
    public isContactExpanded(index: number): boolean {
        return this.expandedContacts[index] === true;
    }

    public contactChanged(index: number) {
        this.modifiedContacts[index] = true;
    }

    public removeContact(index: number) {
        const contact = this.contacts[index];
        
        if (!contact) {
            return;
        }

        let confirmationHeading = 'Remove Contact';
        let confirmationMessage = 'Are you sure you want to remove this contact?';
        let confirmationButtonText = 'Remove';

        if (this.customerId || this.eproducerAccountId) {
            confirmationHeading = 'Delete Contact';
            confirmationMessage = 'Are you sure you want to permanently delete this contact?';
            confirmationButtonText = 'Delete';
        }

        this.modalOpener.confirmModal(
                confirmationHeading, 
                confirmationMessage, 
                confirmationButtonText, 
                'Cancel'
            )
            .result
            .then(() => {
                if (!contact.id) {
                    this.removeContactFromList(index);
                    return;
                }

                if (this.customerId) {
                    this.deleteCustomerContact(contact.id, index);
                }
                
                if (this.eproducerAccountId) {
                    this.deleteEProducerContact(contact.id, index);
                }
            })
            .catch(() => {});
    }

    private removeContactFromList(index: number) {
        this.originalContactCopies.splice(index, 1);
        this.contacts.splice(index, 1);
        this.modifiedContacts.splice(index, 1);
    }

    private deleteCustomerContact(id: number, index: number) {
        this.busyIndicator = {
            message: 'Deleting Contact...',
            promise: this.customerService
                .deleteContact(id)
                .then(() => {
                    this.removeContactFromList(index);
                })
                .catch(() => {
                    this.toastMessageCreator.createSuccessMessage('An error occurred trying to delete the contact');
                })
            }
    }

    private deleteEProducerContact(id: number, index: number) {
        this.busyIndicator = {
            message: 'Deleting Contact...',
            promise: this.eproducerService
                .deleteContact(id)
                .then(() => {
                    this.removeContactFromList(index);
                })
                .catch(() => {
                    this.toastMessageCreator.createSuccessMessage('An error occurred trying to delete the EProducer Account contact');
                })
            }
    }

    public toggleContactExpander(index: number) {
        if (this.isContactExpanded(index)) {
            if (this.modifiedContacts[index]) {
                
                this.modalOpener.confirmModal("Save changes", "Do you want to save your changes?", "Yes", "No")
                    .result
                    .then(() => {
                        this.saveContact(index);
                    })
                    .catch(() => {
                        this.modifiedContacts[index] = false;
                        this.contacts[index] = { ...this.originalContactCopies[index] };
                        this.expandedContacts[index] = false;
                    });
            
                } else {
                this.expandedContacts[index] = false;
            }
        } else {
            this.expandedContacts[index] = true;
        }
    }

    public saveContact(index: number) {
        const contact = this.contacts[index];

        if (this.customerId) {
            contact.customerId = this.customerId;

            this.busyIndicator = {
                message: 'Saving...',
                promise: this.customerService.saveCustomerContact(contact)
                    .then((saveResponse) => {
                        this.contacts[index].id = saveResponse.id;

                        if (saveResponse.epicContactId !== null){
                            this.contacts[index].epicContactId = saveResponse.epicContactId;
                        }
                        
                        this.expandedContacts[index] = false;
                        this.modifiedContacts[index] = false;
                        this.originalContactCopies[index] = {...this.contacts[index]};

                        if (this.contacts[index].isPrimaryContact) {
                            for(let i = 0; i < this.contacts.length; i++) {
                                this.contacts[i].isPrimaryContact = this.contacts[i].id === this.contacts[index].id;
                                this.originalContactCopies[i].isPrimaryContact = this.contacts[i].id === this.contacts[index].id;
                            }
                        }

                        if (contact.isBillingContact) {
                            for(let i = 0; i < this.contacts.length; i++) {
                                this.contacts[i].isBillingContact = this.contacts[i].id === this.contacts[index].id;
                                this.originalContactCopies[i].isBillingContact = this.contacts[i].id === this.contacts[index].id;
                            }
                        }
                    })
                    .catch((err) => {
                        if (typeof err.data == "string"){
                            this.toastMessageCreator.createErrorMessage('An error occurred trying to save the customer contact');
                        } else {
                            this.toastMessageCreator.createErrorMessage(err.data.message);
                        }
                    })
            }
        }

        if (this.eproducerAccountId)
        {
            contact.eProducerAccountId = this.eproducerAccountId ;

            this.busyIndicator = {
                message: 'Saving...',
                promise: this.eproducerService.saveEproducerAccountContact(contact)
                    .then((id) => {
                        this.contacts[index].id = id;
                        this.expandedContacts[index] = false;
                        this.modifiedContacts[index] = false;
                        this.originalContactCopies[index] = {...this.contacts[index]};

                        if (this.contacts[index].isPrimaryContact) {
                            for(let i = 0; i < this.contacts.length; i++) {
                                this.contacts[i].isPrimaryContact = this.contacts[i].id === this.contacts[index].id;
                                this.originalContactCopies[i].isPrimaryContact = this.contacts[i].id === this.contacts[index].id;
                            }
                        }

                        if (contact.isBillingContact) {
                            for(let i = 0; i < this.contacts.length; i++) {
                                this.contacts[i].isBillingContact = this.contacts[i].id === this.contacts[index].id;
                                this.originalContactCopies[i].isBillingContact = this.contacts[i].id === this.contacts[index].id;
                            }
                        }
                    })
                    .catch(() => {
                        this.toastMessageCreator.createErrorMessage('An error occurred trying to save the EProduce Account contact');
                    })
            }
        }
    }

    public addContact() {
        const contact = {} as Person;
        const index = this.contacts.length;

        this.originalContactCopies.push(contact);
        this.contacts.push(contact);

        this.expandedContacts[index] = true;
    }
    
    public $onInit(): void {
        this.modifiedContacts = [];
        this.expandedContacts = [];

        this.originalContactCopies = [];
        for (let i = 0; i < this.contacts.length; i++) {
            this.originalContactCopies[i] = { ...this.contacts[i] };
        }
    }
}

const customerContactListComponent = {
    templateUrl: 'app/components/customerContactList/contactList.html',
    controllerAs: 'vm',
    bindings: {
        contacts: '=',
        customerId: '<?',
        eproducerAccountId: '<?',
        hideIncludeOnBonds: '<?'
    },
    controller: ContactListController
};

app.component('contactList', customerContactListComponent);