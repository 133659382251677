import { IAttributes, ICompileService } from "angular";
import app from "../../../../main";
import { DashboardWidgetScope } from "./DashboardWidgetScope";
import { standardWidgets, valueWidgets, chartWidget } from "./widgetTypes";
import { Injectables } from "../../../../configuration/injectables";

export const dashboardWidgetDirective = ($compile: ICompileService) => {

    const link = (
        scope: DashboardWidgetScope, 
        elem: JQuery, 
        attrs: IAttributes) => {

            let widgetString: string;

            if (standardWidgets.indexOf(scope.dashboardWidget.directive) >= 0) {
                widgetString = `
                    <standard-widget
                        widget="dashboardWidget"
                        remove-widget="removeWidget()"
                        clone-widget="cloneWidget()"
                        widget-index="${scope.widgetIndex}">

                        <${scope.dashboardWidget.directive}
                            widget="dashboardWidget">
                        </${scope.dashboardWidget.directive}>
                    </standard-widget>
                `;    
            } else if (valueWidgets.indexOf(scope.dashboardWidget.directive) >= 0) {
                widgetString = `
                    <value-widget
                        widget="dashboardWidget"
                        remove-widget="removeWidget()"
                        clone-widget="cloneWidget()"
                        widget-index="${scope.widgetIndex}">

                        <${scope.dashboardWidget.directive}
                            widget="dashboardWidget">
                        </${scope.dashboardWidget.directive}>
                    </value-widget>
                `;
            } else if (chartWidget.indexOf(scope.dashboardWidget.directive) >= 0) {
                widgetString = `
                    <chart-widget
                        widget="dashboardWidget"
                        remove-widget="removeWidget()"
                        clone-widget="cloneWidget()"
                        widget-index="${scope.widgetIndex}">

                        <${scope.dashboardWidget.directive}
                            widget="dashboardWidget"
                            widget-index="${scope.widgetIndex}">
                        </${scope.dashboardWidget.directive}>
                    </chart-widget>
                `;
            } else {
                widgetString = `
                    <div 
                        ${scope.dashboardWidget.directive}
                        widget="dashboardWidget" 
                        remove-widget="removeWidget()" 
                        clone-widget="cloneWidget()"
                        widget-index="${scope.widgetIndex}">
                    </div>
                `;
            }

            elem
                .html('')
                .append($compile(widgetString)(scope));
    }

    return {
        scope: { 
            dashboardWidget: '=', 
            widgetIndex: '=', 
            removeWidget: '&', 
            cloneWidget: '&' 
        },
        restrict: 'A',
        link: link
    }
}

dashboardWidgetDirective.$inject = [Injectables.$compile];

app.directive('dashboardWidget', dashboardWidgetDirective);
