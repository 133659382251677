import { UserService } from "../../api/userService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ModalOpener } from "../../modals/modalOpener";
import { UserTableItem } from "../../states/common/users/userTableItem";
import { UserTableQueryOptions } from "../../states/common/users/userTableQueryOptions";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BusyIndicator } from "../busyIndicator/busyIndicator";

export class UserListController {

    static $inject = [
        Injectables.UserService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly userService: UserService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    
    public table: Table<UserTableItem, UserTableQueryOptions>;
    public busyIndicator: BusyIndicator;

    private loadData = () => {
        this.busyIndicator.promise = this.userService.getUsers(this.table.queryOptions)
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            });
    }

    public newUserClicked = () => {
        this.modalOpener.showUserDetailModal()
            .result
            .then(() => {
                this.loadData();
            });
    }

    public editUserClicked = (userId: number) => {
        this.modalOpener.showUserDetailModal(userId)
            .result
            .then(() => {
                this.loadData();
            });
    }

    public exportUsersExcelReport = () =>{
        this.busyIndicator = {
            message: "Loading...",
            promise: this.userService.downloadUsersEntriesExcelReport(this.table.queryOptions as UserTableQueryOptions)
        };
    }

    public $onInit() {
        this.busyIndicator = {
            message: 'Loading...'
        };

        this.loadData();
    }
}

const userListComponent = {
    bindings: {},
    templateUrl: 'app/components/userList/userList.html',
    controller: UserListController,
    controllerAs: 'vm'
};

app.component('userList', userListComponent);