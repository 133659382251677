import { IScope } from "angular";
import { Fee } from "../../api/types/model/fee";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { AutoFeeService } from "../../api/autoFeeService";
import { SelectOption } from "../../api/types/selectOption";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import isUndefinedOrNull from "../../utilities/angularUtilities/isUndefinedOrNull";

class FeeListController {

    public static $inject = [
        Injectables.AutoFeeService
    ];
        
    constructor(
        private readonly autoFeeService: AutoFeeService
    ) {
    }

    public fees: Fee[];
    public total: number;
    public feeOptions: SelectOption<number>[];
    public busyIndicator: BusyIndicator;
    public totalChanged?: (object) => void;
    public heading?: string;
    public isDisabled: boolean;

    public addFee = () => {
        this.fees.push({} as Fee);
    };

    public autoFeeChanged (fee: Fee) {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.autoFeeService.getDefaultValues(fee.autoFeeId)
                .then((autoFeeOption) => {
                    
                    if (!fee.amount) {
                        fee.amount = autoFeeOption.defaultAmount;
                        this.calculateTotal();
                    }

                    if (isUndefinedOrNull(fee.creditProducer)) {
                        fee.creditProducer = autoFeeOption.creditProducer;
                    }

                    if (isUndefinedOrNull(fee.includeInBrokerCommissionCalculation)) {
                        fee.includeInBrokerCommissionCalculation = autoFeeOption.includeInBrokerCommissionCalculation;
                    }

                    fee.code = autoFeeOption.code;
                    fee.description = autoFeeOption.description;
                })
        };
    }

    public removeFee = (index: number) => {
        this.fees.splice(index, 1);
        this.calculateTotal();
    }

    private loadFeeOptions() {
        return this.autoFeeService.getFeeOptions()
            .then((feeOptions) => {
                this.feeOptions = feeOptions;
            }); 
    }

    public calculateTotal = () => {
        this.total = 0;
        
        for (let i = 0; i < this.fees.length; i++) {
            this.total += this.fees[i].amount;
        }

        if (this.totalChanged instanceof Function) {
            this.totalChanged({total: this.total});
        }
    }

    public $onInit = () => {
        if (!this.fees) {
            this.fees = [];
        }

        this.total = 0;

        this.loadFeeOptions();
    }
}

const feeListComponent = {
    templateUrl: 'app/components/feeList/feeList.html',
    bindings: {
        fees: '=', 
        total: '=?',
        heading: '@?',
        totalChanged: '&?',
        isDisabled: '<?'
    },
    controllerAs: 'vm',
    controller: FeeListController
}

app.component('feeList',  feeListComponent); 