import { BillToType } from "./billToType";
import { QuoteType } from "./model/quote";
import { Payment } from "./payment";
import { ShippingInfo } from "./shippingInfo";

export type QuotePurchaseRequest = {
    quoteId: number;
    quoteType: QuoteType;
    payment: Payment;
    shippingInfo: ShippingInfo;
    billToType?: BillToType;
    effectiveDate?: Date;
    expirationDate?: Date;
    bondNumber?: string;
    bondNumberGroupId?: number;
    expedited?: boolean;
}