import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import app from "../../main";
import { TableQueryOptions } from "../../api/types/tableQuery";
import { EmailTemplateService } from "../../api/emailTemplateService";

export class EmailTemplateListController {

    public static $inject = [
        Injectables.EmailTemplateService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];

    constructor(
        private readonly emailTemplateService: EmailTemplateService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<EmailTemplateListItem, TableQueryOptions>;
    public busyIndicator: BusyIndicator;

    public newEmailTemplateClicked = () => {
        this.modalOpener.showEmailTemplateDetailModal()
            .result
            .then(() => {
                this.loadData();
            });
    }

    public editEmailTemplateClicked = (id: number) => {
        this.modalOpener.showEmailTemplateDetailModal(id)
            .result
            .then(() => {
                this.loadData();
            });
    }

    public promptToDelete = (emailTemplate: EmailTemplateListItem) => {
        this.busyIndicator = {
            message: 'Deleting...',
            promise: this.modalOpener.confirmModal('Delete Email Template', 'Are you sure you want to delete this email template?', 'Delete', 'Cancel')
            .result
            .then(() => {
                return this.emailTemplateService.deleteById(emailTemplate.id);
            })
            .then(this.loadData)
        };
    }

    public loadData = () => {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.emailTemplateService.getEmailTemplateListItems(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            })
        };
    }

    public $onInit(): void {
        this.loadData();
    }
}

export type EmailTemplateListItem = {
    id: number;
    name: string;
    comments: string;
}

const emailTemplateListComponent = {
    bindings: {},
    templateUrl: 'app/components/emailTemplateList/emailTemplateList.html',
    controller: EmailTemplateListController,
    controllerAs: 'vm'
};

app.component('emailTemplateList', emailTemplateListComponent);