import { IPromise } from "angular";
import { AutoFeeService } from "../../api/autoFeeService";
import { AutoFee } from "../../api/types/model/autoFee";
import { Injectables } from "../../configuration/injectables";
import { ModalOpener } from "../../modals/modalOpener";
import { AutoFeesDropdownFilterOptions } from "./autoFeesFilterDropdown/autoFeesDropdownFilterOptions";
import { IDebounceDelayer } from "../../utilities/debounceDelayer/iDebouceDelayer";
import { Table } from "../../utilities/tables/table";
import { BusyIndicator } from "../busyIndicator/busyIndicator";
import app from "../../main";

export class AutoFeeListController {

    public static $inject = [
        Injectables.AutoFeeService,
        Injectables.IDebouceDelayer,
        Injectables.ModalOpener
    ];
    
    constructor(
        private readonly autoFeeService:AutoFeeService,
        debouceDelayer: IDebounceDelayer,
        private readonly modalOpener: ModalOpener
    ) {
        this.table = new Table(debouceDelayer);
        this.table.onChange = this.loadData;
    }

    public readonly table: Table<AutoFee, AutoFeesDropdownFilterOptions>;
    public busyIndicator: BusyIndicator;

    public newFeeClicked = () => {
        this.modalOpener.showAutoFeeDetailModal()
            .result
            .then(() => {
                this.loadData();
            });
    }

    public editFeeClicked = (id: number) => {
        this.modalOpener.showAutoFeeDetailModal(id)
            .result
            .then(() => {
                this.loadData();
            });
    }

    public promptToDelete = (autoFee: AutoFee) => {
        this.busyIndicator = {
            message: 'Deleting...',
            promise: this.modalOpener.confirmModal('Delete Fee', 'Are you sure you want to delete this fee?', 'Delete', 'Cancel')
            .result
            .then(() => {
                return this.autoFeeService.deleteById(autoFee.id);
            })
            .then(this.loadData)
        };
    }

    public loadData = () => {
        this.busyIndicator = {
            message: 'Loading...',
            promise: this.autoFeeService
            .load(this.table.queryOptions)
            .then((response) => { 
                this.table.setData(response.items, response.totalRecordCount)
            })
        };
    }

    public $onInit(): void {
        this.loadData();
    }
}

const autoFeeListComponent = {
    bindings: {},
    templateUrl: 'app/components/autoFeeList/autoFeeList.html',
    controller: AutoFeeListController,
    controllerAs: 'vm'
};

app.component('autoFeeList', autoFeeListComponent);