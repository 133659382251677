import { IBaseEntity } from "./iBaseEntity";
import { SystemAccount } from "./systemAccount";
import { UnderwritingConfiguration } from "./underwritingConfiguration";


export interface RequiredFieldSet extends IBaseEntity { 
    name: string;
    systemAccountId: number;
    personFirstName: boolean;
    personMiddleName: boolean;
    personLastName: boolean;
    personSSN: boolean;
    personDateOfBirth: boolean;
    personDriversLicenseNumber: boolean;
    personDriversLicenseState: boolean;
    personHomePhone: boolean;
    personCellPhone: boolean;
    personFax: boolean;
    personEmail: boolean;
    personGender: boolean;
    personPhysicalAddress: boolean;
    personPhysicalState: boolean;
    personPhysicalCity: boolean;
    personPhysicalZip: boolean;
    personPhysicalCounty: boolean;
    personMailAddress: boolean;
    personMailState: boolean;
    personMailZip: boolean;
    personMailCity: boolean;
    personMailCounty: boolean;
    personEmployerName: boolean;
    personEmployerAddress: boolean;
    personEmployerState: boolean;
    personEmployerZip: boolean;
    personEmployerCity: boolean;
    personEmployerCounty: boolean;
    personEmployerPhone: boolean;
    personMaritalStatus: boolean;
    personSpouseFirstName: boolean;
    personSpouseMiddleName: boolean;
    personSpouseLastName: boolean;
    personSpouseSsn: boolean;
    personSpouseEmail: boolean;
    personSpouseCellPhone: boolean;
    personSpouseDateOfBirth: boolean;
    personSpouseGender: boolean;
    personResidenceType: boolean;
    personDateMovedToResidence: boolean;
    personYearsExperience: boolean;
    personPrefix: boolean;
    personOwnershipPercent: number;
    personIsUSCitizen: boolean;
    companyName: boolean;
    companyDBA: boolean;
    companyType: boolean;
    companyPhone: boolean;
    companyFax: boolean;
    companyPhysicalAddress: boolean;
    companyPhysicalState: boolean;
    companyPhysicalCity: boolean;
    companyPhysicalZip: boolean;
    companyPhysicalCounty: boolean;
    companyMailAddress: boolean;
    companyMailState: boolean;
    companyMailZip: boolean;
    companyMailCounty: boolean;
    companyMailCity: boolean;
    companyDateFormed: boolean;
    companyNumberOfOwners: boolean;
    companyStateOfIncorporation: boolean;
    companyFEIN: boolean;
    companyNumberOfEmployees: boolean;
    systemAccount: SystemAccount;
    underwritingConfigurations: UnderwritingConfiguration[];
    id: number;
    createdDateTime: Date;
}