import { IPromise } from "angular";
import { ActivityService } from "../../api/activityService";
import { AttachmentService } from "../../api/attachmentService";
import { SystemAccountService } from "../../api/systemAccountService";
import { SelectOption } from "../../api/types/selectOption";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { ActivityAttachment } from "./activityAttachment";
import { UploadControls } from "../uploader/uploader";
import { ModalOpener } from "../../modals/modalOpener";

class ActivityAttachmentListController {

    public static $inject = [
        Injectables.AttachmentService,
        Injectables.ActivityService,
        Injectables.SystemAccountService,
        Injectables.ModalOpener
    ];

    public uploaderControls: UploadControls;
    public attachments: ActivityAttachment[];
    public processingPromise: IPromise<void>;
    public attachmentTypeOptions: SelectOption[];
    public carrierSharingOptions: SelectOption[];
    
    constructor(
        private readonly attachmentService: AttachmentService,
        private readonly activityService: ActivityService,
        private readonly systemAccountService: SystemAccountService,
        private readonly modalOpener: ModalOpener) {
    }

    public removeExtension(attachment: ActivityAttachment) {
        attachment.displayName = attachment.filename.replace(/\.[^/.]+$/, "");
    }
    
    public addExtension(attachment: ActivityAttachment) {
        const regEx = /(?:\.([^.]+))?$/;
        attachment.filename = attachment.displayName + '.' + regEx.exec(attachment.filename)[1];
        attachment.displayName = attachment.filename;
        this.attachmentModified(attachment);
    }

    public toggleAttachmentDetail(attachment: ActivityAttachment) {
        if (attachment.$isExpanded) {
            attachment.$isExpanded = false;
        } else {
            attachment.$isExpanded = true;
        }
    }

    public attachmentModified(attachment: ActivityAttachment) {
        attachment.isModified = true;
    }

    public deleteAttachment(attachment: ActivityAttachment, index: number): void {
        if (!attachment.id) {
            this.attachments.splice(index, 1);
            
            const uploaderItemIndex = this.uploaderControls
                .getFiles()
                .findIndex(item => item.data === attachment.fileId);
            
            this.uploaderControls.removeFile(uploaderItemIndex);
            
            return;
        }

        if (!attachment.isRemoved) {
            attachment.isRemoved = true;
        } else {
            attachment.isRemoved = false;
        }   
    }

    public attachmentInit(attachment: ActivityAttachment) {
        attachment.$url = this.attachmentService.getAttachmentDownloadUrl(attachment.id);
        attachment.displayName = attachment.filename;
    }

    public getAttachmentTypeOptions() {
        this.activityService.getAttachmentTypes()
            .then((attachmentTypes) => {
                this.attachmentTypeOptions = [];

                for(let i = 0; i < attachmentTypes.length; i++) {
                    this.attachmentTypeOptions.push({ label: attachmentTypes[i].name, value: attachmentTypes[i].id });
                }
            });
    }

    public getCarrierSharingOptions() {
        this.carrierSharingOptions = [{label: 'No', value: null}, {label: 'All Carriers', value: -1}];

        this.systemAccountService.getAppointedCarriers()
            .then((carriers) => {

                for(let i=0; i < carriers.length; i++) {
                    this.carrierSharingOptions.push({label: carriers[i].companyName, value: carriers[i].id});
                }
            });
    }

    public $onInit(): void {
        this.getAttachmentTypeOptions();
        this.getCarrierSharingOptions();
    }
}

const activityAttachmentListComponent = {
    bindings: {
        attachments: '<',
        uploaderControls: '<'
    },
    controller: ActivityAttachmentListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/activityAttachmentList/activityAttachmentList.html'
};

app.component('activityAttachmentList', activityAttachmentListComponent);
