import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/settings/systemSettings";
import app from "../main";
import A3ApiResponse from "./types/a3ApiResponse";
import { Rate } from "./types/model/rate";
import { CacheStore } from "./cacheStore";

export class RateService {

public static $inject = [
    Injectables.$http, 
    Injectables.SystemSettings,
    Injectables.CacheStore
];

    constructor(
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings,
        private readonly cacheStore: CacheStore){            
    }

    public getRateById(rateId: number): IPromise<Rate> {
        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/GetRateById?rateId=${rateId}`;
        
        return this.$http.get<A3ApiResponse<Rate>>(url)
            .then((response) => response.data.value);
    }

    public saveRate(rate: Rate): IPromise<number | void> {
        this.cacheStore.clearCacheContainingKey('GetRateOptions');

        const url = `${this.systemSettings.apiBaseUrl}SystemAccountActions/SaveRate`;

        return this.$http.post<A3ApiResponse<number | void>>(url, rate)
            .then((response) => response.data.value);
    }
}

app.service(Injectables.RateService, RateService);