import { BondListTableQueryOptions } from "../components/bondList/bondListTableQueryOptions";
import { BondListItem } from "../components/bondListFilterDropdown/bondListItem";
import { PurchasedBondItem } from "../states/common/purchasedBonds/purchasedBondItem";
import { Injectables } from "../configuration/injectables";
import { AssignPrincipalBond } from "../modals/assignPrincipalModal/AssignPrincipalBond";
import { AssignPrincipalModalData } from "../modals/assignPrincipalModal/AssignPrincipalModalData";
import { RenewalModalBond } from "../modals/renewalModal/renewalModalBond";
import { RenewalModalSubmission } from "../modals/renewalModal/renewalModalSubmission";
import { RenewalRequest } from "../modals/renewalModal/renewalRequest";
import { RenewalSubmissionCalculatorResultSummary } from "../modals/renewalModal/renewalSubmissionCalculatorResultSummary";
import { BondDetail } from "./types/bondDetail";
import { FileDownloader } from "./fileDownloader";
import { Bond } from "./types/bond";
import { BondForReassignment } from "./types/bondForReassignment";
import { BondPrincipal } from "./types/bondPrincipal";
import { ImportBondPreview } from "./types/importBondPreview";
import { ImportedBond } from "./types/importedBond";
import { BondCorrection } from "./types/model/bondCorrection";
import { BondTransaction } from "./types/model/bondTransaction";
import { IssuingBond } from "./types/model/issuingBond";
import { Obligee } from "./types/model/obligee";
import { PageResponse } from "./types/pageResponse";
import { SelectOption } from "./types/selectOption";
import A3ApiResponse from "./types/a3ApiResponse";
import app from "../main";
import { IHttpService, IQService, IPromise } from "angular";
import { SystemSettings } from "../configuration/settings/systemSettings";
import { PurchasedBondsReportDropdownFilterOptions } from "../../app/states/common/purchasedBonds/purchasedBondsReportDropdown/purchasedBondsReportDropdownFilterOptions";
import { QueryBuilderTableQueryOptions } from "./types/queryBuilderTableOptions";

export class BondService {
    public static $inject = [
        Injectables.$http,
        Injectables.FileDownloader,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly $http: IHttpService,
        private readonly fileDownloader: FileDownloader,
        private readonly systemSettings: SystemSettings
    ) {}

    public calculateRenewalTotals(bonds: RenewalModalSubmission[]): IPromise<RenewalSubmissionCalculatorResultSummary> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/CalculateRenewalSubmissions`;

        return this.$http
            .post<A3ApiResponse<RenewalSubmissionCalculatorResultSummary>>(url, bonds)
            .then((response) => response.data.value);
    }

    public saveServicingRoles(bond: BondDetail): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/UpdateServicingRoles`;
        return this.$http.post(url, bond)
            .then(() => {});
    }

    public renewBonds(renewalRequest: RenewalRequest): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "BondActions/RenewBonds",
                renewalRequest
            )
            .then((response) => {});
    }

    private LoadParams( bondId: number,
        isElectronic?: boolean
    ): string{
        let queryString = `?`;

        if (bondId != null) {
            queryString += `bondId=${bondId}`;
        } 

        if (isElectronic != null) {
            queryString +=
                `&isElectronic=` + isElectronic;
        }

        return queryString;
    }

    public updateIsElectronicSuretyBond(isElectronic: boolean, Id:number): IPromise<void> {
        const queryString = this.LoadParams(Id, isElectronic);

        return this.$http
        .post(
            this.systemSettings.apiBaseUrl +
                "BondActions/UpdateIsElectronicSuretyBond" + queryString , null
        )
        .then(() => {});
    }

    public getBondsByEProducerAccountId(
        eProducerAccountId: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): IPromise<PageResponse<BondListItem>> {
        return this.getBondsForList(
            null,
            eProducerAccountId,
            tableQueryOptions
        );
    }

    public getBondsByCustomerId(
        customerId: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): IPromise<PageResponse<BondListItem>> {
        return this.getBondsForList(customerId, null, tableQueryOptions);
    }

    public getBondListReportByEProducerAccountId(
        eProducerAccountId: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): IPromise<void> {
        const queryString = this.buildBondListTableQueryString(
            null,
            eProducerAccountId,
            tableQueryOptions
        );

        return this.fileDownloader.downloadFile(
            this.systemSettings.apiBaseUrl + "BondActions/GetBondListReport" + queryString,
            null
        );
    }

    public getBondListReportByCustomerId(
        customerId: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): IPromise<void> {
        const queryString = this.buildBondListTableQueryString(
            customerId,
            null,
            tableQueryOptions
        );

        return this.fileDownloader.downloadFile(
            this.systemSettings.apiBaseUrl + "BondActions/GetBondListReport" +
                queryString,
            null
        );
    }

    private buildBondListTableQueryString(
        customerId?: number,
        eProducerAccountId?: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): string {
        if (!tableQueryOptions) {
            tableQueryOptions = {
                pageNumber: 1,
                recordsPerPage: 10,
                orderBy: "",
                searchPhrase: ""
            };
        }

        if (!tableQueryOptions.searchPhrase) {
            tableQueryOptions.searchPhrase = "";
        }

        if (!tableQueryOptions.orderBy) {
            tableQueryOptions.orderBy = "bonds.expirationDate";
        }

        let queryString = `?`;

        if (customerId) {
            queryString += `customerId=${customerId}`;
        } else if (eProducerAccountId) {
            queryString += `eProducerAccountId=${eProducerAccountId}`;
        }

        queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
        queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
        queryString += `&orderBy=${tableQueryOptions.orderBy}`;
        queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

        if (tableQueryOptions.effectiveStartDate) {
            queryString +=
                `&effectiveStartDate=` + tableQueryOptions.effectiveStartDate;
        }

        if (tableQueryOptions.effectiveEndDate) {
            queryString +=
                `&effectiveEndDate=` + tableQueryOptions.effectiveEndDate;
        }

        if (tableQueryOptions.expirationStartDate) {
            queryString +=
                `&expirationStartDate=` + tableQueryOptions.expirationStartDate;
        }

        if (tableQueryOptions.expirationEndDate) {
            queryString +=
                `&expirationEndDate=` + tableQueryOptions.expirationEndDate;
        }

        if (tableQueryOptions.status) {
            queryString += `&status=` + tableQueryOptions.status;
        }

        if (tableQueryOptions.renewalStatus) {
            queryString += `&renewalStatus=` + tableQueryOptions.renewalStatus;
        }

        return queryString;
    }

    private getBondsForList(
        customerId?: number,
        eProducerAccountId?: number,
        tableQueryOptions?: BondListTableQueryOptions
    ): IPromise<PageResponse<BondListItem>> {
        const queryString = this.buildBondListTableQueryString(
            customerId,
            eProducerAccountId,
            tableQueryOptions
        );

        return this.$http
            .get<A3ApiResponse<PageResponse<BondListItem>>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondForBondList" +
                    queryString
            )
            .then((response) => response.data.value);
    }

    public getTransactions(bondId: number): IPromise<BondTransaction[]> {
        return this.$http
            .get<A3ApiResponse<BondTransaction[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetTransactions?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public getObligee(bondId: number): IPromise<Obligee> {
        return this.$http
            .get<A3ApiResponse<Obligee>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetObligee?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public getPrincipal(bondId: number): IPromise<BondPrincipal> {
        return this.$http
            .get<A3ApiResponse<BondPrincipal>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondPrincipal?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public importBonds(bonds: ImportBondPreview[]): IPromise<ImportedBond[]> {
        return this.$http
            .post<A3ApiResponse<ImportedBond[]>>(
                this.systemSettings.apiBaseUrl + "BondActions/ImportBonds",
                { bonds: bonds }
            )
            .then((response) => response.data.value);
    }

    public downloadRenewalTemplate(): IPromise<any> {
        return this.fileDownloader.downloadFile(
            this.systemSettings.apiBaseUrl + "BondActions/DownloadBondUploadTemplate",
            {}
        );
    }

    public uploadBondImportTemplate(
        fileDataUrl
    ): IPromise<ImportBondPreview[]> {
        return this.$http
            .post<A3ApiResponse<ImportBondPreview[]>>(
                this.systemSettings.apiBaseUrl + "BondActions/ProcessBondImportTemplate",
                { dataUrl: fileDataUrl }
            )
            .then((response) => response.data.value);
    }

    public issueBond(bond: IssuingBond): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/IssueBond`;
        
        return this.$http.post<A3ApiResponse<number>>(url, bond)
            .then((response) => response.data.value);
    }

    public getBondsForRenewalModal(
        bondIds: number[]
    ): IPromise<RenewalModalBond[]> {
        let queryString = "?";

        for (let i = 0; i < bondIds.length; i++) {
            queryString += "&bondIds=" + bondIds[i].toString();
        }

        return this.$http
            .get<A3ApiResponse<RenewalModalBond[]>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondsForRenewalModal" +
                    queryString
            )
            .then((response) => response.data.value);
    }

    public getBondOptionsByCustomerId(
        customerId: number
    ): IPromise<SelectOption[]> {
        return this.$http
            .get<A3ApiResponse<any>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondOptionsByCustomerId?customerId=" +
                    customerId
            )
            .then((response) => {
                const options: SelectOption[] = [];

                for (let i = 0; i < response.data.value.length; i++) {
                    options.push({
                        label: response.data.value[i].bondNumber,
                        value: response.data.value[i].id
                    });
                }

                return options;
            });
    }

    public assignPrincipal(
        bonds: AssignPrincipalBond[],
        principal: BondPrincipal
    ): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "BondActions/AssignPrincipal",
                { bonds: bonds, principal: principal }
            )
            .then(() => {});
    }

    public getBondsForPrincipalAssignment(
        customerId: number,
        bondIds: number[]
    ): IPromise<AssignPrincipalModalData> {
        let bondIdString = "";

        for (let i = 0; i < bondIds.length; i++) {
            bondIdString += "&bondIds=" + bondIds[i].toString();
        }

        return this.$http
            .get<A3ApiResponse<AssignPrincipalModalData>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondsForPrincipalAssignment?customerId=" +
                    customerId +
                    bondIdString
            )
            .then((response) => response.data.value);
    }

    public approveReview(bondId: number): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "BondActions/ApproveReview",
                { bondId: bondId }
            )
            .then(() => {});
    }

    public markForReview(bondId: number, comment: string): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "BondActions/MarkForReview",
                { bondId: bondId, comments: comment }
            )
            .then(() => {});
    }

    public reassignBond(bondId: number, newCustomerId: number): IPromise<void> {
        return this.$http
            .post(this.systemSettings.apiBaseUrl + "BondActions/ReassignBond", {
                bondId: bondId,
                newCustomerId: newCustomerId
            })
            .then(() => {});
    }

    public getBondForReassignment(
        bondId: number
    ): IPromise<BondForReassignment> {
        return this.$http
            .get<A3ApiResponse<BondForReassignment>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetForReassignment?bondId=" +
                    bondId
            )
            .then((response) => {
                return response.data.value;
            });
    }

    public getStatusFilterOptions(): SelectOption<string>[] {
        return [
            { label: "Active", value: "'Active'" },
            { label: "Pending Cancellation", value: "'PendingCancellation'" },
            { label: "Cancelled", value: "'Cancelled'" },
            { label: "Pending Reinstatement", value: "'PendingReinstatement'" }
        ] as SelectOption<string>[];
    }

    public getBondDetail(bondId: number): IPromise<BondDetail> {
        return this.$http
            .get<A3ApiResponse<BondDetail>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetBondDetail?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public getMostRecentTermTransaction(
        bondId: number
    ): IPromise<BondTransaction> {
        return this.$http
            .get<A3ApiResponse<BondTransaction>>(
                this.systemSettings.apiBaseUrl +
                    `BondActions/GetMostRecentTermTransaction?bondId=${bondId}`
            )
            .then((response) => response.data.value);
    }
    
    public getRenewalApplicationId(bondId: number): IPromise<number> {
        return this.$http
            .get<A3ApiResponse<number>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetRenewalApplicationId?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public getRenewalApplication(bondId: number): IPromise<Bond> {
        return this.$http
            .get<A3ApiResponse<Bond>>(
                this.systemSettings.apiBaseUrl +
                    "BondActions/GetRenewalApplictionData?bondId=" +
                    bondId
            )
            .then((response) => response.data.value);
    }

    public applyBondCorrection(bondCorrection: BondCorrection): IPromise<void> {
        return this.$http
            .post(
                this.systemSettings.apiBaseUrl + "BondActions/CorrectBond",
                bondCorrection
            )
            .then(() => {});
    }

    public getPurchasedBondReport( tableQueryOptions: QueryBuilderTableQueryOptions): IPromise<PageResponse<PurchasedBondItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/GetPurchasedBondReport`;
        
        return this.$http.post<A3ApiResponse<PageResponse<PurchasedBondItem>>>(url,tableQueryOptions)
            .then((response) => response.data.value);
    }

    public downloadPurchasedBondEntriesExcelReport(tableQueryOptions: QueryBuilderTableQueryOptions, columnNames: string[]): IPromise<PageResponse<PurchasedBondItem>> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/GetPurchasedBondEntriesExcelReport`;

        return this.fileDownloader.downloadFile(url, { ...tableQueryOptions, columnNames: columnNames });
    }

    public createRenewalApplication(bondId: number): IPromise<number> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/CreateRenewalApplication`;

        return this.$http
            .post<A3ApiResponse<number>>(url, { bondId: bondId })
            .then((response) => response.data.value);
    }

    public deleteRenewalApplication(bondId: number): IPromise<void> {
        const url = `${this.systemSettings.apiBaseUrl}BondActions/DeleteRenewalApplication`;

        return this.$http.post(url, { bondId: bondId }).then(() => {});
    }
}

app.service(Injectables.BondService, BondService);
