import { EProducerService } from "../../../api/eProducerService";
import {EProducerAccountListItem} from "./eProducerAccountListItem";
import { IDebounceDelayer } from "../../../utilities/debounceDelayer/iDebouceDelayer";
import { Injectables } from "../../../configuration/injectables";
import { ModalOpener } from "../../../modals/modalOpener";
import { ToastMessageCreator } from "../../../utilities/toastMessages/toastMessageCreator";
import { BusyIndicator } from "../../../components/busyIndicator/busyIndicator";
import TableColumn from "../../../components/dataViews/tableColumn";
import DataView from "../../../components/dataViews/dataView";
import QueryConditionPropertyOption from "../../../components/queryBuilder/queryConditionPropertyOption";
import QueryConditionPropertyType from "../../../components/queryBuilder/queryConditionPropertyType";
import DataViewType from "../../../components/dataViews/dataViewType";
import deepCopy from "../../../utilities/immutable/deepCopy";
import QueryBuilderLogicType from "../../../components/queryBuilder/queryBuilderLogicType";
import { SearchType } from "../../../api/types/searchType";
import { QueryBuilderTable } from "../../../utilities/tables/queryBuilderTable";
import { UserService } from "../../../api/userService";
import QueryConditionPropertyGroup from "../../../components/queryBuilder/queryConditionPropertyGroup";

export class EProducerAccountsController {

    public static $inject = [
        Injectables.EProducerService,
        Injectables.ModalOpener,
        Injectables.IDebouceDelayer,
        Injectables.ToastMessageCreator,
        Injectables.UserService
    ];

    constructor(
        private readonly eProducerService: EProducerService,
        private readonly modalOpener: ModalOpener,
        debouceDelayer: IDebounceDelayer,
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly userService: UserService
    ) {
        const columnOptions: TableColumn<any>[] = [{
                name: 'Id',
                heading: 'Id',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                isVisible: true,
                sortExpression: 'EProducerAccounts.Id',
                index: 0
            }, {
                name: 'LookupCode',
                heading: 'Lookup Code',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                isVisible: true,
                sortExpression: 'EProducerAccounts.LookupCode',
                index: 1
            }, {
                name: 'Name',
                heading: 'Name',
                headingClass: 'text-left nowrap',
                cellClass: 'text-left nowrap',
                isVisible: true,
                locked: true,
                sortExpression: 'EProducerAccounts.Name',
                index: 2
            }, {
                name: 'IsLocked',
                heading: 'Locked',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                isVisible: true,
                sortExpression: 'EProducerAccounts.IsLocked',
                index: 3
            }, {
                name: 'IsApproved',
                heading: 'Approved',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                isVisible: true,
                sortExpression: 'EProducerAccounts.IsApproved',
                index: 4
            }, {
                name: 'MailAddress',
                heading: 'Mail Address',
                headingClass: 'fit-content text-left',
                cellClass: 'fit-content text-left',
                isVisible: false,
                index: 5
            }, {
                name: 'DeleteButton',
                heading: '',
                label: 'Delete Button',
                headingClass: 'fit-content',
                cellClass: 'text-right',
                isVisible: true,
                locked: true,
                index: 100
            }, {
                name: 'Phone',
                heading: 'Phone',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.Phone',
                isVisible: false,
                index: 7
            }, {
                name: 'Fax',
                heading: 'Fax',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.Fax',
                isVisible: false,
                index: 8
            }, {
                name: 'PhysicalAddress',
                heading: 'Physical Address',
                headingClass: 'fit-content text-left',
                cellClass: 'fit-content text-left',
                isVisible: false,
                index: 9
            }, {
                name: 'ReferredBy',
                heading: 'Referred By',
                headingClass: 'fit-content text-left',
                cellClass: 'fit-content text-left',
                sortExpression: 'EProducerAccounts.ReferredBy',
                isVisible: false,
                index: 10
            }, {
                name: 'Producer',
                heading: 'Producer',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'ProducerUser.FullName',
                isVisible: false,
                index: 11
            }, {
                name: 'CSE',
                heading: 'CSE',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'CSEUser.FullName',
                isVisible: false,
                index: 11
            }, {
                name: 'CSM',
                heading: 'CSM',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'CSMUser.FullName',
                isVisible: false,
                index: 12
            }, {
                name: 'CSA',
                heading: 'CSA',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'CSAUser.FullName',
                isVisible: false,
                index: 13
            }, {
                name: 'CreatedDateTime',
                heading: 'Created Date/Time',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.CreatedDateTime',
                isVisible: false,
                index: 14
            }, {
                name: 'AllowDeferredPayment',
                heading: 'Deferred Payment',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.AllowDeferredPayment',
                isVisible: false,
                index: 15
            }, {
                name: 'AgencyCode',
                heading: 'Agency Code',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.AgencyCode',
                isVisible: false,
                index: 16
            }, {
                name: 'AgencySubCode',
                heading: 'Agency Sub Code',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.AgencySubCode',
                isVisible: false,
                index: 17
            }, {
                name: 'IsBrokerVisibleToCarrier',
                heading: 'Visible To Carrier',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.IsBrokerVisibleToCarrier',
                isVisible: false,
                index: 18
            }, {
                name: 'BrandingEnabled',
                heading: 'Branding Enabled',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.BrandingEnabled',
                isVisible: false,
                index: 19
            }, {
                name: 'BrandingCompanyName',
                heading: 'Branding Company Name',
                headingClass: 'fit-content text-left',
                cellClass: 'fit-content text-left',
                sortExpression: 'EProducerAccounts.BrandingCompanyName',
                isVisible: false,
                index: 20
            }, {
                name: 'BrandingPhone',
                heading: 'Branding Phone',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.BrandingPhone',
                isVisible: false,
                index: 21
            }, {
                name: 'BrandingEmail',
                heading: 'Branding Email',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.BrandingEmail',
                isVisible: false,
                index: 22
            }, {
                name: 'BillToType',
                heading: 'Bill to Type',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.BillToType',
                isVisible: false,
                index: 24
            }, {
                name: 'Commission',
                heading: 'Commission %',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.Commission',
                isVisible: false,
                index: 25
            }, {
                name: 'CommissionCalculationType',
                heading: 'Commission Type',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.CommissionCalculationType',
                isVisible: false,
                index: 26
            }, {
                name: 'Comments',
                heading: 'Comments',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.Comments',
                isVisible: false,
                index: 27
            }, {
                name: 'Balance',
                heading: 'Balance',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.Balance',
                isVisible: false,
                index: 29
            }, {
                name: 'UnappliedPaymentTotal',
                heading: 'Unapplied Payments',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.UnappliedPaymentTotal',
                isVisible: false,
                index: 30
            }, {
                name: 'InvoicedBalance',
                heading: 'Invoiced Balance',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.InvoicedBalance',
                isVisible: false,
                index: 31
            }, {
                name: 'NotInvoicedTotal',
                heading: 'Not Invoiced Total',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.NotInvoicedTotal',
                isVisible: false,
                index: 32
            }, {
                name: 'CreditTotal',
                heading: 'Credits',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.CreditTotal',
                isVisible: false,
                index: 33
            }, {
                name: 'DebitTotal',
                heading: 'Debits',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                sortExpression: 'EProducerAccounts.DebitTotal',
                isVisible: false,
                index: 34
            }, {
                name: 'Tags',
                heading: 'Tags',
                headingClass: 'fit-content',
                cellClass: 'fit-content',
                isVisible: false,
                index: 35
        }, {
            name: 'Category',
            heading: 'Category',
            headingClass: 'fit-content',
            sortExpression: 'EProducerAccountBrokerCategories.Name',
            cellClass: 'fit-content',
            isVisible: false,
            index: 36
        }, {
            name: 'LegalName',
            heading: 'Legal Name',
            headingClass: 'text-left',
            sortExpression: 'EProducerAccounts.LegalName',
            cellClass: 'fit-content text-left',
            isVisible: false,
            index: 37
        }];

        const conditionGroups: QueryConditionPropertyGroup[] = [
            {
                label: 'General',
                options: [
                    { label: 'Id', value: 'EProducerAccounts.Id', type: QueryConditionPropertyType.Integer },
                    { label: 'Lookup Code', value: 'EProducerAccounts.LookupCode', type: QueryConditionPropertyType.String },
                    { label: 'Broker Name', value: 'EProducerAccounts.Name', type: QueryConditionPropertyType.String },
                    { label: 'Legal Name', value: 'EProducerAccounts.LegalName', type: QueryConditionPropertyType.String }, 
                    { label: 'Comments', value: 'EProducerAccounts.Comments', type: QueryConditionPropertyType.String },
                    { label: 'Category', value: 'EProducerAccounts.EProducerAccountBrokerCategoryId', type: QueryConditionPropertyType.BrokerCategory },
                    { label: 'Phone', value: 'EProducerAccounts.Phone', type: QueryConditionPropertyType.Phone },
                    { label: 'Fax', value: 'EProducerAccounts.Fax', type: QueryConditionPropertyType.String },
                    { label: 'Referred By', value: 'EProducerAccounts.ReferredBy', type: QueryConditionPropertyType.String },
                    { label: 'Created Date/Time', value: 'EProducerAccounts.CreatedDateTime', type: QueryConditionPropertyType.Date },
                    
                ]
            },{
                label: 'Servicing Roles', 
                options: [
                    { label: 'Producer', value: 'EProducerAccounts.ProducerUserId', type: QueryConditionPropertyType.Producer },
                    { label: 'CSE', value: 'EProducerAccounts.ClientServiceExecutiveUserId', type: QueryConditionPropertyType.ClientServiceExecutive },
                    { label: 'CSM', value: 'EProducerAccounts.ClientServiceManagerUserId', type: QueryConditionPropertyType.ClientServiceManager },
                    { label: 'CSA', value: 'EProducerAccounts.ClientServiceAssociateUserId', type: QueryConditionPropertyType.ClientServiceAssociate },        
                ]
            },{
                label: 'Contacts',
                options: [
                    { label: 'Contact Name', value: 'ContactName', type: QueryConditionPropertyType.StringExists },
                    { label: 'Contact Cell Phone', value: 'ContactCellPhone', type: QueryConditionPropertyType.PhoneExists },
                    { label: 'Contact Home Phone', value: 'ContactHomePhone', type: QueryConditionPropertyType.PhoneExists },
                    { label: 'Contact Email', value: 'ContactEmail', type: QueryConditionPropertyType.StringExists }
                ]
            },{
                label: 'Settings',
                options: [
                    { label: 'Branded Name', value: 'EProducerAccounts.BrandingCompanyName', type: QueryConditionPropertyType.String },
                    { label: 'Branding Enabled', value: 'EProducerAccounts.BrandingEnabled', type: QueryConditionPropertyType.Boolean },
                    { label: 'Branded Email', value: 'EProducerAccounts.BrandingEmail', type: QueryConditionPropertyType.String },
                    { label: 'Bill to Type', value: 'EProducerAccounts.BillToType', type: QueryConditionPropertyType.BillToType },
                    { label: 'Deferred Payment', value: 'EProducerAccounts.AllowDeferredPayment', type: QueryConditionPropertyType.Boolean },
                    { label: 'Commission (%)', value: 'EProducerAccounts.Commission', type: QueryConditionPropertyType.Percent },
                    { label: 'Commission Type', value: 'EProducerAccounts.CommissionCalculationType', type: QueryConditionPropertyType.CommissionCalculationType },    
                    { label: 'Is Locked', value: 'EProducerAccounts.IsLocked', type: QueryConditionPropertyType.Boolean },
                    { label: 'Is Approved', value: 'EProducerAccounts.IsApproved', type: QueryConditionPropertyType.Boolean },
                    { label: 'Agency Code', value: 'EProducerAccounts.AgencyCode', type: QueryConditionPropertyType.String },
                    { label: 'Agency Sub-code', value: 'EProducerAccounts.AgencySubCode', type: QueryConditionPropertyType.String },
                    { label: 'Visible to Carrier', value: 'EProducerAccounts.IsBrokerVisibleToCarrier', type: QueryConditionPropertyType.Boolean },        
                ]
            },{
                label: 'Accounting',
                options: [
                    { label: 'Balance', value: 'EProducerAccounts.Balance', type: QueryConditionPropertyType.Money },
                    { label: 'Unapplied Payments', value: 'EProducerAccounts.UnappliedPaymentTotal', type: QueryConditionPropertyType.Money },
                    { label: 'Invoiced Balance', value: 'EProducerAccounts.InvoicedBalance', type: QueryConditionPropertyType.Money },
                    { label: 'Not Invoiced Total', value: 'EProducerAccounts.NotInvoicedTotal', type: QueryConditionPropertyType.Money },
                    { label: 'Outstanding Credits', value: 'EProducerAccounts.CreditTotal', type: QueryConditionPropertyType.Money },
                    { label: 'Outstanding Debits', value: 'EProducerAccounts.DebitTotal', type: QueryConditionPropertyType.Money },
                ]
            }            
        ];

        const defaultViews: DataView[] = [{
            isDefault: true,
            name: 'Default View',
            dataViewType: DataViewType.Brokers,
            recordsPerPage: 10,
            sortExpression: 'EProducerAccounts.Name',
            columns: deepCopy(columnOptions),
            index: 0,
            conditionSet: {
                logicType: QueryBuilderLogicType.And,
                conditions: [],
                conditionSets: []
            },
            basicFilters: {
                isActive: true
            },
            searchType: SearchType.Basic
        }];

        this.table = new QueryBuilderTable(
            this.loadData,
            debouceDelayer,
            DataViewType.Brokers,
            conditionGroups,
            columnOptions,
            defaultViews,
            userService,
            toastMessageCreator,
            modalOpener
        );
    }

    public readonly table: QueryBuilderTable<EProducerAccountListItem>;
    public busyIndicator: BusyIndicator = {};

    public deleteBroker = (account: EProducerAccountListItem) => {
        if (account.isDeletable)
            this.modalOpener
                .confirmModal(
                    "Delete Broker",
                    `Are you sure you want to delete the broker account <br><b>${account.name}</b>`,
                    "Delete",
                    "Cancel"
                )
                .result.then(() => {
                    return this.eProducerService
                        .deleteAccount(account)
                        .catch(() => {
                            this.toastMessageCreator.createErrorMessage("An error occurred while trying to delete this broker account");
                        });
                })
                .then(() => {
                    this.toastMessageCreator.createSuccessMessage("Broker account has been deleted");
                    this.table.refresh();
                })
                .catch(() => {});
    };

    public addNewBroker = () => {
        this.modalOpener
            .showEProducerBrokerModal()
            .result.then(() => {})
            .catch(() => {});
    };

    public showInviteBrokerModal = () => {
        this.modalOpener
            .showInviteBrokerModal()
            .result.then(() => {})
            .catch(() => {});
    };

    public loadData = () => {
        return this.eProducerService.getEproducerBrokerAccountsList(this.table.getQueryOptions())
            .then((response) => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(() => {
                this.toastMessageCreator.createErrorMessage('An error occurred trying to load broker information');
            }); 
    };

    public $onInit = () => {
        this.table.load();
    };
}
