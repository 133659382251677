import { Injectables } from "../../configuration/injectables";
import { AddressService } from "../../utilities/addresses/addressService";
import app from "../../main";
import { IAttributes, IScope } from "angular";

class AddressFormController {
    
    public static $inject = [
        Injectables.AddressService
    ];

    constructor(private readonly addressService: AddressService) { }

    public object: any;
    public hideCounty: boolean;
    public changed: (event: { object: any, changedProperty: string}) => void;
    public $addressesSame: boolean;
    public isPayment: boolean;
    public isEmployer: boolean;
    public isRequired: boolean;
    public disabled: boolean;
    public propertyPrefix: string;

    public get physicalAddress(): string {
        return this.object[this.getPropertyName('physicalAddress')];
    }

    public set physicalAddress(value: string) {
        this.setObjectField(this.getPropertyName('physicalAddress'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailAddress')] = value;
        }
    }

    public get physicalSuiteAptNumber(): string {
        return this.object[this.getPropertyName('physicalSuiteAptNumber')];
    }

    public set physicalSuiteAptNumber(value: string) {
        this.setObjectField(this.getPropertyName('physicalSuiteAptNumber'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailSuiteAptNumber')] = value;
        }
    }

    public get physicalZip(): number {
        return this.object[this.getPropertyName('physicalZip')];
    }

    public set physicalZip(value: number) {
        this.setObjectField(this.getPropertyName('physicalZip'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailZip')] = value;
        }
    }

    public get physicalCity(): string {
        return this.object[this.getPropertyName('physicalCity')];
    }

    public set physicalCity(value: string) {
        this.setObjectField(this.getPropertyName('physicalCity'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailCity')] = value;
        }
    }

    public get physicalCounty(): string {
        return this.object[this.getPropertyName('physicalCounty')];
    }

    public set physicalCounty(value: string) {
        this.setObjectField(this.getPropertyName('physicalCounty'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailCounty')] = value;
        }
    }

    public get physicalState(): string {
        return this.object[this.getPropertyName('physicalState')];
    }

    public set physicalState(value: string) {
        this.setObjectField(this.getPropertyName('physicalState'), value);

        if (this.$addressesSame) {
            this.object[this.getPropertyName('mailState')] = value;
        }
    }

    public get mailAddress(): string {
        return this.object[this.getPropertyName('mailAddress')];
    }

    public set mailAddress(value: string) {
        this.setObjectField(this.getPropertyName('mailAddress'), value);
        this.setAddressSame();
    }

    public get mailSuiteAptNumber(): string {
        return this.object[this.getPropertyName('mailSuiteAptNumber')];
    }

    public set mailSuiteAptNumber(value: string) {
        this.setObjectField(this.getPropertyName('mailSuiteAptNumber'), value);
        this.setAddressSame();
    }

    public get mailZip(): number {
        return this.object[this.getPropertyName('mailZip')];
    }

    public set mailZip(value: number) {
        this.setObjectField(this.getPropertyName('mailZip'), value);
        this.setAddressSame();
    }

    public get mailCity(): string {
        return this.object[this.getPropertyName('mailCity')];
    }

    public set mailCity(value: string) {
        this.setObjectField(this.getPropertyName('mailCity'), value);
        this.setAddressSame();
    }

    public get mailCounty(): string {
        return this.object[this.getPropertyName('mailCounty')];
    }

    public set mailCounty(value: string) {
        this.setObjectField(this.getPropertyName('mailCounty'), value);
        this.setAddressSame();
    }

    public get mailState(): string {
        return this.object[this.getPropertyName('mailState')];
    }

    public set mailState(value: string) {
        this.setObjectField(this.getPropertyName('mailState'), value);
        this.setAddressSame();
    }

    public get employerAddress(): string {
        return this.object[this.getPropertyName('employerAddress')];
    }

    public set employerAddress(value: string) {
        this.setObjectField(this.getPropertyName('employerAddress'), value);
    }

    public get employerSuiteAptNumber(): string {
        return this.object[this.getPropertyName('employerSuiteAptNumber')];
    }

    public set employerSuiteAptNumber(value: string) {
        this.setObjectField(this.getPropertyName('employerSuiteAptNumber'), value);
    }

    public get employerZip(): number {
        return this.object[this.getPropertyName('employerZip')];
    }

    public set employerZip(value: number) {
        this.setObjectField(this.getPropertyName('employerZip'), value);
    }

    public get employerCity(): string {
        return this.object[this.getPropertyName('employerCity')];
    }

    public set employerCity(value: string) {
        this.setObjectField(this.getPropertyName('employerCity'), value);
    }

    public get employerCounty(): string {
        return this.object[this.getPropertyName('employerCounty')];
    }

    public set employerCounty(value: string) {
        this.setObjectField(this.getPropertyName('employerCounty'), value);
    }

    public get employerState(): string {
        return this.object[this.getPropertyName('employerState')];
    }

    public set employerState(value: string) {
        this.setObjectField(this.getPropertyName('employerState'), value);
    }

    public get address(): string {
        return this.object[this.getPropertyName('address')];
    }

    public set address(value: string) {
        this.setObjectField(this.getPropertyName('address'), value);
    }

    public get zip(): number {
        return this.object[this.getPropertyName('zip')];
    }

    public set zip(value: number) {
        this.setObjectField(this.getPropertyName('zip'), value);
    }

    public get city(): string {
        return this.object[this.getPropertyName('city')];
    }

    public set city(value: string) {
        this.setObjectField(this.getPropertyName('city'), value);
    }

    public get state(): string {
        return this.object[this.getPropertyName('state')];
    }

    public set state(value: string) {
        this.setObjectField(this.getPropertyName('state'), value);
    }

    private getPropertyName(baseName: string): string {
        if (!baseName) {
            return '';
        }

        if (!this.propertyPrefix) {
            return baseName;
        }

        return this.propertyPrefix + baseName.charAt(0).toUpperCase() + baseName.substring(1);
    }

    private setObjectField(property: string, value: any): void {
        if (value === undefined && this.object[property] === null) {
            return;
        }

        if (this.object[property] !== value) {
            this.object[property] = value;
            this.onChanged(property);
        }
    }

    private onChanged(field: string): void {
        if (this.changed !== undefined) {
            this.changed({ object: this.object, changedProperty: field });
        }
    }

    public addressSameChanged(): void {
        if (this.$addressesSame) {
            if (this.object[this.getPropertyName('mailAddress')] !== this.object[this.getPropertyName('physicalAddress')]) {
                this.object[this.getPropertyName('mailAddress')] = this.object[this.getPropertyName('physicalAddress')];
                this.onChanged(this.getPropertyName('mailAddress'));
            }

            if (this.object[this.getPropertyName('mailSuiteAptNumber')] !== this.object[this.getPropertyName('physicalSuiteAptNumber')]) {
                this.object[this.getPropertyName('mailSuiteAptNumber')] = this.object[this.getPropertyName('physicalSuiteAptNumber')];
                this.onChanged(this.getPropertyName('mailSuiteAptNumber'));
            }

            if (this.object[this.getPropertyName('mailCity')] !== this.object[this.getPropertyName('physicalCity')]) {
                this.object[this.getPropertyName('mailCity')] = this.object[this.getPropertyName('physicalCity')];
                this.onChanged(this.getPropertyName('mailCity'));
            }

            if (this.object[this.getPropertyName('mailState')] !== this.object[this.getPropertyName('physicalState')]) {
                this.object[this.getPropertyName('mailState')] = this.object[this.getPropertyName('physicalState')];
                this.onChanged(this.getPropertyName('mailState'));
            }

            if (this.object[this.getPropertyName('mailZip')] !== this.object[this.getPropertyName('physicalZip')]) {
                this.object[this.getPropertyName('mailZip')] = this.object[this.getPropertyName('physicalZip')];
                this.onChanged(this.getPropertyName('mailZip'));
            }

            if (this.object[this.getPropertyName('mailCounty')] !== this.object[this.getPropertyName('physicalCounty')]) {
                this.object[this.getPropertyName('mailCounty')] = this.object[this.getPropertyName('physicalCounty')];
                this.onChanged(this.getPropertyName('mailCounty'));
            }
        }
    }

    private setAddressSame(): void  {
        this.$addressesSame = this.addressService.isMailingAddressSameAsPhysical({
            physicalAddress: this.object[this.getPropertyName('physicalAddress')],
            physicalSuiteAptNumber: this.object[this.getPropertyName('physicalSuiteAptNumber')],
            physicalCity: this.object[this.getPropertyName('physicalCity')],
            physicalCounty: this.object[this.getPropertyName('physicalCounty')],
            physicalZip: this.object[this.getPropertyName('physicalZip')],
            physicalState: this.object[this.getPropertyName('physicalState')],

            mailAddress: this.object[this.getPropertyName('mailAddress')],
            mailSuiteAptNumber: this.object[this.getPropertyName('mailSuiteAptNumber')],
            mailCity: this.object[this.getPropertyName('mailSuiteAptNumber')],
            mailCounty: this.object[this.getPropertyName('mailCounty')],
            mailZip: this.object[this.getPropertyName('mailZip')],
            mailState: this.object[this.getPropertyName('mailState')],
        });
    }

    public $onInit(): void {
        if (!this.object) {
            this.object = {};
        }

        this.setAddressSame();
    }
}

const addressDirective = () => {

    const link = (
        scope: IScope, 
        elm: JQuery, 
        attrs: IAttributes, 
        ctrl: AddressFormController) => {

        if (attrs.required || attrs.required === '') {
            ctrl.isRequired = true;
        } else if (attrs.ngRequired) {
            ctrl.isRequired = scope.$parent.$eval(attrs.ngRequired);

            scope.$parent.$watch(attrs.ngRequired, (newValue: boolean) => {
                ctrl.isRequired = newValue;
            });
        }

        if (attrs.disabled || attrs.disabled === '') {
            ctrl.disabled = true;
        } else if (attrs.ngDisabled) {
            ctrl.disabled = scope.$parent.$eval(attrs.ngDisabled);

            scope.$parent.$watch(attrs.ngDisabled, (newValue: boolean) => {
                ctrl.disabled = newValue;
            });
        }
    };

    return {
        restrict: 'E',
        templateUrl: 'app/components/addressForm/addressForm.html',
        bindToController: {
            object: '=',
            propertyPrefix: '=',
            hideCounty: '=',
            changed: '&',
            countyRequired: '=',
            isEmployer: '=',
            isPayment: '='
        },
        scope: {},
        link: link,
        controller: AddressFormController,
        controllerAs: 'vm'
    };
}

app.directive('address', addressDirective);
