import { Application } from "./application";
import { CreditReport } from "./creditReport";
import { Customer } from "./customer";
import { IBaseEntity } from "./iBaseEntity";
import { IndemnitySignature } from "./indemnitySignature";
import { Lead } from "./lead";
import { PeopleLicense } from "./peopleLicense";

export interface Person extends IBaseEntity {
    ams360CustomerContactId: string;
    ams360CustomerId: string;
    ams360PolicyContactId: string;
    application: Application;
    applicationId: number;
    bondId: number;
    bondTransactionId: number;
    cellPhone: string;
    creditReports: CreditReport[];
    customer: Customer;
    customerId: number;
    dateMovedToResidence: Date;
    dateOfBirth: Date;
    driversLicenseNumber: string;
    driversLicenseState: string;
    email: string;
    fax: string;
    firstName: string;
    gender: string;
    homePhone: string;
    indemnitySignatures: IndemnitySignature[];
    jobTitle: string;
    lastName: string;
    lead: Lead;
    leadId: number;
    mailAddress: string;
    mailCity: string;
    mailCounty: string;
    mailState: string;
    mailSuiteAptNumber: string;
    mailZip: string;
    maritalStatus: MaritalStatus;
    middleName: string;
    noMiddleName: boolean;
    peopleLicenses: PeopleLicense[];
    physicalAddress: string;
    physicalCity: string;
    physicalCounty: string;
    physicalState: string;
    physicalSuiteAptNumber: string;
    physicalZip: string;
    prefix: string;
    residenceType: ResidenceType;
    spouseCellPhone: string;
    spouseDateOfBirth: Date;
    spouseEmail: string;
    spouseFirstName: string;
    spouseGender: string;
    spouseLastName: string;
    spouseMiddleName: string;
    spouseSsn: string;
    ssn: string;
    suffix: string;
    uniqueId: string;
    employerName: string;
    employerPhone: string;
    employerAddress: string;
    employerSuiteAptNumber: string;
    employerCity: string;
    employerState: string;
    employerZip: string;
    employerCounty: string;
    yearsExperience: number;
    isPrimaryContact: boolean;
    isBillingContact: boolean;
    id: number;
    createdDateTime: Date;
    fullName: string;
    $include: boolean;
    secondaryEmail: string;
    notes: string;
    isIndemnitor: boolean;
    preferredName: string;
    nmlsNumber: string;
    eProducerAccountId: number;
    trackWithEpic: boolean;
    epicContactId: number; 
    ownershipPercent: number;
    isUSCitizen: boolean;
}

export enum MaritalStatus {	
    Single = "Single",
    Married = "Married",
    Separated = "Separated",
    Divorced = "Divorced",
    Widowed = "Widowed",
    DomPartn = "DomPartn",
    Fiancee = "Fiancee",
    Other = "Other",
    Unknown = "Unknown",
    CivUnion = "CivUnion"
}
export enum ResidenceType {	
    Own = "Own",
    Rent = "Rent"
}