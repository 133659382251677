import { CustomersService } from "../../api/customerService";
import { InvoiceService } from "../../api/invoiceService";
import { PaymentService } from "../../api/paymentService";
import { SystemAccountService } from "../../api/systemAccountService";
import { PaymentMethod } from "../../api/types/model/paymentTransaction";
import { Payment } from "../../api/types/payment";
import { CreditCardFeeRequest } from "../../api/types/payments/creditCardFeeRequest";
import { SelectOption } from "../../api/types/selectOption";
import { Modal } from "../../components/modals/modal";
import { Injectables } from "../../configuration/injectables";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { UtilityService } from "../../utilities/utilityService";
import { PaymentModalOptions } from "./paymentModalOptions";
import { PaymentModalResult } from "./paymentModalResult";
import app from "../../main";
import { IQService } from "angular";
import { SystemSettings } from "../../configuration/settings/systemSettings";
import { CurrentUserResolver } from "../../utilities/currentUserResolver/currentUserResolver";
import { BusyIndicator } from "../../components/busyIndicator/busyIndicator";
import { PaymentContact } from "../../api/types/paymentContact";

export class PaymentModalController {
    public static $inject = [
        Injectables.$uibModalInstance,
        Injectables.Options,
        Injectables.CurrentUserResolver,
        Injectables.UtilityService,
        Injectables.SystemAccountService,
        Injectables.PaymentService,
        Injectables.$q,
        Injectables.CustomersService,
        Injectables.SystemSettings,
        Injectables.ToastMessageCreator
    ];

    constructor(
        private readonly $uibModalInstance: Modal<PaymentModalResult>,
        private readonly modalOptions: PaymentModalOptions,
        private readonly currentUserResolver: CurrentUserResolver,
        private readonly utilityService: UtilityService,
        private readonly systemAccountService: SystemAccountService,
        private readonly paymentService: PaymentService,
        private readonly $q: IQService,
        private readonly customerService: CustomersService,
        private readonly systemSettings: SystemSettings,
        private readonly toastMessageCreator: ToastMessageCreator
    ) {}

    public payment: Payment;
    public busyIndicator: BusyIndicator;
    public creditCardExpirationMonth: SelectOption<string>[];
    public creditCardExpirationYear: SelectOption<string>[];
    public errorMessage: string;
    public totalFormatted: string;
    public processingFeeFormatted: string;
    public paymentMethods: SelectOption<PaymentMethod>[];
    public achAccountTypeOptions: SelectOption<string>[];
    public termsConfirmed: boolean;
    public paymentContact: PaymentContact ;
    public paymentContacts: PaymentContact[];
    public paymentContactOptions: SelectOption<number>[];
    public selectedPaymentContactIndex: number;

    public $onInit(): void {

        this.achAccountTypeOptions = this.paymentService.getAchAccountTypeOptions();

        this.payment = {
            processingFee: null,
            customerId: this.modalOptions.customerId,
            eProducerAccountId: this.modalOptions.eProducerAccountId,
            systemAccountId: this.currentUserResolver.getCurrentUser().user.systemAccountId,
            createdDateTime: this.utilityService.getCurrentDate()
        } as Payment;

        if (this.systemSettings.environment === "Dev") {
            (this.payment.paymentMethod = PaymentMethod.CreditCard),
                (this.payment.cardNumber = "4111111111111111"),
                (this.payment.expirationMonth = "12"),
                (this.payment.expirationYear = "2031"),
                (this.payment.securityCode = "123");
            this.termsConfirmed = true;
        }

        const promises = [
            this.getPaymentMethods(),
            this.getPaymentContacts(this.payment.customerId, this.payment.eProducerAccountId)
        ];

        this.busyIndicator = {
            message: 'Loading...',
            promise: this.$q.all(promises)
        };

        this.creditCardExpirationMonth = this.utilityService.creditCardMonthOptions;
        this.creditCardExpirationYear = this.utilityService.creditCardYearOptions;
    }

    public paymentContactSelected() {
        const selectedContact = this.paymentContacts[this.selectedPaymentContactIndex];

        if (selectedContact.isCompany) {
            this.payment.companyName = selectedContact.displayName;
        } else {
            this.payment.companyName = null;
        }

        this.payment.firstName = selectedContact.firstName;
        this.payment.phone = selectedContact.cellPhone ? selectedContact.cellPhone : selectedContact.homePhone;
        this.payment.lastName = selectedContact.lastName;
        this.payment.address = selectedContact.physicalAddress;
        this.payment.suiteAptNumber = selectedContact.physicalSuiteAptNumber;
        this.payment.zip = selectedContact.physicalZip;
        this.payment.city = selectedContact.physicalCity;
        this.payment.state = selectedContact.physicalState;
        this.payment.email = selectedContact.email;
    }

    public getPaymentMethods() {
        return this.systemAccountService
            .getPaymentMethods()
            .then((paymentMethods) => {
                this.paymentMethods = paymentMethods;
                
                if (this.paymentMethods.length > 0) {
                    this.payment.paymentMethod = this.paymentMethods[0].value;
                } else {
                    this.payment.paymentMethod = null;
                }
            });
    }

    public getPaymentContacts(customerId: number, eProducerAccountId: number){
        return this.customerService
            .getPaymentContacts(customerId, eProducerAccountId)
            .then((results) => {
                
                this.paymentContacts = results;
                
                this.paymentContacts.push({
                    displayName: 'Other'
                } as PaymentContact);

                this.paymentContactOptions = this.paymentContacts.map((name, index) => {
                    return {
                        label: name.displayName,
                        value: index
                    }
                });
            });
    }

    public checkForPaymentTransactionFees() {
        if (!this.payment.amount) {
            return;
        }

        if (this.payment.paymentMethod !== PaymentMethod.CreditCard) {
            this.payment.processingFee = 0;
            this.totalFormatted = this.utilityService.formatCurrency(this.payment.amount)
            return;
        }

        const request: CreditCardFeeRequest = {
            payment: this.payment
        };

        this.busyIndicator.message = "Assessing Transaction Fee...";
        this.busyIndicator.promise = this.paymentService
            .getPaymentTransactionFees(request)
            .then((response) => {
                if (response.success) {
                    this.payment.processingFee = response.fee;
                    this.processingFeeFormatted =
                        this.utilityService.formatCurrency(response.fee);
                    this.totalFormatted = this.utilityService.formatCurrency(
                        this.payment.amount + response.fee
                    );
                } else {
                    this.errorMessage = response.message;
                }
            });
    }

    public submitPayment() {
        if ((this.payment.paymentMethod !== PaymentMethod.Other) &&
            (this.payment.paymentMethod !== PaymentMethod.WireTransfer) &&
            (this.payment.paymentMethod !== PaymentMethod.Check) &&
            (this.payment.paymentMethod !== PaymentMethod.Return)) {

            this.payment.createdDateTime = null;
        }

        if (this.payment.paymentMethod !== PaymentMethod.Check) {
            this.payment.checkNumber = null;
        }

        this.busyIndicator = {
            message: "Processing Payment...",
            promise: this.paymentService
                .processPayment(this.payment)
                .then((response) => {
                    if (response.success) {
                        this.toastMessageCreator.createSuccessMessage("Payment has been approved");
                        this.$uibModalInstance.close(new PaymentModalResult());
                    } else {
                        this.errorMessage = response.message;
                    }
                })
            };
    }

    public cancel() {
        this.$uibModalInstance.close(new PaymentModalResult());
    }
}

app.controller("PaymentModalController", PaymentModalController);