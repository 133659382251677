import { ODataFactory, ODataQuery } from "../../../api/odata";
import { SystemAccountStatementLineItem } from "../../../api/types/model/systemAccountStatementLineItem";
import { Injectables } from "../../../configuration/injectables";
import { SystemSettings } from "../../../configuration/settings/systemSettings";

export class A3BillingStatementsGridController {

    public static $inject = [
        Injectables.ODataFactory,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly systemSettings: SystemSettings
    ) {}

    public downloadUrlBase: string;
    public reportQuery: ODataQuery;

    public gridLoaded = (records: SystemAccountStatementLineItem[]) => {
        for (let i = 0; i < records.length; i++) {
            records[i]['$downloadUrl'] = this.downloadUrlBase + records[i].id;
        }
    }

    public $onInit = () => {
        this.reportQuery = this.odata.getQuery();
        this.reportQuery.orderby('enddate desc');
        this.downloadUrlBase = this.systemSettings.apiBaseUrl + 'SystemAccountActions/DownloadStatement?id=';
    }
}