import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { ODataFactory, ODataTimeFrameFilterType, ODataEndpoint } from "./odata";
import { LeadType, Lead } from "./types/model/lead";
import A3ApiResponse from "./types/a3ApiResponse";
import { SystemSettings } from "../configuration/settings/systemSettings";

export class LeadService {
    public static $inject = [
        Injectables.ODataFactory,
        Injectables.$http,
        Injectables.SystemSettings
    ];

    constructor(
        private readonly odata: ODataFactory,
        private readonly $http: IHttpService,
        private readonly systemSettings: SystemSettings
    ) {}

    public getCount(leadType: LeadType, filterType: ODataTimeFrameFilterType): IPromise<number> {
        const svc = this.odata.getService<Lead>(ODataEndpoint.Lead);

        svc.query.filterByTimeFrame(filterType, "createdDateTime");

        // TODO: Remove string check of leadType in getCount method when controller is converted to use enumeration.
        if ((leadType as string) === "Prospects Only") {
            leadType = LeadType.Prospect;
        }
        if ((leadType as string) === "Inquiries Only") {
            leadType = LeadType.Inquiry;
        }

        // TODO: Change leadType to enum and check when leadType != 'Submission' and insert the leadType in query string in getCount method.
        if (leadType === LeadType.Prospect) {
            svc.query.and("leadType eq 'Prospect'");
        } else if (leadType === LeadType.Inquiry) {
            svc.query.and("leadType eq 'Inquiry'");
        }

        return svc.count().then((response) => response.data);
    }

    public getLeadDetails(leadId: number): IPromise<Lead> {
        const svc = this.odata.getService<Lead>(ODataEndpoint.Lead);

        svc.query.filter(`id eq ${leadId}`);
        svc.query.expand(
            "bondType,people,companies,application,leadQuestionResponses,followUps"
        );

        return svc.get().then((response) => response.data.value[0]);
    }

    public mtdCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.Mtd);
    }

    public last180ToLast90DaysCount(leadType: LeadType): IPromise<number> {
        return this.getCount(
            leadType,
            ODataTimeFrameFilterType.Last180ToLast90Days
        );
    }

    public last90DaysCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.Last90Days);
    }

    public lastMonthCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.LastMonth);
    }

    public lastWeekCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.LastWeek);
    }

    public lastYearCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.LastYear);
    }

    public thisWeekCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.ThisWeek);
    }

    public twoMonthsAgoCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.TwoMonthsAgo);
    }

    public ytdCount(leadType: LeadType): IPromise<number> {
        return this.getCount(leadType, ODataTimeFrameFilterType.Ytd);
    }

    public saveLead = (lead): IPromise<any> => {
        const url = `${this.systemSettings.apiBaseUrl}LeadActions/CreateLead`;

        return this.$http.post<A3ApiResponse<any>>(url, lead)
            .then(response => response.data.value);
    }
}

app.service(Injectables.LeadService, LeadService);
